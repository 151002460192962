import api from '../api';

export const fetchBookings = (data) => api.post('/core/functions/fetchBookings', data);

export const fetchBooking = (data) => api.post('/core/functions/fetchBooking', data);

export const archiveUnarchiveBooking = (data) => api.post('/core/functions/archiveUnarchiveBooking', data);

export const cancelBooking = (data) => api.post('/core/functions/cancelBooking', data);

export const createUpdateBooking = (data) => api.post('/core/functions/createUpdateBooking', data);

export const completeBooking = (data) => api.post('/core/functions/completeBooking', data);

export const declineBooking = (data) => api.post('/core/functions/declineBooking', data);

export const scheduleBooking = (data) => api.post('/core/functions/scheduleBooking', data);
