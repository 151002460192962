import React, { createContext, useReducer, useState, useEffect, useRef } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ROLES, REQUEST_STATUSES, META_TITLE, SOURCES, REQUEST_RESPONSE_STATUSES } from '../../../constants/general.constants';
import { fetchRequest, archiveUnarchiveRequest, duplicateRequest, archiveUnarchiveRequestResponse, cancelRequestResponse, sendRequestResponse } from '../../services/requests';
import { publishRequest } from '../../services/requests/createRequestService';
import { recordView } from '../../services/metrics';
import { Spinner, TeacherNavigation, PublicNavigation, Tab, DetailPageHeader, Button, Typography, ErrorComponent, MessageDialog, BusinessNavigation, AdminNavigation, IconButton, CancelRequestModal } from '../../components';
import { OverviewTab } from './OverviewTab';
import { ResponsesTab } from './ResponsesTab';
import { MessagesTab } from './MessagesTab';
import { AppointmentTab } from './AppointmentTab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as S from './RequestsAppointmentDetails.styles';
import { ScheduleRequestModal } from './ScheduleRequestModal';
import { FulfillRequestModal } from './FulfillRequestModal';
import { CompleteRequestModal } from './CompleteRequestModal';
import { ShareModal } from './ShareModal';
import { SendResponseModal } from './SendResponseModal';
import { errorHandler } from '../../services/authService';

export const AppointmentDetailsContext = createContext();
const initialState = {
	request: {},
};
function reducer(state, action) {
	switch (action.type) {
		case 'setRequest':
			return {
				...state,
				request: action.payload,
			};
		default:
			return state;
	}
}

const RequestsAppointmentDetails = ({ role }) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const [archiveIsOpen, setArchiveIsOpen] = useState(false);
	const [duplicateIsOpen, setDuplicateIsOpen] = useState(false);
	const [publishIsOpen, setPublishIsOpen] = useState(false);
	const [responseCancelIsOpen, setResponseCancelIsOpen] = useState(false);
	const [createAccountIsOpen, setCreateAccountIsOpen] = useState(false);
	const { request } = state;
	const { id } = useParams();
	const history = useHistory();
	const location = useLocation();
	const shareModalRef = useRef();
	const [alreadyCheckedRequest, setAlreadyCheckedRequest] = useState(false);
	const [tabs, setTabs] = useState([]);
	useEffect(() => {
		if (!alreadyCheckedRequest && Object.keys(request).length) {
			if (request.creatorAccess) {
				setTabs([
					{
						id: 1,
						label: 'Overview',
						component: <OverviewTab role={role} />,
					},
					{
						id: 2,
						label: 'Responses',
						component: <ResponsesTab role={role} />,
					},

					{
						id: 3,
						label: request.type === 'information' ? 'Information' : 'Appointment',
						component: <AppointmentTab role={role} />,
					},
				]);
			} else {
				setTabs([
					{
						id: 1,
						label: 'Overview',
						component: <OverviewTab role={role} />,
					},
					{
						id: 2,
						label: 'Messages',
						component: <MessagesTab role={role} />,
					},
				]);
			}
			setAlreadyCheckedRequest(true);
		}
	}, [alreadyCheckedRequest, request]);
	const [pageStatus, setPageStatus] = useState('idle');

	const backUrl = location.state?.backUrl || `/${role || 'teacher'}/requests`;
	const backTitle = location.state?.backTitle || 'Back to Requests';

	const scheduleRequestModalRef = useRef(null);
	const fulfillRequestModalRef = useRef(null);
	const completeRequestModalRef = useRef(null);
	const cancelModalRef = useRef(null);
	const sendResponseModalRef = useRef(null);

	function handleOpenCreateAccountModal() {
		setCreateAccountIsOpen(true);
	}

	function handleCloseCreateAccountModal() {
		setCreateAccountIsOpen(false);
	}

	function handleMoveToSignUp() {
		setCreateAccountIsOpen(false);
		history.push('/sign-up');
	}

	function handleOpenArchiveModal() {
		setArchiveIsOpen(true);
	}

	function handleCloseArchiveModal() {
		setArchiveIsOpen(false);
	}

	function handleOpenResponseCancelModal() {
		setResponseCancelIsOpen(true);
	}

	function handleCloseResponseCancelModal() {
		setResponseCancelIsOpen(false);
	}

	function handleOpenDuplicateModal() {
		setDuplicateIsOpen(true);
	}

	function handleCloseDuplicateModal() {
		setDuplicateIsOpen(false);
	}

	function handleOpenPublishModal() {
		setPublishIsOpen(true);
	}

	function handleClosePublishModal() {
		setPublishIsOpen(false);
	}

	const handleDuplicateRequest = async () => {
		setDuplicateIsOpen(false);
		try {
			await duplicateRequest({ requestId: id });
			history.push(`/${role || 'teacher'}/requests`);
		} catch (error) {
			errorHandler(error);
		}
	};

	const handlePublishRequest = async () => {
		setPublishIsOpen(false);
		try {
			const {
				data: { result },
			} = await publishRequest(id);
			dispatch({ type: 'setRequest', payload: result.request });
		} catch (error) {
			errorHandler(error);
		}
	};

	const handleArchiveUnarchive = async () => {
		setArchiveIsOpen(false);
		try {
			if (request.isResponder === true) {
				const {
					data: { result },
				} = await archiveUnarchiveRequestResponse({ responseId: request.response.id });
				dispatch({ type: 'setRequest', payload: result.request });
			} else {
				const {
					data: { result },
				} = await archiveUnarchiveRequest({ requestId: id });
				dispatch({ type: 'setRequest', payload: result.request });
			}
		} catch (error) {
			errorHandler(error);
		}
	};

	const handleCancelRequestClick = () => {
		cancelModalRef.current.open(id);
	};

	const handleSendResponseClick = () => {
		sendResponseModalRef.current.open(id);
	};

	const handleCancelResponse = async () => {
		setResponseCancelIsOpen(false);
		try {
			const {
				data: { result },
			} = await cancelRequestResponse({ responseId: request.response.id });
			dispatch({ type: 'setRequest', payload: result.request });
		} catch (error) {
			errorHandler(error);
		}
	};

	async function initializeData() {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchRequest({ requestId: id });
			dispatch({ type: 'setRequest', payload: result.request });
			setPageStatus('success');
			await recordView({ sourceType: SOURCES.REQUEST, sourceId: id });
		} catch (error) {
			setPageStatus('error');
			errorHandler(error);
		}
	}

	useEffect(() => {
		// Set document title
		document.title = role != null ? `Request Details | Dashboard | ${META_TITLE}` : `Request Details | ${META_TITLE}`;

		// Load initial data
		if (pageStatus === 'idle') {
			initializeData();
		}
	}, [pageStatus]);

	const renderButtons = (request) => {
		if (role == null) {
			return (
				<Button
					onClick={() => {
						handleOpenCreateAccountModal();
					}}
				>
					<FontAwesomeIcon icon={['fal', 'comment']} />
					<Typography weight="bold" variation="button-medium">
						Send Response
					</Typography>
				</Button>
			);
		} else if (role === ROLES.ADMIN || role === ROLES.SUPER) {
			return (
				<>
					<IconButton className="icon-button" icon={['fal', 'share']} onClick={() => shareModalRef.current.open(id)} />
					<ShareModal ref={shareModalRef} />
					{request.isArchived === true ? (
						<Button
							onClick={() => {
								handleOpenArchiveModal();
							}}
						>
							<Typography weight="bold" variation="button-medium">
								Unarchive
							</Typography>
						</Button>
					) : (
						<Button
							onClick={() => {
								handleOpenArchiveModal();
							}}
						>
							<Typography weight="bold" variation="button-medium">
								Archive
							</Typography>
						</Button>
					)}
				</>
			);
		} else if (request.creatorAccess === true) {
			if (request.status === REQUEST_STATUSES.PENDING) {
				return request.type === 'information' ? (
					<>
						<Button variant="outline" onClick={handleCancelRequestClick}>
							<Typography weight="bold" variation="button-medium">
								Cancel
							</Typography>
						</Button>
						<Button onClick={() => fulfillRequestModalRef.current.open(id)}>
							<Typography weight="bold" variation="button-medium">
								Fulfill
							</Typography>
						</Button>
						<Button
							onClick={() => {
								handleOpenPublishModal();
							}}
						>
							<Typography weight="bold" variation="button-medium">
								Set as Draft
							</Typography>
						</Button>
						<FulfillRequestModal ref={fulfillRequestModalRef} role={role} />
					</>
				) : (
					<>
						<Button variant="outline" onClick={handleCancelRequestClick}>
							<Typography weight="bold" variation="button-medium">
								Cancel
							</Typography>
						</Button>
						<Button onClick={() => scheduleRequestModalRef.current.open(id)}>
							<Typography weight="bold" variation="button-medium">
								Schedule
							</Typography>
						</Button>
						<Button
							onClick={() => {
								handleOpenPublishModal();
							}}
						>
							<Typography weight="bold" variation="button-medium">
								Set as Draft
							</Typography>
						</Button>
						<ScheduleRequestModal ref={scheduleRequestModalRef} role={role} />
					</>
				);
			} else if (request.status === REQUEST_STATUSES.SCHEDULED) {
				return (
					<>
						<Button variant="outline" onClick={handleCancelRequestClick}>
							<Typography weight="bold" variation="button-medium">
								Cancel
							</Typography>
						</Button>
						<Button onClick={() => completeRequestModalRef.current.open(id)}>
							<Typography weight="bold" variation="button-medium">
								Complete
							</Typography>
						</Button>
						<CompleteRequestModal ref={completeRequestModalRef} role={role} />
					</>
				);
			} else if (request.status === REQUEST_STATUSES.DRAFT) {
				return (
					<>
						<Button
							variant="outline"
							onClick={() => {
								handleOpenArchiveModal();
							}}
						>
							<Typography weight="bold" variation="button-medium">
								Archive
							</Typography>
						</Button>
						<Button
							onClick={() => {
								handleOpenPublishModal();
							}}
						>
							<Typography weight="bold" variation="button-medium">
								Publish
							</Typography>
						</Button>
					</>
				);
			} else if (request.status === REQUEST_STATUSES.COMPLETE || request.status === REQUEST_STATUSES.INCOMPLETE) {
				return (
					<>
						<Button
							variant="outline"
							onClick={() => {
								handleOpenDuplicateModal();
							}}
						>
							<Typography weight="bold" variation="button-medium">
								Duplicate
							</Typography>
						</Button>
						<Button
							onClick={() => {
								handleOpenArchiveModal();
							}}
						>
							<Typography weight="bold" variation="button-medium">
								Archive
							</Typography>
						</Button>
					</>
				);
			} else if (request.status === REQUEST_STATUSES.ARCHIVED) {
				return (
					<>
						<Button
							variant="outline"
							onClick={() => {
								handleOpenDuplicateModal();
							}}
						>
							<Typography weight="bold" variation="button-medium">
								Duplicate
							</Typography>
						</Button>
						<Button
							onClick={() => {
								handleOpenArchiveModal();
							}}
						>
							<Typography weight="bold" variation="button-medium">
								Unarchive
							</Typography>
						</Button>
					</>
				);
			}
		} else if (request.isResponder === true) {
			if (request.response.status === REQUEST_RESPONSE_STATUSES.PENDING || request.response.status === REQUEST_RESPONSE_STATUSES.ACCEPTED) {
				return (
					<>
						<Button variant="outline" onClick={handleOpenResponseCancelModal}>
							<Typography weight="bold" variation="button-medium">
								Cancel
							</Typography>
						</Button>
					</>
				);
			} else if (request.response.status === REQUEST_RESPONSE_STATUSES.COMPLETE || request.response.status === REQUEST_RESPONSE_STATUSES.INCOMPLETE) {
				return (
					<>
						<Button
							onClick={() => {
								handleOpenArchiveModal();
							}}
						>
							<Typography weight="bold" variation="button-medium">
								Archive
							</Typography>
						</Button>
					</>
				);
			} else if (request.response.status === REQUEST_RESPONSE_STATUSES.ARCHIVED) {
				return (
					<>
						<Button
							onClick={() => {
								handleOpenArchiveModal();
							}}
						>
							<Typography weight="bold" variation="button-medium">
								Unarchive
							</Typography>
						</Button>
					</>
				);
			}
		} else {
			return (
				<>
					<Button onClick={() => handleSendResponseClick(request.id)}>
						<FontAwesomeIcon icon={['fal', 'comment']} />
						<Typography weight="bold" variation="button-medium">
							Send Response
						</Typography>
					</Button>
					<SendResponseModal ref={sendResponseModalRef} />
				</>
			);
		}
	};

	const renderContent = () => {
		switch (pageStatus) {
			case 'loading':
				return <Spinner />;
			case 'error':
				return <ErrorComponent />;
			case 'success':
				return (
					<>
						<DetailPageHeader title={request.title} badge={request.creatorAccess === true || request.isResponder === true ? request.status[0].toUpperCase() + request.status.slice(1) : null} backButton={{ label: backTitle, onClick: () => history.push(backUrl) }}>
							{renderButtons(request)}
						</DetailPageHeader>
						<S.Wrapper>
							{(request.creatorAccess === true || request.isResponder === true) && role != null && request.status !== REQUEST_STATUSES.DRAFT ? (
								<Tab tabs={tabs} setTabs={setTabs} />
							) : (
								<S.PublicWrapper>
									<OverviewTab role={role} />
								</S.PublicWrapper>
							)}
						</S.Wrapper>
						{role == null && (
							<MessageDialog
								isOpen={createAccountIsOpen}
								onRequestClose={handleCloseCreateAccountModal}
								title="Create an account"
								subtitle="In order to respond to this request you must login to your account. If you don't have an account, you must signup to continue."
								icon={['fal', 'user']}
								actions={[
									{ id: 'back', label: 'Back', variant: 'outline', variation: 'default' },
									{ id: 'signup', label: 'Sign Up', variant: 'solid', variation: 'default', onClick: handleMoveToSignUp },
								]}
							/>
						)}
						{role != null && (
							<>
								<MessageDialog
									isOpen={archiveIsOpen}
									onRequestClose={handleCloseArchiveModal}
									title={((role === ROLES.ADMIN || role === ROLES.SUPER) && request.isArchived !== true) || request.status !== REQUEST_STATUSES.ARCHIVED ? `Archive ${request.isResponder ? 'Response' : 'Request'}` : `Unarchive ${request.isResponder ? 'Response' : 'Request'}`}
									subtitle={
										((role === ROLES.ADMIN || role === ROLES.SUPER) && request.isArchived !== true) || request.status !== REQUEST_STATUSES.ARCHIVED
											? `${request.isResponder ? 'Are you sure you want to archive this request response?' : 'Are you sure you want to archive this request? Businesses will no longer be able to send you responses.'}`
											: `${request.isResponder ? 'Are you sure you want to unarchive this request response?' : 'Are you sure you want to unarchive this request? Businesses will be able to send you responses.'}`
									}
									icon={['fal', 'file-plus']}
									actions={[
										{ id: 'back', label: 'Cancel', variant: 'outline', variation: 'default' },
										{
											id: 'archiveUnarchive',
											label:
												((role === ROLES.ADMIN || role === ROLES.SUPER) && request.isArchived !== true) || request.status !== REQUEST_STATUSES.ARCHIVED ? `Archive ${request.isResponder ? 'Response' : 'Request'}` : `Unarchive ${request.isResponder ? 'Response' : 'Request'}`,
											variant: 'solid',
											variation: 'default',
											onClick: handleArchiveUnarchive,
										},
									]}
								/>
								<MessageDialog
									isOpen={duplicateIsOpen}
									onRequestClose={handleCloseDuplicateModal}
									title="Duplicate Request?"
									subtitle="Are you sure you want to duplicate this request? All request data will be copied over to the new request."
									icon={['fal', 'file-plus']}
									actions={[
										{ id: 'back', label: 'Cancel', variant: 'outline', variation: 'default' },
										{ id: 'duplicate', label: 'Duplicate Request', variant: 'solid', variation: 'default', onClick: handleDuplicateRequest },
									]}
								/>
								<MessageDialog
									isOpen={publishIsOpen}
									onRequestClose={handleClosePublishModal}
									title={request.status === REQUEST_STATUSES.DRAFT ? 'Publish Request?' : 'Set Request as a Draft?'}
									subtitle={request.status === REQUEST_STATUSES.DRAFT ? 'Are you ready to publish this request? Businesses will be able to send you responses.' : "Are you sure you want to set this request as a draft? Businesses won't be able to send you responses."}
									icon={['fal', 'file-plus']}
									actions={[
										{ id: 'back', label: 'Cancel', variant: 'outline', variation: 'default' },
										{ id: 'publish', label: request.status === REQUEST_STATUSES.DRAFT ? 'Publish Request' : 'Set as Draft', variant: 'solid', variation: 'default', onClick: handlePublishRequest },
									]}
								/>
								<MessageDialog
									isOpen={responseCancelIsOpen}
									onRequestClose={handleCloseResponseCancelModal}
									title="Cancel Request Response?"
									subtitle="Are you sure you want to cancel this request response? The requester will be notified."
									icon={['fal', 'file-plus']}
									actions={[
										{ id: 'back', label: 'Nevermind', variant: 'outline', variation: 'default' },
										{ id: 'duplicate', label: 'Cancel Response', variant: 'solid', variation: 'default', onClick: handleCancelResponse },
									]}
								/>
								<CancelRequestModal ref={cancelModalRef} role={role} />
							</>
						)}
					</>
				);
			default:
				return null;
		}
	};
	if (role === ROLES.TEACHER) {
		return (
			<TeacherNavigation>
				<AppointmentDetailsContext.Provider value={{ state, dispatch }}>{renderContent()}</AppointmentDetailsContext.Provider>
			</TeacherNavigation>
		);
	} else if (role === ROLES.BUSINESS) {
		return (
			<BusinessNavigation>
				<AppointmentDetailsContext.Provider value={{ state, dispatch }}>{renderContent()}</AppointmentDetailsContext.Provider>
			</BusinessNavigation>
		);
	} else if (role === ROLES.ADMIN || role === ROLES.SUPER) {
		return (
			<AdminNavigation>
				<AppointmentDetailsContext.Provider value={{ state, dispatch }}>{renderContent()}</AppointmentDetailsContext.Provider>
			</AdminNavigation>
		);
	}
	return (
		<PublicNavigation>
			<AppointmentDetailsContext.Provider value={{ state, dispatch }}>{renderContent()}</AppointmentDetailsContext.Provider>
		</PublicNavigation>
	);
};

RequestsAppointmentDetails.propTypes = {
	role: PropTypes.string,
};

export default RequestsAppointmentDetails;
