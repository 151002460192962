import React from 'react';
import PropTypes from 'prop-types';

import { LocationsCard } from './LocationsCard';
import { OverviewCard } from './OverviewCard';

import * as S from './OverviewTab.styles';

export const OverviewTab = ({ business }) => {
	return (
		<S.Wrapper>
			<OverviewCard business={business} />
			<LocationsCard business={business} />
		</S.Wrapper>
	);
};

OverviewTab.propTypes = {
	business: PropTypes.object,
};
