import styled from 'styled-components';

import { Button as BaseButton } from '../../components';

export const Wrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	padding: 0 1rem 2rem;
`;

export const Button = styled(BaseButton)`
	svg {
		margin-right: 0.625rem;
	}
`;
