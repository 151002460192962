import styled from 'styled-components';
import colors from '../../../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;

	.location-info {
		&__element {
			display: flex;
			border-top: 1px solid ${colors.neutralForm};
			margin-top: 0.75rem;
			padding-top: 0.75rem;

			&:first-child {
				margin: 0;
				padding: 0;
				border-top: none;
			}

			&:last-child {
				padding-bottom: 0.5rem;
			}
		}

		&__icon-wrapper {
			display: flex;
			justify-content: center;
			width: 1.5rem;
			margin-top: 0.125rem;
			margin-right: 0.75rem;
		}

		&__subtitle {
			margin-top: 0.25rem;
			color: ${colors.neutralText};
		}
	}
`;
