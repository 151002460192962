import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

import * as S from '../CreateResource.styles';
import { OverviewStep } from './OverviewStep';
import { FilesStep } from './FilesStep';
import { LocationStep } from './LocationStep';
import { LocationAltStep } from './LocationAltStep';
import { AvailabilityStep } from './AvailabilityStep';
import { LinksStep } from './LinksStep';
import { EventLinkStep } from './EventLinkStep';
import { DateAndTimeStep } from './DateAndTimeStep';

export const StepDetails = ({ selectedDetailsStepId }) => {
	const { getValues } = useFormContext();

	return (
		<S.FormPanel>
			{getValues('type') === 'appointment' && (
				<>
					{selectedDetailsStepId === 1 && <OverviewStep />}
					{selectedDetailsStepId === 2 && <FilesStep />}
					{selectedDetailsStepId === 3 && <LocationStep />}
					{selectedDetailsStepId === 4 && <AvailabilityStep />}
				</>
			)}
			{getValues('type') === 'event' && (
				<>
					{selectedDetailsStepId === 1 && <OverviewStep />}
					{selectedDetailsStepId === 2 && <FilesStep />}
					{selectedDetailsStepId === 3 && <EventLinkStep />}
					{selectedDetailsStepId === 4 && <LocationAltStep />}
					{selectedDetailsStepId === 5 && <DateAndTimeStep />}
				</>
			)}
			{getValues('type') === 'information' && (
				<>
					{selectedDetailsStepId === 1 && <OverviewStep />}
					{selectedDetailsStepId === 2 && <FilesStep />}
					{selectedDetailsStepId === 3 && <LinksStep />}
				</>
			)}
		</S.FormPanel>
	);
};

StepDetails.propTypes = {
	selectedDetailsStepId: PropTypes.number,
};
