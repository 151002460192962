import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Select, Spinner, Typography, ErrorComponent } from '../../../../components';
import { fetchGroups } from '../../../../services/groups';
import { GRADE_LEVELS } from '../../../../../constants/general.constants';
import { required } from '../../../../utils/form-default-errors';

export const StepThree = ({}) => {
	const {
		control,
		watch,
		formState: { errors },
	} = useFormContext();
	const [pageStatus, setPageStatus] = useState('idle');
	const [groups, setGroups] = useState([]);

	const selectedGroup = groups.find((group) => group.groupId === watch('groupId'));

	async function initializeData() {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchGroups({ page: 0 });

			setGroups(result.groups);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}

	const renderContent = () => {
		switch (pageStatus) {
			case 'loading':
				return <Spinner />;
			case 'error':
				return <ErrorComponent />;
			case 'success':
				return (
					<div className="step__location-fields-wrapper">
						<Controller
							name="groupId"
							control={control}
							rules={{ required: required('Name of Group') }}
							render={({ field: { onChange, value } }) => (
								<Select
									getOptionLabel={(option) => option.name}
									getOptionValue={(option) => option.groupId}
									label="Name of Group (i.e class, entire school, grade, etc.)"
									placeholder="Select a group..."
									id="booked-group"
									error={errors?.groupId}
									options={groups}
									value={groups.find((c) => c.groupId === value) || ''}
									onChange={(val) => onChange(val.groupId)}
								/>
							)}
						/>
						{selectedGroup && (
							<div className="step__information-card">
								<div className="icon-wrapper">
									<FontAwesomeIcon icon="users" />
								</div>
								<div className="info-wrapper">
									<Typography tag="h5" weight="bold">
										{selectedGroup?.name}
									</Typography>
									<div className="info-line">
										<FontAwesomeIcon icon={['fal', 'users']} />
										<Typography tag="p" variation="2">
											{`${selectedGroup?.totalStudents} students`}
										</Typography>
									</div>
									<div className="info-line">
										<FontAwesomeIcon icon={['fal', 'users-class']} />
										<Typography tag="p" variation="2">
											{GRADE_LEVELS.find((grade) => grade.value === selectedGroup?.gradeLevels[0])?.label}
										</Typography>
									</div>
									{selectedGroup?.subject && (
										<div className="info-line">
											<FontAwesomeIcon icon={['fal', 'chalkboard']} />
											<Typography tag="p" variation="2">
												{selectedGroup?.subject}
											</Typography>
										</div>
									)}
									{selectedGroup?.classCode && (
										<div className="info-line">
											<FontAwesomeIcon icon={['fal', 'book']} />
											<Typography tag="p" variation="2">
												{selectedGroup?.classCode}
											</Typography>
										</div>
									)}
								</div>
							</div>
						)}
					</div>
				);
			default:
				return null;
		}
	};

	useEffect(() => {
		// Load initial data
		if (pageStatus === 'idle') {
			initializeData();
		}
	}, [pageStatus]);

	return (
		<div className="step__wrapper">
			<header className="step__header">
				<Typography tag="h2" weight="extrablack" className="step__title">
					Select a Group
				</Typography>
				<Typography tag="p" className="step__subtitle">
					Select a group you would like for the resource.
				</Typography>
			</header>
			<section className="step__content">{renderContent()}</section>
		</div>
	);
};
