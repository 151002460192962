import React from 'react';
import { GRADE_LEVELS } from '../../constants/general.constants';
import { Typography } from '../components/Typography';

export const formatGradeLevel = (levels, range) => {
	if (range === true) {
		const grades = levels.map((level) => GRADE_LEVELS.find((compare) => compare.value === level).label);
		if (grades.length === 0) return '';
		if (grades.length === 1) return grades[0];
		return `${grades[0]} - ${grades[grades.length - 1]}`;
	}
	return levels.map((level) => GRADE_LEVELS.find((compare) => compare.value === level).label).join(', ');
};

export const formatTemplatedMessage = (message, subject, variables) => {
	// Initialize dynamic message
	let dynamicMessage = message;

	// Format data
	const data = {
		subject,
		variables,
	};

	// Ensure message exists
	if (message) {
		// Set dynamic message
		dynamicMessage = [message];

		// Search for template matches
		const matches = message.match(/\{{(.*?)\}}/g);

		// Replace content
		if (matches) {
			matches.forEach((match) => {
				const refObj = match.replace(/[{}]/g, '');
				const refs = refObj.split('.');
				let value = data;
				refs.forEach((ref, index) => {
					if (value != null) {
						value = value[ref];
					} else {
						value = index === refs.length - 1 ? '' : {};
					}
				});

				// Split content at match
				const [beginning, ...end] = dynamicMessage[dynamicMessage.length - 1].split(match);
				const newEnd = end.join(match);

				// Set new content
				dynamicMessage[dynamicMessage.length - 1] = beginning;
				dynamicMessage.push(
					<Typography key={match} variation="2" weight="bold">
						{value}
					</Typography>
				);
				dynamicMessage.push(newEnd);
			});
		}
	}

	// Return dynamic content
	return dynamicMessage;
};

export const formatNumberWithCommas = (n) => n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
