import styled, { css } from 'styled-components';

import colors from '../../../styles/colors';
import { IconButton } from '../../IconButton';

export const Topbar = styled.header`
	display: flex;
	background-color: white;
	border-bottom: 1px solid ${colors.neutralForm};
	padding: 1rem 0.5rem;
	position: relative;
`;

export const TopbarWrapper = styled.div`
	display: flex;
	align-items: center;
	padding: 0 0.5rem;

	@media screen and (max-width: 767px) {
		flex: 0.5;
	}

	.mobile-only {
		display: block;
	}
	.desktop-only {
		display: none;
		margin-left: 0;
	}

	@media screen and (min-width: 768px) {
		.mobile-only {
			display: none;
		}
		.desktop-only {
			display: block;
		}
	}

	${({ fullWidth }) =>
		fullWidth &&
		css`
			justify-content: center;
			flex: 1;
		`}

	& > * + * {
		margin-left: 1rem;
	}

	&:last-child {
		justify-content: flex-end;
	}

	.menu-button {
		color: ${colors.brandPrimary};
	}
`;

export const Picture = styled.picture`
	display: flex;
	max-width: 5.9375rem;

	img {
		width: 100%;
		object-fit: contain;
	}

	@media screen and (min-width: 768px) {
		display: none;
	}
`;

export const Avatar = styled.picture`
	width: 1.875rem;
	min-width: 1.875rem;
	height: 1.875rem;
	min-height: 1.875rem;
	border-radius: 9999px;
	overflow: hidden;
	background-color: ${colors.neutralButton};

	img {
		width: 100%;
		height: 100%;
		max-width: 100%;
		object-fit: cover;
	}
`;

export const AvatarDropdown = styled.button`
	display: flex;
	align-items: center;
	border: none;
	background-color: transparent;
	cursor: pointer;
	appearance: none;

	.avatar-name,
	.angle-down {
		display: none;

		@media screen and (min-width: 768px) {
			display: block;
		}
	}

	.angle-down {
		color: ${colors.brandPrimary};
		width: 1rem;
		height: 1rem;
	}

	& > * + * {
		margin-left: 0.5rem;
	}

	&:hover {
		opacity: 0.8;
	}
`;

export const MenuWrapper = styled.div`
	position: relative;
`;

export const MenuItems = styled.div`
	position: absolute;
	right: 0;
	top: calc(100% + 0.5rem);
	background-color: white;
	outline: none;
	width: 12.75rem;
	border-radius: 0.375rem;
	box-shadow: ${colors.normalShadow};
	display: grid;
	grid-template-columns: 1fr;
	gap: 0.25rem;
	z-index: 1;
	padding: 0.5rem 1rem;

	.menu-item {
		display: block;
		text-decoration: none;
		color: black;
		font-size: 0.75rem;
		line-height: 1.5rem;
		font-weight: 400;
		height: 1.5rem;
		text-align: left;
		appearance: none;
		background-color: transparent;
		border: none;
		cursor: pointer;

		&:hover {
			opacity: 0.8;
		}
	}
`;
