import styled, { css } from 'styled-components';

import colors from '../../../styles/colors';

export const Form = styled.form`
	position: relative;

	.close-button {
		position: absolute;
		top: 1rem;
		right: 1rem;
		color: ${colors.neutralButton};
	}

	.modal-content {
		display: grid;
		grid-template-columns: 1fr;
		gap: 2rem;
		padding: 4rem 0 0;

		&__header {
			display: grid;
			grid-template-columns: 1fr;
			gap: 0.5rem;
			margin-bottom: 1rem;
		}

		&__section {
			display: grid;
			grid-template-columns: 1fr;
			gap: 1.5rem;
			margin: 0 auto;
			padding: 0 1rem;
			width: 100%;
			max-width: 41.25rem;

			.files-wrapper {
				display: grid;
				grid-template-columns: 1fr;
				gap: 1rem;
				margin-top: 1rem;

				header {
					display: flex;
					justify-content: space-between;
					align-items: center;

					button {
						color: ${colors.neutralText};
					}
				}

				&__item {
					display: grid;
					grid-template-columns: 1fr;
					gap: 1rem;
					padding-bottom: 1.5rem;
					border-bottom: 1px solid ${colors.neutralForm};

					&__info {
						h6 {
							color: ${colors.neutralText};
							margin-bottom: 0.375em;
						}
					}

					&__details {
						display: flex;
						align-items: center;

						& > div {
							margin-left: 0.5rem;

							& > p:last-child {
								color: ${colors.neutralText};
							}
						}
					}

					&:last-child {
						padding-bottom: 0;
						border-bottom: none;
					}
				}
			}

			.fields-wrapper {
				display: grid;
				grid-template-columns: 1fr;
				gap: 1.5rem;
				width: 100%;
				max-width: 41rem;
				margin: 1.875rem auto 0;

				h6.gray-text {
					margin-top: 0.25rem;
				}
				.gray-text {
					color: ${colors.neutralText};
				}

				.files-wrapper {
					display: grid;
					grid-template-columns: 1fr;
					gap: 1rem;

					header {
						display: flex;
						justify-content: space-between;
						align-items: center;

						button {
							color: ${colors.neutralText};
						}
					}

					&__item {
						display: grid;
						grid-template-columns: 1fr;
						gap: 1rem;
						padding-bottom: 1.5rem;
						border-bottom: 1px solid ${colors.neutralForm};

						&__info {
							h6 {
								color: ${colors.neutralText};
								margin-bottom: 0.375em;
							}
						}

						&__details {
							display: flex;
							align-items: center;

							& > div {
								margin-left: 0.5rem;

								& > p:last-child {
									color: ${colors.neutralText};
								}
							}
						}

						&:last-child {
							padding-bottom: 0;
							border-bottom: none;
						}
					}
				}

				.two-col {
					display: grid;
					gap: 1.25rem;
					grid-template-columns: 1fr;

					@media (min-width: 992px) {
						grid-template-columns: 1fr 1fr;
					}
				}

				.review {
					&__step-item {
						display: grid;
						grid-template-columns: 1fr;
						gap: 1.5rem;
						margin-top: 1rem;

						&:first-child {
							margin-top: 0;
						}
					}

					&__info-item {
						& > h6 {
							color: ${colors.neutralText};
							margin-bottom: 0.375em;
						}

						&--with-badge {
							& > h6 {
								margin-bottom: calc(0.375em + 0.25rem);
							}
						}

						.badge-wrapper {
							display: flex;
							flex-wrap: wrap;
							margin: -0.25rem;

							.small-badge {
								padding: 0.25rem 0.5rem;
								border-radius: 6.25rem;
								border: 1px solid ${colors.brandPrimary};
								font-size: 0.75rem;
								color: ${colors.brandPrimary};
								font-weight: 700;
								margin: 0.25rem;
							}
						}
					}

					&__overview {
						& > h3 {
							margin-bottom: -1rem;
						}
					}

					&__cover-image {
						border: 1px solid ${colors.neutralForm};
						border-radius: 0.375rem;
					}

					&__file-item {
						display: grid;
						grid-template-columns: 1fr;
						gap: 1rem;
					}

					&__file-detail {
						display: flex;
						align-items: center;

						& > svg {
							color: ${colors.neutralText};
						}

						& > div {
							margin-left: 0.5rem;

							& > p:last-child {
								color: ${colors.neutralText};
							}
						}
					}

					&__location-item {
						display: flex;

						.location-icon-wrapper {
							display: flex;
							justify-content: center;
							margin-right: 0.5rem;
							width: 1.5rem;
							color: black;
						}

						.location-info-wrapper {
							flex: 1;
							display: grid;
							grid-template-columns: 1fr;
							gap: 1rem;

							.custom-margin {
								margin-top: 1rem;
							}
						}
					}
				}
			}

			.radio-wrapper {
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
				width: 100%;
				margin: 1rem auto 0;
				position: relative;

				.radio-field {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					position: relative;
					width: 100%;
					max-width: 16rem;
					height: 16rem;
					padding: 1rem;
					margin: 0.75rem;
					color: ${colors.neutralText};
					z-index: 0;

					&.template-field {
						min-height: 16rem;
						height: auto;
						max-height: none;
					}

					h5 {
						color: black;
					}

					h5,
					p {
						pointer-events: none;
						z-index: 1;
					}

					& > * + * {
						margin-top: 0.5rem;
					}

					svg {
						pointer-events: none;
						margin-bottom: 0.5rem;
						z-index: 1;
					}

					.radio-input {
						position: absolute;
						border: 1px solid ${colors.neutralForm};
						border-radius: 0.375rem;
						appearance: none;
						width: 100%;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						inset: 0;
						cursor: pointer;

						&:checked {
							background-color: rgba(234, 236, 244, 0.4);
							border-width: 2px;
							border-color: ${colors.brandPrimary};
						}
					}

					&.template {
						justify-content: flex-start;
						align-items: flex-start;
						padding: 0.75rem;

						.thumbnail {
							pointer-events: none;
							border-radius: 0.375rem;
							margin-top: 0;
						}

						p {
							margin-top: 0.125rem;
						}
					}

					&.with-error {
						color: ${colors.stateDanger};

						h5 {
							color: ${colors.stateDanger};
						}

						.radio-input {
							border-color: ${colors.stateDanger};
						}
					}

					.radio-input:checked ~ svg,
					.radio-input:checked ~ h5 {
						color: ${colors.brandPrimary};
					}
					.radio-input:checked ~ p {
						color: black;
					}
				}

				.error-wrapper {
					position: absolute;
					bottom: -0.75rem;
					color: ${colors.stateDanger};
					left: 0.75rem;
				}
			}
		}
	}

	.modal-footer {
		display: flex;
		justify-content: flex-end;
		padding: 1.5rem;
		border-top: 1px solid ${colors.neutralForm};
	}
`;

export const HugeImageUploader = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;

	label {
		color: ${colors.neutralText};
		cursor: pointer;
	}

	.reset-btn {
		appearance: none;
		border: none;
		border-radius: 0.375rem;
		cursor: pointer;
		background-color: ${colors.brandPrimary};
		color: white;
		display: grid;
		place-content: center;
		width: 2.25rem;
		height: 2.25rem;
		position: absolute;
		top: 2.25rem;
		right: 1rem;

		svg {
			width: 1.125rem;
			height: 1.125rem;
		}

		&:hover {
			opacity: 0.8;
		}
	}

	${({ withError }) =>
		withError &&
		css`
			label {
				color: ${colors.stateDanger};
			}

			${FileDropZone} {
				border-color: ${colors.stateDanger};
			}
		`}

	.error-wrapper {
		position: absolute;
		bottom: -1rem;
		color: ${colors.stateDanger};
	}
`;

export const FileDropZone = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	border: 1px dashed ${colors.neutralText};
	height: ${({ alternative }) => (alternative ? '12.5rem' : '25rem')};
	border-radius: 0.375rem;
	cursor: pointer;

	overflow: hidden;

	img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

	.upload-placeholder {
		display: grid;
		grid-template-columns: 1fr;
		gap: 0.5rem;

		&__picture {
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 0.375rem;
			padding: 0.5rem;
			width: 100%;
			max-width: 8.25rem;
			margin: 1rem auto 0;
			background-color: ${colors.neutralMessage};
			color: ${colors.brandPrimary};
		}

		&__paragraph {
			color: ${colors.neutralText};

			strong {
				color: ${colors.brandPrimary};
			}
		}
	}

	${({ isDragActive }) =>
		isDragActive &&
		css`
			opacity: 0.8;
		`}

	${({ isDragAccept }) =>
		isDragAccept &&
		css`
			border-color: ${colors.stateSuccess};
		`}

	${({ isDragReject }) =>
		isDragReject &&
		css`
			border-color: ${colors.stateDanger};
		`}
`;

export const CheckboxWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 1rem;
	position: relative;

	.field-label {
		color: ${colors.neutralText};

		&.with-error {
			color: ${colors.stateDanger};
		}
	}

	.checkbox-field {
		display: flex;
		align-items: center;

		& > * + * {
			padding-left: 0.75rem;
		}

		label {
			font-size: 1rem;
			line-height: 1.375rem;
			font-weight: 400;
			color: black;
			cursor: pointer;
		}
	}

	.error-wrapper {
		color: ${colors.stateDanger};
	}

	@media screen and (min-width: 768px) {
		grid-template-columns: 1fr 1fr;

		.field-label,
		.error-wrapper {
			grid-column: 1 / 3;
		}
	}
`;
