import { configureStore } from '@reduxjs/toolkit';

import { loadBusinessDashboardState } from './slices/businessDashboard/businessDashboardSlice';
import userReducer from './slices/user/userSlice';
import menuReducer from './slices/menu/menuSlice';
import businessDashboardReducer from './slices/businessDashboard/businessDashboardSlice';
import filterSearchSlice from './slices/filterSearch/filterSearchSlice';

export default configureStore({
	reducer: {
		user: userReducer,
		menu: menuReducer,
		businessDashboard: businessDashboardReducer,
		filterSearch: filterSearchSlice,
	},
	preloadedState: {
		businessDashboard: loadBusinessDashboardState(),
	},
});
