import React, { createContext, forwardRef, useReducer, useImperativeHandle, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';

import { AGE_GROUPS, CAREER_CLUSTERS, COUNTY_OPTIONS, CURRICULUM_STANDARDS, EMPLOYABILITY_SKILLS, LOCATION_TYPES, RESOURCE_CATEGORIES } from '../../../constants/general.constants';
import { Select } from '../Select';
import { TextInput } from '../TextInput';
import { Typography } from '../Typography';
import { DistanceFilter } from './DistanceFilter';
import { CountyFilter } from './CountyFilter';
import { LocationTypeFilter } from './LocationTypeFilter';
import { ResourceCategoryFilter } from './ResourceCategoryFilter';
import { CareerClusterFilter } from './CareerClusterFilter';
import { AgeGroupFilter } from './AgeGroupFilter';
import { EmployabilitySkillsFilter } from './EmployabilitySkillsFilter';
import { CurriculumStandardsFilter } from './CurriculumStandardsFilter';
import * as S from './FilterSection.styles';
import { Button } from '../Button';
import { ButtonWrapper } from '../MessageDialog/MessageDialog.styles';
import { useDispatch, useSelector } from 'react-redux';
import { saveState } from '../../app/slices/filterSearch/filterSearchSlice';

export const FilterContext = createContext();

const reducer = (state, action) => {
	switch (action.type) {
		case 'REPLACE_STATE':
			return action.payload;
		case 'SET_SEARCH_TEXT':
			return {
				...state,
				searchText: action.payload,
			};
		case 'SET_SORT_OPTION':
			return {
				...state,
				sortOption: action.payload,
			};
		case 'SET_LOCATION_TYPES':
			return {
				...state,
				locationTypes: action.payload,
			};
		case 'SET_COUNTIES':
			return {
				...state,
				counties: action.payload,
			};
		case 'SET_CATEGORIES':
			return {
				...state,
				categories: action.payload,
			};
		case 'SET_CAREER_CLUSTERS':
			return {
				...state,
				careerClusters: action.payload,
			};
		case 'SET_AGE_GROUPS':
			return {
				...state,
				ageGroups: action.payload,
			};
		case 'SET_EMPLOYABILITY_SKILLS':
			return {
				...state,
				employabilitySkills: action.payload,
			};
		case 'SET_CURRICULUM_STANDARDS':
			return {
				...state,
				curriculumStandards: action.payload,
			};
		case 'TOGGLE_SHOW_ADVANCED_FILTERS':
			return {
				...state,
				showAdvancedFilters: !state.showAdvancedFilters,
			};
		case 'RESET_ACTIVE_FILTERS':
			return {
				...state,
				activeFilters: [],
			};
		case 'ADD_TO_ACTIVE_FILTERS':
			return {
				...state,
				activeFilters: [...state.activeFilters, action.payload],
			};
		case 'REMOVE_FROM_ACTIVE_FILTERS':
			return {
				...state,
				activeFilters: state.activeFilters.filter((filter) => filter !== action.payload),
			};
		case 'SET_DISTANCE':
			return {
				...state,
				distance: action.payload,
			};
		default:
			return state;
	}
};

export const FilterSection = forwardRef(({ initialState = {}, onChangeCallback = () => {}, sortOptions = [], showFilters = true, filterOptions = ['distance', 'counties', 'locationTypes', 'categories', 'careerClusters', 'ageGroups', 'employabilitySkills', 'curriculumStandards'], action }, ref) => {
	const filterSearchSavedStates = useSelector((state) => state.filterSearch.value);

	const dispatcher = useDispatch();
	const [state, dispatch] = useReducer(reducer, initialState);
	const dispatchAndTriggerOnChange = (action) => {
		dispatch(action);
		onChangeCallback();
	};

	const toggleAdvancedFilters = () => dispatch({ type: 'TOGGLE_SHOW_ADVANCED_FILTERS' });

	useEffect(() => {
		const filterState = (filterSearchSavedStates || {})[window.location.pathname];
		if (filterState) {
			dispatch({ type: 'REPLACE_STATE', payload: filterState });
			onChangeCallback();
		}
	}, []);

	useEffect(() => {
		if (_.isEqual(state, initialState)) {
			dispatcher(saveState(null));
		} else {
			dispatcher(saveState(state));
		}
	}, [state]);

	useImperativeHandle(
		ref,
		() => {
			const value = {
				searchText: state?.searchText,
				sortOption: state?.sortOption?.value,
				searchOptions: {},
			};
			if (filterOptions.includes('distance')) {
				value.searchOptions.distance = state?.distance;
			}
			if (filterOptions.includes('counties')) {
				value.searchOptions.counties = state?.counties?.map((item) => item?.code);
			}
			if (filterOptions.includes('locationTypes')) {
				value.searchOptions.locationTypes = state?.locationTypes?.map((item) => item?.id);
			}
			if (filterOptions.includes('categories')) {
				value.searchOptions.categories = state?.categories?.map((item) => item?.value);
			}
			if (filterOptions.includes('careerClusters')) {
				value.searchOptions.careerClusters = state?.careerClusters?.map((item) => item?.value);
			}
			if (filterOptions.includes('ageGroups')) {
				value.searchOptions.ageGroups = state?.ageGroups?.map((item) => item?.value);
			}
			if (filterOptions.includes('employabilitySkills')) {
				value.searchOptions.employabilitySkills = state?.employabilitySkills?.map((item) => item?.value);
			}
			if (filterOptions.includes('curriculumStandards')) {
				value.searchOptions.curriculumStandards = state?.curriculumStandards?.map((item) => item?.value);
			}

			return {
				value,
				setDistance: (distance) => {
					dispatchAndTriggerOnChange({ type: 'SET_DISTANCE', payload: distance });
					if (distance > 0) {
						dispatch({ type: 'ADD_TO_ACTIVE_FILTERS', payload: 'distance' });
					}
				},
				setCounties: (counties) => {
					const payload = COUNTY_OPTIONS.filter((item) => counties.includes(item.code));
					dispatchAndTriggerOnChange({ type: 'SET_COUNTIES', payload });
					if (payload.length > 0) {
						dispatch({ type: 'ADD_TO_ACTIVE_FILTERS', payload: 'counties' });
					}
				},
				setLocationTypes: (locationTypes) => {
					const payload = LOCATION_TYPES.filter((item) => locationTypes.includes(item.id));
					dispatchAndTriggerOnChange({ type: 'SET_LOCATION_TYPES', payload });
					if (payload.length > 0) {
						dispatch({ type: 'ADD_TO_ACTIVE_FILTERS', payload: 'locationTypes' });
					}
				},
				setCategories: (categories) => {
					const payload = RESOURCE_CATEGORIES.APPOINTMENT.filter((item) => categories.includes(item.value));
					dispatchAndTriggerOnChange({ type: 'SET_CATEGORIES', payload });
					if (payload.length > 0) {
						dispatch({ type: 'ADD_TO_ACTIVE_FILTERS', payload: 'categories' });
					}
				},
				setCareerClusters: (careerClusters) => {
					const payload = CAREER_CLUSTERS.filter((item) => careerClusters.includes(item.value));
					dispatchAndTriggerOnChange({ type: 'SET_CAREER_CLUSTERS', payload });
					if (payload.length > 0) {
						dispatch({ type: 'ADD_TO_ACTIVE_FILTERS', payload: 'careerClusters' });
					}
				},
				setAgeGroups: (ageGroups) => {
					const payload = AGE_GROUPS.filter((item) => ageGroups.includes(item.value));
					dispatchAndTriggerOnChange({ type: 'SET_AGE_GROUPS', payload });
					if (payload.length > 0) {
						dispatch({ type: 'ADD_TO_ACTIVE_FILTERS', payload: 'ageGroups' });
					}
				},
				setEmployabilitySkills: (employabilitySkills) => {
					const payload = EMPLOYABILITY_SKILLS.filter((item) => employabilitySkills.includes(item.value));
					dispatchAndTriggerOnChange({ type: 'SET_EMPLOYABILITY_SKILLS', payload });
					if (payload.length > 0) {
						dispatch({ type: 'ADD_TO_ACTIVE_FILTERS', payload: 'employabilitySkills' });
					}
				},
				setCurriculumStandards: (curriculumStandards) => {
					const payload = CURRICULUM_STANDARDS.filter((item) => curriculumStandards.includes(item.value));
					dispatchAndTriggerOnChange({ type: 'SET_CURRICULUM_STANDARDS', payload });
					if (payload.length > 0) {
						dispatch({ type: 'ADD_TO_ACTIVE_FILTERS', payload: 'curriculumStandards' });
					}
				},
				resetFilterCount: () => dispatch({ type: 'RESET_ACTIVE_FILTERS' }),
			};
		},
		[state, filterOptions]
	);

	return (
		<FilterContext.Provider value={{ state, dispatch }}>
			<S.Wrapper className={showFilters ? 'grid-1' : 'grid-2'} showFilters={showFilters} action={action}>
				<S.FieldWrapper className="search-input">
					<TextInput value={state?.searchText} onChange={(e) => dispatchAndTriggerOnChange({ type: 'SET_SEARCH_TEXT', payload: e.target.value })} type="search" icon={['fal', 'search']} size="small" placeholder="Search" />
				</S.FieldWrapper>
				<S.RightSide className="sortby-select">
					<S.FieldWrapper>
						<Typography tag="label" weight="bold">
							Sort By:
						</Typography>
						<Select value={state?.sortOption} onChange={(value) => dispatchAndTriggerOnChange({ type: 'SET_SORT_OPTION', payload: value })} options={sortOptions} size="small" placeholder="Sort By" />
					</S.FieldWrapper>
					{action && (
						<Button onClick={action.onClick} size={action.size === 'smaller' ? 'small' : action.size} variant={action.variant} variation={action.variation}>
							{action.icon && action.icon.placement !== 'right' && <FontAwesomeIcon icon={action.icon.source} />}
							<Typography variation={action.size === 'smaller' ? 'button-small' : 'button-medium'} weight="extrablack">
								{action.label}
							</Typography>
							{action.icon && action.icon.placement === 'right' && <FontAwesomeIcon icon={action.icon.source} size={action.size === 'smaller' ? 'sm' : '1x'} />}
						</Button>
					)}
				</S.RightSide>
				{showFilters && (
					<S.FilterButton className="filter-button" isOpen={state?.showAdvancedFilters} isActive={!!state?.activeFilters?.length} onClick={toggleAdvancedFilters}>
						<div className="filter-counter">{state?.activeFilters?.length}</div>
						<FontAwesomeIcon icon={['fal', 'sliders-h']} />
						<Typography tag="p" variation="2">
							Filter
						</Typography>
					</S.FilterButton>
				)}
				<S.AdvancedFiltersWrapper className="advanced-fields" isOpen={state?.showAdvancedFilters}>
					{filterOptions.includes('distance') && <DistanceFilter dispatch={dispatchAndTriggerOnChange} />}
					{filterOptions.includes('counties') && <CountyFilter dispatch={dispatchAndTriggerOnChange} />}
					{filterOptions.includes('locationTypes') && <LocationTypeFilter dispatch={dispatchAndTriggerOnChange} />}
					{filterOptions.includes('categories') && <ResourceCategoryFilter dispatch={dispatchAndTriggerOnChange} />}
					{filterOptions.includes('careerClusters') && <CareerClusterFilter dispatch={dispatchAndTriggerOnChange} />}
					{filterOptions.includes('ageGroups') && <AgeGroupFilter dispatch={dispatchAndTriggerOnChange} />}
					{filterOptions.includes('employabilitySkills') && <EmployabilitySkillsFilter dispatch={dispatchAndTriggerOnChange} />}
					{filterOptions.includes('curriculumStandards') && <CurriculumStandardsFilter dispatch={dispatchAndTriggerOnChange} />}
				</S.AdvancedFiltersWrapper>
			</S.Wrapper>
		</FilterContext.Provider>
	);
});

FilterSection.displayName = 'FilterSection';

FilterSection.propTypes = {
	initialState: PropTypes.shape({
		searchText: PropTypes.string,
		sortOption: PropTypes.shape({
			value: PropTypes.string,
			label: PropTypes.string,
		}),
		showAdvancedFilters: PropTypes.bool,
		activeFilters: PropTypes.arrayOf(PropTypes.string),
		counties: PropTypes.arrayOf(
			PropTypes.shape({
				code: PropTypes.string,
				name: PropTypes.string,
			})
		),
		locationTypes: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.string,
				name: PropTypes.string,
			})
		),
		categories: PropTypes.arrayOf(
			PropTypes.shape({
				value: PropTypes.string,
				label: PropTypes.string,
			})
		),
		careerClusters: PropTypes.arrayOf(
			PropTypes.shape({
				value: PropTypes.string,
				label: PropTypes.string,
			})
		),
		ageGroups: PropTypes.arrayOf(
			PropTypes.shape({
				value: PropTypes.string,
				label: PropTypes.string,
			})
		),
		employabilitySkills: PropTypes.arrayOf(
			PropTypes.shape({
				value: PropTypes.string,
				label: PropTypes.string,
			})
		),
		curriculumStandards: PropTypes.arrayOf(
			PropTypes.shape({
				value: PropTypes.string,
				label: PropTypes.string,
			})
		),
		distance: PropTypes.number,
	}),
	filterOptions: PropTypes.arrayOf(PropTypes.string),
	onChangeCallback: PropTypes.func,
	sortOptions: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			value: PropTypes.string,
		})
	),
	action: PropTypes.shape({
		onClick: PropTypes.func,
		label: PropTypes.string,
		size: PropTypes.oneOf(['medium', 'small', 'smaller']),
		variant: PropTypes.oneOf(['solid', 'outline', 'text']),
		variation: PropTypes.oneOf(['default', 'secondary', 'warning']),
		icon: PropTypes.shape({
			source: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
			placement: PropTypes.oneOf(['left', 'right']),
		}),
	}),
	showFilters: PropTypes.bool,
};
