import React, { useState, useEffect } from 'react';

import { PageHeader, Tab, AdminNavigation } from '../../components';

import * as S from './AdminMessages.styles';
import { META_TITLE } from '../../../constants/general.constants';
import { ReceivedBookingsTab } from './ReceivedBookingsTab';
import { PostedRequestsTab } from './PostedRequestsTab';

const AdminMessages = () => {
	useEffect(() => {
		// Set document title
		document.title = `Messages | Dashboard | ${META_TITLE}`;
	}, []);

	const [tabs, setTabs] = useState([
		{
			id: 1,
			label: 'Bookings',
			component: <ReceivedBookingsTab />,
		},
		{
			id: 2,
			label: 'Requests',
			component: <PostedRequestsTab />,
		},
	]);

	return (
		<AdminNavigation>
			<PageHeader title="Messages" />
			<S.Wrapper>
				<Tab tabs={tabs} setTabs={setTabs} />
			</S.Wrapper>
		</AdminNavigation>
	);
};

export default AdminMessages;
