import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Badge, Button, Select, TextInput, Typography } from '../../../../components';
import { RequestsContext, TABS_OPTIONS } from '../RespondedRequestsTab';
import * as S from './FilterSection.styles';

const BADGE_STATUS = {
	pending: {
		label: 'Pending',
		color: 'primary',
	},
	scheduled: {
		label: 'Scheduled',
		color: 'neutral',
	},
	draft: {
		label: 'Draft',
		color: 'neutral',
	},
	complete: {
		label: 'Completed',
		color: 'success',
	},
	incomplete: {
		label: 'Incomplete',
		color: 'danger',
	},
	archived: {
		label: 'Archived',
		color: 'neutral',
	},
};

export const FilterSection = () => {
	const { state, dispatch } = useContext(RequestsContext);
	const { selectedTab, searchInputValue } = state;

	return (
		<S.Wrapper>
			<div className="filter-wrapper">
				<div className="filter-wrapper__badge-wrapper">
					{TABS_OPTIONS.map((option) => (
						<button key={option.value} className={`badge-button${selectedTab.value === option.value ? ' selected' : ''}`} onClick={() => dispatch({ type: 'SET_SELECTED_TAB', payload: option })}>
							<Typography tag="h6">{option.label}</Typography>
						</button>
					))}
				</div>
				<div className="filter-wrapper__search-input-wrapper">
					<TextInput value={searchInputValue} size="small" placeholder="Search" icon={['fal', 'search']} onChange={(e) => dispatch({ type: 'SET_SEARCH_INPUT_VALUE', payload: e.target.value })} />
				</div>
			</div>
		</S.Wrapper>
	);
};
