import React, { createContext, useReducer, useState, useEffect, useRef } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { BOOKING_STATUSES, META_TITLE, ROLES, SOURCES } from '../../../constants/general.constants';
import { fetchBooking, archiveUnarchiveBooking, cancelBooking, completeBooking } from '../../services/bookings';
import { recordView } from '../../services/metrics';
import { Spinner, TeacherNavigation, Tab, DetailPageHeader, Button, Typography, ErrorComponent, MessageDialog, BusinessNavigation, AdminNavigation, CompleteBookingModal, ScheduleBookingModal, DeclineBookingModal } from '../../components';
import { OverviewTab } from './OverviewTab';
import { MessagesTab } from './MessagesTab';
import { AppointmentTab } from './AppointmentTab';
import * as S from './BookingDetail.styles';
import toast from 'react-hot-toast';

export const BookingDetailsContext = createContext();
const initialState = {
	booking: {},
};
function reducer(state, action) {
	switch (action.type) {
		case 'setBooking':
			return {
				...state,
				booking: action.payload,
			};
		default:
			return state;
	}
}

const BookingDetail = ({ role }) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const { booking } = state;
	const [archiveIsOpen, setArchiveIsOpen] = useState(false);
	const [cancelIsOpen, setCancelIsOpen] = useState(false);
	const [completeConfirmIsOpen, setCompleteConfirmIsOpen] = useState(false);
	const { id } = useParams();
	const history = useHistory();
	const location = useLocation();
	const [tabs, setTabs] = useState([
		{
			id: 1,
			label: 'Appointment',
			component: <AppointmentTab role={role} />,
		},
		{
			id: 2,
			label: 'Messages',
			component: <MessagesTab role={role} />,
		},
		{
			id: 3,
			label: 'Overview',
			component: <OverviewTab role={role} />,
		},
	]);
	const [pageStatus, setPageStatus] = useState('idle');

	const backUrl = location.state?.backUrl || `/${role || 'teacher'}/bookings`;
	const backTitle = location.state?.backTitle || 'Back to Bookings';

	const scheduleBookingModalRef = useRef(null);
	const completeBookingModalRef = useRef(null);
	const declineBookingModalRef = useRef(null);

	function handleOpenArchiveModal() {
		setArchiveIsOpen(true);
	}

	function handleCloseArchiveModal() {
		setArchiveIsOpen(false);
	}

	function handleOpenCancelModal() {
		setCancelIsOpen(true);
	}

	function handleCloseCancelModal() {
		setCancelIsOpen(false);
	}

	function handleOpenCompleteConfirmModal() {
		setCompleteConfirmIsOpen(true);
	}

	function handleCloseCompleteConfirmModal() {
		setCompleteConfirmIsOpen(false);
	}

	async function handleCompleteConfirm() {
		try {
			const {
				data: { result },
			} = await completeBooking({ bookingId: id });
			dispatch({ type: 'setBooking', payload: result.booking });
			toast.success('Your booking has been successfully completed.');
			handleCloseCompleteConfirmModal();
		} catch (error) {}
	}

	const handleArchiveUnarchive = async () => {
		setArchiveIsOpen(false);
		try {
			const {
				data: { result },
			} = await archiveUnarchiveBooking({ bookingId: id });
			dispatch({ type: 'setBooking', payload: result.booking });
		} catch (error) {}
	};

	const handleCancelBooking = async () => {
		setCancelIsOpen(false);
		try {
			await cancelBooking({ bookingId: id });
			history.push(`/${role || 'teacher'}/bookings`);
		} catch (error) {}
	};

	async function initializeData() {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchBooking({ bookingId: id });
			dispatch({ type: 'setBooking', payload: result.booking });
			setTabs((prev) => {
				const newTabs = [...prev];
				return newTabs;
			});
			setPageStatus('success');
			await recordView({ sourceType: SOURCES.BOOKING, sourceId: id });
		} catch (error) {
			setPageStatus('error');
		}
	}

	useEffect(() => {
		// Set document title
		document.title = `Booking Details | Dashboard | ${META_TITLE}`;

		// Load initial data
		if (pageStatus === 'idle') {
			initializeData();
		}
	}, [pageStatus]);

	const renderButtons = (booking) => {
		if (booking.status === BOOKING_STATUSES.PENDING) {
			return (
				<>
					{booking.isCreator ? (
						<Button
							variant="outline"
							onClick={() => {
								handleOpenCancelModal();
							}}
						>
							<Typography weight="bold" variation="button-medium">
								Cancel
							</Typography>
						</Button>
					) : (
						<Button
							variant="outline"
							variation="warning"
							onClick={() => {
								declineBookingModalRef.current.open(id);
							}}
						>
							<Typography weight="bold" variation="button-medium">
								Decline
							</Typography>
						</Button>
					)}
					<Button onClick={() => scheduleBookingModalRef.current.open(id)}>
						<Typography weight="bold" variation="button-medium">
							Schedule
						</Typography>
					</Button>
					<ScheduleBookingModal ref={scheduleBookingModalRef} role={role} context={BookingDetailsContext} />
					<DeclineBookingModal ref={declineBookingModalRef} role={role} dispatch={dispatch} />
				</>
			);
		} else if (booking.status === BOOKING_STATUSES.SCHEDULED) {
			return (
				<>
					<Button
						variant="outline"
						onClick={() => {
							handleOpenCancelModal();
						}}
					>
						<Typography weight="bold" variation="button-medium">
							Cancel
						</Typography>
					</Button>
					<Button onClick={() => (booking.isCreator ? completeBookingModalRef.current.open(id) : handleOpenCompleteConfirmModal())}>
						<Typography weight="bold" variation="button-medium">
							Complete
						</Typography>
					</Button>
					<CompleteBookingModal ref={completeBookingModalRef} role={role} context={BookingDetailsContext} />
					<MessageDialog
						isOpen={completeConfirmIsOpen}
						onRequestClose={handleCloseCompleteConfirmModal}
						title="Complete Booking"
						subtitle="Are you sure you want to complete this booking?"
						icon={['fal', 'envelope']}
						actions={[
							{ id: 'back', label: 'Cancel', variant: 'outline', variation: 'default' },
							{ id: 'cancel', label: 'Complete', variant: 'solid', variation: 'default', onClick: handleCompleteConfirm },
						]}
					/>
				</>
			);
		} else if (booking.status === BOOKING_STATUSES.COMPLETE || booking.status === BOOKING_STATUSES.INCOMPLETE) {
			return (
				<>
					<Button
						onClick={() => {
							handleOpenArchiveModal();
						}}
					>
						<Typography weight="bold" variation="button-medium">
							Archive
						</Typography>
					</Button>
				</>
			);
		} else if (booking.status === BOOKING_STATUSES.ARCHIVED) {
			return (
				<>
					<Button
						onClick={() => {
							handleOpenArchiveModal();
						}}
					>
						<Typography weight="bold" variation="button-medium">
							Unarchive
						</Typography>
					</Button>
				</>
			);
		}
	};

	const renderContent = () => {
		switch (pageStatus) {
			case 'loading':
				return <Spinner />;
			case 'error':
				return <ErrorComponent />;
			case 'success':
				return (
					<>
						<DetailPageHeader title={booking.resource.title} subtitle={booking.resource.business.name} badge={booking.status[0].toUpperCase() + booking.status.slice(1)} backButton={{ label: backTitle, onClick: () => history.push(backUrl) }}>
							{renderButtons(booking)}
						</DetailPageHeader>
						<S.Wrapper>
							<Tab tabs={tabs} noPadding={true} setTabs={setTabs} />
						</S.Wrapper>
						<MessageDialog
							isOpen={archiveIsOpen}
							onRequestClose={handleCloseArchiveModal}
							title={booking.status !== BOOKING_STATUSES.ARCHIVED ? 'Archive Booking' : 'Unarchive Booking'}
							subtitle={booking.status !== BOOKING_STATUSES.ARCHIVED ? 'Are you sure you want to archive this booking? You will be able to unarchive it later.' : 'Are you sure you want to unarchive this booking?'}
							icon={['fal', 'envelope']}
							actions={[
								{ id: 'back', label: 'Cancel', variant: 'outline', variation: 'default' },
								{ id: 'archiveUnarchive', label: booking.status !== BOOKING_STATUSES.ARCHIVED ? 'Archive Booking' : 'Unarchive Booking', variant: 'solid', variation: 'default', onClick: handleArchiveUnarchive },
							]}
						/>
						<MessageDialog
							isOpen={cancelIsOpen}
							onRequestClose={handleCloseCancelModal}
							title="Cancel Booking?"
							subtitle="Are you sure you want to cancel this booking? The business will be notified as well."
							icon={['fal', 'envelope']}
							actions={[
								{ id: 'back', label: 'Nevermind', variant: 'outline', variation: 'default' },
								{ id: 'cancel', label: 'Cancel Booking', variant: 'solid', variation: 'default', onClick: handleCancelBooking },
							]}
						/>
					</>
				);
			default:
				return null;
		}
	};
	if (role === ROLES.TEACHER) {
		return (
			<TeacherNavigation>
				<BookingDetailsContext.Provider value={{ state, dispatch }}>{renderContent()}</BookingDetailsContext.Provider>
			</TeacherNavigation>
		);
	} else if (role === ROLES.BUSINESS) {
		return (
			<BusinessNavigation>
				<BookingDetailsContext.Provider value={{ state, dispatch }}>{renderContent()}</BookingDetailsContext.Provider>
			</BusinessNavigation>
		);
	}
	return (
		<AdminNavigation>
			<BookingDetailsContext.Provider value={{ state, dispatch }}>{renderContent()}</BookingDetailsContext.Provider>
		</AdminNavigation>
	);
};

BookingDetail.propTypes = {
	role: PropTypes.string,
};

export default BookingDetail;
