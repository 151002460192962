import styled from 'styled-components';

import colors from '../../../../styles/colors';

export const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	gap: 1rem;

	.location-title {
		display: flex;
		flex-direction: row;
		align-items: center;

		p {
			margin-left: 0.5rem;
		}
	}

	.grid-line {
		display: grid;
		grid-template-columns: 1fr;
		gap: 1.25rem;
		padding-bottom: 1.25rem;
		border-bottom: 1px solid ${colors.neutralForm};

		&__info-item {
			& > h6 {
				color: ${colors.neutralText};
				margin-bottom: 0.375em;
			}
		}

		&:last-child {
			padding-bottom: 0.75rem;
			border-bottom: none;
		}

		@media (min-width: 425px) {
			grid-template-columns: repeat(2, 1fr);
		}
		@media (min-width: 768px) {
			grid-template-columns: repeat(3, 1fr);
		}
	}
`;
