import api from '../api';

export const fetchMemberships = (data) => api.post('/core/functions/fetchMemberships', data);

export const fetchMembership = (data) => api.post('/core/functions/fetchMembership', data);

export const addCompaniesToMembership = (data) => api.post('/core/functions/addCompaniesToMembership', data);

export const archiveUnarchiveMembership = (data) => api.post('/core/functions/archiveUnarchiveMembership', data);

export const createUpdateMembership = (data) => api.post('/core/functions/createUpdateMembership', data);

export const removeCompaniesFromMembership = (data) => api.post('/core/functions/removeCompaniesFromMembership', data);
