import React, { useContext, forwardRef, useImperativeHandle, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';
import { useFieldArray, useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { formatPayloadBeforeUpdate } from '../OverviewTab';
import { required, urlOnly } from '../../../../utils/form-default-errors';
import { AppointmentDetailsContext } from '../../RequestsAppointmentDetails';
import { errorHandler } from '../../../../services/authService';
import { createRequest } from '../../../../services/requests/createRequestService';
import { Card, Typography, Button, IconButton, EmptyComponent, TextInput, TextArea } from '../../../../components';
import * as S from './LinksCard.styles';
import { ROLES } from '../../../../../constants/general.constants';

const LinksEditModal = forwardRef((_, ref) => {
	const {
		state: { request },
		dispatch,
	} = useContext(AppointmentDetailsContext);
	const [isOpen, setIsOpen] = useState(false);

	const {
		handleSubmit,
		control,
		register,
		setValue,
		reset,
		formState: { errors, isSubmitting },
	} = useForm();
	const { fields, append, remove } = useFieldArray({ control, name: 'links' });

	const handleAddLink = () => {
		append({});
	};

	const handleRemoveLink = (index) => {
		remove(index);
	};

	const handleOpen = () => {
		setValue('links', request.links);
		setIsOpen(true);
	};

	const handleClose = () => {
		setIsOpen(false);
		setTimeout(() => {
			reset();
		}, 200);
	};

	async function asyncCaller(data) {
		const newRequest = formatPayloadBeforeUpdate(request, { links: data.links });

		const {
			data: { result },
		} = await createRequest(newRequest);
		dispatch({ type: 'setRequest', payload: result.request });
		handleClose();
	}

	const onSubmit = (e) => handleSubmit(asyncCaller)(e).catch(errorHandler);

	useImperativeHandle(ref, () => ({
		open: handleOpen,
		close: handleClose,
	}));

	return (
		<ReactModal className="link-card-modal" ref={ref} closeTimeoutMS={200} isOpen={isOpen} onRequestClose={handleClose}>
			<S.Form onSubmit={onSubmit}>
				<IconButton className="close-button" type="button" icon={['fal', 'times']} onClick={handleClose} />
				<S.InfoWrapper>
					<Typography tag="h2" weight="extrablack" center>
						Edit Links
					</Typography>
					<Typography tag="p" center>
						Add or remove links for this request.
					</Typography>
				</S.InfoWrapper>
				<S.FieldsWrapper>
					{fields?.map((field, index) => (
						<div key={field?.id} className="link-item">
							<Typography tag="h3" weight="bold">
								Link {index + 1}
							</Typography>
							<Button className="link-remove" variant="text" type="button" onClick={() => handleRemoveLink(index)}>
								<Typography variation="button-medium" weight="bold">
									Remove
								</Typography>
							</Button>
							<TextInput
								label="Link"
								id={`${index}-url`}
								error={errors?.links?.[index]?.url}
								{...register(`links.${index}.url`, {
									required: required('Link'),
									pattern: urlOnly('Link'),
								})}
							/>
							<TextInput
								label="Title"
								id={`${index}-title`}
								error={errors?.links?.[index]?.title}
								{...register(`links.${index}.title`, {
									required: required('Title'),
								})}
							/>
							<TextArea
								label="Description"
								id={`${index}-description`}
								error={errors?.links?.[index]?.description}
								{...register(`links.${index}.description`, {
									required: required('Description'),
								})}
							/>
						</div>
					))}
					<Button variant="text" type="button" onClick={handleAddLink}>
						<FontAwesomeIcon icon={['fal', 'plus']} />
						<Typography variation="button-medium" weight="bold">
							Add Link
						</Typography>
					</Button>
				</S.FieldsWrapper>
				<S.ButtonsWrapper>
					<Button type="submit">
						<Typography weight="bold" variation="button-medium">
							{isSubmitting ? 'Loading' : 'Save'}
						</Typography>
					</Button>
				</S.ButtonsWrapper>
			</S.Form>
		</ReactModal>
	);
});
LinksEditModal.displayName = 'LinksEditModal';

export const LinksCard = ({ className, role }) => {
	const {
		state: { request },
	} = useContext(AppointmentDetailsContext);
	const { links } = request;

	const user = useSelector((state) => state.user.value);

	const modalRef = useRef(null);

	const handleEditClick = () => {
		modalRef.current.open();
	};

	return (
		<Card
			title="Links"
			className={className}
			transparentHeaderBorder
			actions={(role != null && request.creator != null && request.creator.id === user?.id) || role === ROLES.ADMIN || role === ROLES.SUPER ? [{ id: 1, label: 'Edit', size: 'small', variant: 'outline', onClick: handleEditClick, icon: { source: ['fal', 'edit'] } }] : []}
		>
			<S.Wrapper>
				{links?.length > 0 &&
					links.map((link) => (
						<div key={link.id} className="link-wrapper">
							<div className="link-wrapper__heading">
								<Typography tag="h5" weight="bold">
									{link.title}
								</Typography>
								<Button
									size="small"
									variant="text"
									onClick={() => {
										window.open(link.url);
									}}
								>
									<FontAwesomeIcon icon={['fal', 'external-link']} />
									<Typography variation="button-small" weight="bold">
										Visit Link
									</Typography>
								</Button>
							</div>
							<div className="link-wrapper__body">
								<div className="link-wrapper__element">
									<Typography className="link-wrapper__title" tag="h6" weight="semibold">
										Link
									</Typography>
									<Typography
										className="link-wrapper__value clickable"
										tag="p"
										onClick={() => {
											window.open(link.url);
										}}
									>
										{link.displayUrl}
									</Typography>
								</div>
								<div className="link-wrapper__element">
									<Typography className="link-wrapper__title" tag="h6" weight="semibold">
										Description
									</Typography>
									<Typography className="link-wrapper__value" tag="p">
										{link.description}
									</Typography>
								</div>
							</div>
						</div>
					))}
				{links?.length === 0 && <EmptyComponent title="No links" message="There aren't any links available for visualization." icon={['fal', 'external-link']} />}
			</S.Wrapper>
			<LinksEditModal ref={modalRef} />
		</Card>
	);
};
LinksCard.propTypes = {
	className: PropTypes.string,
	role: PropTypes.string,
};
