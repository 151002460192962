import styled from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1.5rem 1rem;

	.login-message {
		margin-top: 1.5rem;
	}
`;

export const Header = styled.header`
	width: 100%;
	display: flex;
	align-items: center;

	margin-bottom: 1.5rem;

	.centered {
		display: flex;
		flex: 1;
		justify-content: center;
	}

	.flex-1 {
		flex: 1;

		.button-label {
			display: none;

			@media screen and (min-width: 768px) {
				display: block;
			}
		}
	}
`;

export const Picture = styled.picture`
	display: flex;
	height: 3.125rem;

	img {
		height: 100%;
	}
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 55rem;
	background-color: white;
	border: 1px solid ${colors.neutralForm};
	border-radius: 0.375rem;
	padding: 2rem 1rem 1.5rem;

	.description {
		margin-top: 1rem;
	}

	@media screen and (min-width: 768px) {
		padding: 3.4375rem 1rem;
	}
`;

export const OptionElement = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: hidden;
	width: 100%;
	max-width: 20rem;
	border: 1px solid ${colors.neutralForm};
	border-radius: 0.375rem;
	padding: 1rem;

	.icon {
		width: 6rem;
		height: 6rem;
		color: ${colors.brandPrimary};
		margin-bottom: 1rem;
	}

	@media screen and (max-width: 479px) {
		.option-name {
			font-size: 1.25rem;
		}
	}

	button {
		margin-top: 2rem;
		width: 100%;
	}
`;

export const ChoicesWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	gap: 1.5rem;
	margin-top: 2.5rem;

	${OptionElement} {
		justify-self: center;
	}

	@media screen and (min-width: 768px) {
		grid-template-columns: 1fr 1fr;

		${OptionElement} {
			&:first-child {
				justify-self: flex-end;
			}

			&:last-child {
				justify-self: flex-start;
			}
		}
	}
`;
