import React, { useRef, useContext, forwardRef, useState, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';

import { required } from '../../../../utils/form-default-errors';
import { errorHandler } from '../../../../services/authService';
import { createUpdateEmployee } from '../../../../services/business';
import { BusinessCompanyEmployeeDetailContext } from '../../BusinessCompanyEmployeeDetail';
import { Button, Card, Checkbox, IconButton, Typography } from '../../../../components';
import * as S from './LocationsCard.styles';

const EditLocationsModal = forwardRef((_props, ref) => {
	const {
		state: { employee },
		dispatch,
	} = useContext(BusinessCompanyEmployeeDetailContext);
	const [isOpen, setIsOpen] = useState(false);

	const {
		register,
		setValue,
		formState: { errors, isSubmitting },
		reset,
		handleSubmit,
	} = useForm();

	const handleOpenModal = () => {
		setValue(
			'locationIds',
			employee.businessLocations.map(({ id }) => id)
		);
		setIsOpen(true);
	};

	const handleCloseModal = () => {
		setIsOpen(false);
		setTimeout(() => {
			reset();
		}, 200);
	};

	async function asyncCaller(data) {
		const {
			data: { result },
		} = await createUpdateEmployee({ employeeId: employee.id, firstName: employee.firstName, lastName: employee.lastName, permissions: employee.businessPermissions, email: employee.email, profileImageObj: employee.profileImageObj, ...data });

		dispatch({ type: 'SET_EMPLOYEE', payload: result.employee });
		handleCloseModal();
	}

	function onSubmit(e) {
		handleSubmit(asyncCaller)(e).catch(errorHandler);
	}

	useImperativeHandle(
		ref,
		() => ({
			open: handleOpenModal,
			close: handleCloseModal,
		}),
		[handleOpenModal, handleCloseModal]
	);

	return (
		<ReactModal ref={ref} className="employee-locations-card-modal" closeTimeoutMS={200} isOpen={isOpen} onRequestClose={handleCloseModal}>
			<S.Form onSubmit={onSubmit}>
				<IconButton className="close-button" type="button" icon={['fal', 'times']} onClick={handleCloseModal} />
				<div className="modal-content">
					<header className="modal-content__header">
						<Typography tag="h2" weight="extrablack" center>
							Edit Locations
						</Typography>
						<Typography tag="p" center>
							Edit locations associated with your employee.
						</Typography>
					</header>
					<section className="modal-content__section">
						<div className="checkbox-wrapper">
							<Typography className={`field-label${!!errors.locationIds ? ' with-error' : ''}`} tag="p" variation="2" weight="semibold">
								Locations
							</Typography>

							{employee.business.locations.map(({ id, name }) => (
								<div key={id} className="checkbox-field">
									<Checkbox id={id} value={id} {...register('locationIds', { required: required('Locations') })} />
									<label htmlFor={id}>{name}</label>
								</div>
							))}

							{!!errors.locationIds && (
								<div className="error-wrapper">
									<Typography tag="p" variation="2">
										{errors.locationIds.message}
									</Typography>
								</div>
							)}
						</div>
					</section>
				</div>
				<div className="modal-footer">
					<Button>
						<Typography variation="button-medium" weight="bold">
							{isSubmitting ? 'Loading' : 'Save'}
						</Typography>
					</Button>
				</div>
			</S.Form>
		</ReactModal>
	);
});

EditLocationsModal.displayName = 'EditLocationsModal';

export const LocationsCard = ({ className }) => {
	const {
		state: { employee },
	} = useContext(BusinessCompanyEmployeeDetailContext);
	const editModalRef = useRef(null);

	const handleOpenModal = () => editModalRef?.current?.open();

	return (
		<Card title="Locations" className={className} transparentHeaderBorder actions={[{ id: 1, label: 'Edit', size: 'small', variant: 'outline', icon: { source: ['fal', 'edit'] }, onClick: handleOpenModal }]}>
			<S.Wrapper>
				{employee?.businessLocations?.map((location) => (
					<S.LocationListItem key={location?.id}>
						<div className="icon-wrapper">
							<FontAwesomeIcon icon={['fal', 'building']} />
						</div>
						<div className="info-wrapper">
							<Typography tag="h5" weight="bold">
								{location?.name}
							</Typography>
							<Typography tag="p" variation="2">
								{`${location?.address?.addressLine1}, ${location?.address?.city}, ${location?.address?.state} ${location?.address?.postalCode}`}
							</Typography>
						</div>
					</S.LocationListItem>
				))}
			</S.Wrapper>
			<EditLocationsModal ref={editModalRef} />
		</Card>
	);
};

LocationsCard.propTypes = {
	className: PropTypes.string,
};
