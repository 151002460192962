import React, { forwardRef, useState, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { Controller, useForm } from 'react-hook-form';

import { Avatar, Button, IconButton, TextInput, Typography, Select } from '../';
import { uploadProfilePicture } from '../../services/media';
import { createUpdateEmployee } from '../../services/business';
import { errorHandler } from '../../services/authService';
import { required } from '../../utils/form-default-errors';
import * as S from './EditBusinessUserProfileModal.styles';
import { PERMISSIONS } from '../../../constants/general.constants';
import { updateUser } from '../../app/slices/user/userSlice';
import { updateUserProfile } from '../../services/users';

export const EditBusinessUserProfileModal = forwardRef(({ user, dispatch, currentUser = false, allowUpdatePermissions = false }, ref) => {
	const [isOpen, setIsOpen] = useState(false);
	const [avatar, setAvatar] = useState(null);
	const [avatarFile, setAvatarFile] = useState(null);

	const {
		handleSubmit,
		setValue,
		register,
		reset,
		control,
		formState: { errors, isSubmitting },
	} = useForm();

	const handleOpenModal = () => {
		setValue('firstName', user?.firstName);
		setValue('lastName', user?.lastName);
		setValue('permissions', user?.businessPermissions?.[0]);
		setAvatar(user?.profileImageObj?.[200]);
		setAvatarFile(null);
		setIsOpen(true);
	};

	const handleCloseModal = () => {
		setIsOpen(false);
		setTimeout(() => {
			reset();
		}, 200);
	};

	async function asyncCaller(data) {
		let profileImageObj;

		if (avatarFile) {
			const formData = new FormData();
			formData.append('file', avatarFile);

			const { data: uploadResult } = await uploadProfilePicture(formData);

			profileImageObj = uploadResult;
		} else {
			profileImageObj = user?.profileImageObj;
		}

		if (currentUser) {
			const {
				data: { result },
			} = await updateUserProfile({
				firstName: user.firstName,
				lastName: user.lastName,
				email: user.email,
				...data,
				role: user.role,
				profileImageObj,
			});

			dispatch(updateUser(result?.user));
		} else {
			const {
				data: { result },
			} = await createUpdateEmployee({
				employeeId: user.id,
				firstName: user.firstName,
				lastName: user.lastName,
				businessId: user.business.id,
				permissions: user.businessPermissions,
				email: user.email,
				locationIds: user.businessLocations.map((b) => b.id),
				...data,
				...(allowUpdatePermissions ? { permissions: [data.permissions] } : undefined),
				profileImageObj,
			});

			dispatch({ type: 'SET_USER', payload: result.employee });
			dispatch({ type: 'SET_EMPLOYEE', payload: result.employee });
		}

		handleCloseModal();
	}

	const onSubmit = (e) => {
		handleSubmit(asyncCaller)(e).catch(errorHandler);
	};

	useImperativeHandle(
		ref,
		() => ({
			open: handleOpenModal,
			close: handleCloseModal,
		}),
		[handleOpenModal, handleCloseModal]
	);

	return (
		<ReactModal className="profile-card-modal" ref={ref} closeTimeoutMS={200} isOpen={isOpen} onRequestClose={handleCloseModal}>
			<S.Form onSubmit={onSubmit}>
				<IconButton className="close-button" type="button" icon={['fal', 'times']} onClick={handleCloseModal} />
				<div className="modal-content">
					<header className="modal-content__header">
						<Typography tag="h2" weight="extrablack" center>
							Edit Profile
						</Typography>
						<Typography tag="p" center>
							Please update your profile information here.
						</Typography>
					</header>

					<section className="modal-content__section">
						<Avatar className="avatar-element" avatar={avatar} setAvatar={setAvatar} file={avatarFile} setFile={setAvatarFile} />

						<TextInput
							label="First Name"
							placeholder="First Name"
							error={errors.firstName}
							{...register('firstName', {
								required: required('First Name'),
							})}
						/>
						<TextInput
							label="Last Name"
							placeholder="Last Name"
							error={errors.lastName}
							{...register('lastName', {
								required: required('Last Name'),
							})}
						/>
						{allowUpdatePermissions ? (
							<Controller
								name="permissions"
								control={control}
								rules={{ required: required('Permissions') }}
								render={({ field: { onChange, value } }) => <Select label="Permissions" id="permissions" error={errors?.permissions} placeholder="Permissions" options={PERMISSIONS} value={PERMISSIONS.find((c) => c.value === value) || ''} onChange={(val) => onChange(val.value)} />}
							/>
						) : (
							<TextInput label="Role" placeholder="Role" value={user?.businessPermissions?.[0].charAt(0).toUpperCase() + user?.businessPermissions?.[0].slice(1)} readOnly />
						)}
					</section>

					<div className="modal-footer">
						<Button>
							<Typography variation="button-medium" weight="bold">
								{isSubmitting ? 'Loading' : 'Save'}
							</Typography>
						</Button>
					</div>
				</div>
			</S.Form>
		</ReactModal>
	);
});

EditBusinessUserProfileModal.displayName = 'EditBusinessUserProfileModal';

EditBusinessUserProfileModal.propTypes = {
	className: PropTypes.string,
	user: PropTypes.shape(),
	dispatch: PropTypes.func,
	allowUpdatePermissions: PropTypes.bool,
	currentUser: PropTypes.bool,
};
