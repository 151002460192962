import styled from 'styled-components';
import colors from '../../../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding-bottom: 0.5rem;

	& > * + * {
		margin-top: 1rem;
		padding-top: 1rem;
		border-top: 1px solid ${colors.neutralForm};
	}

	.link-wrapper {
		display: flex;
		flex-direction: column;

		&__heading {
			display: flex;
			justify-content: space-between;

			a {
				text-decoration: none;
			}
		}

		&__body {
			margin-top: 0.5rem;

			& > * + * {
				margin-top: 0.75rem;
			}
		}

		&__title {
			color: ${colors.neutralText};
		}

		&__value {
			margin-top: 0.25rem;
		}
	}
`;

export const Form = styled.form`
	position: relative;
	padding: 3rem 1.25rem 0;

	.close-button {
		position: absolute;
		top: 1rem;
		right: 1rem;
		color: ${colors.neutralButton};
	}
`;

export const InfoWrapper = styled.div`
	margin: 0 auto 0.75rem;
	width: 100%;
	max-width: 34.5rem;

	& > * + * {
		margin-top: 0.75rem;
	}
`;

export const FieldsWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	gap: 1.5rem;
	width: 100%;
	max-width: 29rem;
	margin: 2rem auto 0;

	.link-item {
		display: grid;
		grid-template-columns: 1fr;
		gap: 1.125rem;
		position: relative;

		& > h3 {
			margin-bottom: -1rem;
		}

		.link-remove {
			position: absolute;
			right: 0;
			color: ${colors.stateDanger};
		}
	}
`;

export const ButtonsWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin: 1.75rem -1.25rem 0;
	padding: 1rem;
	border-top: 1px solid ${colors.neutralForm};

	& > * + * {
		margin-left: 1rem;
	}
`;
