import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { Button, TextArea, TextInput, Typography } from '../../../../components';
import { required, urlOnly } from '../../../../utils/form-default-errors';

export const StepTwo = ({ role }) => {
	const {
		register,
		control,
		formState: { errors },
	} = useFormContext();
	const { fields, append, remove } = useFieldArray({ control, name: 'linkObjs' });

	const handleAddLink = () => {
		append({});
	};

	const handleRemoveLink = (index) => {
		remove(index);
	};

	return (
		<div className="modal-content">
			<Typography className="bigger-title" tag="h1" weight="extrablack" center>
				Links
			</Typography>
			<Typography tag="p" className="description" center>
				Add any links that help fulfill your request.
			</Typography>

			<div className="fields-wrapper">
				{fields?.map((field, index) => (
					<div key={field?.id} className="link-item">
						<Typography tag="h3" weight="bold">
							Link {index + 1}
						</Typography>
						<Button className="link-remove" variant="text" type="button" onClick={() => handleRemoveLink(index)}>
							<Typography variation="button-medium" weight="bold">
								Remove
							</Typography>
						</Button>
						<TextInput
							label="Link"
							id={`${index}-url`}
							error={errors?.linkObjs?.[index]?.url}
							{...register(`linkObjs.${index}.url`, {
								required: required('Link'),
								pattern: urlOnly('Link'),
							})}
						/>
						<TextInput
							label="Title"
							id={`${index}-title`}
							error={errors?.linkObjs?.[index]?.title}
							{...register(`linkObjs.${index}.title`, {
								required: required('Title'),
							})}
						/>
						<TextArea
							label="Description"
							id={`${index}-description`}
							error={errors?.linkObjs?.[index]?.description}
							{...register(`linkObjs.${index}.description`, {
								required: required('Description'),
							})}
						/>
					</div>
				))}
				<div className="add-link-wrapper">
					<Button variant="text" type="button" onClick={handleAddLink}>
						<FontAwesomeIcon icon={['fal', 'plus']} />
						<Typography variation="button-medium" weight="bold">
							Add Link
						</Typography>
					</Button>
				</div>
			</div>
		</div>
	);
};

StepTwo.propTypes = {
	role: PropTypes.string,
};
