import styled, { css } from 'styled-components';

import colors from '../../../../styles/colors';

export const DirectoryCardsGrid = styled.div`
	display: grid;
	gap: 1.25rem;
	grid-template-columns: 1fr;

	@media screen and (min-width: 992px) {
		grid-template-columns: ${({ showMap }) => `repeat(${showMap ? '2' : '1'}, 1fr)`};
	}
`;

const CARD_VIEW_OPTIONS = {
	grid: css`
		display: grid;
		gap: 1.25rem;
		grid-template-columns: 1fr;

		@media screen and (min-width: 426px) {
			grid-template-columns: repeat(2, 1fr);
		}

		@media screen and (min-width: 900px) {
			grid-template-columns: repeat(3, 1fr);
		}

		@media screen and (min-width: 992px) {
			grid-template-columns: repeat(4, 1fr);
		}
	`,
	list: css`
		display: flex;
		flex-direction: column;

		& > * + * {
			margin-top: 1rem;
			padding-top: 1rem;
			border-top: 1px solid ${colors.neutralForm};
		}
	`,
};

export const CardsWrapper = styled.div`
	padding: 1rem 1.25rem 2rem;
	background-color: white;
	border: 1px solid ${colors.neutralForm};
	border-radius: 0.375rem;

	${({ viewOption }) => CARD_VIEW_OPTIONS[viewOption]}
`;

export const ResultsAndActionsWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0 0 0.75rem;

	.actions-wrapper {
		display: flex;
		align-items: center;
	}
`;

export const ToggleButton = styled.button`
	display: flex;
	align-items: center;
	appearance: none;
	border: 1px solid ${colors.neutralForm};
	border-radius: 0.375rem;
	background-color: white;
	margin-left: 0.75rem;
	padding: 0.3125rem 0.625rem;
	color: ${colors.neutralButton};
	cursor: pointer;

	&:hover {
		opacity: 0.8;
	}

	svg {
		width: 1rem;
		height: 1rem;
	}

	& > * + * {
		margin-left: 0.5rem;
	}

	&.grid-button {
		margin: 0;
		padding: 0.3125rem 0.75rem;

		&.first {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
		&.last {
			margin-left: -1px;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}

	${({ active }) =>
		active &&
		css`
			background-color: ${colors.neutralForm};
			border-color: ${colors.neutralButton};
			z-index: 1;
		`}
`;

export const MapWrapper = styled.div`
	position: relative;
	height: 0;
	padding-top: 100%;
`;
