import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import { Controller, useForm } from 'react-hook-form';

import { errorHandler } from '../../../services/authService';
import { pattern, required } from '../../../utils/form-default-errors';
import { EMAIL } from '../../../utils/common-regex';
import { Button, IconButton, Select, TextArea, Typography, Spinner, ErrorComponent, TextInput } from '../../../components';
import * as S from './ShareModal.styles';
import { fetchBusinesses } from '../../../services/business';
import { shareRequest } from '../../../services/requests/createRequestService';
import toast from 'react-hot-toast';

export const ShareModal = forwardRef((_props, ref) => {
	const [loadingStatus, setLoadingStatus] = useState('idle');
	const [businesses, setBusinesses] = useState([]);
	const [isOpen, setIsOpen] = useState(false);

	const {
		handleSubmit,
		control,
		register,
		watch,
		reset,
		setValue,
		formState: { errors, isSubmitting },
	} = useForm();

	const selectedBusiness = watch('businessId');

	async function initializeData() {
		setLoadingStatus('loading');
		try {
			const {
				data: {
					result: { businesses },
				},
			} = await fetchBusinesses({ page: 0, isSlim: true, filterOptions: { approved: true } });
			setBusinesses([{ id: 'new', name: 'New Business' }, ...businesses]);
			setLoadingStatus('success');
		} catch (error) {
			setLoadingStatus('error');
			errorHandler(error);
		}
	}

	const handleOpen = (requestId) => {
		setValue('requestId', requestId);
		setIsOpen(true);
	};

	const handleClose = () => {
		setIsOpen(false);
		setTimeout(() => {
			reset();
		}, 200);
	};

	async function asyncCaller(data) {
		if (selectedBusiness === 'new') {
			const { businessId, ...rest } = data;
			await shareRequest(rest);
		} else {
			await shareRequest({ requestId: data.requestId, businessId: data.businessId, message: data.message });
		}
		toast.success('Request shared successfully.');
		handleClose();
	}

	const onSubmit = (e) => {
		handleSubmit(asyncCaller)(e).catch(errorHandler);
	};

	useImperativeHandle(
		ref,
		() => ({
			open: handleOpen,
			close: handleClose,
		}),
		[handleOpen, handleClose]
	);

	useEffect(() => {
		if (loadingStatus === 'idle') {
			initializeData();
		}
	}, []);

	const renderContent = () => {
		switch (loadingStatus) {
			case 'loading':
				return <Spinner />;
			case 'error':
				return <ErrorComponent />;
			case 'success':
				return (
					<S.Form onSubmit={onSubmit}>
						<IconButton className="close-button" type="button" icon={['fal', 'times']} onClick={handleClose} />
						<S.InfoWrapper>
							<Typography tag="h2" weight="extrablack" center>
								Share Request
							</Typography>
						</S.InfoWrapper>
						<S.FieldsWrapper>
							<Controller
								name="businessId"
								control={control}
								rules={{ required: required('Business Name') }}
								render={({ field: { onChange, value } }) => (
									<Select
										getOptionValue={(option) => option.id}
										getOptionLabel={(option) => option.name}
										label="Business Name"
										placeholder="Type to search"
										id="business-name"
										error={errors?.businessId}
										options={businesses}
										promise={{
											function: fetchBusinesses,
											params: { page: 0, isSlim: true, filterOptions: { approved: true } },
											key: 'businesses',
											setOptions: setBusinesses,
										}}
										value={businesses.find((c) => c.id === value) || ''}
										onChange={(val) => onChange(val.id)}
									/>
								)}
							/>
							{selectedBusiness === 'new' && (
								<>
									<TextInput
										label="Company Name"
										placeholder="Company Name"
										id="company-name"
										error={errors.businessName}
										{...register('businessName', {
											required: required('Company Name'),
										})}
									/>
									<TextInput
										label="Admin First Name"
										placeholder="Admin First Name"
										id="first-name"
										error={errors.adminFirstName}
										{...register('adminFirstName', {
											required: required('Admin First Name'),
										})}
									/>
									<TextInput
										label="Admin Last Name"
										placeholder="Admin Last Name"
										id="last-name"
										error={errors.adminLastName}
										{...register('adminLastName', {
											required: required('Admin Last Name'),
										})}
									/>
									<TextInput
										label="Admin Email"
										placeholder="Admin Email"
										id="email"
										error={errors.adminEmail}
										{...register('adminEmail', {
											required: required('Admin Email'),
											pattern: pattern('Email', EMAIL),
										})}
									/>
								</>
							)}
							<TextArea label="Message" placeholder="Add message here" id="message" error={errors?.message} {...register('message')} />
						</S.FieldsWrapper>
						<S.ButtonsWrapper>
							<Button type="button" variant="outline" onClick={handleClose}>
								<Typography weight="bold" variation="button-medium">
									Back
								</Typography>
							</Button>
							<Button type="submit">
								<Typography weight="bold" variation="button-medium">
									{isSubmitting ? 'Loading' : 'Share'}
								</Typography>
							</Button>
						</S.ButtonsWrapper>
					</S.Form>
				);
			default:
				return null;
		}
	};

	return (
		<ReactModal className="smaller-modal" ref={ref} closeTimeoutMS={200} isOpen={isOpen} onRequestClose={handleClose}>
			{renderContent()}
		</ReactModal>
	);
});

ShareModal.displayName = 'ShareModal';
