import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import * as S from './Picture.styles';

export const Picture = forwardRef(({ className, pictureClassName, src, alt = '', aspectRatio = 'natural', width, height }, ref) => {
	return (
		<S.Container className={className}>
			<S.Picture ref={ref} className={pictureClassName} aspectRatio={aspectRatio}>
				<img src={src} alt={alt} width={width} height={height} />
			</S.Picture>
		</S.Container>
	);
});

Picture.displayName = 'Picture';

Picture.propTypes = {
	className: PropTypes.string,
	pictureClassName: PropTypes.string,
	src: PropTypes.string,
	alt: PropTypes.string,
	width: PropTypes.number,
	height: PropTypes.number,
	aspectRatio: PropTypes.oneOf(['natural', '1/1', '4/3', '3/4', '16/9', '9/16', '21/9', '9/21', '2/1', '1/2', '3/2', '2/3', '4/1', '1/4']),
};
