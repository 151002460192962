import React from 'react';
import { isPast, add } from 'date-fns';
import { Controller, useFormContext } from 'react-hook-form';

import { required } from '../../../../utils/form-default-errors';
import { Calendar, TextArea, Typography } from '../../../../components';
import { formatToMonthDayAndYear } from '../../../../utils/time-formatter';

export const StepOne = ({}) => {
	const {
		register,
		control,
		watch,
		formState: { errors },
	} = useFormContext();

	const availabilityDates = watch('availabilityDates');

	const formatAvailabilityDates = () => {
		if (availabilityDates) {
			if (availabilityDates.length < 2) {
				return formatToMonthDayAndYear(availabilityDates[0]);
			} else {
				return `${formatToMonthDayAndYear(availabilityDates[0])} - ${formatToMonthDayAndYear(availabilityDates[1])}`;
			}
		}
		return 'Select Date(s) Above';
	};

	return (
		<div className="step__wrapper">
			<header className="step__header">
				<Typography tag="h2" weight="extrablack" className="step__title">
					Select a Date
				</Typography>
				<Typography tag="p" className="step__subtitle">
					Select a time frame you would like for the resource to occur.
				</Typography>
			</header>
			<section className="step__content">
				<div className="step__calendar-wrapper">
					<Typography tag="h4" weight="extrablack" className="step__calendar-title">
						Availability
					</Typography>
					<div className={`step__calendar-content${!!errors.availabilityDates ? ' with-error' : ''}`}>
						<Controller
							name="availabilityDates"
							control={control}
							rules={{ required: required('Date Range') }}
							render={({ field: { onChange, value } }) => <Calendar id="date-range" value={value} onChange={onChange} selectRange={true} allowPartialRange={true} tileDisabled={({ date }) => isPast(add(date, { days: 1 }))} />}
						/>
					</div>
					{!!errors.availabilityDates && (
						<div className="error-wrapper">
							<Typography tag="p" variation="2">
								{errors.availabilityDates.message}
							</Typography>
						</div>
					)}
				</div>

				<div className="step__field-wrapper">
					<Typography tag="h6" weight="semibold" className="step__field-label">
						Selected Dates
					</Typography>
					<Typography tag="p" className={`step__field-value${availabilityDates ? null : ' step__field-value--empty'}`}>
						{formatAvailabilityDates()}
					</Typography>
				</div>

				<TextArea label="Availability Notes (i.e days flexible, days unavailable, etc.)" placeholder="Add any notes here" id="availabilityNotes" error={errors.availabilityNotes} {...register('availabilityNotes')} />
			</section>
		</div>
	);
};
