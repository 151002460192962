import React from 'react';
import PropTypes from 'prop-types';

import { Card, Typography } from '../../../../components';
import * as S from './TeacherCard.styles';
import { buildAddressString } from '../../../../utils/location-methods';
import { ROLES } from '../../../../../constants/general.constants';

export const TeacherCard = ({ className, role, request: { creator: teacher } }) => {
	return (
		<Card title={role === ROLES.TEACHER ? 'Teacher' : 'Creator'} className={className} transparentHeaderBorder>
			<S.Wrapper>
				<div className="header-wrapper">
					<img className="header-wrapper__avatar" src={teacher.profileImageObj['200']} alt="avatar" />
					<Typography tag="p" weight="semibold">
						{teacher.firstName} {teacher.lastName}
					</Typography>
				</div>
				{teacher.school && (
					<>
						<div className="element-wrapper">
							<Typography tag="h6" weight="semibold">
								School Name
							</Typography>
							<Typography tag="p">{teacher.school.name}</Typography>
						</div>
						<div className="element-wrapper">
							<Typography tag="h6" weight="semibold">
								School Address
							</Typography>
							<Typography tag="p">{buildAddressString(teacher.school.address)}</Typography>
						</div>
					</>
				)}
			</S.Wrapper>
		</Card>
	);
};
TeacherCard.propTypes = {
	className: PropTypes.string,
	role: PropTypes.string,
	request: PropTypes.object,
};
