import styled from 'styled-components';

import colors from '../../../../styles/colors';

export const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	gap: 1.25rem;

	.business-review-item {
		display: grid;
		grid-template-columns: 2.625rem 1fr;
		gap: 0.75rem;

		&__image-wrapper {
			img {
				display: block;
				width: 100%;
				border-radius: 9999px;
				border: 1px solid ${colors.neutralForm};
			}
		}

		&__fields-wrapper {
			display: grid;
			gap: 1.25rem;
			margin-top: 0.25rem;

			& > h3 {
				margin-bottom: -0.75rem;
			}
		}

		&.smaller {
			grid-template-columns: 2.125rem 1fr;

			.business-review-item__fields-wrapper {
				gap: 0.5rem;
			}
		}
	}

	.review {
		&__step-item {
			display: grid;
			grid-template-columns: 1fr;
			gap: 1.5rem;
			margin-top: 1rem;
			align-content: start;

			&:first-child {
				margin-top: 0;
			}
		}

		&__businesses {
			border-top: 1px solid ${colors.neutralForm};
			padding-top: 1rem;
		}

		&__learning {
			margin-bottom: 0.75rem;
		}

		&__step-half {
			display: grid;
			grid-template-columns: 1fr;
			gap: 1.5rem;

			@media (min-width: 1024px) {
				grid-template-columns: 1fr 1fr;
			}
		}

		&__info-item {
			& > h6 {
				color: ${colors.neutralText};
				margin-bottom: 0.375em;
			}

			&--with-badge {
				& > h6 {
					margin-bottom: calc(0.375em + 0.25rem);
				}
			}

			.badge-wrapper {
				display: flex;
				flex-wrap: wrap;
				margin: -0.25rem;

				.small-badge {
					padding: 0.25rem 0.5rem;
					border-radius: 6.25rem;
					border: 1px solid ${colors.brandPrimary};
					font-size: 0.75rem;
					color: ${colors.brandPrimary};
					font-weight: 700;
					margin: 0.25rem;
				}
			}
		}
	}
`;

export const BigFieldset = styled.div`
	display: flex;
	flex-direction: column;

	& > * + * {
		margin-top: 0.5rem;
	}
`;

export const BigBox = styled.div`
	border: 1px solid ${colors.neutralForm};
	border-radius: 0.375rem;
	padding: 0.75rem;

	& > * + * {
		margin-top: 0.5rem;
	}
`;

export const BigBoxHeader = styled.div`
	display: flex;
	background-color: ${colors.neutralBackground};
	color: ${colors.neutralText};
	border-radius: 0.25rem;
	padding: 0.5rem 0.75rem;

	> h6 {
		flex: 1;

		&:last-child {
			width: 100%;
			max-width: 9.375rem;
		}
	}
`;

export const BigBoxLine = styled.div`
	display: flex;
	align-items: center;
	padding: 0.625rem 0.75rem;

	> p {
		flex: 1;
	}

	.box-field {
		width: 100%;
		max-width: 9.375rem;
	}
	p.box-field {
		padding-left: 0.75rem;
	}
`;

export const BigBoxFooter = styled.div`
	display: flex;
	padding: 1rem 0.75rem 0.25rem;
	border-top: 1px solid ${colors.neutralForm};

	> p {
		flex: 1;

		&:last-child {
			width: 100%;
			max-width: 9.375rem;
			padding-left: 0.75rem;
			color: ${colors.stateSuccess};
		}

		&.warning-text {
			color: ${colors.stateWarning};
		}
	}
`;
