import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import { formatToDayMonth2DigitYear } from '../../../../utils/time-formatter';
import { Card, Typography } from '../../../../components';
import * as S from './ScheduleCard.styles';

export const ScheduleCard = ({ request, role }) => {
	return (
		<Card title="Schedule" transparentHeaderBorder={true}>
			<S.Wrapper>
				<Typography tag="h4" weight="bold">
					Date
				</Typography>
				<div className="grid-line">
					<div className="grid-line__info-item">
						<Typography tag="h6" weight="semibold">
							Date
						</Typography>
						<Typography tag="p">{formatToDayMonth2DigitYear(moment(request.scheduleDates[0], 'MM-DD-YYYY', true).toDate())}</Typography>
					</div>
					<div className="grid-line__info-item">
						<Typography tag="h6" weight="semibold">
							Time
						</Typography>
						<Typography tag="p">{request.scheduleStartTime}</Typography>
					</div>
					<div className="grid-line__info-item">
						<Typography tag="h6" weight="semibold">
							Availability Notes
						</Typography>
						<Typography tag="p">{request.scheduleNotes}</Typography>
					</div>
				</div>
				{request?.schoolLocation && (
					<>
						<Typography tag="h4" weight="bold">
							Location
						</Typography>
						<div className="location-title">
							<FontAwesomeIcon icon={['fal', 'users']} />
							<Typography tag="p" weight="semibold">
								Student Location
							</Typography>
						</div>
						<div className="grid-line">
							<div className="grid-line__info-item">
								<Typography tag="h6" weight="semibold">
									Location Name
								</Typography>
								<Typography tag="p">{request?.schoolLocation?.name}</Typography>
							</div>
							<div className="grid-line__info-item">
								<Typography tag="h6" weight="semibold">
									Address
								</Typography>
								<Typography tag="p">{`${request?.schoolLocation?.address?.addressLine1}, ${request?.schoolLocation?.address?.state} ${request?.schoolLocation?.address?.postalCode}`}</Typography>
							</div>
						</div>
					</>
				)}
				<Typography tag="h4" weight="bold">
					Notes
				</Typography>
				<div className="grid-line">
					<div className="grid-line__info-item">
						<Typography tag="h6" weight="semibold">
							Additional Notes
						</Typography>
						<Typography tag="p">{request.availabilityNotes}</Typography>
					</div>
				</div>
			</S.Wrapper>
		</Card>
	);
};

ScheduleCard.propTypes = {
	request: PropTypes.shape(),
	role: PropTypes.string,
};
