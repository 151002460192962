import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';

import { ErrorComponent, Picture, Spinner, Typography, Chat } from '../../../components';
import { errorHandler } from '../../../services/authService';
import { fetchRequest } from '../../../services/requests';
import { useChat } from '../../../hooks';
import { AppointmentDetailsContext } from '../RequestsAppointmentDetails';
import * as S from './MessagesTab.styles';
import { fetchMessages } from '../../../services/messages';

export const MessagesTab = () => {
	const {
		state: { request },
		dispatch,
	} = useContext(AppointmentDetailsContext);
	const { id } = useParams();
	const { messages, setMessages } = useChat(request?.response?.id);
	const [tabStatus, setTabStatus] = useState('idle');

	const renderContent = () => {
		switch (tabStatus) {
			case 'loading':
				return <Spinner />;
			case 'success':
				return (
					<>
						<S.ChatHeader>
							<S.InfoWrapper>
								<Picture className="avatar-image" src={request.creator.profileImageObj['200']} aspectRatio="1/1" alt="profile" />
								<div className="info-block">
									<Typography tag="h3" weight="bold">
										{request.creator.firstName} {request.creator.lastName}
									</Typography>
									<Typography tag="p" variation="2">
										{request.creator.school ? request.creator.school.name : request.creator.business.name}
									</Typography>
								</div>
							</S.InfoWrapper>
						</S.ChatHeader>
						<Chat messages={messages} setMessages={setMessages} sendProps={{ requestId: id, responseId: request.response.id, recipientId: request.creator.id }} />
					</>
				);
			case 'error':
				return <ErrorComponent />;
			default:
				return null;
		}
	};

	async function initializeData() {
		setTabStatus('loading');
		try {
			const [
				{
					data: {
						result: { request },
					},
				},
				{
					data: {
						result: { messages },
					},
				},
			] = await Promise.all([fetchRequest({ requestId: id }), fetchMessages({ requestId: id, filterOptions: { received: true, sent: true } })]);
			dispatch({ type: 'setRequest', payload: request });
			setMessages(messages);
			setTabStatus('success');
		} catch (error) {
			setTabStatus('error');
			errorHandler(error);
		}
	}

	useEffect(() => {
		if (tabStatus === 'idle') {
			initializeData();
		}
	}, [tabStatus]);

	return <S.Wrapper>{renderContent()}</S.Wrapper>;
};
