import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Popover } from '@headlessui/react';
import { usePopper } from 'react-popper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { RESOURCE_CATEGORIES } from '../../../../constants/general.constants';
import { FilterContext } from '../FilterSection';
import { TextInput } from '../../TextInput';
import { Typography } from '../../Typography';
import { Checkbox } from '../../Checkbox';
import * as S from '../FilterSection.styles';

const ITEMS_ARRAY = [].concat(...Object.values(RESOURCE_CATEGORIES));
const ACTIONS = {
	SET: 'SET_CATEGORIES',
	ADD_FILTER: 'ADD_TO_ACTIVE_FILTERS',
	REMOVE_FILTER: 'REMOVE_FROM_ACTIVE_FILTERS',
};
const FILTER_NAME = 'categories';
const LABEL = 'Resource Category';

export const ResourceCategoryFilter = ({ dispatch }) => {
	const { state } = useContext(FilterContext);
	const { activeFilters, categories: filterArray } = state;

	const [referenceElement, setReferenceElement] = useState(null);
	const [popperElement, setPopperElement] = useState(null);
	const { styles, attributes } = usePopper(referenceElement, popperElement, {
		placement: 'bottom-start',
		modifiers: [
			{
				name: 'offset',
				options: {
					offset: [0, 12],
				},
			},
		],
	});

	const [searchFilter, setSearchFilter] = useState('');

	const filteredItems = useMemo(() => {
		return ITEMS_ARRAY.filter((item) => {
			return item.label.toLowerCase().includes(searchFilter.toLowerCase());
		});
	}, [searchFilter]);

	const clearValue = (e) => {
		e?.stopPropagation();
		dispatch({ type: ACTIONS.SET, payload: [] });
		dispatch({ type: ACTIONS.REMOVE_FILTER, payload: FILTER_NAME });
	};

	const onChangeHandler = (e, value) => {
		if (e.target.checked) {
			dispatch({ type: ACTIONS.SET, payload: [...filterArray, value] });
			if (!activeFilters.includes(FILTER_NAME)) {
				dispatch({ type: ACTIONS.ADD_FILTER, payload: FILTER_NAME });
			}
		} else {
			const newArray = filterArray.filter((type) => type !== value);
			if (newArray.length === 0) {
				clearValue();
			} else {
				dispatch({ type: ACTIONS.SET, payload: newArray });
			}
		}
	};

	const getButtonClassName = useMemo(() => {
		let className = 'popover-button';
		if (filterArray?.length > 0) {
			className += ' active';
		}
		return className;
	}, [filterArray]);

	useEffect(() => {
		if (filterArray.length > 0) {
			dispatch({ type: 'ADD_TO_ACTIVE_FILTERS', payload: FILTER_NAME });
		}
	}, []);

	return (
		<Popover as={S.SingleFilterWrapper}>
			<Popover.Button ref={setReferenceElement} className={getButtonClassName}>
				{filterArray?.length > 0 ? (
					<>
						<Typography tag="p" variation="2">
							{filterArray?.map((type) => type?.label).join(', ')}
						</Typography>
						<FontAwesomeIcon icon={['fal', 'times']} onClick={clearValue} />
					</>
				) : (
					<Typography tag="p" variation="2">
						{LABEL}
					</Typography>
				)}
			</Popover.Button>
			<Popover.Panel ref={setPopperElement} style={styles.popper} {...attributes.popper} className="popover-panel">
				<div className="popover-panel-wrapper">
					<div className="checkbox-list-wrapper">
						<TextInput value={searchFilter} onChange={(e) => setSearchFilter(e.target.value)} type="search" icon={['fal', 'search']} size="small" placeholder="Search" />
						{filteredItems.map((item) => (
							<div key={item.value} className="checkbox-field">
								<Checkbox id={item.value} value={item.value} checked={filterArray.includes(item)} onChange={(e) => onChangeHandler(e, item)} />
								<label htmlFor={item.value}>{item.label}</label>
							</div>
						))}
					</div>
				</div>
			</Popover.Panel>
		</Popover>
	);
};

ResourceCategoryFilter.displayName = 'ResourceCategoryFilter';

ResourceCategoryFilter.propTypes = {
	dispatch: PropTypes.func.isRequired,
};
