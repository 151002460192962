import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { Card, Typography } from '../../../../components';
import * as S from './BusinessCard.styles';
import { ROLES } from '../../../../../constants/general.constants';

export const BusinessCard = ({ role, className, business }) => {
	const history = useHistory();

	return (
		<Card
			title="Business"
			className={className}
			transparentHeaderBorder
			actions={[
				{
					id: 1,
					label: 'View Profile',
					size: 'smaller',
					variant: 'text',
					icon: { source: ['fal', 'arrow-right'], placement: 'right' },
					onClick: () => history.push({ pathname: role === ROLES.ADMIN || role === ROLES.SUPER ? `/admin/companies/${business.id}` : `/${role || 'teacher'}/directory/business/${business.id}`, state: { backUrl: window.location.pathname, backTitle: 'Back to Booking' } }),
				},
			]}
		>
			<S.Wrapper>
				<div className="business-info">
					<img className="business-info__avatar" src={business.logoObj['200']} alt={`${business.name} profile`} />
					<div className="business-info__details">
						<Typography className="business-info__title" tag="p" weight="semibold">
							{business.name}
						</Typography>
						<Typography className="business-info__subtitle" tag="p" variation="2">
							{business.website}
						</Typography>
					</div>
				</div>
				<div className="business-bio">
					<Typography className="business-bio__title" tag="h6" weight="semibold">
						Bio
					</Typography>
					<Typography tag="p">{business.bio}</Typography>
				</div>
				<div className="business-career-cluster">
					<Typography className="business-career-cluster__title" tag="h6" weight="semibold">
						Career Clusters
					</Typography>
					<div className="business-career-cluster__badges-wrapper">
						{business.careerClusters.map((careerCluster, index) => (
							<div className="business-career-cluster__badge" key={index}>
								{careerCluster}
							</div>
						))}
					</div>
				</div>
			</S.Wrapper>
		</Card>
	);
};

BusinessCard.propTypes = {
	className: PropTypes.string,
	role: PropTypes.string,
	business: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		logoObj: PropTypes.object,
		name: PropTypes.string,
		website: PropTypes.string,
		bio: PropTypes.string,
		careerClusters: PropTypes.array,
	}),
};
