import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { clearUser, updateUser } from '../../app/slices/user/userSlice';
import logo from '../../assets/ncbce-logo-alt.png';
import { createUpdate } from '../../services/business';
import { logoutUser } from '../../services/authService';
import { errorHandler } from '../../services/authService';
import { required } from '../../utils/form-default-errors';
import { Button, Typography, TextInput, Stepper, TextArea } from '../../components';
import * as S from './SignupBusinessCreateCompany.styles';
import { META_TITLE } from '../../../constants/general.constants';
import { clearSavedStates } from '../../app/slices/filterSearch/filterSearchSlice';

const SignupBusinessCreateCompany = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const {
		register,
		formState: { errors, isSubmitting },
		handleSubmit,
	} = useForm();

	const backToHome = () => {
		logoutUser().then(() => {
			dispatch(clearUser());
			dispatch(clearSavedStates());
			history.push('/business/sign-up');
		});
	};

	useEffect(() => {
		// Set document title
		document.title = `Create Business | Business Sign Up | ${META_TITLE}`;
	}, []);

	async function asyncCaller(data) {
		const {
			data: { user },
		} = await createUpdate(data);

		dispatch(updateUser(user));

		history.push('/business/company-locations');
	}

	function onSubmit(e) {
		handleSubmit(asyncCaller)(e).catch(errorHandler);
	}

	return (
		<S.Wrapper>
			<S.Header>
				<div className="flex-1">
					<Button variant="text" size="small" onClick={backToHome}>
						<FontAwesomeIcon icon={['fal', 'arrow-left']} />
						<Typography className="button-label" variation="button-small">
							Back to Home
						</Typography>
					</Button>
				</div>
				<div className="centered">
					<S.Picture>
						<img src={logo} alt="NCBCE logo" />
					</S.Picture>
				</div>
				<div className="flex-1" />
			</S.Header>
			<S.Container>
				<S.ContainerHeader>
					<Stepper
						activeStep={0}
						steps={[
							{ id: 1, label: 'Account' },
							{ id: 2, label: 'Locations' },
						]}
					/>
				</S.ContainerHeader>
				<Typography tag="h1" weight="extrablack" center>
					Create Company Account
				</Typography>
				<Typography tag="p" className="description" center>
					Add in company overview information.
				</Typography>
				<S.Form onSubmit={onSubmit}>
					<S.FieldsWrapper>
						<TextInput
							label="Company Name"
							id="company-name"
							error={errors.businessName}
							{...register('businessName', {
								required: required('Company Name'),
							})}
						/>
						<TextInput
							label="Website"
							id="website"
							error={errors.website}
							{...register('website', {
								required: required('Website'),
							})}
						/>
						<TextArea label="Company Bio" id="company-bio" error={errors.bio} {...register('bio')} />
					</S.FieldsWrapper>
					<S.ContainerFooter>
						<div className="flex-1" />
						<Button>
							<Typography variation="button-medium" weight="bold">
								{isSubmitting ? 'Loading...' : 'Next'}
							</Typography>
						</Button>
					</S.ContainerFooter>
				</S.Form>
			</S.Container>
		</S.Wrapper>
	);
};

export default SignupBusinessCreateCompany;
