import styled, { css } from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	gap: 0.75rem;
	padding-bottom: 0.75rem;
	border-bottom: 1px solid ${colors.neutralForm};
	margin-bottom: 0.75rem;

	@media (min-width: 768px) {
		grid-template-columns: 1fr auto;
	}
`;

export const FieldWrapper = styled.div`
	display: flex;
	align-items: center;

	& > label {
		white-space: nowrap;
		color: ${colors.neutralText};
		margin: 0;
		margin-right: 0.5rem;
	}

	& > div {
		flex: 1;

		@media (min-width: 768px) {
			max-width: 21rem;
		}
	}
`;

export const Form = styled.form`
	position: relative;

	.close-button {
		position: absolute;
		top: 1rem;
		right: 1rem;
		color: ${colors.neutralButton};
	}

	.modal-content {
		display: grid;
		grid-template-columns: 1fr;
		gap: 2rem;
		margin: 1rem auto 2rem;
		max-width: 41.25rem;
		padding: 3rem 1rem 1rem;

		&__header {
			display: grid;
			grid-template-columns: 1fr;
			gap: 0.5rem;
			margin-bottom: 1rem;
		}

		&__section {
			display: grid;
			grid-template-columns: 1fr;
			gap: 1.5rem;

			.checkbox-wrapper {
				display: grid;
				grid-template-columns: 1fr;
				gap: 1rem;
				position: relative;

				.field-label {
					color: ${colors.neutralText};

					&.with-error {
						color: ${colors.stateDanger};
					}
				}

				.checkbox-field {
					display: flex;
					align-items: center;

					& > * + * {
						padding-left: 0.75rem;
					}

					label {
						font-size: 1rem;
						line-height: 1.375rem;
						font-weight: 400;
						color: black;
						cursor: pointer;
					}
				}

				@media screen and (min-width: 768px) {
					grid-template-columns: 1fr 1fr;

					.field-label {
						grid-column: 1 / 3;
					}
				}
			}
		}
	}

	.modal-footer {
		display: flex;
		justify-content: flex-end;
		padding: 1.5rem;
		border-top: 1px solid ${colors.neutralForm};
	}
`;
