import React from 'react';
import PropTypes from 'prop-types';

import { CategoryStep, DetailsStep } from '../Steps';

export const InformationFlow = ({ currentStep }) => {
	return (
		<>
			{currentStep === 1 && <CategoryStep />}
			{currentStep === 2 && <DetailsStep />}
		</>
	);
};

InformationFlow.propTypes = {
	currentStep: PropTypes.number.isRequired,
};
