import styled, { css } from 'styled-components';

import colors from '../../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

export const CardWrapper = styled.div`
	text-decoration: none;
	display: flex;
	flex-direction: column;
	position: relative;
	border-bottom: 1px solid ${colors.neutralForm};
	padding: 0.75rem 0;
	cursor: pointer;

	@media screen and (min-width: 1400px) {
		flex-direction: column;
	}

	.review-card {
		&__card-wrapper {
			display: flex;
		}

		&__thumbnail {
			max-width: 8.375rem;
			display: flex;
			align-items: center;

			picture {
				border-radius: 0.25rem;
				border: 1px solid ${colors.neutralForm};
			}
		}

		&__info-wrapper {
			flex: 1;
			margin-left: 0.75rem;
			min-width: 12.5rem;

			&__type {
				color: ${colors.neutralText};
			}

			&__title {
				margin-top: 0.25rem;
				color: black;
				margin-right: 1rem;
			}

			&__details-wrapper {
				display: grid;
				grid-template-columns: 1fr 1fr;
				margin-top: 0.5rem;
				gap: 0.5rem;

				&__detail-item {
					display: flex;
					align-items: center;
					color: ${colors.neutralButton};

					svg {
						width: 1.25rem;
						margin-right: 0.25rem;
					}
				}
			}
		}
	}

	&:first-child {
		padding-top: 0;
	}
`;

export const EmptyBusinessCard = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	${({ paddingTop = 2.625, paddingBottom = 3.375 }) => css`
		padding-top: ${paddingTop}rem;
		padding-bottom: ${paddingBottom}rem;
	`}

	.icon-box {
		margin-bottom: 1.25rem;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 10rem;
		height: 10rem;
		border-radius: 9999px;
		background-color: ${colors.neutralBackground};
		border: 1px solid ${colors.neutralForm};
		color: ${colors.neutralButton};
	}

	& > h4 {
		color: ${colors.neutralButton};
		margin-bottom: 0.5rem;
	}
	& > p {
		color: ${colors.neutralButton};
	}
`;
