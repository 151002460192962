import React, { useContext, useState, useRef, forwardRef, useImperativeHandle, useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { useForm } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ACCEPTABLE_FILE_TYPES, ROLES } from '../../../../../constants/general.constants';
import { createRequest } from '../../../../services/requests/createRequestService';
import { uploadResources } from '../../../../services/media';
import { sendEvent } from '../../../../services/metrics';
import { errorHandler } from '../../../../services/authService';
import { formatPayloadBeforeUpdate } from '../OverviewTab';
import { Card, Typography, Button, EmptyComponent, IconButton, TextInput, TextArea } from '../../../../components';
import { AppointmentDetailsContext } from '../../RequestsAppointmentDetails';
import { required } from '../../../../utils/form-default-errors';
import * as S from './FilesCard.styles';

const FilesEditModal = forwardRef((_, ref) => {
	const {
		state: { request },
		dispatch,
	} = useContext(AppointmentDetailsContext);
	const [isOpen, setIsOpen] = useState(false);

	const {
		handleSubmit,
		register,
		getValues,
		setValue,
		watch,
		reset,
		formState: { errors, isSubmitting },
	} = useForm();

	const privateFilesDropzone = useDropzone({
		onDrop: useCallback((acceptedFiles) => acceptedFiles.length > 0 && setValue('privateFiles', formatFileArrayValue('privateFiles', acceptedFiles)), []),
		accept: ACCEPTABLE_FILE_TYPES.RESOURCE_MEDIA.MIME_TYPES,
	});
	const publicFilesDropzone = useDropzone({
		onDrop: useCallback((acceptedFiles) => acceptedFiles.length > 0 && setValue('publicFiles', formatFileArrayValue('publicFiles', acceptedFiles)), []),
		accept: ACCEPTABLE_FILE_TYPES.RESOURCE_MEDIA.MIME_TYPES,
	});

	const privateFiles = watch('privateFiles');
	const publicFiles = watch('publicFiles');

	const removeIndexFromArray = (arrayName, index) =>
		setValue(
			arrayName,
			getValues(arrayName).filter((_, i) => i !== index)
		);

	function formatFileArrayValue(arrayName, files) {
		if (getValues(arrayName)) {
			const existingFiles = getValues(arrayName);
			return existingFiles.concat(files.filter((file) => !existingFiles.find((existingFile) => existingFile.name === file.name)));
		}
		return Array.from(files);
	}

	const handleOpen = () => {
		setValue(
			'privateFiles',
			request?.privateFiles?.map((file) => ({
				name: file.fileName,
				title: file.title,
				description: file.description,
				url: file.url,
			}))
		);
		setValue(
			'publicFiles',
			request?.publicFiles?.map((file) => ({
				name: file.fileName,
				title: file.title,
				description: file.description,
				url: file.url,
			}))
		);
		setIsOpen(true);
	};

	const handleClose = () => {
		setIsOpen(false);
		setTimeout(() => {
			reset();
		}, 200);
	};

	async function uploadFile(file) {
		if (file?.url) {
			return file;
		}
		const formData = new FormData();
		formData.append('file', file);

		const {
			data: { document },
		} = await uploadResources(formData);

		return { title: file?.title, description: file?.description, url: document };
	}

	async function asyncCaller(data) {
		const { privateFiles, publicFiles } = data;

		const newFiles = {
			publicFiles: [],
			privateFiles: [],
		};

		if (publicFiles?.length > 0) {
			const publicFilesData = await Promise.all(publicFiles.map(uploadFile));
			newFiles.publicFiles = publicFilesData;
		}
		if (privateFiles?.length > 0) {
			const privateFilesData = await Promise.all(privateFiles.map(uploadFile));
			newFiles.privateFiles = privateFilesData;
		}

		const newRequest = formatPayloadBeforeUpdate(request, { ...newFiles });

		const {
			data: { result },
		} = await createRequest(newRequest);
		dispatch({ type: 'setRequest', payload: result.request });
		handleClose();
	}

	const onSubmit = (e) => handleSubmit(asyncCaller)(e).catch(errorHandler);

	useImperativeHandle(ref, () => ({
		open: handleOpen,
		close: handleClose,
	}));

	return (
		<ReactModal ref={ref} closeTimeoutMS={200} isOpen={isOpen} onRequestClose={handleClose}>
			<S.Form onSubmit={onSubmit}>
				<IconButton className="close-button" type="button" icon={['fal', 'times']} onClick={handleClose} />
				<S.InfoWrapper>
					<Typography tag="h2" weight="extrablack" center>
						Edit Files
					</Typography>
					<Typography tag="p" center>
						Add or remove files for this request.
					</Typography>
				</S.InfoWrapper>
				<S.FieldsWrapper>
					<S.FilesListWrapper>
						<header>
							<Typography tag="h3" weight="bold">
								Private Files
							</Typography>
							<Typography tag="p" variation="2">
								Upload files associated with the request that only you will see.
							</Typography>
						</header>

						<S.FileListDropzone {...privateFilesDropzone.getRootProps()} isDragActive={privateFilesDropzone.isDragActive} isDragAccept={privateFilesDropzone.isDragAccept} isDragReject={privateFilesDropzone.isDragReject}>
							<input {...privateFilesDropzone.getInputProps()} />

							<div className="upload-placeholder">
								<div className="upload-placeholder__picture">
									<FontAwesomeIcon icon={['fal', 'image']} size="2x" />
								</div>
								<Typography tag="p" variation="2" center className="upload-placeholder__paragraph">
									Drag and drop files here, or <strong>browse</strong>
									<br />
									Supports PDF, Doc, PPT, JPG, PNG
								</Typography>
							</div>
						</S.FileListDropzone>

						<div className="files-wrapper">
							{privateFiles?.map((file, index) => (
								<div key={file.name} className="files-wrapper__item">
									<header>
										<Typography tag="h4" weight="bold">
											File {index + 1}
										</Typography>
										<IconButton type="button" icon={['fal', 'trash']} size={1} onClick={() => removeIndexFromArray('privateFiles', index)} />
									</header>

									<div className="files-wrapper__item__info">
										<Typography tag="h6" weight="semibold">
											File
										</Typography>
										<div className="files-wrapper__item__details">
											<FontAwesomeIcon icon={['fal', 'file']} size="lg" />
											<div>
												<Typography tag="p">{file.name}</Typography>
											</div>
										</div>
									</div>

									<TextInput label="Title" error={errors.privateFiles?.[index]?.title} {...register('privateFiles.' + index + '.title', { required: required('Title') })} />

									<TextArea label="Description" error={errors.privateFiles?.[index]?.description} {...register('privateFiles.' + index + '.description', { required: required('Description') })} />
								</div>
							))}
						</div>
					</S.FilesListWrapper>

					<S.FilesListWrapper>
						<header>
							<Typography tag="h3" weight="bold">
								Public Files
							</Typography>
							<Typography tag="p" variation="2">
								Upload files associated with the request that anyone can view and download.
							</Typography>
						</header>

						<S.FileListDropzone {...publicFilesDropzone.getRootProps()} isDragActive={publicFilesDropzone.isDragActive} isDragAccept={publicFilesDropzone.isDragAccept} isDragReject={publicFilesDropzone.isDragReject}>
							<input {...publicFilesDropzone.getInputProps()} />

							<div className="upload-placeholder">
								<div className="upload-placeholder__picture">
									<FontAwesomeIcon icon={['fal', 'image']} size="2x" />
								</div>
								<Typography tag="p" variation="2" center className="upload-placeholder__paragraph">
									Drag and drop files here, or <strong>browse</strong>
									<br />
									Supports PDF, Doc, PPT, JPG, PNG
								</Typography>
							</div>
						</S.FileListDropzone>

						<div className="files-wrapper">
							{publicFiles?.map((file, index) => (
								<div key={file.name} className="files-wrapper__item">
									<header>
										<Typography tag="h4" weight="bold">
											File {index + 1}
										</Typography>
										<IconButton type="button" icon={['fal', 'trash']} size={1} onClick={() => removeIndexFromArray('publicFiles', index)} />
									</header>

									<div className="files-wrapper__item__info">
										<Typography tag="h6" weight="semibold">
											File
										</Typography>
										<div className="files-wrapper__item__details">
											<FontAwesomeIcon icon={['fal', 'file']} size="lg" />
											<div>
												<Typography tag="p">{file.name}</Typography>
											</div>
										</div>
									</div>

									<TextInput label="Title" error={errors.publicFiles?.[index]?.title} {...register('publicFiles.' + index + '.title', { required: required('Title') })} />

									<TextArea label="Description" error={errors.publicFiles?.[index]?.description} {...register('publicFiles.' + index + '.description', { required: required('Description') })} />
								</div>
							))}
						</div>
					</S.FilesListWrapper>
				</S.FieldsWrapper>
				<S.ButtonsWrapper>
					<Button type="submit">
						<Typography weight="bold" variation="button-medium">
							{isSubmitting ? 'Loading' : 'Save'}
						</Typography>
					</Button>
				</S.ButtonsWrapper>
			</S.Form>
		</ReactModal>
	);
});

FilesEditModal.displayName = 'FilesEditModal';

export const FilesCard = ({ className, role }) => {
	const {
		state: { request },
	} = useContext(AppointmentDetailsContext);
	const { publicFiles, privateFiles } = request;

	const user = useSelector((state) => state.user.value);

	const modalRef = useRef(null);

	const handleEditClick = () => {
		modalRef.current.open();
	};

	const sendEventOnDownload = async (contentId, contentUrl) => {
		await sendEvent({
			requestId: request.id,
			action: 'download',
			content: contentUrl,
			contentId,
		});
	};

	return (
		<Card
			title="Files"
			className={className}
			transparentHeaderBorder
			actions={(role != null && request.creator != null && request.creator.id === user?.id) || role === ROLES.ADMIN || role === ROLES.SUPER ? [{ id: 1, label: 'Edit', size: 'small', variant: 'outline', onClick: handleEditClick, icon: { source: ['fal', 'edit'] } }] : []}
		>
			<S.Wrapper>
				{publicFiles?.length > 0 && (
					<>
						<Typography tag="h4" weight="bold">
							Public Files
						</Typography>
						{publicFiles?.map((file) => (
							<div key={file.id} className="file-wrapper">
								<div className="file-wrapper__heading">
									<Typography tag="h5" weight="bold">
										{file.title}
									</Typography>
									{role != null && (
										<a
											href={file.url}
											download
											onClick={() => {
												sendEventOnDownload(file.id, file.url);
											}}
										>
											<Button size="small" variant="text">
												<FontAwesomeIcon icon={['fal', 'arrow-to-bottom']} />
												<Typography variation="button-small" weight="bold">
													Download
												</Typography>
											</Button>
										</a>
									)}
								</div>
								<div className="file-wrapper__body">
									<div className="file-wrapper__element">
										<Typography className="file-wrapper__title" tag="h6" weight="semibold">
											File
										</Typography>
										<Typography className="file-wrapper__value" tag="p">
											{file.fileName}
										</Typography>
									</div>
									<div className="file-wrapper__element">
										<Typography className="file-wrapper__title" tag="h6" weight="semibold">
											Description
										</Typography>
										<Typography className="file-wrapper__value" tag="p">
											{file.description}
										</Typography>
									</div>
								</div>
							</div>
						))}
					</>
				)}
				{!!role && privateFiles?.length > 0 && (
					<>
						<Typography tag="h4" weight="bold">
							Private Files
						</Typography>
						{privateFiles.map((file) => (
							<div key={file.id} className="file-wrapper">
								<div className="file-wrapper__heading">
									<Typography tag="h5" weight="bold">
										{file.title}
									</Typography>
									{role != null && (
										<a
											href={file.url}
											download
											onClick={() => {
												sendEventOnDownload(file.id, file.url);
											}}
										>
											<Button size="small" variant="text">
												<FontAwesomeIcon icon={['fal', 'arrow-to-bottom']} />
												<Typography variation="button-small" weight="bold">
													Download
												</Typography>
											</Button>
										</a>
									)}
								</div>
								<div className="file-wrapper__body">
									<div className="file-wrapper__element">
										<Typography className="file-wrapper__title" tag="h6" weight="semibold">
											File
										</Typography>
										<Typography className="file-wrapper__value" tag="p">
											{file.fileName}
										</Typography>
									</div>
									<div className="file-wrapper__element">
										<Typography className="file-wrapper__title" tag="h6" weight="semibold">
											Description
										</Typography>
										<Typography className="file-wrapper__value" tag="p">
											{file.description}
										</Typography>
									</div>
								</div>
							</div>
						))}
					</>
				)}
				{publicFiles?.length === 0 && privateFiles?.length === 0 && <EmptyComponent title="No files" message="There aren't any files available for visualization." icon={['fal', 'file']} />}
			</S.Wrapper>
			<FilesEditModal ref={modalRef} />
		</Card>
	);
};
FilesCard.propTypes = {
	className: PropTypes.string,
	role: PropTypes.string,
};
