import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import logo from '../../assets/ncbce-logo-alt.png';
import { Typography, Button, Link } from '../../components';
import * as S from './Signup.styles';
import { META_TITLE } from '../../../constants/general.constants';

const Signup = () => {
	const history = useHistory();

	useEffect(() => {
		// Set document title
		document.title = `Sign Up | ${META_TITLE}`;
	}, []);

	function handleSignUpClick() {
		window.location.href = `${process.env.REACT_APP_API_URL}/sso/login?type=signup`;
	}

	function handleBusinessSignUpClick() {
		if (process.env.REACT_APP_REDIRECT_BUSINESS_AUTH === 'true') {
			window.location = 'https://wblnavigator.org/web/organization-signup-form/';
		} else {
			history.push('/business/sign-up');
		}
	}

	return (
		<S.Wrapper>
			<S.Header>
				<div className="flex-1" />
				<div className="centered">
					<Link to="/directory">
						<S.Picture>
							<img src={logo} alt="NCBCE logo" />
						</S.Picture>
					</Link>
				</div>
				<div className="flex-1" />
			</S.Header>
			<S.Container>
				<Typography tag="h1" weight="extrablack" center>
					Sign Up
				</Typography>
				<Typography tag="p" className="description" center>
					Please select which user type you are.
				</Typography>

				<S.ChoicesWrapper>
					<S.OptionElement>
						<FontAwesomeIcon className="icon" icon="graduation-cap" />
						<Typography tag="h5">I am an</Typography>
						<Typography className="option-name" tag="h3" weight="bold" center>
							Educator
						</Typography>
						<Button onClick={handleSignUpClick}>
							<Typography variation="button-medium" weight="bold">
								Sign Up with NCEdCloud
							</Typography>
						</Button>
					</S.OptionElement>
					<S.OptionElement>
						<FontAwesomeIcon className="icon" icon="building" />
						<Typography tag="h5">I am a</Typography>
						<Typography className="option-name" tag="h3" weight="bold" center>
							Business/Organization
						</Typography>
						<Button onClick={handleBusinessSignUpClick}>
							<Typography variation="button-medium" weight="bold">
								Sign Up
							</Typography>
						</Button>
					</S.OptionElement>
				</S.ChoicesWrapper>
			</S.Container>

			<Typography tag="h6" className="login-message" center>
				<span>Already have an account?</span>&nbsp;<Link to="/login">Log In</Link>
			</Typography>
		</S.Wrapper>
	);
};

export default Signup;
