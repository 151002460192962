import styled, { css } from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	padding: 1rem;
	border-bottom: 1px solid ${colors.neutralForm};
`;

export const Container = styled.div`
	max-width: ${({ maxWidth }) => `${maxWidth}rem`};

	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	margin: 0.5rem auto 1rem;
	z-index: 0;
`;

export const ProgressBar = styled.div`
	position: absolute;
	display: flex;
	width: 100%;
	border-radius: 9999px;
	border: none;
	height: 0.1875rem;
	overflow: hidden;
	z-index: -1;
`;

export const Line = styled.div`
	flex: 1;
	background-color: ${colors.neutralText};

	${({ status }) => {
		switch (status) {
			case 'completed':
				return css`
					background-color: ${colors.stateSuccess};
				`;
			default:
				return css`
					background-color: ${colors.neutralText};
				`;
		}
	}}
`;

export const Dot = styled.div`
	position: relative;
	width: 1.875rem;
	height: 1.875rem;
	border-radius: 9999px;
	border-width: 2px;
	background-color: white;
	border: 2px solid ${colors.neutralText};

	display: flex;
	justify-content: center;
	align-items: center;

	.step-icon {
		display: none;
		color: white;
	}

	.step-number {
		font-size: 0.75rem;
		font-weight: 800;
		line-height: 1.125rem;
		letter-spacing: 0.5px;
		color: ${colors.neutralText};
	}

	.step-label {
		position: absolute;
		top: calc(100% + 0.25rem);
		font-size: 0.75rem;
		font-weight: 400;
		line-height: 1rem;
		color: ${colors.neutralText};
	}

	${({ status }) => {
		switch (status) {
			case 'active':
				return css`
					border-color: ${colors.brandPrimary};

					.step-number {
						color: ${colors.brandPrimary};
					}
					.step-label {
						color: ${colors.brandPrimary};
						font-weight: 700;
					}
				`;
			case 'completed':
				return css`
					border-color: ${colors.stateSuccess};
					background-color: ${colors.stateSuccess};

					.step-icon {
						display: block;
					}
					.step-number {
						display: none;
					}
					.step-label {
						color: ${colors.stateSuccess};
					}
				`;
		}
	}};
`;
