import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchUser, clearUser } from '../../app/slices/user/userSlice';

import logo from '../../assets/ncbce-logo-alt.png';
import { fetchGroups } from '../../services/groups';
import { logoutUser } from '../../services/authService';
import { updateUserOnboardingState } from '../../services/onboarding';
import { Button, IconButton, Spinner, Typography, ErrorComponent, ModalCreateGroup } from '../../components';

import * as S from './SignupTeacherGroups.styles';
import { META_TITLE } from '../../../constants/general.constants';
import { clearSavedStates } from '../../app/slices/filterSearch/filterSearchSlice';

const SignupTeacherGroups = () => {
	const [pageStatus, setPageStatus] = useState('idle');
	const history = useHistory();
	const dispatch = useDispatch();
	const [currentSelectedGroup, setCurrentSelectedGroup] = useState(null);
	const [groups, setGroups] = useState([]);
	const [isOpen, setIsOpen] = useState(false);

	function handleOpenModal() {
		setIsOpen(true);
	}

	function handleCloseModal() {
		setIsOpen(false);
		setCurrentSelectedGroup(null);
	}

	function handleEditClick(group) {
		setCurrentSelectedGroup(group);
		handleOpenModal();
	}

	const backToHome = () => {
		logoutUser().then(() => {
			dispatch(clearUser());
			dispatch(clearSavedStates());
			history.push('/login');
		});
	};

	async function handleCompleteClick() {
		// Fetch user
		await dispatch(fetchUser());

		// Push to dashboard
		history.push('/teacher/dashboard');
	}

	async function handleSkipClick() {
		// Handle complete groups onboarding
		await updateUserOnboardingState({ state: 'accountGroupSetupComplete' });

		// Handle complete click
		await handleCompleteClick();
	}

	async function initializeData() {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchGroups({ page: 0 });

			setGroups(result.groups);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}

	useEffect(() => {
		// Set document title
		document.title = `Groups | Teacher Sign Up | ${META_TITLE}`;

		// Load initial data
		if (pageStatus === 'idle') {
			initializeData();
		}
	}, [pageStatus]);

	const renderContent = () => {
		switch (pageStatus) {
			case 'loading':
				return <Spinner />;
			case 'error':
				return <ErrorComponent />;
			case 'success':
				return groups.length > 0 ? (
					<S.GroupListWrapper>
						{groups.map((group) => (
							<S.GroupListItem key={group.groupId}>
								<div className="group-header">
									<Typography tag="h5" weight="bold">
										{group.name}
									</Typography>
									<IconButton size={1.25} icon={['fal', 'edit']} onClick={() => handleEditClick(group)} />
								</div>
								<div className="details-wrapper">
									<div className="detail-item">
										<div className="icon-wrapper">
											<FontAwesomeIcon icon={['fal', 'users']} />
										</div>
										<Typography tag="p" variation="2">
											{group.totalStudents} Students
										</Typography>
									</div>
									<div className="detail-item">
										<div className="icon-wrapper">
											<FontAwesomeIcon icon={['fal', 'users-class']} />
										</div>
										<Typography tag="p" variation="2">
											{group.gradeLevel}
										</Typography>
									</div>
									<div className="detail-item">
										<div className="icon-wrapper">
											<FontAwesomeIcon icon={['fal', 'chalkboard']} />
										</div>
										<Typography tag="p" variation="2">
											{group.subject || `${group.ageRange} years`}
										</Typography>
									</div>
									<div className="detail-item">
										<div className="icon-wrapper">
											<FontAwesomeIcon icon={['fal', 'book']} />
										</div>
										<Typography tag="p" variation="2">
											{group.classCode || 'Standard Group'}
										</Typography>
									</div>
								</div>
							</S.GroupListItem>
						))}
						<Button className="add-new-group" variant="text">
							<FontAwesomeIcon icon={['fal', 'plus']} />
							<Typography variation="button-medium" weight="bold" onClick={handleOpenModal}>
								Add New Group
							</Typography>
						</Button>
						<Button className="complete-onboarding" onClick={handleCompleteClick}>
							<Typography variation="button-medium" weight="bold">
								Complete
							</Typography>
						</Button>
					</S.GroupListWrapper>
				) : (
					<S.FormWrapper>
						<S.GroupAvatar>
							<FontAwesomeIcon className="users-icon" icon={['fal', 'users']} />
						</S.GroupAvatar>
						<Typography className="form-title" tag="h4" weight="bold" center>
							You Have No Groups
						</Typography>
						<Typography className="form-description" tag="p" center>
							Add groups to...
						</Typography>
						<Button onClick={handleOpenModal}>
							<Typography variation="button-medium" weight="bold">
								Create a Group
							</Typography>
						</Button>
						<Button className="skip-button" variant="outline" onClick={handleSkipClick}>
							<Typography variation="button-medium" weight="bold">
								Skip
							</Typography>
						</Button>
					</S.FormWrapper>
				);
			default:
				return null;
		}
	};

	return (
		<>
			<S.Wrapper>
				<S.Header>
					<div className="flex-1">
						<Button
							variant="text"
							size="small"
							onClick={() => {
								backToHome();
							}}
						>
							<FontAwesomeIcon icon={['fal', 'arrow-left']} />
							<Typography className="button-label" variation="button-small">
								Back to Home
							</Typography>
						</Button>
					</div>
					<div className="centered">
						<S.Picture>
							<img src={logo} alt="NCBCE logo" />
						</S.Picture>
					</div>
					<div className="flex-1" />
				</S.Header>
				<S.Container>
					<Typography tag="h1" weight="extrablack" center>
						Groups
					</Typography>
					<Typography tag="p" className="description" center>
						Create a group for your school. A group is a set number of students that can all participate in an event or activity together. This could be a class, club, or other group.
					</Typography>
					{renderContent()}
				</S.Container>
			</S.Wrapper>
			<ModalCreateGroup isOpen={isOpen} onRequestClose={handleCloseModal} setGroups={setGroups} objectToEdit={currentSelectedGroup} />
		</>
	);
};

export default SignupTeacherGroups;
