import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Tab } from '../../../components';
import { ArchivedTab } from './ArchivedTab';
import { CompletedTab } from './CompletedTab';
import { DraftsTab } from './DraftsTab';
import { IncompleteTab } from './IncompleteTab';
import { PendingTab } from './PendingTab';
import { ScheduledTab } from './ScheduledTab';
import * as S from './RequestsTab.styles';

export const RequestsTab = ({ member }) => {
	const [tabs, setTabs] = useState([
		{
			id: 1,
			label: 'Pending',
			component: <PendingTab member={member} />,
		},
		{
			id: 2,
			label: 'Drafts',
			component: <DraftsTab member={member} />,
		},
		{
			id: 3,
			label: 'Scheduled',
			component: <ScheduledTab member={member} />,
		},
		{
			id: 4,
			label: 'Completed',
			component: <CompletedTab member={member} />,
		},
		{
			id: 5,
			label: 'Incomplete',
			component: <IncompleteTab member={member} />,
		},
		{
			id: 6,
			label: 'Archived',
			component: <ArchivedTab member={member} />,
		},
	]);

	return (
		<S.Wrapper>
			<Tab tabs={tabs} setTabs={setTabs} transparent variation="pill" />
		</S.Wrapper>
	);
};

RequestsTab.propTypes = {
	member: PropTypes.object,
};
