import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button, Typography } from '../../../components';
import * as S from './Header.styles';

export const Header = ({ title, backUrl, backTitle, avatar }) => {
	const history = useHistory();

	const goBack = () => {
		history.push(backUrl);
	};

	return (
		<S.Wrapper>
			<Button className="header__back-button" variant="text" size="small" onClick={goBack}>
				<FontAwesomeIcon icon={['fal', 'arrow-left']} />
				<Typography weight="bold" variation="button-small">
					{backTitle}
				</Typography>
			</Button>
			<div className="header__title-wrapper">
				<img className="header__title-wrapper__avatar" src={avatar} alt={`${title} logo`} />
				<Typography tag="h1" weight="extrablack">
					{title}
				</Typography>
			</div>
		</S.Wrapper>
	);
};

Header.propTypes = {
	title: PropTypes.string,
	backUrl: PropTypes.string,
	backTitle: PropTypes.string,
	avatar: PropTypes.string,
};
