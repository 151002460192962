import styled from 'styled-components';

import colors from '../../../../styles/colors';

export const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1rem;

	.header-wrapper {
		display: flex;
		align-items: center;
		grid-column: span 2;

		&__avatar {
			min-width: 3.5rem;
			width: 3.5rem;
			height: 3.5rem;
			border: 1px solid ${colors.neutralForm};
			border-radius: 9999px;
			margin-right: 0.5rem;
		}
	}

	.element-wrapper {
		display: flex;
		flex-direction: column;

		> h6 {
			color: ${colors.neutralText};
		}

		& > * + * {
			margin-top: 0.25rem;
		}
	}
`;
