import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormContext } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';

import { required } from '../../../../utils/form-default-errors';
import { Checkbox, TextArea, TextInput, Typography } from '../../../../components';
import * as S from '../StepTemplateOverview.styles';
import { CAREER_CLUSTERS, GRADE_LEVELS } from '../../../../../constants/general.constants';

export const RequestTemplate = () => {
	const {
		register,
		watch,
		setValue,
		formState: { errors },
	} = useFormContext();

	const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
		onDrop: useCallback((acceptedFiles) => acceptedFiles.length > 0 && setValue('coverImage', acceptedFiles[0], { shouldValidate: true }), []),
		multiple: false,
		accept: 'image/png, image/jpg, image/jpeg',
	});

	const renderCoverImage = (image) => {
		if (image) {
			if (image instanceof File) {
				return <img src={URL.createObjectURL(image)} alt="Cover" />;
			} else {
				return <img src={image[1500]} alt="Cover" />;
			}
		}
		return null;
	};

	const coverImage = watch('coverImage');

	return (
		<>
			<Typography tag="h2" weight="extrablack" center>
				Request Template
			</Typography>
			<Typography tag="p" center>
				Add in overview information for your request template.
			</Typography>

			<div className="fields-wrapper">
				<S.HugeImageUploader withError={errors.coverImage}>
					<Typography tag="label" htmlFor="cover-image" weight="semibold">
						Cover Image
					</Typography>
					<S.FileDropZone {...getRootProps()} isDragActive={isDragActive} isDragAccept={isDragAccept} isDragReject={isDragReject}>
						<input id="cover-image" {...register('coverImage', { required: required('Cover Image') })} {...getInputProps({ onChange: (e) => setValue('coverImage', e.target.files[0], { shouldValidate: true }) })} />
						{renderCoverImage(coverImage)}
					</S.FileDropZone>
					{coverImage && (
						<button className="reset-btn" type="button" onClick={() => resetField('coverImage')}>
							<FontAwesomeIcon icon={['fal', 'trash']} />
						</button>
					)}
					{!!errors.coverImage && (
						<div className="error-wrapper">
							<Typography tag="p" variation="2">
								{errors.coverImage.message}
							</Typography>
						</div>
					)}
				</S.HugeImageUploader>

				<TextInput
					label="Title"
					id="title"
					error={errors.title}
					{...register('title', {
						required: required('Title'),
					})}
				/>

				<TextArea
					label="Description"
					id="description"
					error={errors.description}
					{...register('description', {
						required: required('Description'),
					})}
				/>

				<S.CheckboxWrapper>
					<Typography className={`field-label${!!errors.gradeLevels ? ' with-error' : ''}`} tag="p" variation="2" weight="semibold">
						Grade Level(s)
					</Typography>

					{GRADE_LEVELS.map(({ value, label }) => (
						<div key={value} className="checkbox-field">
							<Checkbox id={value} value={value} {...register('gradeLevels', { required: required('Grade Level(s)') })} />
							<label htmlFor={value}>{label}</label>
						</div>
					))}

					{!!errors.gradeLevels && (
						<div className="error-wrapper">
							<Typography tag="p" variation="2">
								{errors.gradeLevels.message}
							</Typography>
						</div>
					)}
				</S.CheckboxWrapper>

				<S.CheckboxWrapper>
					<Typography className={`field-label${!!errors.careerClusters ? ' with-error' : ''}`} tag="p" variation="2" weight="semibold">
						Career Cluster
					</Typography>

					{CAREER_CLUSTERS.map(({ value, label }) => (
						<div key={value} className="checkbox-field">
							<Checkbox id={value} value={value} {...register('careerClusters', { required: required('Career Cluster') })} />
							<label htmlFor={value}>{label}</label>
						</div>
					))}

					{!!errors.careerClusters && (
						<div className="error-wrapper">
							<Typography tag="p" variation="2">
								{errors.careerClusters.message}
							</Typography>
						</div>
					)}
				</S.CheckboxWrapper>
			</div>
		</>
	);
};
