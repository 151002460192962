import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { fetchMessages } from '../../../../services/messages';
import { acceptRequestResponse } from '../../../../services/requests';
import { useChat } from '../../../../hooks';
import { Button, IconButton, Picture, Typography, Badge, Chat, MessageDialog, DeclineResponsesModal } from '../../../../components';
import { RequestsContext, BADGE_STATUS } from '../PostedRequestsTab';
import * as S from './RequestChat.styles';
import { errorHandler } from '../../../../services/authService';
import toast from 'react-hot-toast';

export const RequestChat = () => {
	const { state, dispatch } = useContext(RequestsContext);
	const { selectedConversation } = state;
	const { messages, setMessages } = useChat(selectedConversation.id);
	const [pageStatus, setPageStatus] = useState('loading');
	const [acceptIsOpen, setAcceptIsOpen] = useState(false);
	const history = useHistory();

	const declineResponsesModalRef = useRef(null);

	function handleOpenAcceptModal() {
		setAcceptIsOpen(true);
	}

	function handleCloseAcceptModal() {
		setAcceptIsOpen(false);
	}

	async function handleOpenDeclineModal(declineAll = false) {
		declineResponsesModalRef.current.open({
			requestId: selectedConversation.request.id,
			responseId: selectedConversation.id,
			declineAll,
		});
	}

	async function handleAcceptResponse(declineOthers = false) {
		try {
			const {
				data: { result },
			} = await acceptRequestResponse({ responseId: selectedConversation.id });
			dispatch({
				type: 'SET_SELECTED_CONVERSATION',
				payload: {
					id: result.response.id,
					status: result.response.status,
					business: result.response.business,
					request: result.request,
				},
			});
			toast.success('You have successfully accepted this response.');
			handleCloseAcceptModal();
			if (declineOthers === true) {
				handleOpenDeclineModal(true);
			}
		} catch (error) {
			errorHandler(error);
		}
	}

	async function initializeData() {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchMessages({ page: 0, displayLimit: 100000, requestId: selectedConversation.request.id, responseId: selectedConversation.id, filterOptions: { received: true, sent: true } });
			setMessages(result.messages);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}

	useEffect(() => {
		// Load initial data
		initializeData();
	}, [selectedConversation]);

	return (
		<S.RequestChatWrapper>
			<S.ChatHeader>
				<S.BackButtonWrapper>
					<Button variant="text" size="small" onClick={() => dispatch({ type: 'CLEAR_SELECTED_CONVERSATION' })}>
						<FontAwesomeIcon icon={['fal', 'arrow-left']} />
						<Typography className="button-label" variation="button-small" weight="bold">
							Back to Messages
						</Typography>
					</Button>
				</S.BackButtonWrapper>
				<S.InfoWrapper>
					<Picture className="avatar-image" src={selectedConversation.business.logoObj['200']} aspectRatio="1/1" alt="profile" />
					<div className="info-block">
						<Typography tag="h3" weight="bold">
							{selectedConversation.business.name}
						</Typography>
						<Typography tag="p" variation="2">
							{selectedConversation.request.title}
						</Typography>
						{selectedConversation.status !== 'pending' && (
							<Badge small="small" type={BADGE_STATUS[selectedConversation.status].color} variant="outlined">
								{BADGE_STATUS[selectedConversation.status].label}
							</Badge>
						)}
					</div>
					<IconButton
						className="info-button"
						icon={['fal', 'info-circle']}
						onClick={() => {
							history.push({ pathname: `/teacher/directory/business/${selectedConversation.business.id}`, state: { backUrl: window.location.pathname, backTitle: 'Back to Messages' } });
						}}
					/>
				</S.InfoWrapper>
				{selectedConversation.status === 'pending' && (
					<>
						<S.ButtonWrapper>
							<Button
								variant="outline"
								size="small"
								variation="warning"
								onClick={() => {
									handleOpenDeclineModal(false);
								}}
							>
								<Typography className="button-label" variation="button-small" weight="bold">
									Decline
								</Typography>
							</Button>
							<Button
								variant="outline"
								size="small"
								onClick={() => {
									handleOpenAcceptModal();
								}}
							>
								<Typography className="button-label" variation="button-small" weight="bold">
									Accept
								</Typography>
							</Button>
						</S.ButtonWrapper>
						<MessageDialog
							isOpen={acceptIsOpen}
							onRequestClose={handleCloseAcceptModal}
							title="Accept Request Response?"
							subtitle="Are you ready to accept this response? The responding business will be notified."
							icon={['fal', 'file-plus']}
							actions={[
								{
									id: 'acceptDecline',
									label: 'Accept, Decline All Others',
									variant: 'outline',
									variation: 'default',
									onClick: () => {
										handleAcceptResponse(true);
									},
								},
								{
									id: 'acceptKeep',
									label: 'Accept, Keep Others Open',
									variant: 'solid',
									variation: 'default',
									onClick: () => {
										handleAcceptResponse(false);
									},
								},
							]}
						/>
						<DeclineResponsesModal ref={declineResponsesModalRef} dispatch={dispatch} />
					</>
				)}
			</S.ChatHeader>
			<Chat
				loading={pageStatus === 'loading'}
				messages={messages}
				setMessages={setMessages}
				sendProps={
					selectedConversation.business
						? { requestId: selectedConversation.request.id, responseId: selectedConversation.id, businessId: selectedConversation.business.id }
						: { requestId: selectedConversation.request.id, responseId: selectedConversation.id, recipientId: selectedConversation.creator.id }
				}
			/>
		</S.RequestChatWrapper>
	);
};
