import styled, { css } from 'styled-components';

import colors from '../../styles/colors';

export const AbsoluteWrapper = styled.div`
	position: absolute;
	bottom: 0;
	right: 1.5rem;
	width: 100%;
	max-width: 29rem;
`;

export const Wrapper = styled.div`
	position: relative;
	border: 1px solid ${colors.neutralForm};
	box-shadow: ${colors.normalShadow};
`;

export const ChatHeader = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	background-color: white;
	margin: 0;
	padding: 0.75rem 1rem;
	user-select: none;
	cursor: pointer;
`;

export const ChatContainer = styled.div`
	border-top: 1px solid ${colors.neutralForm};
	display: none;
	visibility: hidden;
	${(props) =>
		props.isOpen &&
		css`
			display: block;
			visibility: visible;
		`}
`;

export const InfoWrapper = styled.div`
	display: flex;
	align-items: center;
	overflow: hidden;

	.avatar-image {
		width: 2.375rem;
		height: 2.375rem;
		border-radius: 9999px;
		margin-right: 0.75rem;
		user-select: none;
		pointer-events: none;
	}

	.info-block {
		flex: 1;
		overflow: hidden;

		h4 {
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}

	.close-button {
		margin-left: 0.75rem;
		color: ${colors.neutralButton};
	}

	p {
		color: ${colors.neutralText};
	}
`;
