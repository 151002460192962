import React, { useState, useEffect, useRef, createContext, useReducer } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { FILE_EXTENSIONS, META_TITLE } from '../../../constants/general.constants';
import { fetchTemplate, updatePublishStatusTemplate, archiveUnarchiveTemplate } from '../../services/templates';
import { formatAgeByGradeLevels } from '../../utils/ncbce-formats';
import { AdminNavigation, Button, Card, DetailPageHeader, ErrorComponent, Picture, Spinner, Typography } from '../../components';
import { RequestCardEditModal } from './RequestCardEditModal';
import { FileCardEditModal } from './FileCardEditModal';
import * as S from './AdminTemplate.styles';
import { errorHandler } from '../../services/authService';

export const AdminTemplateContext = createContext();

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_TEMPLATE':
			return {
				...state,
				template: action.payload,
			};
		default:
			return state;
	}
};

const AdminTemplate = () => {
	const { id } = useParams();
	const history = useHistory();
	const location = useLocation();
	const backUrl = location.state?.backUrl || '/admin/templates';
	const backTitle = location.state?.backTitle || 'Back to Templates';

	const templateRef = useRef();

	const [state, dispatch] = useReducer(reducer, {});
	const [pageStatus, setPageStatus] = useState('idle');

	async function archiveUnarchive() {
		try {
			const {
				data: {
					result: { template },
				},
			} = await archiveUnarchiveTemplate({ templateId: state.template.id });
			dispatch({ type: 'SET_TEMPLATE', payload: template });
		} catch (error) {
			errorHandler(error);
		}
	}

	async function publishTemplate() {
		try {
			const {
				data: {
					result: { template },
				},
			} = await updatePublishStatusTemplate({ templateId: state.template.id });
			dispatch({ type: 'SET_TEMPLATE', payload: template });
		} catch (error) {
			errorHandler(error);
		}
	}

	async function initializeData() {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchTemplate({ templateId: id });
			dispatch({ type: 'SET_TEMPLATE', payload: result.template });
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}

	const renderButtonsByStatus = (status) => {
		switch (status) {
			case 'published':
				return (
					<Button onClick={archiveUnarchive}>
						<Typography weight="bold" variation="button-medium">
							Archive
						</Typography>
					</Button>
				);
			case 'archived':
				return (
					<Button onClick={archiveUnarchive}>
						<Typography weight="bold" variation="button-medium">
							Unarchive
						</Typography>
					</Button>
				);
			case 'draft':
				return (
					<>
						<Button variant="outline" onClick={archiveUnarchive}>
							<Typography weight="bold" variation="button-medium">
								Archive
							</Typography>
						</Button>
						<Button onClick={publishTemplate}>
							<Typography weight="bold" variation="button-medium">
								Publish
							</Typography>
						</Button>
					</>
				);
			default:
				return;
		}
	};

	const renderContentByType = (type) => {
		switch (type) {
			case 'file':
				return (
					<Card title="Overview" transparentHeaderBorder actions={[{ id: 1, label: 'Edit', size: 'small', variant: 'outline', icon: { source: ['fal', 'edit'] }, onClick: () => templateRef.current.open() }]}>
						<S.CardWrapper>
							<div className="overview-bigicon">
								<FontAwesomeIcon icon={FILE_EXTENSIONS.find((ext) => ext.value === state.template.fileExtension).icon} size="7x" />
							</div>
							<div className="overview-info">
								<div className="overview-info__element a1">
									<Typography className="overview-info__title" tag="h6" weight="semibold">
										Description
									</Typography>
									<Typography className="overview-info__text" tag="p">
										{state.template.fileDescription}
									</Typography>
								</div>
								<div className="overview-info__element a2">
									<Typography className="overview-info__title" tag="h6" weight="semibold">
										Resource Type
									</Typography>
									<Typography className="overview-info__text" tag="p">
										{state.template.resourceType.charAt(0).toUpperCase() + state.template.resourceType.slice(1)}
									</Typography>
								</div>
								<div className="overview-info__element a3">
									<Typography className="overview-info__title" tag="h6" weight="semibold">
										Age
									</Typography>
									<Typography className="overview-info__text" tag="p">
										{formatAgeByGradeLevels()}
									</Typography>
								</div>
								<div className="overview-info__element a4">
									<Typography className="overview-info__title" tag="h6" weight="semibold">
										Career Cluster
									</Typography>
									<div className="overview-info__badges-wrapper">
										{state.template.categories.map((category, index) => (
											<div className="overview-info__badge" key={index}>
												{category}
											</div>
										))}
									</div>
								</div>
								<div className="overview-info__element a5">
									<Typography className="overview-info__title" tag="h6" weight="semibold">
										File Name
									</Typography>
									<Typography className="overview-info__text" tag="p">
										{state.template.fileName}
									</Typography>
								</div>
								<div className="overview-info__element a6">
									<Typography className="overview-info__title" tag="h6" weight="semibold">
										File Type
									</Typography>
									<Typography className="overview-info__text" tag="p">
										{FILE_EXTENSIONS.find((ext) => ext.value === state.template.fileExtension).label}
									</Typography>
								</div>
								<div className="overview-info__element a7">
									<Typography className="overview-info__title" tag="h6" weight="semibold">
										File Title
									</Typography>
									<Typography className="overview-info__text" tag="p">
										{state.template.fileTitle}
									</Typography>
								</div>
							</div>
						</S.CardWrapper>
						<FileCardEditModal ref={templateRef} />
					</Card>
				);
			case 'request':
			case 'resource':
				return (
					<Card title="Overview" transparentHeaderBorder actions={[{ id: 1, label: 'Edit', size: 'small', variant: 'outline', icon: { source: ['fal', 'edit'] }, onClick: () => templateRef.current.open() }]}>
						<S.CardWrapper>
							<Picture className="overview-picture" aspectRatio="3/2" src={state.template.coverImageObj['800']} />
							<div className="overview-info">
								<div className="overview-info__element a1">
									<Typography className="overview-info__title" tag="h6" weight="semibold">
										Description
									</Typography>
									<Typography className="overview-info__text" tag="p">
										{state.template.description}
									</Typography>
								</div>
								<div className="overview-info__element a2">
									<Typography className="overview-info__title" tag="h6" weight="semibold">
										Resource Type
									</Typography>
									<Typography className="overview-info__text" tag="p">
										{state.template.resourceType.charAt(0).toUpperCase() + state.template.resourceType.slice(1)}
									</Typography>
								</div>
								<div className="overview-info__element a3">
									<Typography className="overview-info__title" tag="h6" weight="semibold">
										Age
									</Typography>
									<Typography className="overview-info__text" tag="p">
										{formatAgeByGradeLevels(state.template.gradeLevels)}
									</Typography>
								</div>
								<div className="overview-info__element a4">
									<Typography className="overview-info__title" tag="h6" weight="semibold">
										Career Cluster
									</Typography>
									<div className="overview-info__badges-wrapper">
										{state.template.careerClusters.map((careerCluster, index) => (
											<div className="overview-info__badge" key={index}>
												{careerCluster}
											</div>
										))}
									</div>
								</div>
							</div>
						</S.CardWrapper>
						<RequestCardEditModal ref={templateRef} />
					</Card>
				);
			default:
				return;
		}
	};

	const renderContent = () => {
		switch (pageStatus) {
			case 'loading':
				return <Spinner />;
			case 'error':
				return <ErrorComponent />;
			case 'success':
				return (
					<>
						<DetailPageHeader title={state.template.title || state.template.fileTitle} badge={state.template.status.charAt(0).toUpperCase() + state.template.status.slice(1)} backButton={{ label: backTitle, onClick: () => history.push(backUrl) }}>
							{renderButtonsByStatus(state.template.status)}
						</DetailPageHeader>
						<S.Wrapper>{renderContentByType(state.template.type)}</S.Wrapper>
					</>
				);
			default:
				return null;
		}
	};

	useEffect(() => {
		// Set document title
		document.title = `Template Details | ${META_TITLE}`;

		// Initialize data
		if (pageStatus === 'idle') {
			initializeData();
		}
	}, []);

	return (
		<AdminNavigation>
			<AdminTemplateContext.Provider value={{ state, dispatch }}>{renderContent()}</AdminTemplateContext.Provider>
		</AdminNavigation>
	);
};

export default AdminTemplate;
