import React, { useState, useEffect, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormContext } from 'react-hook-form';
import { format } from 'date-fns';

import { formatToDayMonth2DigitYear } from '../../../utils/time-formatter';
import { fetchBusinessProfile } from '../../../services/directory';
import { fetchBusinessLocations } from '../../../services/businessLocation';
import { bytesToSize } from '../../../utils/filesize-formatter';
import { Picture, Typography } from '../../../components';
import { AGE_GROUPS, ROLES } from '../../../../constants/general.constants';
import * as S from '../CreateResource.styles';

export const StepReview = () => {
	const user = useSelector((state) => state.user.value);

	const [locationsLoading, setLocationsLoading] = useState('idle');
	const [businessProfile, setBusinessProfile] = useState(undefined);

	const { getValues } = useFormContext();

	const shouldShowContentWrapper = (section) => {
		switch (section) {
			case 'overview':
				return true;
			case 'files':
				return getValues('publicFiles')?.length > 0 || getValues('privateFiles')?.length > 0;
			case 'event-link':
				return getValues('type') === 'event';
			case 'locations':
				return (getValues('type') === 'appointment' || getValues('type') === 'event') && getValues('locationTypes')?.length > 0;
			case 'links':
				return getValues('type') === 'information' && getValues('links')?.length > 0;
			case 'availability':
				return getValues('type') === 'appointment';
			case 'date-and-time':
				return getValues('type') === 'event';
			default:
				return;
		}
	};

	const renderCoverImage = (image) => {
		if (image) {
			if (image instanceof File) {
				return <Picture className="review__cover-image" src={URL.createObjectURL(image)} aspectRatio="3/2" />;
			} else {
				return <Picture className="review__cover-image" src={image[1500]} aspectRatio="3/2" />;
			}
		}
		return null;
	};

	async function initializeData() {
		setLocationsLoading('loading');
		try {
			if (user.role === ROLES.ADMIN || user.role === ROLES.SUPER) {
				const {
					data: { result },
				} = await fetchBusinessLocations();
				setBusinessProfile({ locations: result.locations });
			} else {
				const {
					data: { result },
				} = await fetchBusinessProfile({ businessId: user?.business?.id });
				setBusinessProfile(result.business);
			}
			setLocationsLoading('success');
		} catch (error) {
			setLocationsLoading('error');
		}
	}

	useEffect(() => {
		if (locationsLoading === 'idle') {
			initializeData();
		}
	}, [locationsLoading]);

	return (
		<S.FormPanel>
			<Typography tag="h2" weight="extrablack" center>
				Review
			</Typography>
			<Typography tag="p" center>
				Please look over the request you are about to create.
			</Typography>

			<div className="fields-wrapper">
				{shouldShowContentWrapper('overview') && (
					<div className="review__step-item review__overview">
						<Typography tag="h3" weight="bold">
							Overview
						</Typography>

						{renderCoverImage(getValues('coverImage'))}

						<div className="review__info-item">
							<Typography tag="h6" weight="semibold">
								Resource Title
							</Typography>
							<Typography tag="p">{getValues('title')}</Typography>
						</div>

						<div className="review__info-item">
							<Typography tag="h6" weight="semibold">
								Description
							</Typography>
							<Typography tag="p">{getValues('description')}</Typography>
						</div>

						<div className="review__info-item review__info-item--with-badge">
							<Typography tag="h6" weight="semibold">
								Career Cluster
							</Typography>
							<div className="badge-wrapper">
								{getValues('careerClusters').map((value) => (
									<div className="small-badge" key={value}>
										{value}
									</div>
								))}
							</div>
						</div>

						<div className="review__info-item review__info-item--with-badge">
							<Typography tag="h6" weight="semibold">
								Grade Level
							</Typography>
							<div className="badge-wrapper">
								{getValues('ageGroups').map((value) => (
									<div className="small-badge" key={value}>
										{AGE_GROUPS.find((grade) => grade.value === value).label}
									</div>
								))}
							</div>
						</div>
					</div>
				)}

				{shouldShowContentWrapper('files') && (
					<div className="review__step-item review__files">
						<Typography tag="h3" weight="bold">
							Files
						</Typography>

						{getValues('publicFiles')?.length > 0 && (
							<>
								<Typography tag="h4" weight="bold">
									Public Files
								</Typography>

								{getValues('publicFiles')?.map((file, index) => (
									<div className="review__file-item" key={file.name}>
										<Typography tag="h5" weight="bold">
											File {index + 1}
										</Typography>

										<div className="review__info-item">
											<Typography tag="h6" weight="semibold">
												File
											</Typography>
											<div className="review__file-detail">
												<FontAwesomeIcon icon={['fal', 'file']} size="lg" />
												<div>
													<Typography tag="p">{file.name}</Typography>
													<Typography tag="p" variation="2">
														{bytesToSize(file.size)}
													</Typography>
												</div>
											</div>
										</div>

										<div className="review__info-item">
											<Typography tag="h6" weight="semibold">
												File Name
											</Typography>
											<Typography tag="p">{file.title}</Typography>
										</div>

										<div className="review__info-item">
											<Typography tag="h6" weight="semibold">
												File Description
											</Typography>
											<Typography tag="p">{file.description}</Typography>
										</div>
									</div>
								))}
							</>
						)}

						{getValues('privateFiles')?.length > 0 && (
							<>
								<Typography tag="h4" weight="bold">
									Private Files
								</Typography>

								{getValues('privateFiles')?.map((file, index) => (
									<div className="review__file-item" key={file.name}>
										<Typography tag="h5" weight="bold">
											File {index + 1}
										</Typography>

										<div className="review__info-item">
											<Typography tag="h6" weight="semibold">
												File
											</Typography>
											<div className="review__file-detail">
												<FontAwesomeIcon icon={['fal', 'file']} size="lg" />
												<div>
													<Typography tag="p">{file.name}</Typography>
													<Typography tag="p" variation="2">
														{bytesToSize(file.size)}
													</Typography>
												</div>
											</div>
										</div>

										<div className="review__info-item">
											<Typography tag="h6" weight="semibold">
												File Name
											</Typography>
											<Typography tag="p">{file.title}</Typography>
										</div>

										<div className="review__info-item">
											<Typography tag="h6" weight="semibold">
												File Description
											</Typography>
											<Typography tag="p">{file.description}</Typography>
										</div>
									</div>
								))}
							</>
						)}
					</div>
				)}

				{shouldShowContentWrapper('event-link') && (
					<div className="review__step-item review__event-link">
						<Typography tag="h3" weight="bold">
							Event Link
						</Typography>

						<div className="review__info-item">
							<Typography tag="h6" weight="semibold">
								Event Link
							</Typography>
							<Typography tag="p">{getValues('eventLink')}</Typography>
						</div>

						{getValues('eventLinkNotes') && (
							<div className="review__info-item">
								<Typography tag="h6" weight="semibold">
									Event Link Notes
								</Typography>
								<Typography tag="p">{getValues('eventLinkNotes')}</Typography>
							</div>
						)}
					</div>
				)}

				{shouldShowContentWrapper('locations') && (
					<div className="review__step-item review__locations">
						<Typography tag="h3" weight="bold">
							Location
						</Typography>

						{getValues('locationTypes')?.includes('virtual') && (
							<div className="review__location-item">
								<div className="location-icon-wrapper">
									<FontAwesomeIcon icon={['fal', 'camera']} size="lg" />
								</div>
								<div className="location-info-wrapper">
									<Typography tag="h4" weight="bold">
										Virtual
									</Typography>
									{getValues('virtualLocationNotes') && (
										<div className="review__info-item">
											<Typography tag="h6" weight="semibold">
												Notes
											</Typography>
											<Typography tag="p">{getValues('virtualLocationNotes')}</Typography>
										</div>
									)}
								</div>
							</div>
						)}

						{getValues('locationTypes')?.includes('worksite') && locationsLoading === 'success' && (
							<div className="review__location-item">
								<div className="location-icon-wrapper">
									<FontAwesomeIcon icon={['fal', 'building']} size="lg" />
								</div>
								<div className="location-info-wrapper">
									<Typography tag="h4" weight="bold">
										Worksite Location
									</Typography>
									{getValues('worksiteLocations')?.map((location, index) => (
										<Fragment key={index}>
											<div className="review__info-item custom-margin">
												<S.InformationCard>
													<div className="icon-wrapper">
														<FontAwesomeIcon icon="school" />
													</div>
													<div className="info-wrapper">
														<Typography tag="h5" weight="bold">
															{businessProfile?.locations.find((l) => l.id === location.locationId)?.name}
														</Typography>
														<div className="info-line">
															<FontAwesomeIcon icon={['fal', 'map-marker']} />
															<Typography tag="p" variation="2">
																{`${businessProfile?.locations.find((l) => l.id === location.locationId)?.address?.addressLine1}, ${businessProfile?.locations.find((l) => l.id === location.locationId)?.address?.state} ${
																	businessProfile?.locations.find((l) => l.id === location.locationId)?.address?.postalCode
																}`}
															</Typography>
														</div>
														<div className="info-line">
															<FontAwesomeIcon icon={['fal', 'landmark']} />
															<Typography tag="p" variation="2">
																{businessProfile?.locations.find((l) => l.id === location.locationId)?.address?.city}
															</Typography>
														</div>
														<div className="info-line">
															<FontAwesomeIcon icon={['fal', 'school']} />
															<Typography tag="p" variation="2">
																{businessProfile?.locations.find((l) => l.id === location.locationId)?.searchTag}
															</Typography>
														</div>
													</div>
												</S.InformationCard>
											</div>
											{getValues(`worksiteLocations.${index}.notes`) && (
												<div className="review__info-item">
													<Typography tag="h6" weight="semibold">
														Notes
													</Typography>
													<Typography tag="p">{getValues(`worksiteLocations.${index}.notes`)}</Typography>
												</div>
											)}
										</Fragment>
									))}
								</div>
							</div>
						)}

						{getValues('locationTypes')?.includes('student') && (
							<div className="review__location-item">
								<div className="location-icon-wrapper">
									<FontAwesomeIcon icon={['fal', 'users']} size="lg" />
								</div>
								<div className="location-info-wrapper">
									<Typography tag="h4" weight="bold">
										Student Location
									</Typography>

									<div className="review__info-item">
										<Typography tag="h6" weight="semibold">
											Maximum Travel Distance
										</Typography>
										<Typography tag="p">{getValues('studentMaxTravelDistance')} mi</Typography>
									</div>
								</div>
							</div>
						)}

						{getValues('locationTypes')?.includes('inperson') && (
							<div className="review__location-item">
								<div className="location-icon-wrapper">
									<FontAwesomeIcon icon={['fal', 'building']} size="lg" />
								</div>
								<div className="location-info-wrapper">
									<Typography tag="h4" weight="bold">
										In Person
									</Typography>

									<div className="review__info-item">
										<Typography tag="h6" weight="semibold">
											Address
										</Typography>
										<Typography tag="p">{`${getValues('inPersonLocationAddress.addressLine1')}, ${getValues('inPersonLocationAddress.city')}, ${getValues('inPersonLocationAddress.state')} ${getValues('inPersonLocationAddress.postalCode')}`}</Typography>
									</div>

									<div className="review__info-item">
										<Typography tag="h6" weight="semibold">
											Notes
										</Typography>
										<Typography tag="p">{getValues('inPersonNotes')}</Typography>
									</div>
								</div>
							</div>
						)}
					</div>
				)}

				{shouldShowContentWrapper('availability') && (
					<div className="review__step-item review__availability">
						<Typography tag="h3" weight="bold">
							Availability
						</Typography>

						<div className="review__info-item">
							<Typography tag="h6" weight="semibold">
								Start Date
							</Typography>
							<Typography tag="p">{formatToDayMonth2DigitYear(getValues('startDate'))}</Typography>
						</div>

						<div className="review__info-item">
							<Typography tag="h6" weight="semibold">
								Is this ongoing?
							</Typography>
							<Typography className="capitalize" tag="p">
								{getValues('isOngoing')}
							</Typography>
						</div>

						{getValues('isOngoing') === 'no' && (
							<div className="review__info-item">
								<Typography tag="h6" weight="semibold">
									End Date
								</Typography>
								<Typography tag="p">{formatToDayMonth2DigitYear(getValues('endDate'))}</Typography>
							</div>
						)}

						<div className="review__info-item">
							<Typography tag="h6" weight="semibold">
								Days
							</Typography>
							<Typography className="capitalize" tag="p">
								{getValues('days')?.join(', ')}
							</Typography>
						</div>
					</div>
				)}

				{shouldShowContentWrapper('date-and-time') && (
					<div className="review__step-item review__links">
						<Typography tag="h3" weight="bold">
							Date and Time
						</Typography>

						<div className="review__info-item">
							<Typography tag="h6" weight="semibold">
								Are there multiple time frames for this event?
							</Typography>
							<Typography className="capitalize" tag="p">
								{getValues('isMultiple') === 'yes' ? 'Yes, it is happening in multiple different time frames' : 'No, there is only one time frame for the event'}
							</Typography>
						</div>

						{getValues('isMultiple') === 'yes' ? (
							getValues('timeFrames')?.map((time, index) => (
								<Fragment key={index}>
									<Typography tag="h5" weight="bold">
										Time Frame {index + 1}
									</Typography>

									<div className="review__info-item">
										<Typography tag="h6" weight="semibold">
											Start
										</Typography>
										<Typography tag="p">{`${formatToDayMonth2DigitYear(time.startDate)} at ${format(time.startTime, 'h:mm a')}`}</Typography>
									</div>

									<div className="review__info-item">
										<Typography tag="h6" weight="semibold">
											End
										</Typography>
										<Typography tag="p">{`${formatToDayMonth2DigitYear(time.endDate)} at ${format(time.endTime, 'h:mm a')}`}</Typography>
									</div>
								</Fragment>
							))
						) : (
							<>
								<div className="review__info-item">
									<Typography tag="h6" weight="semibold">
										Start
									</Typography>
									<Typography tag="p">{`${formatToDayMonth2DigitYear(getValues('startDate'))} at ${format(getValues('startTime'), 'h:mm a')}`}</Typography>
								</div>

								<div className="review__info-item">
									<Typography tag="h6" weight="semibold">
										End
									</Typography>
									<Typography tag="p">{`${formatToDayMonth2DigitYear(getValues('endDate'))} at ${format(getValues('endTime'), 'h:mm a')}`}</Typography>
								</div>
							</>
						)}
					</div>
				)}

				{shouldShowContentWrapper('links') && (
					<div className="review__step-item review__links">
						<Typography tag="h3" weight="bold">
							Links
						</Typography>

						{getValues('links')?.map((link) => (
							<div className="review__file-item" key={link.url}>
								<Typography tag="h5" weight="bold">
									{link.title}
								</Typography>

								<div className="review__info-item">
									<Typography tag="h6" weight="semibold">
										Link
									</Typography>
									<Typography tag="p">{link.url}</Typography>
								</div>

								<div className="review__info-item">
									<Typography tag="h6" weight="semibold">
										Description
									</Typography>
									<Typography tag="p">{link.description}</Typography>
								</div>
							</div>
						))}
					</div>
				)}
			</div>
		</S.FormPanel>
	);
};
