import React, { createContext, useEffect, useReducer, useState } from 'react';

import { AdminNavigation, DetailPageHeader, ErrorComponent, MessageDialog, Spinner, Tab, Typography } from '../../components';
import * as S from './AdminCompanyLocationDetail.styles';
import { BookingsTab } from './BookingsTab';
import { EmployeesTab } from './EmployeesTab';
import { OverviewTab } from './OverviewTab';
import { RequestsTab } from './RequestsTab';
import { ResourcesTab } from './ResourcesTab';
import { ReviewsTab } from './ReviewsTab';
import { META_TITLE } from '../../../constants/general.constants';
import { useQuery } from '../../hooks/useQuery';
import { useHistory, useParams } from 'react-router-dom';
import { fetchBusinessLocation, removeBusinessLocation } from '../../services/businessLocation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const AdminCompanyDetailContext = createContext();

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_COMPANY_LOCATION':
			return {
				...state,
				companyLocation: action.payload,
			};
		default:
			return state;
	}
};

const AdminCompanyLocationDetail = () => {
	const [pageStatus, setPageStatus] = useState('idle');
	const [removeIsOpen, setRemoveIsOpen] = useState(false);
	const query = useQuery();
	const history = useHistory();
	const [state, dispatch] = useReducer(reducer, {});
	const { id: businessId, locationId } = useParams();

	const [tabs, setTabs] = useState([
		{
			id: 1,
			label: 'Overview',
			component: <OverviewTab />,
			active: query.get('tabId') === '1',
		},
		{
			id: 2,
			label: 'Resources',
			component: <ResourcesTab />,
			active: query.get('tabId') === '2',
		},
		{
			id: 3,
			label: 'Requests',
			component: <RequestsTab />,
			active: query.get('tabId') === '3',
		},
		{
			id: 4,
			label: 'Bookings',
			component: <BookingsTab />,
			active: query.get('tabId') === '4',
		},
		{
			id: 5,
			label: 'Employees',
			component: <EmployeesTab />,
			active: query.get('tabId') === '5',
		},
		{
			id: 6,
			label: 'Reviews',
			component: <ReviewsTab />,
			active: query.get('tabId') === '6',
		},
	]);

	function handleOpenRemoveModal() {
		setRemoveIsOpen(true);
	}

	function handleCloseRemoveModal() {
		setRemoveIsOpen(false);
	}

	async function initializeData() {
		try {
			setPageStatus('loading');
			const {
				data: { result },
			} = await fetchBusinessLocation({ businessId, locationId });
			dispatch({ type: 'SET_COMPANY_LOCATION', payload: result.location });
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}

	const handleRemoveLocation = async () => {
		try {
			await removeBusinessLocation({ businessId, locationId });
			history.push(`/admin/companies/${businessId}`);
		} catch (error) {
			errorHandler(error);
		}
	};

	const handleCreateResource = () => {
		history.push('/admin/create-resource', { businessId });
	};

	useEffect(() => {
		if (pageStatus === 'idle') {
			initializeData();
		}
	}, [pageStatus]);

	useEffect(() => {
		// Set document title
		document.title = `Company Location Details | ${META_TITLE}`;
	}, []);

	function renderContent() {
		switch (pageStatus) {
			case 'loading':
				return <Spinner />;
			case 'success':
				return (
					<AdminCompanyDetailContext.Provider value={{ state, dispatch }}>
						<DetailPageHeader title={state.companyLocation.name} backButton={{ label: 'Back to Company', onClick: () => history.push(`/admin/companies/${businessId}`) }}>
							<>
								<S.Button variant="outline" variation="warning" onClick={handleOpenRemoveModal}>
									<Typography tag="span" variation="2" weight="extrablack">
										Remove Location
									</Typography>
								</S.Button>
								<S.Button variant="solid" onClick={handleCreateResource}>
									<Typography tag="span" variation="2" weight="extrablack">
										<FontAwesomeIcon icon={['fal', 'plus']} />
										Create Resource
									</Typography>
								</S.Button>
								<MessageDialog
									isOpen={removeIsOpen}
									onRequestClose={handleCloseRemoveModal}
									title="Remove Company Location?"
									subtitle="Are you sure you want to remove this company location?"
									icon={['fal', 'building']}
									actions={[
										{ id: 'back', label: 'Cancel', variant: 'outline', variation: 'default' },
										{ id: 'cancel', label: 'Remove Location', variant: 'solid', variation: 'default', onClick: handleRemoveLocation },
									]}
								/>
							</>
						</DetailPageHeader>
						<S.Wrapper>
							<Tab tabs={tabs} setTabs={setTabs} />
						</S.Wrapper>
					</AdminCompanyDetailContext.Provider>
				);
			case 'error':
				return <ErrorComponent />;
			default:
				return null;
		}
	}

	return <AdminNavigation>{renderContent()}</AdminNavigation>;
};

export default AdminCompanyLocationDetail;
