import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Typography } from '../Typography';
import * as S from './Card.styles';

export const Card = ({ children, className, transparentHeaderBorder, title, actions = [], filterComponent }) => {
	return (
		<S.Card className={className}>
			{(title || actions.length > 0) && (
				<S.TitleWrapper transparentHeaderBorder={transparentHeaderBorder}>
					<S.Title>{title}</S.Title>
					{actions.length > 0 && (
						<S.ActionsWrapper>
							{actions.map(({ id, onClick, label, size, variant, variation, icon }) => (
								<S.ActionButton key={id} onClick={onClick} size={size === 'smaller' ? 'small' : size} variant={variant} variation={variation}>
									{icon && icon.placement !== 'right' && <FontAwesomeIcon icon={icon.source} />}
									<Typography variation={size === 'smaller' ? 'button-small' : 'button-medium'} weight="extrablack">
										{label}
									</Typography>
									{icon && icon.placement === 'right' && <FontAwesomeIcon icon={icon.source} size={size === 'smaller' ? 'sm' : '1x'} />}
								</S.ActionButton>
							))}
						</S.ActionsWrapper>
					)}
					{filterComponent}
				</S.TitleWrapper>
			)}
			{children}
		</S.Card>
	);
};

Card.displayName = 'Card';
Card.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	className: PropTypes.string,
	transparentHeaderBorder: PropTypes.bool,
	title: PropTypes.string,
	actions: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			onClick: PropTypes.func,
			label: PropTypes.string,
			size: PropTypes.oneOf(['medium', 'small', 'smaller']),
			variant: PropTypes.oneOf(['solid', 'outline', 'text']),
			variation: PropTypes.oneOf(['default', 'secondary', 'warning']),
			icon: PropTypes.shape({
				source: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
				placement: PropTypes.oneOf(['left', 'right']),
			}),
		})
	),
	className: PropTypes.string,
	filterComponent: PropTypes.object,
};
