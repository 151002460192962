import React, { forwardRef, useState, useImperativeHandle, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactModal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { format } from 'date-fns';

import * as S from './CompleteResourceEditModal.styles';
import { AGE_GROUPS, ROLES } from '../../../constants/general.constants';
import { errorHandler } from '../../services/authService';
import { required } from '../../utils/form-default-errors';
import { IconButton, Typography, Button } from '../../components';
import { AppointmentFlow } from './AppointmentFlow';
import { EventFlow } from './EventFlow';
import { InformationFlow } from './InformationFlow';
import { uploadResources } from '../../services/media';
import { createUpdateResource } from '../../services/resources';

const formatPayloadBeforeUpdate = (resource, payload) => {
	if (payload.type === 'information') {
		return {
			...resource,
			resourceId: resource?.id,
			startDate: resource?.availabilityStartDate,
			endDate: resource?.availabilityEndDate,
			days: resource?.availabilityDays,
			isOngoing: resource?.availabilityIsOngoing,
			availabilityIsOngoing: payload?.isOngoing || resource?.availabilityIsOngoing,
			ageGroups: [...new Set((resource?.gradeLevels || []).map((level) => AGE_GROUPS.find((group) => group.values.includes(level)).value))],
			worksiteLocations: resource?.worksiteLocations.map(({ location, notes }) => ({
				locationId: location.id,
				notes,
			})),
			...payload,
		};
	}
	if (payload.type === 'appointment') {
		return {
			...resource,
			resourceId: resource?.id,
			startDate: resource?.availabilityStartDate,
			endDate: resource?.availabilityEndDate,
			days: resource?.availabilityDays,
			ageGroups: [...new Set((resource?.gradeLevels || []).map((level) => AGE_GROUPS.find((group) => group.values.includes(level)).value))],
			worksiteLocations: resource?.worksiteLocations.map(({ location, notes }) => ({
				locationId: location.id,
				notes,
			})),
			...payload,
			startDate: payload.startDate ? format(payload.startDate, 'MM-dd-yyyy') : null,
			endDate: payload.endDate ? format(payload.endDate, 'MM-dd-yyyy') : null,
			availabilityIsOngoing: payload?.isOngoing || resource?.availabilityIsOngoing,
			isOngoing: payload.isOngoing === 'yes',
		};
	}
	if (payload.type === 'event') {
		return {
			...resource,
			resourceId: resource?.id,
			ageGroups: [...new Set((resource?.gradeLevels || []).map((level) => AGE_GROUPS.find((group) => group.values.includes(level)).value))],
			worksiteLocations: resource?.worksiteLocations.map(({ location, notes }) => ({
				locationId: location.id,
				notes,
			})),
			...payload,
			isMultiple: payload.isMultiple === 'yes',
			timeFrames:
				payload.isMultiple === 'yes'
					? payload.timeFrames.map((timeFrame) => {
							return {
								...timeFrame,
								startDate: format(timeFrame.startDate, 'MM-dd-yyyy'),
								endDate: format(timeFrame.endDate, 'MM-dd-yyyy'),
								startTime: format(timeFrame.startTime, 'h:mm a'),
								endTime: format(timeFrame.endTime, 'h:mm a'),
								notes: timeFrame.notes,
							};
					  })
					: [
							{
								startDate: payload.startDate ? format(payload.startDate, 'MM-dd-yyyy') : null,
								endDate: payload.endDate ? format(payload.endDate, 'MM-dd-yyyy') : null,
								startTime: payload.startTime ? format(payload.startTime, 'h:mm a') : null,
								endTime: payload.endTime ? format(payload.endTime, 'h:mm a') : null,
							},
					  ],
		};
	}
};

export const CompleteResourceEditModal = forwardRef(({ Context }, ref) => {
	const {
		state: { resource },
		dispatch,
	} = useContext(Context);

	const user = useSelector((state) => state.user.value);
	const [isOpen, setIsOpen] = useState(false);
	const [step, setStep] = useState(0);
	const history = useHistory();
	const methods = useForm();
	const {
		handleSubmit,
		register,
		setValue,
		reset,
		watch,
		formState: { errors, isSubmitting },
	} = methods;

	const stepCounts = {
		appointment: 3,
		event: 3,
		information: 2,
	};

	const currentlySelectedType = watch('type');

	function setFormValuesByType(type) {
		reset({ type });
		if (type === 'appointment') {
			setValue('category', resource.category);
			setValue('coverImage', resource.coverImageObj);
			setValue('title', resource.title);
			setValue('description', resource.description);
			setValue('ageGroups', [...new Set((resource?.gradeLevels || []).map((level) => AGE_GROUPS.find((group) => group.values.includes(level)).value))]);
			setValue('careerClusters', resource.careerClusters);
			setValue('startDate', resource.availabilityStartDate ? moment(resource.availabilityStartDate, 'MM-DD-YYYY', true).toDate() : null);
			setValue('isOngoing', !resource.availabilityEndDate ? 'yes' : 'no');
			setValue('endDate', resource.availabilityEndDate ? moment(resource.availabilityEndDate, 'MM-DD-YYYY', true).toDate() : null);
			setValue('days', resource.availabilityDays);
			setValue('availabilityNotes', resource.availabilityNotes);
		} else if (type === 'event') {
			setValue('coverImage', resource.coverImageObj);
			setValue('title', resource.title);
			setValue('description', resource.description);
			setValue('ageGroups', [...new Set((resource?.gradeLevels || []).map((level) => AGE_GROUPS.find((group) => group.values.includes(level)).value))]);
			setValue('careerClusters', resource.careerClusters);
			setValue('eventLink', resource.editableEventLink);
			setValue('eventLinkNotes', resource.eventLinkNotes);
			if (resource.timeFrames.length) {
				if (resource?.timeFrames?.length > 1) {
					setValue('isMultiple', 'yes');
					setValue(
						'timeFrames',
						resource.timeFrames.map((timeFrame) => ({
							startDate: moment(timeFrame.startDate, 'MM-DD-YYYY', true).toDate(),
							startTime: moment(timeFrame.startDate + ' ' + timeFrame.startTime, 'MM-DD-YYYY H:mm', true).toDate(),
							endDate: moment(timeFrame.endDate, 'MM-DD-YYYY', true).toDate(),
							endTime: moment(timeFrame.endDate + ' ' + timeFrame.endTime, 'MM-DD-YYYY H:mm', true).toDate(),
							notes: timeFrame.notes,
						}))
					);
				} else {
					setValue('isMultiple', 'no');
					setValue('startDate', resource?.timeFrames?.[0]?.startDate ? moment(resource?.timeFrames?.[0]?.startDate, 'MM-DD-YYYY', true).toDate() : null);
					setValue('startTime', resource?.timeFrames?.[0]?.startTime ? moment(resource?.timeFrames?.[0]?.startDate + ' ' + resource?.timeFrames?.[0]?.startTime, 'MM-DD-YYYY H:mm', true).toDate() : null);
					setValue('endDate', resource?.timeFrames?.[0]?.endDate ? moment(resource?.timeFrames?.[0]?.endDate, 'MM-DD-YYYY', true).toDate() : null);
					setValue('endTime', resource?.timeFrames?.[0]?.endTime ? moment(resource?.timeFrames?.[0]?.endDate + ' ' + resource?.timeFrames?.[0]?.endTime, 'MM-DD-YYYY H:mm', true).toDate() : null);
					setValue('timeFrameNotes', resource?.timeFrames?.[0]?.notes);
				}
			} else {
				setValue('isMultiple', 'no');
				setValue('timeFrames', [{ id: 1 }]);
			}
		} else if (type === 'information') {
			setValue('category', resource.category);
			setValue('coverImage', resource.coverImageObj);
			setValue('title', resource.title);
			setValue('description', resource.description);
			setValue('ageGroups', [...new Set((resource?.gradeLevels || []).map((level) => AGE_GROUPS.find((group) => group.values.includes(level)).value))]);
			setValue('careerClusters', resource.careerClusters);
		}
	}

	const handleOpen = async () => {
		setValue('type', resource?.type);
		setIsOpen(true);
	};

	const handleClose = () => {
		setIsOpen(false);
		setTimeout(() => {
			setStep(0);
			reset();
		}, 200);
	};

	const renderSelectedTypeFlow = () => {
		switch (currentlySelectedType) {
			case 'appointment':
				return <AppointmentFlow currentStep={step} />;
			case 'event':
				return <EventFlow currentStep={step} />;
			case 'information':
				return <InformationFlow currentStep={step} />;
			default:
				return;
		}
	};

	const handleNextStep = () => setStep(step + 1);

	async function uploadCoverImage(file) {
		const formData = new FormData();
		formData.append('file', file);

		const { data } = await uploadResources(formData);

		return data;
	}

	async function asyncCaller(data) {
		const { coverImage, ...rest } = data;

		if (coverImage instanceof File) {
			const coverImageData = await uploadCoverImage(coverImage);
			rest.coverImageObj = coverImageData;
		} else {
			rest.coverImageObj = coverImage;
		}

		const newResource = formatPayloadBeforeUpdate(resource, { ...rest });

		const {
			data: { result },
		} = await createUpdateResource(newResource);

		if (resource?.type !== result.resource?.type) {
			if (result.resource?.type === 'appointment') {
				history.push({
					pathname: `/${user?.role === ROLES.SUPER ? 'admin' : user?.role}/directory/resources/${resource?.id}`,
					state: {
						backUrl: `/${user?.role === ROLES.SUPER ? 'admin' : user?.role}/directory/resources`,
						backTitle: 'Back to Resources',
					},
				});
				if (resource?.type === 'event') {
					dispatch({ type: 'SET_RESOURCE', payload: result.resource });
				}
			}
			if (result.resource?.type === 'event') {
				history.push({
					pathname: `/${user?.role === ROLES.SUPER ? 'admin' : user?.role}/directory/resources/${resource?.id}`,
					state: {
						backUrl: `/${user?.role === ROLES.SUPER ? 'admin' : user?.role}/directory/resources`,
						backTitle: 'Back to Resources',
					},
				});
				if (resource?.type === 'appointment') {
					dispatch({ type: 'SET_RESOURCE', payload: result.resource });
				}
			}
			if (result.resource?.type === 'information') {
				history.push({
					pathname: `/${user?.role === ROLES.SUPER ? 'admin' : user?.role}/information/${resource?.id}`,
					state: {
						backUrl: user?.role === ROLES.ADMIN || user?.role === ROLES.SUPER ? '/admin/directory/resources' : `/${user?.role}/information`,
						backTitle: user?.role === ROLES.ADMIN || user?.role === ROLES.SUPER ? 'Back to Resources' : 'Back to Information',
					},
				});
			}
		} else {
			dispatch({ type: 'SET_RESOURCE', payload: result.resource });
		}

		handleClose();
	}

	const onSubmit = (e) => {
		if (step < stepCounts[currentlySelectedType]) {
			handleSubmit(handleNextStep)(e);
		} else {
			handleSubmit(asyncCaller)(e).catch(errorHandler);
		}
	};

	useImperativeHandle(
		ref,
		() => ({
			open: handleOpen,
			close: handleClose,
		}),
		[handleOpen, handleClose]
	);

	useEffect(() => {
		if (isOpen) {
			setFormValuesByType(currentlySelectedType);
		}
	}, [isOpen, currentlySelectedType]);

	return (
		<ReactModal ref={ref} className="complete-resource-edit-modal" closeTimeoutMS={200} isOpen={isOpen} onRequestClose={handleClose}>
			<FormProvider {...methods}>
				<S.Form onSubmit={onSubmit}>
					<IconButton className="close-button" type="button" icon={['fal', 'times']} onClick={handleClose} />
					<S.InfoWrapper>
						<Typography tag="h2" weight="extrablack" center>
							Overview Information
						</Typography>
						<Typography tag="p" center>
							Edit the information below to update the overview information for this resource.
						</Typography>
					</S.InfoWrapper>
					{step === 0 && (
						<S.SingleChoiceWrapper>
							<div className="radio-wrapper">
								<div className={`radio-field${errors?.type ? ' with-error' : ''}`}>
									<input
										className="radio-input"
										type="radio"
										id="appointment"
										value="appointment"
										{...register('type', {
											required: required('Type'),
										})}
									/>
									<FontAwesomeIcon icon={['fal', 'calendar']} size="4x" />
									<Typography tag="h5" weight="bold" center>
										Appointment
									</Typography>
									<Typography tag="p" variation="2" center>
										A resource to be scheduled at a specific time and place by the booking user.
									</Typography>
								</div>

								<div className={`radio-field${errors?.type ? ' with-error' : ''}`}>
									<input
										className="radio-input"
										type="radio"
										id="event"
										value="event"
										{...register('type', {
											required: required('Type'),
										})}
									/>
									<FontAwesomeIcon icon={['fal', 'presentation']} size="4x" />
									<Typography tag="h5" weight="bold" center>
										Event
									</Typography>
									<Typography tag="p" variation="2" center>
										An event to be hosted at a specific time and place by your team.
									</Typography>
								</div>

								<div className={`radio-field${errors?.type ? ' with-error' : ''}`}>
									<input
										className="radio-input"
										type="radio"
										id="information"
										value="information"
										{...register('type', {
											required: required('Resource Type'),
										})}
									/>
									<FontAwesomeIcon icon={['fal', 'info-circle']} size="4x" />
									<Typography tag="h5" weight="bold" center>
										Information
									</Typography>
									<Typography tag="p" variation="2" center>
										An informational resource with files, links, and other helpful content.
									</Typography>
								</div>

								{!!errors?.type && (
									<div className="error-wrapper">
										<Typography tag="p" variation="2">
											{errors?.type?.message}
										</Typography>
									</div>
								)}
							</div>
						</S.SingleChoiceWrapper>
					)}

					{step !== 0 && renderSelectedTypeFlow()}

					<S.ButtonsWrapper>
						{step >= 1 && (
							<Button variant="outline" variation="secondary" type="button" onClick={() => setStep((prev) => prev - 1)}>
								<Typography variation="button-medium" weight="bold">
									Back
								</Typography>
							</Button>
						)}
						<div className="flex-1" />
						<Button type="submit">
							<Typography weight="bold" variation="button-medium">
								{isSubmitting ? 'Loading' : step < stepCounts[currentlySelectedType] ? 'Next' : 'Save'}
							</Typography>
						</Button>
					</S.ButtonsWrapper>
				</S.Form>
			</FormProvider>
		</ReactModal>
	);
});

CompleteResourceEditModal.displayName = 'CompleteResourceEditModal';

CompleteResourceEditModal.propTypes = {
	Context: PropTypes.object.isRequired,
};
