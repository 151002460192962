import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useDebouncedFn } from 'beautiful-react-hooks';
import { useTable, useFlexLayout } from 'react-table';

import { fetchSchools } from '../../services/school';
import { AdminNavigation, Button, Card, EmptyComponent, ErrorComponent, FilterSection, PageHeader, Spinner, TableComponents, Typography } from '../../components';
import { META_TITLE, SCHOOL_SORT_OPTIONS } from '../../../constants/general.constants';
import * as S from './AdminSchools.styles';
import { useHistory } from 'react-router-dom';

export const AdminSchools = () => {
	const history = useHistory();
	const [pageStatus, setPageStatus] = useState('idle');
	const [schools, setSchools] = useState([]);

	const initialPageNumber = 0;
	const [pageNumber, setPageNumber] = useState(initialPageNumber);
	const [totalPages, setTotalPages] = useState(undefined);
	const [loadingMore, setLoadingMore] = useState(false);

	const filterRef = useRef();

	const onChangeCallback = useDebouncedFn(async () => {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchSchools({ page: initialPageNumber, filterOptions: { archived: false }, ...filterRef.current.value });
			setSchools(result.schools);
			setPageNumber(initialPageNumber);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}, 300);

	async function loadMore() {
		setLoadingMore(true);
		try {
			if (totalPages > pageNumber + 1) {
				const {
					data: { result },
				} = await fetchSchools({ page: pageNumber + 1, filterOptions: { archived: false }, ...filterRef.current.value });
				setSchools((prev) => [...prev, ...result.schools]);
				setTotalPages(result.totalPages);
				setPageNumber((prev) => prev + 1);
			} else {
				toast.error('No more resources to load');
			}
			setLoadingMore(false);
		} catch (error) {
			setLoadingMore(false);
		}
	}

	async function initializeData() {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchSchools({ page: initialPageNumber, filterOptions: { archived: false } });
			setSchools(result.schools);
			setTotalPages(result.totalPages);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}

	useEffect(() => {
		// Load initial data
		if (pageStatus === 'idle') {
			initializeData();
		}
	}, [pageStatus]);

	useEffect(() => {
		// Set document title
		document.title = `Schools | ${META_TITLE}`;
	}, []);

	const columns = useMemo(
		() => [
			{
				Header: 'School Name',
				accessor: 'name',
			},
			{
				Header: 'ID Number',
				accessor: 'schoolCode',
			},
			{
				Header: 'County',
				accessor: 'addressCountyDisplay',
			},
			{
				Header: 'LEA Code',
				accessor: 'leaCode',
			},
			{
				Header: '# of Teachers',
				accessor: 'teacherCount',
			},
			{
				Header: '# of Groups',
				accessor: 'groupCount',
			},
			{
				Header: '# of Bookings',
				accessor: 'bookingCount',
			},
			{
				Header: '# of Requests',
				accessor: 'requestCount',
			},
		],
		[]
	);
	const data = React.useMemo(() => schools, [schools]);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
		{
			columns,
			data,
		},
		useFlexLayout
	);

	const renderContent = () => {
		switch (pageStatus) {
			case 'loading':
				return <Spinner />;
			case 'error':
				return <ErrorComponent />;
			case 'success':
				return rows.length > 0 ? (
					<table {...getTableProps()}>
						<thead>
							{headerGroups.map(({ key, ...headerGroup }) => (
								<tr key={key} {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map(({ key, ...column }) => (
										<th key={key} {...column.getHeaderProps()}>
											<Typography tag="h6" weight="semibold">
												{column.render('Header')}
											</Typography>
										</th>
									))}
								</tr>
							))}
						</thead>
						<tbody {...getTableBodyProps()}>
							{rows.map(({ key, ...row }) => {
								prepareRow(row);
								return (
									<TableComponents.ClickableRow
										key={key}
										{...row.getRowProps()}
										onClick={() => {
											history.push(`/admin/schools/${row.original.id}`);
										}}
									>
										{row.cells.map(({ key, ...cell }) => {
											return (
												<td key={key} className="overflow-hidden" {...cell.getCellProps()}>
													<Typography tag="p" variation="2">
														{cell.render('Cell')}
													</Typography>
												</td>
											);
										})}
									</TableComponents.ClickableRow>
								);
							})}
						</tbody>
					</table>
				) : (
					<EmptyComponent title="No current schools" message="Add a school to get started." icon={['fal', 'users']} />
				);
			default:
				return null;
		}
	};

	return (
		<AdminNavigation>
			<PageHeader title="Schools" />
			<S.Wrapper>
				<FilterSection
					ref={filterRef}
					onChangeCallback={onChangeCallback}
					initialState={{
						searchText: '',
						sortOption: SCHOOL_SORT_OPTIONS[0],
						showAdvancedFilters: false,
						activeFilters: [],
						distance: 0,
						counties: [],
						locationTypes: [],
						categories: [],
						careerClusters: [],
						ageGroups: [],
						employabilitySkills: [],
						curriculumStandards: [],
					}}
					sortOptions={SCHOOL_SORT_OPTIONS}
					filterOptions={['ageGroups']}
				/>

				<Card>
					<TableComponents.TableWrapper>{renderContent()}</TableComponents.TableWrapper>
				</Card>
				{schools?.length > 0 && totalPages > pageNumber + 1 && (
					<S.LoadMoreWrapper>
						<Button variant="outline" variation="secondary" type="button" onClick={loadMore}>
							<Typography variation="button-medium" weight="bold">
								{loadingMore ? 'Loading...' : 'Load More'}
							</Typography>
						</Button>
					</S.LoadMoreWrapper>
				)}
			</S.Wrapper>
		</AdminNavigation>
	);
};

export default AdminSchools;
