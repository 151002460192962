import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { buildAddressString } from '../../../../utils/location-methods';

import { Card, Typography, EmptyComponent } from '../../../../components';
import * as S from './LocationsCard.styles';

export const LocationsCard = ({ business }) => {
	return (
		<Card title="Locations" transparentHeaderBorder>
			<S.Wrapper>
				{business.locations.length > 0 ? (
					business.locations.map((location) => (
						<div key={location.id} className="location-info__element">
							<div className="location-info__icon-wrapper">
								<FontAwesomeIcon icon={['fal', 'building']} />
							</div>
							<div className="location-info__info-wrapper">
								<Typography tag="h5" weight="bold">
									{location.name}
								</Typography>
								<Typography className="location-info__subtitle" tag="p" variation="2">
									{buildAddressString(location.address)}
								</Typography>
							</div>
						</div>
					))
				) : (
					<EmptyComponent title="No business locations" message="Check back later to view available locations." icon={['fal', 'building']} />
				)}
			</S.Wrapper>
		</Card>
	);
};

LocationsCard.propTypes = {
	business: PropTypes.object,
};
