import React, { useContext, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';

import { numberOnly, required } from '../../../utils/form-default-errors';
import { fetchStaff } from '../../../services/school';
import { Select, TextInput, Typography } from '../..';
import * as S from '../CompleteBookingModal.styles';

export const StepOne = ({ role, context }) => {
	const {
		state: {
			booking: { group },
		},
	} = useContext(context);

	const {
		register,
		control,
		watch,
		formState: { errors },
	} = useFormContext();

	const [teachers, setTeachers] = useState([]);

	const attendance = watch('attendance');
	const genderAttendance = watch('genderAttendance');
	const attendanceAdditionalGroups = watch('attendanceAdditionalGroups');
	const selectedTeachers = watch('selectedTeachers');

	function returnTotals(fieldObj) {
		return fieldObj ? Object.values(fieldObj).reduce((acc, curr) => acc + (Number.isNaN(Number(curr)) ? 0 : Number(curr)), 0) : 0;
	}

	async function fetchTeacherData() {
		try {
			const {
				data: { result },
			} = await fetchStaff();
			setTeachers([...result.staff, { label: 'Teacher is not in System', id: 'not-in-the-system' }]);
		} catch (error) {
			toast.error('Unable to return the list of teachers');
		}
	}

	useEffect(() => {
		fetchTeacherData();
	}, []);

	return (
		<div className="modal-content">
			<Typography className="bigger-title" tag="h1" weight="extrablack" center>
				Attendance
			</Typography>
			<Typography tag="p" className="description" center>
				Please add the actual attendance of the resource.
			</Typography>

			<div className="fields-wrapper more-spacing">
				<div className="section-wrapper">
					<Typography tag="h3" weight="bold">
						Estimation
					</Typography>
					<div className="field-wrapper">
						<Typography tag="h6" weight="semibold" className="field-label">
							Original Numbers of Students Attending
						</Typography>
						<Typography tag="p" className="field-value">
							{group?.totalStudents}
						</Typography>
					</div>
				</div>

				<div className="section-wrapper">
					<S.BigFieldset>
						<Typography tag="h3" weight="bold">
							Actual Attendance
						</Typography>
						<S.BigBox>
							<S.BigBoxHeader>
								<Typography tag="h6" weight="semibold">
									Group
								</Typography>
								<Typography tag="h6" weight="semibold">
									# of Students
								</Typography>
							</S.BigBoxHeader>
							<S.BigBoxLine>
								<Typography tag="p">{group?.name}</Typography>
								<TextInput
									className="box-field"
									defaultValue="0"
									error={errors.attendance?.groupCount}
									{...register('attendance.groupCount', {
										required: required(group?.name),
										pattern: numberOnly(group?.name),
									})}
								/>
							</S.BigBoxLine>
							<S.BigBoxLine>
								<Typography tag="p">Additional Students</Typography>
								<TextInput
									className="box-field"
									defaultValue="0"
									error={errors.attendance?.additionalCount}
									{...register('attendance.additionalCount', {
										required: required('Additional Students'),
										pattern: numberOnly('Additional Students'),
									})}
								/>
							</S.BigBoxLine>
							<S.BigBoxFooter>
								<Typography tag="p" weight="semibold">
									Total
								</Typography>
								<Typography tag="p" weight="semibold" className={group?.totalStudents != returnTotals(attendance) && 'warning-text'}>
									{returnTotals(attendance)}
								</Typography>
							</S.BigBoxFooter>
						</S.BigBox>
					</S.BigFieldset>

					{attendance?.additionalCount > 0 && (
						<TextInput
							label="How many additional groups attended?"
							defaultValue="0"
							error={errors.attendanceAdditionalGroups}
							{...register('attendanceAdditionalGroups', {
								required: required('Additional Groups'),
								pattern: numberOnly('Additional Groups'),
								min: 0,
							})}
						/>
					)}

					{attendanceAdditionalGroups > 0 &&
						Array(Number(attendanceAdditionalGroups))
							.fill()
							?.map((_, index) => (
								<div key={index} className="teacher-line">
									<Controller
										name={`selectedTeachers.${index}.id`}
										control={control}
										rules={{ required: required("Additional Teacher's Name") }}
										render={({ field: { onChange, value } }) => (
											<Select
												getOptionLabel={(option) => (option.label ? option.label : `${option.firstName} ${option.lastName}`)}
												getOptionValue={(option) => option.id}
												label="Additional Teacher's Name"
												id={`additional-teacher-${index}`}
												error={errors?.selectedTeachers?.[index]?.id}
												options={teachers}
												value={teachers.find((c) => c.id === value) || ''}
												onChange={(val) => onChange(val.id)}
											/>
										)}
									/>

									{selectedTeachers?.[index]?.id === 'not-in-the-system' && (
										<div className="teacher-item">
											<Typography tag="h5" weight="bold">
												Teacher {index + 1}
											</Typography>
											<div className="grid-line teacher-fields">
												<TextInput
													label="First Name"
													error={errors?.selectedTeachers?.[index]?.firstName}
													{...register(`selectedTeachers[${index}].firstName`, {
														required: required('First Name'),
													})}
												/>
												<TextInput
													label="Last Name"
													error={errors?.selectedTeachers?.[index]?.lastName}
													{...register(`selectedTeachers[${index}].lastName`, {
														required: required('Last Name'),
													})}
												/>
												<TextInput
													containerClassName="full-line-field"
													label="Email"
													error={errors?.selectedTeachers?.[index]?.email}
													{...register(`selectedTeachers[${index}].email`, {
														required: required('Email'),
													})}
												/>
											</div>
										</div>
									)}
								</div>
							))}
				</div>

				<div className="section-wrapper">
					<Typography tag="h3" weight="bold">
						Demographics
					</Typography>
					<Typography tag="h4" weight="bold">
						Age Range
					</Typography>
					<div className="grid-line age-range">
						<TextInput
							label="Minimum"
							error={errors?.attendanceMinAge}
							{...register('attendanceMinAge', {
								required: required('Minimum'),
								pattern: numberOnly('Minimum'),
							})}
						/>
						<TextInput
							label="Maximum"
							error={errors?.attendanceMaxAge}
							{...register('attendanceMaxAge', {
								required: required('Maximum'),
								pattern: numberOnly('Maximum'),
							})}
						/>
					</div>
				</div>

				<div className="section-wrapper">
					<S.BigFieldset>
						<Typography tag="h3" weight="bold">
							Gender Demographics
						</Typography>
						<S.BigBox>
							<S.BigBoxHeader>
								<Typography tag="h6" weight="semibold">
									Gender
								</Typography>
								<Typography tag="h6" weight="semibold">
									# of Students
								</Typography>
							</S.BigBoxHeader>
							<S.BigBoxLine>
								<Typography tag="p">Male</Typography>
								<TextInput
									className="box-field"
									defaultValue="0"
									error={errors.genderAttendance?.male}
									{...register('genderAttendance.male', {
										required: required('Male'),
										pattern: numberOnly('Male'),
									})}
								/>
							</S.BigBoxLine>
							<S.BigBoxLine>
								<Typography tag="p">Female</Typography>
								<TextInput
									className="box-field"
									defaultValue="0"
									error={errors.genderAttendance?.female}
									{...register('genderAttendance.female', {
										required: required('Female'),
										pattern: numberOnly('Female'),
									})}
								/>
							</S.BigBoxLine>
							<S.BigBoxLine>
								<Typography tag="p">Other</Typography>
								<TextInput
									className="box-field"
									defaultValue="0"
									error={errors.genderAttendance?.other}
									{...register('genderAttendance.other', {
										required: required('Other'),
										pattern: numberOnly('Other'),
									})}
								/>
							</S.BigBoxLine>
							<S.BigBoxFooter>
								<Typography tag="p" weight="semibold">
									Total
								</Typography>
								<Typography tag="p" weight="semibold">
									{returnTotals(genderAttendance)}
								</Typography>
							</S.BigBoxFooter>
						</S.BigBox>
					</S.BigFieldset>
				</div>
			</div>
		</div>
	);
};

StepOne.propTypes = {
	role: PropTypes.string,
	context: PropTypes.shape(),
};
