import React from 'react';
import Lottie from 'react-lottie-player';

import animationData from './loading.json';
import * as S from './Spinner.styles';

export function Spinner() {
	return (
		<S.Wrapper>
			<Lottie loop play animationData={animationData} style={{ width: 192, height: 192 }} />
		</S.Wrapper>
	);
}
