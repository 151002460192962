import { createGlobalStyle } from 'styled-components';

import colors from '../styles/colors';

const GlobalStyles = createGlobalStyle`
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}
	html,
	body,
	#root {
		min-height: 100%;
		height: 100%;
	}
	body, button, input, textarea {
		font-family: 'Inter', sans-serif;
	}
	body {
		background-color: ${colors.neutralBackground};
	}

	.capitalized {
		text-transform: capitalize;
	}

	.ReactModal {
		&__Overlay {
			opacity: 0;
			transition: opacity 200ms ease-in-out;
			background-color: ${colors.darkOverlay} !important;
			display: flex;
			justify-content: center;
			align-items: center;
			z-index: 3;

			&--after-open {
				opacity: 1;
			}

			&--before-close {
				opacity: 0;
			}
		}
		&__Content {
			background-color: white !important;
			position: relative !important;
			inset: auto !important;
			cursor: auto;
			display: block;
			align-items: center;
			width: 100%;
			border: none !important;
			border-radius: 0.25rem !important;
			max-width: 72rem; /* 1152px */
			margin: 1rem;
			overflow: auto;
    		max-height: calc(100% - 2rem);
			padding: 0 !important;
			box-shadow: ${colors.darkShadow};

			&.c-message-dialog {
				max-width: 46.875rem;
			}

			&.smaller-modal {
				max-width: 40.5rem;
				overflow: scroll;
			}

			&.link-card-modal,
			&.employee-locations-card-modal {
				max-width: 40.5rem;
			}

			&.account-card-modal,
			&.profile-card-modal,
			&.add-user-modal,
			&.invite-business-modal,
			&.add-employee-modal,
			&.change-ownership-modal {
				max-width: 46.875rem;
				overflow: scroll;
			}

			&.edit-company-account-modal {
				max-width: 46.875rem;
			}

			&.start-review-modal {
				overflow: scroll;
			}

			&.table-chart-graph-modal,
			&.complete-booking-modal,
			&.complete-request-modal,
			&.schedule-request-modal {
				overflow: hidden;
				height: 100%;
			}
		}
	}

	.capitalize {
		text-transform: capitalize;
	}

	.flex-1 {
		flex: 1 1 0%;
	}

	.recharts-legend-item {
		font-size: 0.875rem;
	}

	.react-datepicker-wrapper {
		display: block;
		width: 100%;
	}
	.react-datepicker {
		&__triangle {
			transform: translateX(2.375rem) !important;
		}
	}
	.sharethis-sticky-share-buttons {
		display: none !important;
	}
	.st-sticky-share-buttons {
		display: none !important;
	}
`;

export default GlobalStyles;
