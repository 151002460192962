import styled from 'styled-components';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0 1rem;
	flex: 1;
`;

export const PublicWrapper = styled.div`
	margin: 1rem 0;
`;
