import React, { createContext, forwardRef, useReducer, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { addDays, format } from 'date-fns';

import { COUNTY_OPTIONS } from '../../../../constants/general.constants';
import { Typography, Select, DatePicker } from '../../../components';
import * as S from './FiltersSection.styles';

export const FilterContext = createContext();

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_VIEW_FOR_FILTER':
			return {
				...state,
				viewFor: action.payload,
			};
		case 'SET_COUNTY_FILTER':
			return {
				...state,
				county: action.payload,
			};
		case 'SET_PSU_FILTER':
			return {
				...state,
				psu: action.payload,
			};
		case 'SET_COMPANY_FILTER':
			return {
				...state,
				company: action.payload,
			};
		case 'SET_SCHOOL_FILTER':
			return {
				...state,
				school: action.payload,
			};
		case 'SET_START_DATE_RANGE_FILTER':
			return {
				...state,
				startDateRange: action.payload,
			};
		case 'SET_END_DATE_RANGE_FILTER':
			return {
				...state,
				endDateRange: action.payload,
			};
		default:
			return state;
	}
};

const viewForFilterOptions = [
	{ value: 'county', label: 'County' },
	{ value: 'psu', label: 'Public School Unit (PSU)' },
	{ value: 'company', label: 'Company' },
	{ value: 'school', label: 'School' },
];
const countyFilterOptions = [{ code: 'all', name: 'All Counties' }, ...COUNTY_OPTIONS];
const psuFilterOptions = [{ value: 'all', label: 'All' }];
const companyFilterOptions = [{ value: 'all', label: 'All' }];
const schoolFilterOptions = [{ value: 'all', label: 'All' }];

export const FiltersSection = forwardRef(
	(
		{ initialState = { viewFor: viewForFilterOptions[0], county: countyFilterOptions[0], psu: psuFilterOptions[0], company: companyFilterOptions[0], school: schoolFilterOptions[0], startDateRange: addDays(new Date(), -365), endDateRange: addDays(new Date(), 1) }, onChangeCallback = () => {} },
		ref
	) => {
		const [state, dispatch] = useReducer(reducer, initialState);
		const dispatchAndTriggerOnChange = (action) => {
			dispatch(action);
			onChangeCallback();
		};

		useImperativeHandle(ref, () => {
			const viewOption = state?.viewFor?.value;
			let value = {
				viewOption,
				startDate: state?.startDateRange ? format(state?.startDateRange, 'MM-dd-yyyy') : null,
				endDate: state?.endDateRange ? format(state?.endDateRange, 'MM-dd-yyyy') : null,
			};

			switch (viewOption) {
				case 'county':
					value.countyId = state?.county?.code;
					break;
				case 'psu':
					value.psu = state?.psu?.value;
					break;
				case 'company':
					value.company = state?.company?.value;
					break;
				case 'school':
					value.school = state?.school?.value;
				default:
					break;
			}

			return {
				value,
			};
		});

		return (
			<FilterContext.Provider value={{ state, dispatch }}>
				<S.Wrapper>
					<S.FieldWrapper className="viewfor-select">
						<Typography tag="label" weight="bold">
							View For:
						</Typography>
						<Select value={state?.viewFor} onChange={(value) => dispatchAndTriggerOnChange({ type: 'SET_VIEW_FOR_FILTER', payload: value })} options={viewForFilterOptions} size="small" placeholder="View For" />
					</S.FieldWrapper>
					{state?.viewFor?.value === 'county' && (
						<S.FieldWrapper className="currentselected-select">
							<Typography tag="label" weight="bold">
								County:
							</Typography>
							<Select
								getOptionValue={(option) => option.code}
								getOptionLabel={(option) => option.name}
								id="county"
								placeholder="County"
								options={countyFilterOptions}
								size="small"
								value={state?.county}
								onChange={(value) => dispatchAndTriggerOnChange({ type: 'SET_COUNTY_FILTER', payload: value })}
							/>
						</S.FieldWrapper>
					)}
					{state?.viewFor?.value === 'psu' && (
						<S.FieldWrapper className="currentselected-select">
							<Typography tag="label" weight="bold">
								PSU:
							</Typography>
							<Select id="psu" placeholder="PSU" options={psuFilterOptions} size="small" value={state?.psu} onChange={(value) => dispatchAndTriggerOnChange({ type: 'SET_PSU_FILTER', payload: value })} />
						</S.FieldWrapper>
					)}
					{state?.viewFor?.value === 'company' && (
						<S.FieldWrapper className="currentselected-select">
							<Typography tag="label" weight="bold">
								Company:
							</Typography>
							<Select id="company" placeholder="Company" options={companyFilterOptions} size="small" value={state?.company} onChange={(value) => dispatchAndTriggerOnChange({ type: 'SET_COMPANY_FILTER', payload: value })} />
						</S.FieldWrapper>
					)}
					{state?.viewFor?.value === 'school' && (
						<S.FieldWrapper className="currentselected-select">
							<Typography tag="label" weight="bold">
								School:
							</Typography>
							<Select id="school" placeholder="School" options={schoolFilterOptions} size="small" value={state?.school} onChange={(value) => dispatchAndTriggerOnChange({ type: 'SET_SCHOOL_FILTER', payload: value })} />
						</S.FieldWrapper>
					)}
					<S.FieldWrapper className="daterange-select">
						<Typography tag="label" weight="bold">
							Date Range:
						</Typography>
						<DatePicker
							withoutIcon
							selectsRange
							size="small"
							startDate={state?.startDateRange}
							endDate={state?.endDateRange}
							onChange={[
								(start) => {
									dispatch({ type: 'SET_START_DATE_RANGE_FILTER', payload: start });
									if (start && state?.endDateRange) {
										onChangeCallback();
									}
								},
								(end) => {
									dispatch({ type: 'SET_END_DATE_RANGE_FILTER', payload: end });
									if (state?.startDateRange && end) {
										onChangeCallback();
									}
								},
							]}
						/>
					</S.FieldWrapper>
				</S.Wrapper>
			</FilterContext.Provider>
		);
	}
);

FiltersSection.displayName = 'FiltersSection';

FiltersSection.propTypes = {
	initialState: PropTypes.shape({}),
	onChangeCallback: PropTypes.func,
};
