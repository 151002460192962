import styled, { css } from 'styled-components';

import colors from '../../../styles/colors';

export const LinkTitle = styled.h3`
	font-size: 0.5rem;
	line-height: 0.875rem;
	font-weight: 700;
	color: white;
	margin: 1rem 1rem 0.375rem 1rem;
`;

export const Sidebar = styled.aside`
	display: flex;
	flex-direction: column;
	width: 0%;
	max-width: 12.5rem;
	background-color: ${colors.brandPrimary};
	box-shadow: ${colors.darkShadow};
	overflow-x: auto;
	position: absolute;
	height: inherit;
	z-index: 3;
	transition: width 0.4s cubic-bezier(0.4, 0, 0.2, 1);

	padding-top: 2.8125rem;

	${({ opened }) =>
		opened &&
		css`
			width: 100%;
		`}
`;

export const Overlay = styled.div`
	position: absolute;
	inset: 0;
	display: none;
	cursor: pointer;
	z-index: 2;

	${({ opened }) =>
		opened &&
		css`
			display: block;
		`}
`;

export const LinksWrapper = styled.nav`
	display: flex;
	flex-direction: column;
`;
