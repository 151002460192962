import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { fetchMessages } from '../../../../services/messages';
import { useChat } from '../../../../hooks';
import { Button, IconButton, Picture, Typography, Chat } from '../../../../components';
import { ResourcesContext } from '../PostedResourcesTab';
import * as S from './ResourceChat.styles';

export const ResourceChat = () => {
	const { state, dispatch } = useContext(ResourcesContext);
	const { selectedConversation } = state;
	const { messages, setMessages } = useChat(selectedConversation.id);
	const [pageStatus, setPageStatus] = useState('loading');
	const history = useHistory();

	async function initializeData() {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchMessages({ page: 0, displayLimit: 100000, resourceId: selectedConversation.resource.id, filterOptions: { received: true, sent: true } });
			setMessages(result.messages);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}

	useEffect(() => {
		// Load initial data
		initializeData();
	}, [selectedConversation]);

	return (
		<S.ResourceChatWrapper>
			<S.ChatHeader>
				<S.BackButtonWrapper>
					<Button variant="text" size="small" onClick={() => dispatch({ type: 'CLEAR_SELECTED_CONVERSATION' })}>
						<FontAwesomeIcon icon={['fal', 'arrow-left']} />
						<Typography className="button-label" variation="button-small" weight="bold">
							Back to Messages
						</Typography>
					</Button>
				</S.BackButtonWrapper>
				<S.InfoWrapper>
					<Picture className="avatar-image" src={selectedConversation?.recipient?.avatarObj?.['200']} aspectRatio="1/1" alt="profile" />
					<div className="info-block">
						<Typography tag="h3" weight="bold">
							{selectedConversation.recipient.name}
						</Typography>
						<Typography tag="p" variation="2">
							{selectedConversation.resource.title}
						</Typography>
					</div>
					{selectedConversation.recipient.type === 'business' && (
						<IconButton
							className="info-button"
							icon={['fal', 'info-circle']}
							onClick={() => {
								history.push({ pathname: `/business/directory/business/${selectedConversation.business.id}`, state: { backUrl: window.location.pathname, backTitle: 'Back to Messages' } });
							}}
						/>
					)}
				</S.InfoWrapper>
			</S.ChatHeader>
			<Chat
				loading={pageStatus === 'loading'}
				messages={messages}
				setMessages={setMessages}
				sendProps={{
					resourceId: selectedConversation.resource.id,
					...(selectedConversation.recipient.type === 'user'
						? {
								recipientId: selectedConversation.recipient.id,
						  }
						: {
								businessId: selectedConversation.recipient.id,
						  }),
				}}
			/>
		</S.ResourceChatWrapper>
	);
};
