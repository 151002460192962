import styled from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	padding: 1rem;
`;

export const CardWrapper = styled.div`
	display: flex;
	flex-direction: column;

	& > * + * {
		margin-top: 1rem;
	}

	.overview-picture {
		picture {
			border-radius: 0.375rem;
		}
	}

	.overview-info {
		display: grid;
		grid-template-columns: 1fr;
		gap: 0.75rem;
		grid-template-areas:
			'a1'
			'a2'
			'a3'
			'a4'
			'a5'
			'a6'
			'a7';
		padding-bottom: 0.25rem;

		.a1 {
			grid-area: a1;
		}
		.a2 {
			grid-area: a2;
		}
		.a3 {
			grid-area: a3;
		}
		.a4 {
			grid-area: a4;
		}
		.a5 {
			grid-area: a5;
		}
		.a6 {
			grid-area: a6;
		}
		.a7 {
			grid-area: a7;
		}

		&__title {
			color: ${colors.neutralText};
			margin-bottom: 0.25rem;
		}

		&__badges-wrapper {
			display: flex;
			flex-wrap: wrap;
			margin: -0.25rem;
		}

		&__badge {
			padding: 0.25rem 0.5rem;
			border-radius: 6.25rem;
			border: 1px solid ${colors.brandPrimary};
			font-size: 0.75rem;
			color: ${colors.brandPrimary};
			font-weight: 700;
			margin: 0.25rem;
		}
	}

	@media screen and (min-width: 992px) {
		flex-direction: row;

		& > * + * {
			margin-top: 0;
			margin-left: 1rem;
		}

		.overview-picture {
			min-width: 16.875rem;
			max-width: 16.875rem;
		}

		.overview-bigicon {
			display: flex;
			justify-content: center;
			align-items: center;
			min-width: 13.5625rem;
			max-width: 13.5625rem;
			height: 13.5625rem;
			border: 1px solid ${colors.neutralForm};
			border-radius: 9999px;
			background-color: ${colors.neutralBackground};
			color: ${colors.brandPrimary};
		}

		.overview-info {
			flex: 1;
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-template-areas:
				'a1 a1 a1'
				'a2 a3 a3'
				'a4 a4 a4'
				'a5 a6 a7';
		}
	}
`;
