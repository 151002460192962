import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import colors from '../../../styles/colors';

export const Wrapper = styled.div`
	display: grid;
	gap: 1rem;
	column-gap: 0.5rem;
	grid-template-columns: 1fr;

	@media screen and (min-width: 1100px) {
		grid-template-columns: 26.875rem 1fr;
	}
`;

export const BookedCardsList = styled.div`
	display: flex;
	flex-direction: column;
	border-top: 1px solid ${colors.neutralForm};
	padding-top: 1rem;

	@media screen and (min-width: 1100px) {
		border-top: none;
		border-left: 1px solid ${colors.neutralForm};
		padding: 0 1rem;
		margin: 0.5rem 0;
	}
`;

export const BookedCard = styled(Link)`
	text-decoration: none;
	display: flex;
	border-bottom: 1px solid ${colors.neutralForm};
	padding: 0.75rem 0;

	&:first-child {
		padding-top: 0;
	}

	.booked-card {
		&__colored-border {
			min-width: 0.375rem;
			width: 0.375rem;
			height: 100%;
			border-radius: 0.125rem;
			margin-right: 0.1875rem;
		}

		&__thumbnail {
			max-width: 8.375rem;
			display: flex;
			align-items: center;

			picture {
				border-radius: 0.25rem;
				border: 1px solid ${colors.neutralForm};
			}
		}

		&__info-wrapper {
			flex: 1;
			margin-left: 0.75rem;
			min-width: 12.5rem;

			&__type {
				color: ${colors.neutralText};
			}

			&__title {
				margin-top: 0.25rem;
				color: black;
			}

			&__details-wrapper {
				display: grid;
				grid-template-columns: 1fr 1fr;
				margin-top: 0.5rem;
				gap: 0.5rem;

				&__detail-item {
					display: flex;
					align-items: center;
					color: ${colors.neutralButton};

					svg {
						width: 1.25rem;
						margin-right: 0.25rem;
					}
				}
			}
		}
	}

	.yellow-tile {
		background-color: ${colors.stateWarning};
	}
	.green-tile {
		background-color: ${colors.stateSuccess};
	}
	.blue-tile {
		background-color: ${colors.brandPrimary};
	}
	.light-blue-tile {
		background-color: ${colors.brandSecondary};
	}
	.purple-tile {
		background-color: ${colors.brandPurple};
	}
	.red-tile {
		background-color: ${colors.stateDanger};
	}
	.orange-tile {
		background-color: ${colors.brandOrange};
	}
`;

export const FilterComponent = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	max-width: 12.5rem;

	.filter-label {
		color: ${colors.neutralText};
	}

	.filter-select {
		width: 100%;
		margin-left: 0.5rem;
	}
`;

export const EmptyBusinessCard = styled.div`
	grid-column: span 2;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	${({ paddingTop = 2.625, paddingBottom = 3.375 }) => css`
		padding-top: ${paddingTop}rem;
		padding-bottom: ${paddingBottom}rem;
	`}

	.icon-box {
		margin-bottom: 1.25rem;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 10rem;
		height: 10rem;
		border-radius: 9999px;
		background-color: ${colors.neutralBackground};
		border: 1px solid ${colors.neutralForm};
		color: ${colors.neutralButton};
	}

	& > h4 {
		color: ${colors.neutralButton};
		margin-bottom: 0.5rem;
	}
	& > p {
		color: ${colors.neutralButton};
	}
`;
