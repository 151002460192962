import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PieChart, Pie, Legend, Cell, ResponsiveContainer } from 'recharts';

import { Card } from '../../Card';

const COLORS = ['#394A90', '#485EB7', '#6D7EC5', '#919ED4', '#B6BFE2', '#DADFF1'];

export const PieChartGraph = ({ className, data = {}, title }) => {
	const [chartData, setChartData] = useState([]);

	useEffect(() => {
		setChartData(Object.keys(data).map((key) => ({ name: key.charAt(0).toUpperCase() + key.slice(1), value: data[key] })));
	}, [data]);

	if (chartData.length === 0 || Object.keys(data).length === 0 || Object.values(data).every((datum) => datum === 0)) {
		return null;
	}

	return (
		<Card className={className} title={title} transparentHeaderBorder>
			<ResponsiveContainer width="100%" height={400}>
				<PieChart>
					<Pie data={chartData} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={90} outerRadius={120} legendType="square">
						{chartData.map((_entry, index) => (
							<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
						))}
					</Pie>
					<Legend layout="vertical" align="right" verticalAlign="top" />
				</PieChart>
			</ResponsiveContainer>
		</Card>
	);
};
PieChartGraph.propTypes = {
	className: PropTypes.string,
	data: PropTypes.object,
	title: PropTypes.string,
};
