import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useChat } from '../../../../hooks';
import { fetchMessages } from '../../../../services/messages';
import { Button, IconButton, Picture, Typography, Chat } from '../../../../components';
import { ResourcesContext } from '../ResourcesTab';
import * as S from './ResourceChat.styles';

export const ResourceChat = () => {
	const { state, dispatch } = useContext(ResourcesContext);
	const { selectedConversation } = state;
	const { messages, setMessages } = useChat(selectedConversation.resource?.conversations?.[0].channelId);
	const [pageStatus, setPageStatus] = useState('loading');
	const history = useHistory();

	async function initializeData() {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchMessages({ page: 0, displayLimit: 100000, resourceId: selectedConversation.id, filterOptions: { received: true, sent: true } });
			setMessages(result.messages);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}

	useEffect(() => {
		// Load initial data
		initializeData();
	}, [selectedConversation]);

	return (
		<>
			<S.ResourceChatWrapper>
				<S.ChatHeader>
					<S.BackButtonWrapper>
						<Button variant="text" size="small" onClick={() => dispatch({ type: 'CLEAR_SELECTED_CONVERSATION' })}>
							<FontAwesomeIcon icon={['fal', 'arrow-left']} />
							<Typography className="button-label" variation="button-small" weight="bold">
								Back to Messages
							</Typography>
						</Button>
					</S.BackButtonWrapper>
					<S.InfoWrapper>
						<Picture className="avatar-image" src={selectedConversation?.business?.logoObj?.['200']} aspectRatio="1/1" alt="profile" />
						<div className="info-block">
							<Typography tag="h3" weight="bold">
								{selectedConversation.business.name}
							</Typography>
							<Typography tag="p" variation="2">
								{selectedConversation.resource.title}
							</Typography>
						</div>
						<IconButton
							className="info-button"
							icon={['fal', 'info-circle']}
							onClick={() => {
								history.push({ pathname: `/teacher/directory/business/${selectedConversation.business.id}`, state: { backUrl: window.location.pathname, backTitle: 'Back to Messages' } });
							}}
						/>
					</S.InfoWrapper>
				</S.ChatHeader>
				<Chat loading={pageStatus === 'loading'} messages={messages} setMessages={setMessages} sendProps={{ resourceId: selectedConversation.id, businessId: selectedConversation.business.id }} />
			</S.ResourceChatWrapper>
		</>
	);
};
