import React from 'react';
import { useFormContext } from 'react-hook-form';

import { TextArea, Typography } from '../../../../components';
import { required } from '../../../../utils/form-default-errors';

export const StepFour = ({}) => {
	const {
		register,
		formState: { errors },
	} = useFormContext();

	return (
		<div className="step__wrapper">
			<header className="step__header">
				<Typography tag="h2" weight="extrablack" className="step__title">
					Add a Note
				</Typography>
				<Typography tag="p" className="step__subtitle">
					Add any additional notes for this booking.
				</Typography>
			</header>
			<section className="step__content">
				<TextArea className="step__huge-textarea" id="notes" label="Notes" placeholder="Write your notes here..." error={errors?.notes} {...register('notes')} />
			</section>
		</div>
	);
};
