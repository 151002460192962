import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';

import logo from '../../assets/ncbce-logo-alt.png';
import { Button, Typography } from '../../components';
import * as S from './SignupBusinessPending.styles';
import { META_TITLE } from '../../../constants/general.constants';

const SignupBusinessPending = () => {
	const history = useHistory();

	function onCompleteClick() {
		history.push('/business/dashboard');
	}

	useEffect(() => {
		// Set document title
		document.title = `Account Pending | ${META_TITLE}`;
	}, []);

	return (
		<S.Wrapper>
			<S.Header>
				<div className="flex-1">
					<Button variant="text" size="small">
						<FontAwesomeIcon icon={['fal', 'arrow-left']} />
						<Typography className="button-label" variation="button-small">
							Back to Home
						</Typography>
					</Button>
				</div>
				<div className="centered">
					<S.Picture>
						<img src={logo} alt="NCBCE logo" />
					</S.Picture>
				</div>
				<div className="flex-1" />
			</S.Header>
			<S.Container>
				<FontAwesomeIcon className="featured-icon" icon={['fal', 'check-circle']} size="3x" />
				<Typography tag="h1" weight="extrablack" center>
					Account Pending
				</Typography>
				<Typography tag="p" className="description" center>
					Your account is now pending. You should receive an email confirmation once you have been approved.
				</Typography>

				<Button type="submit" className="submit-button" onClick={onCompleteClick}>
					<Typography variation="button-medium" weight="bold">
						Continue to Dashboard
					</Typography>
				</Button>
			</S.Container>
		</S.Wrapper>
	);
};

export default SignupBusinessPending;
