import React from 'react';
import PropTypes from 'prop-types';

import { CategoryStep, DetailsStep, AvailabilityStep } from '../Steps';

export const AppointmentFlow = ({ currentStep }) => {
	return (
		<>
			{currentStep === 1 && <CategoryStep />}
			{currentStep === 2 && <DetailsStep />}
			{currentStep === 3 && <AvailabilityStep />}
		</>
	);
};

AppointmentFlow.propTypes = {
	currentStep: PropTypes.number.isRequired,
};
