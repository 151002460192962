import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { OverviewCard } from './OverviewCard';
import { LocationCard } from './LocationCard';
import { AvailabilityCard } from './AvailabilityCard';
import { BusinessCard } from './BusinessCard';
import { LearningConnectionsCard } from './LearningConnectionsCard';
import { BookingDetailsContext } from '../BookingDetail';
import * as S from './OverviewTab.styles';

export const OverviewTab = ({ role }) => {
	const {
		state: { booking },
	} = useContext(BookingDetailsContext);

	return (
		<S.Wrapper>
			<OverviewCard className="a1" booking={booking} role={role} />
			<LocationCard className="a2" booking={booking} role={role} />
			<AvailabilityCard className="a3" booking={booking} role={role} />
			<BusinessCard className="a4" business={booking.business} role={role} />
			{(booking.resource.employabilitySkills.length > 0 || booking.resource.curriculumStandards.length > 0) && <LearningConnectionsCard className="a5" booking={booking} role={role} />}
		</S.Wrapper>
	);
};

OverviewTab.propTypes = {
	role: PropTypes.string,
};
