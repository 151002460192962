import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { TeacherNavigation, PageHeader, Tab } from '../../components';

import { CurrentTab } from './CurrentTab';
import { ArchivedTab } from './ArchivedTab';
import * as S from './TeacherInformation.styles';
import { META_TITLE } from '../../../constants/general.constants';

const TeacherInformation = () => {
	const history = useHistory();
	useEffect(() => {
		// Set document title
		document.title = `Information | Dashboard | ${META_TITLE}`;
	}, []);

	const [tabs, setTabs] = useState([
		{
			id: 1,
			label: 'Current',
			component: <CurrentTab />,
		},
		{
			id: 2,
			label: 'Archived',
			component: <ArchivedTab />,
		},
	]);

	return (
		<TeacherNavigation>
			<PageHeader title="Information" action={[{ id: 1, onClick: () => history.push('/teacher/create-request'), label: 'Create Request', icon: ['fal', 'plus'] }]} />
			<S.Wrapper>
				<Tab tabs={tabs} setTabs={setTabs} />
			</S.Wrapper>
		</TeacherNavigation>
	);
};

export default TeacherInformation;
