import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDebouncedFn } from 'beautiful-react-hooks';

import { RESOURCE_SORT_OPTIONS, ROLES } from '../../../../constants/general.constants';
import { fetchResources, saveUnsaveResource } from '../../../services/directory';
import { Picture, Typography, Spinner, ErrorComponent, EmptyComponent, FilterSection, Button } from '../../../components';
import * as S from './ResourcesTab.styles';

export const ResourcesTab = ({ business, role }) => {
	const [pageStatus, setPageStatus] = useState('idle');
	const [resources, setResources] = useState([]);
	const history = useHistory();

	const initialPageNumber = 0;
	const [pageNumber, setPageNumber] = useState(initialPageNumber);
	const [totalPages, setTotalPages] = useState(undefined);
	const [loadingMore, setLoadingMore] = useState(false);

	const filterRef = useRef();

	const onChangeCallback = useDebouncedFn(async () => {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchResources({ page: initialPageNumber, businessId: business.id, ...filterRef.current.value });
			setResources(result.resources);
			setPageNumber(initialPageNumber);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}, 300);

	async function loadMore() {
		setLoadingMore(true);
		try {
			if (totalPages > pageNumber + 1) {
				const {
					data: { result },
				} = await fetchResources({ page: pageNumber + 1, businessId: business.id, ...filterRef.current.value });
				setResources((prev) => [...prev, ...result.resources]);
				setTotalPages(result.totalPages);
				setPageNumber((prev) => prev + 1);
			} else {
				toast.error('No more resources to load');
			}
			setLoadingMore(false);
		} catch (error) {
			setLoadingMore(false);
		}
	}

	async function initializeData() {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchResources({ page: initialPageNumber, businessId: business.id });
			setResources(result.resources);
			setTotalPages(result.totalPages);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}

	const handleSaveUnsaveResource = async (id) => {
		try {
			const {
				data: { result },
			} = await saveUnsaveResource({ resourceId: id });
			const newResources = [...resources];
			newResources[newResources.findIndex((el) => el.id === result.resource.id)] = result.resource;
			setResources(newResources);
		} catch (error) {}
	};

	useEffect(() => {
		// Load initial data
		if (pageStatus === 'idle') {
			initializeData();
		}
	}, []);

	const renderContent = () => {
		switch (pageStatus) {
			case 'loading':
				return <Spinner />;
			case 'error':
				return <ErrorComponent />;
			case 'success':
				return resources.length > 0 ? (
					<S.CardsWrapper>
						{resources.map((resource) => (
							<S.CardItem
								key={resource.id}
								onClick={() => {
									history.push({ pathname: resource.type === 'information' ? `/${role || 'teacher'}/information/${resource.id}` : `/${role || 'teacher'}/directory/resources/${resource.id}`, state: { backUrl: window.location.pathname, backTitle: 'Back to Business' } });
								}}
							>
								<Picture className="thumbnail" src={resource.coverImageObj['200']} aspectRatio="3/2" />
								<div className="info-wrapper">
									<Typography className="category" tag="p" variation="3">
										{resource.category || 'Event'}
									</Typography>
									<Typography tag="h5" weight="bold">
										{resource.title}
									</Typography>
									<Typography tag="p" variation="2">
										{resource.business.name}
									</Typography>
								</div>
								{role !== ROLES.ADMIN && role !== ROLES.SUPER && (
									<S.IconButton
										bookmarked={resource.isSaved}
										icon="bookmark"
										size={1}
										onClick={(e) => {
											e.stopPropagation();
											e.nativeEvent.stopImmediatePropagation();
											handleSaveUnsaveResource(resource.id);
										}}
									/>
								)}
							</S.CardItem>
						))}
					</S.CardsWrapper>
				) : (
					<EmptyComponent title="No available resources" message="Check back later to view available resources published by this business." icon={['fal', 'books']} />
				);
			default:
				return null;
		}
	};

	return (
		<S.Wrapper>
			<FilterSection
				ref={filterRef}
				onChangeCallback={onChangeCallback}
				initialState={{
					searchText: '',
					sortOption: RESOURCE_SORT_OPTIONS[2],
					showAdvancedFilters: false,
					activeFilters: [],
					distance: 0,
					counties: [],
					locationTypes: [],
					categories: [],
					careerClusters: [],
					ageGroups: [],
					employabilitySkills: [],
					curriculumStandards: [],
				}}
				sortOptions={RESOURCE_SORT_OPTIONS}
			/>
			{renderContent()}
			{resources?.length > 0 && totalPages > pageNumber + 1 && (
				<S.LoadMoreWrapper>
					<Button variant="outline" variation="secondary" type="button" onClick={loadMore}>
						<Typography variation="button-medium" weight="bold">
							{loadingMore ? 'Loading...' : 'Load More'}
						</Typography>
					</Button>
				</S.LoadMoreWrapper>
			)}
		</S.Wrapper>
	);
};

ResourcesTab.propTypes = {
	business: PropTypes.object,
	role: PropTypes.string,
};
