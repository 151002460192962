import React, { useEffect, useState, useImperativeHandle, forwardRef, useRef } from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';

import { AdminNavigation, PageHeader, Tab, IconButton, Typography, TextInput, TextArea, Button } from '../../components';
import * as S from './AdminCompanies.styles';
import { PendingTab } from './PendingTab';
import { ApprovedTab } from './ApprovedTab';
import { DeclinedTab } from './DeclinedTab';
import { META_TITLE } from '../../../constants/general.constants';
import { exportBusinesses } from '../../services/business';
import { pattern, required } from '../../utils/form-default-errors';
import { EMAIL } from '../../utils/common-regex';
import { useForm } from 'react-hook-form';
import { errorHandler } from '../../services/authService';
import { inviteBusiness } from '../../services/invites';
import toast from 'react-hot-toast';

const InviteBusinessModal = forwardRef(({ onSuccessCallback }, ref) => {
	const [isOpen, setIsOpen] = useState(false);
	const {
		handleSubmit,
		register,
		reset,
		formState: { errors, isSubmitting },
	} = useForm();

	const handleOpenModal = () => {
		setIsOpen(true);
	};

	const handleCloseModal = () => {
		setIsOpen(false);
		setTimeout(() => {
			reset();
		}, 200);
	};

	async function asyncCaller(data) {
		await inviteBusiness(data);
		onSuccessCallback();
		handleCloseModal();
	}

	const onSubmit = (e) => {
		handleSubmit(asyncCaller)(e).catch(errorHandler);
	};

	useImperativeHandle(
		ref,
		() => ({
			open: handleOpenModal,
			close: handleCloseModal,
		}),
		[handleOpenModal, handleCloseModal]
	);

	return (
		<ReactModal ref={ref} className="invite-business-modal" closeTimeoutMS={200} isOpen={isOpen} onRequestClose={handleCloseModal}>
			<S.Form onSubmit={onSubmit}>
				<IconButton className="close-button" type="button" icon={['fal', 'times']} onClick={handleCloseModal} />
				<div className="modal-content">
					<header className="modal-content__header">
						<Typography tag="h2" weight="extrablack" center>
							Invite Company
						</Typography>
					</header>

					<section className="modal-content__section">
						<TextInput
							label="Company Name"
							placeholder="Company Name"
							error={errors.businessName}
							{...register('businessName', {
								required: required('Company Name'),
							})}
						/>

						<TextInput
							label="Admin First Name"
							placeholder="Admin First Name"
							error={errors.adminFirstName}
							{...register('adminFirstName', {
								required: required('Admin First Name'),
							})}
						/>

						<TextInput
							label="Admin Last Name"
							placeholder="Admin Last Name"
							error={errors.adminLastName}
							{...register('adminLastName', {
								required: required('Admin Last Name'),
							})}
						/>

						<TextInput
							label="Admin Email"
							placeholder="Admin Email"
							error={errors.adminEmail}
							{...register('adminEmail', {
								required: required('Admin Email'),
								pattern: pattern('Admin Email', EMAIL),
							})}
						/>

						<TextArea
							label="Message"
							placeholder="Message"
							error={errors.message}
							{...register('message', {
								required: required('Message'),
							})}
						/>
					</section>

					<div className="modal-footer">
						<Button variant="outline" variation="secondary" type="button" onClick={handleCloseModal}>
							<Typography variation="button-medium" weight="bold">
								Cancel
							</Typography>
						</Button>
						<div className="flex-1" />
						<Button>
							<Typography variation="button-medium" weight="bold">
								{isSubmitting ? 'Loading' : 'Invite'}
							</Typography>
						</Button>
					</div>
				</div>
			</S.Form>
		</ReactModal>
	);
});

InviteBusinessModal.displayName = 'InviteBusinessModal';

InviteBusinessModal.propTypes = {
	onSuccessCallback: PropTypes.func.isRequired,
};

const AdminUsers = () => {
	const pendingTabRef = useRef();
	const [tabs, setTabs] = useState([
		{
			id: 1,
			label: 'Pending',
			component: <PendingTab ref={pendingTabRef} />,
		},
		{
			id: 2,
			label: 'Approved',
			component: <ApprovedTab />,
		},
		{
			id: 3,
			label: 'Declined',
			component: <DeclinedTab />,
		},
	]);

	const InviteBusinessModalRef = useRef();

	const inviteBusiness = () => InviteBusinessModalRef.current.open();

	const handleExportExcel = async () => {
		// Export businesses
		exportBusinesses({}).then(() => {
			toast.success('Your export is in progress. We will send you an email when it is ready!');
		});
	};

	useEffect(() => {
		// Set document title
		document.title = `Companies | ${META_TITLE}`;
	}, []);

	return (
		<AdminNavigation>
			<PageHeader
				title="Companies"
				action={[
					{
						id: 1,
						onClick: () => {
							handleExportExcel();
						},
						label: 'Export XLSX',
						type: 'outline',
					},
					{ id: 2, onClick: inviteBusiness, label: 'Invite Business', icon: ['fal', 'envelope'] },
				]}
			/>
			<InviteBusinessModal ref={InviteBusinessModalRef} onSuccessCallback={() => pendingTabRef.current?.onChangeCallback()} />
			<S.Wrapper>
				<Tab tabs={tabs} setTabs={setTabs} />
			</S.Wrapper>
		</AdminNavigation>
	);
};

export default AdminUsers;
