import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Popover } from '@headlessui/react';
import { usePopper } from 'react-popper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { FilterContext } from '../FilterSection';
import { RangeInput } from '../../RangeInput';
import { Typography } from '../../Typography';
import * as S from '../FilterSection.styles';

export const DistanceFilter = ({ min = 0, max = 200, dispatch }) => {
	const { state } = useContext(FilterContext);
	const { activeFilters, distance } = state;

	const [referenceElement, setReferenceElement] = useState(null);
	const [popperElement, setPopperElement] = useState(null);
	const { styles, attributes } = usePopper(referenceElement, popperElement, {
		placement: 'bottom-start',
		modifiers: [
			{
				name: 'offset',
				options: {
					offset: [0, 12],
				},
			},
		],
	});

	const clearDistanceValue = (e) => {
		e?.stopPropagation();
		dispatch({ type: 'SET_DISTANCE', payload: 0 });
		dispatch({ type: 'REMOVE_FROM_ACTIVE_FILTERS', payload: 'distance' });
	};

	const onChangeHandler = (value) => {
		if (value === 0) {
			clearDistanceValue();
		} else {
			dispatch({ type: 'SET_DISTANCE', payload: value });
			if (!activeFilters.includes('distance')) {
				dispatch({ type: 'ADD_TO_ACTIVE_FILTERS', payload: 'distance' });
			}
		}
	};

	const getButtonClassName = useMemo(() => {
		let className = 'popover-button';
		if (distance > 0) {
			className += ' active';
		}
		return className;
	}, [distance]);

	useEffect(() => {
		if (distance > 0) {
			dispatch({ type: 'ADD_TO_ACTIVE_FILTERS', payload: 'distance' });
		}
	}, []);

	return (
		<Popover as={S.SingleFilterWrapper}>
			<Popover.Button ref={setReferenceElement} className={getButtonClassName}>
				{distance ? (
					<>
						<Typography tag="p" variation="2">{`${distance} mi`}</Typography>
						<FontAwesomeIcon icon={['fal', 'times']} onClick={clearDistanceValue} />
					</>
				) : (
					<Typography tag="p" variation="2">
						Distance
					</Typography>
				)}
			</Popover.Button>
			<Popover.Panel ref={setPopperElement} style={styles.popper} {...attributes.popper} className="popover-panel">
				<div className="popover-panel-wrapper with-range">
					<RangeInput value={distance} onChange={onChangeHandler} min={min} max={max} unit="mi" />
				</div>
			</Popover.Panel>
		</Popover>
	);
};

DistanceFilter.displayName = 'DistanceFilter';

DistanceFilter.propTypes = {
	min: PropTypes.number,
	max: PropTypes.number,
	dispatch: PropTypes.func.isRequired,
};
