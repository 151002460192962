import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useDebouncedFn } from 'beautiful-react-hooks';

import { updateUser } from '../../../app/slices/user/userSlice';
import { updateUserDefaultSearch, updateUserDefaultGroup } from '../../../services/users';
import { fetchSavedSearches } from '../../../services/searches';
import { fetchGroups } from '../../../services/groups';
import { errorHandler } from '../../../services/authService';
import { Card, ErrorComponent, Select, Spinner } from '../../../components';
import * as S from './ResourcePreferencesCard.styles';

const ResourcePreferencesCard = ({ className }) => {
	const user = useSelector((state) => state.user.value);
	const dispatch = useDispatch();

	const [cardStatus, setCardStatus] = useState('idle');

	const [searchList, setSearchList] = useState([]);
	const [groupList, setGroupList] = useState([]);

	const [defaultSearch, setDefaultSearch] = useState(user?.defaultSearch);
	const [defaultGroup, setDefaultGroup] = useState(user?.defaultGroup);

	const updateDefaultSearch = async (searchId) => {
		try {
			const {
				data: { result },
			} = await updateUserDefaultSearch({ searchId });
			dispatch(updateUser(result.user));
		} catch (error) {
			errorHandler(error);
		}
	};

	const updateDefaultGroup = async (groupId) => {
		try {
			const {
				data: { result },
			} = await updateUserDefaultGroup({ groupId });
			dispatch(updateUser(result.user));
		} catch (error) {
			errorHandler(error);
		}
	};

	const onChangeDefaultSearch = (val) => {
		setDefaultSearch(val.id);
		updateDefaultSearch(val.id);
	};
	const onChangeDefaultGroup = (val) => {
		setDefaultGroup(val.groupId);
		updateDefaultGroup(val.groupId);
	};

	const fetchInfoCb = useCallback(async () => {
		setCardStatus('loading');
		try {
			const [
				{
					data: {
						result: { searches },
					},
				},
				{
					data: {
						result: { groups },
					},
				},
			] = await Promise.all([fetchSavedSearches({ page: 0 }), fetchGroups({ page: 0 })]);
			setSearchList(searches);
			setGroupList(groups);
			setCardStatus('success');
		} catch (error) {
			errorHandler(error);
			setCardStatus('error');
		}
	}, []);

	const renderContent = () => {
		switch (cardStatus) {
			case 'loading':
				return <Spinner />;
			case 'error':
				return <ErrorComponent />;
			case 'success':
				return (
					<S.Wrapper>
						<Select getOptionLabel={(option) => option.name} getOptionValue={(option) => option.id} label="Default Saved Search" placeholder="Saved Search Name" options={searchList} value={searchList.find((c) => c.id === defaultSearch) || ''} onChange={onChangeDefaultSearch} />
						<Select getOptionLabel={(option) => option.name} getOptionValue={(option) => option.groupId} label="Default Group" placeholder="Group Name" options={groupList} value={groupList.find((c) => c.groupId === defaultGroup) || ''} onChange={onChangeDefaultGroup} />
					</S.Wrapper>
				);
			default:
				return null;
		}
	};

	useEffect(() => {
		if (cardStatus === 'idle') {
			fetchInfoCb();
		}
	}, [cardStatus, fetchInfoCb]);

	return (
		<Card className={className} title="Resource Preferences" transparentHeaderBorder={true}>
			{renderContent()}
		</Card>
	);
};

ResourcePreferencesCard.propTypes = {
	className: PropTypes.string,
};

export default ResourcePreferencesCard;
