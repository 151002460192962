import React, { useContext, useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { useChat } from '../../../hooks';
import { fetchMessages } from '../../../services/messages';
import { IconButton, Picture, Typography, Chat } from '../../../components';
import { BookingDetailsContext } from '../BookingDetail';
import * as S from './MessagesTab.styles';
import { ROLES } from '../../../../constants/general.constants';

export const MessagesTab = ({ role }) => {
	const {
		state: { booking },
	} = useContext(BookingDetailsContext);
	const user = useSelector((state) => state.user.value);
	const { messages, setMessages } = useChat(booking.id);
	const [pageStatus, setPageStatus] = useState('idle');
	const history = useHistory();

	let sendProps;
	if (role === ROLES.TEACHER || booking.isCreator) {
		sendProps = {
			bookingId: booking.id,
			businessId: booking.business.id,
		};
	} else if (Object.keys(booking.creatorBusiness).length > 0) {
		if (booking.creatorBusiness.id === user.business.id) {
			sendProps = {
				bookingId: booking.id,
				businessId: booking.business.id,
			};
		} else {
			sendProps = {
				bookingId: booking.id,
				businessId: booking.creatorBusiness.id,
			};
		}
	} else {
		sendProps = { bookingId: booking.id, recipientId: booking.creator.id };
	}

	async function initializeData() {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchMessages({ page: 0, displayLimit: 100000, bookingId: booking.id, filterOptions: { received: true, sent: true } });
			setMessages(result.messages);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}

	useEffect(() => {
		// Load initial data
		if (pageStatus === 'idle') {
			initializeData();
		}
	}, []);

	const renderRecipientInfoWrapper = () => {
		if (role === ROLES.TEACHER || booking.isCreator) {
			return (
				<S.InfoWrapper>
					<Picture className="avatar-image" src={booking.business.logoObj['200']} aspectRatio="1/1" alt="profile" />
					<div className="info-block">
						<Typography tag="h3" weight="bold">
							{booking.business.name}
						</Typography>
						<Typography tag="p" variation="2">
							{booking.resource.title}
						</Typography>
					</div>
					<IconButton
						className="info-button"
						icon={['fal', 'info-circle']}
						onClick={() => {
							history.push({ pathname: role === ROLES.ADMIN || role === ROLES.SUPER ? `/admin/companies/${booking.business.id}` : `/${role || 'teacher'}/directory/business/${booking.business.id}`, state: { backUrl: window.location.pathname, backTitle: 'Back to Booking' } });
						}}
					/>
				</S.InfoWrapper>
			);
		} else if (Object.keys(booking.creatorBusiness).length > 0) {
			if (booking.creatorBusiness.id === user.business.id) {
				return (
					<S.InfoWrapper>
						<Picture className="avatar-image" src={booking.business.logoObj['200']} aspectRatio="1/1" alt="profile" />
						<div className="info-block">
							<Typography tag="h3" weight="bold">
								{booking.business.name}
							</Typography>
							<Typography tag="p" variation="2">
								{booking.resource.title}
							</Typography>
						</div>
						<IconButton
							className="info-button"
							icon={['fal', 'info-circle']}
							onClick={() => {
								history.push({ pathname: role === ROLES.ADMIN || role === ROLES.SUPER ? `/admin/companies/${booking.business.id}` : `/${role || 'teacher'}/directory/business/${booking.business.id}`, state: { backUrl: window.location.pathname, backTitle: 'Back to Booking' } });
							}}
						/>
					</S.InfoWrapper>
				);
			} else {
				return (
					<S.InfoWrapper>
						<Picture className="avatar-image" src={booking.creatorBusiness.logoObj['200']} aspectRatio="1/1" alt="profile" />
						<div className="info-block">
							<Typography tag="h3" weight="bold">
								{booking.creatorBusiness.name}
							</Typography>
							<Typography tag="p" variation="2">
								{booking.resource.title}
							</Typography>
						</div>
						<IconButton
							className="info-button"
							icon={['fal', 'info-circle']}
							onClick={() => {
								history.push({ pathname: role === ROLES.ADMIN || role === ROLES.SUPER ? `/admin/companies/${booking.business.id}` : `/${role || 'teacher'}/directory/business/${booking.creatorBusiness.id}`, state: { backUrl: window.location.pathname, backTitle: 'Back to Booking' } });
							}}
						/>
					</S.InfoWrapper>
				);
			}
		} else {
			return (
				<S.InfoWrapper>
					<Picture className="avatar-image" src={booking.creator.profileImageObj['200']} aspectRatio="1/1" alt="profile" />
					<div className="info-block">
						<Typography tag="h3" weight="bold">
							{booking.creator.firstName} {booking.creator.lastName}
						</Typography>
						<Typography tag="p" variation="2">
							{booking.resource.title}
						</Typography>
					</div>
				</S.InfoWrapper>
			);
		}
	};

	return (
		<S.RequestChatWrapper>
			<S.ChatHeader>{renderRecipientInfoWrapper()}</S.ChatHeader>
			<Chat loading={pageStatus === 'loading'} messages={messages} setMessages={setMessages} sendProps={sendProps} />
		</S.RequestChatWrapper>
	);
};

MessagesTab.propTypes = {
	role: PropTypes.string,
};
