import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDebouncedFn } from 'beautiful-react-hooks';
import { addDays, format } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

import { fetchReport, generateReport } from '../../../services/reports';
import { Card, Typography, EmptyComponent, Spinner } from '../../../components';
import { BarChartGraph, MapChartGraph, PieChartGraph, TableChartGraph, FiltersSection, LineChartGraph } from '../../../components/graphs';
import { REPORT_TYPES, ROLES, REPORT_VIEW_OPTIONS, REPORT_VIEW_OPTIONS_FOR_ROLE } from '../../../../constants/general.constants';
import * as S from './EngagementsTab.styles';

/**
 * Constants
 */

const MAX_POLLING_ATTEMPTS = 10;

/**
 * State
 */

let pollingTimer = null;

export const EngagementsTab = ({ role }) => {
	const [pageStatus, setPageStatus] = useState('idle');
	const [report, setReport] = useState(null);

	const filterRef = useRef();
	const pollingCount = useRef(0);

	// Create valid view options for role
	const validViewOptions = REPORT_VIEW_OPTIONS_FOR_ROLE(role == null, [role]);

	const onChangeCallback = useDebouncedFn(async () => {
		setPageStatus('loading');
		const accessKey = uuidv4();
		try {
			const {
				data: {
					result: { report },
				},
			} = await generateReport({
				isPublic: role == null,
				type: REPORT_TYPES.ENGAGEMENTS,
				graphs: [],
				accessKey,
				...filterRef.current.value,
			});
			setReport(report);
			setPageStatus('success');
		} catch (error) {
			// Check if timeout error
			if (error?.response?.status === 503) {
				pollForReportUpdates(accessKey);
			} else {
				setPageStatus('error');
			}
		}
	}, 200);

	async function initializeData() {
		setPageStatus('loading');
		const accessKey = uuidv4();
		try {
			const {
				data: {
					result: { report },
				},
			} = await generateReport({
				isPublic: role == null,
				type: REPORT_TYPES.ENGAGEMENTS,
				graphs: [],
				viewOption: validViewOptions[0],
				businessLocationId: 'all',
				startDate: format(addDays(new Date(), -365), 'MM-dd-yyyy'),
				endDate: format(addDays(new Date(), 1), 'MM-dd-yyyy'),
				countyId: 'all',
				accessKey,
			});
			setReport(report);
			setPageStatus('success');
		} catch (error) {
			// Check if timeout error
			if (error?.response?.status === 503) {
				pollForReportUpdates(accessKey);
			} else {
				setPageStatus('error');
			}
		}
	}

	const pollForReportUpdates = (accessKey) => {
		pollingTimer = setInterval(async () => {
			// Fetch report
			const {
				data: {
					result: { report },
				},
			} = await fetchReport({ accessKey });

			// Check if report if complete
			if (report.isComplete === true) {
				// Cancel interval
				if (pollingTimer != null) clearTimeout(pollingTimer);
				pollingTimer = null;

				// Clear polling count
				pollingCount.current = 0;

				// Set report
				setReport(report);
				setPageStatus('success');
			}

			// Update poll count
			pollingCount.current += 1;

			// Show error if exceeded maxium poll attempts
			if (pollingCount.current > MAX_POLLING_ATTEMPTS) {
				// Cancel interval
				if (pollingTimer != null) clearTimeout(pollingTimer);
				pollingTimer = null;

				// Clear polling count
				pollingCount.current = 0;

				// Set error state
				setPageStatus('error');
			}
		}, 5 * 1000); // 5 seconds
	};

	useEffect(() => {
		// Load initial data
		if (pageStatus === 'idle') {
			initializeData();
		}
	}, [pageStatus]);

	const renderGraphs = () => {
		if (report?.timepoints.length > 0) {
			if (role == null) {
				return (
					<>
						<LineChartGraph className="col6" data={report.timepoints} title="Engagements Over Time" />
						<BarChartGraph className="col6" data={report?.timepoints[report.timepoints.length - 1]?.data?.categoryType} title="Category Type" />
						<MapChartGraph className="col6" title="County" data={report?.timepoints[report.timepoints.length - 1]?.data?.county} />
						<PieChartGraph className="col6" data={report?.timepoints[report.timepoints.length - 1]?.data?.locationType} title="Location Type" />
						<BarChartGraph className="col6" data={report?.timepoints[report.timepoints.length - 1]?.data?.careerClusters} title="Career Cluster" />
						<PieChartGraph className="col6" data={report?.timepoints[report.timepoints.length - 1]?.data?.employabilitySkills} title="Employability Skills" />
						<TableChartGraph className="col6" data={report?.timepoints[report.timepoints.length - 1]?.data?.curriculumStandards} title="Curriculum Standard" labels={['Curriculum Standard', '# of Engagements']} />
						<BarChartGraph className="col6" data={report?.timepoints[report.timepoints.length - 1]?.data?.gradeLevel} title="Grade Level" />
						<TableChartGraph className="col6" data={report?.timepoints[report.timepoints.length - 1]?.data?.companies} title="Companies" labels={['Company', '# of Engagements']} fields={['name', 'engagementsCount']} />
						<TableChartGraph className="col6" data={report?.timepoints[report.timepoints.length - 1]?.data?.schools} title="Schools" labels={['School', '# of Engagements']} fields={['name', 'engagementsCount']} />
						<PieChartGraph className="col6" data={report?.timepoints[report.timepoints.length - 1]?.data?.gender} title="Gender" />
					</>
				);
			} else if (role === ROLES.TEACHER) {
				return (
					<>
						<LineChartGraph className="col6" data={report.timepoints} title="Engagements Over Time" />
						<MapChartGraph className="col6" title="County" data={report?.timepoints[report.timepoints.length - 1]?.data?.county} />
						<PieChartGraph className="col6" data={report?.timepoints[report.timepoints.length - 1]?.data?.locationType} title="Location Type" />
						<BarChartGraph className="col6" data={report?.timepoints[report.timepoints.length - 1]?.data?.careerClusters} title="Career Cluster" />
						<PieChartGraph className="col6" data={report?.timepoints[report.timepoints.length - 1]?.data?.employabilitySkills} title="Employability Skills" />
						<TableChartGraph className="col6" data={report?.timepoints[report.timepoints.length - 1]?.data?.curriculumStandards} title="Curriculum Standard" labels={['Curriculum Standard', '# of Engagements']} />
						<TableChartGraph className="col6" data={report?.timepoints[report.timepoints.length - 1]?.data?.groups} title="Groups" labels={['Group', '# of Engagements']} fields={['name', 'engagementsCount']} />
						<TableChartGraph className="col6" data={report?.timepoints[report.timepoints.length - 1]?.data?.companies} title="Companies" labels={['Company', '# of Engagements']} fields={['name', 'engagementsCount']} />
					</>
				);
			} else if (role === ROLES.BUSINESS) {
				return (
					<>
						<LineChartGraph className="col6" data={report.timepoints} title="Engagements Over Time" />
						<BarChartGraph className="col6" data={report?.timepoints[report.timepoints.length - 1]?.data?.gradeLevel} title="Grade Level" />
						<BarChartGraph className="col6" data={report?.timepoints[report.timepoints.length - 1]?.data?.businessLocations} title="Company Locations" />
						<PieChartGraph className="col6" data={report?.timepoints[report.timepoints.length - 1]?.data?.locationType} title="Location Type" />
						<TableChartGraph className="col6" data={report?.timepoints[report.timepoints.length - 1]?.data?.schools} title="Schools" labels={['School', '# of Engagements']} fields={['name', 'engagementsCount']} />
						<MapChartGraph className="col6" title="County" data={report?.timepoints[report.timepoints.length - 1]?.data?.county} />
						<PieChartGraph className="col6" data={report?.timepoints[report.timepoints.length - 1]?.data?.gender} title="Gender" />
						<PieChartGraph className="col6" data={report?.timepoints[report.timepoints.length - 1]?.data?.employabilitySkills} title="Employability Skills" />
						<TableChartGraph className="col6" data={report?.timepoints[report.timepoints.length - 1]?.data?.teachers} title="Teachers" labels={['Teacher', '# of Engagements']} fields={['name', 'engagementsCount']} />
					</>
				);
			} else if (role === ROLES.ADMIN || role === ROLES.SUPER) {
				return (
					<>
						<LineChartGraph className="col6" data={report.timepoints} title="Engagements Over Time" />
						<BarChartGraph className="col6" data={report?.timepoints[report.timepoints.length - 1]?.data?.categoryType} title="Category Type" />
						<MapChartGraph className="col6" title="County" data={report?.timepoints[report.timepoints.length - 1]?.data?.county} />
						<PieChartGraph className="col6" data={report?.timepoints[report.timepoints.length - 1]?.data?.locationType} title="Location Type" />
						<BarChartGraph className="col6" data={report?.timepoints[report.timepoints.length - 1]?.data?.careerClusters} title="Career Cluster" />
						<PieChartGraph className="col6" data={report?.timepoints[report.timepoints.length - 1]?.data?.employabilitySkills} title="Employability Skills" />
						<TableChartGraph className="col6" data={report?.timepoints[report.timepoints.length - 1]?.data?.curriculumStandards} title="Curriculum Standard" labels={['Curriculum Standard', '# of Engagements']} />
						<BarChartGraph className="col6" data={report?.timepoints[report.timepoints.length - 1]?.data?.gradeLevel} title="Grade Level" />
						<TableChartGraph className="col6" data={report?.timepoints[report.timepoints.length - 1]?.data?.companies} title="Companies" labels={['Company', '# of Engagements']} fields={['name', 'engagementsCount']} />
						<TableChartGraph className="col6" data={report?.timepoints[report.timepoints.length - 1]?.data?.schools} title="Schools" labels={['School', '# of Engagements']} fields={['name', 'engagementsCount']} />
						<PieChartGraph className="col6" data={report?.timepoints[report.timepoints.length - 1]?.data?.gender} title="Gender" />
					</>
				);
			}
		}
	};

	return (
		<S.Wrapper>
			<FiltersSection ref={filterRef} onChangeCallback={onChangeCallback} />
			{pageStatus === 'loading' ? (
				<Spinner />
			) : report ? (
				<S.GridWrapper>
					<S.CardWrapper className="col12">
						{report?.metrics?.map((metric) => (
							<Card className="metric-card" key={metric.id}>
								<Typography tag="h1" weight="extrablack">
									{metric.data}
								</Typography>
								<Typography tag="h6" weight="semibold">
									{metric.name}
								</Typography>
							</Card>
						))}
					</S.CardWrapper>
					{renderGraphs()}
				</S.GridWrapper>
			) : (
				<EmptyComponent title="Gathering report data" message="We are currently processing report data. Check back later to view full reports." icon={['fal', 'chart-bar']} />
			)}
		</S.Wrapper>
	);
};

EngagementsTab.propTypes = {
	role: PropTypes.string,
};
