import styled from 'styled-components';

export const Wrapper = styled.div`
	display: grid;
	padding: 1rem;
	grid-template-columns: 1fr;
	gap: 1.25rem;
	grid-template-areas:
		'a1'
		'a2'
		'a3'
		'a4'
		'a5';

	.a1 {
		grid-area: a1;
	}
	.a2 {
		grid-area: a2;
	}
	.a3 {
		grid-area: a3;
	}
	.a4 {
		grid-area: a4;
	}
	.a5 {
		grid-area: a5;
	}

	@media screen and (min-width: 992px) {
		grid-template-columns: repeat(2, 1fr);
		grid-template-areas:
			'a1 a1'
			'a2 a2'
			'a3 a4'
			'. a5';
	}
`;
