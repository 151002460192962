import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import * as S from './Typography.styles';

export const Typography = forwardRef(({ children, tag = 'span', variation = '1', weight = 'regular', truncate, center, ...rest }, ref) => {
	return (
		<S.Typography ref={ref} as={tag} variation={variation} weight={weight} truncate={truncate} center={center} {...rest}>
			{children}
		</S.Typography>
	);
});

Typography.displayName = 'Typography';
Typography.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	tag: PropTypes.oneOf(['label', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'span']),
	variation: PropTypes.oneOf(['1', '2', '3', 'button-small', 'button-medium']),
	weight: PropTypes.oneOf(['regular', 'semibold', 'bold', 'extrablack']),
	truncate: PropTypes.bool,
	center: PropTypes.bool,
};
