import styled, { css } from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;

	& > label {
		cursor: pointer;
		color: ${({ error }) => (error ? colors.stateDanger : colors.neutralText)};
	}
`;

const SIZES = {
	small: css`
		font-size: 0.75rem;
		min-height: 2.25rem;
	`,
	medium: css`
		font-size: 1rem;
		min-height: 2.8125rem;
	`,
};

export const TextInput = styled.input`
	appearance: none;
	padding: 0 0.75rem;
	border-radius: 0.375rem;
	border: 1px solid ${colors.neutralForm};
	color: black;
	line-height: 'normal';
	width: 100%;
	outline: none;

	${({ size }) => SIZES[size]}

	&[type='password'] {
		letter-spacing: 0.25rem;
	}

	&:focus {
		border-color: ${colors.neutralButton};
	}

	&:read-only {
		background-color: ${colors.neutralBackground};
		border-color: ${colors.neutralForm};
	}

	${({ withIcon }) =>
		withIcon &&
		css`
			padding-left: 2.25rem;
		`}

	${({ error }) =>
		error &&
		css`
			color: ${colors.stateDanger};
			border-color: ${colors.stateDanger};
			:focus {
				border-color: ${colors.stateDanger};
			}
		`}
`;

export const FloatingWrapper = styled.div`
	position: absolute;
	bottom: -1rem;
	color: ${colors.stateDanger};
	max-width: 100%;

	p {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
`;

export const IconWrapper = styled.div`
	position: absolute;
	display: flex;
	height: 100%;
	justify-content: center;
	align-items: center;
	padding: 0 0.75rem;
	pointer-events: none;

	svg {
		color: ${colors.neutralForm};
	}
`;
