import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { Typography } from '../Typography';
import * as S from './RangeInput.styles';

export const RangeInput = forwardRef(({ min = 0, max = 100, unit, label, error, ...rest }, ref) => {
	const Thumb = (props, state) => (
		<S.Thumb {...props}>
			<Typography tag="p" className="floating-top">
				{`${state.valueNow}${unit ? unit : ''}`}
			</Typography>
		</S.Thumb>
	);

	const Track = (props, state) => <S.Track {...props} index={state.index} />;

	return (
		<S.Container>
			{label && (
				<Typography tag="label" weight="semibold">
					{label}
				</Typography>
			)}
			<S.Wrapper>
				<Typography tag="p" variation="2" className="floating-bottom left">{`${min} ${unit ? unit : ''}`}</Typography>
				<S.Slider ref={ref} {...rest} min={min} max={max} renderThumb={Thumb} renderTrack={Track} />
				<Typography tag="p" variation="2" className="floating-bottom right">{`${max} ${unit ? unit : ''}`}</Typography>
			</S.Wrapper>
			{error && error?.message && (
				<S.FloatingWrapper title={error.message}>
					<Typography tag="p" variation="2">
						{error.message}
					</Typography>
				</S.FloatingWrapper>
			)}
		</S.Container>
	);
});

RangeInput.displayName = 'RangeInput';
RangeInput.propTypes = {
	unit: PropTypes.string,
	min: PropTypes.number,
	max: PropTypes.number,
	label: PropTypes.string,
	error: PropTypes.shape(),
};
