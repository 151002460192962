import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { ACCEPTABLE_FILE_TYPES } from '../../../../../constants/general.constants';
import { required } from '../../../../utils/form-default-errors';
import { IconButton, TextArea, TextInput, Typography } from '../../../../components';
import * as S from '../FulfillRequestModal.styles';

export const StepOne = ({ role }) => {
	const {
		register,
		setValue,
		getValues,
		watch,
		formState: { errors },
	} = useFormContext();

	const fileObjsDropzone = useDropzone({
		onDrop: useCallback((acceptedFiles) => acceptedFiles.length > 0 && setValue('fileObjs', formatFileArrayValue('fileObjs', acceptedFiles)), []),
		accept: ACCEPTABLE_FILE_TYPES.RESOURCE_MEDIA.MIME_TYPES,
	});

	const fileObjs = watch('fileObjs');

	const removeIndexFromArray = (arrayName, index) =>
		setValue(
			arrayName,
			getValues(arrayName).filter((_, i) => i !== index)
		);

	function formatFileArrayValue(arrayName, files) {
		if (getValues(arrayName)) {
			const existingFiles = getValues(arrayName);
			return existingFiles.concat(files.filter((file) => !existingFiles.find((existingFile) => existingFile.name === file.name)));
		}
		return Array.from(files);
	}

	return (
		<div className="modal-content">
			<Typography className="bigger-title" tag="h1" weight="extrablack" center>
				Files
			</Typography>
			<Typography tag="p" className="description" center>
				Add any documents that help fulfilled your request.
			</Typography>

			<div className="fields-wrapper">
				<S.FilesListWrapper>
					<header>
						<Typography tag="h3" weight="bold">
							Private Files
						</Typography>
						<Typography tag="p" variation="2">
							Upload files associated with the request that only you will see.
						</Typography>
					</header>

					<S.FileListDropzone {...fileObjsDropzone.getRootProps()} isDragActive={fileObjsDropzone.isDragActive} isDragAccept={fileObjsDropzone.isDragAccept} isDragReject={fileObjsDropzone.isDragReject}>
						<input {...fileObjsDropzone.getInputProps()} />

						<div className="upload-placeholder">
							<div className="upload-placeholder__picture">
								<FontAwesomeIcon icon={['fal', 'image']} size="2x" />
							</div>
							<Typography tag="p" variation="2" center className="upload-placeholder__paragraph">
								Drag and drop files here, or <strong>browse</strong>
								<br />
								Supports PDF, Doc, PPT, JPG, PNG
							</Typography>
						</div>
					</S.FileListDropzone>

					<div className="files-wrapper">
						{fileObjs?.map((file, index) => (
							<div key={file.name} className="files-wrapper__item">
								<header>
									<Typography tag="h4" weight="bold">
										File {index + 1}
									</Typography>
									<IconButton type="button" icon={['fal', 'trash']} size={1} onClick={() => removeIndexFromArray('fileObjs', index)} />
								</header>

								<div className="files-wrapper__item__info">
									<Typography tag="h6" weight="semibold">
										File
									</Typography>
									<div className="files-wrapper__item__details">
										<FontAwesomeIcon icon={['fal', 'file']} size="lg" />
										<div>
											<Typography tag="p">{file.name}</Typography>
										</div>
									</div>
								</div>

								<TextInput label="Title" error={errors.fileObjs?.[index]?.title} {...register('fileObjs.' + index + '.title', { required: required('Title') })} />

								<TextArea label="Description" error={errors.fileObjs?.[index]?.description} {...register('fileObjs.' + index + '.description', { required: required('Description') })} />
							</div>
						))}
					</div>
				</S.FilesListWrapper>
			</div>
		</div>
	);
};

StepOne.propTypes = {
	role: PropTypes.string,
};
