import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { BusinessCompanyEmployeeDetailContext } from '../BusinessCompanyEmployeeDetail';
import { ErrorComponent, Spinner } from '../../../components';
import { fetchEmployee } from '../../../services/business';
import { LocationsCard } from './LocationsCard';
import { AccountCard } from './AccountCard';
import { ProfileCard } from './ProfileCard';
import * as S from './OverviewTab.styles';

export const OverviewTab = () => {
	const { dispatch } = useContext(BusinessCompanyEmployeeDetailContext);
	const { id: userId } = useParams();
	const [tabStatus, setTabStatus] = useState('idle');

	async function initializeData() {
		try {
			setTabStatus('loading');
			const {
				data: {
					result: { employee },
				},
			} = await fetchEmployee({ userId });
			dispatch({ type: 'SET_EMPLOYEE', payload: employee });
			setTabStatus('success');
		} catch (error) {
			setTabStatus('error');
		}
	}

	function renderContent() {
		switch (tabStatus) {
			case 'loading':
				return <Spinner />;
			case 'success':
				return (
					<S.Wrapper>
						<ProfileCard className="b1" />
						<AccountCard className="b2" />
						<LocationsCard className="b3" />
					</S.Wrapper>
				);
			case 'error':
				return <ErrorComponent />;
			default:
				return null;
		}
	}

	useEffect(() => {
		if (tabStatus === 'idle') {
			initializeData();
		}
	}, [tabStatus]);

	return renderContent();
};
