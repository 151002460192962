// COPYRIGHT NCBCE

/**
 * Roles
 */

exports.ROLES = {
	TEACHER: 'teacher',
	BUSINESS: 'business',
	ADMIN: 'admin',
	SUPER: 'super'
};

exports.PERMISSIONS = [
	{ label: 'Admin', value: 'admin' },
	{ label: 'Staff', value: 'staff' },
];

/**
 * Teacher Roles Options
 */

exports.TEACHER_ROLES_OPTIONS = [
	{ value: 'Academy Coordinator', label: 'Academy Coordinator' },
	{ value: 'Assistant Principal', label: 'Assistant Principal' },
	{ value: 'Career Development Coordinator', label: 'Career Development Coordinator' },
	{ value: 'Career Coach', label: 'Career Coach' },
	{ value: 'Principal', label: 'Principal' },
	{ value: 'School Counselor', label: 'School Counselor' },
	{ value: 'School to Career Coordinator', label: 'School to Career Coordinator' },
	{ value: 'Special Populations Coordinator', label: 'Special Populations Coordinator' },
	{ value: 'Teacher (Core)', label: 'Teacher (Core)' },
	{ value: 'Teacher (CTE)', label: 'Teacher (CTE)' },
	{ value: 'Teacher (Exceptional Children)', label: 'Teacher (Exceptional Children)' },
	{ value: 'Teacher (Elective)', label: 'Teacher (Elective)' },
	{ value: 'Work Based Learning Coordinator', label: 'Work Based Learning Coordinator' },
	{ value: 'CTE Assistant Director / CIMC', label: 'CTE Assistant Director / CIMC' }
];

/**
 * Business Permission Options
 */

exports.BUSINESS_PERMISSION_OPTIONS = [
	{ label: 'Owner', value: 'owner' },
	{ label: 'Admin', value: 'admin' },
	{ label: 'Staff', value: 'staff' },
];

/**
 * General
 */

exports.DEFAULT_TIMEZONE = 'America/New_York';
exports.PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
exports.META_TITLE = 'NCBCE Navigator';

/**
 * Cookies
 */

exports.SESSION_COOKIE_EXPIRATION = 365 * 24 * 60 * 60 * 1000; // One year
exports.SESSION_TOKEN_COOKIE = 'e_s_token';

/**
 * Cookie Domain
 */

exports.COOKIE_DOMAIN = () => {
	switch (process.env.ENV) {
		case 'development':
			return null;
		case 'qa':
			return process.env.APP_URL.replace(/(^\w+:|^)\/\//, '');
		case 'staging':
			return process.env.APP_URL.replace(/(^\w+:|^)\/\//, '');
		case 'production':
			return process.env.APP_URL.replace(/(^\w+:|^)\/\//, '');
		default:
			return null;
	}
};

/**
 * File Upload Limits (Bytes)
 */

exports.FILE_UPLOAD_LIMITS = {
	PROFILE_MEDIA: 10000000, // 10 MB
	RESOURCE_MEDIA: 10000000, // 10 MB
	MESSAGE_MEDIA: 10000000, // 10 MB
};

/**
 * Acceptable File Types
 */

exports.ACCEPTABLE_FILE_TYPES = {
	PROFILE_MEDIA: {
		EXTENSIONS: [
			'png',
			'jpeg',
			'jpg'
		],
		MIME_TYPES: [
			'image/png',
			'image/jpeg',
			'image/jpg'
		]
	},
	RESOURCE_MEDIA: {
		EXTENSIONS: [
			'png',
			'jpeg',
			'jpg',
			'pdf',
			'doc',
			'docx',
			'ppt',
			'pptx'
		],
		MIME_TYPES: [
			'image/png',
			'image/jpeg',
			'image/jpg',
			'application/pdf',
			'application/msword',
			'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
			'application/vnd.ms-powerpoint',
			'application/vnd.openxmlformats-officedocument.presentationml.presentation'
		]
	},
	MESSAGE_MEDIA: {
		EXTENSIONS: [
			'png',
			'jpeg',
			'jpg',
			'pdf'
		],
		MIME_TYPES: [
			'image/png',
			'image/jpeg',
			'image/jpg',
			'application/pdf'
		]
	}
};

/**
 * File Extensions
 */

exports.FILE_EXTENSIONS = [
	{
		value: 'doc',
		label: 'Word Document',
		icon: ['fal',
			'file-word']
	},
	{
		value: 'docx',
		label: 'Word Document',
		icon: ['fal',
			'file-word']
	},
	{
		value: 'pdf',
		label: 'PDF Document',
		icon: ['fal',
			'file-pdf']
	},
	{
		value: 'png',
		label: 'PNG Image',
		icon: ['fal',
			'file-image']
	},
	{
		value: 'jpeg',
		label: 'JPEG Image',
		icon: ['fal',
			'file-image']
	},
	{
		value: 'jpg',
		label: 'JPG Image',
		icon: ['fal',
			'file-image']
	},
	{
		value: 'ppt',
		label: 'PowerPoint Document',
		icon: ['fal',
			'file-powerpoint']
	},
	{
		value: 'pptx',
		label: 'PowerPoint Document',
		icon: ['fal',
			'file-powerpoint']
	}
];

/**
 * State Options
 */

exports.STATE_ABBREVIATIONS = [
	'AL',
	'AK',
	'AS',
	'AZ',
	'AR',
	'CA',
	'CO',
	'CT',
	'DE',
	'DC',
	'FM',
	'FL',
	'GA',
	'GU',
	'HI',
	'ID',
	'IL',
	'IN',
	'IA',
	'KS',
	'KY',
	'LA',
	'ME',
	'MH',
	'MD',
	'MA',
	'MI',
	'MN',
	'MS',
	'MO',
	'MT',
	'NE',
	'NV',
	'NH',
	'NJ',
	'NM',
	'NY',
	'NC',
	'ND',
	'MP',
	'OH',
	'OK',
	'OR',
	'PW',
	'PA',
	'PR',
	'RI',
	'SC',
	'SD',
	'TN',
	'TX',
	'UT',
	'VT',
	'VI',
	'VA',
	'WA',
	'WV',
	'WI',
	'WY'
];

exports.STATE_OPTIONS = [
	{
		name: 'Alabama',
		abbreviation: 'AL'
	},
	{
		name: 'Alaska',
		abbreviation: 'AK'
	},
	{
		name: 'American Samoa',
		abbreviation: 'AS'
	},
	{
		name: 'Arizona',
		abbreviation: 'AZ'
	},
	{
		name: 'Arkansas',
		abbreviation: 'AR'
	},
	{
		name: 'California',
		abbreviation: 'CA'
	},
	{
		name: 'Colorado',
		abbreviation: 'CO'
	},
	{
		name: 'Connecticut',
		abbreviation: 'CT'
	},
	{
		name: 'Delaware',
		abbreviation: 'DE'
	},
	{
		name: 'District Of Columbia',
		abbreviation: 'DC'
	},
	{
		name: 'Federated States Of Micronesia',
		abbreviation: 'FM'
	},
	{
		name: 'Florida',
		abbreviation: 'FL'
	},
	{
		name: 'Georgia',
		abbreviation: 'GA'
	},
	{
		name: 'Guam',
		abbreviation: 'GU'
	},
	{
		name: 'Hawaii',
		abbreviation: 'HI'
	},
	{
		name: 'Idaho',
		abbreviation: 'ID'
	},
	{
		name: 'Illinois',
		abbreviation: 'IL'
	},
	{
		name: 'Indiana',
		abbreviation: 'IN'
	},
	{
		name: 'Iowa',
		abbreviation: 'IA'
	},
	{
		name: 'Kansas',
		abbreviation: 'KS'
	},
	{
		name: 'Kentucky',
		abbreviation: 'KY'
	},
	{
		name: 'Louisiana',
		abbreviation: 'LA'
	},
	{
		name: 'Maine',
		abbreviation: 'ME'
	},
	{
		name: 'Marshall Islands',
		abbreviation: 'MH'
	},
	{
		name: 'Maryland',
		abbreviation: 'MD'
	},
	{
		name: 'Massachusetts',
		abbreviation: 'MA'
	},
	{
		name: 'Michigan',
		abbreviation: 'MI'
	},
	{
		name: 'Minnesota',
		abbreviation: 'MN'
	},
	{
		name: 'Mississippi',
		abbreviation: 'MS'
	},
	{
		name: 'Missouri',
		abbreviation: 'MO'
	},
	{
		name: 'Montana',
		abbreviation: 'MT'
	},
	{
		name: 'Nebraska',
		abbreviation: 'NE'
	},
	{
		name: 'Nevada',
		abbreviation: 'NV'
	},
	{
		name: 'New Hampshire',
		abbreviation: 'NH'
	},
	{
		name: 'New Jersey',
		abbreviation: 'NJ'
	},
	{
		name: 'New Mexico',
		abbreviation: 'NM'
	},
	{
		name: 'New York',
		abbreviation: 'NY'
	},
	{
		name: 'North Carolina',
		abbreviation: 'NC'
	},
	{
		name: 'North Dakota',
		abbreviation: 'ND'
	},
	{
		name: 'Northern Mariana Islands',
		abbreviation: 'MP'
	},
	{
		name: 'Ohio',
		abbreviation: 'OH'
	},
	{
		name: 'Oklahoma',
		abbreviation: 'OK'
	},
	{
		name: 'Oregon',
		abbreviation: 'OR'
	},
	{
		name: 'Palau',
		abbreviation: 'PW'
	},
	{
		name: 'Pennsylvania',
		abbreviation: 'PA'
	},
	{
		name: 'Puerto Rico',
		abbreviation: 'PR'
	},
	{
		name: 'Rhode Island',
		abbreviation: 'RI'
	},
	{
		name: 'South Carolina',
		abbreviation: 'SC'
	},
	{
		name: 'South Dakota',
		abbreviation: 'SD'
	},
	{
		name: 'Tennessee',
		abbreviation: 'TN'
	},
	{
		name: 'Texas',
		abbreviation: 'TX'
	},
	{
		name: 'Utah',
		abbreviation: 'UT'
	},
	{
		name: 'Vermont',
		abbreviation: 'VT'
	},
	{
		name: 'Virgin Islands',
		abbreviation: 'VI'
	},
	{
		name: 'Virginia',
		abbreviation: 'VA'
	},
	{
		name: 'Washington',
		abbreviation: 'WA'
	},
	{
		name: 'West Virginia',
		abbreviation: 'WV'
	},
	{
		name: 'Wisconsin',
		abbreviation: 'WI'
	},
	{
		name: 'Wyoming',
		abbreviation: 'WY'
	}
];

/**
 * Days of Week
 */

exports.DAYS_OF_WEEK = [
	'sun',
	'mon',
	'tue',
	'wed',
	'thu',
	'fri',
	'sat'
];

/**
 * Sources
 */

exports.SOURCES = {
	PLATFORM: 'platform',
	RESOURCE: 'resource',
	BUSINESS: 'business',
	BUSINESS_LOCATION: 'businessLocation',
	TEACHER: 'teacher',
	EMPLOYEE: 'employee',
	GROUP: 'group',
	ADMIN: 'admin',
	USER: 'user',
	REQUEST: 'request',
	BOOKING: 'booking',
	SCHOOL: 'school',
	MEMBERSHIP: 'membership',
	SEARCH: 'search',
	TEMPLATE: 'template'
};

/**
 * Statuses
 */

exports.BOOKING_STATUSES = {
	PENDING: 'pending',
	SCHEDULED: 'scheduled',
	COMPLETE: 'complete',
	INCOMPLETE: 'incomplete',
	ARCHIVED: 'archived'
};

exports.RESOURCE_STATUSES = {
	DRAFT: 'draft',
	PUBLISHED: 'published',
	ARCHIVED: 'archived'
};

exports.TEMPLATE_STATUSES = {
	DRAFT: 'draft',
	PUBLISHED: 'published',
	ARCHIVED: 'archived'
};

exports.REQUEST_STATUSES = {
	PENDING: 'pending',
	SCHEDULED: 'scheduled',
	DRAFT: 'draft',
	COMPLETE: 'complete',
	INCOMPLETE: 'incomplete',
	ARCHIVED: 'archived'
};

exports.REQUEST_RESPONSE_STATUSES = {
	PENDING: 'pending',
	ACCEPTED: 'accepted',
	DECLINED: 'declined',
	ARCHIVED: 'archived',
	COMPLETE: 'complete',
	INCOMPLETE: 'incomplete'
};

/**
 * Grade Levels
 */

exports.GRADE_LEVELS = [
	{ value: 'KG', label: 'Kindergarten' },
	{ value: '01', label: '1st Grade' },
	{ value: '02', label: '2nd Grade' },
	{ value: '03', label: '3rd Grade' },
	{ value: '04', label: '4th Grade' },
	{ value: '05', label: '5th Grade' },
	{ value: '06', label: '6th Grade' },
	{ value: '07', label: '7th Grade' },
	{ value: '08', label: '8th Grade' },
	{ value: '09', label: '9th Grade' },
	{ value: '10', label: '10th Grade' },
	{ value: '11', label: '11th Grade' },
	{ value: '12', label: '12th Grade' },
	{ value: 'Adult', label: 'Adult' },
];

/**
 * Age Groups
 */

exports.AGE_GROUPS = [
	{
		values: [
			'KG',
			'01',
			'02',
			'03',
			'04',
			'05',
		],
		value: 'Elementary School',
		label: 'Elementary School',
		search: [
			'Elementary School',
			'Elementary'
		]
	},
	{
		values: [
			'06',
			'07',
			'08'
		],
		value: 'Middle School',
		label: 'Middle School',
		search: [
			'Middle School',
		]
	},
	{
		values: [
			'09',
			'10',
			'11',
			'12'
		],
		value: 'High School',
		label: 'High School',
		search: [
			'High School',
			'Post Secondary'
		]
	},
	{
		values: [
			'Adult'
		],
		value: '18+',
		label: '18+',
		search: [
			'18+',
			'Adult Learners',
			'Educators'
		]
	}
];

/**
 * Career Clusters
 */

exports.CAREER_CLUSTERS = [
	{ value: 'Agriculture, Food & Natural Resources', label: 'Agriculture, Food & Natural Resources' },
	{ value: 'Architecture & Construction', label: 'Architecture & Construction' },
	{ value: 'Arts, Audio/Video Technology & Communications', label: 'Arts, Audio/Video Technology & Communications' },
	{ value: 'Business, Management & Administration', label: 'Business, Management & Administration' },
	{ value: 'Clean Energy', label: 'Clean Energy' },
	{ value: 'Education & Training', label: 'Education & Training' },
	{ value: 'Finance', label: 'Finance' },
	{ value: 'Fine Arts & Humanities', label: 'Fine Arts & Humanities' },
	{ value: 'Government & Public Administration', label: 'Government & Public Administration' },
	{ value: 'Health Science', label: 'Health Science' },
	{ value: 'Hospitality & Tourism', label: 'Hospitality & Tourism' },
	{ value: 'Human Services', label: 'Human Services' },
	{ value: 'Information Technology', label: 'Information Technology' },
	{ value: 'Judicial', label: 'Judicial' },
	{ value: 'Law, Public Safety, Corrections & Security', label: 'Law, Public Safety, Corrections & Security' },
	{ value: 'Manufacturing', label: 'Manufacturing' },
	{ value: 'Marketing, Sales & Service', label: 'Marketing, Sales & Service' },
	{ value: 'Science, Technology, Engineering & Mathematics', label: 'Science, Technology, Engineering & Mathematics' },
	{ value: 'Transportation, Distribution & Logistics', label: 'Transportation, Distribution & Logistics' },
];

/**
 * Resource Categories
 */

exports.RESOURCE_CATEGORIES = {
	APPOINTMENT: [
		{
			value: 'Career Fair',
			label: 'Career Fair',
			search: [
				'Career Fair',
				'Career Fairs'
			],
			icon: 'map',
			description: 'An event where employers/companies educate students on different career paths and can give students useful contacts and information.'
		},
		{
			value: 'Job Shadow',
			label: 'Job Shadow',
			search: [
				'Job Shadow',
				'Job Shadows'
			],
			icon: 'user-friends',
			description: 'A short term program where students follow a professional throughout their day.'
		},
		{
			value: 'Guest Speaker',
			label: 'Guest Speaker',
			search: [
				'Guest Speaker',
				'Guest Speakers'
			],
			icon: 'podium',
			description: 'A professional in the workforce that speaks to students and answers questions about their particular career and personal career path.'
		},
		{
			value: 'Worksite Tour',
			label: 'Worksite Tour',
			search: [
				'Worksite Tour',
				'Worksite Tours'
			],
			icon: 'map-marker',
			description: 'Tours provided by professionals at place of work to interested students.'
		},
		{
			value: 'Mock Interview',
			label: 'Mock Interview',
			search: [
				'Mock Interview',
				'Mock Interviews'
			],
			icon: 'question-circle',
			description: 'A practice interview in which a student can use their interview skills and receive feedback on how they can better prepare for an actual interview.'
		},
		{
			value: 'Students @ Work',
			label: 'Students @ Work',
			search: [
				'Students @ Work'
			],
			icon: 'user',
			description: 'A month-long career awareness program for middle school students to learn about potential careers as well as the skills needed for those jobs.'
		}
	],
	INFORMATION: [
		{
			value: 'Career Profiles',
			label: 'Career Profiles',
			search: [
				'Career Profiles',
				'Career Profile'
			],
			icon: 'file-user',
			description: 'Brief summaries at the top of each resume defining a person’s work experience, achievements, and qualifications.'
		},
		{
			value: 'Career/Science Competitions',
			label: 'Career/Science Competitions',
			search: [
				'Career/Science Competitions',
				'Career/Science Competition'
			],
			icon: 'award',
			description: 'An event in which students compete in their knowledge about science or a specific career field.'
		},
		{
			value: 'Co-Ops',
			label: 'Co-Ops',
			search: [
				'Co-Ops',
				'Co-Op'
			],
			icon: 'book-user',
			description: 'Cooperative education where a student can integrate work-based learning into their education. They can maintain their educational path while working full time in the business of their choosing.'
		},
		{
			value: 'Externship (Teacher)',
			label: 'Externship (Teacher)',
			search: [
				'Externship (Teacher)',
			],
			icon: 'briefcase',
			description: 'Educational experience for teachers to go into business where they can learn about different careers by shadowing professionals. Allows teachers to educate their students about potential career paths.'
		},
		{
			value: 'Internship (Student)',
			label: 'Internship (Student)',
			search: [
				'Internship (Student)'
			],
			icon: 'address-card',
			description: 'A program that lasts a couple months and allows a student to learn by helping an employer by completing assignments designated by their employer.'
		},
		{
			value: 'Mentor (Student)',
			label: 'Mentor (Student)',
			search: [
				'Mentor (Student)'
			],
			icon: 'book-reader',
			description: 'A professional that a student can learn, seek advice from, and build a relationship within their specified career path.'
		},
		{
			value: 'Mentor (Teacher)',
			label: 'Mentor (Teacher)',
			search: [
				'Mentor (Teacher)'
			],
			icon: 'chalkboard-teacher',
			description: 'A professional that a teacher can learn from and build a relationship with so that teacher can educate students on that career.'
		},
		{
			value: 'On-Job Training',
			label: 'On-Job Training',
			search: [
				'On-Job Training'
			],
			icon: 'street-view',
			description: 'Training in which a student can learn by a professional through doing a specific job in a career field.'
		},
		{
			value: 'Summer Camps',
			label: 'Summer Camps',
			search: [
				'Summer Camps',
				'Summer Camp'
			],
			icon: 'campground',
			description: 'Summer programs that students attend to learn and strengthen their leadership skills. Enables students to learn from professionals daily.'
		},
		{
			value: 'Youth (Pre) Apprenticeships',
			label: 'Youth (Pre) Apprenticeships',
			search: [
				'Youth (Pre) Apprenticeships',
				'Youth (Pre) Apprenticeship',
				'Youth Apprenticeships',
				'Youth Apprenticeship'
			],
			icon: 'user-hard-hat',
			description: 'Educational experiences where students can learn from professionals through work and training with them over a period of time. Students receive hands-on experience and begin building their career.'
		},

		{
			value: 'Registered Apprenticeship',
			label: 'Registered Apprenticeship',
			search: [
				'Registered Apprenticeships',
				'Registered Apprenticeship'
			],
			icon: 'user-hard-hat',
			description: 'Educational experiences where people can learn from professionals through work and training with them over a period of time. Trainees receive hands-on experience and begin building their career.'
		},

		{
			value: 'Learning Activities',
			label: 'Learning Activities',
			search: [
				'Learning Activities',
				'Learning Activity'
			],
			icon: 'pencil',
			description: 'Activities designed to stimulate experiential learning, conceptual thinking, and prompt students to engage in discussion.'
		},
		{
			value: 'Mobile App',
			label: 'Mobile App',
			search: [
				'Mobile App'
			],
			icon: 'mobile',
			description: 'Applications on mobile devices that help students learn more about a career pathway.'
		},
		{
			value: 'Publications',
			label: 'Publications',
			search: [
				'Publications',
				'Publications'
			],
			icon: 'file-alt',
			description: 'Journals and articles that professionals publish for the public about their work in a specific career path.'
		}
	]
};

/**
 * Employability Skills
 */

exports.EMPLOYABILITY_SKILLS = [
	{ value: 'Communication Skills', label: 'Communication Skills' },
	{ value: 'Ethics', label: 'Ethics' },
	{ value: 'Problem Solving Skills', label: 'Problem Solving Skills' },
	{ value: 'Professionalism', label: 'Professionalism' },
	{ value: 'Resource Management', label: 'Resource Management' },
	{ value: 'Teamwork', label: 'Teamwork' },
];

/**
 * Curriculum Standards
 */

exports.CURRICULUM_STANDARDS = [
	{ value: 'Arts Education - Dance', label: 'Arts Education - Dance' },
	{ value: 'Arts Education - Music', label: 'Arts Education - Music' },
	{ value: 'Arts Education - Theatre Arts', label: 'Arts Education - Theatre Arts' },
	{ value: 'Arts Education - Visual Arts', label: 'Arts Education - Visual Arts' },
	{ value: 'Computer Science', label: 'Computer Science' },
	{ value: 'Digital Learning', label: 'Digital Learning' },
	{ value: 'English Language Arts', label: 'English Language Arts' },
	{ value: 'English Language Arts Extended Content Standards', label: 'English Language Arts Extended Content Standards' },
	{ value: 'Healthful Living - Health Education', label: 'Healthful Living - Health Education' },
	{ value: 'Healthful Living - Physical Education', label: 'Healthful Living - Physical Education' },
	{ value: 'Mathematics', label: 'Mathematics' },
	{ value: 'Mathematics Extended Content Standards', label: 'Mathematics Extended Content Standards' },
	{ value: 'Science', label: 'Science' },
	{ value: 'Science Extended Content Standards', label: 'Science Extended Content Standards' },
	{ value: 'Social Studies', label: 'Social Studies' },
	{ value: 'Social Studies Extended Content Standards', label: 'Social Studies Extended Content Standards' },
	{ value: ' English Language Development (for English Language Learners)', label: ' English Language Development (for English Language Learners)' },
	{ value: 'Guidance', label: 'Guidance' },
	{ value: 'World Languages', label: 'World Languages' },
	{ value: 'Career and Technical Education', label: 'Career and Technical Education' }
];

/**
 * Location Types
 */

exports.LOCATION_TYPES = [
	{
		id: 'virtual',
		name: 'Virtual',
		description: 'Hosted online via video chat, phone call, or conference call.',
		icon: [
			'fal',
			'camera'
		]
	},
	{
		id: 'inperson',
		name: 'In Person',
		description: 'Hosted at an in-person gathering.',
		icon: [
			'fal',
			'building'
		]
	},
	{
		id: 'student',
		name: 'Student Location',
		description: 'Hosted at a school or other student location.',
		icon: [
			'fal',
			'users'
		]
	},
	{
		id: 'worksite',
		name: 'Worksite Location',
		description: 'Hosted at a company headquarters or other business site.',
		icon: [
			'fal',
			'building'
		]
	}
];

/**
 * Cancel Reasons
 */

exports.REQUEST_CANCEL_REASONS = [
	{ value: 'No Longer Needed', label: 'No Longer Needed' },
	{ value: 'Request Already Fulfilled', label: 'Request Already Fulfilled' },
	{ value: 'Other', label: 'Other' },
];

/**
 * Decline Reasons
 */

exports.COMPANY_DECLINE_REASONS = [
	{ value: 'Need more information', label: 'Need more information' },
	{ value: 'Business already signed up', label: 'Business already signed up' },
	{ value: 'Other', label: 'Other' },
];
exports.BOOKING_DECLINE_REASONS = [
	{ value: 'No Longer Available', label: 'No Longer Available' },
	{ value: 'Need more information', label: 'Need more information' },
	{ value: 'Other', label: 'Other' },
];

/*
 * Report Parameters
 */

exports.REPORT_TYPES = {
	RESOURCES: 'resource',
	REQUESTS: 'request',
	BOOKINGS: 'booking',
	INFORMATION: 'information',
	ENGAGEMENTS: 'engagement'
};

exports.REPORT_VIEW_OPTIONS = {
	COUNTY: 'county',
	PSU: 'psu', // LEA
	COMPANY: 'company',
	COMPANY_LOCATION: 'company_locaton',
	SCHOOL: 'school',
	TEACHER: 'teacher',
	MEMBERSHIP: 'membership'
};

exports.REPORT_VIEW_OPTIONS_FOR_ROLE = (isPublic, userRoles) => {
	let validViewOptions = [
		exports.REPORT_VIEW_OPTIONS.COUNTY,
		exports.REPORT_VIEW_OPTIONS.PSU, // LEA
		exports.REPORT_VIEW_OPTIONS.COMPANY,
		exports.REPORT_VIEW_OPTIONS.SCHOOL
	];
	if (isPublic !== true && userRoles.includes(exports.ROLES.TEACHER)) {
		validViewOptions = [
			exports.REPORT_VIEW_OPTIONS.SCHOOL
		];
	} else if (isPublic !== true && userRoles.includes(exports.ROLES.BUSINESS)) {
		validViewOptions = [
			exports.REPORT_VIEW_OPTIONS.COMPANY_LOCATION
		];
	} else if (isPublic !== true && (userRoles.includes(exports.ROLES.ADMIN) || userRoles.includes(exports.ROLES.SUPER))) {
		validViewOptions = [
			exports.REPORT_VIEW_OPTIONS.COUNTY,
			exports.REPORT_VIEW_OPTIONS.PSU, // LEA
			exports.REPORT_VIEW_OPTIONS.SCHOOL,
			exports.REPORT_VIEW_OPTIONS.TEACHER,
			exports.REPORT_VIEW_OPTIONS.COMPANY,
			exports.REPORT_VIEW_OPTIONS.MEMBERSHIP
		];
	}
	return validViewOptions;
};

exports.REPORT_GRAPH_TYPES = {
	CATEGORY_TYPE: 'category_type',
	COUNTY: 'county',
	LOCATION_TYPE: 'location_type',
	CAREER_CLUSTER: 'career_cluster',
	EMPLOYABILITY_SKILLS: 'employability_skills',
	CURRICULUM_STANDARDS: 'curriculum_standards',
	GRADE_LEVEL: 'grade_level',
	SAVES_PER_RESOURCE: 'saves_per_resource',
	VIEWS_PER_RESOURCE: 'views_per_resource',
	CLICKS_PER_RESOURCE: 'clicks_per_resource',
	DOWNLOADS_PER_RESOURCE: 'downloads_per_resource',
	OVER_TIME: 'over_time', // requests, bookings, engagements
	COMPANIES: 'companies', // requests, bookings, information, engagements
	SCHOOLS: 'schools', // requests, bookings, information, engagements
	GENDER: 'gender', // requests, bookings, engagements
	GROUPS: 'groups', // bookings (teacher), requests (teacher), engagements (teacher)
	EMPLOYEE: 'employee', // resources (business)
	TEACHER: 'teacher', // engagements (business)
	RESOURCE: 'resource' // bookings (business)
};

/**
 * Sort Options
 */

exports.STAFF_SORT_OPTIONS = [
	{ value: 'firstName', label: 'First Name' },
	{ value: 'lastName', label: 'Last Name' },
	{ value: 'email', label: 'Email' }
];

exports.USER_SORT_OPTIONS = [
	{ value: 'firstName', label: 'First Name' },
	{ value: 'lastName', label: 'Last Name' },
	{ value: 'email', label: 'Email' },
];

exports.USER_BUSINESS_SORT_OPTIONS = [
	{ value: 'firstName', label: 'First Name' },
	{ value: 'lastName', label: 'Last Name' },
	{ value: 'email', label: 'Email' },
	{ value: 'businessName', label: 'Business Name' },
];

exports.GROUP_SORT_OPTIONS = [
	{ value: 'name', label: 'Group Name' },
	{ value: 'gradeLevel', label: 'Grade Level' },
	{ value: 'classStatus', label: 'Class Status' }
];

exports.MESSAGE_SORT_OPTIONS = [
	{ value: 'recent', label: 'Recent' },
	{ value: 'conversation', label: 'Conversation' },
];

exports.BOOKING_SORT_OPTIONS = [
	{ value: 'resourceName', label: 'Resource Name' },
	{ value: 'businessName', label: 'Business Name' },
	{ value: 'groupName', label: 'Group Name' },
	{ value: 'startDate', label: 'Start Date' }
];

exports.BUSINESS_SORT_OPTIONS = [
	{ value: 'createdDate', label: 'Created Date' },
	{ value: 'businessName', label: 'Business Name' },
	{ value: 'numberOfLocations', label: 'Number of Locations' },
	{ value: 'numberOfResources', label: 'Number of Resources' }
];

exports.BUSINESS_LOCATIONS_SORT_OPTIONS = [
	{ value: 'createdDate', label: 'Created Date' },
	{ value: 'locationName', label: 'Location Name' },
];

exports.RESOURCE_SORT_OPTIONS = [
	{ value: 'featured', label: 'Featured' },
	{ value: 'resourceName', label: 'Resource Name' },
	{ value: 'businessName', label: 'Business Name' },
	{ value: 'publishDate', label: 'Publish Date' },
];

exports.REQUEST_SORT_OPTIONS = [
	{ value: 'featured', label: 'Featured' },
	{ value: 'requestName', label: 'Request Name' },
	{ value: 'entityName', label: 'Organization Name' },
	{ value: 'publishDate', label: 'Publish Date' },
];

exports.TEMPLATE_SORT_OPTIONS = [
	{ value: 'publishDate', label: 'Publish Date' },
	{ value: 'templateName', label: 'Template Name' },
	{ value: 'gradeLevel', label: 'Grade Level' }
];

exports.MEMBERSHIP_SORT_OPTIONS = [
	{ value: 'publishDate', label: 'Publish Date' },
	{ value: 'membershipName', label: 'Membership Name' },
	{ value: 'numberOfCompanies', label: 'Number of Companies' }
];

exports.SCHOOL_SORT_OPTIONS = [
	{ value: 'createdDate', label: 'Created Date' },
	{ value: 'schoolName', label: 'School Name' },
	{ value: 'leaCode', label: 'LEA Code' }
];

exports.REVIEW_SORT_OPTIONS = [
	{ value: 'completedDate', label: 'Completed Date' },
	{ value: 'rating', label: 'Review Rating' }
];

/**
 * County Codes
 */

exports.COUNTY_OPTIONS = [
	{
		code: '001',
		name: 'Alamance',
		postalCodes: [
			'27201',
			'27202',
			'27215',
			'27216',
			'27217',
			'27244',
			'27253',
			'27258',
			'27302',
			'27340',
			'27349',
			'27359'
		]
	},
	{
		code: '002',
		name: 'Alexander',
		postalCodes: ['28636',
			'28678',
			'28681']
	},
	{
		code: '003',
		name: 'Alleghany',
		postalCodes: ['28623',
			'28627',
			'28644',
			'28663',
			'28668',
			'28675']
	},
	{
		code: '004',
		name: 'Anson',
		postalCodes: [
			'28007',
			'28091',
			'28102',
			'28119',
			'28133',
			'28135',
			'28170'
		]
	},
	{
		code: '005',
		name: 'Ashe',
		postalCodes: [
			'28615',
			'28617',
			'28626',
			'28629',
			'28631',
			'28640',
			'28643',
			'28672',
			'28684',
			'28693',
			'28694'
		]
	},
	{
		code: '006',
		name: 'Avery',
		postalCodes: [
			'28604',
			'28616',
			'28622',
			'28646',
			'28652',
			'28653',
			'28657',
			'28662',
			'28664'
		]
	},
	{
		code: '007',
		name: 'Beaufort',
		postalCodes: [
			'27806',
			'27808',
			'27810',
			'27814',
			'27817',
			'27821',
			'27860',
			'27865',
			'27889'
		]
	},
	{
		code: '008',
		name: 'Bertie',
		postalCodes: [
			'27805',
			'27847',
			'27849',
			'27872',
			'27924',
			'27957',
			'27967',
			'27983'
		]
	},
	{
		code: '009',
		name: 'Bladen',
		postalCodes: [
			'28320',
			'28332',
			'28337',
			'28392',
			'28399',
			'28433',
			'28434',
			'28448'
		]
	},
	{
		code: '010',
		name: 'Brunswick',
		postalCodes: [
			'28420',
			'28422',
			'28451',
			'28452',
			'28459',
			'28461',
			'28462',
			'28465',
			'28467',
			'28468',
			'28469',
			'28470',
			'28479'
		]
	},
	{
		code: '011',
		name: 'Buncombe',
		postalCodes: [
			'28701',
			'28704',
			'28709',
			'28711',
			'28715',
			'28728',
			'28730',
			'28748',
			'28757',
			'28770',
			'28776',
			'28778',
			'28787',
			'28801',
			'28802',
			'28803',
			'28804',
			'28805',
			'28806',
			'28810',
			'28813',
			'28814',
			'28815',
			'28816'
		]
	},
	{
		code: '012',
		name: 'Burke',
		postalCodes: [
			'28612',
			'28619',
			'28628',
			'28637',
			'28641',
			'28647',
			'28655',
			'28666',
			'28671',
			'28680',
			'28690'
		]
	},
	{
		code: '013',
		name: 'Cabarrus',
		postalCodes: [
			'28025',
			'28026',
			'28027',
			'28075',
			'28081',
			'28082',
			'28083',
			'28107',
			'28124'
		]
	},
	{
		code: '014',
		name: 'Caldwell',
		postalCodes: [
			'28611',
			'28630',
			'28633',
			'28638',
			'28645',
			'28661',
			'28667'
		]
	},
	{
		code: '015',
		name: 'Camden',
		postalCodes: ['27921',
			'27974',
			'27976']
	},
	{
		code: '016',
		name: 'Carteret',
		postalCodes: [
			'28511',
			'28512',
			'28516',
			'28520',
			'28524',
			'28528',
			'28531',
			'28553',
			'28557',
			'28570',
			'28575',
			'28577',
			'28579',
			'28581',
			'28582',
			'28589',
			'28594'
		]
	},
	{
		code: '017',
		name: 'Caswell',
		postalCodes: [
			'27212',
			'27291',
			'27305',
			'27311',
			'27314',
			'27315',
			'27379'
		]
	},
	{
		code: '018',
		name: 'Catawba',
		postalCodes: [
			'28601',
			'28602',
			'28603',
			'28609',
			'28610',
			'28613',
			'28650',
			'28658',
			'28673',
			'28682'
		]
	},
	{
		code: '019',
		name: 'Chatham',
		postalCodes: [
			'27207',
			'27208',
			'27213',
			'27228',
			'27252',
			'27256',
			'27312',
			'27344',
			'27559'
		]
	},
	{
		code: '020',
		name: 'Cherokee',
		postalCodes: ['28781',
			'28901',
			'28903',
			'28905',
			'28906']
	},
	{
		code: '021',
		name: 'Chowan',
		postalCodes: ['27932',
			'27980']
	},
	{
		code: '022',
		name: 'Clay',
		postalCodes: ['28902',
			'28904',
			'28909']
	},
	{
		code: '023',
		name: 'Cleveland',
		postalCodes: [
			'28017',
			'28020',
			'28038',
			'28042',
			'28073',
			'28086',
			'28089',
			'28090',
			'28114',
			'28136',
			'28150',
			'28151',
			'28152',
			'28169'
		]
	},
	{
		code: '024',
		name: 'Columbus',
		postalCodes: [
			'28423',
			'28424',
			'28430',
			'28431',
			'28432',
			'28436',
			'28438',
			'28439',
			'28442',
			'28450',
			'28455',
			'28456',
			'28463',
			'28472'
		]
	},
	{
		code: '025',
		name: 'Craven',
		postalCodes: [
			'28519',
			'28523',
			'28526',
			'28527',
			'28532',
			'28533',
			'28560',
			'28561',
			'28562',
			'28563',
			'28564',
			'28586'
		]
	},
	{
		code: '026',
		name: 'Cumberland',
		postalCodes: [
			'28301',
			'28301',
			'28302',
			'28303',
			'28304',
			'28305',
			'28306',
			'28307',
			'28308',
			'28309',
			'28310',
			'28311',
			'28312',
			'28314',
			'28331',
			'28342',
			'28348',
			'28356',
			'28390',
			'28391',
			'28395'
		]
	},
	{
		code: '027',
		name: 'Currituck',
		postalCodes: [
			'27916',
			'27917',
			'27923',
			'27927',
			'27929',
			'27939',
			'27941',
			'27947',
			'27950',
			'27956',
			'27958',
			'27964',
			'27965',
			'27966',
			'27973'
		]
	},
	{
		code: '028',
		name: 'Dare',
		postalCodes: [
			'27915',
			'27920',
			'27936',
			'27943',
			'27948',
			'27949',
			'27953',
			'27954',
			'27959',
			'27968',
			'27972',
			'27978',
			'27981',
			'27982'
		]
	},
	{
		code: '029',
		name: 'Davidson',
		postalCodes: [
			'27239',
			'27292',
			'27293',
			'27294',
			'27295',
			'27299',
			'27351',
			'27360',
			'27361',
			'27373',
			'27374'
		]
	},
	{
		code: '030',
		name: 'Davie',
		postalCodes: ['27006',
			'27014',
			'27028']
	},
	{
		code: '031',
		name: 'Duplin',
		postalCodes: [
			'28325',
			'28341',
			'28349',
			'28398',
			'28453',
			'28458',
			'28464',
			'28466',
			'28508',
			'28518',
			'28521'
		]
	},
	{
		code: '032',
		name: 'Durham',
		postalCodes: [
			'27503',
			'27572',
			'27701',
			'27702',
			'27703',
			'27704',
			'27705',
			'27706',
			'27707',
			'27708',
			'27709',
			'27709',
			'27710',
			'27711',
			'27711',
			'27712',
			'27713',
			'27715',
			'27717',
			'27722'
		]
	},
	{
		code: '033',
		name: 'Edgecombe',
		postalCodes: [
			'27801',
			'27802',
			'27809',
			'27815',
			'27819',
			'27852',
			'27864',
			'27881',
			'27886'
		]
	},
	{
		code: '034',
		name: 'Forsyth',
		postalCodes: [
			'27009',
			'27010',
			'27012',
			'27023',
			'27040',
			'27045',
			'27050',
			'27051',
			'27094',
			'27098',
			'27099',
			'27101',
			'27102',
			'27103',
			'27104',
			'27105',
			'27106',
			'27107',
			'27108',
			'27109',
			'27110',
			'27111',
			'27113',
			'27114',
			'27115',
			'27116',
			'27117',
			'27120',
			'27127',
			'27130',
			'27150',
			'27152',
			'27155',
			'27157',
			'27198',
			'27199',
			'27284',
			'27285'
		]
	},
	{
		code: '035',
		name: 'Franklin',
		postalCodes: ['27508',
			'27525',
			'27549',
			'27596']
	},
	{
		code: '036',
		name: 'Gaston',
		postalCodes: [
			'28006',
			'28012',
			'28016',
			'28021',
			'28032',
			'28034',
			'28052',
			'28053',
			'28054',
			'28055',
			'28056',
			'28077',
			'28098',
			'28101',
			'28120',
			'28164'
		]
	},
	{
		code: '037',
		name: 'Gates',
		postalCodes: [
			'27926',
			'27935',
			'27937',
			'27938',
			'27946',
			'27969',
			'27979'
		]
	},
	{
		code: '038',
		name: 'Graham',
		postalCodes: ['28733',
			'28771']
	},
	{
		code: '039',
		name: 'Granville',
		postalCodes: ['27507',
			'27509',
			'27522',
			'27565',
			'27581',
			'27582']
	},
	{
		code: '040',
		name: 'Greene',
		postalCodes: ['27888',
			'28538',
			'28554',
			'28580']
	},
	{
		code: '041',
		name: 'Guilford',
		postalCodes: [
			'27214',
			'27233',
			'27235',
			'27249',
			'27260',
			'27261',
			'27262',
			'27263',
			'27264',
			'27265',
			'27268',
			'27282',
			'27283',
			'27301',
			'27310',
			'27313',
			'27342',
			'27357',
			'27358',
			'27377',
			'27401',
			'27402',
			'27403',
			'27404',
			'27405',
			'27406',
			'27407',
			'27408',
			'27409',
			'27410',
			'27411',
			'27412',
			'27413',
			'27415',
			'27416',
			'27417',
			'27419',
			'27420',
			'27425',
			'27427',
			'27429',
			'27435',
			'27438',
			'27455',
			'27495',
			'27497',
			'27498',
			'27499'
		]
	},
	{
		code: '042',
		name: 'Halifax',
		postalCodes: [
			'27823',
			'27839',
			'27843',
			'27844',
			'27850',
			'27870',
			'27874',
			'27887',
			'27890'
		]
	},
	{
		code: '043',
		name: 'Harnett',
		postalCodes: [
			'27501',
			'27506',
			'27521',
			'27543',
			'27546',
			'27552',
			'28323',
			'28326',
			'28334',
			'28335',
			'28339',
			'28368'
		]
	},
	{
		code: '044',
		name: 'Haywood',
		postalCodes: [
			'28716',
			'28721',
			'28738',
			'28745',
			'28751',
			'28785',
			'28786'
		]
	},
	{
		code: '045',
		name: 'Henderson',
		postalCodes: [
			'28710',
			'28724',
			'28726',
			'28727',
			'28729',
			'28731',
			'28732',
			'28735',
			'28739',
			'28742',
			'28758',
			'28759',
			'28760',
			'28784',
			'28790',
			'28791',
			'28792',
			'28793'
		]
	},
	{
		code: '046',
		name: 'Hertford',
		postalCodes: ['27818',
			'27855',
			'27910',
			'27922',
			'27942',
			'27986']
	},
	{ code: '047', name: 'Hoke', postalCodes: ['28376'] },
	{
		code: '048',
		name: 'Hyde',
		postalCodes: ['27824',
			'27826',
			'27875',
			'27885',
			'27960']
	},
	{
		code: '049',
		name: 'Iredell',
		postalCodes: [
			'28010',
			'28115',
			'28117',
			'28123',
			'28166',
			'28625',
			'28634',
			'28660',
			'28677',
			'28687',
			'28688',
			'28689',
			'28699'
		]
	},
	{
		code: '050',
		name: 'Jackson',
		postalCodes: [
			'28707',
			'28717',
			'28723',
			'28725',
			'28736',
			'28779',
			'28783',
			'28788',
			'28789'
		]
	},
	{
		code: '051',
		name: 'Johnston',
		postalCodes: [
			'27504',
			'27520',
			'27524',
			'27527',
			'27528',
			'27542',
			'27555',
			'27568',
			'27569',
			'27576',
			'27577',
			'27593'
		]
	},
	{
		code: '052',
		name: 'Jones',
		postalCodes: ['28522',
			'28555',
			'28573',
			'28585']
	},
	{
		code: '053',
		name: 'Lee',
		postalCodes: ['27237',
			'27330',
			'27331',
			'27332',
			'27505',
			'28355']
	},
	{
		code: '054',
		name: 'Lenoir',
		postalCodes: [
			'28501',
			'28502',
			'28503',
			'28504',
			'28525',
			'28551',
			'28572'
		]
	},
	{
		code: '055',
		name: 'Lincoln',
		postalCodes: ['28033',
			'28037',
			'28080',
			'28092',
			'28093',
			'28168']
	},
	{
		code: '056',
		name: 'Macon',
		postalCodes: ['28734',
			'28741',
			'28744',
			'28763',
			'28775']
	},
	{
		code: '057',
		name: 'Madison',
		postalCodes: ['28743',
			'28753',
			'28754']
	},
	{
		code: '058',
		name: 'Martin',
		postalCodes: [
			'27825',
			'27840',
			'27841',
			'27846',
			'27857',
			'27861',
			'27871',
			'27892'
		]
	},
	{
		code: '059',
		name: 'McDowell',
		postalCodes: ['28737',
			'28749',
			'28752',
			'28761',
			'28762']
	},
	{
		code: '060',
		name: 'Mecklenburg',
		postalCodes: [
			'28031',
			'28035',
			'28036',
			'28070',
			'28078',
			'28105',
			'28106',
			'28126',
			'28130',
			'28134',
			'28201',
			'28202',
			'28203',
			'28204',
			'28205',
			'28206',
			'28207',
			'28208',
			'28209',
			'28210',
			'28211',
			'28212',
			'28213',
			'28214',
			'28215',
			'28216',
			'28217',
			'28218',
			'28219',
			'28220',
			'28221',
			'28222',
			'28223',
			'28224',
			'28226',
			'28227',
			'28228',
			'28229',
			'28230',
			'28231',
			'28232',
			'28233',
			'28234',
			'28235',
			'28236',
			'28237',
			'28241',
			'28242',
			'28243',
			'28244',
			'28246',
			'28247',
			'28253',
			'28254',
			'28255',
			'28256',
			'28258',
			'28260',
			'28262',
			'28263',
			'28265',
			'28266',
			'28269',
			'28270',
			'28271',
			'28272',
			'28273',
			'28274',
			'28275',
			'28277',
			'28278',
			'28280',
			'28281',
			'28282',
			'28284',
			'28285',
			'28287',
			'28288',
			'28289',
			'28290',
			'28296',
			'28297',
			'28299'
		]
	},
	{
		code: '061',
		name: 'Mitchell',
		postalCodes: ['28705',
			'28765',
			'28777']
	},
	{
		code: '062',
		name: 'Montgomery',
		postalCodes: ['27209',
			'27229',
			'27247',
			'27306',
			'27356',
			'27371']
	},
	{
		code: '063',
		name: 'Moore',
		postalCodes: [
			'27242',
			'27259',
			'27281',
			'27325',
			'27376',
			'28315',
			'28327',
			'28350',
			'28370',
			'28373',
			'28374',
			'28387',
			'28388',
			'28394'
		]
	},
	{
		code: '064',
		name: 'Nash',
		postalCodes: [
			'27557',
			'27803',
			'27804',
			'27807',
			'27816',
			'27856',
			'27868',
			'27878',
			'27882',
			'27891'
		]
	},
	{
		code: '065',
		name: 'New Hanover',
		postalCodes: [
			'28401',
			'28402',
			'28403',
			'28404',
			'28405',
			'28406',
			'28407',
			'28408',
			'28409',
			'28410',
			'28411',
			'28412',
			'28428',
			'28429',
			'28449',
			'28480'
		]
	},
	{
		code: '066',
		name: 'Northampton',
		postalCodes: [
			'27820',
			'27831',
			'27832',
			'27842',
			'27845',
			'27853',
			'27862',
			'27866',
			'27867',
			'27869',
			'27876',
			'27877',
			'27897'
		]
	},
	{
		code: '067',
		name: 'Onslow',
		postalCodes: [
			'28445',
			'28460',
			'28539',
			'28540',
			'28541',
			'28542',
			'28543',
			'28544',
			'28545',
			'28546',
			'28547',
			'28574',
			'28584'
		]
	},
	{
		code: '068',
		name: 'Orange',
		postalCodes: [
			'27231',
			'27243',
			'27278',
			'27510',
			'27514',
			'27515',
			'27516',
			'27517',
			'27599'
		]
	},
	{
		code: '069',
		name: 'Pamlico',
		postalCodes: [
			'28509',
			'28510',
			'28515',
			'28529',
			'28537',
			'28552',
			'28556',
			'28571',
			'28583',
			'28587'
		]
	},
	{
		code: '070',
		name: 'Pasquotank',
		postalCodes: ['27906',
			'27907',
			'27909']
	},
	{
		code: '071',
		name: 'Pender',
		postalCodes: [
			'28421',
			'28425',
			'28435',
			'28443',
			'28454',
			'28457',
			'28478'
		]
	},
	{
		code: '072',
		name: 'Perquimans',
		postalCodes: ['27919',
			'27930',
			'27944',
			'27985']
	},
	{
		code: '073',
		name: 'Person',
		postalCodes: ['27343',
			'27541',
			'27573',
			'27574',
			'27583']
	},
	{
		code: '074',
		name: 'Pitt',
		postalCodes: [
			'27811',
			'27812',
			'27827',
			'27828',
			'27829',
			'27833',
			'27834',
			'27835',
			'27836',
			'27837',
			'27858',
			'27879',
			'27884',
			'28513',
			'28530',
			'28590'
		]
	},
	{
		code: '075',
		name: 'Polk',
		postalCodes: ['28722',
			'28750',
			'28756',
			'28773',
			'28782']
	},
	{
		code: '076',
		name: 'Randolph',
		postalCodes: [
			'27203',
			'27204',
			'27205',
			'27230',
			'27248',
			'27298',
			'27316',
			'27317',
			'27341',
			'27350',
			'27355',
			'27370'
		]
	},
	{
		code: '077',
		name: 'Richmond',
		postalCodes: [
			'28330',
			'28338',
			'28345',
			'28347',
			'28363',
			'28367',
			'28379',
			'28380'
		]
	},
	{
		code: '078',
		name: 'Robeson',
		postalCodes: [
			'28319',
			'28340',
			'28357',
			'28358',
			'28359',
			'28360',
			'28362',
			'28364',
			'28369',
			'28371',
			'28372',
			'28375',
			'28377',
			'28378',
			'28383',
			'28384',
			'28386'
		]
	},
	{
		code: '079',
		name: 'Rockingham',
		postalCodes: [
			'27025',
			'27027',
			'27048',
			'27288',
			'27289',
			'27320',
			'27323',
			'27326',
			'27375'
		]
	},
	{
		code: '080',
		name: 'Rowan',
		postalCodes: [
			'27013',
			'27054',
			'28023',
			'28039',
			'28041',
			'28071',
			'28072',
			'28088',
			'28125',
			'28138',
			'28144',
			'28145',
			'28146',
			'28147',
			'28159'
		]
	},
	{
		code: '081',
		name: 'Rutherford',
		postalCodes: [
			'28018',
			'28019',
			'28024',
			'28040',
			'28043',
			'28074',
			'28076',
			'28139',
			'28160',
			'28167',
			'28720',
			'28746'
		]
	},
	{
		code: '082',
		name: 'Sampson',
		postalCodes: [
			'28318',
			'28328',
			'28329',
			'28344',
			'28366',
			'28382',
			'28385',
			'28393',
			'28441',
			'28444',
			'28447'
		]
	},
	{
		code: '083',
		name: 'Scotland',
		postalCodes: ['28343',
			'28351',
			'28352',
			'28353',
			'28396']
	},
	{
		code: '084',
		name: 'Stanly',
		postalCodes: [
			'28001',
			'28002',
			'28009',
			'28097',
			'28109',
			'28127',
			'28128',
			'28129',
			'28137',
			'28163'
		]
	},
	{
		code: '085',
		name: 'Stokes',
		postalCodes: [
			'27016',
			'27019',
			'27021',
			'27022',
			'27042',
			'27043',
			'27046',
			'27052'
		]
	},
	{
		code: '086',
		name: 'Surry',
		postalCodes: [
			'27007',
			'27017',
			'27024',
			'27030',
			'27031',
			'27041',
			'27047',
			'27049',
			'27053',
			'28621',
			'28676'
		]
	},
	{
		code: '087',
		name: 'Swain',
		postalCodes: ['28702',
			'28713',
			'28719']
	},
	{
		code: '088',
		name: 'Transylvania',
		postalCodes: [
			'28708',
			'28712',
			'28718',
			'28747',
			'28766',
			'28768',
			'28772',
			'28774'
		]
	},
	{ code: '089', name: 'Tyrrell', postalCodes: ['27925'] },
	{
		code: '090',
		name: 'Union',
		postalCodes: [
			'28079',
			'28103',
			'28104',
			'28108',
			'28110',
			'28111',
			'28112',
			'28173',
			'28174'
		]
	},
	{
		code: '091',
		name: 'Vance',
		postalCodes: ['27536',
			'27537',
			'27544',
			'27553',
			'27556',
			'27584']
	},
	{
		code: '092',
		name: 'Wake',
		postalCodes: [
			'27502',
			'27511',
			'27512',
			'27513',
			'27518',
			'27519',
			'27523',
			'27526',
			'27529',
			'27539',
			'27540',
			'27545',
			'27560',
			'27562',
			'27571',
			'27587',
			'27588',
			'27591',
			'27592',
			'27597',
			'27601',
			'27602',
			'27603',
			'27604',
			'27605',
			'27606',
			'27607',
			'27608',
			'27609',
			'27610',
			'27611',
			'27612',
			'27613',
			'27614',
			'27615',
			'27616',
			'27617',
			'27619',
			'27620',
			'27622',
			'27623',
			'27624',
			'27625',
			'27626',
			'27627',
			'27628',
			'27629',
			'27634',
			'27635',
			'27636',
			'27640',
			'27650',
			'27656',
			'27658',
			'27661',
			'27668',
			'27675',
			'27676',
			'27690',
			'27695',
			'27697',
			'27698',
			'27699'
		]
	},
	{
		code: '093',
		name: 'Warren',
		postalCodes: ['27551',
			'27563',
			'27570',
			'27586',
			'27589',
			'27594']
	},
	{
		code: '094',
		name: 'Washington',
		postalCodes: ['27928',
			'27962',
			'27970']
	},
	{
		code: '095',
		name: 'Watauga',
		postalCodes: [
			'28605',
			'28607',
			'28608',
			'28618',
			'28679',
			'28691',
			'28692',
			'28698'
		]
	},
	{
		code: '096',
		name: 'Wayne',
		postalCodes: [
			'27530',
			'27531',
			'27532',
			'27533',
			'27534',
			'27830',
			'27863',
			'28333',
			'28365',
			'28578'
		]
	},
	{
		code: '097',
		name: 'Wilkes',
		postalCodes: [
			'28606',
			'28624',
			'28635',
			'28649',
			'28651',
			'28654',
			'28656',
			'28659',
			'28665',
			'28669',
			'28670',
			'28683',
			'28685',
			'28697'
		]
	},
	{
		code: '098',
		name: 'Wilson',
		postalCodes: [
			'27813',
			'27822',
			'27851',
			'27873',
			'27880',
			'27883',
			'27893',
			'27894',
			'27895',
			'27896'
		]
	},
	{
		code: '099',
		name: 'Yadkin',
		postalCodes: ['27011',
			'27018',
			'27020',
			'27055',
			'28642']
	},
	{
		code: '100',
		name: 'Yancey',
		postalCodes: ['28714',
			'28740',
			'28755']
	}
];
