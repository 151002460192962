import styled from 'styled-components';

import colors from '../../../styles/colors';

export const Wrapper = styled.div`
	display: grid;
	gap: 1rem;
	padding-bottom: 0.5rem;

	.info-item {
		& > h6 {
			color: ${colors.neutralText};
			margin-bottom: 0.375em;
		}

		&--with-badge {
			& > h6 {
				margin-bottom: calc(0.375em + 0.25rem);
			}
		}

		.badge-wrapper {
			display: flex;
			flex-wrap: wrap;
			margin: -0.25rem;

			.small-badge {
				padding: 0.25rem 0.5rem;
				border-radius: 6.25rem;
				border: 1px solid ${colors.brandPrimary};
				font-size: 0.75rem;
				color: ${colors.brandPrimary};
				font-weight: 700;
				margin: 0.25rem;
			}
		}
	}
`;
