import React, { createContext, useContext, useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useList } from 'react-use';
import { Picture, Typography, EmptyComponent, Spinner } from '../../../components';
import { formatToRelativeTime } from '../../../utils/time-formatter';

import { FilterSection } from './FilterSection';
import { ResourceChat } from './ResourceChat';
import * as S from './PostedResourcesTab.styles';
import { fetchResources } from '../../../services/directory';

export const VIEW_MESSAGES_OPTIONS = [
	{ value: 'grouped-by-resource', label: 'Grouped by Resource' },
	{ value: 'individual-messages', label: 'Individual Messages' },
];

export const ResourcesContext = createContext();

export const initialState = {
	viewMessages: VIEW_MESSAGES_OPTIONS[0],
	searchInputValue: '',
	groupedHeaderInfo: null,
	selectedConversation: null,
};
export function reducer(state, action) {
	switch (action.type) {
		case 'SET_VIEW_MESSAGES':
			return {
				...state,
				viewMessages: action.payload,
			};
		case 'SET_SEARCH_INPUT_VALUE':
			return {
				...state,
				searchInputValue: action.payload,
			};
		case 'SET_GROUPED_HEADER_INFO':
			return {
				...state,
				groupedHeaderInfo: action.payload,
				searchInputValue: '',
			};
		case 'CLEAR_GROUPED_HEADER_INFO':
			return {
				...state,
				groupedHeaderInfo: null,
				searchInputValue: '',
			};
		case 'SET_SELECTED_CONVERSATION':
			return {
				...state,
				selectedConversation: action.payload,
			};
		case 'CLEAR_SELECTED_CONVERSATION':
			return {
				...state,
				selectedConversation: null,
			};
		default:
			return state;
	}
}

const IndividualItem = ({ id, business, recipient, resource, lastMessage, status }) => {
	const {
		state: { selectedConversation },
		dispatch,
	} = useContext(ResourcesContext);

	return (
		<S.ListItem key={id} onClick={() => dispatch({ type: 'SET_SELECTED_CONVERSATION', payload: { id, status, business, recipient, resource } })} className={selectedConversation != null && selectedConversation.id === id ? 'is-selected' : ''}>
			<div className="individual-card__avatar-wrapper">
				<Picture className="individual-card__avatar" src={recipient?.avatarObj?.[200]} aspectRatio="1/1" />
			</div>
			<div className="individual-card__info-wrapper">
				<Typography className="individual-card__name" tag="h5" weight="bold">
					{recipient.name}
				</Typography>
				<Typography className="individual-card__category" tag="p" variation="3">
					{lastMessage ? `${formatToRelativeTime(new Date(lastMessage.date.iso))} • ${resource.title}` : resource.title}
				</Typography>
				{lastMessage && (
					<Typography className="individual-card__message" tag="p" variation="2">
						{lastMessage.message}
					</Typography>
				)}
			</div>
		</S.ListItem>
	);
};
IndividualItem.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	business: PropTypes.object.isRequired,
	recipient: PropTypes.object.isRequired,
	lastMessage: PropTypes.object,
};

const GroupedItem = ({ id, coverImageObj, category, title, responses }) => {
	const { dispatch } = useContext(ResourcesContext);

	const handleClick = () => {
		dispatch({ type: 'SET_GROUPED_HEADER_INFO', payload: { title, responses } });
	};

	return (
		<S.GroupedListItem key={id} onClick={handleClick}>
			<div className="grouped-card__thumbnail-wrapper">
				<Picture pictureClassName="grouped-card__thumbnail" src={coverImageObj?.[200]} aspectRatio="3/2" />
			</div>
			<div className="grouped-card__info-wrapper">
				<Typography className="grouped-card__category" tag="p" variation="3">
					{category}
				</Typography>
				<Typography className="grouped-card__name" tag="h5" weight="bold">
					{title}
				</Typography>
				<div className="grouped-card__count-wrapper">
					<FontAwesomeIcon icon={['fal', 'comment']} />
					<Typography className="grouped-card__count-text" tag="p" variation="2">
						{`${responses.length} ${responses.length !== 1 ? 'Responses' : 'Response'}`}
					</Typography>
				</div>
			</div>
		</S.GroupedListItem>
	);
};
GroupedItem.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	coverImageObj: PropTypes.shape(),
	category: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	responses: PropTypes.arrayOf(PropTypes.shape({ ...IndividualItem.propTypes })).isRequired,
};

// Initialize default data holder
let resourcesArray = [];
let responsesArray = [];

export const PostedResourcesTab = ({}) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const [pageStatus, setPageStatus] = useState('idle');
	const { viewMessages, searchInputValue, selectedConversation, groupedHeaderInfo } = state;
	const [individualList, { set: setIndList, filter: filterInd }] = useList([]);
	const [groupedList, { set: setGrpList, filter: filterGrp }] = useList([]);
	const [currentSelectedGroupedList, { set: setSlctdGrp, filter: filterSelectedGrp }] = useList([]);

	const handleListFilter = (filterMethod, resetMethod) => {
		resetMethod();
		if (searchInputValue) {
			filterMethod((item) => item.searchTag.toLowerCase().includes(searchInputValue.toLowerCase()));
		}
	};

	const handleFetchData = async () => {
		setPageStatus('loading');

		// Fetch data for value
		if (viewMessages.value === 'grouped-by-resource') {
			const {
				data: { result },
			} = await fetchResources({
				filterOptions: { creator: true, hasMessages: true },
			});
			const newArray = result.resources.map((resource) => ({
				...resource,
				responses: resource.conversations.map((conversation) => ({
					...conversation,
					id: conversation.channelId,
					business: resource.business,
					resource,
				})),
			}));
			resourcesArray = newArray;
			setGrpList(newArray);
		} else {
			const {
				data: { result },
			} = await fetchResources({
				filterOptions: { creator: true, hasMessages: true },
			});
			const conversations = [].concat(
				...result.resources.map((resource) => {
					return resource.conversations.map((response) => ({
						id: response.channelId,
						searchTag: resource.searchTag,
						resource,
						recipient: response.recipient,
						business: resource.business,
						status: resource.status,
						lastMessage: response.lastMessage,
					}));
				})
			);
			responsesArray = conversations;
			setIndList(conversations);
		}
		setPageStatus('success');
	};

	const filterData = () => {
		// Update filter state
		if (!!groupedHeaderInfo) {
			handleListFilter(filterSelectedGrp, () =>
				setSlctdGrp(
					groupedHeaderInfo.responses.map((response) => {
						return {
							...response,
							resource: {
								...response.resource,
								title: groupedHeaderInfo.title,
								status: groupedHeaderInfo.status,
							},
						};
					})
				)
			);
		} else {
			if (viewMessages.value === 'grouped-by-resource') {
				handleListFilter(filterGrp, () => setGrpList(resourcesArray));
			} else {
				handleListFilter(filterInd, () => setIndList(responsesArray));
			}
		}
	};

	useEffect(async () => {
		// Fetch data
		await handleFetchData();

		// Update filter state
		filterData();
	}, [viewMessages]);

	useEffect(async () => {
		// Update filter state
		filterData();
	}, [searchInputValue, groupedHeaderInfo]);

	return (
		<ResourcesContext.Provider value={{ state, dispatch }}>
			<S.Wrapper someChatIsSelected={selectedConversation}>
				<S.ContentWrapper>
					<FilterSection />
					<S.RelativeWrapper>
						<S.ListWrapper>
							{!!groupedHeaderInfo &&
								(currentSelectedGroupedList.length > 0 ? (
									currentSelectedGroupedList.map(({ id, business, recipient, resource, lastMessage }) => <IndividualItem key={id} id={id} business={business} recipient={recipient} resource={resource} lastMessage={lastMessage} />)
								) : pageStatus === 'loading' ? (
									<Spinner />
								) : (
									<EmptyComponent title="No available conversations" message="Check back later to view available conversations." icon={['fal', 'comment']} />
								))}
							{!groupedHeaderInfo &&
								viewMessages.value === 'grouped-by-resource' &&
								(groupedList.length > 0 ? (
									groupedList.map(({ id, coverImageObj, category, title, responses }) => <GroupedItem key={id} id={id} coverImageObj={coverImageObj} category={category} title={title} responses={responses} />)
								) : pageStatus === 'loading' ? (
									<Spinner />
								) : (
									<EmptyComponent title="No available resources" message="Check back later to view available messages for resources." icon={['fal', 'comment']} />
								))}
							{viewMessages.value === 'individual-messages' &&
								(individualList.length > 0 ? (
									individualList.map(({ id, business, recipient, resource, lastMessage }) => {
										return <IndividualItem key={id} id={id} business={business} recipient={recipient} resource={resource} lastMessage={lastMessage} />;
									})
								) : pageStatus === 'loading' ? (
									<Spinner />
								) : (
									<EmptyComponent title="No available conversations" message="Check back later to view available conversations." icon={['fal', 'comment']} />
								))}
						</S.ListWrapper>
					</S.RelativeWrapper>
				</S.ContentWrapper>
				{selectedConversation && (
					<S.ChatWrapper>
						<div className="relative-wrapper">
							<ResourceChat conversation={selectedConversation} />
						</div>
					</S.ChatWrapper>
				)}
			</S.Wrapper>
		</ResourcesContext.Provider>
	);
};
