import styled from 'styled-components';

import { IconButton as ReactIconButton } from '../../../components';
import colors from '../../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

export const IconButton = styled(ReactIconButton)`
	position: absolute;
	padding: inherit;
	top: 0;
	right: 0;

	svg {
		stroke: ${colors.neutralButton};
		stroke-width: 3rem;

		path {
			fill: ${({ bookmarked }) => (bookmarked ? colors.brandPrimary : 'transparent')};
		}
	}
`;

export const CardItem = styled.div`
	cursor: pointer;
	text-decoration: none;
	color: black;
	display: flex;
	position: relative;
	justify-content: flex-start;
	border-top: 1px solid ${colors.neutralForm};
	padding: 1rem 0;

	.thumbnail {
		border: 1px solid ${colors.neutralForm};
		border-radius: 0.375rem;
		margin-right: 0.75rem;
		min-width: 6rem;
		max-width: 6rem;
	}

	.info-wrapper {
		display: flex;
		flex: 1;
		flex-direction: column;

		.category {
			color: ${colors.neutralText};
			margin-top: 0.125rem;
			margin-bottom: 0.5rem;
		}
	}

	&:first-child {
		padding-top: 0;
		border: none;
	}
	&:last-child {
		padding-bottom: 0;
	}
`;

export const CardsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	background-color: white;
	border-radius: 0.375rem;
	padding: 1rem;
	border: 1px solid ${colors.neutralForm};
	margin-top: 1rem;
`;

export const LoadMoreWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 1.75rem 0 0;
	border: none;
`;
