import api from '../api';

export const registerBusinessUser = (data) => api.post('/users/registerBusinessUser', data, { withCredentials: true });

export const updateUserOnboardingState = (data) => api.post('/core/functions/updateUserOnboardingState', data);

export const teacherOnboarding = (data) =>
	api.post('/users/teacher/onboarding', data, {
		headers: {
			'content-type': 'multipart/form-data',
		},
	});
