import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDebouncedFn } from 'beautiful-react-hooks';
import toast from 'react-hot-toast';

import { REQUEST_SORT_OPTIONS } from '../../../../../constants/general.constants';
import { fetchRequests } from '../../../../services/requests';
import { Picture, Typography, Spinner, ErrorComponent, EmptyComponent, FilterSection, Button } from '../../../../components';
import * as S from './RespondedTab.styles';

export const RespondedTab = () => {
	const [pageStatus, setPageStatus] = useState('idle');
	const [requests, setRequests] = useState([]);

	const initialPageNumber = 0;
	const [pageNumber, setPageNumber] = useState(initialPageNumber);
	const [totalPages, setTotalPages] = useState(undefined);
	const [loadingMore, setLoadingMore] = useState(false);

	const filterRef = useRef();

	const onChangeCallback = useDebouncedFn(async () => {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchRequests({ page: initialPageNumber, filterOptions: { status: 'scheduled', responded: true }, ...filterRef.current.value });
			setRequests(result.requests);
			setPageNumber(initialPageNumber);
			setTotalPages(result.totalPages);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}, 300);

	async function loadMore() {
		setLoadingMore(true);
		try {
			if (totalPages > pageNumber + 1) {
				const {
					data: { result },
				} = await fetchRequests({ page: pageNumber + 1, filterOptions: { status: 'scheduled', responded: true }, ...filterRef.current.value });
				setRequests((prev) => [...prev, ...result.requests]);
				setTotalPages(result.totalPages);
				setPageNumber((prev) => prev + 1);
			} else {
				toast.error('No more resources to load');
			}
			setLoadingMore(false);
		} catch (error) {
			setLoadingMore(false);
		}
	}

	async function initializeData() {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchRequests({ page: initialPageNumber, filterOptions: { status: 'scheduled', responded: true } });
			setRequests(result.requests);
			setTotalPages(result.totalPages);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}

	useEffect(() => {
		// Load initial data
		if (pageStatus === 'idle') {
			initializeData();
		}
	}, []);

	const renderContent = () => {
		switch (pageStatus) {
			case 'loading':
				return <Spinner />;
			case 'error':
				return <ErrorComponent />;
			case 'success':
				return requests.length > 0 ? (
					<>
						<S.CardsWrapper>
							{requests.map((request) => (
								<S.CardItem key={request.id} to={{ pathname: `/business/requests/${request.id}`, state: { backUrl: window.location.pathname, backTitle: 'Back to Requests' } }}>
									<Picture className="thumbnail" src={request.coverImageObj['200']} aspectRatio="3/2" />
									<div className="info-wrapper">
										<Typography className="category" tag="p" variation="3">
											{request.category}
										</Typography>
										<Typography tag="h5" weight="bold">
											{request.title}
										</Typography>
										<div className="info-wrapper__details-wrapper">
											<div key="type" className="detail-item">
												<div className="icon-wrapper">
													<FontAwesomeIcon icon={['fal', 'books']} />
												</div>
												<Typography tag="p" variation="2">
													{request.type === 'appointment' ? 'Appointment' : 'Information'}
												</Typography>
											</div>
											{request.group && request.group.name && (
												<div key="group" className="detail-item">
													<div className="icon-wrapper">
														<FontAwesomeIcon icon={['fal', 'users']} />
													</div>
													<Typography tag="p" variation="2">
														{request.group.name || 'No Group'}
													</Typography>
												</div>
											)}
											<div key="responses" className="detail-item">
												<div className="icon-wrapper">
													<FontAwesomeIcon icon={['fal', 'comment']} />
												</div>
												<Typography tag="p" variation="2">
													{request?.responses?.length !== 1 ? `${request?.responses?.length} Responses` : '1 Response'}
												</Typography>
											</div>
											{request.type === 'appointment' && (
												<div key="dates" className="detail-item">
													<div className="icon-wrapper">
														<FontAwesomeIcon icon={['fal', 'calendar']} />
													</div>
													<Typography tag="p" variation="2">
														{request.availabilityDateRange}
													</Typography>
												</div>
											)}
										</div>
									</div>
									{request?.responses?.filter((response) => response.isNew === true).length > 0 && (
										<div className="badges-wrapper">
											<div className="badge-item">
												<Typography tag="p" variation="3" weight="bold">
													{request?.responses?.filter((response) => response.isNew === true).length !== 1 ? `${request?.responses?.filter((response) => response.isNew === true).length} New Responses` : '1 New Response'}
												</Typography>
											</div>
										</div>
									)}
								</S.CardItem>
							))}
						</S.CardsWrapper>
						{totalPages > pageNumber + 1 && (
							<S.LoadMoreWrapper>
								<Button variant="outline" variation="secondary" type="button" onClick={loadMore}>
									<Typography variation="button-medium" weight="bold">
										{loadingMore ? 'Loading...' : 'Load More'}
									</Typography>
								</Button>
							</S.LoadMoreWrapper>
						)}
					</>
				) : (
					<EmptyComponent title="No scheduled requests" message="Check back later to view your scheduled requests." icon={['fal', 'file-plus']} />
				);

			default:
				return null;
		}
	};

	return (
		<S.Wrapper>
			<FilterSection
				ref={filterRef}
				onChangeCallback={onChangeCallback}
				initialState={{
					searchText: '',
					sortOption: REQUEST_SORT_OPTIONS[3],
					showAdvancedFilters: false,
					activeFilters: [],
					distance: 0,
					counties: [],
					locationTypes: [],
					categories: [],
					careerClusters: [],
					ageGroups: [],
					employabilitySkills: [],
					curriculumStandards: [],
				}}
				sortOptions={REQUEST_SORT_OPTIONS}
				filterOptions={['distance', 'counties', 'locationTypes', 'categories', 'careerClusters', 'ageGroups']}
			/>
			{renderContent()}
		</S.Wrapper>
	);
};

RespondedTab.propTypes = {};
