import React from 'react';
import PropTypes from 'prop-types';

import { Card, Typography } from '../../../../components';
import * as S from './LearningConnectionsCard.styles';

export const LearningConnectionsCard = ({ className, resource }) => {
	return (
		<Card title="Learning Connections" className={className} transparentHeaderBorder>
			<S.Wrapper>
				<S.ListItem>
					<Typography tag="h6" weight="semibold" className="item-title">
						Employability Skills
					</Typography>
					{resource.employabilitySkills.map((item) => (
						<Typography key={item.value}>
							{item.value} - {item.endorsements !== 1 ? `${item.endorsements} Endorsements` : '1 Endorsement'}
						</Typography>
					))}
				</S.ListItem>
				<S.ListItem>
					<Typography tag="h6" weight="semibold" className="item-title">
						Curriculum Standards
					</Typography>
					{resource.curriculumStandards.map((item) => (
						<Typography key={item.value}>
							{item.value} - {item.endorsements !== 1 ? `${item.endorsements} Endorsements` : '1 Endorsement'}
						</Typography>
					))}
				</S.ListItem>
			</S.Wrapper>
		</Card>
	);
};

LearningConnectionsCard.propTypes = {
	className: PropTypes.string,
	resource: PropTypes.object,
};
