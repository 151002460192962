import api from '../api';

export const createUpdate = (data) => api.post('/businesses/createUpdate', data);

export const fetchEmployee = (data) => api.post('/core/functions/fetchEmployee', data);

export const fetchEmployees = (data) => api.post('/core/functions/fetchEmployees', data);

export const createUpdateEmployee = (data) => api.post('/core/functions/createUpdateEmployee', data);

export const removeEmployee = (data) => api.post('/core/functions/removeEmployee', data);

export const fetchBusinesses = (data) => api.post('/core/functions/fetchBusinesses', data);

export const approveBusiness = (data) => api.post('/core/functions/approveBusiness', data);

export const declineBusiness = (data) => api.post('/core/functions/declineBusiness', data);

export const removeBusiness = (data) => api.post('/core/functions/removeBusiness', data);

export const exportBusinesses = (data) => api.post('/core/functions/exportBusinesses', data);
