import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Badge, Button, Select, TextInput, Typography } from '../../../../components';
import { RequestsContext, TABS_OPTIONS, VIEW_MESSAGES_OPTIONS } from '../PostedRequestsTab';
import * as S from './FilterSection.styles';

const BADGE_STATUS = {
	pending: {
		label: 'Pending',
		color: 'primary',
	},
	scheduled: {
		label: 'Scheduled',
		color: 'neutral',
	},
	draft: {
		label: 'Draft',
		color: 'neutral',
	},
	complete: {
		label: 'Completed',
		color: 'success',
	},
	incomplete: {
		label: 'Incomplete',
		color: 'danger',
	},
	archived: {
		label: 'Archived',
		color: 'neutral',
	},
};

export const FilterSection = () => {
	const { state, dispatch } = useContext(RequestsContext);
	const { viewMessages, selectedTab, searchInputValue, groupedHeaderInfo } = state;

	return (
		<S.Wrapper>
			<div className="filter-wrapper">
				{!!groupedHeaderInfo ? (
					<div className="filter-wrapper__grouped-header-wrapper">
						<Button className="filter-wrapper__grouped-header-wrapper__back-button" variant="text" size="small" onClick={() => dispatch({ type: 'CLEAR_GROUPED_HEADER_INFO' })}>
							<FontAwesomeIcon icon={['fal', 'arrow-left']} />
							<Typography className="button-label" variation="button-small" weight="bold">
								Back to Requests
							</Typography>
						</Button>
						<div className="filter-wrapper__grouped-header-wrapper__inner-wrapper">
							<div className="filter-wrapper__grouped-header-wrapper__flex-column">
								<Typography tag="h3" weight="extrablack">
									{groupedHeaderInfo.title}
								</Typography>
								<div className="filter-wrapper__grouped-header-wrapper__count-wrapper">
									<FontAwesomeIcon icon={['fal', 'comment']} />
									<Typography className="filter-wrapper__grouped-header-wrapper__count-text" tag="p" variation="2">
										{`${groupedHeaderInfo.responses?.length} ${groupedHeaderInfo.responses?.length !== 1 ? 'Responses' : 'Response'}`}
									</Typography>
								</div>
							</div>
							<div className="filter-wrapper__grouped-header-wrapper__badge-wrapper">
								<Badge small="small" type={BADGE_STATUS[groupedHeaderInfo.status].color} variant="outlined">
									{BADGE_STATUS[groupedHeaderInfo.status].label}
								</Badge>
							</div>
						</div>
					</div>
				) : (
					<div className="filter-wrapper__view-messages">
						<Typography className="field-label" tag="p" variation="2" weight="bold">
							View Messages:
						</Typography>
						<Select containerClassName="select-field" size="small" defaultValue={viewMessages} onChange={(option) => dispatch({ type: 'SET_VIEW_MESSAGES', payload: option })} options={VIEW_MESSAGES_OPTIONS} />
					</div>
				)}
				<div className="filter-wrapper__badge-wrapper">
					{TABS_OPTIONS.map((option) => (
						<button key={option.value} className={`badge-button${selectedTab.value === option.value ? ' selected' : ''}`} onClick={() => dispatch({ type: 'SET_SELECTED_TAB', payload: option })}>
							<Typography tag="h6">{option.label}</Typography>
						</button>
					))}
				</div>
				<div className="filter-wrapper__search-input-wrapper">
					<TextInput value={searchInputValue} size="small" placeholder="Search" icon={['fal', 'search']} onChange={(e) => dispatch({ type: 'SET_SEARCH_INPUT_VALUE', payload: e.target.value })} />
				</div>
			</div>
		</S.Wrapper>
	);
};
