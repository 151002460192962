import React, { forwardRef, useRef, useState, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { updateUser } from '../../../app/slices/user/userSlice';
import { updateUserProfile } from '../../../services/users';
import { uploadProfilePicture } from '../../../services/media';
import { errorHandler } from '../../../services/authService';
import { Avatar, Button, Card, IconButton, TextInput, Typography } from '../../../components';
import { required } from '../../../utils/form-default-errors';
import * as S from './ProfileCard.styles';

const EditProfileModal = forwardRef((_props, ref) => {
	const user = useSelector((state) => state.user.value);
	const dispatch = useDispatch();

	const [isOpen, setIsOpen] = useState(false);
	const [avatar, setAvatar] = useState(null);
	const [avatarFile, setAvatarFile] = useState(null);

	const {
		handleSubmit,
		setValue,
		register,
		formState: { errors, isSubmitting },
	} = useForm();

	const handleOpenModal = () => {
		setValue('firstName', user?.firstName);
		setValue('lastName', user?.lastName);
		setValue('role', user?.role);
		setAvatar(user?.profileImageObj?.[200]);
		setAvatarFile(null);
		setIsOpen(true);
	};

	const handleCloseModal = () => {
		setIsOpen(false);
	};

	async function asyncCaller(data) {
		let profileImageObj;

		if (avatarFile) {
			const formData = new FormData();
			formData.append('file', avatarFile);

			const { data: uploadResult } = await uploadProfilePicture(formData);

			profileImageObj = uploadResult;
		} else {
			profileImageObj = user?.profileImageObj;
		}

		const {
			data: { result },
		} = await updateUserProfile({ ...data, profileImageObj });

		dispatch(updateUser(result?.user));
		handleCloseModal();
	}

	const onSubmit = (e) => {
		handleSubmit(asyncCaller)(e).catch(errorHandler);
	};

	useImperativeHandle(
		ref,
		() => ({
			open: handleOpenModal,
			close: handleCloseModal,
		}),
		[handleOpenModal, handleCloseModal]
	);

	return (
		<ReactModal className="profile-card-modal" ref={ref} closeTimeoutMS={200} isOpen={isOpen} onRequestClose={handleCloseModal}>
			<S.Form onSubmit={onSubmit}>
				<IconButton className="close-button" type="button" icon={['fal', 'times']} onClick={handleCloseModal} />
				<div className="modal-content">
					<header className="modal-content__header">
						<Typography tag="h2" weight="extrablack" center>
							Edit Profile
						</Typography>
						<Typography tag="p" center>
							Please update your profile information here.
						</Typography>
					</header>

					<section className="modal-content__section">
						<Avatar className="avatar-element" avatar={avatar} setAvatar={setAvatar} file={avatarFile} setFile={setAvatarFile} />

						<TextInput
							label="First Name"
							placeholder="First Name"
							error={errors.firstName}
							{...register('firstName', {
								required: required('First Name'),
							})}
						/>
						<TextInput
							label="Last Name"
							placeholder="Last Name"
							error={errors.lastName}
							{...register('lastName', {
								required: required('Last Name'),
							})}
						/>
						<TextInput label="Role" placeholder="Role" value={user?.role} className="capitalize" readOnly />
					</section>

					<div className="modal-footer">
						<Button>
							<Typography variation="button-medium" weight="bold">
								{isSubmitting ? 'Loading' : 'Save'}
							</Typography>
						</Button>
					</div>
				</div>
			</S.Form>
		</ReactModal>
	);
});

EditProfileModal.displayName = 'EditProfileModal';

const ProfileCard = ({ className }) => {
	const user = useSelector((state) => state.user.value);

	const modalRef = useRef(null);

	const handleCardButtonClick = () => {
		modalRef.current.open();
	};

	return (
		<Card className={className} title="Profile" transparentHeaderBorder={true} actions={[{ id: 1, label: 'Edit', size: 'small', variant: 'outline', icon: { source: ['fal', 'edit'] }, onClick: handleCardButtonClick }]}>
			<S.Wrapper>
				<div className="avatar-wrapper">
					<Avatar avatar={user?.profileImageObj?.[200]} />
				</div>
				<div className="info-wrapper">
					<div className="element-info">
						<Typography className="element-info__title" tag="h6" weight="semibold">
							First Name
						</Typography>
						<Typography className="element-info__text" tag="p">
							{user?.firstName}
						</Typography>
					</div>
					<div className="element-info">
						<Typography className="element-info__title" tag="h6" weight="semibold">
							Last Name
						</Typography>
						<Typography className="element-info__text" tag="p">
							{user?.lastName}
						</Typography>
					</div>
					<div className="element-info">
						<Typography className="element-info__title" tag="h6" weight="semibold">
							Role
						</Typography>
						<Typography className="element-info__text capitalized" tag="p">
							{user?.role}
						</Typography>
					</div>
				</div>
			</S.Wrapper>
			<EditProfileModal ref={modalRef} />
		</Card>
	);
};

ProfileCard.propTypes = {
	className: PropTypes.string,
};

export default ProfileCard;
