import styled from 'styled-components';

export const CardWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: center;
	gap: 1rem;

	@media screen and (min-width: 600px) {
		flex-direction: row;

		.metric-card {
			flex: 1;
		}
	}
`;
