import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';

import { Spinner, ErrorComponent, TeacherNavigation, PageHeader, Tab, MessageDialog } from '../../components';

import { fetchGroup, archiveUnarchiveGroup } from '../../services/groups';
import { OverviewTab } from './OverviewTab';
import { BookingsTab } from './BookingsTab';
import { RequestsTab } from './RequestsTab';
import * as S from './TeacherGroupDetail.styles';
import { META_TITLE } from '../../../constants/general.constants';

const TeacherGroupDetail = () => {
	const { id } = useParams();
	const [archiveIsOpen, setArchiveIsOpen] = useState(false);
	const location = useLocation();
	const history = useHistory();
	const [pageStatus, setPageStatus] = useState('idle');
	const [group, setGroup] = useState(null);
	const tabsConfig = (groupObj) => [
		{
			id: 1,
			label: 'Overview',
			component: <OverviewTab group={groupObj} />,
		},
		{
			id: 2,
			label: 'Bookings',
			component: <BookingsTab group={groupObj} />,
		},
		{
			id: 3,
			label: 'Requests',
			component: <RequestsTab group={groupObj} />,
		},
	];
	const [tabs, setTabs] = useState(tabsConfig({}));

	const back = location.state?.back || '/teacher/groups';

	function handleOpenArchiveModal() {
		setArchiveIsOpen(true);
	}

	function handleCloseArchiveModal() {
		setArchiveIsOpen(false);
	}

	const handleArchiveUnarchive = async () => {
		setArchiveIsOpen(false);
		try {
			const {
				data: { result },
			} = await archiveUnarchiveGroup({ groupId: id });
			setGroup(result.group);
		} catch (error) {}
	};

	async function initializeData() {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchGroup({ groupId: id });
			setGroup(result.group);
			setTabs(tabsConfig(result.group));
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}

	useEffect(() => {
		// Set document title
		document.title = `Group Detail | Dashboard | ${META_TITLE}`;

		// Load initial data
		if (pageStatus === 'idle') {
			initializeData();
		}
	}, [pageStatus]);

	const renderContent = () => {
		switch (pageStatus) {
			case 'loading':
				return <Spinner />;
			case 'error':
				return <ErrorComponent />;
			case 'success':
				return (
					<>
						<PageHeader
							title={group.name}
							action={[
								group.isActive === true
									? {
											id: 1,
											onClick: () => {
												handleOpenArchiveModal();
											},
											label: 'Archive',
											icon: ['fal', 'archive'],
									  }
									: {
											id: 1,
											onClick: () => {
												handleOpenArchiveModal();
											},
											label: 'Unarchive',
											icon: ['fal', 'inbox-out'],
									  },
							]}
							back={{
								onClick: () => {
									history.push(back);
								},
								label: 'Back to Groups',
							}}
						/>
						<S.Wrapper>
							<Tab tabs={tabs} setTabs={setTabs} />
						</S.Wrapper>
						<MessageDialog
							isOpen={archiveIsOpen}
							onRequestClose={handleCloseArchiveModal}
							title={group.isActive === true ? 'Archive Group' : 'Unarchive Group'}
							subtitle={group.isActive === true ? 'Are you sure you want to archive this group? You will be able to unarchive it later.' : 'Are you sure you want to unarchive this group? It will be available to assign to bookings.'}
							icon={['fal', 'users']}
							actions={[
								{ id: 'back', label: 'Cancel', variant: 'outline', variation: 'default' },
								{ id: 'archiveUnarchive', label: group.isActive === true ? 'Archive Group' : 'Unarchive Group', variant: 'solid', variation: 'default', onClick: handleArchiveUnarchive },
							]}
						/>
					</>
				);
			default:
				return null;
		}
	};

	return <TeacherNavigation>{renderContent()}</TeacherNavigation>;
};

export default TeacherGroupDetail;
