import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import { format } from 'date-fns';

import { errorHandler } from '../../../services/authService';
import { createUpdateBooking } from '../../../services/bookings';
import { Button, IconButton, Modal, Stepper, Typography } from '../../../components';
import { StepOne } from './StepOne';
import { StepTwo } from './StepTwo';
import { StepThree } from './StepThree';
import { StepFour } from './StepFour';
import { StepFive } from './StepFive';
import * as S from './ModalBookingResource.styles';
import { ROLES } from '../../../../constants/general.constants';

export const ModalBookingResource = ({ isOpen, onRequestClose, onComplete, resourceId, resourceLocationTypes = [], worksiteLocations = [], role = null }) => {
	const methods = useForm();

	const [currentStep, setCurrentStep] = useState(0);

	const STEPS =
		role === ROLES.BUSINESS
			? [
					{ id: 1, label: 'Date' },
					{ id: 2, label: 'Location' },
					{ id: 3, label: 'Note' },
					{ id: 4, label: 'Review' },
			  ]
			: [
					{ id: 1, label: 'Date' },
					{ id: 2, label: 'Location' },
					{ id: 3, label: 'Group' },
					{ id: 4, label: 'Note' },
					{ id: 5, label: 'Review' },
			  ];

	const handleCloseModal = () => {
		onRequestClose();
		setCurrentStep(0);
		methods.reset();
	};

	const onBack = () => {
		if (currentStep > 0) {
			setTimeout(() => {
				setCurrentStep((prev) => prev - 1);
			}, 100);
		}
	};

	const onNext = () => {
		setTimeout(() => {
			setCurrentStep((prev) => prev + 1);
		}, 100);
	};

	async function asyncCaller(data) {
		// Create availability dates
		const availabilityDates = data.availabilityDates.length === 1 ? [format(data.availabilityDates[0], 'MM-dd-yyyy')] : [format(data.availabilityDates[0], 'MM-dd-yyyy'), format(data.availabilityDates[1], 'MM-dd-yyyy')];

		// Create booking
		const {
			data: { result },
		} = await createUpdateBooking({ resourceId, ...data, availabilityDates });

		// Complete flow
		handleCloseModal();
		onComplete();
	}

	const onSubmit = (e) => {
		if (currentStep === STEPS.length - 1) {
			methods.handleSubmit(asyncCaller)(e).catch(errorHandler);
		} else {
			methods.handleSubmit(onNext)(e);
		}
	};

	return (
		<Modal isOpen={isOpen} onRequestClose={handleCloseModal} fullHeight>
			<FormProvider {...methods}>
				<S.ModalFormWrapper onSubmit={onSubmit}>
					<IconButton className="close-button" type="button" icon={['fal', 'times']} onClick={handleCloseModal} />

					<Stepper activeStep={currentStep} steps={STEPS} maxWidth={25} />

					<div className="modal-content">
						{role === ROLES.BUSINESS ? (
							<>
								{currentStep === 0 && <StepOne />}
								{currentStep === 1 && <StepTwo worksiteLocations={worksiteLocations} resourceLocationTypes={resourceLocationTypes} />}
								{currentStep === 2 && <StepFour />}
								{currentStep === 3 && <StepFive worksiteLocations={worksiteLocations} />}
							</>
						) : (
							<>
								{currentStep === 0 && <StepOne />}
								{currentStep === 1 && <StepTwo worksiteLocations={worksiteLocations} resourceLocationTypes={resourceLocationTypes} />}
								{currentStep === 2 && <StepThree />}
								{currentStep === 3 && <StepFour />}
								{currentStep === 4 && <StepFive worksiteLocations={worksiteLocations} />}
							</>
						)}
					</div>

					<div className="modal-footer">
						{currentStep > 0 && (
							<>
								<Button variant="outline" variation="secondary" type="button" onClick={onBack}>
									<Typography variation="button-medium" weight="bold">
										Back
									</Typography>
								</Button>
								<div className="flex-1" />
							</>
						)}
						<Button>
							<Typography variation="button-medium" weight="bold">
								{currentStep === STEPS.length - 1 ? (methods.formState.isSubmitting ? 'Loading' : 'Book') : 'Next'}
							</Typography>
						</Button>
					</div>
				</S.ModalFormWrapper>
			</FormProvider>
		</Modal>
	);
};
ModalBookingResource.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	role: PropTypes.string,
	onRequestClose: PropTypes.func.isRequired,
	onComplete: PropTypes.func,
	resourceId: PropTypes.string,
	resourceLocationTypes: PropTypes.arrayOf(PropTypes.string),
	worksiteLocations: PropTypes.arrayOf(
		PropTypes.shape({
			location: PropTypes.shape({
				id: PropTypes.string,
				name: PropTypes.string,
				searchTag: PropTypes.string,
			}),
			notes: PropTypes.string,
		})
	),
};
