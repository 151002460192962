import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ROLES } from '../../../constants/general.constants';

export const PublicAuthRoute = ({ component: Component, path, exact = false }) => {
	const user = useSelector((state) => state.user.value);
	const status = useSelector((state) => state.user.status);
	const [shouldRedirect, setShouldRedirect] = useState(true);

	useEffect(() => {
		setShouldRedirect(false);
	}, []);

	const render = (props) => {
		let dashboardRoute = '/dashboard';
		if (user && shouldRedirect) {
			switch (user.role) {
				case ROLES.TEACHER:
					dashboardRoute = '/teacher/dashboard';
					break;
				case ROLES.BUSINESS:
					dashboardRoute = '/business/dashboard';
					break;
				case ROLES.ADMIN:
					dashboardRoute = '/admin/dashboard';
					break;
				case ROLES.SUPER:
					dashboardRoute = '/admin/dashboard';
					break;
			}
			return (
				<Redirect
					to={{
						pathname: dashboardRoute,
					}}
				/>
			);
		} else {
			return <Component {...props} />;
		}
	};

	if (status === 'idle') {
		return null;
	}

	return <Route exact={exact} path={path} render={render} />;
};

PublicAuthRoute.propTypes = {
	component: PropTypes.func.isRequired,
	path: PropTypes.string,
	exact: PropTypes.bool,
};
