import styled from 'styled-components';
import { Link } from 'react-router-dom';

import colors from '../../../../styles/colors';

export const Wrapper = styled.div``;

export const CardItem = styled(Link)`
	text-decoration: none;
	color: black;
	display: flex;
	position: relative;
	justify-content: flex-start;
	align-items: center;
	border-top: 1px solid ${colors.neutralForm};
	padding: 0.5rem 0;

	.thumbnail {
		border: 1px solid ${colors.neutralForm};
		border-radius: 0.375rem;
		margin-right: 0.75rem;
		min-width: 6rem;
		max-width: 6rem;
	}

	.badges-wrapper {
		position: absolute;
		padding: inherit;
		top: 0;
		right: 0;

		.badge-item {
			border: 1px solid ${colors.brandPrimary};
			margin-right: 0.5rem;
			padding: 0.125rem 0.5rem;
			border-radius: 9999px;
			color: ${colors.brandPrimary};

			&:last-child {
				margin-right: 0;
			}

			svg {
				margin-right: 3px;
			}
		}
	}

	.info-wrapper {
		display: flex;
		flex: 1;
		flex-direction: column;

		.category {
			color: ${colors.neutralText};
			margin-bottom: 0.25rem;
		}

		&__details-wrapper {
			display: grid;
			margin-top: 0.5rem;
			gap: 0.5rem;
			grid-template-columns: repeat(1, 1fr);

			.detail-item {
				display: flex;
				color: ${colors.neutralButton};

				.icon-wrapper {
					display: flex;
					justify-content: center;
					width: 1.5rem;
				}

				& > * + * {
					margin-left: 0.5rem;
				}
			}

			@media screen and (min-width: 426px) {
				grid-template-columns: repeat(2, 1fr);
			}

			@media screen and (min-width: 900px) {
				grid-template-columns: repeat(3, 1fr);
			}

			@media screen and (min-width: 992px) {
				grid-template-columns: repeat(4, 1fr);
			}
		}
	}

	&:first-child {
		padding-top: 0;
		border: none;
	}
	&:last-child {
		padding-bottom: 0;
	}
`;

export const CardsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	background-color: white;
	border-radius: 0.375rem;
	padding: 0.75rem 1rem;
	border: 1px solid ${colors.neutralForm};
	margin-top: 1rem;
`;
