import styled from 'styled-components';

import colors from '../../../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	width: 100%;

	.availability-info {
		width: 100%;
		flex: 0 0 100%;
		display: grid;
		grid-template-columns: 1fr;
		gap: 0.75rem;
		grid-template-areas:
			'a1'
			'a2';
		padding-bottom: 0.8rem;

		&__title {
			color: ${colors.neutralText};
			margin-bottom: 0.25rem;
		}
	}

	@media screen and (min-width: 992px) {
		flex-direction: row;

		.availability-picture {
			min-width: 16.875rem;
			max-width: 16.875rem;
		}

		.availability-info {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-template-areas: 'a1 a2';
		}
	}
`;
