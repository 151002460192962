import React from 'react';
import PropTypes from 'prop-types';

import { Card, Typography } from '../../../../components';
import * as S from './CancellationCard.styles';

export const CancellationCard = ({ request, role }) => {
	return (
		<Card title="Cancellation" transparentHeaderBorder={true}>
			<S.Wrapper>
				<div className="grid-line">
					<div className="grid-line__info-item">
						<Typography tag="h6" weight="semibold">
							Cancelled By
						</Typography>
						<Typography tag="p">{`${request.cancelUser?.firstName} ${request.cancelUser?.lastName}`}</Typography>
					</div>
					<div className="grid-line__info-item">
						<Typography tag="h6" weight="semibold">
							Reason
						</Typography>
						<Typography tag="p">{request.cancelReason}</Typography>
					</div>
					<div className="grid-line__info-item">
						<Typography tag="h6" weight="semibold">
							Message
						</Typography>
						<Typography tag="p">{request.cancelNotes}</Typography>
					</div>
				</div>
			</S.Wrapper>
		</Card>
	);
};

CancellationCard.propTypes = {
	request: PropTypes.shape(),
	role: PropTypes.string,
};
