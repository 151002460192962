import styled, { css } from 'styled-components';
import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: grid;
	gap: 1.25rem;
	grid-template-columns: 1fr;
	padding: 1.25rem 1rem 2.5rem;

	.dashboard-banners-wrapper {
		display: grid;
		gap: 1.25rem;
		grid-template-columns: 1fr;

		@media screen and (min-width: 992px) {
			grid-template-columns: repeat(2, 1fr);
		}
	}

	.a1 {
		grid-area: a1;
	}
	.a2 {
		grid-area: a2;
	}
	.a3 {
		grid-area: a3;
	}
	.a4 {
		grid-area: a4;
	}

	grid-template-areas:
		'a1'
		'a2'
		'a3'
		'a4';

	@media screen and (min-width: 992px) {
		grid-template-columns: repeat(5, 1fr);
		grid-template-areas:
			'a1 a1 a1 a1 a1'
			'a2 a2 a2 a2 a2'
			'a3 a3 a3 a4 a4';
	}
`;

export const ActiveBanner = styled.div`
	display: flex;
	position: relative;
	align-items: center;
	background-color: white;
	border-bottom: 1px solid ${colors.neutralForm};
	padding: 1.5rem 2rem;

	& > svg {
		color: ${colors.brandPrimary};
	}

	.info-wrapper {
		flex: 1;
		margin-left: 1.5rem;
	}

	.floating-button {
		position: absolute;
		right: 1rem;
		top: 0.75rem;
	}
`;

export const DashboardBanner = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	background-color: white;
	border-radius: 0.375rem;
	border: 1px solid ${colors.neutralForm};
	padding: 1.5rem 2rem;

	& > h3 {
		margin-bottom: 0.5rem;
	}

	& > h5 {
		margin-bottom: 2rem;
	}

	.floating-button {
		position: absolute;
		right: 1rem;
		top: 0.75rem;
	}
`;

export const EmptyBusinessCard = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	${({ paddingTop = 2.625, paddingBottom = 3.375 }) => css`
		padding-top: ${paddingTop}rem;
		padding-bottom: ${paddingBottom}rem;
	`}

	.icon-box {
		margin-bottom: 1.25rem;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 10rem;
		height: 10rem;
		border-radius: 9999px;
		background-color: ${colors.neutralBackground};
		border: 1px solid ${colors.neutralForm};
		color: ${colors.neutralButton};
	}

	& > h4 {
		color: ${colors.neutralButton};
		margin-bottom: 0.5rem;
	}
	& > p {
		color: ${colors.neutralButton};
	}
`;
