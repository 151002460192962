import React, { useRef, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AdminCompanyDetailContext } from '../../AdminCompanyDetail';
import { Card, Typography, EditBusinessLocationsModal } from '../../../../components';
import * as S from './LocationsCard.styles';

export const LocationsCard = () => {
	const {
		state: { company },
		dispatch,
	} = useContext(AdminCompanyDetailContext);

	const editModalRef = useRef(null);

	const handleOpenModal = () => editModalRef?.current?.open();

	return (
		<Card
			title="Locations"
			transparentHeaderBorder
			actions={[
				{ id: 1, label: 'Edit', size: 'small', variant: 'outline', icon: { source: ['fal', 'edit'] }, onClick: handleOpenModal },
				{ id: 2, label: 'Add Location', size: 'small', variant: 'solid', icon: { source: ['fal', 'plus'] }, onClick: handleOpenModal },
			]}
		>
			<S.Wrapper>
				{company?.locations?.map((location) => (
					<S.LocationListItem key={location?.id} to={`/admin/companies/${company.id}/locations/${location?.id}`}>
						<div className="icon-wrapper">
							<FontAwesomeIcon icon={['fal', 'building']} />
						</div>
						<div className="info-wrapper">
							<Typography tag="h5" weight="bold">
								{location?.name}
							</Typography>
							<Typography tag="p" variation="2">
								{`${location?.address?.addressLine1}, ${location?.address?.city}, ${location?.address?.state} ${location?.address?.postalCode}`}
							</Typography>
						</div>
					</S.LocationListItem>
				))}
			</S.Wrapper>
			<EditBusinessLocationsModal ref={editModalRef} company={company} dispatch={dispatch} />
		</Card>
	);
};
