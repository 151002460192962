import React, { useState } from 'react';

import { Tab } from '../../../components';
import { RequestsTab } from './RequestsTab';
import { ResourcesTab } from './ResourcesTab';
import { FilesTab } from './FilesTab';
import * as S from './TemplatesTab.styles';

export const TemplatesTab = ({}) => {
	const [tabs, setTabs] = useState([
		{
			id: 1,
			label: 'Requests',
			component: <RequestsTab />,
		},
		{
			id: 2,
			label: 'Resources',
			component: <ResourcesTab />,
		},
		{
			id: 3,
			label: 'Files',
			component: <FilesTab />,
		},
	]);

	return (
		<S.Wrapper>
			<Tab tabs={tabs} setTabs={setTabs} transparent variation="pill" />
		</S.Wrapper>
	);
};
