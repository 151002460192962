import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDebouncedFn } from 'beautiful-react-hooks';
import toast from 'react-hot-toast';

import { LOCATION_TYPES, BOOKING_SORT_OPTIONS } from '../../../../constants/general.constants';
import { fetchBookings } from '../../../services/bookings';
import { Picture, Typography, Spinner, ErrorComponent, EmptyComponent, FilterSection, Button } from '../../../components';
import * as S from './ScheduledTab.styles';

export const ScheduledTab = () => {
	const [pageStatus, setPageStatus] = useState('idle');
	const [bookings, setBookings] = useState([]);

	const initialPageNumber = 0;
	const [pageNumber, setPageNumber] = useState(initialPageNumber);
	const [totalPages, setTotalPages] = useState(undefined);
	const [loadingMore, setLoadingMore] = useState(false);

	const filterRef = useRef();

	const onChangeCallback = useDebouncedFn(async () => {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchBookings({ page: initialPageNumber, filterOptions: { status: 'scheduled', inbound: false, outbound: true }, ...filterRef.current.value });
			setBookings(result.bookings);
			setPageNumber(initialPageNumber);
			setTotalPages(result.totalPages);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}, 300);

	async function loadMore() {
		setLoadingMore(true);
		try {
			if (totalPages > pageNumber + 1) {
				const {
					data: { result },
				} = await fetchBookings({ page: pageNumber + 1, filterOptions: { status: 'scheduled', inbound: false, outbound: true }, ...filterRef.current.value });
				setBookings((prev) => [...prev, ...result.bookings]);
				setTotalPages(result.totalPages);
				setPageNumber((prev) => prev + 1);
			} else {
				toast.error('No more resources to load');
			}
			setLoadingMore(false);
		} catch (error) {
			setLoadingMore(false);
		}
	}

	async function initializeData() {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchBookings({ page: initialPageNumber, filterOptions: { status: 'scheduled', inbound: false, outbound: true } });
			setBookings(result.bookings);
			setTotalPages(result.totalPages);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}

	useEffect(() => {
		// Load initial data
		if (pageStatus === 'idle') {
			initializeData();
		}
	}, []);

	const renderContent = () => {
		switch (pageStatus) {
			case 'loading':
				return <Spinner />;
			case 'error':
				return <ErrorComponent />;
			case 'success':
				return bookings.length > 0 ? (
					<>
						<S.CardsWrapper>
							{bookings.map((booking) => (
								<S.CardItem key={booking.id} to={{ pathname: `/admin/bookings/${booking.id}`, state: { backUrl: window.location.pathname, backTitle: 'Back to Bookings' } }}>
									<Picture className="thumbnail" src={booking.resource.coverImageObj['200']} aspectRatio="3/2" />
									<div className="info-wrapper">
										<Typography className="category" tag="p" variation="3">
											{booking.resource.category || 'Event'}
										</Typography>
										<Typography tag="h5" weight="bold">
											{booking.resource.title}
										</Typography>
										<div className="info-wrapper__details-wrapper">
											<div key="company" className="detail-item">
												<div className="icon-wrapper">
													<FontAwesomeIcon icon={['fal', 'building']} />
												</div>
												<Typography tag="p" variation="2">
													{booking.business.name}
												</Typography>
											</div>
											<div key="group" className="detail-item">
												<div className="icon-wrapper">
													<FontAwesomeIcon icon={['fal', 'users']} />
												</div>
												<Typography tag="p" variation="2">
													{booking.group.name || 'No Group'}
												</Typography>
											</div>
											<div key="location" className="detail-item">
												<div className="icon-wrapper">
													<FontAwesomeIcon icon={['fal', 'map-marker']} />
												</div>
												<Typography tag="p" variation="2">
													{booking.locationTypes.map((type) => LOCATION_TYPES.find((item) => item.id === type).name).join(', ')}
												</Typography>
											</div>
											<div key="date" className="detail-item">
												<div className="icon-wrapper">
													<FontAwesomeIcon icon={['fal', 'calendar']} />
												</div>
												<Typography tag="p" variation="2">
													{booking.scheduleDateRange || booking.availabilityDateRange}
												</Typography>
											</div>
										</div>
									</div>
								</S.CardItem>
							))}
						</S.CardsWrapper>
						{totalPages > pageNumber + 1 && (
							<S.LoadMoreWrapper>
								<Button variant="outline" variation="secondary" type="button" onClick={loadMore}>
									<Typography variation="button-medium" weight="bold">
										{loadingMore ? 'Loading...' : 'Load More'}
									</Typography>
								</Button>
							</S.LoadMoreWrapper>
						)}
					</>
				) : (
					<EmptyComponent title="No scheduled bookings" message="Check back later to view your scheduled bookings." icon={['fal', 'envelope']} />
				);

			default:
				return null;
		}
	};

	return (
		<S.Wrapper>
			<FilterSection
				ref={filterRef}
				onChangeCallback={onChangeCallback}
				initialState={{
					searchText: '',
					sortOption: BOOKING_SORT_OPTIONS[3],
					showAdvancedFilters: false,
					activeFilters: [],
					distance: 0,
					counties: [],
					locationTypes: [],
					categories: [],
					careerClusters: [],
					ageGroups: [],
					employabilitySkills: [],
					curriculumStandards: [],
				}}
				sortOptions={BOOKING_SORT_OPTIONS}
			/>
			{renderContent()}
		</S.Wrapper>
	);
};

ScheduledTab.propTypes = {};
