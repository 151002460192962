import React, { useState, useEffect } from 'react';
import { Menu } from '@headlessui/react';
import { useSelector } from 'react-redux';

import { Button } from '../../../Button';
import { Typography } from '../../../Typography';
import * as S from './Notifications.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatTemplatedMessage } from '../../../../utils/content-formatter';
import { formatToRelativeTime } from '../../../../utils/time-formatter';
import { EmptyComponent } from '../../../EmptyComponent';
import { Spinner } from '../../../Spinner';
import { ErrorComponent } from '../../../ErrorComponent';
import { fetchNotifications, markNotificationsRead } from '../../../../services/notifications';
import { SOURCES } from '../../../../../constants/general.constants';

const LINK_BY_SOURCE = {
	[SOURCES.PLATFORM]: '/dashboard',
	[SOURCES.RESOURCE]: '/dashboard',
	[SOURCES.BUSINESS]: '/dashboard',
	[SOURCES.BUSINESS_LOCATION]: '/dashboard',
	[SOURCES.TEACHER]: '/dashboard',
	[SOURCES.EMPLOYEE]: '/dashboard',
	[SOURCES.GROUP]: '/dashboard',
	[SOURCES.ADMIN]: '/dashboard',
	[SOURCES.USER]: '/dashboard',
	[SOURCES.REQUEST]: '/dashboard',
	[SOURCES.BOOKING]: '/dashboard',
	[SOURCES.SCHOOL]: '/dashboard',
	[SOURCES.MEMBERSHIP]: '/dashboard',
	[SOURCES.SEARCH]: '/dashboard',
	[SOURCES.TEMPLATE]: '/dashboard',
};

export const Notifications = () => {
	const { role } = useSelector((state) => state.user.value);
	const [loadingStatus, setLoadingStatus] = useState('idle');
	const [notifications, setNotifications] = useState([]);

	function createActivityLink(activity) {
		return '/' + role + (LINK_BY_SOURCE[activity.type] || '/dashboard');
	}

	async function initializeData() {
		setLoadingStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchNotifications({ limit: 20 });
			setNotifications(result.notifications);
			setLoadingStatus('success');
		} catch (error) {
			setLoadingStatus('error');
		}
	}

	useEffect(() => {
		if (loadingStatus === 'idle') {
			initializeData();
		}
	}, []);

	const renderContent = () => {
		switch (loadingStatus) {
			case 'loading':
				return <Spinner />;
			case 'error':
				return <ErrorComponent />;
			case 'success':
				return notifications.length > 0 ? (
					<S.List>
						{notifications.map((notification) => (
							<Menu.Item key={notification.id} onClick={() => markNotificationsRead({ notificationIds: [notification.id] })}>
								{() => (
									<S.ListItem to={createActivityLink(notification)}>
										<div className="list-item__thumbnail">
											<FontAwesomeIcon icon={notification.icon} size="lg" />
										</div>
										<div className="list-item__info-wrapper">
											<Typography tag="p" variation="2">
												{formatTemplatedMessage(notification.message, notification.subject, notification.variables)}
											</Typography>
											<Typography tag="p" variation="3" className="list-item__info-wrapper__small-text">
												{formatToRelativeTime(new Date(notification.date.iso))}
											</Typography>
										</div>
										{!notification.isRead && <div className="list-item__is-read-badge" />}
									</S.ListItem>
								)}
							</Menu.Item>
						))}
					</S.List>
				) : (
					<EmptyComponent title="No available notifications" message="Check back later for new notifications." icon={['fal', 'bell']} />
				);
			default:
				return null;
		}
	};

	return (
		<Menu as={S.NotificationWrapper}>
			<Menu.Button as={S.BellButton} />
			<Menu.Items as={S.NotificationItems}>
				<S.SpaceBetween>
					<Typography tag="h5" weight="bold">
						Notifications
					</Typography>
					{notifications.length > 0 && (
						<Button variant="text" type="button" onClick={() => markNotificationsRead({ notificationIds: notifications.map((n) => n.id) })}>
							<Typography variation="button-small" weight="bold">
								Mark all as Read
							</Typography>
						</Button>
					)}
				</S.SpaceBetween>
				{renderContent()}
			</Menu.Items>
		</Menu>
	);
};
