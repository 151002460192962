import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { Picture } from '../Picture';
import { Typography } from '../Typography';
import * as S from './DirectoryCard.styles';
import { REQUEST_STATUSES, RESOURCE_STATUSES, ROLES } from '../../../constants/general.constants';
import { Badge } from '../Badge';

const DirectoryCard = ({ to, back, variation = 'grid', thumbnail, status, bookmarked, type, title, location, role, bookmarkAction, isArchived }) => {
	const history = useHistory();
	const formatType = (type) => {
		switch (type) {
			case 'appointment':
				return 'Appointment';
			case 'information':
				return 'Information';
			case 'event':
				return 'Event';
			default:
				return type || 'Event';
		}
	};

	const renderAdminBadge = () => {
		if (isArchived === true) {
			return (
				<S.ArchiveBadgeContainer>
					<Badge variation="square">Archived</Badge>
				</S.ArchiveBadgeContainer>
			);
		} else if (status === RESOURCE_STATUSES.DRAFT || status === REQUEST_STATUSES.DRAFT) {
			return (
				<S.ArchiveBadgeContainer>
					<Badge variation="square">Draft</Badge>
				</S.ArchiveBadgeContainer>
			);
		}
	};

	return (
		<S.DirectoryCard
			className="directory-card"
			variation={variation}
			onClick={() => {
				history.push({ pathname: to, state: { backUrl: back } });
			}}
		>
			{role != null && role !== ROLES.ADMIN && role !== ROLES.SUPER && (
				<S.IconButton
					className="directory-card__bookmark-button"
					bookmarked={bookmarked}
					icon="bookmark"
					size={1}
					onClick={(e) => {
						e.stopPropagation();
						e.nativeEvent.stopImmediatePropagation();
						bookmarkAction();
					}}
				/>
			)}
			{(role === ROLES.ADMIN || role === ROLES.SUPER) && renderAdminBadge()}
			<Picture className="directory-card__thumbnail" src={thumbnail} aspectRatio="3/2" />
			<S.InfoBox>
				<Typography className="directory-card__type" tag="p" variation="3">
					{formatType(type)}
				</Typography>
				<Typography className="directory-card__title" tag="h5" weight="bold">
					{title}
				</Typography>
				<Typography className="directory-card__location" tag="p" variation="2">
					{location}
				</Typography>
			</S.InfoBox>
		</S.DirectoryCard>
	);
};

DirectoryCard.propTypes = {
	to: PropTypes.string.isRequired,
	back: PropTypes.string.isRequired,
	variation: PropTypes.oneOf(['grid', 'list', 'list-alt']),
	thumbnail: PropTypes.string,
	status: PropTypes.string,
	bookmarked: PropTypes.bool,
	type: PropTypes.string,
	title: PropTypes.string,
	location: PropTypes.string,
	role: PropTypes.string,
	bookmarkAction: PropTypes.func,
	isArchived: PropTypes.bool,
};

export { DirectoryCard };
