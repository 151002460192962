import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const UserRoute = ({ component: Component, path, exact = false }) => {
	const user = useSelector((state) => state.user.value);
	const status = useSelector((state) => state.user.status);

	const render = (props) =>
		user ? (
			<Component {...props} />
		) : (
			<Redirect
				to={{
					pathname: '/login',
				}}
			/>
		);

	if (status === 'idle') {
		return null;
	}

	return <Route exact={exact} path={path} render={render} />;
};

UserRoute.propTypes = {
	component: PropTypes.func.isRequired,
	path: PropTypes.string,
	exact: PropTypes.bool,
};
