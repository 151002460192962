import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { formatToDayMonth2DigitYear } from '../../../utils/time-formatter';
import { Typography } from '../..';

export const StepFour = ({ role, context }) => {
	const {
		state: { booking },
	} = useContext(context);

	const { getValues, watch } = useFormContext();

	const user = useSelector((state) => state.user.value);
	const selectedSchool = user.schools.find(({ id }) => id === watch('schoolLocationId'));

	const businessOptions = booking?.worksiteLocations || [];
	const selectedBusiness = businessOptions?.find(({ id }) => id === watch('businessLocationId'));

	const formatAvailabilityDate = () => {
		if (getValues('availabilityDates')?.length > 1) {
			return `${formatToDayMonth2DigitYear(getValues('availabilityDates')[0])} - ${formatToDayMonth2DigitYear(getValues('availabilityDates')[1])}`;
		} else if (getValues('availabilityDates')?.length === 1) {
			return `${formatToDayMonth2DigitYear(getValues('availabilityDates')[0])}`;
		}
		return '';
	};

	return (
		<div className="modal-content">
			<Typography className="bigger-title" tag="h1" weight="extrablack" center>
				Review Your Scheduled Booking
			</Typography>
			<Typography tag="p" className="description" center>
				Please look over the schedule you are about to submit for your booking.
			</Typography>

			<div className="review-wrapper">
				<div className="section-wrapper">
					<Typography tag="h3" weight="bold">
						Date
					</Typography>
					<div className="field-wrapper">
						<Typography tag="h6" weight="semibold" className="field-label">
							Date Range
						</Typography>
						<Typography tag="p" className="field-value">
							{formatAvailabilityDate()}
						</Typography>
					</div>
					<div className="field-wrapper">
						<Typography tag="h6" weight="semibold" className="field-label">
							Availability Notes
						</Typography>
						<Typography tag="p" className="field-value">
							{getValues('availabilityNotes')}
						</Typography>
					</div>
				</div>
				<div className="section-wrapper">
					<Typography tag="h3" weight="bold">
						Location
					</Typography>
					{getValues('locationTypes')?.includes('virtual') && (
						<div className="location-field">
							<div className="location-field__info">
								<div className="location-field__icon-wrapper">
									<FontAwesomeIcon icon={['fal', 'camera']} />
								</div>
								<Typography tag="h4" weight="bold">
									Virtual Location
								</Typography>
							</div>
							<div className="field-wrapper">
								<Typography tag="h6" weight="semibold" className="field-label">
									Location Notes
								</Typography>
								<Typography tag="p" className="field-value">
									{getValues('virtualLocationNotes')}
								</Typography>
							</div>
						</div>
					)}
					{getValues('locationTypes')?.includes('student') && (
						<div className="location-field">
							<div className="location-field__info">
								<div className="location-field__icon-wrapper">
									<FontAwesomeIcon icon={['fal', 'users']} />
								</div>
								<Typography tag="h4" weight="bold">
									Student Location
								</Typography>
							</div>
							<div className="location-field-box">
								<div className="icon-wrapper">
									<FontAwesomeIcon icon="school" />
								</div>
								<div className="info-wrapper">
									<Typography tag="h5" weight="bold">
										{selectedSchool?.name}
									</Typography>
									<div className="info-line">
										<FontAwesomeIcon icon={['fal', 'map-marker']} />
										<Typography tag="p" variation="2">
											{`${selectedSchool?.address.addressLine1}, ${selectedSchool?.address.state} ${selectedSchool?.address.postalCode}`}
										</Typography>
									</div>
									<div className="info-line">
										<FontAwesomeIcon icon={['fal', 'landmark']} />
										<Typography tag="p" variation="2">
											{selectedSchool?.address.city}
										</Typography>
									</div>
									<div className="info-line">
										<FontAwesomeIcon icon={['fal', 'school']} />
										<Typography tag="p" variation="2">
											{selectedSchool?.schoolCode}
										</Typography>
									</div>
								</div>
							</div>
							<div className="field-wrapper mt-1">
								<Typography tag="h6" weight="semibold" className="field-label">
									Location Notes
								</Typography>
								<Typography tag="p" className="field-value">
									{getValues('studentLocationNotes')}
								</Typography>
							</div>
						</div>
					)}
					{getValues('locationTypes')?.includes('worksite') && (
						<div className="location-field">
							<div className="location-field__info">
								<div className="location-field__icon-wrapper">
									<FontAwesomeIcon icon={['fal', 'building']} />
								</div>
								<Typography tag="h4" weight="bold">
									Business Location
								</Typography>
							</div>
							<div className="location-field-box">
								<div className="icon-wrapper">
									<FontAwesomeIcon icon="building" />
								</div>
								<div className="info-wrapper">
									<Typography tag="h5" weight="bold">
										{selectedBusiness?.name}
									</Typography>
									<div className="info-line">
										<FontAwesomeIcon icon={['fal', 'map-marker']} />
										<Typography tag="p" variation="2">
											{`${selectedBusiness?.address.addressLine1}, ${selectedBusiness?.address.state} ${selectedBusiness?.address.postalCode}`}
										</Typography>
									</div>
									<div className="info-line">
										<FontAwesomeIcon icon={['fal', 'landmark']} />
										<Typography tag="p" variation="2">
											{selectedBusiness?.address.city}
										</Typography>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
				<div className="section-wrapper">
					<Typography tag="h3" weight="bold">
						Notes
					</Typography>
					<div className="field-wrapper">
						<Typography tag="h6" weight="semibold" className="field-label">
							Additional Notes
						</Typography>
						<Typography tag="p" className="field-value">
							{getValues('notes')}
						</Typography>
					</div>
				</div>
			</div>
		</div>
	);
};

StepFour.propTypes = {
	role: PropTypes.string,
	context: PropTypes.shape(),
};
