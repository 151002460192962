import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';

import { REQUEST_CANCEL_REASONS } from '../../../../constants/general.constants';
import { errorHandler } from '../../../services/authService';
import { required } from '../../../utils/form-default-errors';
import { Button, IconButton, Select, TextArea, Typography } from '../../../components';
import { sendRequestResponse } from '../../../services/requests';
import * as S from './SendResponseModal.styles';

export const SendResponseModal = forwardRef(({}, ref) => {
	const [isOpen, setIsOpen] = useState(false);
	const history = useHistory();

	const {
		handleSubmit,
		register,
		setValue,
		reset,
		formState: { errors, isSubmitting },
	} = useForm();

	const handleOpen = (requestId) => {
		setValue('requestId', requestId);
		setIsOpen(true);
	};

	const handleClose = () => {
		setIsOpen(false);
		setTimeout(() => {
			reset();
		}, 200);
	};

	async function asyncCaller(data) {
		await sendRequestResponse(data);
		history.push('/business/requests');
	}

	const onSubmit = (e) => {
		handleSubmit(asyncCaller)(e).catch(errorHandler);
	};

	useImperativeHandle(
		ref,
		() => ({
			open: handleOpen,
			close: handleClose,
		}),
		[handleOpen, handleClose]
	);

	return (
		<ReactModal className="smaller-modal" ref={ref} closeTimeoutMS={200} isOpen={isOpen} onRequestClose={handleClose}>
			<S.Form onSubmit={onSubmit}>
				<IconButton className="close-button" type="button" icon={['fal', 'times']} onClick={handleClose} />
				<S.InfoWrapper>
					<Typography tag="h2" weight="extrablack" center>
						Send Response
					</Typography>
					<Typography tag="p" center>
						Send your first message below.
					</Typography>
				</S.InfoWrapper>
				<S.FieldsWrapper>
					<TextArea
						label="Message"
						placeholder="Add message here"
						id="message"
						error={errors?.message}
						{...register('message', {
							required: required('Message'),
						})}
					/>
				</S.FieldsWrapper>
				<S.ButtonsWrapper>
					<Button type="button" variant="outline" onClick={handleClose}>
						<Typography weight="bold" variation="button-medium">
							Back
						</Typography>
					</Button>
					<Button type="submit">
						<Typography weight="bold" variation="button-medium">
							{isSubmitting ? 'Loading' : 'Send'}
						</Typography>
					</Button>
				</S.ButtonsWrapper>
			</S.Form>
		</ReactModal>
	);
});

SendResponseModal.displayName = 'SendResponseModal';
