import styled, { css } from 'styled-components';

import colors from '../../../styles/colors';

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	position: relative;
	height: 100%;

	.close-button {
		position: absolute;
		top: 1rem;
		right: 1rem;
		color: ${colors.neutralButton};
	}

	.modal-content-wrapper {
		flex: 1;
		overflow: auto;
	}

	.modal-content {
		display: block;
		flex: 1;
		max-width: 41.75rem;
		margin: 0 auto;
		width: 100%;
		padding: 3rem 1rem 2rem;

		.bigger-title {
			overflow: hidden;
			margin: 0 -1rem;
		}

		.description {
			margin-top: 0.5rem;
			margin-bottom: 3rem;
		}

		.calendar-wrapper {
			display: flex;
			flex-direction: column;
			position: relative;
		}

		.calendar-title {
			margin-bottom: 0.5rem;
		}

		.calendar-content {
			border: 1px solid ${colors.neutralForm};
			border-radius: 0.375rem;
		}

		.with-error {
			border-color: ${colors.stateDanger};
		}

		.field-wrapper.mt-1 {
			margin-top: 0.5rem;
		}

		.field-label {
			color: ${colors.neutralText};

			&.custom-margin {
				margin-top: 1.5rem;
			}

			&--empty {
				color: ${colors.neutralText};
			}

			&.with-error {
				color: ${colors.stateDanger};
			}
		}

		.location-field {
			display: flex;
			flex-direction: column;

			&__info {
				display: flex;
				align-items: center;
				margin-bottom: 0.5rem;
			}

			&__icon-wrapper {
				margin-right: 0.5rem;
				width: 1.5rem;
				display: flex;
				justify-content: center;
				align-items: center;

				svg {
					width: 1.5rem;
					height: 1.5rem;
				}
			}

			.location-field-box {
				display: flex;
				padding: 0.5rem 0.75rem;
				background-color: ${colors.neutralBackground};
				border: 1px solid ${colors.neutralForm};
				border-radius: 0.375rem;

				.icon-wrapper {
					display: flex;
					justify-content: center;
					width: 1.25rem;
					color: ${colors.brandPrimary};
				}

				.info-wrapper {
					margin-left: 0.75rem;
					color: #000000;

					.info-line {
						display: flex;
						margin-top: 0.5rem;
						color: ${colors.neutralButton};

						svg {
							width: 1rem;
							height: 1rem;
						}

						p {
							margin-left: 0.5rem;
						}
					}
				}
			}
		}

		.fields-wrapper {
			display: grid;
			grid-template-columns: 1fr;
			gap: 1.25rem;

			.time-wrapper {
				display: grid;
				grid-template-columns: 1fr;
				gap: 1.25rem;

				@media (min-width: 992px) {
					grid-template-columns: 1fr 1fr;
				}
			}

			.huge-textarea {
				min-height: 21rem;
			}
		}

		.review-wrapper {
			display: grid;
			grid-template-columns: 1fr;
			gap: 2rem;
		}

		.section-wrapper {
			display: grid;
			grid-template-columns: 1fr;
			gap: 1.25rem;
		}

		.checkbox-field,
		.radio-field {
			display: flex;
			align-items: center;

			& > * + * {
				padding-left: 0.75rem;
			}

			label {
				font-size: 1rem;
				line-height: 1.375rem;
				font-weight: 400;
				color: black;
				cursor: pointer;
			}
		}

		.error-wrapper {
			position: absolute;
			bottom: -1rem;
			color: ${colors.stateDanger};
		}
	}

	.modal-footer {
		display: flex;
		justify-content: flex-end;
		padding: 1.5rem;
		border-top: 1px solid ${colors.neutralForm};
	}
`;

export const ButtonsWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin: 0;
	padding: 1rem;
	border-top: 1px solid ${colors.neutralForm};

	& > * + * {
		margin-left: 1rem;
	}
`;

export const LocationStep = styled.section`
	display: grid;
	grid-template-columns: 1fr;
	gap: 1.5rem;
	margin-top: 2.5rem;
`;

export const LocationStepWrapper = styled.div`
	position: relative;
	display: grid;
	grid-template-columns: 1fr;
	gap: 1rem;
	margin-top: -0.75rem;

	.error-wrapper {
		bottom: -1.5rem;
	}
`;

export const SelectedLocations = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	gap: 2rem;
	margin-bottom: 1.5rem;

	.step {
		&__location {
			display: grid;
			grid-template-columns: 2rem 1fr;
		}

		&__location-fields-wrapper {
			display: grid;
			grid-template-columns: 1fr;
			gap: 0.75rem;
		}

		&__information-card {
			display: flex;
			padding: 0.5rem 0.75rem;
			background-color: ${colors.neutralBackground};
			border: 1px solid ${colors.neutralForm};
			border-radius: 0.375rem;

			.icon-wrapper {
				display: flex;
				justify-content: center;
				width: 1.25rem;
				color: ${colors.brandPrimary};
			}

			.info-wrapper {
				margin-left: 0.75rem;
				color: #000000;

				.info-line {
					display: flex;
					margin-top: 0.5rem;
					color: ${colors.neutralButton};

					svg {
						width: 1rem;
						height: 1rem;
					}

					p {
						margin-left: 0.5rem;
					}
				}
			}
		}
	}
`;
