import React, { forwardRef, useState, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';

import { Chat } from '../Chat';
import { Picture } from '../Picture';
import { Typography } from '../Typography';
import { IconButton } from '../IconButton';
import * as S from './FloatingChatContainer.styles';

// This component will be appended to the bottom of the closest parent with position relative
export const FloatingChatContainer = forwardRef(({ header, loading, messages, setMessages, sendProps }, ref) => {
	const [isOpen, setIsOpen] = useState(false);

	const handleOnChatHeaderClick = () => {
		handleOpenChatContainer();
	};

	const handleOnButtonCloseClick = (e) => {
		e.stopPropagation();
		handleCloseChatContainer();
	};

	const handleOpenChatContainer = () => {
		setIsOpen(true);
	};

	const handleCloseChatContainer = () => {
		setIsOpen(false);
	};

	useImperativeHandle(ref, () => ({
		open: handleOpenChatContainer,
		close: handleCloseChatContainer,
	}));

	return (
		<S.AbsoluteWrapper>
			<S.Wrapper>
				<S.ChatHeader onClick={handleOnChatHeaderClick}>
					<S.InfoWrapper>
						<Picture className="avatar-image" src={header.avatar} aspectRatio="1/1" alt="profile" />
						<div className="info-block">
							<Typography tag="h4" weight="bold">
								{header.name}
							</Typography>
						</div>
						<IconButton className="close-button" size={1.25} icon={['fal', 'times']} onClick={handleOnButtonCloseClick} />
					</S.InfoWrapper>
				</S.ChatHeader>
				<S.ChatContainer isOpen={isOpen}>
					<Chat loading={loading} messages={messages} setMessages={setMessages} sendProps={sendProps} />
				</S.ChatContainer>
			</S.Wrapper>
		</S.AbsoluteWrapper>
	);
});

FloatingChatContainer.displayName = 'FloatingChatContainer';

FloatingChatContainer.propTypes = {
	header: PropTypes.shape({
		avatar: PropTypes.string,
		name: PropTypes.string,
	}),
	loading: PropTypes.bool,
	messages: PropTypes.arrayOf(
		PropTypes.shape({
			sender: PropTypes.shape({
				senderType: PropTypes.oneOf(['business', 'user']),
				business: PropTypes.shape({
					name: PropTypes.string,
					logoObj: PropTypes.shape({
						200: PropTypes.string,
					}),
				}),
				user: PropTypes.shape({
					firstName: PropTypes.string,
					lastName: PropTypes.string,
					profileImageObj: PropTypes.shape({
						200: PropTypes.string,
					}),
				}),
			}),
			message: PropTypes.string,
			mediaObj: PropTypes.shape({
				600: PropTypes.string,
			}),
			type: PropTypes.oneOf(['received', 'sent']),
		})
	),
	sendProps: PropTypes.shape({
		businessId: PropTypes.string,
		bookingId: PropTypes.string,
		recipientId: PropTypes.string,
		requestId: PropTypes.string,
		responseId: PropTypes.string,
		resourceId: PropTypes.string,
	}),
	setMessages: PropTypes.func,
};
