import React, { forwardRef, useContext, useRef, useImperativeHandle, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { Controller, useForm } from 'react-hook-form';

import { formatPayloadBeforeUpdate } from '../OverviewTab';
import { createRequest } from '../../../../services/requests/createRequestService';
import { fetchGroups } from '../../../../services/groups';
import { AppointmentDetailsContext } from '../../RequestsAppointmentDetails';
import { Button, Card, ErrorComponent, IconButton, Select, Spinner, Typography } from '../../../../components';
import { errorHandler } from '../../../../services/authService';
import { required } from '../../../../utils/form-default-errors';
import * as S from './GroupCard.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GRADE_LEVELS, ROLES } from '../../../../../constants/general.constants';

const GroupEditModal = forwardRef((_, ref) => {
	const {
		state: { request },
		dispatch,
	} = useContext(AppointmentDetailsContext);
	const [loadingStatus, setLoadingStatus] = useState('idle');
	const [isOpen, setIsOpen] = useState(false);
	const [groups, setGroups] = useState([]);

	const {
		handleSubmit,
		control,
		setValue,
		reset,
		watch,
		formState: { errors, isSubmitting },
	} = useForm();

	const selectedGroup = groups.find((group) => group.groupId === watch('groupId'));

	const handleOpen = (groupId) => {
		setValue('groupId', groupId);
		setIsOpen(true);
	};

	const handleClose = () => {
		setIsOpen(false);
		setTimeout(() => {
			reset();
		}, 200);
	};

	async function asyncCaller(data) {
		const newRequest = formatPayloadBeforeUpdate(request, { groupId: data.groupId });

		const {
			data: { result },
		} = await createRequest(newRequest);
		dispatch({ type: 'setRequest', payload: result.request });
		handleClose();
	}

	const onSubmit = (e) => handleSubmit(asyncCaller)(e).catch(errorHandler);

	const renderContent = () => {
		switch (loadingStatus) {
			case 'loading':
				return <Spinner />;
			case 'error':
				return <ErrorComponent />;
			case 'success':
				return (
					<>
						<S.InfoWrapper>
							<Typography tag="h2" weight="extrablack" center>
								Edit Group
							</Typography>
							<Typography tag="p" center>
								Select a group for this request.
							</Typography>
						</S.InfoWrapper>
						<S.FieldsWrapper>
							<div>
								<Controller
									name="groupId"
									control={control}
									rules={{ required: required('Name of Group') }}
									render={({ field: { onChange, value } }) => (
										<Select
											getOptionLabel={(option) => option.name}
											getOptionValue={(option) => option.groupId}
											label="Name of Group (i.e class, entire school, grade, etc.)"
											placeholder="Select a group..."
											id="group"
											error={errors?.groupId}
											options={groups}
											value={groups.find((c) => c.groupId === value) || ''}
											onChange={(val) => onChange(val.groupId)}
										/>
									)}
								/>
								{selectedGroup && (
									<div className="information-card">
										<div className="icon-wrapper">
											<FontAwesomeIcon icon="users" />
										</div>
										<div className="info-wrapper">
											<Typography tag="h5" weight="bold">
												{selectedGroup?.name}
											</Typography>
											<div className="info-line">
												<FontAwesomeIcon icon={['fal', 'users']} />
												<Typography tag="p" variation="2">
													{`${selectedGroup?.totalStudents} students`}
												</Typography>
											</div>
											<div className="info-line">
												<FontAwesomeIcon icon={['fal', 'users-class']} />
												<Typography tag="p" variation="2">
													{GRADE_LEVELS.find((grade) => grade.value === selectedGroup?.gradeLevels[0])?.label}
												</Typography>
											</div>
											{selectedGroup?.subject && (
												<div className="info-line">
													<FontAwesomeIcon icon={['fal', 'chalkboard']} />
													<Typography tag="p" variation="2">
														{selectedGroup?.subject}
													</Typography>
												</div>
											)}
											{selectedGroup?.classCode && (
												<div className="info-line">
													<FontAwesomeIcon icon={['fal', 'book']} />
													<Typography tag="p" variation="2">
														{selectedGroup?.classCode}
													</Typography>
												</div>
											)}
										</div>
									</div>
								)}
							</div>
						</S.FieldsWrapper>
						<S.ButtonsWrapper>
							<Button type="submit">
								<Typography weight="bold" variation="button-medium">
									{isSubmitting ? 'Loading' : 'Save'}
								</Typography>
							</Button>
						</S.ButtonsWrapper>
					</>
				);
			default:
				return null;
		}
	};

	async function initializeData() {
		setLoadingStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchGroups({ page: 0 });

			setGroups(result.groups);
			setLoadingStatus('success');
		} catch (error) {
			setLoadingStatus('error');
		}
	}

	useImperativeHandle(ref, () => ({
		open: handleOpen,
		close: handleClose,
	}));

	useEffect(() => {
		initializeData();
	}, []);

	return (
		<ReactModal className="smaller-modal" ref={ref} closeTimeoutMS={200} isOpen={isOpen} onRequestClose={handleClose}>
			<S.Form onSubmit={onSubmit}>
				<IconButton className="close-button" type="button" icon={['fal', 'times']} onClick={handleClose} />
				{renderContent()}
			</S.Form>
		</ReactModal>
	);
});
GroupEditModal.displayName = 'GroupEditModal';

export const GroupCard = ({ className, role }) => {
	const {
		state: { request },
	} = useContext(AppointmentDetailsContext);
	const { group } = request;

	const user = useSelector((state) => state.user.value);

	const modalRef = useRef(null);

	const handleEditClick = () => modalRef.current.open(group?.groupId);

	return (
		<Card
			title="Group"
			className={className}
			transparentHeaderBorder
			actions={(role != null && request.creator != null && request.creator.id === user?.id) || role === ROLES.ADMIN || role === ROLES.SUPER ? [{ id: 1, label: 'Edit', size: 'small', variant: 'outline', onClick: handleEditClick, icon: { source: ['fal', 'edit'] } }] : []}
		>
			<S.Wrapper>
				<Typography className="block-title" tag="p" weight="semibold">
					{group?.name}
				</Typography>
				<div className="element-wrapper">
					<Typography tag="h6" weight="semibold">
						Grade Level
					</Typography>
					<Typography tag="p">{group?.gradeLevel}</Typography>
				</div>
				<div className="element-wrapper">
					<Typography tag="h6" weight="semibold">
						# of Students
					</Typography>
					<Typography tag="p">{group?.totalStudents}</Typography>
				</div>
				{group?.isClass === true && (
					<div className="element-wrapper">
						<Typography tag="h6" weight="semibold">
							Subject
						</Typography>
						<Typography tag="p">{group?.subject}</Typography>
					</div>
				)}
				{group?.isClass === true && (
					<div className="element-wrapper">
						<Typography tag="h6" weight="semibold">
							Course Code
						</Typography>
						<Typography tag="p">{group?.classCode}</Typography>
					</div>
				)}
				<div className="element-wrapper">
					<Typography tag="h6" weight="semibold">
						Age Range
					</Typography>
					<Typography tag="p">{group?.ageRange} years</Typography>
				</div>
			</S.Wrapper>
			{role != null && <GroupEditModal ref={modalRef} />}
		</Card>
	);
};
GroupCard.propTypes = {
	className: PropTypes.string,
	role: PropTypes.string,
};
