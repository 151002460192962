import React, { forwardRef, useContext, useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';

import { beginPasswordReset, errorHandler } from '../../../../services/authService';
import { checkEmailExists } from '../../../../services/users';
import { Button, Card, IconButton, TextInput, Typography } from '../../../../components';
import { BusinessCompanyEmployeeDetailContext } from '../../BusinessCompanyEmployeeDetail';
import { EMAIL } from '../../../../utils/common-regex';
import { pattern, required } from '../../../../utils/form-default-errors';
import * as S from './AccountCard.styles';
import { createUpdateEmployee } from '../../../../services/business';

const EditEmailModal = forwardRef((_props, ref) => {
	const {
		state: { employee },
		dispatch,
	} = useContext(BusinessCompanyEmployeeDetailContext);
	const [isOpen, setIsOpen] = useState(false);
	const {
		handleSubmit,
		setValue,
		register,
		reset,
		formState: { errors, isSubmitting },
	} = useForm();

	const handleOpenModal = () => {
		setValue('email', employee?.email);
		setIsOpen(true);
	};

	const handleCloseModal = () => {
		setIsOpen(false);
		setTimeout(() => {
			reset();
		}, 200);
	};

	async function asyncCaller(data) {
		const {
			data: {
				result: { exists },
			},
		} = await checkEmailExists(data);

		if (exists) {
			toast.error('Email already exists.');
			return;
		}

		const {
			data: { result },
		} = await createUpdateEmployee({ employeeId: employee.id, firstName: employee.firstName, lastName: employee.lastName, permissions: employee.businessPermissions, locationIds: employee.businessLocations.map((l) => l.id), profileImageObj: employee.profileImageObj, ...data });

		dispatch({ type: 'SET_EMPLOYEE', payload: result.employee });
		handleCloseModal();
	}

	const onSubmit = (e) => {
		handleSubmit(asyncCaller)(e).catch(errorHandler);
	};

	useImperativeHandle(
		ref,
		() => ({
			open: handleOpenModal,
			close: handleCloseModal,
		}),
		[handleOpenModal, handleCloseModal]
	);

	return (
		<ReactModal className="account-card-modal" ref={ref} closeTimeoutMS={200} isOpen={isOpen} onRequestClose={handleCloseModal}>
			<S.Form onSubmit={onSubmit}>
				<IconButton className="close-button" type="button" icon={['fal', 'times']} onClick={handleCloseModal} />
				<div className="modal-content">
					<header className="modal-content__header">
						<Typography tag="h2" weight="extrablack" center>
							Edit Email
						</Typography>
						<Typography tag="p" center>
							Please update your email here.
						</Typography>
					</header>

					<section className="modal-content__section">
						<TextInput
							label="Email"
							placeholder="Email"
							error={errors.email}
							{...register('email', {
								required: required('Email'),
								pattern: pattern('Email', EMAIL),
							})}
						/>
					</section>

					<div className="modal-footer">
						<Button>
							<Typography variation="button-medium" weight="bold">
								{isSubmitting ? 'Loading' : 'Save'}
							</Typography>
						</Button>
					</div>
				</div>
			</S.Form>
		</ReactModal>
	);
});

EditEmailModal.displayName = 'EditEmailModal';

const AccountCard = ({ className }) => {
	const {
		state: { employee },
	} = useContext(BusinessCompanyEmployeeDetailContext);

	const modalRef = useRef(null);

	const handleEditEmailClick = () => {
		modalRef.current.open();
	};

	const handleResetPasswordClick = async () => {
		try {
			await beginPasswordReset({ email: employee.email });
			toast.success("We've sent a secure email to your inbox with instructions on how to reset your password.");
		} catch (error) {
			errorHandler(error);
		}
	};

	return (
		<Card className={className} title="Account" transparentHeaderBorder={true}>
			<S.Wrapper>
				<div className="row-line">
					<div className="element-info">
						<Typography className="element-info__title" tag="h6" weight="semibold">
							Email
						</Typography>
						<Typography className="element-info__text" tag="p">
							{employee?.email}
						</Typography>
					</div>
					<Button variant="text" type="button" onClick={handleEditEmailClick}>
						<Typography variation="button-medium" weight="bold">
							Update Email
						</Typography>
					</Button>
				</div>
				<div className="row-line">
					<div className="element-info">
						<Typography className="element-info__title" tag="h6" weight="semibold">
							Password
						</Typography>
						<Typography className="element-info__text" tag="p">
							**********
						</Typography>
					</div>
					<Button variant="text" type="button" onClick={handleResetPasswordClick}>
						<Typography variation="button-medium" weight="bold">
							Reset Password
						</Typography>
					</Button>
				</div>
			</S.Wrapper>
			<EditEmailModal ref={modalRef} />
		</Card>
	);
};

AccountCard.propTypes = {
	className: PropTypes.string,
};

export default AccountCard;
