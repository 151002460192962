import React, { createContext, useEffect, useReducer, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { BusinessNavigation, Button, DetailPageHeader, ErrorComponent, Spinner, Tab, Typography, MessageDialog } from '../../components';
import { META_TITLE } from '../../../constants/general.constants';
import { fetchEmployee, removeEmployee } from '../../services/business';
import { OverviewTab } from './OverviewTab';
import { ResourcesTab } from './ResourcesTab';
import { RequestsTab } from './RequestsTab';
import { BookingsTab } from './BookingsTab';
import * as S from './BusinessCompanyEmployeeDetail.styles';
import { errorHandler } from '../../services/authService';
import { useQuery } from '../../hooks/useQuery';

export const BusinessCompanyEmployeeDetailContext = createContext();

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_EMPLOYEE':
			return {
				...state,
				employee: action.payload,
			};
		default:
			return state;
	}
};

const BusinessCompanyEmployeeDetail = () => {
	const [pageStatus, setPageStatus] = useState('idle');
	const [state, dispatch] = useReducer(reducer, {});
	const [removeIsOpen, setRemoveIsOpen] = useState(false);
	const { id: userId } = useParams();
	const query = useQuery();

	const history = useHistory();
	const [tabs, setTabs] = useState([
		{
			id: 1,
			label: 'Overview',
			component: <OverviewTab />,
			active: query.get('tabId') === '1',
		},
		{
			id: 2,
			label: 'Resources',
			component: <ResourcesTab />,
			active: query.get('tabId') === '2',
		},
		{
			id: 3,
			label: 'Requests',
			component: <RequestsTab />,
			active: query.get('tabId') === '3',
		},
		{
			id: 4,
			label: 'Bookings',
			component: <BookingsTab />,
			active: query.get('tabId') === '4',
		},
	]);

	const backTitle = location.state?.backTitle || 'Back to Staff';

	function handleOpenRemoveModal() {
		setRemoveIsOpen(true);
	}

	function handleCloseRemoveModal() {
		setRemoveIsOpen(false);
	}

	const handleRemoveEmployee = async () => {
		try {
			await removeEmployee({ businessId: state.employee.business.id, employeeId: state.employee.id });
			history.push('/business/company?tabId=2');
		} catch (error) {
			errorHandler(error);
		}
	};

	async function initializeData() {
		try {
			setPageStatus('loading');
			const {
				data: { result },
			} = await fetchEmployee({ userId });
			dispatch({ type: 'SET_EMPLOYEE', payload: result.employee });
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}

	function renderContent() {
		switch (pageStatus) {
			case 'loading':
				return <Spinner />;
			case 'success':
				return (
					<BusinessCompanyEmployeeDetailContext.Provider value={{ state, dispatch }}>
						<DetailPageHeader title={`${state.employee.firstName} ${state.employee.lastName}`} backButton={{ label: backTitle, onClick: () => history.push('/business/company?tabId=2') }}>
							{!state.employee.businessPermissions.includes('owner') && (
								<>
									<Button variant="outline" variation="warning" onClick={handleOpenRemoveModal}>
										<Typography weight="bold" variation="button-medium">
											Remove User
										</Typography>
									</Button>
									<MessageDialog
										isOpen={removeIsOpen}
										onRequestClose={handleCloseRemoveModal}
										title="Remove User?"
										subtitle="Are you sure you want to remove this user?"
										icon={['fal', 'user']}
										actions={[
											{ id: 'back', label: 'Cancel', variant: 'outline', variation: 'default' },
											{ id: 'cancel', label: 'Remove User', variant: 'solid', variation: 'default', onClick: handleRemoveEmployee },
										]}
									/>
								</>
							)}
						</DetailPageHeader>
						<S.Wrapper>
							<Tab tabs={tabs} setTabs={setTabs} />
						</S.Wrapper>
					</BusinessCompanyEmployeeDetailContext.Provider>
				);
			case 'error':
				return <ErrorComponent />;
			default:
				return null;
		}
	}

	useEffect(() => {
		document.title = `Company Staff Detail | ${META_TITLE}`;
	}, []);

	useEffect(() => {
		if (pageStatus === 'idle') {
			initializeData();
		}
	}, [pageStatus]);

	return <BusinessNavigation>{renderContent()}</BusinessNavigation>;
};

export default BusinessCompanyEmployeeDetail;
