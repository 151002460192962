import styled, { css } from 'styled-components';

import colors from '../../../styles/colors';

export const Wrapper = styled.div`
	flex: 1;
	display: grid;
	grid-template-columns: 1fr;
	position: relative;

	${({ someChatIsSelected }) =>
		someChatIsSelected &&
		css`
			@media (min-width: 992px) {
				grid-template-columns: 3fr 4fr;
			}
		`}
`;

export const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin: -0.75rem -1rem -1rem;
	padding: 0.75rem 1rem 1rem;
	background-color: white;
`;

export const RelativeWrapper = styled.div`
	position: relative;
	flex: 1;
	overflow: hidden;
	margin: 0 -1rem;
`;

export const ListWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: grid;
	grid-template-columns: 1fr;
	align-content: start;
	padding: 0 1rem;
	overflow: auto;
`;

export const EmptyList = styled.div``;

export const GroupedListItem = styled.button`
	appearance: none;
	border: none;
	text-align: left;
	background-color: transparent;
	position: relative;
	display: grid;
	grid-template-columns: auto 1fr;
	grid-template-areas:
		'thumbnail-wrapper info-wrapper'
		'badge-wrapper     badge-wrapper';
	column-gap: 0.75rem;
	row-gap: 0.25rem;
	padding: 0.5rem 0.75rem;
	margin: 0.75rem -0.75rem calc(-0.25rem - 1px);
	border-radius: 0.25rem;

	&:first-child {
		margin-top: 0.1875rem;
	}

	@media (min-width: 415px) {
		grid-template-columns: auto 1fr auto;
		grid-template-areas: 'thumbnail-wrapper info-wrapper badge-wrapper';
	}

	.grouped-card {
		&__thumbnail-wrapper {
			display: flex;
			grid-area: thumbnail-wrapper;
			min-width: 6.25rem;
			max-width: 6.25rem;
		}

		&__info-wrapper {
			grid-area: info-wrapper;
		}

		&__badge-wrapper {
			grid-area: badge-wrapper;
		}

		&__thumbnail {
			border: 1px solid ${colors.neutralForm};
			border-radius: 0.375rem;
			width: 100%;
		}

		&__category {
			color: ${colors.neutralText};
			margin-top: 0.25rem;
			margin-bottom: 0.25rem;
		}

		&__count-wrapper {
			display: flex;
			align-items: center;
			margin-top: 0.125rem;
			color: ${colors.neutralButton};
		}

		&__count-text {
			margin-left: 0.25rem;
		}
	}

	&::after {
		content: '';
		height: 1px;
		position: absolute;
		left: 0.75rem;
		right: 0.75rem;
		bottom: -0.25rem;
		background-color: ${colors.neutralForm};
	}

	&:hover {
		background-color: ${colors.neutralBackground};
		cursor: pointer;
	}
`;

export const ListItem = styled.button`
	appearance: none;
	border: none;
	text-align: left;
	background-color: transparent;
	position: relative;
	display: grid;
	grid-template-columns: auto 1fr;
	grid-template-areas:
		'avatar-wrapper info-wrapper'
		'badge-wrapper  badge-wrapper';
	column-gap: 0.5rem;
	row-gap: 0.25rem;
	padding: 0.5rem 0.75rem;
	margin: 0.75rem -0.75rem calc(-0.25rem - 1px);
	border-radius: 0.25rem;

	&:first-child {
		margin-top: 0.1875rem;
	}

	@media (min-width: 415px) {
		grid-template-columns: auto 1fr auto;
		grid-template-areas: 'avatar-wrapper info-wrapper badge-wrapper';
	}

	.individual-card {
		&__avatar-wrapper {
			grid-area: avatar-wrapper;
		}

		&__avatar {
			border-radius: 9999px;
			border: 1px solid ${colors.neutralForm};
			min-width: 2.375rem;
			max-height: 2.5rem;
			box-sizing: content-box;
		}

		&__info-wrapper {
			grid-area: info-wrapper;
		}

		&__category {
			color: ${colors.neutralText};
			margin-bottom: 0.25rem;
		}

		&__badge-wrapper {
			grid-area: badge-wrapper;
		}
	}

	&::after {
		content: '';
		height: 1px;
		position: absolute;
		left: 0.75rem;
		right: 0.75rem;
		bottom: -0.25rem;
		background-color: ${colors.neutralForm};
	}

	&.is-selected {
		background-color: ${colors.neutralBackground};
	}

	&:hover {
		background-color: ${colors.neutralBackground};
		cursor: pointer;
	}
`;

export const ChatWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0 -1rem -1rem;

	position: absolute;
	top: -1rem;
	left: 0;
	right: 0;
	bottom: 0;

	@media (min-width: 992px) {
		position: static;
		margin: -1rem -1rem -1rem 1rem;
	}

	.relative-wrapper {
		position: relative;
		height: 100%;
	}
`;
