import styled from 'styled-components';

import colors from '../../../../styles/colors';

export const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	gap: 2rem;
	padding-bottom: 0.5rem;

	.avatar-wrapper {
		display: flex;
		justify-content: center;
	}

	.info-wrapper {
		display: grid;
		grid-template-columns: 1fr;
		gap: 1rem;
		align-self: flex-start;

		&__grid-wrapper {
			display: grid;
			grid-template-columns: 1fr;
			gap: 1rem;

			@media (min-width: 680px) {
				grid-template-columns: repeat(2, 1fr);
			}

			@media (min-width: 1200px) {
				grid-template-columns: repeat(3, 1fr);
			}
		}
	}

	.element-info {
		&__title {
			color: ${colors.neutralText};
			margin-bottom: 0.25rem;
		}

		&__badges-wrapper {
			display: flex;
			flex-wrap: wrap;
			margin: -0.25rem;
		}

		&__badge {
			padding: 0.25rem 0.5rem;
			border-radius: 6.25rem;
			border: 1px solid ${colors.brandPrimary};
			font-size: 0.75rem;
			color: ${colors.brandPrimary};
			font-weight: 700;
			margin: 0.25rem;
		}
	}

	@media screen and (min-width: 992px) {
		grid-template-columns: auto 1fr;
	}
`;

export const Form = styled.form`
	position: relative;

	.close-button {
		position: absolute;
		top: 1rem;
		right: 1rem;
		color: ${colors.neutralButton};
	}

	.modal-content {
		display: grid;
		grid-template-columns: 1fr;
		gap: 2rem;
		margin: 0 auto 2rem;
		max-width: 41.25rem;
		padding: 3rem 1rem 1rem;

		.avatar-element {
			justify-self: center;
		}

		&__header {
			display: grid;
			grid-template-columns: 1fr;
			gap: 0.5rem;
			margin-bottom: 1rem;
		}

		&__section {
			display: grid;
			grid-template-columns: 1fr;
			gap: 1.5rem;
		}
	}

	.modal-footer {
		display: flex;
		justify-content: flex-end;
		padding: 1.5rem;
		border-top: 1px solid ${colors.neutralForm};
	}
`;
