import React from 'react';
import PropTypes from 'prop-types';

import { Navigation } from '../../components';

const links = [
	{ id: 1, to: '/teacher/dashboard', label: 'Dashboard', icon: ['fal', 'tachometer'] },
	{ id: 2, label: 'Browse' },
	{ id: 3, to: '/teacher/directory/resources', label: 'Directory', icon: ['fal', 'books'] },
	{ id: 4, to: '/teacher/saved', label: 'Saved', icon: ['fal', 'bookmark'] },
	{ id: 5, label: 'Activity' },
	{ id: 6, to: '/teacher/requests', label: 'Requests', icon: ['fal', 'file-plus'] },
	{ id: 7, to: '/teacher/bookings', label: 'Bookings', icon: ['fal', 'envelope'] },
	{ id: 8, to: '/teacher/information', label: 'Information', icon: ['fal', 'info-circle'] },
	{ id: 9, to: '/teacher/messages', label: 'Messages', icon: ['fal', 'comments'] },
	{ id: 10, label: 'Account' },
	{ id: 11, to: '/teacher/groups', label: 'Groups', icon: ['fal', 'users'] },
	{ id: 12, to: '/teacher/school', label: 'School', icon: ['fal', 'school'] },
	{ id: 13, to: '/teacher/reports', label: 'Reports', icon: ['fal', 'chart-bar'] },
];

export const TeacherNavigation = ({ children }) => {
	return (
		<Navigation links={links} logoLink="/teacher/dashboard">
			{children}
		</Navigation>
	);
};
TeacherNavigation.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
