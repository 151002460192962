import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as S from './DemographicsCard.styles';
import { Card, ModalCreateGroup, Typography } from '../../../../components';

const DemographicsCard = ({ className, group, setGroup }) => {
	const [isOpen, setIsOpen] = useState(false);

	function handleOpenModal() {
		setIsOpen(true);
	}

	function handleCloseModal() {
		setIsOpen(false);
	}

	return (
		<Card className={className} title="Demographics" transparentHeaderBorder={true} actions={[{ id: 1, label: 'Edit', size: 'small', variant: 'outline', icon: { source: ['fal', 'edit'] }, onClick: handleOpenModal }]}>
			<S.DetailWrapper>
				<div className="element-info">
					<Typography className="element-info__title" tag="h6" weight="semibold">
						# of Students
					</Typography>
					<Typography className="element-info__text" tag="p">
						{group.totalStudents}
					</Typography>
				</div>
				<div className="element-info">
					<Typography className="element-info__title" tag="h6" weight="semibold">
						Minimum Age
					</Typography>
					<Typography className="element-info__text" tag="p">
						{group.ageRangeMin}
					</Typography>
				</div>
				<div className="element-info">
					<Typography className="element-info__title" tag="h6" weight="semibold">
						Maximum Age
					</Typography>
					<Typography className="element-info__text" tag="p">
						{group.ageRangeMax}
					</Typography>
				</div>
			</S.DetailWrapper>
			<S.DemographicsWrapper>
				<S.OneLineWrapper>
					<S.TableWrapper>
						<Typography tag="h5" weight="bold">
							Gender
						</Typography>
						<div className="table-info">
							<div className="table-info__header">
								<Typography className="table-info__header-item" tag="h6" weight="semibold">
									Gender
								</Typography>
								<Typography className="table-info__header-item" tag="h6" weight="semibold">
									# of Students
								</Typography>
							</div>
							<div className="table-info__body">
								<div className="table-info__body-row">
									<Typography tag="h6">Female</Typography>
									<Typography tag="h6">{group.genderFemale}</Typography>
								</div>
								<div className="table-info__body-row">
									<Typography tag="h6">Male</Typography>
									<Typography tag="h6">{group.genderMale}</Typography>
								</div>
								<div className="table-info__body-row">
									<Typography tag="h6">Other</Typography>
									<Typography tag="h6">{group.genderOther}</Typography>
								</div>
							</div>
						</div>
					</S.TableWrapper>
					<S.TableWrapper>
						<Typography tag="h5" weight="bold">
							Ethnicity
						</Typography>
						<div className="table-info">
							<div className="table-info__header">
								<Typography className="table-info__header-item" tag="h6" weight="semibold">
									Ethnicity
								</Typography>
								<Typography className="table-info__header-item" tag="h6" weight="semibold">
									# of Students
								</Typography>
							</div>
							<div className="table-info__body">
								<div className="table-info__body-row">
									<Typography tag="h6">Hispanic</Typography>
									<Typography tag="h6">{group.ethnicityHispanic}</Typography>
								</div>
								<div className="table-info__body-row">
									<Typography tag="h6">Non-Hispanic</Typography>
									<Typography tag="h6">{group.ethnicityNonHispanic}</Typography>
								</div>
							</div>
						</div>
					</S.TableWrapper>
				</S.OneLineWrapper>
				<S.TableWrapper>
					<Typography tag="h5" weight="bold">
						Race
					</Typography>
					<div className="table-info">
						<div className="table-info__header">
							<Typography className="table-info__header-item" tag="h6" weight="semibold">
								Race
							</Typography>
							<Typography className="table-info__header-item" tag="h6" weight="semibold">
								# of Students
							</Typography>
						</div>
						<div className="table-info__body">
							<div className="table-info__body-row">
								<Typography tag="h6">White</Typography>
								<Typography tag="h6">{group.raceWhite}</Typography>
							</div>
							<div className="table-info__body-row">
								<Typography tag="h6">Black or African American</Typography>
								<Typography tag="h6">{group.raceBlack}</Typography>
							</div>
							<div className="table-info__body-row">
								<Typography tag="h6">American Indian or Alaska Native</Typography>
								<Typography tag="h6">{group.raceNativeAmerican}</Typography>
							</div>
							<div className="table-info__body-row">
								<Typography tag="h6">Asian</Typography>
								<Typography tag="h6">{group.raceAsian}</Typography>
							</div>
							<div className="table-info__body-row">
								<Typography tag="h6">Native Hawaiian and Pacific Islander</Typography>
								<Typography tag="h6">{group.racePacificIslander}</Typography>
							</div>
							<div className="table-info__body-row">
								<Typography tag="h6">Some Other Race</Typography>
								<Typography tag="h6">{group.raceOther}</Typography>
							</div>
						</div>
					</div>
				</S.TableWrapper>
			</S.DemographicsWrapper>

			<ModalCreateGroup isOpen={isOpen} onRequestClose={handleCloseModal} objectToEdit={group} editCallback={setGroup} />
		</Card>
	);
};

DemographicsCard.propTypes = {
	className: PropTypes.string,
	group: PropTypes.object.isRequired,
	setGroup: PropTypes.func.isRequired,
};

export default DemographicsCard;
