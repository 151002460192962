import React, { forwardRef, useState, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { Controller, useForm } from 'react-hook-form';

import { Avatar, Button, IconButton, Select, TextArea, TextInput, Typography } from '../';
import { errorHandler } from '../../services/authService';
import { createUpdate } from '../../services/business';
import { uploadProfilePicture } from '../../services/media';
import { required, urlOnly } from '../../utils/form-default-errors';
import * as S from './EditBusinessModal.styles';
import { CAREER_CLUSTERS } from '../../../constants/general.constants';

export const EditBusinessModal = forwardRef(({ company, dispatch }, ref) => {
	const [isOpen, setIsOpen] = useState(false);
	const [avatar, setAvatar] = useState(null);
	const [avatarFile, setAvatarFile] = useState(null);

	const {
		handleSubmit,
		control,
		setValue,
		register,
		reset,
		formState: { errors, isSubmitting },
	} = useForm();

	const handleOpenModal = () => {
		setValue('businessId', company.id);
		setValue('businessName', company?.name);
		setValue('bio', company?.bio);
		setValue('website', company?.website);
		setValue('linkedin', company?.linkedin);
		setValue('facebook', company?.facebook);
		setValue('instagram', company?.instagram);
		setValue('twitter', company?.twitter);
		setValue('careerClusters', company?.careerClusters || []);
		setAvatar(company?.logoObj?.[200]);
		setAvatarFile(null);
		setIsOpen(true);
	};

	const handleCloseModal = () => {
		setIsOpen(false);
		setTimeout(() => {
			reset();
		}, 200);
	};

	async function asyncCaller(data) {
		let logoObj;

		if (avatarFile) {
			const formData = new FormData();
			formData.append('file', avatarFile);
			const { data: uploadResult } = await uploadProfilePicture(formData);
			logoObj = uploadResult;
		} else {
			logoObj = company?.logoObj;
		}

		const {
			data: { business },
		} = await createUpdate({ ...data, linkedin: data.linkedin || null, facebook: data.facebook || null, instagram: data.instagram || null, twitter: data.twitter || null, logoObj });

		dispatch({ type: 'SET_BUSINESS_PROFILE', payload: business });
		dispatch({ type: 'SET_COMPANY', payload: business });
		handleCloseModal();
	}

	const onSubmit = (e) => {
		handleSubmit(asyncCaller)(e).catch(errorHandler);
	};

	useImperativeHandle(
		ref,
		() => ({
			open: handleOpenModal,
			close: handleCloseModal,
		}),
		[handleOpenModal, handleCloseModal]
	);

	return (
		<ReactModal className="edit-company-account-modal" ref={ref} closeTimeoutMS={200} isOpen={isOpen} onRequestClose={handleCloseModal}>
			<S.Form onSubmit={onSubmit}>
				<IconButton className="close-button" type="button" icon={['fal', 'times']} onClick={handleCloseModal} />
				<div className="modal-content">
					<header className="modal-content__header">
						<Typography tag="h2" weight="extrablack" center>
							Edit Company Profile
						</Typography>
						<Typography tag="p" center>
							Please update your company profile information here.
						</Typography>
					</header>

					<section className="modal-content__section">
						<Avatar className="avatar-element" avatar={avatar} setAvatar={setAvatar} file={avatarFile} setFile={setAvatarFile} />

						<TextInput
							label="Name"
							placeholder="Name"
							error={errors.businessName}
							{...register('businessName', {
								required: required('Name'),
							})}
						/>

						<TextArea
							label="Bio"
							placeholder="Bio"
							error={errors.bio}
							{...register('bio', {
								required: required('Bio'),
							})}
						/>

						<TextInput
							label="Website"
							placeholder="Website"
							error={errors.website}
							{...register('website', {
								required: required('Website'),
								pattern: urlOnly('Website'),
							})}
						/>

						<TextInput
							label="LinkedIn"
							placeholder="LinkedIn"
							error={errors.linkedin}
							{...register('linkedin', {
								pattern: urlOnly('LinkedIn'),
							})}
						/>

						<TextInput
							label="Facebook"
							placeholder="Facebook"
							error={errors.facebook}
							{...register('facebook', {
								pattern: urlOnly('Facebook'),
							})}
						/>

						<TextInput
							label="Instagram"
							placeholder="Instagram"
							error={errors.instagram}
							{...register('instagram', {
								pattern: urlOnly('Instagram'),
							})}
						/>

						<TextInput
							label="Twitter"
							placeholder="Twitter"
							error={errors.twitter}
							{...register('twitter', {
								pattern: urlOnly('Twitter'),
							})}
						/>

						<Controller
							name="careerClusters"
							control={control}
							render={({ field: { onChange, value } }) => (
								<Select
									isMulti
									containerClassName="custom-select"
									label="Career Clusters"
									id="careerClusters"
									placeholder="Career Clusters"
									error={errors?.careerClusters}
									options={CAREER_CLUSTERS}
									value={value?.map((val) => CAREER_CLUSTERS.find((c) => c.value === val)) || ''}
									onChange={(val) => onChange(val?.map((val) => val.value))}
								/>
							)}
						/>
					</section>
				</div>

				<div className="modal-footer">
					<Button>
						<Typography variation="button-medium" weight="bold">
							{isSubmitting ? 'Loading' : 'Save'}
						</Typography>
					</Button>
				</div>
			</S.Form>
		</ReactModal>
	);
});

EditBusinessModal.displayName = 'EditBusinessModal';

EditBusinessModal.propTypes = {
	company: PropTypes.shape(),
	dispatch: PropTypes.func,
};
