import React, { useEffect, useState } from 'react';

import { BusinessNavigation, Button, Card, IconButton, PageHeader, Tab, Typography } from '../../components';
import * as S from './BusinessCompany.styles';
import { ProfileTab } from './ProfileTab';
import { StaffTab } from './StaffTab';
import { META_TITLE } from '../../../constants/general.constants';
import { useQuery } from '../../hooks/useQuery';

const BusinessCompany = () => {
	const query = useQuery();
	const [tabs, setTabs] = useState([
		{
			id: 1,
			label: 'Profile',
			component: <ProfileTab />,
			active: query.get('tabId') === '1',
		},
		{
			id: 2,
			label: 'Staff',
			component: <StaffTab />,
			active: query.get('tabId') === '2',
		},
	]);

	useEffect(() => {
		// Set document title
		document.title = `Company Account | ${META_TITLE}`;
	}, []);

	return (
		<BusinessNavigation>
			<PageHeader title="Company Account" />
			<S.Wrapper>
				<Tab tabs={tabs} setTabs={setTabs} />
			</S.Wrapper>
		</BusinessNavigation>
	);
};

export default BusinessCompany;
