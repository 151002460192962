import React, { useEffect, useState, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';

import * as S from '../../CreateResource.styles';
import { ACCEPTABLE_FILE_TYPES } from '../../../../../constants/general.constants';
import { fetchTemplates } from '../../../../services/requests/createRequestService';
import { Typography, Spinner, TextInput, TextArea, IconButton } from '../../../../components';
import { required } from '../../../../utils/form-default-errors';
import { bytesToSize } from '../../../../utils/filesize-formatter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const FilesStep = () => {
	const [pageStatus, setPageStatus] = useState('idle');
	const [templates, setTemplates] = useState([]);

	const {
		register,
		setValue,
		getValues,
		watch,
		formState: { errors },
	} = useFormContext();

	const privateFilesDropzone = useDropzone({
		onDrop: useCallback((acceptedFiles) => acceptedFiles.length > 0 && setValue('privateFiles', formatFileArrayValue('privateFiles', acceptedFiles)), []),
		accept: ACCEPTABLE_FILE_TYPES.RESOURCE_MEDIA.MIME_TYPES,
	});
	const publicFilesDropzone = useDropzone({
		onDrop: useCallback((acceptedFiles) => acceptedFiles.length > 0 && setValue('publicFiles', formatFileArrayValue('publicFiles', acceptedFiles)), []),
		accept: ACCEPTABLE_FILE_TYPES.RESOURCE_MEDIA.MIME_TYPES,
	});

	const privateFiles = watch('privateFiles');
	const publicFiles = watch('publicFiles');

	const removeIndexFromArray = (arrayName, index) =>
		setValue(
			arrayName,
			getValues(arrayName).filter((_, i) => i !== index)
		);

	function formatFileArrayValue(arrayName, files) {
		if (getValues(arrayName)) {
			const existingFiles = getValues(arrayName);
			return existingFiles.concat(files.filter((file) => !existingFiles.find((existingFile) => existingFile.name === file.name)));
		}
		return Array.from(files);
	}

	const renderFileTemplatesWrapper = () => {
		switch (pageStatus) {
			case 'loading':
				return <Spinner />;
			case 'success':
				return templates.length > 0 ? (
					<S.FileTemplatesWrapper>
						<Typography tag="h3" weight="bold">
							Downloadable File Templates
						</Typography>
						<Typography tag="p" variation="2">
							Download a file template to help get you started.
						</Typography>

						<div className="file-templates-container">
							{templates.map((template) => (
								<a key={template.id} href={template.fileUrl} download>
									<FontAwesomeIcon icon={['fal', 'file']} size="2x" />
									<div>
										<Typography tag="p" variation="3">
											{template.fileExtension.toUpperCase()}
										</Typography>
										<Typography tag="h5" weight="bold">
											{template.fileTitle}
										</Typography>
										<Typography tag="p" variation="2">
											{template.fileDescription}
										</Typography>
									</div>
									<FontAwesomeIcon icon={['fal', 'arrow-to-bottom']} />
								</a>
							))}
						</div>
					</S.FileTemplatesWrapper>
				) : null;
			default:
				return null;
		}
	};

	async function initializeData() {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchTemplates({ type: 'file', resourceType: 'appointment', category: 'Career Fair' });

			setTemplates(result.templates);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}

	useEffect(() => {
		if (pageStatus === 'idle') {
			initializeData();
		}
	}, [pageStatus]);

	return (
		<>
			<Typography tag="h2" weight="extrablack" center>
				Files
			</Typography>
			<Typography tag="p" center>
				Add in files to attach with your request.
			</Typography>

			<div className="fields-wrapper">
				{renderFileTemplatesWrapper()}

				<S.FilesListWrapper>
					<header>
						<Typography tag="h3" weight="bold">
							Private Files
						</Typography>
						<Typography tag="p" variation="2">
							Upload files associated with the request that only you will see.
						</Typography>
					</header>

					<S.FileListDropzone {...privateFilesDropzone.getRootProps()} isDragActive={privateFilesDropzone.isDragActive} isDragAccept={privateFilesDropzone.isDragAccept} isDragReject={privateFilesDropzone.isDragReject}>
						<input {...privateFilesDropzone.getInputProps()} />

						<div className="upload-placeholder">
							<div className="upload-placeholder__picture">
								<FontAwesomeIcon icon={['fal', 'image']} size="2x" />
							</div>
							<Typography tag="p" variation="2" center className="upload-placeholder__paragraph">
								Drag and drop files here, or <strong>browse</strong>
								<br />
								Supports PDF, Doc, PPT, JPG, PNG
							</Typography>
						</div>
					</S.FileListDropzone>

					<div className="files-wrapper">
						{privateFiles?.map((file, index) => (
							<div key={file.name} className="files-wrapper__item">
								<header>
									<Typography tag="h4" weight="bold">
										File {index + 1}
									</Typography>
									<IconButton type="button" icon={['fal', 'trash']} size={1} onClick={() => removeIndexFromArray('privateFiles', index)} />
								</header>

								<div className="files-wrapper__item__info">
									<Typography tag="h6" weight="semibold">
										File
									</Typography>
									<div className="files-wrapper__item__details">
										<FontAwesomeIcon icon={['fal', 'file']} size="lg" />
										<div>
											<Typography tag="p">{file.name}</Typography>
											<Typography tag="p" variation="2">
												{bytesToSize(file.size)}
											</Typography>
										</div>
									</div>
								</div>

								<TextInput label="Title" error={errors.privateFiles?.[index]?.title} {...register('privateFiles.' + index + '.title', { required: required('Title') })} />

								<TextArea label="Description" error={errors.privateFiles?.[index]?.description} {...register('privateFiles.' + index + '.description', { required: required('Description') })} />
							</div>
						))}
					</div>
				</S.FilesListWrapper>

				<S.FilesListWrapper>
					<header>
						<Typography tag="h3" weight="bold">
							Public Files
						</Typography>
						<Typography tag="p" variation="2">
							Upload files associated with the request that anyone can view and download.
						</Typography>
					</header>

					<S.FileListDropzone {...publicFilesDropzone.getRootProps()} isDragActive={publicFilesDropzone.isDragActive} isDragAccept={publicFilesDropzone.isDragAccept} isDragReject={publicFilesDropzone.isDragReject}>
						<input {...publicFilesDropzone.getInputProps()} />

						<div className="upload-placeholder">
							<div className="upload-placeholder__picture">
								<FontAwesomeIcon icon={['fal', 'image']} size="2x" />
							</div>
							<Typography tag="p" variation="2" center className="upload-placeholder__paragraph">
								Drag and drop files here, or <strong>browse</strong>
								<br />
								Supports PDF, Doc, PPT, JPG, PNG
							</Typography>
						</div>
					</S.FileListDropzone>

					<div className="files-wrapper">
						{publicFiles?.map((file, index) => (
							<div key={file.name} className="files-wrapper__item">
								<header>
									<Typography tag="h4" weight="bold">
										File {index + 1}
									</Typography>
									<IconButton type="button" icon={['fal', 'trash']} size={1} onClick={() => removeIndexFromArray('publicFiles', index)} />
								</header>

								<div className="files-wrapper__item__info">
									<Typography tag="h6" weight="semibold">
										File
									</Typography>
									<div className="files-wrapper__item__details">
										<FontAwesomeIcon icon={['fal', 'file']} size="lg" />
										<div>
											<Typography tag="p">{file.name}</Typography>
											<Typography tag="p" variation="2">
												{bytesToSize(file.size)}
											</Typography>
										</div>
									</div>
								</div>

								<TextInput label="Title" error={errors.publicFiles?.[index]?.title} {...register('publicFiles.' + index + '.title', { required: required('Title') })} />

								<TextArea label="Description" error={errors.publicFiles?.[index]?.description} {...register('publicFiles.' + index + '.description', { required: required('Description') })} />
							</div>
						))}
					</div>
				</S.FilesListWrapper>
			</div>
		</>
	);
};
