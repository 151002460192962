import styled from 'styled-components';

import colors from '../../../../styles/colors';

export const Wrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 1rem;
	padding-bottom: 0.5rem;

	.element-info {
		&__title {
			color: ${colors.neutralText};
			margin-bottom: 0.25rem;
		}
		&__text {
			.success {
				color: ${colors.stateSuccess};
			}
			.danger {
				color: ${colors.stateDanger};
			}
			svg {
				margin-right: 0.3125rem;
			}
		}
	}

	@media screen and (min-width: 992px) {
		grid-template-columns: repeat(4, 1fr);
	}
`;
