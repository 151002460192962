import styled from 'styled-components';

import colors from '../../../../styles/colors';

export const Wrapper = styled.div``;

export const ListItem = styled.div`
	display: flex;
	flex-direction: column;
	border-top: 1px solid ${colors.neutralForm};
	margin-top: 0.75rem;
	padding-top: 0.75rem;

	.item-title {
		color: ${colors.neutralText};
	}

	& > * + * {
		margin-top: 0.5rem;
	}

	&:first-child {
		border: none;
		margin-top: 0;
		padding-top: 0;
	}

	&:last-child {
		padding-bottom: 0.25rem;
	}
`;
