import styled from 'styled-components';

import colors from '../../../../styles/colors';

export const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	gap: 2rem;
	padding-bottom: 0.5rem;

	.info-wrapper {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		gap: 1rem;
		align-self: flex-start;

		@media (min-width: 425px) {
			grid-template-columns: repeat(2, 1fr);
		}
		@media (min-width: 768px) {
			grid-template-columns: repeat(4, 1fr);
		}
	}

	.element-info {
		&__title {
			color: ${colors.neutralText};
			margin-bottom: 0.25rem;
		}
	}
`;
