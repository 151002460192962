import styled, { css } from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.section`
	display: grid;
	grid-template-columns: 1fr auto;
	grid-gap: 0.75rem;

	grid-template-areas: ${(props) => (props.showFilters ? "'search-input search-input''sortby-select filter-button''advanced-fields advanced-fields'" : "'search-input search-input''sortby-select sortby-select''advanced-fields advanced-fields'")};

	.search-input {
		grid-area: search-input;

		@media screen and (min-width: 768px) {
			& > div {
				max-width: 22rem;
			}
		}
	}
	.sortby-select {
		grid-area: sortby-select;
	}
	.filter-button {
		grid-area: filter-button;
	}
	.advanced-fields {
		position: relative;
		margin-bottom: 0.375rem;
		grid-area: advanced-fields;
	}

	@media screen and (min-width: 768px) {
		grid-template-columns: ${(props) => (props.action ? 'auto 1fr 28rem' : 'auto 1fr 14rem')};

		&.grid-1 {
			grid-template-areas:
				'filter-button search-input sortby-select'
				'advanced-fields advanced-fields advanced-fields';
		}
		&.grid-2 {
			grid-template-areas:
				'search-input search-input sortby-select'
				'advanced-fields advanced-fields advanced-fields';
		}
	}
`;

export const RightSide = styled.div`
	display: flex;
	align-items: center;

	& > * + * {
		margin-left: 0.75rem;
	}
`;

export const FieldWrapper = styled.div`
	flex: 1;
	display: flex;
	align-items: center;

	& > label {
		white-space: nowrap;
		color: ${colors.neutralText};
		margin: 0;
		margin-right: 0.5rem;
	}

	& > div {
		flex: 1;
	}
`;

export const FilterButton = styled.button.attrs({ type: 'button' })`
	display: flex;
	justify-content: center;
	align-items: center;
	appearance: none;
	padding: 0.5625rem 0.75rem;
	border: 1px solid ${colors.neutralForm};
	border-radius: 0.375rem;
	color: ${colors.neutralButton};
	background-color: white;
	cursor: pointer;

	.filter-counter {
		justify-content: center;
		align-items: center;

		display: none;
		border-radius: 9999px;
		background-color: ${colors.brandPrimary};
		color: white;
		font-weight: bold;
		width: 20px;
		height: 20px;
		margin: -2px;
		font-size: 0.625rem;
	}

	& > svg {
		width: 1rem !important;
		height: 1rem !important;
	}

	& > p {
		line-height: 1rem;
		margin-left: 0.5rem;
	}

	${({ isOpen }) =>
		isOpen &&
		css`
			color: ${colors.brandPrimary};
			border-color: ${colors.neutralButton};
			background-color: ${colors.neutralForm};
		`}

	${({ isActive }) =>
		isActive &&
		css`
			.filter-counter {
				display: flex;
			}

			& > svg {
				display: none;
			}
		`}
`;

export const AdvancedFiltersWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin: -0.375rem;

	${({ isOpen }) =>
		!isOpen &&
		css`
			display: none;
		`}
`;

export const SingleFilterWrapper = styled.div`
	margin: 0.375rem;
	overflow: hidden;

	.popover-button {
		position: relative;
		height: 2.25rem;
		display: flex;
		max-width: 100%;
		justify-content: center;
		align-items: center;
		appearance: none;
		padding: 0.5625rem 0.75rem;
		border: 1px solid ${colors.neutralForm};
		border-radius: 0.375rem;
		color: ${colors.neutralButton};
		background-color: white;
		cursor: pointer;

		& > p {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		&.active {
			color: ${colors.brandPrimary};
			border-color: ${colors.neutralButton};
			background-color: ${colors.neutralForm};
		}

		& > svg {
			margin-left: 0.5rem;
		}
	}

	.popover-panel {
		position: absolute;
		width: 100%;
		max-width: 25rem;
		z-index: 2;
		background-color: white;
		border: 1px solid ${colors.neutralForm};
		border-radius: 0.375rem;
		box-shadow: ${colors.normalShadow};
		max-height: 20rem;
		overflow-y: auto;
	}

	.popover-panel-wrapper {
		margin: 1rem 1.5rem;

		.checkbox-list-wrapper {
			display: grid;
			gap: 0.75rem;
			grid-template-columns: 1fr;

			.checkbox-field {
				display: flex;
				align-items: center;

				& > * + * {
					padding-left: 0.75rem;
				}

				label {
					font-size: 1rem;
					line-height: 1.375rem;
					font-weight: 400;
					color: black;
					cursor: pointer;
				}
			}
		}

		&.with-range {
			margin-bottom: 0;
		}
	}
`;
