import React, { forwardRef, useContext, useRef, useState, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ROLES } from '../../../../constants/general.constants';
import { Card, Typography, Button, IconButton, TextInput, TextArea, EmptyComponent } from '../../../components';
import * as S from './LinksCard.styles';
import { formatPayloadBeforeUpdate, InformationDetailsContext } from '../InformationDetails';
import { useSelector } from 'react-redux';
import ReactModal from 'react-modal';
import { errorHandler } from '../../../services/authService';
import { useFieldArray, useForm } from 'react-hook-form';
import { v4 } from 'uuid';
import { required, urlOnly } from '../../../utils/form-default-errors';
import { createUpdateResource } from '../../../services/resources';

const LinksEditModal = forwardRef((_, ref) => {
	const {
		state: { resource },
		dispatch,
	} = useContext(InformationDetailsContext);
	const [isOpen, setIsOpen] = useState(false);

	const {
		register,
		setValue,
		getValues,
		watch,
		handleSubmit,
		reset,
		control,
		formState: { errors, isSubmitting },
	} = useForm();
	const { fields, append, remove } = useFieldArray({ control, name: 'links' });

	function handleAddLink() {
		append({ id: v4() });
	}

	function handleRemoveLink(index) {
		remove(index);
	}

	const handleOpen = () => {
		setValue(
			'links',
			resource.links.map((link) => ({
				...link,
				url: link.displayUrl,
			}))
		);
		setIsOpen(true);
	};

	const handleClose = () => {
		setIsOpen(false);
		setTimeout(() => {
			reset();
		}, 200);
	};

	async function asyncCaller(data) {
		const newResource = formatPayloadBeforeUpdate(resource, { ...data });

		const {
			data: { result },
		} = await createUpdateResource(newResource);
		dispatch({ type: 'SET_RESOURCE', payload: result.resource });

		handleClose();
	}

	const onSubmit = (e) => handleSubmit(asyncCaller)(e).catch(errorHandler);

	useImperativeHandle(ref, () => ({
		open: handleOpen,
		close: handleClose,
	}));

	return (
		<ReactModal ref={ref} closeTimeoutMS={200} isOpen={isOpen} onRequestClose={handleClose}>
			<S.Form onSubmit={onSubmit}>
				<IconButton className="close-button" type="button" icon={['fal', 'times']} onClick={handleClose} />
				<S.InfoWrapper>
					<Typography tag="h2" weight="extrablack" center>
						Edit Links
					</Typography>
					<Typography tag="p" center>
						Please edit the links below to update the resource.
					</Typography>
				</S.InfoWrapper>
				<S.FieldsWrapper>
					<S.LinksWrapper>
						{fields.map(({ id }, index) => (
							<div key={id} className="link-item">
								<Typography tag="h3" weight="bold">
									Link {index + 1}
								</Typography>
								<Button className="link-remove" variant="text" type="button" onClick={() => handleRemoveLink(index)}>
									<Typography variation="button-medium" weight="bold">
										Remove
									</Typography>
								</Button>
								<TextInput
									label="Link"
									id={`${index}-url`}
									error={errors?.links?.[index]?.url}
									{...register(`links.${index}.url`, {
										required: required('Link'),
										pattern: urlOnly('Link'),
									})}
								/>
								<TextInput
									label="Title"
									id={`${index}-title`}
									error={errors?.links?.[index]?.title}
									{...register(`links.${index}.title`, {
										required: required('Title'),
									})}
								/>
								<TextArea
									label="Description"
									id={`${index}-description`}
									error={errors?.links?.[index]?.description}
									{...register(`links.${index}.description`, {
										required: required('Description'),
									})}
								/>
							</div>
						))}

						<div className="add-link-wrapper">
							<Button variant="text" type="button" onClick={handleAddLink}>
								<FontAwesomeIcon icon={['fal', 'plus']} />
								<Typography variation="button-medium" weight="bold">
									Add Link
								</Typography>
							</Button>
						</div>
					</S.LinksWrapper>
				</S.FieldsWrapper>
				<S.ButtonsWrapper>
					<Button type="submit">
						<Typography weight="bold" variation="button-medium">
							{isSubmitting ? 'Loading' : 'Save'}
						</Typography>
					</Button>
				</S.ButtonsWrapper>
			</S.Form>
		</ReactModal>
	);
});
LinksEditModal.displayName = 'LinksEditModal';

export const LinksCard = ({ className, role }) => {
	const {
		state: { resource },
	} = useContext(InformationDetailsContext);
	const user = useSelector((state) => state.user.value);
	const modalRef = useRef(null);

	const handleEditClick = () => {
		modalRef.current.open();
	};

	return (
		<Card
			title="Links"
			className={className}
			transparentHeaderBorder
			actions={(role != null && resource.business != null && resource.business.id === user?.business?.id) || role === ROLES.ADMIN || role === ROLES.SUPER ? [{ id: 1, label: 'Edit', size: 'small', variant: 'outline', onClick: handleEditClick, icon: { source: ['fal', 'edit'] } }] : []}
		>
			<S.Wrapper>
				{resource?.links?.length > 0 ? (
					resource.links.map((link) => (
						<div key={link.id} className="link-wrapper">
							<div className="link-wrapper__heading">
								<Typography tag="h5" weight="bold">
									{link.title}
								</Typography>
								<Button
									size="small"
									variant="text"
									onClick={() => {
										window.open(link.url);
									}}
								>
									<FontAwesomeIcon icon={['fal', 'external-link']} />
									<Typography variation="button-small" weight="bold">
										Visit Link
									</Typography>
								</Button>
							</div>
							<div className="link-wrapper__body">
								<div className="link-wrapper__element">
									<Typography className="link-wrapper__title" tag="h6" weight="semibold">
										Link
									</Typography>
									<Typography
										className="link-wrapper__value clickable"
										tag="p"
										onClick={() => {
											window.open(link.url);
										}}
									>
										{link.displayUrl}
									</Typography>
								</div>
								<div className="link-wrapper__element">
									<Typography className="link-wrapper__title" tag="h6" weight="semibold">
										Description
									</Typography>
									<Typography className="link-wrapper__value" tag="p">
										{link.description}
									</Typography>
								</div>
							</div>
						</div>
					))
				) : (
					<EmptyComponent title="No current links to show" />
				)}
			</S.Wrapper>
			<LinksEditModal ref={modalRef} />
		</Card>
	);
};
LinksCard.propTypes = {
	className: PropTypes.string,
	role: PropTypes.string,
};
