import React from 'react';
import PropTypes from 'prop-types';

import { Navigation } from '..';

const links = [
	{ id: 1, to: '/admin/dashboard', label: 'Dashboard', icon: ['fal', 'tachometer'] },
	{ id: 2, label: 'Browse' },
	{ id: 3, to: '/admin/directory/resources', label: 'Resource Directory', icon: ['fal', 'books'] },
	{ id: 4, to: '/admin/directory/requests', label: 'Request Directory', icon: ['fal', 'file-search'] },
	{ id: 5, label: 'Activity' },
	{ id: 6, to: '/admin/messages', label: 'Messages', icon: ['fal', 'comments'] },
	{ id: 7, to: '/admin/bookings', label: 'Bookings', icon: ['fal', 'envelope'] },
	{ id: 8, to: '/admin/requests', label: 'Requests', icon: ['fal', 'file-plus'] },
	{ id: 9, label: 'Admin' },
	{ id: 10, to: '/admin/users', label: 'Users', icon: ['fal', 'user'] },
	{ id: 11, to: '/admin/schools', label: 'Schools', icon: ['fal', 'school'] },
	{ id: 12, to: '/admin/companies', label: 'Companies', icon: ['fal', 'building'] },
	{ id: 13, to: '/admin/memberships', label: 'Memberships', icon: ['fal', 'id-card-alt'] },
	{ id: 14, to: '/admin/admins', label: 'Admins', icon: ['fal', 'users'] },
	{ id: 15, to: '/admin/templates', label: 'Templates', icon: ['fal', 'file'] },
	{ id: 16, to: '/admin/reports', label: 'Reports', icon: ['fal', 'chart-bar'] },
];

export const AdminNavigation = ({ children }) => {
	return (
		<Navigation links={links} logoLink="/admin/dashboard">
			{children}
		</Navigation>
	);
};
AdminNavigation.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
