import api from '../api';

export const updateUserProfile = (data) => api.post('/core/functions/updateUserProfile', data);

export const updateUserNotifications = (data) => api.post('/core/functions/updateUserNotifications', data);

export const updateUserDefaultGroup = (data) => api.post('/core/functions/updateUserDefaultGroup', data);

export const updateUserDefaultSearch = (data) => api.post('/core/functions/updateUserDefaultSearch', data);

export const checkEmailExists = (data) => api.post('/core/functions/checkEmailExists', data);

export const updateRequestPreferences = (data) => api.post('/core/functions/updateRequestPreferences', data);

export const fetchUsers = (data) => api.post('/core/functions/fetchUsers', data);

export const fetchUser = (data) => api.post('/core/functions/fetchUser', data);

export const removeUser = (data) => api.post('/core/functions/removeUser', data);

export const exportUsers = (data) => api.post('/core/functions/exportUsers', data);
