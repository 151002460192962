import React, { useContext } from 'react';

import { AdminUserDetailContext } from '../AdminUserDetail';
import { MetricsCards } from './MetricsCards';
import { AccountCard } from './AccountCard';
import { ProfileCard } from './ProfileCard';
import { CompanyCard } from './CompanyCard';
import * as S from './OverviewTab.styles';
import { ROLES } from '../../../../constants/general.constants';

export const OverviewTab = () => {
	const {
		state: { user },
	} = useContext(AdminUserDetailContext);

	function renderContent() {
		return (
			<S.Wrapper>
				<MetricsCards className="b1" />
				<ProfileCard className="b2" />
				<AccountCard className="b3" />
				{user.role === ROLES.BUSINESS && user.business && user.business.name && <CompanyCard className="b4" />}
			</S.Wrapper>
		);
	}

	return renderContent();
};
