import React, { forwardRef, useRef, useState, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { updateUser } from '../../../app/slices/user/userSlice';
import { updateUserProfile } from '../../../services/users';
import { uploadProfilePicture } from '../../../services/media';
import { errorHandler } from '../../../services/authService';
import { Avatar, Button, Card, IconButton, TextInput, Typography, EditBusinessUserProfileModal } from '../../../components';
import { required } from '../../../utils/form-default-errors';
import * as S from './ProfileCard.styles';

const ProfileCard = ({ className }) => {
	const user = useSelector((state) => state.user.value);
	const dispatch = useDispatch();

	const modalRef = useRef(null);

	const handleCardButtonClick = () => {
		modalRef.current.open();
	};

	return (
		<Card className={className} title="Profile" transparentHeaderBorder={true} actions={[{ id: 1, label: 'Edit', size: 'small', variant: 'outline', icon: { source: ['fal', 'edit'] }, onClick: handleCardButtonClick }]}>
			<S.Wrapper>
				<div className="avatar-wrapper">
					<Avatar avatar={user?.profileImageObj?.[200]} />
				</div>
				<div className="info-wrapper">
					<div className="element-info">
						<Typography className="element-info__title" tag="h6" weight="semibold">
							First Name
						</Typography>
						<Typography className="element-info__text" tag="p">
							{user?.firstName}
						</Typography>
					</div>
					<div className="element-info">
						<Typography className="element-info__title" tag="h6" weight="semibold">
							Last Name
						</Typography>
						<Typography className="element-info__text" tag="p">
							{user?.lastName}
						</Typography>
					</div>
					<div className="element-info">
						<Typography className="element-info__title" tag="h6" weight="semibold">
							Role
						</Typography>
						<Typography className="element-info__text capitalized" tag="p">
							{user?.businessPermissions.join(', ')}
						</Typography>
					</div>
				</div>
			</S.Wrapper>
			<EditBusinessUserProfileModal ref={modalRef} user={user} dispatch={dispatch} allowUpdatePermissions={false} currentUser={true} />
		</Card>
	);
};

ProfileCard.propTypes = {
	className: PropTypes.string,
};

export default ProfileCard;
