import React from 'react';

import { AccountCard } from './AccountCard';
import { ProfileCard } from './ProfileCard';
import * as S from './OverviewTab.styles';

export const OverviewTab = () => {
	function renderContent() {
		return (
			<S.Wrapper>
				<ProfileCard className="b1" />
				<AccountCard className="b2" />
			</S.Wrapper>
		);
	}
	return renderContent();
};
