import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useChat } from '../../../../hooks';
import { fetchMessages } from '../../../../services/messages';
import { cancelBooking } from '../../../../services/bookings';
import { Button, IconButton, Picture, Typography, MessageDialog, Chat } from '../../../../components';
import { BookingsContext } from '../ReceivedBookingsTab';
import * as S from './BookingChat.styles';
import { useSelector } from 'react-redux';

export const BookingChat = () => {
	const { state, dispatch } = useContext(BookingsContext);
	const user = useSelector((state) => state.user.value);
	const { selectedConversation } = state;
	const { messages, setMessages } = useChat(selectedConversation.id);
	const [cancelIsOpen, setCancelIsOpen] = useState(false);
	const [pageStatus, setPageStatus] = useState('loading');
	const history = useHistory();

	function handleOpenCancelModal() {
		setCancelIsOpen(true);
	}

	function handleCloseCancelModal() {
		setCancelIsOpen(false);
	}

	const handleCancelBooking = async () => {
		setCancelIsOpen(false);
		try {
			await cancelBooking({ bookingId: selectedConversation.id });
			dispatch({ type: 'SET_SELECTED_CONVERSATION', payload: { ...selectedConversation, status: 'incomplete' } });
			handleCloseCancelModal();
		} catch (error) {}
	};

	async function initializeData() {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchMessages({ page: 0, displayLimit: 100000, bookingId: selectedConversation.id, filterOptions: { received: true, sent: true } });
			setMessages(result.messages);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}

	useEffect(() => {
		// Load initial data
		initializeData();
	}, [selectedConversation]);

	const renderRecipientInfoWrapper = () => {
		if (selectedConversation.isCreator) {
			return (
				<S.InfoWrapper>
					<Picture className="avatar-image" src={selectedConversation.business.logoObj['200']} aspectRatio="1/1" alt="profile" />
					<div className="info-block">
						<Typography tag="h3" weight="bold">
							{selectedConversation.business.name}
						</Typography>
						<Typography tag="p" variation="2">
							{selectedConversation.resource.title}
						</Typography>
					</div>
					<IconButton
						className="info-button"
						icon={['fal', 'info-circle']}
						onClick={() => {
							history.push({ pathname: `/teacher/directory/business/${selectedConversation.business.id}`, state: { backUrl: window.location.pathname, backTitle: 'Back to Messages' } });
						}}
					/>
				</S.InfoWrapper>
			);
		} else if (Object.keys(selectedConversation.creatorBusiness).length > 0) {
			if (selectedConversation.creatorBusiness.id === user.business.id) {
				return (
					<S.InfoWrapper>
						<Picture className="avatar-image" src={selectedConversation.business.logoObj['200']} aspectRatio="1/1" alt="profile" />
						<div className="info-block">
							<Typography tag="h3" weight="bold">
								{selectedConversation.business.name}
							</Typography>
							<Typography tag="p" variation="2">
								{selectedConversation.resource.title}
							</Typography>
						</div>
						<IconButton
							className="info-button"
							icon={['fal', 'info-circle']}
							onClick={() => {
								history.push({ pathname: `/teacher/directory/business/${selectedConversation.business.id}`, state: { backUrl: window.location.pathname, backTitle: 'Back to Messages' } });
							}}
						/>
					</S.InfoWrapper>
				);
			} else {
				return (
					<S.InfoWrapper>
						<Picture className="avatar-image" src={selectedConversation.creatorBusiness.logoObj['200']} aspectRatio="1/1" alt="profile" />
						<div className="info-block">
							<Typography tag="h3" weight="bold">
								{selectedConversation.creatorBusiness.name}
							</Typography>
							<Typography tag="p" variation="2">
								{selectedConversation.resource.title}
							</Typography>
						</div>
						<IconButton
							className="info-button"
							icon={['fal', 'info-circle']}
							onClick={() => {
								history.push({ pathname: `/teacher/directory/business/${selectedConversation.creatorBusiness.id}`, state: { backUrl: window.location.pathname, backTitle: 'Back to Messages' } });
							}}
						/>
					</S.InfoWrapper>
				);
			}
		} else {
			return (
				<S.InfoWrapper>
					<Picture className="avatar-image" src={selectedConversation.creator.profileImageObj['200']} aspectRatio="1/1" alt="profile" />
					<div className="info-block">
						<Typography tag="h3" weight="bold">
							{selectedConversation.creator.firstName} {selectedConversation.creator.lastName}
						</Typography>
						<Typography tag="p" variation="2">
							{selectedConversation.resource.title}
						</Typography>
					</div>
				</S.InfoWrapper>
			);
		}
	};

	return (
		<>
			<S.BookingChatWrapper>
				<S.ChatHeader>
					<S.BackButtonWrapper>
						<Button variant="text" size="small" onClick={() => dispatch({ type: 'CLEAR_SELECTED_CONVERSATION' })}>
							<FontAwesomeIcon icon={['fal', 'arrow-left']} />
							<Typography className="button-label" variation="button-small" weight="bold">
								Back to Messages
							</Typography>
						</Button>
					</S.BackButtonWrapper>
					{renderRecipientInfoWrapper()}
				</S.ChatHeader>
				<Chat loading={pageStatus === 'loading'} messages={messages} setMessages={setMessages} />
			</S.BookingChatWrapper>
			<MessageDialog
				isOpen={cancelIsOpen}
				onRequestClose={handleCloseCancelModal}
				title="Cancel Booking?"
				subtitle="Are you sure you want to cancel this booking? The business will be notified as well."
				icon={['fal', 'envelope']}
				actions={[
					{ id: 'back', label: 'Nevermind', variant: 'outline', variation: 'default' },
					{ id: 'cancel', label: 'Cancel Booking', variant: 'solid', variation: 'default', onClick: handleCancelBooking },
				]}
			/>
		</>
	);
};
