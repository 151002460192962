import React from 'react';
import PropTypes from 'prop-types';

import { Card, Typography } from '../../../../components';
import * as S from './OverviewCard.styles';

export const OverviewCard = ({ business }) => {
	return (
		<Card title="Overview" transparentHeaderBorder>
			<S.Wrapper>
				<S.Avatar src={business.logoObj['800']} alt="avatar" />
				<S.InfoWrapper>
					<div className="info-wrapper__elements">
						<Typography className="info-wrapper__title" tag="h6" weight="semibold">
							Bio
						</Typography>
						<Typography tag="p">{business.bio}</Typography>
					</div>
					<div className="info-wrapper__elements">
						<Typography className="info-wrapper__title" tag="h6" weight="semibold">
							Career Clusters
						</Typography>
						<div className="info-wrapper__badges-wrapper">
							{business.careerClusters.map((careerCluster, index) => (
								<div className="info-wrapper__badge" key={index}>
									{careerCluster}
								</div>
							))}
						</div>
					</div>
					<div className="info-wrapper__grid-wrapper">
						{business.website && business.website !== '' && (
							<div className="info-wrapper__elements">
								<Typography className="info-wrapper__title" tag="h6" weight="semibold">
									Website
								</Typography>
								<S.LinkTypography
									tag="p"
									onClick={() => {
										window.open(business.website);
									}}
								>
									{business.website}
								</S.LinkTypography>
							</div>
						)}
						{business.linkedin && business.linkedin !== '' && (
							<div className="info-wrapper__elements">
								<Typography className="info-wrapper__title" tag="h6" weight="semibold">
									LinkedIn
								</Typography>
								<S.LinkTypography
									tag="p"
									onClick={() => {
										window.open(business.linkedin);
									}}
								>
									{business.linkedin}
								</S.LinkTypography>
							</div>
						)}
						{business.facebook && business.facebook !== '' && (
							<div className="info-wrapper__elements">
								<Typography className="info-wrapper__title" tag="h6" weight="semibold">
									Facebook
								</Typography>
								<S.LinkTypography
									tag="p"
									onClick={() => {
										window.open(business.facebook);
									}}
								>
									{business.facebook}
								</S.LinkTypography>
							</div>
						)}
						{business.instagram && business.instagram !== '' && (
							<div className="info-wrapper__elements">
								<Typography className="info-wrapper__title" tag="h6" weight="semibold">
									Instagram
								</Typography>
								<S.LinkTypography
									tag="p"
									onClick={() => {
										window.open(business.instagram);
									}}
								>
									{business.instagram}
								</S.LinkTypography>
							</div>
						)}
						{business.twitter && business.twitter !== '' && (
							<div className="info-wrapper__elements">
								<Typography className="info-wrapper__title" tag="h6" weight="semibold">
									Twitter
								</Typography>
								<S.LinkTypography
									tag="p"
									onClick={() => {
										window.open(business.twitter);
									}}
								>
									{business.twitter}
								</S.LinkTypography>
							</div>
						)}
					</div>
				</S.InfoWrapper>
			</S.Wrapper>
		</Card>
	);
};

OverviewCard.propTypes = {
	business: PropTypes.object,
};
