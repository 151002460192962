import styled from 'styled-components';

import colors from '../../../styles/colors';

export const Form = styled.form`
	position: relative;
	padding: 3rem 1.25rem 1.75rem;

	.close-button {
		position: absolute;
		top: 1rem;
		right: 1rem;
		color: ${colors.neutralButton};
	}
`;

export const InfoWrapper = styled.div`
	margin: 0 auto 0.75rem;
	width: 100%;
	max-width: 34.5rem;

	& > * + * {
		margin-top: 0.75rem;
	}
`;

export const FieldsWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	gap: 1rem;
	width: 100%;
	max-width: 29rem;
	margin: 0 auto;
`;

export const ButtonsWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 1.75rem;

	& > * + * {
		margin-left: 1rem;
	}
`;
