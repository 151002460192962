import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { updateUser, clearUser } from '../../app/slices/user/userSlice';
import logo from '../../assets/ncbce-logo-alt.png';
import { errorHandler, logoutUser } from '../../services/authService';
import { teacherOnboarding } from '../../services/onboarding';
import { Button, Typography, Avatar, TextInput, Select } from '../../components';
import * as S from './SignupTeacherOverview.styles';
import { required } from '../../utils/form-default-errors';
import { TEACHER_ROLES_OPTIONS, META_TITLE } from '../../../constants/general.constants';
import { clearSavedStates } from '../../app/slices/filterSearch/filterSearchSlice';

const SignupTeacherOverview = () => {
	const user = useSelector((state) => state.user.value);
	const dispatch = useDispatch();
	const history = useHistory();
	const {
		handleSubmit,
		control,
		watch,
		formState: { errors, isSubmitting },
	} = useForm({
		defaultValues: {
			roleName: TEACHER_ROLES_OPTIONS[0].value,
			primarySchoolId: user.schools?.[0]?.id,
		},
	});

	useEffect(() => {
		// Set document title
		document.title = `Teacher Sign Up | ${META_TITLE}`;
	}, []);

	async function asyncCaller(data) {
		const formData = new FormData();
		formData.append('roleName', data.roleName);
		formData.append('primarySchoolId', data.primarySchoolId);
		if (avatar) {
			formData.append('file', avatarFile);
		}
		const {
			data: { user },
		} = await teacherOnboarding(formData);
		updateUser(user);
		history.push('/sign-up/teacher/groups');
	}

	function onSubmit(e) {
		handleSubmit(asyncCaller)(e).catch(errorHandler);
	}

	const backToHome = () => {
		logoutUser().then(() => {
			dispatch(clearUser());
			dispatch(clearSavedStates());
			history.push('/login');
		});
	};

	const selectedSchool = user.schools?.find((school) => school.id === watch('primarySchoolId'));

	const [avatar, setAvatar] = useState(null);
	const [avatarFile, setAvatarFile] = useState(null);

	const primarySchoolOptions = [...user.schools];

	return (
		<S.Wrapper>
			<S.Header>
				<div className="flex-1">
					<Button
						variant="text"
						size="small"
						onClick={() => {
							backToHome();
						}}
					>
						<FontAwesomeIcon icon={['fal', 'arrow-left']} />
						<Typography className="button-label" variation="button-small">
							Back to Home
						</Typography>
					</Button>
				</div>
				<div className="centered">
					<S.Picture>
						<img src={logo} alt="NCBCE logo" />
					</S.Picture>
				</div>
				<div className="flex-1" />
			</S.Header>
			<S.Container>
				<Typography tag="h1" weight="extrablack" center>
					Confirm Account Information
				</Typography>
				<Typography tag="p" className="description" center>
					Review your profile and school information below.
				</Typography>

				<S.FormWrapper onSubmit={onSubmit}>
					<Typography tag="h4" weight="bold">
						Teacher Profile
					</Typography>
					<Avatar className="avatar-element" avatar={avatar} setAvatar={setAvatar} file={avatarFile} setFile={setAvatarFile} />

					<div className="form-group">
						<S.InformationCard>
							<div className="icon-wrapper">
								<FontAwesomeIcon icon="user" />
							</div>
							<div className="info-wrapper">
								<Typography tag="h5" weight="bold">
									{`${user.firstName} ${user.lastName}`}
								</Typography>
								<div className="info-line">
									<FontAwesomeIcon icon={['fal', 'envelope']} />
									<Typography tag="p" variation="2">
										{user.email}
									</Typography>
								</div>
							</div>
						</S.InformationCard>

						<TextInput label="Teacher UID" placeholder="Teacher UID" value={user.uid} readOnly />

						<Controller
							name="roleName"
							control={control}
							rules={{ required: required('Role') }}
							render={({ field: { onChange, value } }) => <Select label="Role" id="role-name" error={errors.roleName} options={TEACHER_ROLES_OPTIONS} value={TEACHER_ROLES_OPTIONS.find((c) => c.value === value) || ''} onChange={(val) => onChange(val.value)} />}
						/>
					</div>

					<div className="school-group">
						<Typography tag="h4" weight="bold">
							School
						</Typography>
						{user.schools?.length > 1 && (
							<Controller
								name="primarySchoolId"
								control={control}
								rules={{ required: required('School') }}
								render={({ field: { onChange, value } }) => (
									<Select
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
										label="School"
										id="primary-school-id"
										error={errors.primarySchoolId}
										options={primarySchoolOptions}
										value={primarySchoolOptions.find((c) => c.id === value) || ''}
										onChange={(val) => onChange(val.id)}
									/>
								)}
							/>
						)}
						<S.InformationCard>
							<div className="icon-wrapper">
								<FontAwesomeIcon icon="school" />
							</div>
							<div className="info-wrapper">
								<Typography tag="h5" weight="bold">
									{selectedSchool?.name}
								</Typography>
								<div className="info-line">
									<FontAwesomeIcon icon={['fal', 'map-marker']} />
									<Typography tag="p" variation="2">
										{`${selectedSchool?.address.addressLine1}, ${selectedSchool?.address.state} ${selectedSchool?.address.postalCode}`}
									</Typography>
								</div>
								<div className="info-line">
									<FontAwesomeIcon icon={['fal', 'landmark']} />
									<Typography tag="p" variation="2">
										{selectedSchool?.address.city}
									</Typography>
								</div>
								<div className="info-line">
									<FontAwesomeIcon icon={['fal', 'school']} />
									<Typography tag="p" variation="2">
										{selectedSchool?.schoolCode}
									</Typography>
								</div>
							</div>
						</S.InformationCard>
					</div>

					<Button className="submit-button">
						<Typography variation="button-medium" weight="bold">
							{isSubmitting ? 'Loading...' : 'Continue'}
						</Typography>
					</Button>
				</S.FormWrapper>
			</S.Container>
		</S.Wrapper>
	);
};

export default SignupTeacherOverview;
