import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { fetchMessages } from '../../../../services/messages';
import { useChat } from '../../../../hooks';
import { Button, IconButton, Picture, Typography, Spinner, Badge, Chat } from '../../../../components';
import { RequestsContext, BADGE_STATUS } from '../PostedRequestsTab';
import * as S from './RequestChat.styles';

export const RequestChat = () => {
	const { state, dispatch } = useContext(RequestsContext);
	const { selectedConversation } = state;
	const { messages, setMessages } = useChat(selectedConversation.id);
	const [pageStatus, setPageStatus] = useState('loading');
	const history = useHistory();

	async function initializeData() {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchMessages({ page: 0, displayLimit: 100000, requestId: selectedConversation.request.id, responseId: selectedConversation.id, filterOptions: { received: true, sent: true } });
			setMessages(result.messages);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}

	useEffect(() => {
		// Load initial data
		initializeData();
	}, [selectedConversation]);

	return (
		<S.RequestChatWrapper>
			<S.ChatHeader>
				<S.BackButtonWrapper>
					<Button variant="text" size="small" onClick={() => dispatch({ type: 'CLEAR_SELECTED_CONVERSATION' })}>
						<FontAwesomeIcon icon={['fal', 'arrow-left']} />
						<Typography className="button-label" variation="button-small" weight="bold">
							Back to Messages
						</Typography>
					</Button>
				</S.BackButtonWrapper>
				<S.InfoWrapper>
					<Picture className="avatar-image" src={selectedConversation.business.logoObj['200']} aspectRatio="1/1" alt="profile" />
					<div className="info-block">
						<Typography tag="h3" weight="bold">
							{selectedConversation.business.name}
						</Typography>
						<Typography tag="p" variation="2">
							{selectedConversation.request.title}
						</Typography>
						{selectedConversation.status !== 'pending' && (
							<Badge small="small" type={BADGE_STATUS[selectedConversation.status].color} variant="outlined">
								{BADGE_STATUS[selectedConversation.status].label}
							</Badge>
						)}
					</div>
					<IconButton
						className="info-button"
						icon={['fal', 'info-circle']}
						onClick={() => {
							history.push({ pathname: `/teacher/directory/business/${selectedConversation.business.id}`, state: { backUrl: window.location.pathname, backTitle: 'Back to Messages' } });
						}}
					/>
				</S.InfoWrapper>
			</S.ChatHeader>
			<Chat loading={pageStatus === 'loading'} messages={messages} setMessages={setMessages} />
		</S.RequestChatWrapper>
	);
};
