import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import * as S from '../../CreateResource.styles';
import { DetailedCheckbox, TextArea, Typography, Select, TextInput } from '../../../../components';
import { LOCATION_TYPES, STATE_OPTIONS } from '../../../../../constants/general.constants';
import { required } from '../../../../utils/form-default-errors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LOCATION_TYPES_WITHOUT_IN_PERSON = LOCATION_TYPES.filter(({ id }) => id !== 'student' && id !== 'worksite');

export const LocationAltStep = () => {
	const {
		register,
		watch,
		control,
		formState: { errors },
	} = useFormContext();

	const locationTypes = watch('locationTypes');

	return (
		<>
			<Typography tag="h2" weight="extrablack" center>
				Location
			</Typography>
			<Typography tag="p" center>
				Select where you would your request to occur.
			</Typography>

			<div className="fields-wrapper">
				<S.LocationStep>
					<div>
						<Typography tag="h3" weight="bold">
							Location Type
						</Typography>
						<Typography tag="p" variation="2">
							Select all locations you want to have this resource.
						</Typography>
					</div>
					<S.LocationStepWrapper>
						{LOCATION_TYPES_WITHOUT_IN_PERSON.map(({ id, name, description, icon }) => (
							<DetailedCheckbox key={id} id={id} value={id} title={name} description={description} icon={icon} {...register('locationTypes', { required: required('Location Type') })} />
						))}
						{!!errors.locationTypes && (
							<div className="error-wrapper">
								<Typography tag="p" variation="2">
									{errors.locationTypes.message}
								</Typography>
							</div>
						)}
					</S.LocationStepWrapper>

					<S.SelectedLocations>
						<div>
							<Typography tag="h3" weight="bold">
								Location Details
							</Typography>
							<Typography tag="p" variation="2">
								Enter in more information about your locations.
							</Typography>
						</div>
						{locationTypes?.length === 0 && (
							<Typography tag="p" className="gray-color">
								No location types selected.
							</Typography>
						)}
						{!locationTypes ||
							(locationTypes.length >= 1 && (
								<>
									{locationTypes?.includes('virtual') && (
										<div className="step__location">
											<div className="step__location-icon-wrapper">
												<FontAwesomeIcon icon={['fal', 'camera']} size="lg" />
											</div>
											<div className="step__location-fields-wrapper">
												<Typography tag="h4" weight="bold">
													Virtual
												</Typography>
												<TextArea id="virtualLocationNotes" label="Location Notes" placeholder="Write a message..." error={errors?.virtualLocationNotes} {...register('virtualLocationNotes')} />
											</div>
										</div>
									)}
									{locationTypes?.includes('inperson') && (
										<div className="step__location">
											<div className="step__location-icon-wrapper">
												<FontAwesomeIcon icon={['fal', 'building']} size="lg" />
											</div>
											<div className="step__location-fields-wrapper">
												<Typography tag="h4" weight="bold">
													In Person
												</Typography>
												<TextInput id="inPersonLocationAddress" label="Address" placeholder="Address" error={errors?.inPersonLocationAddress?.addressLine1} {...register('inPersonLocationAddress.addressLine1', { required: required('Address') })} />
												<div className="step__location-field-row">
													<TextInput id="inpersonCity" label="City" placeholder="City" error={errors?.inPersonLocationAddress?.city} {...register('inPersonLocationAddress.city', { required: required('City') })} />
													<Controller
														name="inPersonLocationAddress.state"
														control={control}
														rules={{ required: required('State') }}
														render={({ field: { onChange, value } }) => (
															<Select
																getOptionLabel={(option) => option.abbreviation}
																getOptionValue={(option) => option.abbreviation}
																label="State"
																placeholder="State"
																id="state"
																error={errors?.inPersonLocationAddress?.state}
																options={STATE_OPTIONS}
																value={STATE_OPTIONS.find((c) => c.abbreviation === value) || ''}
																onChange={(val) => onChange(val.abbreviation)}
															/>
														)}
													/>
													<TextInput id="inpersonZipcode" label="Zipcode" placeholder="Zipcode" error={errors?.inPersonLocationAddress?.postalCode} {...register('inPersonLocationAddress.postalCode', { required: required('Zipcode') })} />
												</div>
												<TextArea id="inPersonNotes" label="Location Notes" placeholder="Write a message..." error={errors?.inPersonNotes} {...register('inPersonNotes')} />
											</div>
										</div>
									)}
								</>
							))}
					</S.SelectedLocations>
				</S.LocationStep>
			</div>
		</>
	);
};
