import styled from 'styled-components';

import colors from '../../../../styles/colors';

export const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	gap: 2rem;
	padding-bottom: 0.5rem;

	.avatar-wrapper {
		display: flex;
		justify-content: center;
	}

	.info-wrapper {
		display: grid;
		grid-template-columns: 1fr;
		gap: 1rem;
		align-self: flex-start;
	}

	.element-info {
		&__title {
			color: ${colors.neutralText};
			margin-bottom: 0.25rem;
		}
	}

	@media screen and (min-width: 992px) {
		grid-template-columns: auto 1fr;
	}
`;
