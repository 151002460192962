import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormContext } from 'react-hook-form';

import { RESOURCE_CATEGORIES } from '../../../../constants/general.constants';
import { required } from '../../../utils/form-default-errors';
import { Typography } from '../../../components';
import * as S from '../CreateResource.styles';

const RESOURCE_CATEGORY_OPTIONS = {
	appointment: RESOURCE_CATEGORIES.APPOINTMENT,
	information: RESOURCE_CATEGORIES.INFORMATION,
};

export const StepResourceCategory = () => {
	const {
		register,
		getValues,
		formState: { errors },
	} = useFormContext();

	return (
		<S.FormPanel>
			<Typography tag="h2" weight="extrablack" center>
				Select a Category
			</Typography>
			<Typography tag="p" center>
				Select an option for the category of request you would like to make.
			</Typography>
			<div className="radio-wrapper">
				{RESOURCE_CATEGORY_OPTIONS[getValues('type')]?.map(({ value, label, icon, description }) => (
					<div key={value} className={`radio-field${errors.category ? ' with-error' : ''}`}>
						<input
							className="radio-input"
							type="radio"
							value={value}
							{...register('category', {
								required: required('Category'),
							})}
						/>
						<FontAwesomeIcon icon={['fal', icon]} size="4x" />
						<Typography tag="h5" weight="bold" center>
							{label}
						</Typography>
						<Typography tag="p" variation="2" center>
							{description}
						</Typography>
					</div>
				))}

				{!!errors.category && (
					<div className="error-wrapper">
						<Typography tag="p" variation="2">
							{errors.category.message}
						</Typography>
					</div>
				)}
			</div>
		</S.FormPanel>
	);
};
