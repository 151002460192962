import styled, { css } from 'styled-components';

import colors from '../../styles/colors';

export const MainWrapper = styled.div`
	flex: 1;
	overflow: auto;
	display: flex;
`;

export const SidebarWrapper = styled.aside`
	overflow: auto;
	min-width: 19.375rem;
	width: 100%;
	max-width: 19.375rem;
	border: none;
	border-right: 1px solid ${colors.neutralForm};
	background-color: white;
	padding: 1rem 1.75rem;

	.sidebar-option {
		appearance: none;
		border: none;
		width: 100%;
		background-color: transparent;
		font-size: 1rem;
		line-height: 1.375rem;
		font-weight: 700;
		display: flex;
		align-items: center;
		cursor: pointer;
		padding: 0.5rem 0;

		& > * + * {
			margin-left: 0.5rem;
		}

		&__circle {
			width: 1.875rem;
			height: 1.875rem;
			min-width: 1.875rem;
			min-height: 1.875rem;
			border-radius: 9999px;
			color: ${colors.neutralText};
			border: 2px solid ${colors.neutralText};
			display: inline-flex;
			justify-content: center;
			font-weight: 600;
			align-items: center;

			& > svg {
				display: none;
			}
		}

		&__name {
			font-weight: 400;
			color: ${colors.neutralText};
		}

		&.active {
			.sidebar-option__name,
			.sidebar-option__circle {
				color: ${colors.brandPrimary};
			}

			.sidebar-option__name {
				font-weight: 700;
			}

			.sidebar-option__circle {
				border-color: ${colors.brandPrimary};
			}
		}

		&.completed {
			.sidebar-option__name {
				color: ${colors.stateSuccess};
			}

			.sidebar-option__circle {
				color: white;
				border-color: ${colors.stateSuccess};
				background-color: ${colors.stateSuccess};

				& > span {
					display: none;
				}
				& > svg {
					display: block;
				}
			}
		}
	}
`;

export const FormWrapper = styled.form`
	flex: 1;
	display: flex;
	flex-direction: column;
	overflow: hidden;

	.error-wrapper {
		color: ${colors.stateDanger};
	}
`;

export const FormContent = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	overflow: auto;
	overflow-x: hidden;
`;

export const FormFooter = styled.footer`
	background-color: white;
	border-top: 1px solid ${colors.neutralForm};
	padding: 0 2rem 1.25rem;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;

	.back-button {
		margin-top: 1.25rem;
	}

	.spacer {
		flex: 1;
		min-width: 1rem;
		height: 1rem;
	}

	.submit-container {
		display: flex;
		align-items: center;
		margin-top: 1.25rem;

		& > * + * {
			margin-left: 1rem;
		}
	}

	@media screen and (min-width: 768px) {
		justify-content: space-between;
	}
`;

export const FormPanel = styled.div`
	background-color: white;
	border: 1px solid ${colors.neutralForm};
	border-radius: 0.375rem;
	margin: 1.5rem 1rem;
	padding: 2.5rem 1rem;
	display: flex;
	flex-direction: column;

	& > * + * {
		margin-top: 0.75rem;
	}

	.fields-wrapper {
		display: grid;
		grid-template-columns: 1fr;
		gap: 1.5rem;
		width: 100%;
		max-width: 41rem;
		margin: 1.875rem auto 0;

		h6.gray-text {
			margin-top: 0.25rem;
		}
		.gray-text {
			color: ${colors.neutralText};
		}

		.files-wrapper {
			display: grid;
			grid-template-columns: 1fr;
			gap: 1rem;

			header {
				display: flex;
				justify-content: space-between;
				align-items: center;

				button {
					color: ${colors.neutralText};
				}
			}

			&__item {
				display: grid;
				grid-template-columns: 1fr;
				gap: 1rem;
				padding-bottom: 1.5rem;
				border-bottom: 1px solid ${colors.neutralForm};

				&__info {
					h6 {
						color: ${colors.neutralText};
						margin-bottom: 0.375em;
					}
				}

				&__details {
					display: flex;
					align-items: center;

					& > div {
						margin-left: 0.5rem;

						& > p:last-child {
							color: ${colors.neutralText};
						}
					}
				}

				&:last-child {
					padding-bottom: 0;
					border-bottom: none;
				}
			}
		}

		.two-col {
			display: grid;
			gap: 1.25rem;
			grid-template-columns: 1fr;

			@media (min-width: 992px) {
				grid-template-columns: 1fr 1fr;
			}
		}

		.review {
			&__step-item {
				display: grid;
				grid-template-columns: 1fr;
				gap: 1.5rem;
				margin-top: 1rem;

				&:first-child {
					margin-top: 0;
				}
			}

			&__info-item {
				& > h6 {
					color: ${colors.neutralText};
					margin-bottom: 0.375em;
				}

				&--with-badge {
					& > h6 {
						margin-bottom: calc(0.375em + 0.25rem);
					}
				}

				.badge-wrapper {
					display: flex;
					flex-wrap: wrap;
					margin: -0.25rem;

					.small-badge {
						padding: 0.25rem 0.5rem;
						border-radius: 6.25rem;
						border: 1px solid ${colors.brandPrimary};
						font-size: 0.75rem;
						color: ${colors.brandPrimary};
						font-weight: 700;
						margin: 0.25rem;
					}
				}
			}

			&__overview {
				& > h3 {
					margin-bottom: -1rem;
				}
			}

			&__cover-image {
				border: 1px solid ${colors.neutralForm};
				border-radius: 0.375rem;
			}

			&__file-item {
				display: grid;
				grid-template-columns: 1fr;
				gap: 1rem;
			}

			&__file-detail {
				display: flex;
				align-items: center;

				& > svg {
					color: ${colors.neutralText};
				}

				& > div {
					margin-left: 0.5rem;

					& > p:last-child {
						color: ${colors.neutralText};
					}
				}
			}

			&__location-item {
				display: flex;

				.location-icon-wrapper {
					display: flex;
					justify-content: center;
					margin-right: 0.5rem;
					width: 1.5rem;
					color: black;
				}

				.location-info-wrapper {
					flex: 1;
					display: grid;
					grid-template-columns: 1fr;
					gap: 1rem;

					.custom-margin {
						margin-top: 1rem;
					}
				}
			}
		}
	}

	.radio-wrapper {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		width: 100%;
		margin: 1rem auto 0;
		position: relative;

		.radio-field {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			position: relative;
			width: 100%;
			max-width: 16rem;
			height: 16rem;
			padding: 1rem;
			margin: 0.75rem;
			color: ${colors.neutralText};
			z-index: 0;

			&.template-field {
				min-height: 16rem;
				height: auto;
				max-height: none;
			}

			h5 {
				color: black;
			}

			h5,
			p {
				pointer-events: none;
				z-index: 1;
			}

			& > * + * {
				margin-top: 0.5rem;
			}

			svg {
				pointer-events: none;
				margin-bottom: 0.5rem;
				z-index: 1;
			}

			.radio-input {
				position: absolute;
				border: 1px solid ${colors.neutralForm};
				border-radius: 0.375rem;
				appearance: none;
				width: 100%;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				inset: 0;
				cursor: pointer;

				&:checked {
					background-color: rgba(234, 236, 244, 0.4);
					border-width: 2px;
					border-color: ${colors.brandPrimary};
				}
			}

			&.template {
				justify-content: flex-start;
				align-items: flex-start;
				padding: 0.75rem;

				.thumbnail {
					pointer-events: none;
					border-radius: 0.375rem;
					margin-top: 0;
				}

				p {
					margin-top: 0.125rem;
				}
			}

			&.with-error {
				color: ${colors.stateDanger};

				h5 {
					color: ${colors.stateDanger};
				}

				.radio-input {
					border-color: ${colors.stateDanger};
				}
			}

			.radio-input:checked ~ svg,
			.radio-input:checked ~ h5 {
				color: ${colors.brandPrimary};
			}
			.radio-input:checked ~ p {
				color: black;
			}
		}

		.error-wrapper {
			position: absolute;
			bottom: -0.75rem;
			color: ${colors.stateDanger};
			left: 0.75rem;
		}
	}
`;
