import styled from 'styled-components';

import colors from '../../../../styles/colors';

export const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1.25rem;
	padding-bottom: 0.5rem;

	.block-title {
		grid-column: span 2;
	}

	.element-wrapper {
		display: flex;
		flex-direction: column;

		> h6 {
			color: ${colors.neutralText};
		}

		& > * + * {
			margin-top: 0.25rem;
		}
	}
`;

export const Form = styled.form`
	position: relative;
	padding: 3rem 1.25rem 0;

	.close-button {
		position: absolute;
		top: 1rem;
		right: 1rem;
		color: ${colors.neutralButton};
	}
`;

export const InfoWrapper = styled.div`
	margin: 0 auto 0.75rem;
	width: 100%;
	max-width: 34.5rem;

	& > * + * {
		margin-top: 0.75rem;
	}
`;

export const FieldsWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	gap: 1rem;
	width: 100%;
	max-width: 29rem;
	margin: 1rem auto 0;

	.information-card {
		display: flex;
		padding: 0.5rem 0.75rem;
		background-color: ${colors.neutralBackground};
		border: 1px solid ${colors.neutralForm};
		border-radius: 0.375rem;
		margin-top: 0.5rem;

		.icon-wrapper {
			display: flex;
			justify-content: center;
			width: 1.25rem;
			color: ${colors.brandPrimary};
		}

		.info-wrapper {
			margin-left: 0.75rem;
			color: #000000;

			.info-line {
				display: flex;
				margin-top: 0.5rem;
				color: ${colors.neutralButton};

				svg {
					width: 1rem;
					height: 1rem;
				}

				p {
					margin-left: 0.5rem;
				}
			}
		}
	}
`;

export const ButtonsWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin: 1.75rem -1.25rem 0;
	padding: 1rem;
	border-top: 1px solid ${colors.neutralForm};

	& > * + * {
		margin-left: 1rem;
	}
`;
