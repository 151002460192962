import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { isPast, add } from 'date-fns';
import PropTypes from 'prop-types';

import { formatToMonthDayAndYear } from '../../../utils/time-formatter';
import { Typography, Calendar, DatePicker, TextArea } from '../..';
import { required } from '../../../utils/form-default-errors';

export const StepOne = ({ role }) => {
	const {
		register,
		control,
		watch,
		formState: { errors },
	} = useFormContext();

	const availabilityDates = watch('availabilityDates');

	const formatAvailabilityDates = () => {
		if (availabilityDates) {
			if (availabilityDates.length < 2) {
				return formatToMonthDayAndYear(availabilityDates[0]);
			} else {
				return `${formatToMonthDayAndYear(availabilityDates[0])} - ${formatToMonthDayAndYear(availabilityDates[1])}`;
			}
		}
		return 'Select Date(s) Above';
	};

	return (
		<div className="modal-content">
			<Typography className="bigger-title" tag="h1" weight="extrablack" center>
				Select a Date
			</Typography>
			<Typography tag="p" className="description" center>
				Select a time frame you would like for the resource to occur.
			</Typography>

			<div className="fields-wrapper">
				<div className="calendar-wrapper">
					<Typography tag="h4" weight="extrablack" className="calendar-title">
						Availability
					</Typography>
					<div className={`calendar-content${!!errors?.availabilityDates ? ' with-error' : ''}`}>
						<Controller
							name="availabilityDates"
							control={control}
							rules={{ required: required('Date Range') }}
							render={({ field: { onChange, value } }) => <Calendar id="date-range" value={value} onChange={onChange} selectRange={true} allowPartialRange={true} tileDisabled={({ date }) => isPast(add(date, { days: 1 }))} />}
						/>
					</div>
					{!!errors?.availabilityDates && (
						<div className="error-wrapper">
							<Typography tag="p" variation="2">
								{errors?.availabilityDates?.message}
							</Typography>
						</div>
					)}
					<div className="field-wrapper">
						<Typography tag="h6" weight="semibold" className="field-label custom-margin">
							Selected Dates
						</Typography>
						<Typography tag="p" className={`field-value${availabilityDates ? null : ' field-value--empty'}`}>
							{formatAvailabilityDates()}{' '}
						</Typography>
					</div>
				</div>

				<div className="time-wrapper">
					<Controller
						control={control}
						name="startTime"
						rules={{ required: required('Start Time') }}
						render={({ field: { value, onChange } }) => <DatePicker label="Start Time" selected={value} onChange={onChange} error={errors?.startTime} showTimeSelect showTimeSelectOnly timeIntervals={15} timeCaption="Time" dateFormat="h:mm a" withoutIcon />}
					/>
					<Controller
						control={control}
						name="endTime"
						rules={{ required: required('End Time') }}
						render={({ field: { value, onChange } }) => <DatePicker label="End Time" selected={value} onChange={onChange} error={errors?.endTime} showTimeSelect showTimeSelectOnly timeIntervals={15} timeCaption="Time" dateFormat="h:mm a" withoutIcon />}
					/>
				</div>

				<TextArea id="availabilityNotes" label="Availability Notes (i.e days flexible, days unavailable, etc.)" placeholder="Notes go here..." error={errors?.availabilityNotes} {...register('availabilityNotes')} />
			</div>
		</div>
	);
};

StepOne.propTypes = {
	role: PropTypes.string,
};
