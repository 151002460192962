import React, { useEffect, useState, useImperativeHandle, forwardRef, useRef } from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';

import { AdminNavigation, Button, ErrorComponent, IconButton, PageHeader, Select, Spinner, Tab, TextArea, TextInput, Typography } from '../../components';
import * as S from './AdminMemberships.styles';
import { ArchivedTab } from './ArchivedTab';
import { CurrentTab } from './CurrentTab';
import { META_TITLE } from '../../../constants/general.constants';
import { errorHandler } from '../../services/authService';
import { fetchBusinesses } from '../../services/business';
import { required } from '../../utils/form-default-errors';
import { Controller, useForm } from 'react-hook-form';
import { addCompaniesToMembership, createUpdateMembership } from '../../services/memberships';

const CreateMembershipModal = forwardRef(({ onSuccessCallback }, ref) => {
	const [loadingStatus, setLoadingStatus] = useState('idle');
	const [isOpen, setIsOpen] = useState(false);
	const [companies, setCompanies] = useState([]);

	const {
		handleSubmit,
		register,
		reset,
		control,
		formState: { errors, isSubmitting },
	} = useForm();

	async function initializeData() {
		setLoadingStatus('loading');
		try {
			const {
				data: {
					result: { businesses },
				},
			} = await fetchBusinesses({ page: 0, isSlim: true });
			setCompanies(businesses);
			setLoadingStatus('success');
		} catch (error) {
			setLoadingStatus('error');
			errorHandler(error);
		}
	}

	const handleOpenModal = () => {
		setIsOpen(true);
	};

	const handleCloseModal = () => {
		setIsOpen(false);
		setTimeout(() => {
			reset();
		}, 200);
	};

	async function asyncCaller(data) {
		const { businessIds, ...rest } = data;
		const {
			data: {
				result: { membership },
			},
		} = await createUpdateMembership(rest);
		await addCompaniesToMembership({ membershipId: membership.id, businessIds });
		onSuccessCallback();
		handleCloseModal();
	}

	const onSubmit = (e) => {
		handleSubmit(asyncCaller)(e).catch(errorHandler);
	};

	const renderContent = () => {
		switch (loadingStatus) {
			case 'loading':
				return <Spinner />;
			case 'error':
				return <ErrorComponent />;
			case 'success':
				return (
					<S.Form onSubmit={onSubmit}>
						<IconButton className="close-button" type="button" icon={['fal', 'times']} onClick={handleCloseModal} />
						<div className="modal-content">
							<header className="modal-content__header">
								<Typography tag="h2" weight="extrablack" center>
									Create Membership
								</Typography>
							</header>

							<section className="modal-content__section">
								<Typography tag="h3" weight="bold">
									Overview
								</Typography>

								<TextInput
									label="Name"
									placeholder="Name"
									error={errors.name}
									{...register('name', {
										required: required('Name'),
									})}
								/>

								<TextArea
									label="Description"
									placeholder="Description"
									error={errors.description}
									{...register('description', {
										required: required('Description'),
									})}
								/>

								<Typography tag="h3" weight="bold">
									Members
								</Typography>

								<Controller
									name="businessIds"
									control={control}
									rules={{ required: required('Company') }}
									render={({ field: { onChange, value } }) => (
										<Select
											isMulti
											getOptionValue={(option) => option.id}
											getOptionLabel={(option) => option.name}
											label="Company"
											id="businessIds"
											error={errors?.businessIds}
											placeholder="Company"
											options={companies}
											promise={{
												function: fetchBusinesses,
												params: { isSlim: true },
												key: 'businesses',
												setOptions: setCompanies,
											}}
											value={value?.map((val) => companies.find((c) => c.id === val)) || ''}
											onChange={(val) => onChange(val?.map((val) => val.id))}
										/>
									)}
								/>
							</section>

							<div className="modal-footer">
								<Button variant="outline" variation="secondary" type="button" onClick={handleCloseModal}>
									<Typography variation="button-medium" weight="bold">
										Cancel
									</Typography>
								</Button>
								<div className="flex-1" />
								<Button>
									<Typography variation="button-medium" weight="bold">
										{isSubmitting ? 'Loading' : 'Create'}
									</Typography>
								</Button>
							</div>
						</div>
					</S.Form>
				);
			default:
				return null;
		}
	};

	useEffect(() => {
		if (loadingStatus === 'idle') {
			initializeData();
		}
	}, []);

	useImperativeHandle(
		ref,
		() => ({
			open: handleOpenModal,
			close: handleCloseModal,
		}),
		[handleOpenModal, handleCloseModal]
	);

	return (
		<ReactModal ref={ref} className="add-user-modal" closeTimeoutMS={200} isOpen={isOpen} onRequestClose={handleCloseModal}>
			{renderContent()}
		</ReactModal>
	);
});

CreateMembershipModal.displayName = 'CreateMembershipModal';

CreateMembershipModal.propTypes = {
	onSuccessCallback: PropTypes.func.isRequired,
};

const AdminMemberships = () => {
	const currentTabRef = useRef();
	const [tabs, setTabs] = useState([
		{
			id: 1,
			label: 'Current',
			component: <CurrentTab ref={currentTabRef} />,
		},
		{
			id: 2,
			label: 'Archived',
			component: <ArchivedTab />,
		},
	]);

	const CreateMembershipModalRef = useRef();

	const handleCreateMembership = () => CreateMembershipModalRef.current.open();

	useEffect(() => {
		// Set document title
		document.title = `Memberships | ${META_TITLE}`;
	}, []);

	return (
		<AdminNavigation>
			<PageHeader title="Memberships" action={[{ id: 2, onClick: handleCreateMembership, label: 'Create Membership', icon: ['fal', 'plus'] }]} />
			<CreateMembershipModal ref={CreateMembershipModalRef} onSuccessCallback={() => currentTabRef.current?.onChangeCallback()} />
			<S.Wrapper>
				<Tab tabs={tabs} setTabs={setTabs} />
			</S.Wrapper>
		</AdminNavigation>
	);
};

export default AdminMemberships;
