import React, { useEffect, useState } from 'react';
import { Controller, useFormContext, useFieldArray } from 'react-hook-form';
import { useSelector } from 'react-redux';

import * as S from '../../CreateResource.styles';
import { fetchBusinessProfile } from '../../../../services/directory';
import { fetchBusinessLocations } from '../../../../services/businessLocation';
import { Button, DetailedCheckbox, RangeInput, TextArea, Typography, Select } from '../../../../components';
import { LOCATION_TYPES, ROLES } from '../../../../../constants/general.constants';
import { required } from '../../../../utils/form-default-errors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LOCATION_TYPES_WITHOUT_IN_PERSON = LOCATION_TYPES.filter(({ id }) => id !== 'inperson');

export const LocationStep = () => {
	const {
		register,
		watch,
		control,
		getValues,
		formState: { errors },
	} = useFormContext();
	const user = useSelector((state) => state.user.value);
	const [locationsLoading, setLocationsLoading] = useState('idle');
	const [businessProfile, setBusinessProfile] = useState(undefined);

	const locationTypes = watch('locationTypes');

	const { fields, append, remove } = useFieldArray({ control, name: 'worksiteLocations' });

	const handleAddLocation = () => {
		append({});
	};

	const handleRemoveLocation = (index) => {
		remove(index);
	};

	async function initializeData() {
		setLocationsLoading('loading');
		try {
			if (user.role === ROLES.ADMIN || user.role === ROLES.SUPER) {
				const {
					data: { result },
				} = await fetchBusinessLocations();
				setBusinessProfile({ locations: result.locations });
			} else {
				const {
					data: { result },
				} = await fetchBusinessProfile({ businessId: user?.business?.id });
				setBusinessProfile(result.business);
			}
			setLocationsLoading('success');
		} catch (error) {
			setLocationsLoading('error');
		}
	}

	useEffect(() => {
		if (locationsLoading === 'idle') {
			initializeData();
		}
	}, [locationsLoading]);

	return (
		<>
			<Typography tag="h2" weight="extrablack" center>
				Location
			</Typography>
			<Typography tag="p" center>
				Select where you would your request to occur.
			</Typography>

			<div className="fields-wrapper">
				<S.LocationStep>
					<div>
						<Typography tag="h3" weight="bold">
							Location Type
						</Typography>
						<Typography tag="p" variation="2">
							Select all locations you want to have this resource.
						</Typography>
					</div>
					<S.LocationStepWrapper>
						{LOCATION_TYPES_WITHOUT_IN_PERSON.map(({ id, name, description, icon }) => (
							<DetailedCheckbox key={id} id={id} value={id} title={name} description={description} icon={icon} {...register('locationTypes', { required: required('Location Type') })} />
						))}
						{!!errors.locationTypes && (
							<div className="error-wrapper">
								<Typography tag="p" variation="2">
									{errors.locationTypes.message}
								</Typography>
							</div>
						)}
					</S.LocationStepWrapper>

					<S.SelectedLocations>
						<div>
							<Typography tag="h3" weight="bold">
								Location Details
							</Typography>
							<Typography tag="p" variation="2">
								Enter in more information about your locations.
							</Typography>
						</div>
						{locationTypes?.length === 0 && (
							<Typography tag="p" className="gray-color">
								No location types selected.
							</Typography>
						)}
						{!locationTypes ||
							(locationTypes.length >= 1 && (
								<>
									{locationTypes?.includes('virtual') && (
										<div className="step__location">
											<div className="step__location-icon-wrapper">
												<FontAwesomeIcon icon={['fal', 'camera']} size="lg" />
											</div>
											<div className="step__location-fields-wrapper">
												<Typography tag="h4" weight="bold">
													Virtual
												</Typography>
												<TextArea id="virtualLocationNotes" label="Location Notes" placeholder="Write a message..." error={errors?.virtualLocationNotes} {...register('virtualLocationNotes')} />
											</div>
										</div>
									)}
									{locationTypes?.includes('worksite') && locationsLoading === 'success' && (
										<div className="step__location">
											<div className="step__location-icon-wrapper">
												<FontAwesomeIcon icon={['fal', 'building']} size="lg" />
											</div>
											<div className="step__location-fields-wrapper">
												<Typography tag="h4" weight="bold">
													Worksite Location
												</Typography>
												{fields.map(({ id }, index) => (
													<S.LocationItem key={id}>
														<Typography tag="h5" weight="bold">
															Location {index + 1}
														</Typography>
														<S.FieldsWrapper>
															<Controller
																name={`worksiteLocations.${index}.locationId`}
																control={control}
																rules={{ required: required('Location') }}
																render={({ field: { onChange, value } }) => (
																	<Select
																		getOptionLabel={(option) => option.name}
																		getOptionValue={(option) => option.id}
																		label="Location"
																		id={`location-${index}`}
																		error={errors?.worksiteLocations?.[index]?.locationId}
																		options={businessProfile?.locations}
																		value={businessProfile?.locations.find((c) => c.id === value) || ''}
																		onChange={(val) => onChange(val.id)}
																	/>
																)}
															/>
															{getValues(`worksiteLocations.${index}.locationId`) && (
																<div className="step__information-card">
																	<div className="icon-wrapper">
																		<FontAwesomeIcon icon="school" />
																	</div>
																	<div className="info-wrapper">
																		<Typography tag="h5" weight="bold">
																			{businessProfile?.locations.find((location) => location.id === getValues(`worksiteLocations.${index}.locationId`))?.name}
																		</Typography>
																		<div className="info-line">
																			<FontAwesomeIcon icon={['fal', 'map-marker']} />
																			<Typography tag="p" variation="2">
																				{`${businessProfile?.locations.find((location) => location.id === getValues(`worksiteLocations.${index}.locationId`))?.address?.addressLine1}, ${
																					businessProfile?.locations.find((location) => location.id === getValues(`worksiteLocations.${index}.locationId`))?.address?.state
																				} ${businessProfile?.locations.find((location) => location.id === getValues(`worksiteLocations.${index}.locationId`))?.address?.postalCode}`}
																			</Typography>
																		</div>
																		<div className="info-line">
																			<FontAwesomeIcon icon={['fal', 'landmark']} />
																			<Typography tag="p" variation="2">
																				{businessProfile?.locations.find((location) => location.id === getValues(`worksiteLocations.${index}.locationId`))?.address?.city}
																			</Typography>
																		</div>
																		<div className="info-line">
																			<FontAwesomeIcon icon={['fal', 'school']} />
																			<Typography tag="p" variation="2">
																				{businessProfile?.locations.find((location) => location.id === getValues(`worksiteLocations.${index}.locationId`))?.searchTag}
																			</Typography>
																		</div>
																	</div>
																</div>
															)}
															<TextArea
																label="Location Notes"
																id={`location-notes-${index}`}
																error={errors?.worksiteLocations?.[index]?.notes}
																{...register(`worksiteLocations.${index}.notes`, {
																	required: required('Location Notes'),
																})}
															/>
														</S.FieldsWrapper>
														{fields.length > 1 && (
															<Button className="location-remove" variant="text" type="button" onClick={() => handleRemoveLocation(index)}>
																<Typography variation="button-medium" weight="bold">
																	Remove
																</Typography>
															</Button>
														)}
													</S.LocationItem>
												))}
												<S.AddWrapper>
													<Button variant="text" type="button" onClick={handleAddLocation}>
														<FontAwesomeIcon icon={['fal', 'plus']} />
														<Typography variation="button-medium" weight="bold">
															Add Location
														</Typography>
													</Button>
												</S.AddWrapper>
											</div>
										</div>
									)}
									{locationTypes?.includes('student') && (
										<div className="step__location">
											<div className="step__location-icon-wrapper">
												<FontAwesomeIcon icon={['fal', 'users']} size="lg" />
											</div>
											<div className="step__location-fields-wrapper">
												<Typography tag="h4" weight="bold">
													Student Location
												</Typography>
												<Controller
													name="studentMaxTravelDistance"
													control={control}
													rules={{ required: required('Maximum Travel Distance') }}
													render={({ field: { onChange, value } }) => <RangeInput error={errors?.studentMaxTravelDistance} label="Maximum Travel Distance" id="student-max-travel-distance" unit="mi" value={value} onChange={onChange} />}
												/>
											</div>
										</div>
									)}
								</>
							))}
					</S.SelectedLocations>
				</S.LocationStep>
			</div>
		</>
	);
};
