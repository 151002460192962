import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';

import { Button, Card, ErrorComponent, Spinner, Typography } from '../../../components';
import * as S from './PendingCompanies.styles';
import { approveBusiness, fetchBusinesses } from '../../../services/business';
import { errorHandler } from '../../../services/authService';

export const PendingCompanies = ({ className }) => {
	const history = useHistory();
	const [loadingStatus, setLoadingStatus] = useState('idle');
	const [pendingCompanies, setPendingCompanies] = useState([]);

	async function handleApproveBusiness(businessId) {
		try {
			await approveBusiness({ businessId });
			setPendingCompanies((prev) => prev.filter((company) => company.id !== businessId));
		} catch (error) {
			errorHandler(error);
		}
	}

	async function initializeData() {
		setLoadingStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchBusinesses({ filterOptions: { pending: true } });
			setPendingCompanies(result.businesses);
			setLoadingStatus('success');
		} catch (error) {
			setLoadingStatus('error');
		}
	}

	useEffect(() => {
		if (loadingStatus === 'idle') {
			initializeData();
		}
	}, []);

	const renderContent = () => {
		switch (loadingStatus) {
			case 'loading':
				return <Spinner />;
			case 'error':
				return <ErrorComponent />;
			case 'success':
				return pendingCompanies?.length > 0 ? (
					<S.List>
						{pendingCompanies.map((company) => (
							<S.ListItem key={company.id}>
								<div className="info-wrapper">
									<div className="info-wrapper__avatar">
										<img src={company.logoObj?.[200]} alt={company.name} />
									</div>
									<Typography className="info-wrapper__name" tag="h5" weight="bold">
										{company.name}
									</Typography>
									<Button variant="outline" size="small" onClick={() => handleApproveBusiness(company.id)}>
										<Typography variation="button-medium" weight="bold">
											Approve
										</Typography>
									</Button>
								</div>
								<div className="details-wrapper">
									<div className="details-wrapper__item">
										<FontAwesomeIcon icon={['fal', 'globe']} />
										<Typography tag="p" variation="2">
											{company.website}
										</Typography>
									</div>
									<div className="details-wrapper__item">
										<FontAwesomeIcon icon={['fal', 'building']} />
										<Typography tag="p" variation="2">
											{company.locations?.length || 0} {company.locations?.length === 1 ? 'Location' : 'Locations'}
										</Typography>
									</div>
								</div>
							</S.ListItem>
						))}
					</S.List>
				) : (
					<S.EmptyBusinessCard paddingTop={4} paddingBottom={6}>
						<div className="icon-box">
							<FontAwesomeIcon icon={['fal', 'building']} size="4x" />
						</div>
						<Typography tag="h4" weight="bold" center>
							You Have No Pending Companies
						</Typography>
						<Typography tag="p" center>
							Once your account is approved you will be able to view your pending companies here.
						</Typography>
					</S.EmptyBusinessCard>
				);
			default:
				return null;
		}
	};

	return (
		<Card
			className={className}
			title="Pending Companies"
			actions={[
				{
					id: 1,
					label: 'View All',
					size: 'small',
					variant: 'text',
					icon: {
						source: ['fal', 'arrow-right'],
						placement: 'right',
					},
					onClick: () => history.push('/admin/companies'),
				},
			]}
		>
			{renderContent()}
		</Card>
	);
};

PendingCompanies.propTypes = {
	className: PropTypes.string,
};
