import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { BusinessNavigation, PageHeader, Tab } from '../../components';

import { PendingTab } from './PendingTab';
import { ScheduledTab } from './ScheduledTab';
import { DraftsTab } from './DraftsTab';
import { CompletedTab } from './CompletedTab';
import { IncompleteTab } from './IncompleteTab';
import { ArchivedTab } from './ArchivedTab';
import * as S from './BusinessRequests.styles';
import { META_TITLE } from '../../../constants/general.constants';

const BusinessRequests = () => {
	const history = useHistory();
	const [tabs, setTabs] = useState([
		{
			id: 1,
			label: 'Pending',
			component: <PendingTab />,
		},
		{
			id: 2,
			label: 'Scheduled',
			component: <ScheduledTab />,
		},
		{
			id: 3,
			label: 'Drafts',
			component: <DraftsTab />,
		},
		{
			id: 4,
			label: 'Completed',
			component: <CompletedTab />,
		},
		{
			id: 5,
			label: 'Incomplete',
			component: <IncompleteTab />,
		},
		{
			id: 6,
			label: 'Archived',
			component: <ArchivedTab />,
		},
	]);

	useEffect(() => {
		// Set document title
		document.title = `Requests | Dashboard | ${META_TITLE}`;
	}, []);

	return (
		<BusinessNavigation>
			<PageHeader title="Requests" action={[{ id: 1, onClick: () => history.push('/business/create-request'), label: 'Create Request', icon: ['fal', 'plus'] }]} />
			<S.Wrapper>
				<Tab tabs={tabs} setTabs={setTabs} />
			</S.Wrapper>
		</BusinessNavigation>
	);
};

export default BusinessRequests;
