import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import ReactCalendar from 'react-calendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import * as S from './Calendar.styles';

export const Calendar = forwardRef(({ events = [], containerClassName, allDates, ...rest }, ref) => {
	const tileClassName = ({ date }) => {
		if (allDates != null && allDates.length > 0) {
			const match = allDates.includes(date.toDateString());
			if (!match) return 'inactiveDay';
			else {
				const nextDate = moment(date).add(1, 'days').toDate();
				const previousDate = moment(date).subtract(1, 'days').toDate();
				const matchNextDate = allDates.includes(nextDate.toDateString());
				const matchPreviousDate = allDates.includes(previousDate.toDateString());
				if (matchNextDate !== true && matchPreviousDate !== true) {
					return 'rangeStartEnd';
				}
				if (matchNextDate !== true) {
					return 'rangeEnd';
				}
				if (matchPreviousDate !== true) {
					return 'rangeStart';
				}
			}
		} else {
			for (let i = 0; i < events.length; i += 1) {
				const { allDates, color } = events[i];
				const match = allDates.includes(date.toDateString());
				if (match) {
					if (color) return color;
				}
			}
		}
	};

	return (
		<S.CalendarWrapper className={containerClassName}>
			<ReactCalendar
				ref={ref}
				locale="en-US"
				minDetail="month"
				nextLabel={<FontAwesomeIcon icon={['fal', 'angle-right']} className="react-calendar__navigation__arrow__icon" />}
				next2Label={null}
				prevLabel={<FontAwesomeIcon icon={['fal', 'angle-left']} className="react-calendar__navigation__arrow__icon" />}
				prev2Label={null}
				showNeighboringMonth={false}
				tileClassName={tileClassName}
				{...rest}
			/>
		</S.CalendarWrapper>
	);
});

Calendar.displayName = 'Calendar';
Calendar.propTypes = {
	events: PropTypes.array,
	containerClassName: PropTypes.string,
	allDates: PropTypes.array,
};
