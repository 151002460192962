import styled, { css } from 'styled-components';

import colors from '../../../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;

	& > * + * {
		margin-top: 1rem;
	}

	.overview-picture {
		padding-bottom: 0.5rem;

		picture {
			border-radius: 0.375rem;
		}
	}

	.overview-info {
		display: grid;
		grid-template-columns: 1fr;
		gap: 0.75rem;
		padding-bottom: 0.5rem;

		&__title {
			color: ${colors.neutralText};
			margin-bottom: 0.25rem;
		}

		&__badges-wrapper {
			display: flex;
			flex-wrap: wrap;
			margin: -0.25rem;
		}

		&__badge {
			padding: 0.25rem 0.5rem;
			border-radius: 6.25rem;
			border: 1px solid ${colors.brandPrimary};
			font-size: 0.75rem;
			color: ${colors.brandPrimary};
			font-weight: 700;
			margin: 0.25rem;
		}
	}

	@media screen and (min-width: 992px) {
		flex-direction: row;

		& > * + * {
			margin-top: 0;
			margin-left: 1rem;
		}

		.overview-picture {
			min-width: 16.875rem;
			max-width: 16.875rem;
		}

		.overview-info {
			display: grid;
			grid-template-columns: repeat(2, 1fr);

			&__element {
				&:first-child,
				&:last-child {
					grid-column: 1 / 3;
				}
			}
		}
	}
`;

export const Form = styled.form`
	position: relative;
	padding: 3rem 1.25rem 0;

	.close-button {
		position: absolute;
		top: 1rem;
		right: 1rem;
		color: ${colors.neutralButton};
	}
`;

export const CategoryWrapper = styled.div`
	.radio-wrapper {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		width: 100%;
		margin: 1rem auto 0;
		position: relative;

		.radio-field {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			position: relative;
			width: 100%;
			max-width: 16rem;
			height: 16rem;
			padding: 1rem;
			margin: 0.75rem;
			color: ${colors.neutralText};
			z-index: 0;

			&.template-field {
				min-height: 16rem;
				height: auto;
				max-height: none;
			}

			h5 {
				color: black;
			}

			h5,
			p {
				pointer-events: none;
				z-index: 1;
			}

			& > * + * {
				margin-top: 0.5rem;
			}

			svg {
				pointer-events: none;
				margin-bottom: 0.5rem;
				z-index: 1;
			}

			.radio-input {
				position: absolute;
				border: 1px solid ${colors.neutralForm};
				border-radius: 0.375rem;
				appearance: none;
				width: 100%;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				inset: 0;
				cursor: pointer;

				&:checked {
					background-color: rgba(234, 236, 244, 0.4);
					border-width: 2px;
					border-color: ${colors.brandPrimary};
				}
			}

			&.template {
				justify-content: flex-start;
				align-items: flex-start;
				padding: 0.75rem;

				.thumbnail {
					pointer-events: none;
					border-radius: 0.375rem;
					margin-top: 0;
				}

				p {
					margin-top: 0.125rem;
				}
			}

			&.with-error {
				color: ${colors.stateDanger};

				h5 {
					color: ${colors.stateDanger};
				}

				.radio-input {
					border-color: ${colors.stateDanger};
				}
			}

			.radio-input:checked ~ svg,
			.radio-input:checked ~ h5 {
				color: ${colors.brandPrimary};
			}
			.radio-input:checked ~ p {
				color: black;
			}
		}

		.error-wrapper {
			position: absolute;
			bottom: -0.75rem;
			color: ${colors.stateDanger};
			left: 0.75rem;
		}
	}
`;

export const InfoWrapper = styled.div`
	margin: 0 auto 0.75rem;
	width: 100%;
	max-width: 34.5rem;

	& > * + * {
		margin-top: 0.75rem;
	}
`;

export const FieldsWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	gap: 1.5rem;
	width: 100%;
	max-width: 29rem;
	margin: 2rem auto 0;
`;

export const FileDropZone = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	border: 1px dashed ${colors.neutralText};
	height: 25rem;
	border-radius: 0.375rem;
	cursor: pointer;

	overflow: hidden;

	img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

	${({ isDragActive }) =>
		isDragActive &&
		css`
			opacity: 0.8;
		`}

	${({ isDragAccept }) =>
		isDragAccept &&
		css`
			border-color: ${colors.stateSuccess};
		`}

	${({ isDragReject }) =>
		isDragReject &&
		css`
			border-color: ${colors.stateDanger};
		`}
`;

export const HugeImageUploader = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;

	label {
		color: ${colors.neutralText};
		cursor: pointer;
	}

	.reset-btn {
		appearance: none;
		border: none;
		border-radius: 0.375rem;
		cursor: pointer;
		background-color: ${colors.brandPrimary};
		color: white;
		display: grid;
		place-content: center;
		width: 2.25rem;
		height: 2.25rem;
		position: absolute;
		top: 2.25rem;
		right: 1rem;

		svg {
			width: 1.125rem;
			height: 1.125rem;
		}

		&:hover {
			opacity: 0.8;
		}
	}

	${({ withError }) =>
		withError &&
		css`
			label {
				color: ${colors.stateDanger};
			}

			${FileDropZone} {
				border-color: ${colors.stateDanger};
			}
		`}

	.error-wrapper {
		position: absolute;
		bottom: -1rem;
		color: ${colors.stateDanger};
	}
`;

export const ButtonsWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin: 1.75rem -1.25rem 0;
	padding: 1rem;
	border-top: 1px solid ${colors.neutralForm};

	& > * + * {
		margin-left: 1rem;
	}
`;

export const CareerClusterWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 1rem;
	position: relative;

	.field-label {
		color: ${colors.neutralText};

		&.with-error {
			color: ${colors.stateDanger};
		}
	}

	.checkbox-field {
		display: flex;
		align-items: center;

		& > * + * {
			padding-left: 0.75rem;
		}

		label {
			font-size: 1rem;
			line-height: 1.375rem;
			font-weight: 400;
			color: black;
			cursor: pointer;
		}
	}

	.error-wrapper {
		position: absolute;
		bottom: -1rem;
		color: ${colors.stateDanger};
	}

	@media screen and (min-width: 768px) {
		grid-template-columns: 1fr 1fr;

		.field-label {
			grid-column: 1 / 3;
		}
	}
`;
