import styled from 'styled-components';

import { Typography } from '../../../../components';
import colors from '../../../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;

	& > * + * {
		margin-top: 1rem;
	}

	@media screen and (min-width: 992px) {
		flex-direction: row;

		& > * + * {
			margin: 0 0 0 1rem;
		}
	}
`;

export const Avatar = styled.img`
	height: 9.75rem;
	min-width: 9.75rem;
	width: 9.75rem;
	border-radius: 9999px;
	border: 1px solid ${colors.neutralForm};
`;

export const InfoWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	gap: 1rem;
	padding-bottom: 0.5rem;

	.info-wrapper {
		&__grid-wrapper {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 1rem;

			@media screen and (min-width: 992px) {
				grid-template-columns: repeat(3, 1fr);
			}
		}

		&__title {
			color: ${colors.neutralButton};
			margin-bottom: 0.25rem;
		}

		&__badges-wrapper {
			display: flex;
			flex-wrap: wrap;
			margin: -0.25rem;
		}

		&__badge {
			padding: 0.25rem 0.5rem;
			border-radius: 6.25rem;
			border: 1px solid ${colors.brandPrimary};
			font-size: 0.75rem;
			color: ${colors.brandPrimary};
			font-weight: 700;
			margin: 0.25rem;
		}
	}
`;

export const LinkTypography = styled(Typography)`
	cursor: pointer;
`;
