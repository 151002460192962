import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import countyShapes from './north-carolina-counties.json';
import Datamap from 'datamaps';

import { Card } from '../../Card';

import * as S from './MapChartGraph.styles';
import { COUNTY_OPTIONS } from '../../../../constants/general.constants';

export const MapChartGraph = ({ className, title, data = {} }) => {
	const countyRange = { min: 0, max: Object.values(data).length > 0 ? Math.max(...Object.values(data)) : 85 };

	function generateMapFills() {
		var t = 0,
			a = '',
			e = {},
			o = [],
			i = [0, 5, 10, 15, 20],
			n = 1,
			r = 0.9 * countyRange.max,
			d = 5,
			l = ['#ddd', '#6D7EC5', '#485EB7', '#394A90', '#35436D', '#ddd'],
			s = {};
		if (r > 20) for (i = [], d = Math.ceil((r - n) / 3), t = 0; 5 > t; t++) i.push(d * t);
		for (a = '0', o.push(a), e[a] = l[0], t = 1; 4 > t; t++) (a = n + '-' + i[t]), o.push(a), (e[a] = l[t]), (n += d);
		return (
			(a = n + '+'),
			o.push(a),
			(e[a] = l[t]),
			(e.defaultFill = l[t + 1]),
			Object.keys(data).forEach((county) => {
				var a = e.defaultFill,
					n = 0;
				const count = data[county];
				const name = COUNTY_OPTIONS.find((countyObj) => countyObj.code === county)?.name;
				if (count > 0)
					for (n = 1; 5 > n; n++)
						if (count <= i[n]) {
							a = o[n];
							break;
						}
				s[name] = {
					fillKey: a,
					resources: count,
					name: name,
					lid: name?.toLowerCase(),
				};
			}),
			{
				legend: e,
				data: s,
			}
		);
	}

	useEffect(() => {
		// Generate map fills
		const mapFills = generateMapFills();

		// Create navigator map element
		const NavigatorMap = new Datamap({
			element: document.getElementById('navigator-county-map'),
			responsive: true,
			geographyConfig: {
				dataJson: countyShapes,
				borderColor: '#9198b3',
				highlightFillColor: '#9df0c7',
				highlightBorderColor: 'rgba(0, 0, 0, 0.2)',
				popupTemplate: function (t, a) {
					return '<div class="hoverinfo"><strong>' + t.properties?.name + '</strong><br> Resources: ' + a.resources + '</div>';
				},
			},
			scope: 'NorthCarolina',
			fills: mapFills.legend,
			data: mapFills.data,
			setProjection: function (t) {
				var a, i;
				return (
					(a = d3.geo
						.mercator()
						.center([-79.800836, 35.265684])
						.scale(5.5 * t.offsetWidth)
						.translate([t.offsetWidth / 2, t.offsetHeight / 2])),
					(i = d3.geo.path().projection(a)),
					{
						path: i,
						projection: a,
					}
				);
			},
		});
		window.addEventListener('resize', function () {
			NavigatorMap.resize();
		});

		// Create legend
		NavigatorMap.legend();
	}, []);

	return (
		<Card className={className} title={title} transparentHeaderBorder>
			<S.Wrapper>
				<div id="navigator-county-map" className="nvgtr-map-container" />
			</S.Wrapper>
		</Card>
	);
};

MapChartGraph.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string,
	data: PropTypes.shape(),
};
