import React from 'react';
import PropTypes from 'prop-types';

import * as S from './RatingStars.styles';
import { IconButton } from '../IconButton';
import { Typography } from '../Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const RatingStars = ({ label, error, value, onChange }) => {
	const onClick = (starValue) => onChange(starValue);

	return (
		<S.Wrapper error={error} hasOnChange={!!onChange}>
			{label && (
				<Typography tag="label" weight="semibold">
					{label}
				</Typography>
			)}
			<S.StarsWrapper>
				{[1, 2, 3, 4, 5].map((starValue) => {
					if (!!onChange) {
						return <IconButton key={starValue} type="button" icon={starValue <= value ? 'star' : ['fal', 'star']} onClick={() => onClick(starValue)} />;
					}
					return <S.RatingStarIcon key={starValue} icon={starValue <= value ? 'star' : ['fal', 'star']} />;
				})}
			</S.StarsWrapper>
			{error && error.message && (
				<S.FloatingWrapper title={error.message}>
					<Typography tag="p" variation="2">
						{error.message}
					</Typography>
				</S.FloatingWrapper>
			)}
		</S.Wrapper>
	);
};

RatingStars.propTypes = {
	label: PropTypes.string,
	value: PropTypes.number,
	onChange: PropTypes.func,
	error: PropTypes.object,
};
