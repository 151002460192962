import React, { forwardRef, useEffect, useState, useCallback, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactModal from 'react-modal';
import { useForm, Controller } from 'react-hook-form';

import * as S from './SavedSearchSection.styles';
import { AGE_GROUPS, CAREER_CLUSTERS, EMPLOYABILITY_SKILLS, LOCATION_TYPES, COUNTY_OPTIONS, RESOURCE_CATEGORIES, CURRICULUM_STANDARDS } from '../../../constants/general.constants';
import { addUpdateSearch, fetchSavedSearches } from '../../services/searches';
import { TextInput } from '../TextInput';
import { Typography } from '../Typography';
import { Button } from '../Button';
import { Select } from '../Select';
import { IconButton } from '../IconButton';
import { Checkbox } from '../Checkbox';
import { RangeInput } from '../RangeInput';
import { errorHandler } from '../../services/authService';
import { required } from '../../utils/form-default-errors';

const SaveSearchModal = forwardRef(({ onSuccessCallback = () => {} }, ref) => {
	const [isOpen, setIsOpen] = useState(false);

	const resourceCategories = [].concat(...Object.values(RESOURCE_CATEGORIES));

	const {
		handleSubmit,
		control,
		setValue,
		getValues,
		reset,
		register,
		formState: { errors, isSubmitting },
	} = useForm({
		defaultValues: {
			ageGroups: [],
			careerClusters: [],
			categories: [],
			counties: [],
			curriculumStandards: [],
			distance: 0,
			employabilitySkills: [],
			locationTypes: [],
		},
	});

	const handleOpenModal = (currentSelectedFilters) => {
		setValue('ageGroups', currentSelectedFilters?.ageGroups);
		setValue('careerClusters', currentSelectedFilters?.careerClusters);
		setValue('categories', currentSelectedFilters?.categories);
		setValue('counties', currentSelectedFilters?.counties);
		setValue('curriculumStandards', currentSelectedFilters?.curriculumStandards);
		setValue('distance', currentSelectedFilters?.distance);
		setValue('employabilitySkills', currentSelectedFilters?.employabilitySkills);
		setValue('locationTypes', currentSelectedFilters?.locationTypes);
		setIsOpen(true);
	};

	const handleOpenEditModal = (currentSavedSearch) => {
		setValue('searchId', currentSavedSearch?.id);
		setValue('name', currentSavedSearch?.name);
		setValue('ageGroups', currentSavedSearch?.ageGroups);
		setValue('careerClusters', currentSavedSearch?.careerClusters);
		setValue('categories', currentSavedSearch?.categories);
		setValue('counties', currentSavedSearch?.counties);
		setValue('curriculumStandards', currentSavedSearch?.curriculumStandards);
		setValue('distance', currentSavedSearch?.distance);
		setValue('employabilitySkills', currentSavedSearch?.employabilitySkills);
		setValue('locationTypes', currentSavedSearch?.locationTypes);
		setIsOpen(true);
	};

	const handleCloseModal = () => {
		setIsOpen(false);
		reset();
	};

	async function asyncCaller(data) {
		const {
			data: { result },
		} = await addUpdateSearch(data);
		handleCloseModal();
		onSuccessCallback(result.search, data?.searchId);
	}

	const onSubmit = (e) => {
		handleSubmit(asyncCaller)(e).catch(errorHandler);
	};

	useImperativeHandle(
		ref,
		() => ({
			open: handleOpenModal,
			openEdit: handleOpenEditModal,
			close: handleCloseModal,
		}),
		[handleOpenModal, handleCloseModal]
	);

	return (
		<ReactModal ref={ref} closeTimeoutMS={200} isOpen={isOpen} onRequestClose={handleCloseModal}>
			<S.Form onSubmit={onSubmit}>
				<IconButton className="close-button" type="button" icon={['fal', 'times']} onClick={handleCloseModal} />
				<div className="modal-content">
					<header className="modal-content__header">
						<Typography tag="h2" weight="extrablack" center>
							Save Search
						</Typography>
						<Typography tag="p" center>
							Please confirm the criteria which you would like to save for the search.
						</Typography>
					</header>
					<section className="modal-content__section">
						<Typography tag="h3" weight="bold">
							General Information
						</Typography>
						<TextInput
							label="Save Search Name"
							placeholder="Save Search Name"
							id="name"
							error={errors?.name}
							{...register('name', {
								required: required('Save Search Name'),
							})}
						/>
					</section>
					<section className="modal-content__section">
						<Typography tag="h3" weight="bold">
							Location
						</Typography>
						<Controller name="distance" control={control} render={({ field: { onChange, value } }) => <RangeInput error={errors?.distance} label="Distance" id="distance" unit="mi" value={value} onChange={onChange} />} />

						<Controller
							name="counties"
							control={control}
							render={({ field: { onChange, value } }) => (
								<Select
									isMulti
									getOptionValue={(option) => option.code}
									getOptionLabel={(option) => option.name}
									label="County"
									id="counties"
									placeholder="Counties"
									options={COUNTY_OPTIONS}
									value={value?.map((val) => COUNTY_OPTIONS.find((c) => c.code === val)) || ''}
									onChange={(val) => onChange(val?.map((val) => val.code))}
								/>
							)}
						/>

						<div className="checkbox-wrapper">
							<Typography className="field-label" tag="p" variation="2" weight="semibold">
								Location Type
							</Typography>

							{LOCATION_TYPES.map(({ id, name }) => (
								<div key={id} className="checkbox-field">
									<Checkbox id={id} value={id} {...register('locationTypes')} />
									<label htmlFor={id}>{name}</label>
								</div>
							))}
						</div>
					</section>
					<section className="modal-content__section">
						<Typography tag="h3" weight="bold">
							Resource
						</Typography>

						<Controller
							name="categories"
							control={control}
							render={({ field: { onChange, value } }) => (
								<Select isMulti label="Resource Category" id="categories" placeholder="Categories" options={resourceCategories} value={value?.map((val) => resourceCategories.find((c) => c.value === val)) || ''} onChange={(val) => onChange(val?.map((val) => val.value))} />
							)}
						/>

						<div className="checkbox-wrapper">
							<Typography className="field-label" tag="p" variation="2" weight="semibold">
								Career Cluster
							</Typography>

							{CAREER_CLUSTERS.map(({ value, label }) => (
								<div key={value} className="checkbox-field">
									<Checkbox id={value} value={value} {...register('careerClusters')} />
									<label htmlFor={value}>{label}</label>
								</div>
							))}
						</div>
						<div className="checkbox-wrapper">
							<Typography className="field-label" tag="p" variation="2" weight="semibold">
								Age Group
							</Typography>

							{AGE_GROUPS.map(({ value, label }) => (
								<div key={value} className="checkbox-field">
									<Checkbox id={value} value={value} {...register('ageGroups')} />
									<label htmlFor={value}>{label}</label>
								</div>
							))}
						</div>
						<div className="checkbox-wrapper">
							<Typography className="field-label" tag="p" variation="2" weight="semibold">
								Employability Skills
							</Typography>

							{EMPLOYABILITY_SKILLS.map(({ value, label }) => (
								<div key={value} className="checkbox-field">
									<Checkbox id={value} value={value} {...register('employabilitySkills')} />
									<label htmlFor={value}>{label}</label>
								</div>
							))}
						</div>

						<Controller
							name="curriculumStandards"
							control={control}
							render={({ field: { onChange, value } }) => (
								<Select
									isMulti
									label="Curriculum Standards"
									id="curriculumStandards"
									placeholder="Curriculum Standards"
									options={CURRICULUM_STANDARDS}
									value={value?.map((val) => CURRICULUM_STANDARDS.find((c) => c.value === val)) || ''}
									onChange={(val) => onChange(val?.map((val) => val.value))}
								/>
							)}
						/>
					</section>
				</div>
				<div className="modal-footer">
					<Button>
						<Typography variation="button-medium" weight="bold">
							{isSubmitting ? 'Loading' : getValues('searchId') ? 'Save' : 'Save'}
						</Typography>
					</Button>
				</div>
			</S.Form>
		</ReactModal>
	);
});

SaveSearchModal.displayName = 'SaveSearchModal';

SaveSearchModal.propTypes = {
	onSuccessCallback: PropTypes.func,
};

export const SavedSearchSection = forwardRef(({ currentSelectedFilters = {}, onSelectCallback = () => {} }, _ref) => {
	const [searchList, setSearchList] = useState([]);
	const [selectedOption, setSelectedOption] = useState(undefined);

	const modalRef = useRef();

	const openSaveSearchModal = () => {
		modalRef.current.open(currentSelectedFilters?.searchOptions);
	};

	const onSuccessCallback = (search, editedId) => {
		if (editedId) {
			const editedItemIndex = searchList.findIndex((item) => item.id === editedId);
			setSearchList((prev) => {
				const newList = [...prev];
				newList[editedItemIndex] = search;
				return newList;
			});
		} else {
			setSearchList((prev) => [...prev, search]);
		}
		setSelectedOption(search);
	};

	const handleSavedSearchChange = (option) => {
		setSelectedOption(option);
		onSelectCallback(option);
	};

	const fetchSavedSearchesCb = useCallback(async () => {
		try {
			const {
				data: { result },
			} = await fetchSavedSearches({ page: 0 });
			setSearchList(result.searches);
		} catch (error) {
			errorHandler(error);
		}
	}, []);

	useEffect(() => {
		fetchSavedSearchesCb();
	}, [fetchSavedSearchesCb]);

	return (
		<S.Wrapper>
			<S.FieldWrapper className="savedsearch-select">
				<Typography tag="label" weight="bold">
					Saved Search:
				</Typography>
				<Select
					getOptionValue={(option) => option}
					getOptionLabel={(option) => option.name}
					value={selectedOption}
					onChange={handleSavedSearchChange}
					options={searchList}
					size="small"
					placeholder="Search"
					icon={selectedOption ? ['fal', 'edit'] : null}
					iconBtnOnClick={() => modalRef.current.openEdit(selectedOption)}
				/>
			</S.FieldWrapper>
			<Button variant="outline" onClick={openSaveSearchModal}>
				<FontAwesomeIcon icon={['fal', 'bookmark']} />
				<Typography weight="bold" variation="button-medium">
					Save Search
				</Typography>
			</Button>
			<SaveSearchModal ref={modalRef} onSuccessCallback={onSuccessCallback} />
		</S.Wrapper>
	);
});

SavedSearchSection.displayName = 'SavedSearchSection';

SavedSearchSection.propTypes = {
	currentSelectedFilters: PropTypes.object,
	onSelectCallback: PropTypes.func,
};
