import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTable, useFlexLayout } from 'react-table';
import { useHistory } from 'react-router-dom';
import { useDebouncedFn } from 'beautiful-react-hooks';
import toast from 'react-hot-toast';

import { GROUP_SORT_OPTIONS } from '../../../../constants/general.constants';
import { fetchGroups } from '../../../services/groups';
import { Typography, Card, TableComponents, Spinner, ErrorComponent, EmptyComponent, FilterSection, Button } from '../../../components';
import * as S from './CurrentTab.styles';

export const CurrentTab = ({ newGroups }) => {
	const history = useHistory();
	const [pageStatus, setPageStatus] = useState('idle');
	const [groups, setGroups] = useState([]);

	const initialPageNumber = 0;
	const [pageNumber, setPageNumber] = useState(initialPageNumber);
	const [totalPages, setTotalPages] = useState(undefined);
	const [loadingMore, setLoadingMore] = useState(false);

	const filterRef = useRef();

	const onChangeCallback = useDebouncedFn(async () => {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchGroups({ page: initialPageNumber, filterOptions: { archived: false }, ...filterRef.current.value });
			setGroups(result.groups);
			setPageNumber(initialPageNumber);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}, 300);

	async function loadMore() {
		setLoadingMore(true);
		try {
			if (totalPages > pageNumber + 1) {
				const {
					data: { result },
				} = await fetchGroups({ page: pageNumber + 1, filterOptions: { archived: false }, ...filterRef.current.value });
				setGroups((prev) => [...prev, ...result.groups]);
				setTotalPages(result.totalPages);
				setPageNumber((prev) => prev + 1);
			} else {
				toast.error('No more resources to load');
			}
			setLoadingMore(false);
		} catch (error) {
			setLoadingMore(false);
		}
	}

	async function initializeData() {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchGroups({ page: initialPageNumber, filterOptions: { archived: false } });
			setGroups(result.groups);
			setTotalPages(result.totalPages);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}

	useEffect(() => {
		setGroups((prev) => {
			const newData = [...prev];
			newData.push(...newGroups);
			setGroups(newData);
		});
	}, [newGroups]);

	useEffect(() => {
		// Load initial data
		if (pageStatus === 'idle') {
			initializeData();
		}
	}, [pageStatus]);

	const columns = React.useMemo(
		() => [
			{
				Header: 'Name',
				accessor: 'name',
			},
			{
				Header: 'Grade Level',
				accessor: 'gradeLevel',
			},
			{
				Header: '# of Students',
				accessor: 'totalStudents',
			},
			{
				Header: 'Age Range',
				accessor: 'ageRange',
			},
			{
				Header: 'Class Status',
				accessor: 'isClass',
				Cell: TableComponents.Cells.ClassStatusCell,
			},
		],
		[]
	);
	const data = React.useMemo(() => groups, [groups]);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
		{
			columns,
			data,
		},
		useFlexLayout
	);

	const renderContent = () => {
		switch (pageStatus) {
			case 'loading':
				return <Spinner />;
			case 'error':
				return <ErrorComponent />;
			case 'success':
				return rows.length > 0 ? (
					<table {...getTableProps()}>
						<thead>
							{headerGroups.map(({ key, ...headerGroup }) => (
								<tr key={key} {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map(({ key, ...column }) => (
										<th key={key} {...column.getHeaderProps()}>
											<Typography tag="h6" weight="semibold">
												{column.render('Header')}
											</Typography>
										</th>
									))}
								</tr>
							))}
						</thead>
						<tbody {...getTableBodyProps()}>
							{rows.map(({ key, ...row }) => {
								prepareRow(row);
								return (
									<TableComponents.ClickableRow
										key={key}
										{...row.getRowProps()}
										onClick={() => {
											history.push({ pathname: `/teacher/group/${row.original.groupId}`, state: { back: window.location.pathname } });
										}}
									>
										{row.cells.map(({ key, ...cell }) => {
											return (
												<td key={key} {...cell.getCellProps()}>
													<Typography tag="p" variation="2">
														{cell.render('Cell')}
													</Typography>
												</td>
											);
										})}
									</TableComponents.ClickableRow>
								);
							})}
						</tbody>
					</table>
				) : (
					<EmptyComponent title="No current groups" message="Create a group to get started." icon={['fal', 'users']} />
				);
			default:
				return null;
		}
	};

	return (
		<S.Wrapper>
			<FilterSection
				ref={filterRef}
				onChangeCallback={onChangeCallback}
				initialState={{
					searchText: '',
					sortOption: GROUP_SORT_OPTIONS[0],
					showAdvancedFilters: false,
					activeFilters: [],
					distance: 0,
					counties: [],
					locationTypes: [],
					categories: [],
					careerClusters: [],
					ageGroups: [],
					employabilitySkills: [],
					curriculumStandards: [],
				}}
				sortOptions={GROUP_SORT_OPTIONS}
				filterOptions={['ageGroups']}
			/>
			<Card>
				<TableComponents.TableWrapper>{renderContent()}</TableComponents.TableWrapper>
			</Card>
			{groups?.length > 0 && totalPages > pageNumber + 1 && (
				<S.LoadMoreWrapper>
					<Button variant="outline" variation="secondary" type="button" onClick={loadMore}>
						<Typography variation="button-medium" weight="bold">
							{loadingMore ? 'Loading...' : 'Load More'}
						</Typography>
					</Button>
				</S.LoadMoreWrapper>
			)}
		</S.Wrapper>
	);
};

CurrentTab.propTypes = {
	newGroups: PropTypes.array,
};
