import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ROLES } from '../../../constants/general.constants';
import { TeacherNavigation, PublicNavigation, PageHeader, Tab, AdminNavigation } from '../../components';
import { PendingTab } from './PendingTab';
import { ScheduledTab } from './ScheduledTab';
import { DraftsTab } from './DraftsTab';
import { CompletedTab } from './CompletedTab';
import { IncompleteTab } from './IncompleteTab';
import { ArchivedTab } from './ArchivedTab';
import { PublicRequests } from './PublicRequests';
import * as S from './Requests.styles';
import { META_TITLE } from '../../../constants/general.constants';

const Requests = ({ role }) => {
	const history = useHistory();
	useEffect(() => {
		// Set document title
		document.title = role != null ? `Requests | Dashboard | ${META_TITLE}` : `Requests | ${META_TITLE}`;
	}, []);

	const [tabs, setTabs] = useState([
		{
			id: 1,
			label: 'Pending',
			component: <PendingTab role={role} />,
		},
		{
			id: 2,
			label: 'Scheduled',
			component: <ScheduledTab role={role} />,
		},
		{
			id: 3,
			label: 'Drafts',
			component: <DraftsTab role={role} />,
		},
		{
			id: 4,
			label: 'Completed',
			component: <CompletedTab role={role} />,
		},
		{
			id: 5,
			label: 'Incomplete',
			component: <IncompleteTab role={role} />,
		},
		{
			id: 6,
			label: 'Archived',
			component: <ArchivedTab role={role} />,
		},
	]);

	switch (role) {
		case ROLES.SUPER:
		case ROLES.ADMIN:
			return (
				<AdminNavigation>
					<PageHeader title="Requests" action={[{ id: 1, onClick: () => history.push('/admin/create-request'), label: 'Create Request', icon: ['fal', 'plus'] }]} />
					<S.Wrapper>
						<Tab tabs={tabs} setTabs={setTabs} />
					</S.Wrapper>
				</AdminNavigation>
			);
		case ROLES.TEACHER:
			return (
				<TeacherNavigation>
					<PageHeader title="Requests" action={[{ id: 1, onClick: () => history.push('/teacher/create-request'), label: 'Create Request', icon: ['fal', 'plus'] }]} />
					<S.Wrapper>
						<Tab tabs={tabs} setTabs={setTabs} />
					</S.Wrapper>
				</TeacherNavigation>
			);
		default:
			return (
				<PublicNavigation>
					<PageHeader title="Requests" />
					<S.Wrapper>
						<PublicRequests />
					</S.Wrapper>
				</PublicNavigation>
			);
	}
};

Requests.propTypes = {
	role: PropTypes.string,
};

export default Requests;
