import styled from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1rem 1rem 2rem;

	& > * + * {
		border-top: 1px solid ${colors.neutralForm};
		padding-top: 0.75rem;
		margin-top: 1rem;
	}
`;

export const LoadMoreWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 1.75rem 0 0;
	border: none;
`;
