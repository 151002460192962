import { createSlice } from '@reduxjs/toolkit';

const KEY = 'NCBCE_businessDashboard';

export function loadBusinessDashboardState() {
	try {
		const serializedState = localStorage.getItem(KEY);
		if (!serializedState) return undefined;
		return JSON.parse(serializedState);
	} catch (e) {
		return undefined;
	}
}

export function saveBusinessDashboardState(state) {
	try {
		const serializedState = JSON.stringify(state);
		localStorage.setItem(KEY, serializedState);
	} catch (e) {
		console.error(e);
	}
}

export const businessDashboardSlice = createSlice({
	name: 'businessDashboard',
	initialState: {
		showActive: false,
		showPending: true,
		showEmployees: true,
		showCompleteProfile: true,
	},
	reducers: {
		addActive: (state, action) => {
			state.showActive = action.payload;
		},
		removeActive: (state) => {
			state.showActive = false;
		},
		removePending: (state) => {
			state.showPending = false;
		},
		removeEmployees: (state) => {
			state.showEmployees = false;
		},
		removeCompleteProfile: (state) => {
			state.showCompleteProfile = false;
		},
	},
});

export const { addActive, removeActive, removePending, removeEmployees, removeCompleteProfile } = businessDashboardSlice.actions;

export default businessDashboardSlice.reducer;
