import styled from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1.5rem 1rem;
`;

export const Header = styled.header`
	width: 100%;
	display: flex;
	align-items: center;

	margin-bottom: 1.5rem;

	.centered {
		display: flex;
		flex: 1;
		justify-content: center;
	}

	.flex-1 {
		flex: 1;

		.button-label {
			display: none;

			@media screen and (min-width: 768px) {
				display: block;
			}
		}
	}
`;

export const Picture = styled.picture`
	display: flex;
	height: 3.125rem;

	img {
		height: 100%;
	}
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 55rem;
	background-color: white;
	border: 1px solid ${colors.neutralForm};
	border-radius: 0.375rem;
	padding: 2rem 1rem 1.5rem;

	.description {
		margin-top: 1rem;
	}

	@media screen and (min-width: 768px) {
		padding: 3.4375rem 1rem 1.5rem;
	}
`;

export const FormWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 25.625rem;
	margin: 4rem auto 0;
	color: ${colors.neutralButton};

	.form-title {
		margin-top: 1.5rem;
		margin-bottom: 0.5rem;
	}

	.form-description {
		margin-bottom: 1.5rem;
	}

	.skip-button {
		margin-top: 8rem;
	}
`;

export const GroupAvatar = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 10.3125rem;
	height: 10.3125rem;
	border: 1px solid ${colors.neutralForm};
	background-color: ${colors.neutralBackground};
	color: ${colors.neutralButton};
	border-radius: 9999px;
	overflow: hidden;

	.users-icon {
		width: 5rem;
		height: 5rem;
	}
`;

export const GroupListWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 25.625rem;
	margin: 2rem auto;

	.add-new-group {
		align-self: flex-start;
		padding-left: 0;
		padding-right: 0;
	}

	.complete-onboarding {
		align-self: center;
		margin-top: 8rem;
	}
`;

export const GroupListItem = styled.div`
	border: 1px solid ${colors.neutralForm};
	border-radius: 0.375rem;
	padding: 0.75rem 0.875rem;
	margin-bottom: 0.5rem;

	&:last-of-type {
		margin-bottom: 0;
	}

	.group-header {
		display: flex;
		justify-content: space-between;

		button {
			color: ${colors.brandPrimary};
		}
	}

	.details-wrapper {
		display: grid;
		margin-top: 0.75rem;
		gap: 0.5rem;
		grid-template-columns: repeat(2, 1fr);

		.detail-item {
			display: flex;
			color: ${colors.neutralButton};

			.icon-wrapper {
				display: flex;
				justify-content: center;
				width: 1.5rem;
			}

			& > * + * {
				margin-left: 0.5rem;
			}
		}
	}
`;
