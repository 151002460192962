import styled, { css } from 'styled-components';

import colors from '../../styles/colors';
import { hideScrollbar } from '../../styles/utils';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	overflow-x: hidden;
	margin: -0.25rem -1rem 0;

	${({ variation }) =>
		variation === 'pill' &&
		css`
			padding: 0 1rem;
		`}
`;

export const Badge = styled.div`
	min-width: 1.25rem;
	width: 1.25rem;
	margin-left: 0.375rem;
	font-size: 0.625rem;
	font-weight: 700;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	color: ${colors.neutralText};
	border: 1px solid ${colors.neutralText};
	border-radius: 0.25rem;
`;

export const Head = styled.div`
	display: flex;
	flex-direction: row;
	overflow-x: scroll;
	white-space: nowrap;
	background-color: ${({ transparent }) => (transparent ? 'transparent' : 'white')};
	position: relative;
	box-shadow: ${({ transparent }) => (transparent ? 'none' : colors.sidebarShadow)};
	border-bottom: ${({ transparent }) => (transparent ? `1px solid ${colors.neutralForm}` : 'none')};

	${hideScrollbar}
`;

export const TabButton = styled.button`
	display: flex;
	justify-content: center;
	text-align: center;
	appearance: none;
	border: none;
	background-color: transparent;
	padding: 0.5rem 1rem;
	flex: 1;
	cursor: pointer;
	color: ${({ active }) => (active ? colors.brandPrimary : colors.neutralText)};

	& > *:first-child {
		position: relative;
	}

	${({ variation }) =>
		variation === 'pill' &&
		css`
			padding: 0;
			margin: 0.25rem 0 0.75rem;
			border-radius: 6.25rem;

			h6 {
				font-weight: 400;
				padding: 0.25rem 1rem;
			}
		`}

	${({ active, variation }) =>
		variation === 'default' &&
		active &&
		css`
			${Badge} {
				color: ${colors.brandPrimary};
				border-color: ${colors.brandPrimary};
			}
		`}

	${({ active, variation }) =>
		variation === 'pill' &&
		active &&
		css`
			background-color: ${colors.brandPrimary};

			h6 {
				font-weight: 600;
				color: white;
			}
		`}


	@media screen and (min-width: 768px) {
		flex: 0;
	}
`;

export const ActiveIndicator = styled.div`
	position: absolute;
	bottom: -0.5rem;
	left: 0;
	right: 0;
	height: 0.125rem;
	background-color: ${colors.brandPrimary};

	${({ variation }) =>
		variation === 'pill' &&
		css`
			display: none;
		`}
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;

	&.padding {
		padding: 1rem;
	}
`;
