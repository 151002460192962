import styled from 'styled-components';

export const Wrapper = styled.div`
	display: grid;
	gap: 1rem;

	.topbar-grid {
		display: grid;
		grid-template-columns: 1fr;

		& > *:last-child {
			justify-self: start;
		}

		@media (min-width: 1200px) {
			grid-template-columns: 1fr auto;
			gap: 1rem;

			& > *:last-child {
				align-self: start;
			}
		}
	}
`;

export const LoadMoreWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 1.75rem 0 0;
	border: none;
`;
