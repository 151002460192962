import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { META_TITLE, REQUEST_SORT_OPTIONS, ROLES } from '../../../constants/general.constants';
import { RequestPreferencesModal } from './RequestPreferencesModal';
import { AdminNavigation, BusinessNavigation, Button, ErrorComponent, FilterSection, PageHeader, Spinner, Typography, Toggle, IconButton } from '../../components';
import * as S from './RequestsDirectory.styles';
import { useDebouncedFn } from 'beautiful-react-hooks';
import { fetchRequests, saveUnsaveRequest } from '../../services/requests';
import { DirectoryCardsGrid } from './DirectoryCardsGrid';

const RequestsDirectory = () => {
	const user = useSelector((state) => state.user.value);
	const history = useHistory();
	const [pageStatus, setPageStatus] = useState('idle');
	const [requests, setRequests] = useState([]);
	const [useRequestPreferences, setUseRequestPreferences] = useState(Object.keys(user?.requestPreferences)?.length > 0);

	const initialPageNumber = 0;
	const [pageNumber, setPageNumber] = useState(initialPageNumber);
	const [totalPages, setTotalPages] = useState(undefined);
	const [totalItems, setTotalItems] = useState(0);
	const [loadingMore, setLoadingMore] = useState(false);

	const requestPreferencesModalRef = useRef(null);
	const filterRef = useRef();

	const onChangeCallback = useDebouncedFn(async () => {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchRequests({
				page: initialPageNumber,
				...filterRef.current.value,
				filterOptions: {
					general: true,
					useRequestPreferences,
				},
			});
			setRequests(result.requests);
			setPageNumber(initialPageNumber);
			setTotalPages(result.totalPages);
			setTotalItems(result.totalItems);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}, 300);

	const handleSaveUnsave = async (id) => {
		try {
			const {
				data: { result },
			} = await saveUnsaveRequest({ requestId: id });
			const newRequests = [...requests];
			newRequests[newRequests.findIndex((el) => el.id === result.request.id)] = result.request;
			setRequests(newRequests);
		} catch (error) {}
	};

	async function loadMore() {
		setLoadingMore(true);
		try {
			if (totalPages > pageNumber + 1) {
				const {
					data: { result },
				} = await fetchRequests({
					page: pageNumber + 1,
					...filterRef.current.value,
					filterOptions: {
						general: true,
						useRequestPreferences,
					},
				});
				setRequests((prev) => [...prev, ...result.requests]);
				setTotalPages(result.totalPages);
				setTotalItems(result.totalItems);
				setPageNumber((prev) => prev + 1);
			} else {
				toast.error('No more requests to load');
			}
			setLoadingMore(false);
		} catch (error) {
			setLoadingMore(false);
		}
	}

	async function initializeData() {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchRequests({
				page: initialPageNumber,
				filterOptions: {
					general: true,
					useRequestPreferences,
				},
			});
			setRequests(result.requests);
			setTotalPages(result.totalPages);
			setTotalItems(result.totalItems);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}

	const renderContent = () => {
		switch (pageStatus) {
			case 'loading':
				return <Spinner />;
			case 'error':
				return <ErrorComponent />;
			case 'success':
				return (
					<>
						<DirectoryCardsGrid data={requests} role={user.role} handleSaveUnsave={handleSaveUnsave} totalItems={totalItems} />
						{requests?.length > 0 && totalPages > pageNumber + 1 && (
							<S.LoadMoreWrapper>
								<Button variant="outline" variation="secondary" type="button" onClick={loadMore}>
									<Typography variation="button-medium" weight="bold">
										{loadingMore ? 'Loading...' : 'Load More'}
									</Typography>
								</Button>
							</S.LoadMoreWrapper>
						)}
					</>
				);
			default:
				return null;
		}
	};

	useEffect(() => {
		if (pageStatus !== 'idle') {
			onChangeCallback();
		}
	}, [useRequestPreferences]);

	useEffect(() => {
		document.title = `Requests Directory | ${META_TITLE}`;

		if (pageStatus === 'idle') {
			initializeData();
		}
	}, [pageStatus]);

	switch (user.role) {
		case ROLES.SUPER:
		case ROLES.ADMIN:
			return (
				<AdminNavigation>
					<PageHeader title="Request Directory" action={[{ id: 1, onClick: () => history.push('/admin/create-request'), label: 'Create Request', icon: ['fal', 'plus'] }]} />
					<S.Wrapper>
						<S.Subheader>
							<div className="p-container">
								<Typography variation="2" weight="bold">
									Request Preferences:
								</Typography>
								{Object.keys(user?.requestPreferences)?.length > 0 ? (
									<S.ToggleLine>
										<Toggle name="useRequestPreferences" checked={useRequestPreferences} onChange={(e) => setUseRequestPreferences(e.target.checked)} />
										<Typography variation="2">My Saved Request Preference</Typography>
										<IconButton onClick={() => requestPreferencesModalRef.current.edit(user.requestPreferences)} icon={['fal', 'edit']} size={1} />
									</S.ToggleLine>
								) : (
									<Typography variation="2">No Request Preferences</Typography>
								)}
							</div>
							{!Object.keys(user?.requestPreferences)?.length && (
								<Button variant="outline" onClick={() => requestPreferencesModalRef.current.open()}>
									<FontAwesomeIcon icon={['fal', 'cog']} />
									<Typography weight="semibold" variation="button-small">
										Set Request Preferences
									</Typography>
								</Button>
							)}
						</S.Subheader>
						<RequestPreferencesModal ref={requestPreferencesModalRef} />
						<FilterSection
							ref={filterRef}
							onChangeCallback={onChangeCallback}
							filterOptions={['distance', 'counties', 'locationTypes', 'categories', 'careerClusters', 'ageGroups']}
							initialState={{
								searchText: '',
								sortOption: REQUEST_SORT_OPTIONS[3],
								showAdvancedFilters: false,
								activeFilters: [],
								distance: 0,
								counties: [],
								locationTypes: [],
								categories: [],
								careerClusters: [],
								ageGroups: [],
							}}
							sortOptions={REQUEST_SORT_OPTIONS}
						/>
						{renderContent()}
					</S.Wrapper>
				</AdminNavigation>
			);
		default:
			return (
				<BusinessNavigation>
					<PageHeader title="Request Directory" action={[{ id: 1, onClick: () => history.push('/business/create-request'), label: 'Create Request', icon: ['fal', 'plus'] }]} />
					<S.Wrapper>
						<S.Subheader>
							<div className="p-container">
								<Typography variation="2" weight="bold">
									Request Preferences:
								</Typography>
								{Object.keys(user?.requestPreferences)?.length > 0 ? (
									<S.ToggleLine>
										<Toggle name="useRequestPreferences" checked={useRequestPreferences} onChange={(e) => setUseRequestPreferences(e.target.checked)} />
										<Typography variation="2">My Saved Request Preference</Typography>
										<IconButton onClick={() => requestPreferencesModalRef.current.edit(user.requestPreferences)} icon={['fal', 'edit']} size={1} />
									</S.ToggleLine>
								) : (
									<Typography variation="2">No Request Preferences</Typography>
								)}
							</div>
							{!Object.keys(user?.requestPreferences)?.length && (
								<Button variant="outline" onClick={() => requestPreferencesModalRef.current.open()}>
									<FontAwesomeIcon icon={['fal', 'cog']} />
									<Typography weight="semibold" variation="button-small">
										Set Request Preferences
									</Typography>
								</Button>
							)}
						</S.Subheader>
						<RequestPreferencesModal ref={requestPreferencesModalRef} />
						<FilterSection
							ref={filterRef}
							onChangeCallback={onChangeCallback}
							filterOptions={['distance', 'counties', 'locationTypes', 'categories', 'careerClusters', 'ageGroups']}
							initialState={{
								searchText: '',
								sortOption: REQUEST_SORT_OPTIONS[3],
								showAdvancedFilters: false,
								activeFilters: [],
								distance: 0,
								counties: [],
								locationTypes: [],
								categories: [],
								careerClusters: [],
								ageGroups: [],
							}}
							sortOptions={REQUEST_SORT_OPTIONS}
						/>
						{renderContent()}
					</S.Wrapper>
				</BusinessNavigation>
			);
	}
};

RequestsDirectory.propTypes = {};

export default RequestsDirectory;
