import styled from 'styled-components';

import colors from '../../styles/colors';

export const Form = styled.form`
	position: relative;

	.close-button {
		position: absolute;
		top: 1rem;
		right: 1rem;
		color: ${colors.neutralButton};
	}

	.modal-content {
		display: grid;
		grid-template-columns: 1fr;
		gap: 2rem;
		padding: 4rem 0 0;

		&__header {
			display: grid;
			grid-template-columns: 1fr;
			gap: 0.5rem;
			margin-bottom: 1rem;
		}

		&__section {
			display: grid;
			grid-template-columns: 1fr;
			gap: 1.5rem;
			margin: 0 auto;
			padding: 0 1rem;
			width: 100%;
			max-width: 41.25rem;

			.avatar-element {
				justify-self: center;
			}

			.information-card {
				display: flex;
				padding: 0.5rem 0.75rem;
				background-color: ${colors.neutralBackground};
				border: 1px solid ${colors.neutralForm};
				border-radius: 0.375rem;
				margin-top: 0.5rem;

				.icon-wrapper {
					display: flex;
					justify-content: center;
					width: 1.25rem;
					color: ${colors.brandPrimary};
				}

				.info-wrapper {
					margin-left: 0.75rem;
					color: #000000;

					.info-line {
						display: flex;
						margin-top: 0.5rem;
						color: ${colors.neutralButton};

						svg {
							width: 1rem;
							height: 1rem;
						}

						p {
							margin-left: 0.5rem;
						}
					}
				}
			}
		}
	}

	.modal-footer {
		display: flex;
		justify-content: flex-end;
		padding: 1.5rem;
		border-top: 1px solid ${colors.neutralForm};
	}
`;
