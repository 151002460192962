import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { closeMenu } from '../../../app/slices/menu/menuSlice';
import { NavLink } from './NavLink';
import * as S from './Sidebar.styles';

export const Sidebar = ({ links = [] }) => {
	const opened = useSelector((state) => state.menu.opened);
	const collapsed = useSelector((state) => state.menu.collapsed);
	const dispatch = useDispatch();

	return (
		<>
			<S.Overlay opened={opened} onClick={() => dispatch(closeMenu())} />
			<S.Sidebar opened={opened} collapsed={collapsed}>
				<S.LinksWrapper>
					<ul>
						{links.map((link) =>
							!!link.to || !!link.redirect ? (
								<NavLink
									key={link.id}
									to={link.to}
									icon={link.icon}
									onClick={() => {
										dispatch(closeMenu());
									}}
								>
									{link.label}
								</NavLink>
							) : (
								<S.LinkTitle key={link.id}>{link.label}</S.LinkTitle>
							)
						)}
					</ul>
				</S.LinksWrapper>
			</S.Sidebar>
		</>
	);
};

Sidebar.propTypes = {
	logoLink: PropTypes.string,
	links: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
			to: PropTypes.string,
			label: PropTypes.string.isRequired,
			icon: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])),
		})
	),
};
