import styled from 'styled-components';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1rem 0rem 2rem;
`;

export const LoadMoreWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 1.75rem 0 0;
	border: none;
`;
