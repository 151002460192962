import styled, { css } from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: grid;
	gap: 1.25rem;
	padding: 1.25rem 1rem 2.5rem;

	.a1 {
		grid-area: a1;
	}
	.a2 {
		grid-area: a2;
	}
	.a3 {
		grid-area: a3;
	}

	grid-template-areas:
		'a1'
		'a2'
		'a3';

	@media screen and (min-width: 992px) {
		grid-template-columns: repeat(12, 1fr);
		grid-template-areas:
			'a1 a1 a1 a1 a1 a1 a1 a1 a1 a1 a1 a1'
			'a2 a2 a2 a2 a2 a2 a2 a2 a3 a3 a3 a3';
	}
`;

export const EmptyBusinessCard = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	${({ paddingTop = 2.625, paddingBottom = 3.375 }) => css`
		padding-top: ${paddingTop}rem;
		padding-bottom: ${paddingBottom}rem;
	`}

	.icon-box {
		margin-bottom: 1.25rem;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 10rem;
		height: 10rem;
		border-radius: 9999px;
		background-color: ${colors.neutralBackground};
		border: 1px solid ${colors.neutralForm};
		color: ${colors.neutralButton};
	}

	& > h4 {
		color: ${colors.neutralButton};
		margin-bottom: 0.5rem;
	}
	& > p {
		color: ${colors.neutralButton};
	}
`;
