import styled from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	padding: 1.75rem 1.5rem 1.5rem;
	text-align: center;
`;

export const IconWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin-bottom: 1.5rem;

	.circle-wrapper {
		display: grid;
		place-content: center;
		width: 7.5rem;
		height: 7.5rem;
		background-color: ${colors.neutralBackground};
		border-radius: 9999px;

		svg {
			color: ${colors.brandPrimary};
		}
	}
`;

export const InfoWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	gap: 0.5rem;
	max-width: 31.25rem;
	margin: 0 auto;
`;

export const ButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 2rem;

	button {
		margin: 0px 0.5rem;
	}
`;
