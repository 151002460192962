import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { useMediaQuery } from 'beautiful-react-hooks';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

import { Button, HugeDropdown, MessageDialog, PageHeader, TeacherNavigation, BusinessNavigation, AdminNavigation, Typography } from '../../components';
import { META_TITLE, ROLES } from '../../../constants/general.constants';
import { errorHandler } from '../../services/authService';
import { createRequest, publishRequest } from '../../services/requests/createRequestService';
import * as S from './CreateRequest.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StepResourceType } from './StepResourceType';
import { StepResourceCategory } from './StepResourceCategory';
import { StepTemplate } from './StepTemplate';
import { StepReview } from './StepReview';
import { StepDetails } from './StepDetails';
import { uploadResources } from '../../services/media';

const CreateRequest = ({ role }) => {
	const STEP_DETAILS_OPTIONS = {
		appointment: [{ id: 1, name: 'Overview' }, { id: 2, name: 'Files' }, { id: 3, name: 'Location' }, { id: 4, name: 'Availability' }, role === ROLES.TEACHER && { id: 5, name: 'Group' }].filter(Boolean),
		information: [{ id: 1, name: 'Overview' }, { id: 2, name: 'Files' }, { id: 3, name: 'Links' }, role === ROLES.TEACHER && { id: 4, name: 'Group' }].filter(Boolean),
	};

	const history = useHistory();
	const location = useLocation();

	const [steps, setSteps] = useState([
		{ id: 1, name: 'Resource Type', completed: false },
		{ id: 2, name: 'Resource Category', completed: false },
		{ id: 3, name: 'Template', completed: false },
		{ id: 4, name: 'Details', completed: false },
		{ id: 5, name: 'Review', completed: false },
	]);

	const [confirmationDraftIsOpen, setConfirmationDraftIsOpen] = useState(false);
	const [confirmationIsOpen, setConfirmationIsOpen] = useState(false);

	const isMobile = useMediaQuery('(max-width: 992px)');
	const [selectedStep, setSelectedStep] = useState(steps[0]);
	const methods = useForm();

	const type = methods.watch('type');
	const [selectedDetailsStepId, setSelectedDetailsStepId] = useState(1);

	function handleOpenConfirmationModal() {
		setConfirmationIsOpen(true);
	}

	function handleCloseConfirmationModal() {
		setConfirmationIsOpen(false);
		history.push(`/${role || 'teacher'}/requests`);
	}

	function handleOpenConfirmationDraftModal() {
		setConfirmationDraftIsOpen(true);
	}

	function handleCloseConfirmationDraftModal() {
		setConfirmationDraftIsOpen(false);
		history.push(`/${role || 'teacher'}/requests`);
	}

	async function handleDraftClick() {
		const { coverImage, publicFiles, privateFiles, ...rest } = methods.getValues();

		if (coverImage instanceof File) {
			const coverImageData = await uploadCoverImage(coverImage);
			rest.coverImageObj = coverImageData;
		} else {
			rest.coverImageObj = coverImage;
		}

		if (publicFiles?.length > 0) {
			const publicFilesData = await Promise.all(publicFiles.map(uploadFile));
			rest.publicFiles = publicFilesData;
		}
		if (privateFiles?.length > 0) {
			const privateFilesData = await Promise.all(privateFiles.map(uploadFile));
			rest.privateFiles = privateFilesData;
		}

		const payload = {
			...rest,
			startDate: rest.startDate ? format(rest.startDate, 'MM-dd-yyyy') : null,
			endDate: rest.endDate ? format(rest.endDate, 'MM-dd-yyyy') : null,
			templateId: rest.templateId === 'START_FROM_SCRATCH' ? null : rest.templateId,
			privateFiles: rest.privateFiles ? rest.privateFiles : [],
			publicFiles: rest.publicFiles ? rest.publicFiles : [],
			links: rest.links ? rest.links : [],
			isOngoing: rest.isOngoing === 'yes',
			userId: location.state?.userId,
		};

		await createRequest(payload);

		handleOpenConfirmationDraftModal();
	}

	async function uploadCoverImage(file) {
		const formData = new FormData();
		formData.append('file', file);

		const { data } = await uploadResources(formData);

		return data;
	}

	async function uploadFile(file) {
		const formData = new FormData();
		formData.append('file', file);

		const {
			data: { document },
		} = await uploadResources(formData);

		return { title: file?.title, description: file?.description, url: document };
	}

	async function asyncCaller(data) {
		const { coverImage, publicFiles, privateFiles, ...rest } = data;

		if (coverImage instanceof File) {
			const coverImageData = await uploadCoverImage(coverImage);
			rest.coverImageObj = coverImageData;
		} else {
			rest.coverImageObj = coverImage;
		}

		if (publicFiles?.length > 0) {
			const publicFilesData = await Promise.all(publicFiles.map(uploadFile));
			rest.publicFiles = publicFilesData;
		}
		if (privateFiles?.length > 0) {
			const privateFilesData = await Promise.all(privateFiles.map(uploadFile));
			rest.privateFiles = privateFilesData;
		}

		const payload = {
			...rest,
			startDate: rest.startDate ? format(rest.startDate, 'MM-dd-yyyy') : null,
			endDate: rest.endDate ? format(rest.endDate, 'MM-dd-yyyy') : null,
			templateId: rest.templateId === 'START_FROM_SCRATCH' ? null : rest.templateId,
			privateFiles: rest.privateFiles ? rest.privateFiles : [],
			publicFiles: rest.publicFiles ? rest.publicFiles : [],
			links: rest.links ? rest.links : [],
			isOngoing: rest.isOngoing === 'yes',
			userId: location.state?.userId,
		};

		const {
			data: {
				result: { request },
			},
		} = await createRequest(payload);

		await publishRequest(request.id);

		handleOpenConfirmationModal();
	}

	const completeStep = (stepIndex) => {
		setSteps((prev) => {
			const newSteps = [...prev];
			newSteps[stepIndex].completed = true;
			return newSteps;
		});
	};

	const uncompleteStep = (stepIndex) => {
		setSteps((prev) => {
			const newSteps = [...prev];
			newSteps[stepIndex].completed = false;
			return newSteps;
		});
	};

	const handleBackButton = () => {
		if (selectedStep.id > 1) {
			if (selectedStep.id === 4 && selectedDetailsStepId > 1) {
				setSelectedDetailsStepId(selectedDetailsStepId - 1);
			} else {
				uncompleteStep(selectedStep.id - 2);
				if (selectedStep.id - 1 === 4) {
					setSelectedDetailsStepId(STEP_DETAILS_OPTIONS[type].length);
				}
				setSelectedStep(steps[selectedStep.id - 2]);
			}
		} else {
			history.goBack();
		}
	};

	const handleStepClick = (step) => {
		if (step.completed || steps[step.id - 2].completed) {
			if (step.id === 4) {
				setSelectedDetailsStepId(1);
			}
			setSelectedStep(step);
		}
	};

	const handleDetailStepOptionClick = (stepId) => {
		setSelectedStep(steps[3]);
		setSelectedDetailsStepId(stepId);
	};

	const onSubmit = (e) => {
		switch (selectedStep.id) {
			case 1:
				methods.handleSubmit(() => {
					completeStep(0);
					setSelectedStep(steps[selectedStep.id]);
				})(e);
				break;
			case 2:
				methods.handleSubmit(() => {
					completeStep(1);
					setSelectedStep(steps[selectedStep.id]);
				})(e);
				break;
			case 3:
				methods.handleSubmit(() => {
					completeStep(2);
					setSelectedStep(steps[selectedStep.id]);
				})(e);
				break;
			case 4:
				methods.handleSubmit(() => {
					if ((type === 'appointment' && selectedDetailsStepId === STEP_DETAILS_OPTIONS.appointment.length) || (type === 'information' && selectedDetailsStepId === STEP_DETAILS_OPTIONS.information.length)) {
						completeStep(3);
						setSelectedStep(steps[selectedStep.id]);
					} else {
						setSelectedDetailsStepId((prev) => prev + 1);
					}
				})(e);
				break;
			case 5:
				methods.handleSubmit(asyncCaller)(e).catch(errorHandler);
				break;
		}
	};

	useEffect(() => {
		// Set document title
		document.title = `Create Request | ${META_TITLE}`;
	}, []);

	const renderComponent = () => {
		return (
			<>
				{!isMobile && <PageHeader title="Create Request" />}
				{isMobile && <HugeDropdown value={selectedStep} onChange={handleStepClick} steps={steps} />}
				<S.MainWrapper>
					{!isMobile && (
						<S.SidebarWrapper>
							{steps.map((step) => (
								<Fragment key={step.id}>
									<button onClick={() => handleStepClick(step)} className={`sidebar-option${step === selectedStep ? ' active' : ''}${step.completed ? ' completed' : ''}`}>
										<div className="sidebar-option__circle">
											<FontAwesomeIcon icon={['fal', 'check']} />
											<span>{step.id}</span>
										</div>
										<span className="sidebar-option__name">{step.name}</span>
									</button>
									{selectedStep?.id >= 4 && step.id === 4 && (
										<div className="sidebar-option__details-options-wrapper">
											{STEP_DETAILS_OPTIONS[type].map((option) => (
												<button key={option.id} onClick={() => handleDetailStepOptionClick(option.id)} className={`sidebar-option__details-option${selectedDetailsStepId === option.id && selectedStep.id === 4 ? ' selected' : ''}`}>
													{option.name}
												</button>
											))}
										</div>
									)}
								</Fragment>
							))}
						</S.SidebarWrapper>
					)}
					<FormProvider {...methods}>
						<S.FormWrapper onSubmit={onSubmit}>
							<S.FormContent>
								{selectedStep.id === 1 && <StepResourceType role={role} />}
								{selectedStep.id === 2 && <StepResourceCategory role={role} />}
								{selectedStep.id === 3 && <StepTemplate role={role} />}
								{selectedStep.id === 4 && <StepDetails role={role} selectedDetailsStepId={selectedDetailsStepId} />}
								{selectedStep.id === 5 && <StepReview role={role} />}
							</S.FormContent>
							<S.FormFooter>
								<Button className="back-button" variant="outline" variation="secondary" type="button" onClick={handleBackButton}>
									<Typography variation="button-medium" weight="bold">
										Back
									</Typography>
								</Button>
								<div className="spacer" />
								<div className="submit-container">
									{selectedStep.id === 5 && (
										<Button variant="outline" type="button" onClick={handleDraftClick}>
											<Typography variation="button-medium" weight="bold">
												Save as Draft
											</Typography>
										</Button>
									)}

									<Button>
										<Typography variation="button-medium" weight="bold">
											{selectedStep.id === 5 ? (methods.formState.isSubmitting ? 'Loading' : 'Publish Now') : 'Next'}
										</Typography>
									</Button>
								</div>
							</S.FormFooter>
						</S.FormWrapper>
					</FormProvider>
				</S.MainWrapper>
				<MessageDialog isOpen={confirmationDraftIsOpen} onRequestClose={handleCloseConfirmationDraftModal} title="Request Saved" subtitle="Your request has been saved as a draft. Come back to publish it at any time." icon={['fal', 'check']} />
				<MessageDialog isOpen={confirmationIsOpen} onRequestClose={handleCloseConfirmationModal} title="Request Posted" subtitle="Your Request has been posted. Keep checking back to view your responses!" icon={['fal', 'check']} />
			</>
		);
	};

	if (role === ROLES.TEACHER) {
		return <TeacherNavigation>{renderComponent()}</TeacherNavigation>;
	} else if (role === ROLES.BUSINESS) {
		return <BusinessNavigation>{renderComponent()}</BusinessNavigation>;
	}
	return <AdminNavigation>{renderComponent()}</AdminNavigation>;
};

CreateRequest.propTypes = {
	role: PropTypes.string,
};

export default CreateRequest;
