/* eslint-disable jsx-a11y/aria-role */
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { DirectoryCard, Map, Typography, EmptyComponent } from '../../../components';
import * as S from './SavedCardsGrid.styles';
import { formatNumberWithCommas } from '../../../utils/content-formatter';

const VIEW_OPTIONS = {
	grid: 'grid',
	list: 'list',
};

const SavedCardsGrid = ({ data, handleSaveUnsave, totalItems, type = 'resource' }) => {
	const [showMap, setShowMap] = useState(false);
	const [selectedViewOption, setSelectedViewOption] = useState(VIEW_OPTIONS.grid);

	const buildToUrl = (card) => {
		if (type === 'resource') {
			return card.type === 'information' ? `/business/information/${card.id}` : `/business/directory/resources/${card.id}`;
		}
		return card.type === 'information' ? `/business/information/${card.id}` : `/business/requests/${card.id}`;
	};

	return (
		<S.SavedCardsGrid showMap={showMap}>
			<div>
				<S.ResultsAndActionsWrapper>
					<Typography tag="p" variation="2">
						{totalItems === 1 ? '1 Results' : `${formatNumberWithCommas(totalItems)} Results`}
					</Typography>
					<div />
					<div className="actions-wrapper">
						<S.ToggleButton className="grid-button first" active={selectedViewOption === 'grid'} onClick={() => setSelectedViewOption('grid')}>
							<FontAwesomeIcon icon={['fal', 'th-large']} />
						</S.ToggleButton>
						<S.ToggleButton className="grid-button last" active={selectedViewOption === 'list'} onClick={() => setSelectedViewOption('list')}>
							<FontAwesomeIcon icon={['fal', 'list']} />
						</S.ToggleButton>
						<S.ToggleButton active={showMap} onClick={() => setShowMap((prev) => !prev)}>
							<FontAwesomeIcon icon={['fal', 'map']} />
							<Typography tag="p" variation="2">
								Show Map
							</Typography>
						</S.ToggleButton>
					</div>
				</S.ResultsAndActionsWrapper>
				{data.length > 0 ? (
					<S.CardsWrapper viewOption={showMap && selectedViewOption === 'grid' ? 'list' : selectedViewOption}>
						{data.map((card) => (
							<DirectoryCard
								key={card.id}
								role={'business'}
								to={buildToUrl(card)}
								back={'/business/saved'}
								status={card.status}
								variation={showMap && selectedViewOption === 'grid' ? 'list-alt' : selectedViewOption}
								thumbnail={card.coverImageObj['800']}
								bookmarked={card.isSaved}
								type={card.category}
								title={card.title}
								location={card?.creator?.business?.name || card?.business?.name || `${card?.creator?.firstName} ${card?.creator?.lastName}`}
								bookmarkAction={() => {
									handleSaveUnsave(card.id);
								}}
								isArchived={card.isArchived}
							/>
						))}
					</S.CardsWrapper>
				) : (
					<EmptyComponent title={type === 'resources' ? 'No saved resources' : 'No saved requests'} message={type === 'resources' ? 'Save a resource from the directory to get started.' : 'Save a request from the directory to get started.'} icon={['fal', 'bookmark']} />
				)}
			</div>
			{showMap && (
				<S.MapWrapper>
					<Map className="map-component" markers={data.filter((datum) => datum.type !== 'information')} entityType={type === 'resources' ? 'resource' : 'request'} />
				</S.MapWrapper>
			)}
		</S.SavedCardsGrid>
	);
};

SavedCardsGrid.propTypes = {
	type: PropTypes.string,
	data: PropTypes.array,
	handleSaveUnsave: PropTypes.func,
	totalItems: PropTypes.number,
};

export { SavedCardsGrid };
