import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import * as S from '../../CreateRequest.styles';
import { Select, DetailedCheckbox, RangeInput, TextArea, Typography } from '../../../../components';
import { fetchSchools } from '../../../../services/school';
import { LOCATION_TYPES, ROLES } from '../../../../../constants/general.constants';
import { required } from '../../../../utils/form-default-errors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LOCATION_TYPES_WITHOUT_IN_PERSON = LOCATION_TYPES.filter(({ id }) => id !== 'inperson');

export const LocationStep = ({ role }) => {
	const {
		register,
		watch,
		control,
		formState: { errors },
	} = useFormContext();

	const user = useSelector((state) => state.user.value);

	const [schoolsLoading, setSchoolsLoading] = useState('idle');
	const [schoolOptions, setSchoolOptions] = useState([...user.schools]);

	const selectedSchool = user.schools.find(({ id }) => id === watch('schoolLocationId'));

	const locationTypes = watch('locationTypes');

	async function initializeData() {
		setSchoolsLoading('loading');
		try {
			const {
				data: { result },
			} = await fetchSchools({ page: 0, isSlim: true });
			setSchoolOptions(result.schools);
			setSchoolsLoading('success');
		} catch (error) {
			setSchoolsLoading('error');
		}
	}

	useEffect(() => {
		if (user.role === ROLES.ADMIN || user.role === ROLES.SUPER) {
			if (schoolsLoading === 'idle') {
				initializeData();
			}
		} else {
			setSchoolsLoading('success');
		}
	}, [schoolsLoading]);

	return (
		<>
			<Typography tag="h2" weight="extrablack" center>
				Location
			</Typography>
			<Typography tag="p" center>
				Select where you would your request to occur.
			</Typography>

			<div className="fields-wrapper">
				<S.LocationStep>
					<div>
						<Typography tag="h3" weight="bold">
							Location Type
						</Typography>
						<Typography tag="p" variation="2">
							Select all locations you want to have this resource.
						</Typography>
					</div>
					<S.LocationStepWrapper>
						{LOCATION_TYPES_WITHOUT_IN_PERSON.map(({ id, name, description, icon }) => (
							<DetailedCheckbox key={id} id={id} value={id} title={name} description={description} icon={icon} {...register('locationTypes', { required: required('Location Type') })} />
						))}
						{!!errors.locationTypes && (
							<div className="error-wrapper">
								<Typography tag="p" variation="2">
									{errors.locationTypes.message}
								</Typography>
							</div>
						)}
					</S.LocationStepWrapper>

					<S.SelectedLocations>
						{!locationTypes ||
							(locationTypes.length >= 1 && (
								<>
									<div>
										<Typography tag="h3" weight="bold">
											Location Details
										</Typography>
										<Typography tag="p" variation="2">
											Enter in more information about your locations.
										</Typography>
									</div>
									{locationTypes?.includes('virtual') && (
										<div className="step__location">
											<div className="step__location-icon-wrapper">
												<FontAwesomeIcon icon={['fal', 'camera']} size="lg" />
											</div>
											<div className="step__location-fields-wrapper">
												<Typography tag="h4" weight="bold">
													Virtual
												</Typography>
												<TextArea id="virtualLocationNotes" label="Location Notes" placeholder="Write a message..." error={errors?.virtualLocationNotes} {...register('virtualLocationNotes')} />
											</div>
										</div>
									)}
									{locationTypes?.includes('worksite') && (
										<div className="step__location">
											<div className="step__location-icon-wrapper">
												<FontAwesomeIcon icon={['fal', 'building']} size="lg" />
											</div>
											<div className="step__location-fields-wrapper">
												<Typography tag="h4" weight="bold">
													Worksite Location
												</Typography>
												<Controller
													name="worksiteMaxTravelDistance"
													control={control}
													rules={{ required: required('Maximum Travel Distance') }}
													render={({ field: { onChange, value } }) => <RangeInput error={errors?.worksiteMaxTravelDistance} label="Maximum Travel Distance" id="worksite-max-travel-distance" unit="mi" value={value} onChange={onChange} />}
												/>
											</div>
										</div>
									)}
									{locationTypes?.includes('student') && (
										<div className="step__location">
											<div className="step__location-icon-wrapper">
												<FontAwesomeIcon icon={['fal', 'users']} size="lg" />
											</div>
											<div className="step__location-fields-wrapper">
												<Typography tag="h4" weight="bold">
													Student Location
												</Typography>
												<Controller
													name="schoolLocationId"
													control={control}
													rules={{ required: required('Student Location') }}
													render={({ field: { onChange, value } }) => (
														<Select
															getOptionLabel={(option) => option.name}
															getOptionValue={(option) => option.id}
															label="Student Location"
															placeholder="Select a school..."
															id="student-location-school"
															error={errors?.schoolLocationId}
															options={schoolOptions}
															promise={{
																function: fetchSchools,
																params: { isSlim: true },
																key: 'schools',
																setOptions: setSchoolOptions,
															}}
															value={schoolOptions.find((c) => c.id === value) || ''}
															onChange={(val) => onChange(val.id)}
														/>
													)}
												/>
												{selectedSchool && (
													<div className="step__information-card">
														<div className="icon-wrapper">
															<FontAwesomeIcon icon="school" />
														</div>
														<div className="info-wrapper">
															<Typography tag="h5" weight="bold">
																{selectedSchool?.name}
															</Typography>
															<div className="info-line">
																<FontAwesomeIcon icon={['fal', 'map-marker']} />
																<Typography tag="p" variation="2">
																	{`${selectedSchool?.address.addressLine1}, ${selectedSchool?.address.state} ${selectedSchool?.address.postalCode}`}
																</Typography>
															</div>
															<div className="info-line">
																<FontAwesomeIcon icon={['fal', 'landmark']} />
																<Typography tag="p" variation="2">
																	{selectedSchool?.address.city}
																</Typography>
															</div>
															<div className="info-line">
																<FontAwesomeIcon icon={['fal', 'school']} />
																<Typography tag="p" variation="2">
																	{selectedSchool?.schoolCode}
																</Typography>
															</div>
														</div>
													</div>
												)}

												<TextArea id="studentLocationNotes" label="Location Notes" placeholder="Write a message..." error={errors?.studentLocationNotes} {...register('studentLocationNotes')} />
											</div>
										</div>
									)}
								</>
							))}
					</S.SelectedLocations>
				</S.LocationStep>
			</div>
		</>
	);
};

LocationStep.propTypes = {
	role: PropTypes.string,
};
