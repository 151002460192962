import api from '../api';

export const fetchResources = (data) => api.post('/core/functions/fetchResources', data);

export const reviewInformationResource = (data) => api.post('/core/functions/reviewInformationResource', data);

export const createUpdateResource = (data) => api.post('/core/functions/createUpdateResource', data);

export const duplicateResource = (data) => api.post('/core/functions/duplicateResource', data);

export const updateResourceOwnership = (data) => api.post('/core/functions/updateResourceOwnership', data);

export const updatePublishStatusResource = (id) =>
	api.post('/core/functions/updatePublishStatusResource', {
		resourceId: id,
	});
