import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';

import { required } from '../../../utils/form-default-errors';
import { Checkbox, Select, Typography } from '../..';
import { CURRICULUM_STANDARDS, EMPLOYABILITY_SKILLS } from '../../../../constants/general.constants';
import * as S from '../CompleteBookingModal.styles';

export const StepThree = ({ role }) => {
	const {
		register,
		control,
		formState: { errors },
	} = useFormContext();

	return (
		<div className="modal-content">
			<Typography className="bigger-title" tag="h1" weight="extrablack" center>
				Learning Connections
			</Typography>
			<Typography tag="p" className="description" center>
				Please indicate which learning connections apply to this resource.
			</Typography>

			<div className="fields-wrapper">
				<div className="checkbox-wrapper">
					<Typography className={`field-label${!!errors.employabilitySkills ? ' with-error' : ''}`} tag="p" variation="2" weight="semibold">
						Employability Skills
					</Typography>

					{EMPLOYABILITY_SKILLS.map(({ value, label }) => (
						<div key={value} className="checkbox-field">
							<Checkbox id={value} value={value} {...register('employabilitySkills', { required: required('Employability Skills') })} />
							<label htmlFor={value}>{label}</label>
						</div>
					))}
					{!!errors?.employabilitySkills && (
						<div className="error-wrapper mt1">
							<Typography tag="p" variation="2">
								{errors?.employabilitySkills?.message}
							</Typography>
						</div>
					)}
				</div>

				<Controller
					name="curriculumStandards"
					control={control}
					rules={{ required: required('Curriculum Standards') }}
					render={({ field: { onChange, value } }) => (
						<Select
							isMulti
							containerClassName="custom-select"
							label="Curriculum Standards"
							id="curriculumStandards"
							placeholder="Curriculum Standards"
							error={errors?.curriculumStandards}
							options={CURRICULUM_STANDARDS}
							value={value?.map((val) => CURRICULUM_STANDARDS.find((c) => c.value === val)) || ''}
							onChange={(val) => onChange(val?.map((val) => val.value))}
						/>
					)}
				/>
			</div>
		</div>
	);
};

StepThree.propTypes = {
	role: PropTypes.string,
};
