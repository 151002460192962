import React from 'react';
import PropTypes from 'prop-types';
import { OverviewCard } from './OverviewCard';
import { LocationCard } from './LocationCard';
import { AvailabilityCard } from './AvailabilityCard';
import { BusinessCard } from './BusinessCard';
import { LearningConnectionsCard } from './LearningConnectionsCard';
import { AGE_GROUPS } from '../../../../constants/general.constants';

import * as S from './OverviewTab.styles';

export const formatPayloadBeforeUpdate = (resource, payload) => {
	return {
		...resource,
		resourceId: resource?.id,
		startDate: resource?.availabilityStartDate,
		endDate: resource?.availabilityEndDate,
		days: resource?.availabilityDays,
		isOngoing: resource?.availabilityIsOngoing,
		ageGroups: [...new Set((resource?.gradeLevels || []).map((level) => AGE_GROUPS.find((group) => group.values.includes(level)).value))],
		worksiteLocations: resource?.worksiteLocations.map(({ location, notes }) => ({
			locationId: location.id,
			notes,
		})),
		...payload,
	};
};

const OverviewTab = ({ resource, role }) => {
	return (
		<S.Wrapper>
			<OverviewCard className="a1" resource={resource} role={role} />
			<LocationCard className="a2" resource={resource} role={role} />
			<AvailabilityCard className="a3" resource={resource} role={role} />
			<BusinessCard className="a4" business={resource?.business} role={role} />
			{(resource?.employabilitySkills?.length > 0 || resource?.curriculumStandards?.length > 0) && <LearningConnectionsCard className="a5" resource={resource} />}
		</S.Wrapper>
	);
};

OverviewTab.propTypes = {
	resource: PropTypes.object,
	role: PropTypes.string,
};

export default OverviewTab;
