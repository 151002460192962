import React from 'react';
import PropTypes from 'prop-types';

import * as S from './PublicNavigation.styles';
import { Sidebar } from './Sidebar';
import { Topbar } from './Topbar';

export const PublicNavigation = ({ children }) => {
	const links = [
		{ id: 1, label: 'Browse' },
		{ id: 2, to: '/directory', label: 'Directory', icon: ['fal', 'books'] },
		{ id: 3, to: '/requests', label: 'Requests', icon: ['fal', 'file-plus'] },
		/*{ id: 4, to: '/reports', label: 'Reports', icon: ['fal', 'chart-bar'] },*/
		{ id: 5, label: 'About' },
		{ id: 6, to: '/about', label: 'About Us', icon: ['fal', 'users'] },
		{ id: 7, to: '/faq', label: 'FAQ', icon: ['fal', 'question-circle'] },
		{ id: 8, label: 'Get Started' },
		{ id: 9, to: '/sign-up', label: 'Sign Up', icon: ['fal', 'plus'] },
		{ id: 10, to: '/login', label: 'Log In', icon: ['fal', 'plus'] },
	];

	return (
		<S.Navigation>
			<Sidebar links={links} logoLink="/directory" />
			<S.Wrapper>
				<Topbar />
				<S.Content>{children}</S.Content>
			</S.Wrapper>
		</S.Navigation>
	);
};

PublicNavigation.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	logoLink: PropTypes.string,
	links: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
			to: PropTypes.string,
			label: PropTypes.string.isRequired,
			icon: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])),
		})
	),
};
