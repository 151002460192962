import React, { useState } from 'react';

import * as S from './PendingTab.styles';
import { Tab } from '../../../components';
import { ReceivedTab } from './ReceivedTab';
import { SentTab } from './SentTab';

export const PendingTab = () => {
	const [tabs, setTabs] = useState([
		{
			id: 1,
			label: 'Received',
			component: <ReceivedTab />,
		},
		{
			id: 2,
			label: 'Sent',
			component: <SentTab />,
		},
	]);

	return (
		<S.Wrapper>
			<Tab tabs={tabs} setTabs={setTabs} transparent variation="pill" />
		</S.Wrapper>
	);
};

PendingTab.propTypes = {};
