import styled from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: grid;
	background-color: white;
	padding: 0.75rem 1.25rem;
	column-gap: 1.25rem;
	border-bottom: 1px solid ${colors.neutralForm};

	grid-template-areas:
		'back-button'
		'title'
		'subtitle'
		'actions';

	@media screen and (min-width: 992px) {
		grid-template-areas:
			'back-button back-button'
			'title       actions'
			'subtitle    subtitle';
	}

	.page-header {
		&__back-button-wrapper {
			grid-area: back-button;
			margin: 0 -0.625rem 0.25rem;
		}

		&__title-wrapper {
			grid-area: title;
		}

		&__subtitle-wrapper {
			grid-area: subtitle;
			margin-bottom: 1rem;
			margin-top: 0.25rem;

			@media screen and (min-width: 992px) {
				margin-bottom: 0;
			}

			p {
				margin-bottom: 0.6rem;
			}
		}

		&__actions-wrapper {
			grid-area: actions;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			flex-wrap: wrap;
			margin: -0.375rem;

			.icon-button {
				color: ${colors.brandPrimary};
			}

			& > * {
				margin: 0.375rem;
			}
		}

		&__back-button {
			letter-spacing: 0.75px;

			svg {
				font-size: 0.75rem;
			}

			span {
				margin-left: 0.5rem;
			}
		}
	}

	.chq-atc {
		display: inline-block;
		position: relative;
	}

	.chq-atc--button {
		background: transparent;
		display: flex;
		-webkit-align-items: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		border-radius: 0.375rem;
		-webkit-letter-spacing: 1.5px;
		-moz-letter-spacing: 1.5px;
		-ms-letter-spacing: 1.5px;
		letter-spacing: 1.5px;
		cursor: pointer;
		border-width: 1px;
		border-style: solid;
		padding: 0.5625rem 1.5625rem;
		background-color: transparent;
		border-color: ${colors.brandPrimary};
		color: ${colors.brandPrimary};
	}

	.chq-atc--button:focus {
		background: transparent;
	}

	.chq-atc--button span {
		margin-left: 0.625rem;
	}

	.chq-atc--button:hover {
		background: transparent;
		opacity: 0.8;
	}

	.chq-atc--button svg {
		color: ${colors.brandPrimary};
		vertical-align: text-bottom;
		width: 0.775em !important;
		display: inline-block;
		font-size: inherit;
		height: 1em !important;
		vertical-align: -0.125em;

		:first-child {
			display: none;
		}
	}

	.chq-atc--button path {
		fill: ${colors.brandPrimary};
	}

	.chq-atc--dropdown {
		background-color: white;
		border-radius: 5px;
		border: 1px solid #eaeaea;
		box-shadow: 0.5px 0.5px 3px rgba(0, 0, 0, 0.2);
		box-sizing: border-box;
		position: absolute;
		text-align: left;
		white-space: nowrap;
		width: 100%;
		z-index: 1;
	}

	.chq-atc--dropdown a {
		color: ${colors.brandPrimary};
		display: block;
		padding: 8px 15px;
		text-decoration: none;
	}

	.chq-atc--dropdown a:hover {
		background-color: #eaeaea;
	}
`;
