import React, { useEffect } from 'react';

import { AdminNavigation, Card, PageHeader, Typography } from '../../components';
import { META_TITLE } from '../../../constants/general.constants';
import { PendingCompanies } from './PendingCompanies';
import { RecentActivity } from './RecentActivity';
import * as S from './AdminDashboard.styles';
import { MetricsCards } from './MetricsCards';

const AdminDashboard = () => {
	useEffect(() => {
		document.title = `Dashboard | ${META_TITLE}`;
	}, []);

	return (
		<AdminNavigation>
			<PageHeader title="Dashboard" />
			<S.Wrapper>
				<MetricsCards className="a1" />
				<PendingCompanies className="a2" />
				<RecentActivity className="a3" />
			</S.Wrapper>
		</AdminNavigation>
	);
};

export default AdminDashboard;
