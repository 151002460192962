import React, { createContext, useEffect, useReducer, useRef, useState } from 'react';

import { AdminNavigation, DetailPageHeader, ErrorComponent, MessageDialog, Spinner, Tab, Typography, DeclineCompanyModal } from '../../components';
import * as S from './AdminCompanyDetail.styles';
import { BookingsTab } from './BookingsTab';
import { EmployeesTab } from './EmployeesTab';
import { OverviewTab } from './OverviewTab';
import { RequestsTab } from './RequestsTab';
import { ResourcesTab } from './ResourcesTab';
import { ReviewsTab } from './ReviewsTab';
import { META_TITLE } from '../../../constants/general.constants';
import { useQuery } from '../../hooks/useQuery';
import { useHistory, useParams } from 'react-router-dom';
import { fetchBusinessProfile } from '../../services/directory';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { approveBusiness, removeBusiness } from '../../services/business';

export const AdminCompanyDetailContext = createContext();

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_COMPANY':
			return {
				...state,
				company: action.payload,
			};
		default:
			return state;
	}
};

const AdminCompanyDetail = () => {
	const [pageStatus, setPageStatus] = useState('idle');
	const [removeIsOpen, setRemoveIsOpen] = useState(false);
	const [approveIsOpen, setApproveIsOpen] = useState(false);
	const query = useQuery();
	const history = useHistory();
	const [state, dispatch] = useReducer(reducer, {});
	const { id: businessId } = useParams();

	const declineCompanyModalRef = useRef(null);

	const [tabs, setTabs] = useState([
		{
			id: 1,
			label: 'Overview',
			component: <OverviewTab />,
			active: query.get('tabId') === '1',
		},
		{
			id: 2,
			label: 'Resources',
			component: <ResourcesTab />,
			active: query.get('tabId') === '2',
		},
		{
			id: 3,
			label: 'Requests',
			component: <RequestsTab />,
			active: query.get('tabId') === '3',
		},
		{
			id: 4,
			label: 'Bookings',
			component: <BookingsTab />,
			active: query.get('tabId') === '4',
		},
		{
			id: 5,
			label: 'Employees',
			component: <EmployeesTab />,
			active: query.get('tabId') === '5',
		},
		{
			id: 6,
			label: 'Reviews',
			component: <ReviewsTab />,
			active: query.get('tabId') === '6',
		},
	]);

	function handleOpenRemoveModal() {
		setRemoveIsOpen(true);
	}

	function handleCloseRemoveModal() {
		setRemoveIsOpen(false);
	}

	function handleOpenApproveModal() {
		setApproveIsOpen(true);
	}

	function handleCloseApproveModal() {
		setApproveIsOpen(false);
	}

	async function initializeData() {
		try {
			setPageStatus('loading');
			const {
				data: { result },
			} = await fetchBusinessProfile({ businessId });
			dispatch({ type: 'SET_COMPANY', payload: result.business });
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}

	const handleRemoveCompany = async () => {
		try {
			await removeBusiness({ businessId });
			history.push('/admin/companies');
		} catch (error) {
			errorHandler(error);
		}
	};

	const handleCreateResource = () => {
		history.push('/admin/create-resource', { businessId });
	};

	const handleApproveCompany = async () => {
		try {
			const {
				data: { result },
			} = await approveBusiness({ businessId });
			dispatch({ type: 'SET_COMPANY', payload: result.business });
			handleCloseApproveModal();
		} catch (error) {
			errorHandler(error);
		}
	};

	useEffect(() => {
		if (pageStatus === 'idle') {
			initializeData();
		}
	}, [pageStatus]);

	useEffect(() => {
		// Set document title
		document.title = `Company Details | ${META_TITLE}`;
	}, []);

	function renderContent() {
		switch (pageStatus) {
			case 'loading':
				return <Spinner />;
			case 'success':
				return (
					<AdminCompanyDetailContext.Provider value={{ state, dispatch }}>
						<DetailPageHeader title={state.company.name} backButton={{ label: 'Back to Companies', onClick: () => history.push('/admin/companies') }}>
							<>
								{state.company.isDeclined !== true &&
									(state.company.isApproved === true ? (
										<>
											<S.Button variant="outline" variation="warning" onClick={handleOpenRemoveModal}>
												<Typography tag="span" variation="2" weight="extrablack">
													Remove Company
												</Typography>
											</S.Button>
											<S.Button variant="solid" onClick={handleCreateResource}>
												<Typography tag="span" variation="2" weight="extrablack">
													<FontAwesomeIcon icon={['fal', 'plus']} />
													Create Resource
												</Typography>
											</S.Button>
											<MessageDialog
												isOpen={removeIsOpen}
												onRequestClose={handleCloseRemoveModal}
												title="Remove Company?"
												subtitle="Are you sure you want to remove this company?"
												icon={['fal', 'building']}
												actions={[
													{ id: 'back', label: 'Cancel', variant: 'outline', variation: 'default' },
													{ id: 'cancel', label: 'Remove Company', variant: 'solid', variation: 'default', onClick: handleRemoveCompany },
												]}
											/>
										</>
									) : (
										<>
											<S.Button
												variant="outline"
												variation="warning"
												onClick={() => {
													declineCompanyModalRef.current.open(businessId);
												}}
											>
												<Typography tag="span" variation="2" weight="extrablack">
													Decline
												</Typography>
											</S.Button>
											<S.Button variant="solid" onClick={handleOpenApproveModal}>
												<Typography tag="span" variation="2" weight="extrablack">
													Approve
												</Typography>
											</S.Button>
											<MessageDialog
												isOpen={approveIsOpen}
												onRequestClose={handleCloseApproveModal}
												title="Approve Company?"
												subtitle="Are you ready to approve this company?"
												icon={['fal', 'building']}
												actions={[
													{ id: 'back', label: 'Cancel', variant: 'outline', variation: 'default' },
													{ id: 'cancel', label: 'Approve Company', variant: 'solid', variation: 'default', onClick: handleApproveCompany },
												]}
											/>
											<DeclineCompanyModal ref={declineCompanyModalRef} dispatch={dispatch} />
										</>
									))}
							</>
						</DetailPageHeader>
						<S.Wrapper>
							{state.company.isDeclined === true || state.company.isApproved === true ? (
								<Tab tabs={tabs} setTabs={setTabs} />
							) : (
								<S.SingleWrapper>
									<OverviewTab />
								</S.SingleWrapper>
							)}
						</S.Wrapper>
					</AdminCompanyDetailContext.Provider>
				);
			case 'error':
				return <ErrorComponent />;
			default:
				return null;
		}
	}

	return <AdminNavigation>{renderContent()}</AdminNavigation>;
};

export default AdminCompanyDetail;
