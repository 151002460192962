import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button } from '../Button';
import { Badge } from '../Badge';
import { Typography } from '../Typography';
import * as S from './DetailPageHeader.styles';

export const DetailPageHeader = ({ title, subtitle, badge, backButton = {}, children }) => {
	return (
		<S.Wrapper>
			<div className="page-header__back-button-wrapper">
				<Button className="page-header__back-button" onClick={backButton.onClick} variant="text" size="small">
					<FontAwesomeIcon icon={['fal', 'arrow-left']} />
					<Typography weight="bold" variation="button-small">
						{backButton.label}
					</Typography>
				</Button>
			</div>

			<div className="page-header__title-wrapper">
				<Typography tag="h1" weight="extrablack">
					{title}
				</Typography>
			</div>

			<div className="page-header__subtitle-wrapper">
				{subtitle && <Typography tag="p">{subtitle}</Typography>}
				{badge && <Badge variant="outlined">{badge}</Badge>}
			</div>

			<div className="page-header__actions-wrapper">{children}</div>
		</S.Wrapper>
	);
};

DetailPageHeader.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string,
	badge: PropTypes.string,
	backButton: PropTypes.shape({
		label: PropTypes.string,
		onClick: PropTypes.func,
	}),
	children: PropTypes.node,
};
