import React, { useState, useEffect, createContext, useReducer, useRef } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import AddToCalendar from '@culturehq/add-to-calendar';
import '@culturehq/add-to-calendar/dist/styles.css';

import { buildAddressString } from '../../utils/location-methods';
import { ROLES, SOURCES, META_TITLE, RESOURCE_STATUSES } from '../../../constants/general.constants';
import { archiveUnarchiveResource, fetchResource, saveUnsaveResource } from '../../services/directory';
import { recordView } from '../../services/metrics';
import { fetchMessages } from '../../services/messages';
import { TeacherNavigation, PublicNavigation, Spinner, DetailPageHeader, IconButton, Button, Typography, MessageDialog, ErrorComponent, AdminNavigation, BusinessNavigation, Tab } from '../../components';
import * as S from './DirectoryResourceDetails.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ModalBookingResource } from './ModalBookingResource';
import { OverviewTab } from './OverviewTab';
import { BookingsTab } from './BookingsTab';
import { useSelector } from 'react-redux';
import { FloatingChatContainer } from '../../components';
import { useChat } from '../../hooks';
import { duplicateResource, updatePublishStatusResource } from '../../services/resources';
import { errorHandler } from '../../services/authService';

export const DirectoryResourceDetailsContext = createContext();

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_RESOURCE':
			return {
				...state,
				resource: action.payload,
			};
		default:
			return state;
	}
};

const DirectoryResourceDetails = ({ role }) => {
	const [state, dispatch] = useReducer(reducer, {});
	const { id } = useParams();
	const history = useHistory();
	const location = useLocation();
	const floatingChatContainerRef = useRef();
	const [pageStatus, setPageStatus] = useState('idle');
	const [isOpen, setIsOpen] = useState(false);
	const [archiveIsOpen, setArchiveIsOpen] = useState(false);
	const [duplicateIsOpen, setDuplicateIsOpen] = useState(false);
	const [publishIsOpen, setPublishIsOpen] = useState(false);
	const [confirmationIsOpen, setConfirmationIsOpen] = useState(false);
	const [createAccountIsOpen, setCreateAccountIsOpen] = useState(false);
	const user = useSelector(({ user }) => user.value);

	// Create conversation id
	const channelId = role === ROLES.BUSINESS ? `${id}-${user?.business?.id}` : `${id}-${user?.id}`;

	// Use chat hook
	const { messages, setMessages } = useChat(channelId);

	const buildTabs = (resource) => {
		return [
			{
				id: 1,
				label: 'Overview',
				component: <OverviewTab resource={resource} role={role} />,
			},
			{
				id: 2,
				label: 'Bookings',
				component: <BookingsTab resource={resource} role={role} />,
			},
		].filter(Boolean);
	};
	const [tabs, setTabs] = useState(buildTabs(state.resource));

	const backUrl = location.state?.backUrl || `/${role || 'teacher'}/directory/resources`;
	const backTitle = location.state?.backTitle;

	function handleOpenModal() {
		setIsOpen(true);
	}

	function handleCloseModal() {
		setIsOpen(false);
	}

	function handleOpenArchiveModal() {
		setArchiveIsOpen(true);
	}

	function handleCloseArchiveModal() {
		setArchiveIsOpen(false);
	}

	function handleOpenConfirmationModal() {
		setConfirmationIsOpen(true);
	}

	function handleCloseConfirmationModal() {
		setConfirmationIsOpen(false);
		history.push(`/${role || 'teacher'}/bookings`);
	}

	function handleOpenPublishModal() {
		setPublishIsOpen(true);
	}

	function handleClosePublishModal() {
		setPublishIsOpen(false);
	}

	function handleOpenCreateAccountModal() {
		setCreateAccountIsOpen(true);
	}

	function handleCloseCreateAccountModal() {
		setCreateAccountIsOpen(false);
	}

	function handleOpenDuplicateModal() {
		setDuplicateIsOpen(true);
	}

	function handleCloseDuplicateModal() {
		setDuplicateIsOpen(false);
	}

	function handleMoveToSignUp() {
		setCreateAccountIsOpen(false);
		history.push('/sign-up');
	}

	const handleDuplicateResource = async () => {
		setDuplicateIsOpen(false);
		try {
			const {
				data: { result },
			} = await duplicateResource({ resourceId: id });
			history.push(`/${role || 'teacher'}/directory/resources/${result.resource.id}`);
		} catch (error) {
			errorHandler(error);
		}
	};

	const handlePublishResource = async () => {
		setPublishIsOpen(false);
		try {
			const {
				data: { result },
			} = await updatePublishStatusResource(id);
			dispatch({ type: 'SET_RESOURCE', payload: result.resource });
			setTabs(buildTabs(result.resource));
		} catch (error) {}
	};

	const handleArchiveUnarchive = async () => {
		setArchiveIsOpen(false);
		try {
			const {
				data: { result },
			} = await archiveUnarchiveResource({ resourceId: id });
			dispatch({ type: 'SET_RESOURCE', payload: result.resource });
			setTabs(buildTabs(result.resource));
		} catch (error) {}
	};

	const handleSaveUnsaveResource = async () => {
		try {
			const {
				data: { result },
			} = await saveUnsaveResource({ resourceId: id });
			dispatch({ type: 'SET_RESOURCE', payload: result.resource });
			setTabs(buildTabs(result.resource));
		} catch (error) {}
	};

	const createBackLabel = (url) => {
		if (backTitle != null) return backTitle;
		switch (url) {
			case `/${role || 'teacher'}/directory/resources`:
				return 'Back to Directory';
			case `/${role || 'teacher'}/saved`:
				return 'Back to Saved Resources';
			default:
				return 'Back to All Resources';
		}
	};

	async function initializeData() {
		setPageStatus('loading');
		try {
			// Fetch resource
			const {
				data: { result },
			} = await fetchResource({ resourceId: id });

			// Fetch messages
			if (role) {
				const {
					data: { result: messagesResult },
				} = await fetchMessages({ page: 0, displayLimit: 100000, resourceId: id, filterOptions: { received: true, sent: true } });
				setMessages(messagesResult.messages);
			}

			// Set data
			dispatch({ type: 'SET_RESOURCE', payload: result.resource });
			setTabs(buildTabs(result.resource));
			setPageStatus('success');
			await recordView({ sourceType: SOURCES.RESOURCE, sourceId: id });
		} catch (error) {
			setPageStatus('error');
		}
	}

	useEffect(() => {
		// Set document title
		document.title = role != null ? `Resource Detail | Dashboard | ${META_TITLE}` : `Resource Detail | ${META_TITLE}`;

		// Load initial data
		if (pageStatus === 'idle') {
			initializeData();
		}
	}, []);

	const renderContent = () => {
		switch (pageStatus) {
			case 'loading':
				return <Spinner />;
			case 'error':
				return <ErrorComponent />;
			case 'success':
				return (
					<DirectoryResourceDetailsContext.Provider value={{ state, dispatch }}>
						<DetailPageHeader
							title={state.resource.title}
							subtitle={state.resource.business.name}
							badge={(role === ROLES.ADMIN || role === ROLES.SUPER) && state.resource != null && state.resource.status != null ? state.resource.status[0].toUpperCase() + state.resource.status.slice(1) : undefined}
							backButton={{ label: createBackLabel(backUrl), onClick: () => history.push(backUrl) }}
						>
							{(role === ROLES.TEACHER || role === ROLES.BUSINESS) && (
								<>
									<IconButton
										className="icon-button"
										icon={['fal', 'share']}
										onClick={() => {
											document.querySelectorAll("[data-network='sharethis']")[0].click();
										}}
									/>
									{state.resource.isSaved === true ? (
										<IconButton
											className="icon-button"
											icon={['fa', 'bookmark']}
											onClick={() => {
												handleSaveUnsaveResource();
											}}
										/>
									) : (
										<IconButton
											className="icon-button"
											icon={['fal', 'bookmark']}
											onClick={() => {
												handleSaveUnsaveResource();
											}}
										/>
									)}
									{state.resource.isCreatorAccess === true && (
										<>
											<Button
												variant="outline"
												onClick={() => {
													handleOpenDuplicateModal();
												}}
											>
												<Typography weight="bold" variation="button-medium">
													Duplicate
												</Typography>
											</Button>
											{state.resource.status === RESOURCE_STATUSES.DRAFT && (
												<Button
													variant="outline"
													onClick={() => {
														handleOpenPublishModal();
													}}
												>
													<Typography weight="bold" variation="button-medium">
														Publish
													</Typography>
												</Button>
											)}
											{state.resource.status === RESOURCE_STATUSES.PUBLISHED && (
												<Button
													variant="outline"
													onClick={() => {
														handleOpenPublishModal();
													}}
												>
													<Typography weight="bold" variation="button-medium">
														Set as Draft
													</Typography>
												</Button>
											)}
											{state.resource.status === RESOURCE_STATUSES.ARCHIVED ? (
												<Button
													onClick={() => {
														handleOpenArchiveModal();
													}}
												>
													<Typography weight="bold" variation="button-medium">
														Unarchive
													</Typography>
												</Button>
											) : (
												<Button
													onClick={() => {
														handleOpenArchiveModal();
													}}
												>
													<Typography weight="bold" variation="button-medium">
														Archive
													</Typography>
												</Button>
											)}
										</>
									)}
								</>
							)}
							{(role === ROLES.ADMIN || role === ROLES.SUPER) && (
								<>
									<Button
										variant="outline"
										onClick={() => {
											handleOpenDuplicateModal();
										}}
									>
										<Typography weight="bold" variation="button-medium">
											Duplicate
										</Typography>
									</Button>
									{state.resource.isArchived === true ? (
										<Button
											onClick={() => {
												handleOpenArchiveModal();
											}}
										>
											<Typography weight="bold" variation="button-medium">
												Unarchive
											</Typography>
										</Button>
									) : (
										<Button
											onClick={() => {
												handleOpenArchiveModal();
											}}
										>
											<Typography weight="bold" variation="button-medium">
												Archive
											</Typography>
										</Button>
									)}
								</>
							)}
							{state.resource.type === 'event' ? (
								<>
									<AddToCalendar
										event={{
											name: state.resource.title,
											details: `${state.resource.description}\n\n${state.resource.eventLink}`,
											location: state.resource.inPersonLocationAddress ? buildAddressString(state.resource.inPersonLocationAddress) : 'Virtual',
											startsAt: state.resource.timeFrames[0].startDateFull,
											endsAt: state.resource.timeFrames[0].endDateFull,
										}}
									>
										<FontAwesomeIcon icon={['fal', 'calendar']} />
										<Typography weight="bold" variation="button-medium">
											Add to Calendar
										</Typography>
									</AddToCalendar>
									<Button
										onClick={() => {
											window.open(state.resource.eventLink);
										}}
									>
										<FontAwesomeIcon icon={['fal', 'external-link']} />
										<Typography weight="bold" variation="button-medium">
											View Event
										</Typography>
									</Button>
								</>
							) : (
								<>
									{!state.resource.isCreatorAccess && (role === ROLES.TEACHER || role === ROLES.BUSINESS) && (
										<Button variant="outline" onClick={() => floatingChatContainerRef.current?.open()}>
											<FontAwesomeIcon icon={['fal', 'comment']} />
											<Typography weight="bold" variation="button-medium">
												Message
											</Typography>
										</Button>
									)}
									{role !== ROLES.ADMIN && role !== ROLES.SUPER && (
										<Button
											onClick={() => {
												if (role != null) handleOpenModal();
												else handleOpenCreateAccountModal();
											}}
										>
											<FontAwesomeIcon icon={['fal', 'envelope']} />
											<Typography weight="bold" variation="button-medium">
												Book Resource
											</Typography>
										</Button>
									)}
								</>
							)}
						</DetailPageHeader>
						{(role != null && state.resource?.business != null && state.resource?.business.id === user?.business?.id) || role === ROLES.ADMIN || role === ROLES.SUPER ? (
							<S.Wrapper>
								<Tab tabs={tabs} setTabs={setTabs} />
							</S.Wrapper>
						) : (
							<S.SingleWrapper>
								<OverviewTab resource={state.resource} role={role} />
							</S.SingleWrapper>
						)}
						{role != null && <ModalBookingResource isOpen={isOpen} onRequestClose={handleCloseModal} onComplete={handleOpenConfirmationModal} resourceId={id} resourceLocationTypes={state.resource.locationTypes} worksiteLocations={state.resource.worksiteLocations} role={role} />}
						{role != null && <MessageDialog isOpen={confirmationIsOpen} onRequestClose={handleCloseConfirmationModal} title="Booking Sent" subtitle="Your Booking has been sent. Keep checking back to view responses from the company!" icon={['fal', 'check']} />}
						{role != null && (
							<MessageDialog
								isOpen={archiveIsOpen}
								onRequestClose={handleCloseArchiveModal}
								title={((role === ROLES.ADMIN || role === ROLES.SUPER) && state.resource.isArchived !== true) || state.resource.status !== RESOURCE_STATUSES.ARCHIVED ? 'Archive Resource' : 'Unarchive Resource'}
								subtitle={
									((role === ROLES.ADMIN || role === ROLES.SUPER) && state.resource.isArchived !== true) || state.resource.status !== RESOURCE_STATUSES.ARCHIVED
										? 'Are you sure you want to archive this resource? You will be able to unarchive it later.'
										: 'Are you sure you want to unarchive this resource?'
								}
								icon={['fal', 'books']}
								actions={[
									{ id: 'back', label: 'Cancel', variant: 'outline', variation: 'default' },
									{
										id: 'archiveUnarchive',
										label: ((role === ROLES.ADMIN || role === ROLES.SUPER) && state.resource.isArchived !== true) || state.resource.status !== RESOURCE_STATUSES.ARCHIVED ? 'Archive Resource' : 'Unarchive Resource',
										variant: 'solid',
										variation: 'default',
										onClick: handleArchiveUnarchive,
									},
								]}
							/>
						)}
						{role == null && (
							<MessageDialog
								isOpen={createAccountIsOpen}
								onRequestClose={handleCloseCreateAccountModal}
								title="Create an account"
								subtitle="In order message or book this resource you must login to your account. If you don’t have an account, you must signup to continue."
								icon={['fal', 'user']}
								actions={[
									{ id: 'back', label: 'Back', variant: 'outline', variation: 'default' },
									{ id: 'signup', label: 'Sign Up', variant: 'solid', variation: 'default', onClick: handleMoveToSignUp },
								]}
							/>
						)}
						{role != null && (
							<MessageDialog
								isOpen={duplicateIsOpen}
								onRequestClose={handleCloseDuplicateModal}
								title="Duplicate Resource?"
								subtitle="Are you sure you want to duplicate this resource? All resource data will be copied over to the new resource."
								icon={['fal', 'books']}
								actions={[
									{ id: 'back', label: 'Cancel', variant: 'outline', variation: 'default' },
									{ id: 'duplicate', label: 'Duplicate Resource', variant: 'solid', variation: 'default', onClick: handleDuplicateResource },
								]}
							/>
						)}
						{role != null && (
							<MessageDialog
								isOpen={publishIsOpen}
								onRequestClose={handleClosePublishModal}
								title={state.resource.status === RESOURCE_STATUSES.DRAFT ? 'Publish Resource?' : 'Set Resource as a Draft?'}
								subtitle={state.resource.status === RESOURCE_STATUSES.DRAFT ? 'Are you ready to publish this resource? Teachers will be able to send you requests.' : "Are you sure you want to set this resource as a draft? Teachers won't be able to send you requests."}
								icon={['fal', 'books']}
								actions={[
									{ id: 'back', label: 'Cancel', variant: 'outline', variation: 'default' },
									{ id: 'publish', label: state.resource.status === RESOURCE_STATUSES.DRAFT ? 'Publish Resource' : 'Set as Draft', variant: 'solid', variation: 'default', onClick: handlePublishResource },
								]}
							/>
						)}
						{!state.resource.isCreatorAccess && state.resource.type !== 'event' && (role === ROLES.TEACHER || role === ROLES.BUSINESS) && (
							<FloatingChatContainer
								ref={floatingChatContainerRef}
								header={{ avatar: state.resource.business.logoObj[200], name: state.resource.business.name }}
								loading={false}
								messages={messages}
								setMessages={setMessages}
								sendProps={{ resourceId: id, businessId: state.resource.business.id }}
							/>
						)}
					</DirectoryResourceDetailsContext.Provider>
				);
			default:
				return null;
		}
	};

	switch (role) {
		case ROLES.TEACHER:
			return <TeacherNavigation>{renderContent()}</TeacherNavigation>;
		case ROLES.BUSINESS:
			return <BusinessNavigation>{renderContent()}</BusinessNavigation>;
		case ROLES.ADMIN:
		case ROLES.SUPER:
			return <AdminNavigation>{renderContent()}</AdminNavigation>;
		default:
			return <PublicNavigation>{renderContent()}</PublicNavigation>;
	}
};

DirectoryResourceDetails.propTypes = {
	role: PropTypes.string,
};

export default DirectoryResourceDetails;
