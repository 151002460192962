import React, { forwardRef, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import ReactModal from 'react-modal';

import { Button, ErrorComponent, IconButton, Select, Spinner, Typography } from '../../components';
import { errorHandler } from '../../services/authService';
import * as S from './ChangeOwnershipModal.styles';
import { fetchBusinesses } from '../../services/business';
import { updateResourceOwnership } from '../../services/resources';
import { required } from '../../utils/form-default-errors';

export const ChangeOwnershipModal = forwardRef(({ onSuccessCallback }, ref) => {
	const [loadingState, setLoadingState] = useState('idle');
	const [isOpen, setIsOpen] = useState(false);
	const [businesses, setBusinesses] = useState([]);

	const {
		handleSubmit,
		reset,
		setValue,
		control,
		formState: { errors, isSubmitting },
	} = useForm();

	const handleOpenModal = async ({ resourceId, businessId }) => {
		setLoadingState('loading');
		setValue('resourceId', resourceId);
		setValue('businessId', businessId);
		setIsOpen(true);
		try {
			const {
				data: { result },
			} = await fetchBusinesses({ page: 0, isSlim: true });
			setBusinesses(result.businesses);
			setLoadingState('success');
		} catch (error) {
			setLoadingState('error');
			errorHandler(error);
		}
	};

	const handleCloseModal = () => {
		setIsOpen(false);
		setTimeout(() => {
			reset();
		}, 200);
	};

	async function asyncCaller(data) {
		const {
			data: { result },
		} = await updateResourceOwnership(data);
		onSuccessCallback(result.resource);
		handleCloseModal();
	}

	const onSubmit = (e) => {
		handleSubmit(asyncCaller)(e).catch(errorHandler);
	};

	useImperativeHandle(
		ref,
		() => ({
			open: handleOpenModal,
			close: handleCloseModal,
		}),
		[handleOpenModal, handleCloseModal]
	);

	const renderContent = () => {
		switch (loadingState) {
			case 'loading':
				return <Spinner />;
			case 'success':
				return (
					<S.Form onSubmit={onSubmit}>
						<IconButton className="close-button" type="button" icon={['fal', 'times']} onClick={handleCloseModal} />
						<div className="modal-content">
							<header className="modal-content__header">
								<Typography tag="h2" weight="extrablack" center>
									Change Ownership
								</Typography>
							</header>

							<section className="modal-content__section">
								<Controller
									name="businessId"
									control={control}
									rules={{ required: required('Company') }}
									render={({ field: { onChange, value } }) => (
										<Select
											getOptionLabel={(option) => option.name}
											getOptionValue={(option) => option.id}
											label="Company"
											placeholder="Company"
											id="company"
											error={errors?.businessId}
											options={businesses}
											promise={{
												function: fetchBusinesses,
												params: { isSlim: true },
												key: 'businesses',
												setOptions: setBusinesses,
											}}
											value={businesses.find((c) => c.id === value) || ''}
											onChange={(val) => onChange(val.id)}
										/>
									)}
								/>
							</section>

							<div className="modal-footer">
								<Button variant="outline" variation="secondary" type="button" onClick={handleCloseModal}>
									<Typography variation="button-medium" weight="bold">
										Cancel
									</Typography>
								</Button>
								<div className="flex-1" />
								<Button>
									<Typography variation="button-medium" weight="bold">
										{isSubmitting ? 'Loading' : 'Update'}
									</Typography>
								</Button>
							</div>
						</div>
					</S.Form>
				);
			case 'error':
				return <ErrorComponent />;
			default:
				return null;
		}
	};

	return (
		<ReactModal ref={ref} className="change-ownership-modal" closeTimeoutMS={200} isOpen={isOpen} onRequestClose={handleCloseModal}>
			{renderContent()}
		</ReactModal>
	);
});

ChangeOwnershipModal.displayName = 'ChangeOwnershipModal';

ChangeOwnershipModal.propTypes = {
	onSuccessCallback: PropTypes.func,
};
