import styled from 'styled-components';

import colors from '../../../styles/colors';

export const Wrapper = styled.div`
	.business-info {
		display: flex;
		align-items: center;
		margin-bottom: 1rem;

		&__avatar {
			border-radius: 9999px;
			width: 3.5rem;
			height: 3.5rem;
			margin-right: 0.5rem;
		}

		&__details {
			margin-bottom: 0.25rem;
		}

		&__subtitle {
			color: ${colors.neutralButton};
		}
	}

	.business-bio {
		display: flex;
		flex-direction: column;
		margin-bottom: 1rem;

		&__title {
			color: ${colors.neutralButton};
		}
	}

	.business-career-cluster {
		display: flex;
		flex-direction: column;
		padding-bottom: 0.25rem;

		&__title {
			color: ${colors.neutralButton};
			margin-bottom: 0.25rem;
		}

		&__badges-wrapper {
			display: flex;
			flex-wrap: wrap;
			margin: -0.25rem;
		}

		&__badge {
			padding: 0.25rem 0.5rem;
			border-radius: 6.25rem;
			border: 1px solid ${colors.brandPrimary};
			font-size: 0.75rem;
			color: ${colors.brandPrimary};
			font-weight: 700;
			margin: 0.25rem;
		}
	}
`;
