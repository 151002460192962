import styled from 'styled-components';
import colors from '../../../styles/colors';

export const Wrapper = styled.div`
	padding: 0.75rem 1rem;
	margin: 0 -1rem;
	background-color: white;

	.header {
		&__back-button {
			margin-left: -0.625rem;
			letter-spacing: 0.75px;

			svg {
				font-size: 0.75rem;
			}

			span {
				margin-left: 0.5rem;
			}
		}

		&__title-wrapper {
			display: flex;
			align-items: center;
			margin-top: 0.25rem;

			&__avatar {
				border: 1px solid ${colors.neutralForm};
				width: 3.5rem;
				height: 3.5rem;
				border-radius: 9999px;
				margin-right: 1rem;
			}
		}
	}
`;
