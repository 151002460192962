import styled, { css } from 'styled-components';

import colors from '../../styles/colors';

const SIZES = {
	small: {
		square: css`
			padding: 0.1875rem 0.4375rem;
		`,
		pill: css`
			padding: 0.1875rem 0.4375rem;
		`,
	},
	medium: {
		square: css`
			padding: 0.25rem 0.5625rem;
		`,
		pill: css`
			padding: 0.25rem 0.875rem;
		`,
	},
};

const ICON_SIZES = {
	small: '0.6875rem',
	medium: '1.125rem',
};

const VARIATIONS = {
	square: css`
		border-radius: 0.25rem;
	`,
	pill: css`
		border-radius: 9999px;
	`,
};

const TYPES = {
	primary: {
		solid: css`
			background-color: ${colors.brandPrimary};
			border-color: ${colors.brandPrimary};
		`,
		outlined: css`
			background-color: transparent;
			border-color: ${colors.brandPrimary};
			color: ${colors.brandPrimary};
		`,
	},
	neutral: {
		solid: css`
			background-color: ${colors.neutralText};
			border-color: ${colors.neutralText};
		`,
		outlined: css`
			background-color: transparent;
			border-color: ${colors.neutralText};
			color: ${colors.neutralText};
		`,
	},
	danger: {
		solid: css`
			background-color: ${colors.stateDanger};
			border-color: ${colors.stateDanger};
		`,
		outlined: css`
			background-color: transparent;
			border-color: ${colors.stateDanger};
			color: ${colors.stateDanger};
		`,
	},
	warning: {
		solid: css`
			background-color: ${colors.stateWarning};
			border-color: ${colors.stateWarning};
		`,
		outlined: css`
			background-color: transparent;
			border-color: ${colors.stateWarning};
			color: ${colors.stateWarning};
		`,
	},
	success: {
		solid: css`
			background-color: ${colors.stateSuccess};
			border-color: ${colors.stateSuccess};
		`,
		outlined: css`
			background-color: transparent;
			border-color: ${colors.stateSuccess};
			color: ${colors.stateSuccess};
		`,
	},
};

export const Badge = styled.span`
	display: inline-flex;
	justify-content: center;
	align-items: center;
	color: white;
	user-select: none;
	cursor: default;
	border: 1px solid transparent;
	white-space: nowrap;

	${({ iconPlacement }) =>
		iconPlacement === 'right'
			? css`
					.badge-icon {
						margin-left: 0.3125rem;
						order: 1;
					}
			  `
			: css`
					.badge-icon {
						margin-right: 0.3125rem;
					}
			  `};

	${({ size, type, variation, variant }) => css`
		.badge-icon {
			width: ${ICON_SIZES[size]};
			height: ${ICON_SIZES[size]};
		}

		${SIZES[size][variation]}
		${TYPES[type][variant]}
		${VARIATIONS[variation]}
	`}
`;
