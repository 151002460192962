import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { RequestPreferencesModal } from '../../RequestsDirectory/RequestPreferencesModal';
import { Card, Typography } from '../../../components';
import { LOCATION_TYPES, COUNTY_OPTIONS } from '../../../../constants/general.constants';
import * as S from './RequestPreferencesCard.styles';

const RequestPreferencesCard = ({ className }) => {
	const user = useSelector((state) => state.user.value);
	const requestPreferencesModalRef = useRef(null);

	return (
		<Card className={className} title="Request Preferences" transparentHeaderBorder={true} actions={[{ id: 1, label: 'Edit', size: 'small', variant: 'outline', icon: { source: ['fal', 'edit'] }, onClick: () => requestPreferencesModalRef?.current?.edit(user?.requestPreferences) }]}>
			{Object.keys(user?.requestPreferences).length > 0 ? (
				<S.Wrapper>
					<Typography tag="h4" weight="bold">
						Location
					</Typography>

					<div className="info-item">
						<Typography tag="h6" weight="semibold">
							Distance
						</Typography>
						<Typography tag="p">{`${user?.requestPreferences?.distance} mi`}</Typography>
					</div>

					<div className="info-item info-item--with-badge">
						<Typography tag="h6" weight="semibold">
							County
						</Typography>
						<div className="badge-wrapper">
							{user?.requestPreferences?.counties?.map((county) => (
								<div key={county} className="small-badge">
									{COUNTY_OPTIONS.find((c) => c.code === county)?.name}
								</div>
							))}
						</div>
					</div>

					<div className="info-item">
						<Typography tag="h6" weight="semibold">
							Location Type
						</Typography>
						<Typography tag="p">{user?.requestPreferences?.locationTypes?.map((locationType) => LOCATION_TYPES.find((l) => l.id === locationType)?.name).join(', ')}</Typography>
					</div>

					<Typography tag="h4" weight="bold">
						Resource
					</Typography>

					<div className="info-item info-item--with-badge">
						<Typography tag="h6" weight="semibold">
							Resource Category
						</Typography>
						<div className="badge-wrapper">
							{user?.requestPreferences?.resourceCategories?.map((category, index) => (
								<div className="small-badge" key={category}>
									{category}
								</div>
							))}
						</div>
					</div>

					<div className="info-item info-item--with-badge">
						<Typography tag="h6" weight="semibold">
							Career Cluster
						</Typography>
						<div className="badge-wrapper">
							{user?.requestPreferences?.careerClusters?.map((cluster) => (
								<div className="small-badge" key={cluster}>
									{cluster}
								</div>
							))}
						</div>
					</div>

					<div className="info-item">
						<Typography tag="h6" weight="semibold">
							Age Group
						</Typography>
						<Typography tag="p">{user?.requestPreferences?.ageGroups?.join(', ')}</Typography>
					</div>
				</S.Wrapper>
			) : (
				<S.Wrapper>
					<Typography tag="h5">You have not set any request preferences.</Typography>
				</S.Wrapper>
			)}

			<RequestPreferencesModal ref={requestPreferencesModalRef} />
		</Card>
	);
};

RequestPreferencesCard.propTypes = {
	className: PropTypes.string,
};

export default RequestPreferencesCard;
