import React from 'react';
import { useFormContext } from 'react-hook-form';

import * as S from '../AdminCreateTemplate.styles';
import { RequestTemplate } from './RequestTemplate';
import { ResourceTemplate } from './ResourceTemplate';
import { FileTemplate } from './FileTemplate';

export const StepTemplateOverview = () => {
	const { watch } = useFormContext();

	const type = watch('type');

	return (
		<S.FormPanel>
			{type === 'request' && <RequestTemplate />}
			{type === 'resource' && <ResourceTemplate />}
			{type === 'file' && <FileTemplate />}
		</S.FormPanel>
	);
};
