import styled from 'styled-components';

import colors from '../../../../styles/colors';

export const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	gap: 1.25rem;

	.business-review-item {
		display: grid;
		grid-template-columns: 2.625rem 1fr;
		gap: 0.75rem;

		&__image-wrapper {
			img {
				display: block;
				width: 100%;
				border-radius: 9999px;
				border: 1px solid ${colors.neutralForm};
			}
		}

		&__fields-wrapper {
			display: grid;
			gap: 1.25rem;
			margin-top: 0.25rem;

			& > h3 {
				margin-bottom: -0.75rem;
			}
		}

		&.smaller {
			grid-template-columns: 2.125rem 1fr;

			.business-review-item__fields-wrapper {
				gap: 0.5rem;
			}
		}
	}

	.review {
		&__flex-row {
			display: flex;
			justify-content: space-between;

			a {
				display: flex;
				align-items: center;
				justify-content: center;
				text-decoration: none;
				color: ${colors.brandPrimary};

				> * + * {
					margin-left: 0.375rem;
				}
			}
		}
		&__step-item {
			display: grid;
			grid-template-columns: 1fr;
			gap: 1.5rem;
			align-content: start;

			&:first-child {
				margin-top: 0;
			}
		}
		&__files,
		&__links {
			border-bottom: 1px solid ${colors.neutralForm};
			padding-bottom: 1.5rem;

			& > h3 {
				margin-bottom: -1rem;
			}
		}
		&__file-item {
			display: grid;
			gap: 1rem;
			border-bottom: 1px solid ${colors.neutralForm};
			padding-bottom: 1.5rem;

			&:last-child {
				border: none;
				padding-bottom: 0;
			}
		}
		&__businesses {
			border-top: 1px solid ${colors.neutralForm};
			padding-top: 1rem;
		}

		&__learning {
			margin-bottom: 0.75rem;
		}

		&__step-half {
			display: grid;
			grid-template-columns: 1fr;
			gap: 1.5rem;

			@media (min-width: 1024px) {
				grid-template-columns: 1fr 1fr;
			}
		}

		&__info-item {
			& > h6 {
				color: ${colors.neutralText};
				margin-bottom: 0.375em;
			}

			&--with-badge {
				& > h6 {
					margin-bottom: calc(0.375em + 0.25rem);
				}
			}

			.badge-wrapper {
				display: flex;
				flex-wrap: wrap;
				margin: -0.25rem;

				.small-badge {
					padding: 0.25rem 0.5rem;
					border-radius: 6.25rem;
					border: 1px solid ${colors.brandPrimary};
					font-size: 0.75rem;
					color: ${colors.brandPrimary};
					font-weight: 700;
					margin: 0.25rem;
				}
			}
		}
	}
`;
