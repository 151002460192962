import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormContext } from 'react-hook-form';

import { GRADE_LEVELS, ROLES } from '../../../../../constants/general.constants';
import { fetchGroups } from '../../../../services/groups';
import { ErrorComponent, Spinner, Typography } from '../../../../components';
import { formatToMonthDayAndYear } from '../../../../utils/time-formatter';

export const StepFive = ({ worksiteLocations = [], role = null }) => {
	const { getValues } = useFormContext();
	const [groupStatus, setGroupStatus] = useState('idle');
	const [groups, setGroups] = useState([]);

	const user = useSelector((state) => state.user.value);
	const selectedSchool = user.schools.find((school) => school.id === getValues('schoolLocationId'));

	const selectedBusiness = worksiteLocations.find((business) => business.location.id === getValues('businessLocationId'));

	const selectedGroup = groups.find((group) => group.groupId === getValues('groupId'));

	const formatAvailabilityDates = () => {
		if (getValues('availabilityDates')) {
			if (getValues('availabilityDates').length < 2) {
				return formatToMonthDayAndYear(getValues('availabilityDates')[0]);
			} else {
				return `${formatToMonthDayAndYear(getValues('availabilityDates')[0])} - ${formatToMonthDayAndYear(getValues('availabilityDates')[1])}`;
			}
		}
		return 'Select Date(s) Above';
	};

	async function initializeGroupData() {
		setGroupStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchGroups({ page: 0 });

			setGroups(result.groups);
			setGroupStatus('success');
		} catch (error) {
			setGroupStatus('error');
		}
	}

	const renderGroupContent = () => {
		switch (groupStatus) {
			case 'loading':
				return <Spinner />;
			case 'error':
				return <ErrorComponent />;
			case 'success':
				return (
					<>
						<div className="step__content-item">
							<Typography tag="h3" weight="bold">
								Group
							</Typography>
						</div>
						<div className="step__information-card">
							<div className="icon-wrapper">
								<FontAwesomeIcon icon="users" />
							</div>
							<div className="info-wrapper">
								<Typography tag="h5" weight="bold">
									{selectedGroup?.name}
								</Typography>
								<div className="info-line">
									<FontAwesomeIcon icon={['fal', 'users']} />
									<Typography tag="p" variation="2">
										{`${selectedGroup?.totalStudents} students`}
									</Typography>
								</div>
								<div className="info-line">
									<FontAwesomeIcon icon={['fal', 'users-class']} />
									<Typography tag="p" variation="2">
										{GRADE_LEVELS.find((grade) => grade.value === selectedGroup?.gradeLevels[0])?.label}
									</Typography>
								</div>
								{selectedGroup?.subject && (
									<div className="info-line">
										<FontAwesomeIcon icon={['fal', 'chalkboard']} />
										<Typography tag="p" variation="2">
											{selectedGroup?.subject}
										</Typography>
									</div>
								)}
								{selectedGroup?.classCode && (
									<div className="info-line">
										<FontAwesomeIcon icon={['fal', 'book']} />
										<Typography tag="p" variation="2">
											{selectedGroup?.classCode}
										</Typography>
									</div>
								)}
							</div>
						</div>
					</>
				);
			default:
				return null;
		}
	};

	useEffect(() => {
		if (groupStatus === 'idle') {
			initializeGroupData();
		}
	}, [groupStatus]);

	return (
		<div className="step__wrapper">
			<header className="step__header">
				<Typography tag="h2" weight="extrablack" className="step__title">
					Review Your Booking
				</Typography>
				<Typography tag="p" className="step__subtitle">
					Please look over the booking you are about to submit.
				</Typography>
			</header>
			<section className="step__content">
				<div className="step__content-item">
					<Typography tag="h3" weight="bold">
						Date
					</Typography>
				</div>
				<div className="step__field-wrapper">
					<Typography tag="h6" weight="semibold" className="step__field-label">
						Date Range
					</Typography>
					<Typography tag="p" className="step__field-value">
						{formatAvailabilityDates()}
					</Typography>
				</div>
				{getValues('availabilityNotes') && (
					<div className="step__field-wrapper">
						<Typography tag="h6" weight="semibold" className="step__field-label">
							Availability Notes
						</Typography>
						<Typography tag="p" className="step__field-value">
							{getValues('availabilityNotes')}
						</Typography>
					</div>
				)}
				<div className="step__content-item">
					<Typography tag="h3" weight="bold">
						Location
					</Typography>
				</div>
				{getValues('locationTypes')?.includes('virtual') && (
					<div className="step__location">
						<div className="step__location-icon-wrapper">
							<FontAwesomeIcon icon={['fal', 'camera']} size="lg" />
						</div>
						<div className="step__location-fields-wrapper">
							<Typography tag="h4" weight="bold">
								Virtual
							</Typography>
							<div className="step__field-wrapper">
								<Typography tag="h6" weight="semibold" className="step__field-label">
									Notes
								</Typography>
								<Typography tag="p" className="step__field-value">
									{getValues('virtualLocationNotes')}
								</Typography>
							</div>
						</div>
					</div>
				)}
				{getValues('locationTypes')?.includes('worksite') && (
					<div className="step__location">
						<div className="step__location-icon-wrapper">
							<FontAwesomeIcon icon={['fal', 'building']} size="lg" />
						</div>
						<div className="step__location-fields-wrapper">
							<Typography tag="h4" weight="bold">
								Worksite Location
							</Typography>
							<div className="step__field-wrapper">
								<div className="step__information-card">
									<div className="icon-wrapper">
										<FontAwesomeIcon icon="building" />
									</div>
									<div className="info-wrapper">
										<Typography tag="h5" weight="bold">
											{selectedBusiness?.location?.name}
										</Typography>
										<div className="info-line">
											<FontAwesomeIcon icon={['fal', 'map-marker']} />
											<Typography tag="p" variation="2">
												{`${selectedBusiness?.location?.address.addressLine1}, ${selectedBusiness?.location?.address.state} ${selectedBusiness?.location?.address.postalCode}`}
											</Typography>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
				{getValues('locationTypes')?.includes('student') && (
					<div className="step__location">
						<div className="step__location-icon-wrapper">
							<FontAwesomeIcon icon={['fal', 'users']} size="lg" />
						</div>
						<div className="step__location-fields-wrapper">
							<Typography tag="h4" weight="bold">
								Student Location
							</Typography>
							<div className="step__field-wrapper">
								<div className="step__information-card">
									<div className="icon-wrapper">
										<FontAwesomeIcon icon="school" />
									</div>
									<div className="info-wrapper">
										<Typography tag="h5" weight="bold">
											{selectedSchool?.name}
										</Typography>
										<div className="info-line">
											<FontAwesomeIcon icon={['fal', 'map-marker']} />
											<Typography tag="p" variation="2">
												{`${selectedSchool?.address.addressLine1}, ${selectedSchool?.address.state} ${selectedSchool?.address.postalCode}`}
											</Typography>
										</div>
										<div className="info-line">
											<FontAwesomeIcon icon={['fal', 'landmark']} />
											<Typography tag="p" variation="2">
												{selectedSchool?.address.city}
											</Typography>
										</div>
										<div className="info-line">
											<FontAwesomeIcon icon={['fal', 'school']} />
											<Typography tag="p" variation="2">
												{selectedSchool?.schoolCode}
											</Typography>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
				{role === ROLES.TEACHER && renderGroupContent()}
				<div className="step__content-item">
					<Typography tag="h3" weight="bold">
						Notes
					</Typography>
				</div>
				<div className="step__field-wrapper">
					<Typography tag="h6" weight="semibold" className="step__field-label">
						Additional Notes
					</Typography>
					<Typography tag="p" className="step__field-value">
						{getValues('notes')}
					</Typography>
				</div>
			</section>
		</div>
	);
};

StepFive.propTypes = {
	worksiteLocations: PropTypes.array,
	role: PropTypes.string,
};
