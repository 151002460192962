import styled from 'styled-components';

import colors from '../../styles/colors';

export const CalendarWrapper = styled.div`
	.react-calendar {
		margin-top: 0.5rem;

		&__navigation {
			display: flex;

			&__arrow {
				appearance: none;
				border: none;
				background-color: transparent;
				color: ${colors.neutralButton};
				padding: 0 0.5rem;
				cursor: pointer;

				&__icon {
					width: 1.5rem;
					height: 1.5rem;
				}
			}

			&__next-button {
				padding-left: 0;
			}

			&__prev-button {
				padding-right: 0;
			}

			&__label {
				appearance: none;
				border: none;
				background-color: transparent;

				&__labelText {
					font-size: 1rem;
					font-weight: 700;
					line-height: 1.25rem;
					text-align: center;
					color: black;
				}
			}
		}

		&__viewContainer {
		}

		&__month-view {
			&__weekdays {
				margin-top: 1rem;

				&__weekday {
					display: flex;
					justify-content: center;
					padding: 0 0.5rem;
					font-size: 0.75rem;
					font-weight: 600;
					line-height: 1.125rem;
					letter-spacing: 0.5px;
					color: ${colors.neutralText};
					user-select: none;

					abbr {
						text-decoration: none;
					}
				}
			}
			&__days {
				&__day {
				}
			}
		}

		&__tile {
			display: flex;
			justify-content: center;
			appearance: none;
			border: none;
			background-color: transparent;
			padding: 0.25rem 0.5rem;
			margin: 0.5rem 0;
			cursor: pointer;

			abbr {
				display: grid;
				place-content: center;
				text-align: center;
				border-radius: 9999px;
				font-size: 1rem;
				font-weight: 600;
				line-height: 1.375rem;
				color: ${colors.brandPrimary};
				width: 2rem;
				min-width: 2rem;
				height: 2rem;
			}

			&:disabled {
				cursor: not-allowed;

				abbr {
					font-weight: 400;
					color: ${colors.neutralText};
				}
			}

			&--range {
				background-color: ${colors.brandPrimary};

				abbr {
					color: white;
				}
			}

			&--rangeStart {
				background-color: ${colors.brandPrimary};
				border-top-left-radius: 9999px;
				border-bottom-left-radius: 9999px;
				/* padding-left: 0;
				margin-left: 0; */

				abbr {
				}
			}

			&--rangeEnd {
				background-color: ${colors.brandPrimary};
				border-top-right-radius: 9999px;
				border-bottom-right-radius: 9999px;
				/* padding-right: 0;
				margin-right: 0; */

				abbr {
				}
			}
		}

		.rangeStartEnd {
			border-top-right-radius: 9999px;
			border-bottom-right-radius: 9999px;
			border-top-left-radius: 9999px;
			border-bottom-left-radius: 9999px;
		}

		.rangeStart {
			border-top-left-radius: 9999px;
			border-bottom-left-radius: 9999px;
		}

		.rangeEnd {
			border-top-right-radius: 9999px;
			border-bottom-right-radius: 9999px;
		}

		.inactiveDay {
			background-color: transparent;
			border-top-left-radius: 9999px;
			border-bottom-left-radius: 9999px;

			abbr {
				color: ${colors.brandPrimary};
			}
		}
	}

	.yellow-tile abbr {
		color: white;
		background-color: ${colors.stateWarning};
	}
	.green-tile abbr {
		color: white;
		background-color: ${colors.stateSuccess};
	}
	.blue-tile abbr {
		color: white;
		background-color: ${colors.brandPrimary};
	}
	.light-blue-tile abbr {
		color: white;
		background-color: ${colors.brandSecondary};
	}
	.purple-tile abbr {
		color: white;
		background-color: ${colors.brandPurple};
	}
	.red-tile abbr {
		color: white;
		background-color: ${colors.stateDanger};
	}
	.orange-tile abbr {
		color: white;
		background-color: ${colors.brandOrange};
	}
`;
