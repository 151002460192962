import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { logoutUser } from '../../services/authService';
import { clearUser } from '../../app/slices/user/userSlice';
import { removeCookie } from '../../utils/cookie-methods';
import { Spinner } from '../../components';
import * as S from './Logout.styles';
import { META_TITLE, SESSION_TOKEN_COOKIE } from '../../../constants/general.constants';
import { clearSavedStates } from '../../app/slices/filterSearch/filterSearchSlice';

const Logout = () => {
	const dispatch = useDispatch();

	const [shouldRedirect, setShouldRedirect] = useState(false);

	useEffect(() => {
		// Set document title
		document.title = `Logout | ${META_TITLE}`;

		let mounted = true;
		logoutUser().then(() => {
			if (mounted) {
				dispatch(clearUser());
				dispatch(clearSavedStates());
				removeCookie(SESSION_TOKEN_COOKIE);
				setShouldRedirect(true);
			}
		});
		return () => (mounted = false);
	}, []);

	return !shouldRedirect ? (
		<S.Wrapper>
			<Spinner />
		</S.Wrapper>
	) : (
		<Redirect
			to={{
				pathname: '/login',
			}}
		/>
	);
};

export default Logout;
