import React from 'react';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as S from '../../CreateResource.styles';
import { Button, DatePicker, Radio, TextArea, Typography } from '../../../../components';
import { required } from '../../../../utils/form-default-errors';

export const DateAndTimeStep = () => {
	const {
		register,
		control,
		watch,
		formState: { errors },
	} = useFormContext();

	const isMultiple = watch('isMultiple');

	const { fields, append, remove } = useFieldArray({ control, name: 'timeFrames' });

	const handleAddLocation = () => {
		append({});
	};

	const handleRemoveLocation = (index) => {
		remove(index);
	};

	return (
		<>
			<Typography tag="h2" weight="extrablack" center>
				Date and Time
			</Typography>
			<Typography tag="p" center>
				Add in your availability for this request.
			</Typography>

			<div className="fields-wrapper">
				<Typography tag="h3" weight="bold">
					Event Duration
				</Typography>

				<S.RadioOptionsWrapper>
					<Typography className={`field-label${!!errors.isMultiple ? ' with-error' : ''}`} tag="p" variation="2" weight="semibold">
						Are there multiple time frames for this event?
					</Typography>

					<div className="radio-field">
						<Radio
							id="isMultiple1"
							value="yes"
							{...register('isMultiple', {
								required: required('Is this multiple'),
							})}
						/>
						<label htmlFor="isMultiple1">Yes, it is happening in multiple different time frames</label>
					</div>
					<div className="radio-field">
						<Radio
							id="isMultiple2"
							value="no"
							{...register('isMultiple', {
								required: required('Is this multiple'),
							})}
						/>
						<label htmlFor="isMultiple2">No, there is only one time frame for the event</label>
					</div>

					{!!errors.isMultiple && (
						<div className="error-wrapper">
							<Typography tag="p" variation="2">
								{errors.isMultiple.message}
							</Typography>
						</div>
					)}
				</S.RadioOptionsWrapper>

				{isMultiple === 'no' ? (
					<>
						<Typography tag="h3" weight="bold">
							Time Frame
						</Typography>

						<div className="two-col">
							<Controller control={control} name="startDate" rules={{ required: required('Start Date') }} render={({ field: { value, onChange } }) => <DatePicker label="Start Date" placeholder="mm/dd/yyyy" selected={value} onChange={onChange} error={errors?.startDate} />} />
							<Controller
								control={control}
								name="startTime"
								rules={{ required: required('Start Time') }}
								render={({ field: { value, onChange } }) => <DatePicker label="Start Time" selected={value} onChange={onChange} error={errors?.startTime} showTimeSelect showTimeSelectOnly timeIntervals={15} timeCaption="Time" dateFormat="h:mm a" withoutIcon />}
							/>
						</div>

						<div className="two-col">
							<Controller control={control} name="endDate" rules={{ required: required('End Date') }} render={({ field: { value, onChange } }) => <DatePicker label="End Date" placeholder="mm/dd/yyyy" selected={value} onChange={onChange} error={errors?.endDate} />} />
							<Controller
								control={control}
								name="endTime"
								rules={{ required: required('End Time') }}
								render={({ field: { value, onChange } }) => <DatePicker label="End Time" selected={value} onChange={onChange} error={errors?.endTime} showTimeSelect showTimeSelectOnly timeIntervals={15} timeCaption="Time" dateFormat="h:mm a" withoutIcon />}
							/>
						</div>

						<TextArea label="Time Frame Notes" id="time-frame-notes" placeholder="Write a message..." {...register('timeFrameNotes')} />
					</>
				) : (
					<>
						{fields.map(({ id }, index) => (
							<S.LocationItem key={id}>
								<Typography tag="h5" weight="bold">
									Time Frame {index + 1}
								</Typography>
								<S.FieldsWrapper>
									<div className="two-col">
										<Controller
											control={control}
											name={`timeFrames.${index}.startDate`}
											rules={{ required: required('Start Date') }}
											render={({ field: { value, onChange } }) => <DatePicker label="Start Date" placeholder="mm/dd/yyyy" selected={value} onChange={onChange} error={errors?.timeFrames?.[index]?.startDate} />}
										/>
										<Controller
											control={control}
											name={`timeFrames.${index}.startTime`}
											rules={{ required: required('Start Time') }}
											render={({ field: { value, onChange } }) => (
												<DatePicker label="Start Time" selected={value} onChange={onChange} error={errors?.timeFrames?.[index]?.startTime} showTimeSelect showTimeSelectOnly timeIntervals={15} timeCaption="Time" dateFormat="h:mm a" withoutIcon />
											)}
										/>
									</div>
									<div className="two-col">
										<Controller
											control={control}
											name={`timeFrames.${index}.endDate`}
											rules={{ required: required('End Date') }}
											render={({ field: { value, onChange } }) => <DatePicker label="End Date" placeholder="mm/dd/yyyy" selected={value} onChange={onChange} error={errors?.timeFrames?.[index]?.endDate} />}
										/>
										<Controller
											control={control}
											name={`timeFrames.${index}.endTime`}
											rules={{ required: required('End Time') }}
											render={({ field: { value, onChange } }) => (
												<DatePicker label="End Time" selected={value} onChange={onChange} error={errors?.timeFrames?.[index]?.endTime} showTimeSelect showTimeSelectOnly timeIntervals={15} timeCaption="Time" dateFormat="h:mm a" withoutIcon />
											)}
										/>
									</div>
									<TextArea
										label="Time Frame Notes"
										id={`${index}-time-frame-notes`}
										error={errors?.timeFrames?.[index]?.notes}
										{...register(`timeFrames.${index}.notes`, {
											required: required('Time Frame Notes'),
										})}
									/>
								</S.FieldsWrapper>
								{fields.length > 1 && (
									<Button className="location-remove" variant="text" type="button" onClick={() => handleRemoveLocation(index)}>
										<Typography variation="button-medium" weight="bold">
											Remove
										</Typography>
									</Button>
								)}
							</S.LocationItem>
						))}
						<S.AddWrapper>
							<Button variant="text" type="button" onClick={handleAddLocation}>
								<FontAwesomeIcon icon={['fal', 'plus']} />
								<Typography variation="button-medium" weight="bold">
									Add Location
								</Typography>
							</Button>
						</S.AddWrapper>
					</>
				)}
			</div>
		</>
	);
};
