import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { fetchBookings } from '../../../services/bookings';
import { Card, Picture, Typography, Spinner, ErrorComponent } from '../../../components';
import * as S from './PendingBookings.styles';
import { LOCATION_TYPES } from '../../../../constants/general.constants';

export const PendingBookings = ({ className }) => {
	const history = useHistory();
	const [pageStatus, setPageStatus] = useState('idle');
	const [bookings, setBookings] = useState([]);

	async function initializeData() {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchBookings({ filterOptions: { status: ['pending'], inbound: true } });
			setBookings(result.bookings);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}

	useEffect(() => {
		if (pageStatus === 'idle') {
			initializeData();
		}
	}, []);

	const renderContent = () => {
		switch (pageStatus) {
			case 'loading':
				return <Spinner />;
			case 'error':
				return <ErrorComponent />;
			case 'success':
				return bookings?.length > 0 ? (
					<>
						{bookings.map((booking) => (
							<S.CardWrapper
								key={booking.id}
								onClick={() => {
									history.push({ pathname: `/business/bookings/${booking.id}`, state: { backUrl: window.location.pathname, backTitle: 'Back to Dashboard' } });
								}}
							>
								<div className="review-card__card-wrapper">
									<Picture className="review-card__thumbnail" src={booking?.resource?.coverImageObj?.['200']} aspectRatio="3/2" />
									<div className="review-card__info-wrapper">
										<Typography className="review-card__info-wrapper__type" tag="p" variation="3">
											{booking.resource.category || 'Event'}
										</Typography>
										<Typography className="review-card__info-wrapper__title" tag="h5" weight="bold">
											{booking?.resource?.title}
										</Typography>
										<div className="review-card__info-wrapper__details-wrapper">
											<div className="review-card__info-wrapper__details-wrapper__detail-item">
												<FontAwesomeIcon icon={['fal', 'building']} />
												<Typography tag="p" variation="2">
													{booking.business.name}
												</Typography>
											</div>
											<div className="review-card__info-wrapper__details-wrapper__detail-item">
												<FontAwesomeIcon icon={['fal', 'calendar']} />
												<Typography tag="p" variation="2">
													{booking.scheduleDateRange || booking.availabilityDateRange}
												</Typography>
											</div>
											<div className="review-card__info-wrapper__details-wrapper__detail-item">
												<FontAwesomeIcon icon={['fal', 'users']} />
												<Typography tag="p" variation="2">
													{booking.group.name || 'No Group'}
												</Typography>
											</div>
											<div className="review-card__info-wrapper__details-wrapper__detail-item">
												<FontAwesomeIcon icon={['fal', 'map-marker']} />
												<Typography tag="p" variation="2">
													{booking.locationTypes.map((type) => LOCATION_TYPES.find((item) => item.id === type).name).join(', ')}
												</Typography>
											</div>
										</div>
									</div>
								</div>
							</S.CardWrapper>
						))}
					</>
				) : (
					<S.EmptyBusinessCard paddingTop={4} paddingBottom={6}>
						<div className="icon-box">
							<FontAwesomeIcon icon={['fal', 'envelope']} size="4x" />
						</div>
						<Typography tag="h4" weight="bold" center>
							You Have No Pending Bookings
						</Typography>
						<Typography tag="p" center>
							Pending bookings will appear here.
						</Typography>
					</S.EmptyBusinessCard>
				);
			default:
				return null;
		}
	};

	return (
		<Card
			className={className}
			title="Pending Bookings"
			actions={[
				{
					id: 1,
					label: 'View All',
					size: 'small',
					variant: 'text',
					onClick: () => {
						history.push('/business/bookings');
					},
					icon: {
						source: ['fal', 'arrow-right'],
						placement: 'right',
					},
				},
			]}
		>
			<S.Wrapper>{renderContent()}</S.Wrapper>
		</Card>
	);
};

PendingBookings.propTypes = {
	className: PropTypes.string,
};
