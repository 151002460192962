import styled, { css } from 'styled-components';

import colors from '../../../styles/colors';

export const HugeImageUploader = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;

	label {
		color: ${colors.neutralText};
		cursor: pointer;
	}

	.reset-btn {
		appearance: none;
		border: none;
		border-radius: 0.375rem;
		cursor: pointer;
		background-color: ${colors.brandPrimary};
		color: white;
		display: grid;
		place-content: center;
		width: 2.25rem;
		height: 2.25rem;
		position: absolute;
		top: 2.25rem;
		right: 1rem;

		svg {
			width: 1.125rem;
			height: 1.125rem;
		}

		&:hover {
			opacity: 0.8;
		}
	}

	${({ withError }) =>
		withError &&
		css`
			label {
				color: ${colors.stateDanger};
			}

			${FileDropZone} {
				border-color: ${colors.stateDanger};
			}
		`}

	.error-wrapper {
		position: absolute;
		bottom: -1rem;
		color: ${colors.stateDanger};
	}
`;

export const FileDropZone = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	border: 1px dashed ${colors.neutralText};
	height: ${({ alternative }) => (alternative ? '12.5rem' : '25rem')};
	border-radius: 0.375rem;
	cursor: pointer;

	overflow: hidden;

	img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

	.upload-placeholder {
		display: grid;
		grid-template-columns: 1fr;
		gap: 0.5rem;

		&__picture {
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 0.375rem;
			padding: 0.5rem;
			width: 100%;
			max-width: 8.25rem;
			margin: 1rem auto 0;
			background-color: ${colors.neutralMessage};
			color: ${colors.brandPrimary};
		}

		&__paragraph {
			color: ${colors.neutralText};

			strong {
				color: ${colors.brandPrimary};
			}
		}
	}

	${({ isDragActive }) =>
		isDragActive &&
		css`
			opacity: 0.8;
		`}

	${({ isDragAccept }) =>
		isDragAccept &&
		css`
			border-color: ${colors.stateSuccess};
		`}

	${({ isDragReject }) =>
		isDragReject &&
		css`
			border-color: ${colors.stateDanger};
		`}
`;

export const CheckboxWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 1rem;
	position: relative;

	.field-label {
		color: ${colors.neutralText};

		&.with-error {
			color: ${colors.stateDanger};
		}
	}

	.checkbox-field {
		display: flex;
		align-items: center;

		& > * + * {
			padding-left: 0.75rem;
		}

		label {
			font-size: 1rem;
			line-height: 1.375rem;
			font-weight: 400;
			color: black;
			cursor: pointer;
		}
	}

	.error-wrapper {
		color: ${colors.stateDanger};
	}

	@media screen and (min-width: 768px) {
		grid-template-columns: 1fr 1fr;

		.field-label,
		.error-wrapper {
			grid-column: 1 / 3;
		}
	}
`;

export const FilesListWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	gap: 1rem;

	& > header {
		label {
			color: ${colors.neutralText};
			cursor: pointer;
		}
	}

	.files-wrapper {
		display: grid;
		grid-template-columns: 1fr;
		gap: 1rem;

		header {
			display: flex;
			justify-content: space-between;
			align-items: center;

			button {
				color: ${colors.neutralText};
			}
		}

		&__item {
			display: grid;
			grid-template-columns: 1fr;
			gap: 1rem;
			padding-bottom: 1.5rem;
			border-bottom: 1px solid ${colors.neutralForm};

			&__info {
				h6 {
					color: ${colors.neutralText};
					margin-bottom: 0.375em;
				}
			}

			&__details {
				display: flex;
				align-items: center;

				& > div {
					margin-left: 0.5rem;

					& > p:last-child {
						color: ${colors.neutralText};
					}
				}
			}

			&:last-child {
				padding-bottom: 0;
				border-bottom: none;
			}
		}
	}
`;

export const FileListDropzone = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	border: 1px dashed ${colors.neutralText};
	height: 12.5rem;
	border-radius: 0.375rem;
	cursor: pointer;

	overflow: hidden;

	img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

	.upload-placeholder {
		display: grid;
		grid-template-columns: 1fr;
		gap: 0.5rem;

		&__picture {
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 0.375rem;
			padding: 0.5rem;
			width: 100%;
			max-width: 8.25rem;
			margin: 1rem auto 0;
			background-color: ${colors.neutralMessage};
			color: ${colors.brandPrimary};
		}

		&__paragraph {
			color: ${colors.neutralText};

			strong {
				color: ${colors.brandPrimary};
			}
		}
	}

	${({ isDragActive }) =>
		isDragActive &&
		css`
			opacity: 0.8;
		`}

	${({ isDragAccept }) =>
		isDragAccept &&
		css`
			border-color: ${colors.stateSuccess};
		`}

	${({ isDragReject }) =>
		isDragReject &&
		css`
			border-color: ${colors.stateDanger};
		`}
`;
