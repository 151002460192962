import React, { useEffect } from 'react';

import { TeacherNavigation, PageHeader } from '../../components';
import { BookedCard } from './BookedCard';
import { PendingReviews } from './PendingReviews';
import { RecentResponses } from './RecentResponses';
import * as S from './TeacherDashboard.styles';
import { META_TITLE } from '../../../constants/general.constants';

const TeacherDashboard = () => {
	useEffect(() => {
		// Set document title
		document.title = `Dashboard | ${META_TITLE}`;
	}, []);

	return (
		<TeacherNavigation>
			<PageHeader title="Dashboard" />
			<S.Wrapper>
				<BookedCard className="a1" />
				<PendingReviews className="a2" />
				<RecentResponses className="a3" />
			</S.Wrapper>
		</TeacherNavigation>
	);
};

export default TeacherDashboard;
