import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as S from './Stepper.styles';

export const Stepper = ({ activeStep = 0, steps = [], maxWidth = 10 }) => {
	return (
		<S.Wrapper>
			<S.Container maxWidth={maxWidth}>
				<S.ProgressBar>{steps.map(({ id }, index) => (index === 0 ? null : <S.Line key={id} status={activeStep >= index ? 'completed' : null} />))}</S.ProgressBar>
				{steps.map(({ id, label }, index) => (
					<S.Dot key={id} status={activeStep === index ? 'active' : activeStep > index ? 'completed' : null}>
						<FontAwesomeIcon className="step-icon" icon={['fal', 'check']} />
						<span className="step-number">{id}</span>
						<span className="step-label">{label}</span>
					</S.Dot>
				))}
			</S.Container>
		</S.Wrapper>
	);
};
Stepper.propTypes = {
	activeStep: PropTypes.number.isRequired,
	steps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	maxWidth: PropTypes.number,
};
