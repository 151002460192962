import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { useMediaQuery } from 'beautiful-react-hooks';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

import { AdminNavigation, BusinessNavigation, Button, HugeDropdown, MessageDialog, PageHeader, Typography } from '../../components';
import { errorHandler } from '../../services/authService';
import { createUpdateResource, updatePublishStatusResource } from '../../services/resources';
import * as S from './CreateResource.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StepResourceType } from './StepResourceType';
import { StepResourceCategory } from './StepResourceCategory';
import { StepTemplate } from './StepTemplate';
import { StepReview } from './StepReview';
import { StepDetails } from './StepDetails';
import { uploadResources } from '../../services/media';
import { META_TITLE, ROLES } from '../../../constants/general.constants';

const STEP_DETAILS_OPTIONS = {
	appointment: [
		{ id: 1, name: 'Overview' },
		{ id: 2, name: 'Files' },
		{ id: 3, name: 'Location' },
		{ id: 4, name: 'Availability' },
	],
	event: [
		{ id: 1, name: 'Overview' },
		{ id: 2, name: 'Files' },
		{ id: 3, name: 'Event Link' },
		{ id: 4, name: 'Location' },
		{ id: 5, name: 'Date and Time' },
	],
	information: [
		{ id: 1, name: 'Overview' },
		{ id: 2, name: 'Files' },
		{ id: 3, name: 'Links' },
	],
};

const DEFAULT_STEPS = [
	{ id: 1, name: 'Resource Type', completed: false },
	{ id: 2, name: 'Resource Category', completed: false },
	{ id: 3, name: 'Template', completed: false },
	{ id: 4, name: 'Details', completed: false },
	{ id: 5, name: 'Review', completed: false },
];

const EVENT_STEPS = [
	{ id: 1, name: 'Resource Type', completed: false },
	{ id: 4, name: 'Details', completed: false },
	{ id: 5, name: 'Review', completed: false },
];

const CreateResource = ({ role }) => {
	const history = useHistory();
	const location = useLocation();
	const [steps, setSteps] = useState(DEFAULT_STEPS);

	const [confirmationDraftIsOpen, setConfirmationDraftIsOpen] = useState(false);
	const [confirmationIsOpen, setConfirmationIsOpen] = useState(false);

	const isMobile = useMediaQuery('(max-width: 992px)');
	const [selectedStep, setSelectedStep] = useState(DEFAULT_STEPS[0]);
	const methods = useForm({
		defaultValues: {
			locationTypes: [],
			worksiteLocations: [
				{
					id: 1,
				},
			],
			isMultiple: 'no',
			timeFrames: [
				{
					id: 1,
				},
			],
		},
	});

	const type = methods.watch('type');
	const [selectedDetailsStepId, setSelectedDetailsStepId] = useState(1);

	function handleOpenConfirmationModal() {
		setConfirmationIsOpen(true);
	}

	function handleCloseConfirmationModal() {
		setConfirmationIsOpen(false);
		history.push(role === ROLES.BUSINESS ? '/business/resources' : '/admin/directory/resources');
	}

	function handleOpenConfirmationDraftModal() {
		setConfirmationDraftIsOpen(true);
	}

	function handleCloseConfirmationDraftModal() {
		setConfirmationDraftIsOpen(false);
		history.push(role === ROLES.BUSINESS ? '/business/resources' : '/admin/directory/resources');
	}

	async function handleDraftClick() {
		const { coverImage, publicFiles, privateFiles, ...rest } = methods.getValues();

		if (coverImage instanceof File) {
			const coverImageData = await uploadCoverImage(coverImage);
			rest.coverImageObj = coverImageData;
		} else {
			rest.coverImageObj = coverImage;
		}

		if (publicFiles?.length > 0) {
			const publicFilesData = await Promise.all(publicFiles.map(uploadFile));
			rest.publicFiles = publicFilesData;
		}
		if (privateFiles?.length > 0) {
			const privateFilesData = await Promise.all(privateFiles.map(uploadFile));
			rest.privateFiles = privateFilesData;
		}

		if (rest.isMultiple === 'yes') {
			rest.timeFrames = rest.timeFrames.map((timeFrame) => {
				return {
					...timeFrame,
					startDate: format(timeFrame.startDate, 'MM-dd-yyyy'),
					endDate: format(timeFrame.endDate, 'MM-dd-yyyy'),
					startTime: format(timeFrame.startTime, 'h:mm a'),
					endTime: format(timeFrame.endTime, 'h:mm a'),
					notes: timeFrame.notes,
				};
			});
		} else {
			rest.timeFrames = [
				{
					startDate: rest.startDate ? format(rest.startDate, 'MM-dd-yyyy') : null,
					endDate: rest.endDate ? format(rest.endDate, 'MM-dd-yyyy') : null,
					startTime: rest.startTime ? format(rest.startTime, 'h:mm a') : null,
					endTime: rest.endTime ? format(rest.endTime, 'h:mm a') : null,
				},
			];
		}

		const payload = {
			...rest,
			startDate: rest.startDate ? format(rest.startDate, 'MM-dd-yyyy') : null,
			endDate: rest.endDate ? format(rest.endDate, 'MM-dd-yyyy') : null,
			templateId: rest.templateId === 'START_FROM_SCRATCH' ? null : rest.templateId,
			privateFiles: rest.privateFiles ? rest.privateFiles : [],
			publicFiles: rest.publicFiles ? rest.publicFiles : [],
			links: rest.links ? rest.links : [],
			isOngoing: rest.isOngoing === 'yes',
			userId: location.state?.userId,
			businessId: location.state?.businessId,
		};

		await createUpdateResource(payload);

		handleOpenConfirmationDraftModal();
	}

	async function uploadCoverImage(file) {
		const formData = new FormData();
		formData.append('file', file);

		const { data } = await uploadResources(formData);

		return data;
	}

	async function uploadFile(file) {
		const formData = new FormData();
		formData.append('file', file);

		const {
			data: { document },
		} = await uploadResources(formData);

		return { title: file?.title, description: file?.description, url: document };
	}

	async function asyncCaller(data) {
		const { coverImage, publicFiles, privateFiles, ...rest } = data;

		if (coverImage instanceof File) {
			const coverImageData = await uploadCoverImage(coverImage);
			rest.coverImageObj = coverImageData;
		} else {
			rest.coverImageObj = coverImage;
		}

		if (publicFiles?.length > 0) {
			const publicFilesData = await Promise.all(publicFiles.map(uploadFile));
			rest.publicFiles = publicFilesData;
		}
		if (privateFiles?.length > 0) {
			const privateFilesData = await Promise.all(privateFiles.map(uploadFile));
			rest.privateFiles = privateFilesData;
		}

		if (rest.isMultiple === 'yes') {
			rest.timeFrames = rest.timeFrames.map((timeFrame) => {
				return {
					...timeFrame,
					startDate: format(timeFrame.startDate, 'MM-dd-yyyy'),
					endDate: format(timeFrame.endDate, 'MM-dd-yyyy'),
					startTime: format(timeFrame.startTime, 'h:mm a'),
					endTime: format(timeFrame.endTime, 'h:mm a'),
					notes: timeFrame.notes,
				};
			});
		} else {
			rest.timeFrames = [
				{
					startDate: rest.startDate ? format(rest.startDate, 'MM-dd-yyyy') : null,
					endDate: rest.endDate ? format(rest.endDate, 'MM-dd-yyyy') : null,
					startTime: rest.startTime ? format(rest.startTime, 'h:mm a') : null,
					endTime: rest.endTime ? format(rest.endTime, 'h:mm a') : null,
					notes: rest.timeFrameNotes,
				},
			];
		}

		const payload = {
			...rest,
			startDate: rest.startDate ? format(rest.startDate, 'MM-dd-yyyy') : null,
			endDate: rest.endDate ? format(rest.endDate, 'MM-dd-yyyy') : null,
			templateId: rest.templateId === 'START_FROM_SCRATCH' ? null : rest.templateId,
			privateFiles: rest.privateFiles ? rest.privateFiles : [],
			publicFiles: rest.publicFiles ? rest.publicFiles : [],
			links: rest.links ? rest.links : [],
			isOngoing: rest.isOngoing === 'yes',
			userId: location.state?.userId,
			businessId: location.state?.businessId,
		};

		const {
			data: {
				result: { resource },
			},
		} = await createUpdateResource(payload);

		await updatePublishStatusResource(resource.id);

		handleOpenConfirmationModal();
	}

	const completeStep = (stepIndex) => {
		setSteps((prev) => {
			const newSteps = [...prev];
			newSteps[stepIndex].completed = true;
			return newSteps;
		});
	};

	const uncompleteStep = (stepIndex) => {
		setSteps((prev) => {
			const newSteps = [...prev];
			newSteps[stepIndex].completed = false;
			return newSteps;
		});
	};

	const handleBackButton = () => {
		if (selectedStep.id > 1) {
			if (selectedStep.id === 4 && selectedDetailsStepId > 1) {
				setSelectedDetailsStepId(selectedDetailsStepId - 1);
			} else {
				uncompleteStep(type === 'event' ? selectedStep.id - 4 : selectedStep.id - 2);
				if (selectedStep.id - 1 === 4) {
					setSelectedDetailsStepId(STEP_DETAILS_OPTIONS[type].length);
				}
				setSelectedStep(steps[type === 'event' ? selectedStep.id - 4 : selectedStep.id - 2]);
			}
		} else {
			history.goBack();
		}
	};

	const handleStepClick = (step) => {
		if ((type === 'event' && (step?.completed || steps[step.id - 4]?.completed)) || step?.completed || steps[step.id - 2]?.completed) {
			if (step.id === 4) {
				setSelectedDetailsStepId(1);
			}
			setSelectedStep(step);
		}
	};

	const handleDetailStepOptionClick = (stepId) => {
		if (type === 'event') {
			setSelectedStep(steps[1]);
		} else {
			setSelectedStep(steps[3]);
		}
		setSelectedDetailsStepId(stepId);
	};

	const onSubmit = (e) => {
		switch (selectedStep.id) {
			case 1:
				const currentType = methods.getValues('type');
				methods.reset();
				methods.setValue('type', currentType);
				methods.handleSubmit(() => {
					completeStep(0);
					setSelectedStep(steps[selectedStep.id]);
				})(e);
				break;
			case 2:
				methods.handleSubmit(() => {
					completeStep(1);
					setSelectedStep(steps[selectedStep.id]);
				})(e);
				break;
			case 3:
				methods.handleSubmit(() => {
					completeStep(2);
					setSelectedStep(steps[selectedStep.id]);
				})(e);
				break;
			case 4:
				methods.handleSubmit(() => {
					if ((type === 'appointment' && selectedDetailsStepId === 4) || (type === 'information' && selectedDetailsStepId === 3)) {
						completeStep(3);
						setSelectedStep(steps[selectedStep.id]);
					} else if (type === 'event' && selectedDetailsStepId === 5) {
						completeStep(1);
						setSelectedStep(steps[2]);
					} else {
						setSelectedDetailsStepId((prev) => prev + 1);
					}
				})(e);
				break;
			case 5:
				methods.handleSubmit(asyncCaller)(e).catch(errorHandler);
				break;
		}
	};

	useEffect(() => {
		if (type === 'event') {
			setSteps(EVENT_STEPS.map((step) => ({ ...step, completed: false })));
			setSelectedStep({ ...EVENT_STEPS[0], completed: false });
		} else {
			setSteps(DEFAULT_STEPS.map((step) => ({ ...step, completed: false })));
			setSelectedStep({ ...DEFAULT_STEPS[0], completed: false });
		}
	}, [type]);

	useEffect(() => {
		// Set document title
		document.title = `Create Resource | ${META_TITLE}`;
	}, []);

	const renderContent = () => {
		return (
			<>
				{!isMobile && <PageHeader title="Create Resource" />}
				{isMobile && <HugeDropdown value={selectedStep} onChange={handleStepClick} steps={steps} />}
				<S.MainWrapper>
					{!isMobile && (
						<S.SidebarWrapper>
							{steps.map((step, index) => (
								<Fragment key={step.id}>
									<button onClick={() => handleStepClick(step)} className={`sidebar-option${step === selectedStep ? ' active' : ''}${step.completed ? ' completed' : ''}`}>
										<div className="sidebar-option__circle">
											<FontAwesomeIcon icon={['fal', 'check']} />
											<span>{index + 1}</span>
										</div>
										<span className="sidebar-option__name">{step.name}</span>
									</button>
									{selectedStep?.id >= 4 && step.id === 4 && (
										<div className="sidebar-option__details-options-wrapper">
											{STEP_DETAILS_OPTIONS[type].map((option) => (
												<button key={option.id} onClick={() => handleDetailStepOptionClick(option.id)} className={`sidebar-option__details-option${selectedDetailsStepId === option.id && selectedStep.id === 4 ? ' selected' : ''}`}>
													{option.name}
												</button>
											))}
										</div>
									)}
								</Fragment>
							))}
						</S.SidebarWrapper>
					)}
					<FormProvider {...methods}>
						<S.FormWrapper onSubmit={onSubmit}>
							<S.FormContent>
								{selectedStep.id === 1 && <StepResourceType />}
								{selectedStep.id === 2 && <StepResourceCategory />}
								{selectedStep.id === 3 && <StepTemplate />}
								{selectedStep.id === 4 && <StepDetails selectedDetailsStepId={selectedDetailsStepId} />}
								{selectedStep.id === 5 && <StepReview />}
							</S.FormContent>
							<S.FormFooter>
								<Button className="back-button" variant="outline" variation="secondary" type="button" onClick={handleBackButton}>
									<Typography variation="button-medium" weight="bold">
										Back
									</Typography>
								</Button>
								<div className="spacer" />
								<div className="submit-container">
									{selectedStep.id === 5 && (
										<Button variant="outline" type="button" onClick={handleDraftClick}>
											<Typography variation="button-medium" weight="bold">
												Save as Draft
											</Typography>
										</Button>
									)}

									<Button>
										<Typography variation="button-medium" weight="bold">
											{selectedStep.id === 5 ? (methods.formState.isSubmitting ? 'Loading' : 'Publish Now') : 'Next'}
										</Typography>
									</Button>
								</div>
							</S.FormFooter>
						</S.FormWrapper>
					</FormProvider>
				</S.MainWrapper>
				<MessageDialog isOpen={confirmationDraftIsOpen} onRequestClose={handleCloseConfirmationDraftModal} title="Resource Saved" subtitle="Your resource has been saved as a draft. Come back to publish it at any time." icon={['fal', 'check']} />
				<MessageDialog isOpen={confirmationIsOpen} onRequestClose={handleCloseConfirmationModal} title="Resource Posted" subtitle="Your resource has been posted. Users can now book appointments with you." icon={['fal', 'check']} />
			</>
		);
	};

	if (role === ROLES.BUSINESS) {
		return <BusinessNavigation>{renderContent()}</BusinessNavigation>;
	}
	return <AdminNavigation>{renderContent()}</AdminNavigation>;
};

CreateResource.propTypes = {
	role: PropTypes.string,
};

export default CreateResource;
