import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Card, Calendar, Typography } from '../../../../components';
import * as S from './AvailabilityCard.styles';

export const AvailabilityCard = ({ role, className, booking: { resource } }) => {
	return (
		<Card title={resource.type === 'event' ? 'Date and Time' : 'Availability'} className={className} transparentHeaderBorder>
			<S.Wrapper>
				{resource.type === 'event' ? (
					<>
						{resource.timeFrames.map((timeFrame) => (
							<>
								<div className="availability-info">
									<div className="availability-info__element a1">
										<Typography className="availability-info__title" tag="h6" weight="semibold">
											Date
										</Typography>
										<Typography className="availability-info__text" tag="p">
											{timeFrame.displayDate}
										</Typography>
									</div>
									<div className="availability-info__element a2">
										<Typography className="availability-info__title" tag="h6" weight="semibold">
											Time
										</Typography>
										<Typography className="availability-info__text" tag="p">
											{timeFrame.displayTime}
										</Typography>
									</div>
								</div>
							</>
						))}
						<Calendar
							allDates={resource.availablilityDates.map((date) => moment(date, 'MM-DD-YYYY', true).toDate().toDateString())}
							value={resource.availablilityDates.length > 0 ? [moment(resource.availablilityDates[0], 'MM-DD-YYYY', true).toDate(), moment(resource.availablilityDates[resource.availablilityDates.length - 1], 'MM-DD-YYYY', true).toDate()] : undefined}
						/>
					</>
				) : (
					<Calendar
						allDates={resource.availablilityDates.map((date) => moment(date, 'MM-DD-YYYY', true).toDate().toDateString())}
						value={resource.availablilityDates.length > 0 ? [moment(resource.availablilityDates[0], 'MM-DD-YYYY', true).toDate(), moment(resource.availablilityDates[resource.availablilityDates.length - 1], 'MM-DD-YYYY', true).toDate()] : undefined}
					/>
				)}
			</S.Wrapper>
		</Card>
	);
};

AvailabilityCard.propTypes = {
	className: PropTypes.string,
	booking: PropTypes.shape(),
	role: PropTypes.string,
};
