import React, { useEffect } from 'react';
import ReactPlayerVimeo from 'react-player/vimeo';
import ReactPlayerYouTube from 'react-player/youtube';

import logo from '../../assets/ncbce-logo-alt.png';
import { Typography, Link, PublicNavigation } from '../../components';
import * as S from './About.styles';
import { META_TITLE } from '../../../constants/general.constants';

const About = () => {
	useEffect(() => {
		// Set document title
		document.title = `About The Navigator | ${META_TITLE}`;
	}, []);

	return (
		<PublicNavigation>
			<S.Wrapper>
				<S.Header>
					<div className="flex-1" />
					<div className="centered">
						<Link to="/directory">
							<S.Picture>
								<img src={logo} alt="NCBCE logo" />
							</S.Picture>
						</Link>
					</div>
					<div className="flex-1" />
				</S.Header>
				<S.Container>
					<Typography tag="h1" weight="extrablack" center>
						Welcome To The Navigator
					</Typography>
					<Typography tag="p" className="description" left>
						The Navigator is a tool provided at no cost to employers and educators, designed to connect classroom learning to career pathways. Through this platform employers post work-based learning engagement and information opportunities like career profiles, guest speakers,
						internships, and pre-apprenticeships. Educators can search for, request, and track their engagement through the platform. The Navigator was developed through a generous partnership with Fidelity Investments, and supported through funding from NCBCE and the state of North
						Carolina.
					</Typography>
					<ReactPlayerYouTube url="https://youtu.be/RDBPzaSLTGg" controls className="videoPlayer" />
					<Typography tag="p" className="description" left>
						We believe that when employers connect with educators to connect with their future workforce, new paths are paved and that changes everything. So get started today and help make North Carolina more #jobready. This is real-world learning for real-life success.
					</Typography>
					<Typography tag="p" className="description" left>
						Setting up an account and posting information or opportunities takes less than five minutes—all you need is your company logo, short bio, address, and contact information.
					</Typography>
					<ReactPlayerVimeo url="https://vimeo.com/376825885" controls className="videoPlayer" />
				</S.Container>
			</S.Wrapper>
		</PublicNavigation>
	);
};

export default About;
