import React, { forwardRef, useCallback, useContext, useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sendEvent } from '../../../services/metrics';

import { ACCEPTABLE_FILE_TYPES, ROLES } from '../../../../constants/general.constants';
import { Card, Typography, Button, TextArea, TextInput, IconButton, EmptyComponent } from '../../../components';
import * as S from './FilesCard.styles';
import ReactModal from 'react-modal';
import { formatPayloadBeforeUpdate, InformationDetailsContext } from '../InformationDetails';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { errorHandler } from '../../../services/authService';
import { useDropzone } from 'react-dropzone';
import { bytesToSize } from '../../../utils/filesize-formatter';
import { required } from '../../../utils/form-default-errors';
import { uploadResources } from '../../../services/media';
import { createUpdateResource } from '../../../services/resources';

const FilesEditModal = forwardRef((_, ref) => {
	const {
		state: { resource },
		dispatch,
	} = useContext(InformationDetailsContext);
	const [isOpen, setIsOpen] = useState(false);

	const {
		register,
		setValue,
		getValues,
		watch,
		handleSubmit,
		reset,
		formState: { errors, isSubmitting },
	} = useForm();

	const publicFilesDropzone = useDropzone({
		onDrop: useCallback((acceptedFiles) => acceptedFiles.length > 0 && setValue('publicFiles', formatFileArrayValue('publicFiles', acceptedFiles)), []),
		accept: ACCEPTABLE_FILE_TYPES.RESOURCE_MEDIA.MIME_TYPES,
	});

	const publicFiles = watch('publicFiles');

	async function uploadFile(file) {
		const formData = new FormData();
		formData.append('file', file);

		const {
			data: { document },
		} = await uploadResources(formData);

		return { title: file?.title, description: file?.description, url: document };
	}

	const removeIndexFromArray = (arrayName, index) =>
		setValue(
			arrayName,
			getValues(arrayName).filter((_, i) => i !== index)
		);

	function formatFileArrayValue(arrayName, files) {
		if (getValues(arrayName)) {
			const existingFiles = getValues(arrayName);
			return existingFiles.concat(files.filter((file) => !existingFiles.find((existingFile) => existingFile.name === file.name)));
		}
		return Array.from(files);
	}

	const handleOpen = () => {
		setValue('publicFiles', resource.publicFiles);
		setIsOpen(true);
	};

	const handleClose = () => {
		setIsOpen(false);
		setTimeout(() => {
			reset();
		}, 200);
	};

	async function asyncCaller(data) {
		if (publicFiles?.length > 0) {
			const oldFiles = data.publicFiles.filter((file) => !file.size);
			const newFiles = data.publicFiles.filter((file) => file.size);
			const publicFilesData = await Promise.all(newFiles.map(uploadFile));
			data.publicFiles = [...oldFiles, ...publicFilesData];
		}

		const newResource = formatPayloadBeforeUpdate(resource, { ...data });

		const {
			data: { result },
		} = await createUpdateResource(newResource);
		dispatch({ type: 'SET_RESOURCE', payload: result.resource });

		handleClose();
	}

	const onSubmit = (e) => handleSubmit(asyncCaller)(e).catch(errorHandler);

	useImperativeHandle(ref, () => ({
		open: handleOpen,
		close: handleClose,
	}));

	return (
		<ReactModal ref={ref} closeTimeoutMS={200} isOpen={isOpen} onRequestClose={handleClose}>
			<S.Form onSubmit={onSubmit}>
				<IconButton className="close-button" type="button" icon={['fal', 'times']} onClick={handleClose} />
				<S.InfoWrapper>
					<Typography tag="h2" weight="extrablack" center>
						Edit Files
					</Typography>
					<Typography tag="p" center>
						Please edit the files below to update the resource.
					</Typography>
				</S.InfoWrapper>
				<S.FieldsWrapper>
					<S.FilesListWrapper>
						<header>
							<Typography tag="h3" weight="bold">
								Public Files
							</Typography>
							<Typography tag="p" variation="2">
								Upload files associated with the request that anyone can view and download.
							</Typography>
						</header>

						<S.FileListDropzone {...publicFilesDropzone.getRootProps()} isDragActive={publicFilesDropzone.isDragActive} isDragAccept={publicFilesDropzone.isDragAccept} isDragReject={publicFilesDropzone.isDragReject}>
							<input {...publicFilesDropzone.getInputProps()} />

							<div className="upload-placeholder">
								<div className="upload-placeholder__picture">
									<FontAwesomeIcon icon={['fal', 'image']} size="2x" />
								</div>
								<Typography tag="p" variation="2" center className="upload-placeholder__paragraph">
									Drag and drop files here, or <strong>browse</strong>
									<br />
									Supports PDF, Doc, PPT, JPG, PNG
								</Typography>
							</div>
						</S.FileListDropzone>

						<div className="files-wrapper">
							{publicFiles?.map((file, index) => (
								<div key={file.name || file.fileName} className="files-wrapper__item">
									<header>
										<Typography tag="h4" weight="bold">
											File {index + 1}
										</Typography>
										<IconButton type="button" icon={['fal', 'trash']} size={1} onClick={() => removeIndexFromArray('publicFiles', index)} />
									</header>

									<div className="files-wrapper__item__info">
										<Typography tag="h6" weight="semibold">
											File
										</Typography>
										<div className="files-wrapper__item__details">
											<FontAwesomeIcon icon={['fal', 'file']} size="lg" />
											<div>
												<Typography tag="p">{file.name || file.fileName}</Typography>
												{file.size && (
													<Typography tag="p" variation="2">
														{bytesToSize(file.size)}
													</Typography>
												)}
											</div>
										</div>
									</div>

									<TextInput label="Title" error={errors.publicFiles?.[index]?.title} {...register('publicFiles.' + index + '.title', { required: required('Title') })} />

									<TextArea label="Description" error={errors.publicFiles?.[index]?.description} {...register('publicFiles.' + index + '.description', { required: required('Description') })} />
								</div>
							))}
						</div>
					</S.FilesListWrapper>
				</S.FieldsWrapper>
				<S.ButtonsWrapper>
					<Button type="submit">
						<Typography weight="bold" variation="button-medium">
							{isSubmitting ? 'Loading' : 'Save'}
						</Typography>
					</Button>
				</S.ButtonsWrapper>
			</S.Form>
		</ReactModal>
	);
});
FilesEditModal.displayName = 'FilesEditModal';

export const FilesCard = ({ className, role }) => {
	const {
		state: { resource },
	} = useContext(InformationDetailsContext);
	const user = useSelector((state) => state.user.value);
	const { publicFiles: files } = resource;
	const modalRef = useRef(null);

	const handleEditClick = () => {
		modalRef.current.open();
	};

	const sendEventOnDownload = async (contentId, contentUrl) => {
		await sendEvent({
			resourceId: resource.id,
			action: 'download',
			content: contentUrl,
			contentId,
		});
	};

	// Render card component
	return (
		<Card
			title="Files"
			className={className}
			transparentHeaderBorder
			actions={(role != null && resource.business != null && resource.business.id === user?.business?.id) || role === ROLES.ADMIN || role === ROLES.SUPER ? [{ id: 1, label: 'Edit', size: 'small', variant: 'outline', onClick: handleEditClick, icon: { source: ['fal', 'edit'] } }] : []}
		>
			<S.Wrapper>
				{files?.length > 0 ? (
					<>
						{files.map((file) => (
							<div key={file.id} className="file-wrapper">
								<div className="file-wrapper__heading">
									<Typography tag="h5" weight="bold">
										{file.title}
									</Typography>
									{(role === ROLES.TEACHER || role === ROLES.BUSINESS || role === ROLES.ADMIN || role === ROLES.SUPER) && (
										<a
											href={file.url}
											download
											onClick={() => {
												sendEventOnDownload(file.id, file.url);
											}}
										>
											<Button size="small" variant="text">
												<FontAwesomeIcon icon={['fal', 'arrow-to-bottom']} />
												<Typography variation="button-small" weight="bold">
													Download
												</Typography>
											</Button>
										</a>
									)}
								</div>
								<div className="file-wrapper__body">
									<div className="file-wrapper__element">
										<Typography className="file-wrapper__title" tag="h6" weight="semibold">
											File
										</Typography>
										<Typography className="file-wrapper__value" tag="p">
											{file.fileName}
										</Typography>
									</div>
									<div className="file-wrapper__element">
										<Typography className="file-wrapper__title" tag="h6" weight="semibold">
											Description
										</Typography>
										<Typography className="file-wrapper__value" tag="p">
											{file.description}
										</Typography>
									</div>
								</div>
							</div>
						))}
					</>
				) : (
					<EmptyComponent title="No current files to show" />
				)}
			</S.Wrapper>
			<FilesEditModal ref={modalRef} />
		</Card>
	);
};
FilesCard.propTypes = {
	className: PropTypes.string,
	role: PropTypes.string,
};
