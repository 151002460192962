import React from 'react';
import { useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';

import { DAYS_OF_WEEK } from '../../../../../constants/general.constants';
import * as S from '../../CreateResource.styles';
import { DatePicker, Radio, TextArea, TextInput, Typography } from '../../../../components';
import { required } from '../../../../utils/form-default-errors';

export const AvailabilityStep = () => {
	const {
		register,
		control,
		watch,
		formState: { errors },
	} = useFormContext();

	const isOngoing = watch('isOngoing');

	return (
		<>
			<Typography tag="h2" weight="extrablack" center>
				Availability
			</Typography>
			<Typography tag="p" center>
				Add in your availability for this request.
			</Typography>

			<div className="fields-wrapper">
				<Controller control={control} name="startDate" rules={{ required: required('Start Date') }} render={({ field: { value, onChange } }) => <DatePicker label="Start Date" selected={value} onChange={onChange} />} />
				<S.RadioOptionsWrapper>
					<Typography className={`field-label${!!errors.isOngoing ? ' with-error' : ''}`} tag="p" variation="2" weight="semibold">
						Is this ongoing?
					</Typography>

					<div className="radio-field">
						<Radio
							id="isOngoing1"
							value="yes"
							{...register('isOngoing', {
								required: required('Is this ongoing'),
							})}
						/>
						<label htmlFor="isOngoing1">Yes, it is ongoing</label>
					</div>
					<div className="radio-field">
						<Radio
							id="isOngoing2"
							value="no"
							{...register('isOngoing', {
								required: required('Is this ongoing'),
							})}
						/>
						<label htmlFor="isOngoing2">No, there is an end date</label>
					</div>

					{!!errors.isOngoing && (
						<div className="error-wrapper">
							<Typography tag="p" variation="2">
								{errors.isOngoing.message}
							</Typography>
						</div>
					)}
				</S.RadioOptionsWrapper>

				{isOngoing === 'no' && <Controller control={control} name="endDate" rules={{ required: required('End Date') }} render={({ field: { value, onChange } }) => <DatePicker label="End Date" selected={value} onChange={onChange} />} />}

				<S.DaysSelector>
					<Typography className={`field-label${!!errors.days ? ' with-error' : ''}`} tag="p" variation="2" weight="semibold">
						Days
					</Typography>

					<div className="days-wrapper">
						{DAYS_OF_WEEK.map((value) => (
							<div key={value} className="day-wrapper">
								<input type="checkbox" id={`id-${value}`} value={value} {...register('days', { required: required('Days') })} />
								<label htmlFor={`id-${value}`}>{value}</label>
							</div>
						))}
					</div>

					{!!errors.days && (
						<div className="error-wrapper">
							<Typography tag="p" variation="2">
								{errors.days.message}
							</Typography>
						</div>
					)}
				</S.DaysSelector>

				<TextArea label="Availability Notes" id="availability-notes" placeholder="Write a message..." {...register('availabilityNotes')} />
			</div>
		</>
	);
};
