import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContainer, LineChart, XAxis, YAxis, Line, CartesianGrid, Tooltip } from 'recharts';
import { format } from 'date-fns';

import colors from '../../../styles/colors';
import { Card } from '../../Card';

export const LineChartGraph = ({ className, data = [], title }) => {
	const [chartData, setChartData] = useState([]);

	useEffect(() => {
		setChartData(
			data.map((datum) => {
				return { date: new Date(datum.time.iso), value: datum.data.count };
			})
		);
	}, [data]);

	if (chartData.length === 0 || Object.keys(data).length === 0) {
		return null;
	}

	return (
		<Card className={className} title={title} transparentHeaderBorder>
			<ResponsiveContainer width="100%" height={400}>
				<LineChart data={chartData}>
					<CartesianGrid />
					<XAxis
						dataKey="date"
						fontSize={14}
						tickFormatter={(value, index) => {
							return format(value, 'MMM d');
						}}
					/>
					<YAxis fontSize={14} width={30} />
					<Tooltip />
					<Line dataKey="value" strokeWidth={2} stroke={colors.brandPrimary} />
				</LineChart>
			</ResponsiveContainer>
		</Card>
	);
};
LineChartGraph.propTypes = {
	className: PropTypes.string,
	data: PropTypes.array,
	title: PropTypes.string,
};
