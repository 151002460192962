import React from 'react';
import PropTypes from 'prop-types';

import { Card, RatingStars, Typography } from '../../../../components';
import * as S from './ReviewCard.styles';

export const ReviewCard = ({ request, role }) => {
	return (
		<Card title="Review" transparentHeaderBorder={true}>
			<S.Wrapper>
				<div className="review__step-half">
					<div className="review__step-item">
						<Typography tag="h4" weight="bold">
							Attendance
						</Typography>

						<S.BigFieldset>
							<Typography tag="h5" weight="bold">
								Actual Attendance
							</Typography>
							<S.BigBox>
								<S.BigBoxHeader>
									<Typography tag="h6" weight="semibold">
										Group
									</Typography>
									<Typography tag="h6" weight="semibold">
										# of Students
									</Typography>
								</S.BigBoxHeader>
								<S.BigBoxLine>
									<Typography tag="p">{request?.group?.name}</Typography>
									<p className="box-field">{request?.attendanceGroupCount}</p>
								</S.BigBoxLine>
								<S.BigBoxLine>
									<Typography tag="p">Additional Students</Typography>
									<p className="box-field">{request?.attendanceAdditionalCount}</p>
								</S.BigBoxLine>
								<S.BigBoxFooter>
									<Typography tag="p" weight="semibold">
										Total
									</Typography>
									<Typography tag="p" weight="semibold" className={request?.group?.totalStudents != request?.attendanceGroupCount + request?.attendanceAdditionalCount && 'warning-text'}>
										{request?.attendanceGroupCount + request?.attendanceAdditionalCount}
									</Typography>
								</S.BigBoxFooter>
							</S.BigBox>
						</S.BigFieldset>
					</div>
					<div className="review__step-item">
						<div className="review__info-item">
							<Typography tag="h6" weight="semibold">
								How many additional groups attended?
							</Typography>
							<Typography tag="p">{request?.attendanceAdditionalGroups}</Typography>
						</div>

						<div className="review__info-item">
							<Typography tag="h6" weight="semibold">
								Additional Teacher&apos;s Name
							</Typography>
							<Typography tag="p">{[...request?.attendanceAdditionalTeachers, ...request?.attendanceAdditionalTeachersContacts].map(({ firstName, lastName }) => `${firstName} ${lastName}`).join(', ')}</Typography>
						</div>
					</div>
				</div>

				<div className="review__step-half">
					<div className="review__step-item">
						<Typography tag="h5" weight="bold">
							Demographics
						</Typography>

						<div className="review__info-item">
							<Typography tag="h6" weight="semibold">
								Age Range
							</Typography>
							<Typography tag="p">{`${request?.attendanceMinAge} - ${request?.attendanceMaxAge}`}</Typography>
						</div>

						<S.BigFieldset>
							<Typography tag="h6" weight="bold">
								Gender
							</Typography>
							<S.BigBox>
								<S.BigBoxHeader>
									<Typography tag="h6" weight="semibold">
										Gender
									</Typography>
									<Typography tag="h6" weight="semibold">
										# of Students
									</Typography>
								</S.BigBoxHeader>
								<S.BigBoxLine>
									<Typography tag="p">Male</Typography>
									<p className="box-field">{request?.attendanceGenderMale}</p>
								</S.BigBoxLine>
								<S.BigBoxLine>
									<Typography tag="p">Female</Typography>
									<p className="box-field">{request?.attendanceGenderFemale}</p>
								</S.BigBoxLine>
								<S.BigBoxLine>
									<Typography tag="p">Other</Typography>
									<p className="box-field">{request?.attendanceGenderOther}</p>
								</S.BigBoxLine>
								<S.BigBoxFooter>
									<Typography tag="p" weight="semibold">
										Total
									</Typography>
									<Typography tag="p" weight="semibold" className={request?.group?.totalStudents != request?.attendanceGroupCount + request?.attendanceAdditionalCount && 'warning-text'}>
										{request?.attendanceGenderMale + request?.attendanceGenderFemale + request?.attendanceGenderOther}
									</Typography>
								</S.BigBoxFooter>
							</S.BigBox>
						</S.BigFieldset>
					</div>
				</div>

				{request?.reviews?.length > 0 && (
					<div className="review__step-item review__businesses">
						<Typography tag="h4" weight="bold">
							Businesses
						</Typography>
						{request?.reviews?.map(({ id, business }, index) => (
							<div className="business-review-item smaller" key={id}>
								<div className="business-review-item__image-wrapper">
									<img src={business?.logoObj?.[200]} alt={business?.name} />
								</div>
								<div className="business-review-item__fields-wrapper">
									<Typography tag="h4" weight="bold">
										{business?.name}
									</Typography>
									<RatingStars label="Rating" value={request?.reviews[index].rating} />
									<div className="review__info-item">
										<Typography tag="h6" weight="semibold">
											Message
										</Typography>
										<Typography tag="p">{request?.reviews[index].comments} </Typography>
									</div>
								</div>
							</div>
						))}
					</div>
				)}

				<div className="review__step-item review__learning">
					<Typography tag="h4" weight="bold">
						Learning Connections
					</Typography>

					<div className="review__step-item">
						<div className="review__step-half">
							<div className="review__info-item review__info-item--with-badge">
								<Typography tag="h6" weight="semibold">
									Employability Skills
								</Typography>
								<div className="badge-wrapper">
									{request?.employabilitySkills?.map((value) => (
										<div className="small-badge" key={value}>
											{value}
										</div>
									))}
								</div>
							</div>

							<div className="review__info-item review__info-item--with-badge">
								<Typography tag="h6" weight="semibold">
									Curriculum Standards
								</Typography>
								<div className="badge-wrapper">
									{request?.curriculumStandards?.map((value) => (
										<div className="small-badge" key={value}>
											{value}
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</S.Wrapper>
		</Card>
	);
};

ReviewCard.propTypes = {
	request: PropTypes.shape(),
	role: PropTypes.string,
};
