import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ReactPlayerYouTube from 'react-player/youtube';

import logo from '../../assets/ncbce-logo-alt.png';
import { Typography, Link, PublicNavigation } from '../../components';
import * as S from './FAQ.styles';
import { META_TITLE } from '../../../constants/general.constants';

const FAQ = () => {
	const history = useHistory();

	useEffect(() => {
		// Set document title
		document.title = `Frequently Asked Questions | ${META_TITLE}`;
	}, []);

	return (
		<PublicNavigation>
			<S.Wrapper>
				<S.Header>
					<div className="flex-1" />
					<div className="centered">
						<Link to="/directory">
							<S.Picture>
								<img src={logo} alt="NCBCE logo" />
							</S.Picture>
						</Link>
					</div>
					<div className="flex-1" />
				</S.Header>
				<S.Container>
					<Typography tag="h1" weight="extrablack" center>
						Frequently Asked Questions
					</Typography>

					<Typography tag="h3" weight="extrablack" left>
						What is the Navigator?
					</Typography>
					<Typography tag="p" className="description" left>
						The Navigator is an online marketplace that makes it easier for NC educators and workforce development professionals to connect students and job seekers with real-world learning opportunities. The Navigator was developed through a generous partnership with Fidelity
						Investments.
					</Typography>
					<ReactPlayerYouTube url="https://youtu.be/KLR4W0cxlF0" controls className="videoPlayer" />

					<Typography tag="h3" weight="extrablack" left>
						How Does It Work?
					</Typography>
					<Typography tag="p" className="description" left>
						The platform operates like an online shopping experience, only everything on the Navigator is free. The inventory is made up of “resources”, or employer-offered work-based learning experiences ranging from guest speakers, to job shadowing opportunities and apprenticeships.
						Employers post resources, then educators and workforce teams search for and request those resources.
					</Typography>
					<ReactPlayerYouTube url="https://youtu.be/IgXn_erpZ5A" controls className="videoPlayer" />

					<Typography tag="h3" weight="extrablack" left>
						How do Students Access the Navigator?
					</Typography>
					<Typography tag="p" className="description" left>
						We are working on creating direct access for students, but currently all requests need to be made through a public school educator.
					</Typography>

					<Typography tag="h3" weight="extrablack" left>
						Who Built the Navigator and How do We Get it?
					</Typography>
					<Typography tag="p" className="description" left>
						The Navigator platform was developed by the North Carolina Business Committee For Education (NCBCE) in partnership with Fidelity Investments and the Office of the Governor. Inquire at ncbceadmin@nc.gov to learn how your state can leverage the Navigator.
					</Typography>

					<Typography tag="h3" weight="extrablack" left>
						How do I Get a Login?
					</Typography>
					<Typography tag="p" className="description" left>
						Educators: Access through your NC edCloud account. Employers and workforce development organizations fill out the quick <Link to="/business/sign-up">sign-up form</Link>. Once the form is submitted, businesses/organizations will go through an approval process verifying you are
						a legitimate entity. After you are approved, you will receive an email with a password reset prompt. If someone from your organization has already signed up, please contact your company administrator to request access.
					</Typography>

					<Typography tag="h3" weight="extrablack" left>
						What Is Work-Based Learning?
					</Typography>
					<Typography tag="p" className="description" left>
						Work-based learning directly addresses North Carolina’s growing need for skilled workers by providing students and job seekers with opportunities to engage in career awareness, industry exploration and field experiences. These activities connect classroom curriculum with
						real-world learning, ensuring North Carolinians are better prepared for the jobs of today and tomorrow.
					</Typography>
					<ReactPlayerYouTube url="https://youtu.be/Ap5md0qQlIQ" controls className="videoPlayer" />

					<Typography tag="h3" weight="extrablack" left>
						What If I Cannot Fulfill an Educator's Request?
					</Typography>
					<Typography tag="p" className="description" left>
						It is ok to decline a request. Educators realize businesses cannot fulfill every request.
					</Typography>

					<Typography tag="h3" weight="extrablack" left>
						How Often Will My Resource Be Requested?
					</Typography>
					<Typography tag="p" className="description" left>
						That depends on the demand in your area. From our experience, a business can expect each resource posted to be requested 3 to 4 times a year. Educators are busy and only have certain times of the year to engage with businesses. Typically, those months are September through
						early December and January through April. Remember you can decline a request from an educator.
					</Typography>

					<Typography tag="h3" weight="extrablack" left>
						What If I Am Busy - Can I Delete a Resource?
					</Typography>
					<Typography tag="p" className="description" left>
						Yes, you can remove a resource at any time; however, instead of deleting a resource, we advise you to mark your resources as private. Only you will be able to see a private resource. This will keep the information available to you if you decide to make it public again. When
						you decided you have time to engage with schools, simply change your resource back to public and educators will be able to view and request.
					</Typography>

					<Typography tag="h3" weight="extrablack" left>
						When Will the Business Get Back to the Educator?
					</Typography>
					<Typography tag="p" className="description" left>
						We ask businesses to contact educators within 10 business days. If it has been longer, please contact us at ncbceadmin@nc.gov.
					</Typography>

					<Typography tag="h3" weight="extrablack" left>
						What If My Logo Does Not Fit Into the Space Correctly?
					</Typography>
					<Typography tag="p" className="description" left>
						A square logo fits best into the space but if your logo does not fit, we will make it work. We check weekly for logos that do not fit the space correctly and change them to fit into the space. The look of your logo will not be altered in the process.
					</Typography>

					<Typography tag="h3" weight="extrablack" left>
						What Are Other Ways For A Business To Support Schools Other Than Work-Based Learning Engagements?
					</Typography>
					<Typography tag="p" className="description" left>
						<ol className="orderedList">
							<li>Donations of office furniture for career centers</li>
							<li>Financial support for field trips/industry tours</li>
							<li>Donations for business apparel/shirts/jackets</li>
							<li>Monetary support for state and national competition winners and participants</li>
							<li>Donations of food/refreshments for various school events (ex: mock interviews, career fairs, fund raising events, prom, community service events)</li>
							<li>Portfolios for graduating seniors</li>
							<li>Academic planners for students</li>
							<li>Professional dress seminars</li>
							<li>Dress for Success closets</li>
							<li>Judging for local and state competitions</li>
						</ol>
					</Typography>

					<Typography tag="h3" weight="extrablack" left>
						Do We Share Business Information?
					</Typography>
					<Typography tag="p" className="description" left>
						We do not sell or share business information with any other organizations.
					</Typography>

					<Typography tag="h3" weight="extrablack" left>
						Where do I Go for Training?
					</Typography>
					<Typography tag="p" className="description" left>
						Contact NCBCE at ncbceadmin@nc.gov. Links to resources you may find helpful:
						<br />
						<br />
						<Link to="https://www.dpi.nc.gov/Districts-Schools/Classroom-Resources/Career-And-Technical-Education/Career-Planning-And-Placement#Work-Based-Learning">
							https://www.dpi.nc.gov/Districts-Schools/Classroom-Resources/Career-And-Technical-Education/Career-Planning-And-Placement#Work-Based-Learning
						</Link>
						<br />
						<br />
						<Link to="http://www.experiencemorenc.com">http://www.experiencemorenc.com</Link>
					</Typography>
				</S.Container>
			</S.Wrapper>
		</PublicNavigation>
	);
};

export default FAQ;
