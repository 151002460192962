import React, { useContext } from 'react';

import { AdminCompanyDetailContext } from '../AdminCompanyDetail';
import { MetricsCards } from './MetricsCards';
import { OverviewCard } from './OverviewCard';
import { LocationsCard } from './LocationsCard';
import * as S from './OverviewTab.styles';

export const OverviewTab = () => {
	const {
		state: { company },
	} = useContext(AdminCompanyDetailContext);

	function renderContent() {
		return (
			<S.Wrapper>
				{(company.isDeclined === true || company.isApproved === true) && <MetricsCards />}
				<OverviewCard />
				<LocationsCard />
			</S.Wrapper>
		);
	}
	return renderContent();
};
