import styled from 'styled-components';
import { Link } from 'react-router-dom';

import colors from '../../../../styles/colors';
import { IconButton } from '../../../IconButton';

export const BellButton = styled(IconButton).attrs({
	className: 'menu-button',
	icon: ['fal', 'bell'],
})``;

export const SpaceBetween = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 0.25rem;
	margin-bottom: 0.25rem;
	border-bottom: 1px solid ${colors.neutralForm};
`;

export const NotificationWrapper = styled.div`
	@media screen and (min-width: 768px) {
		position: relative;
	}
`;

export const NotificationItems = styled.div`
	position: absolute;
	right: 0;
	top: calc(100% + 0.5rem);
	background-color: white;
	outline: none;
	width: 100%;
	border-radius: 0.375rem;
	box-shadow: ${colors.darkShadow};
	display: grid;
	grid-template-columns: 1fr;
	gap: 0.25rem;
	z-index: 1;
	padding: 0.5rem 1rem;
	max-height: 500px;
	overflow: auto;

	button {
		padding: 0;
	}

	.empty-component {
		padding: 1rem;
	}

	@media screen and (min-width: 768px) {
		width: 20.25rem;
	}
`;

export const List = styled.div``;

export const ListItem = styled(Link)`
	text-decoration: none;
	color: black;
	display: flex;
	margin-top: 0.625rem;
	padding-bottom: 0.625rem;
	position: relative;
	border-bottom: 1px solid ${colors.neutralForm};

	.list-item {
		&__thumbnail {
			display: flex;
			justify-content: center;
			align-items: center;
			min-width: 2.625rem;
			width: 2.625rem;
			height: 2.625rem;
			border-radius: 9999px;
			border: 1px solid ${colors.neutralForm};
			background-color: ${colors.neutralBackground};
			color: ${colors.brandPrimary};
			margin-right: 0.625rem;
		}
		&__info-wrapper {
			margin-top: 0.125rem;

			&__small-text {
				color: ${colors.neutralText};
				margin-top: 0.375rem;
			}
		}
		&__is-read-badge {
			position: absolute;
			top: calc(50% - 0.625rem);
			right: 0;
			background-color: ${colors.brandPrimary};
			width: 0.5rem;
			height: 0.5rem;
			border-radius: 9999px;
		}
	}
`;
