import React, { useState, useImperativeHandle, forwardRef, useContext } from 'react';
import ReactModal from 'react-modal';
import { FormProvider, useForm } from 'react-hook-form';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

import { IconButton, Stepper, Typography, Button, MessageDialog } from '../../../components';
import { AppointmentDetailsContext } from '../RequestsAppointmentDetails';
import { errorHandler } from '../../../services/authService';
import { scheduleRequest } from '../../../services/requests';
import * as S from './ScheduleRequestModal.styles';
import { StepOne } from './StepOne';
import { StepTwo } from './StepTwo';
import { StepThree } from './StepThree';
import { StepFour } from './StepFour';

export const ScheduleRequestModal = forwardRef(({ role }, ref) => {
	const { dispatch } = useContext(AppointmentDetailsContext);
	const [isOpen, setIsOpen] = useState(false);
	const [requestResult, setRequestResult] = useState(null);

	const [confirmationOpen, setConfirmationOpen] = useState(false);

	const stepOptions = [
		{ id: 1, label: 'Date' },
		{ id: 2, label: 'Location' },
		{ id: 3, label: 'Note' },
		{ id: 4, label: 'Review' },
	];

	const [currentStep, setCurrentStep] = useState(0);

	const methods = useForm();

	const handleOpen = (requestId) => {
		methods.setValue('requestId', requestId);
		methods.setValue('availabilityDates', null);
		methods.setValue('startTime', null);
		methods.setValue('endTime', null);
		methods.setValue('locationTypes', []);
		methods.setValue('virtualLocationNotes', null);
		methods.setValue('schoolLocationId', null);
		methods.setValue('studentLocationNotes', null);
		methods.setValue('businessLocationId', null);
		methods.setValue('notes', null);
		setIsOpen(true);
	};

	const handleClose = () => {
		setIsOpen(false);
		setTimeout(() => {
			methods.reset();
			setCurrentStep(0);
		}, 200);
	};

	async function asyncCaller(data) {
		const { availabilityDates, startTime, endTime, ...rest } = data;
		const newAvailabilityDates = data.availabilityDates.length === 1 ? [format(data.availabilityDates[0], 'MM-dd-yyyy')] : [format(data.availabilityDates[0], 'MM-dd-yyyy'), format(data.availabilityDates[1], 'MM-dd-yyyy')];
		const newStartTime = format(data.startTime, 'h:mm a');
		const newEndTime = format(data.endTime, 'h:mm a');

		const {
			data: { result },
		} = await scheduleRequest({ ...rest, availabilityDates: newAvailabilityDates, startTime: newStartTime, endTime: newEndTime });
		setRequestResult(result.request);

		setConfirmationOpen(true);
	}

	const handleCloseConfirmation = () => {
		setConfirmationOpen(false);
		handleClose();
		dispatch({ type: 'setRequest', payload: requestResult });
	};

	const onSubmit = (e) => {
		if (stepOptions.length === currentStep + 1) {
			methods.handleSubmit(asyncCaller)(e).catch(errorHandler);
		} else {
			methods
				.handleSubmit(() => {
					setCurrentStep((prev) => prev + 1);
				})(e)
				.catch(errorHandler);
		}
	};

	useImperativeHandle(ref, () => ({
		open: handleOpen,
		close: handleClose,
	}));

	return (
		<ReactModal className="schedule-request-modal" ref={ref} closeTimeoutMS={200} isOpen={isOpen} onRequestClose={handleClose}>
			<FormProvider {...methods}>
				<S.Form onSubmit={onSubmit}>
					<IconButton className="close-button" type="button" icon={['fal', 'times']} onClick={handleClose} />
					<Stepper activeStep={currentStep} steps={stepOptions} maxWidth={25} />
					<div className="modal-content-wrapper">
						{currentStep === 0 && <StepOne role={role} />}
						{currentStep === 1 && <StepTwo role={role} />}
						{currentStep === 2 && <StepThree role={role} />}
						{currentStep === 3 && <StepFour role={role} />}
					</div>

					<S.ButtonsWrapper>
						{currentStep > 0 && (
							<>
								<Button variant="outline" variation="secondary" type="button" onClick={() => setCurrentStep((prev) => prev - 1)}>
									<Typography variation="button-medium" weight="bold">
										Back
									</Typography>
								</Button>
								<div className="flex-1" />
							</>
						)}
						<Button type="submit">
							<Typography weight="bold" variation="button-medium">
								{methods?.formState?.isSubmitting ? 'Loading' : stepOptions.length === currentStep + 1 ? 'Schedule' : 'Next'}
							</Typography>
						</Button>
					</S.ButtonsWrapper>
				</S.Form>
			</FormProvider>
			<MessageDialog
				isOpen={confirmationOpen}
				onRequestClose={handleCloseConfirmation}
				title="Request Scheduled"
				subtitle="Your Request has been posted. Keep checking back to view your responses!"
				icon={['fal', 'check']}
				actions={[{ id: 'close-confirmation', label: 'Close', variant: 'solid', variation: 'default', onClick: handleCloseConfirmation }]}
			/>
		</ReactModal>
	);
});

ScheduleRequestModal.propTypes = {
	role: PropTypes.string,
};

ScheduleRequestModal.displayName = 'ScheduleRequestModal';
