import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Listbox } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as S from './HugeDropdown.styles';

export const HugeDropdown = ({ value, onChange, steps = [] }) => {
	return (
		<S.Wrapper>
			<Listbox value={value} onChange={onChange}>
				<Listbox.Button className={`c-listbox__button${value?.completed ? ' completed' : ''}`}>
					<div className="c-listbox__button__circle">
						<FontAwesomeIcon icon={['fal', 'check']} />
						<span>{value?.id}</span>
					</div>
					<span className="c-listbox__button__name">{value?.name}</span>
					<FontAwesomeIcon icon={['fal', 'angle-down']} size="2x" />
				</Listbox.Button>
				<Listbox.Options className="c-listbox__options">
					{steps.map((step, index) => (
						<Listbox.Option key={step.id} value={step} as={Fragment}>
							{({ active, selected }) => (
								<li className={`c-listbox__option${selected ? ' option-selected' : ''}${active ? ' option-active' : ''}${step.completed ? ' completed' : ''}`}>
									<div className="c-listbox__option__circle">
										<FontAwesomeIcon icon={['fal', 'check']} />
										<span>{index + 1}</span>
									</div>
									<span className="c-listbox__option__name">{step.name}</span>
								</li>
							)}
						</Listbox.Option>
					))}
				</Listbox.Options>
			</Listbox>
		</S.Wrapper>
	);
};

HugeDropdown.displayName = 'HugeDropdown';
HugeDropdown.propTypes = {
	value: PropTypes.shape({
		id: PropTypes.number.isRequired,
		name: PropTypes.string.isRequired,
		completed: PropTypes.bool.isRequired,
	}),
	onChange: PropTypes.func,
	steps: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired,
		})
	),
};
