import React, { createContext, useContext, useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { useList } from 'react-use';
import { Badge, Picture, Typography, EmptyComponent, Spinner } from '../../../components';
import { formatToRelativeTime } from '../../../utils/time-formatter';

import { fetchResources } from '../../../services/resources';
import { FilterSection } from './FilterSection';
import { ResourceChat } from './ResourceChat';
import * as S from './ResourcesTab.styles';

export const ResourcesContext = createContext();

export const initialState = {
	searchInputValue: '',
	selectedConversation: null,
};
export function reducer(state, action) {
	switch (action.type) {
		case 'SET_SEARCH_INPUT_VALUE':
			return {
				...state,
				searchInputValue: action.payload,
			};
		case 'SET_SELECTED_CONVERSATION':
			return {
				...state,
				selectedConversation: action.payload,
			};
		case 'CLEAR_SELECTED_CONVERSATION':
			return {
				...state,
				selectedConversation: null,
			};
		default:
			return state;
	}
}

const IndividualItem = ({ resource }) => {
	const {
		state: { selectedConversation },
		dispatch,
	} = useContext(ResourcesContext);

	const lastMessage = resource.conversations[0].lastMessage;

	return (
		<S.ListItem key={resource.id} onClick={() => dispatch({ type: 'SET_SELECTED_CONVERSATION', payload: { id: resource.id, business: resource.business, resource } })} className={selectedConversation != null && selectedConversation.id === resource.id ? 'is-selected' : ''}>
			<div className="individual-card__avatar-wrapper">
				<Picture className="individual-card__avatar" src={resource.business?.logoObj?.[200]} aspectRatio="1/1" />
			</div>
			<div className="individual-card__info-wrapper">
				<Typography className="individual-card__name" tag="h5" weight="bold">
					{resource.title}
				</Typography>
				<Typography className="individual-card__category" tag="p" variation="3">
					{lastMessage ? `${formatToRelativeTime(new Date(lastMessage.date.iso))} • ${resource?.business.name}` : resource?.business.name}
				</Typography>
				{lastMessage && (
					<Typography className="individual-card__message" tag="p" variation="2">
						{lastMessage.message}
					</Typography>
				)}
			</div>
		</S.ListItem>
	);
};
IndividualItem.propTypes = {
	resource: PropTypes.object,
};

// Initialize default data holder
let resourcesArray = [];

export const ResourcesTab = ({}) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const [pageStatus, setPageStatus] = useState('idle');
	const { selectedTab, searchInputValue, selectedConversation } = state;
	const [individualList, { set: setIndList, filter: filterInd }] = useList([]);

	const handleListFilter = (filterMethod, resetMethod) => {
		resetMethod();
		if (searchInputValue) {
			filterMethod((item) => item.searchTag.toLowerCase().includes(searchInputValue.toLowerCase()));
		}
	};

	const handleFetchData = async () => {
		setPageStatus('loading');

		// Fetch data for value
		const {
			data: { result },
		} = await fetchResources({
			filterOptions: { creator: true, hasMessages: true, general: true },
		});
		resourcesArray = result.resources;
		setIndList(result.resources);
		setPageStatus('success');
	};

	const filterData = () => {
		// Update filter state
		handleListFilter(filterInd, () => setIndList(resourcesArray));
	};

	useEffect(async () => {
		// Fetch data
		await handleFetchData();

		// Update filter state
		filterData();
	}, []);

	useEffect(async () => {
		// Update filter state
		filterData();
	}, [selectedTab, searchInputValue]);

	useEffect(async () => {
		if (selectedConversation != null) {
			const editedItemIndex = resourcesArray.findIndex((item) => item.id === selectedConversation.id);
			resourcesArray[editedItemIndex].status = selectedConversation.status;
			setIndList(resourcesArray);
		}
	}, [selectedConversation]);

	return (
		<ResourcesContext.Provider value={{ state, dispatch }}>
			<S.Wrapper someChatIsSelected={selectedConversation}>
				<S.ContentWrapper>
					<FilterSection />
					<S.RelativeWrapper>
						<S.ListWrapper>
							{individualList.length > 0 ? (
								individualList.map((resource) => <IndividualItem key={resource.id} resource={resource} />)
							) : pageStatus === 'loading' ? (
								<Spinner />
							) : (
								<EmptyComponent title="No available conversations" message="Check back later to view available conversations." icon={['fal', 'comment']} />
							)}
						</S.ListWrapper>
					</S.RelativeWrapper>
				</S.ContentWrapper>
				{selectedConversation && (
					<S.ChatWrapper>
						<div className="relative-wrapper">
							<ResourceChat conversation={selectedConversation} />
						</div>
					</S.ChatWrapper>
				)}
			</S.Wrapper>
		</ResourcesContext.Provider>
	);
};
