import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Typography } from '../../Typography';
import { IconButton } from '../../IconButton';
import { Button } from '../../Button';
import { openMenu } from '../../../app/slices/menu/menuSlice';
import logo from '../../../assets/ncbce-logo-alt.png';
import * as S from './Topbar.styles';

export const Topbar = () => {
	const user = useSelector((state) => state.user.value);
	const dispatch = useDispatch();

	return (
		<S.Topbar>
			<S.TopbarWrapper>
				<S.NavigationLink to="/directory">
					<S.Picture>
						<img src={logo} alt="logo" />
					</S.Picture>
				</S.NavigationLink>
			</S.TopbarWrapper>
			<S.TopbarWrapper fullWidth>
				<S.MobileNavigation>
					<IconButton className="menu-button" icon={['fal', 'bars']} onClick={() => dispatch(openMenu())} />
				</S.MobileNavigation>
				<S.DesktopNavigation>
					<S.NavigationLink to="/directory">
						<Button className="text-link" variant="text">
							<Typography weight="semibold" variation="button-small">
								Resources
							</Typography>
						</Button>
					</S.NavigationLink>
					<S.NavigationLink to="/requests">
						<Button className="text-link" variant="text">
							<Typography weight="semibold" variation="button-small">
								Requests
							</Typography>
						</Button>
					</S.NavigationLink>
					{/*<S.NavigationLink to="/reports">
						<Button className="text-link" variant="text">
							<Typography weight="semibold" variation="button-small">
								Reports
							</Typography>
						</Button>
					</S.NavigationLink>*/}
					<S.NavigationLink to="/about">
						<Button className="text-link" variant="text">
							<Typography weight="semibold" variation="button-small">
								About
							</Typography>
						</Button>
					</S.NavigationLink>
					<S.NavigationLink to="/faq">
						<Button className="text-link" variant="text">
							<Typography weight="semibold" variation="button-small">
								FAQ
							</Typography>
						</Button>
					</S.NavigationLink>
					<S.NavigationLink to="/login">
						<Button variant="outline">
							<Typography weight="bold" variation="button-small">
								Log In
							</Typography>
						</Button>
					</S.NavigationLink>
					<S.NavigationLink to="/sign-up">
						<Button>
							<Typography weight="bold" variation="button-small">
								Sign Up
							</Typography>
						</Button>
					</S.NavigationLink>
				</S.DesktopNavigation>
			</S.TopbarWrapper>
		</S.Topbar>
	);
};
