import React, { useState, useEffect, useRef } from 'react';
import { useDebouncedFn } from 'beautiful-react-hooks';

import { META_TITLE, REQUEST_SORT_OPTIONS } from '../../../../constants/general.constants';
import { fetchSavedRequests, saveUnsaveRequest } from '../../../services/requests';
import { FilterSection, Spinner, ErrorComponent, Button, Typography } from '../../../components';
import { SavedCardsGrid } from '../SavedCardsGrid';
import * as S from './RequestsTab.styles';

export const RequestsTab = () => {
	const [pageStatus, setPageStatus] = useState('idle');
	const [savedRequests, setSavedRequests] = useState([]);

	const initialPageNumber = 0;
	const [pageNumber, setPageNumber] = useState(initialPageNumber);
	const [totalPages, setTotalPages] = useState(undefined);
	const [totalItems, setTotalItems] = useState(0);
	const [loadingMore, setLoadingMore] = useState(false);

	const filterRef = useRef();

	const handleSaveUnsave = async (id) => {
		try {
			await saveUnsaveRequest({ requestId: id });
			let newRequests = [...savedRequests];
			newRequests = newRequests.filter((item) => item.id !== id);
			setSavedRequests(newRequests);
		} catch (error) {}
	};

	const onChangeCallback = useDebouncedFn(async () => {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchSavedRequests({ page: 0, ...filterRef.current.value });
			setSavedRequests(result.requests);
			setPageNumber(initialPageNumber);
			setTotalPages(result.totalPages);
			setTotalItems(result.totalItems);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}, 300);

	async function loadMore() {
		setLoadingMore(true);
		try {
			if (totalPages > pageNumber + 1) {
				const {
					data: { result },
				} = await fetchSavedRequests({ page: pageNumber + 1, ...filterRef.current.value });
				setSavedRequests((prev) => [...prev, ...result.requests]);
				setTotalPages(result.totalPages);
				setTotalItems(result.totalItems);
				setPageNumber((prev) => prev + 1);
			} else {
				toast.error('No more requests to load');
			}
			setLoadingMore(false);
		} catch (error) {
			setLoadingMore(false);
		}
	}

	async function initializeData() {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchSavedRequests({ page: 0 });
			setSavedRequests(result.requests);
			setTotalPages(result.totalPages);
			setTotalItems(result.totalItems);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}

	const renderContent = () => {
		switch (pageStatus) {
			case 'loading':
				return <Spinner />;
			case 'error':
				return <ErrorComponent />;
			case 'success':
				return (
					<>
						<SavedCardsGrid data={savedRequests} handleSaveUnsave={handleSaveUnsave} totalItems={totalItems} type="request" />
						{savedRequests?.length > 0 && totalPages > pageNumber + 1 && (
							<S.LoadMoreWrapper>
								<Button variant="outline" variation="secondary" type="button" onClick={loadMore}>
									<Typography variation="button-medium" weight="bold">
										{loadingMore ? 'Loading...' : 'Load More'}
									</Typography>
								</Button>
							</S.LoadMoreWrapper>
						)}
					</>
				);
			default:
				return null;
		}
	};

	useEffect(() => {
		document.title = `Saved Requests | Dashboard | ${META_TITLE}`;

		if (pageStatus === 'idle') {
			initializeData();
		}
	}, []);

	return (
		<S.Wrapper>
			<FilterSection
				ref={filterRef}
				onChangeCallback={onChangeCallback}
				initialState={{
					searchText: '',
					sortOption: REQUEST_SORT_OPTIONS[3],
					showAdvancedFilters: false,
					activeFilters: [],
					distance: 0,
					counties: [],
					locationTypes: [],
					categories: [],
					careerClusters: [],
					ageGroups: [],
					employabilitySkills: [],
					curriculumStandards: [],
				}}
				sortOptions={REQUEST_SORT_OPTIONS}
			/>
			{renderContent()}
		</S.Wrapper>
	);
};
