import React, { forwardRef, useContext, useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';

import { beginPasswordReset, errorHandler } from '../../../../services/authService';
import { checkEmailExists, updateUserProfile } from '../../../../services/users';
import { Button, Card, IconButton, TextInput, Typography } from '../../../../components';
import { AdminAdminDetailContext } from '../../AdminAdminDetail';
import { EMAIL } from '../../../../utils/common-regex';
import { pattern, required } from '../../../../utils/form-default-errors';
import * as S from './AccountCard.styles';

const EditEmailModal = forwardRef((_props, ref) => {
	const {
		state: { user },
		dispatch,
	} = useContext(AdminAdminDetailContext);
	const [isOpen, setIsOpen] = useState(false);
	const {
		handleSubmit,
		setValue,
		register,
		reset,
		formState: { errors, isSubmitting },
	} = useForm();

	const handleOpenModal = () => {
		setValue('email', user?.email);
		setIsOpen(true);
	};

	const handleCloseModal = () => {
		setIsOpen(false);
		setTimeout(() => {
			reset();
		}, 200);
	};

	async function asyncCaller(data) {
		const {
			data: {
				result: { exists },
			},
		} = await checkEmailExists(data);

		if (exists) {
			toast.error('Email already exists.');
			return;
		}
		const {
			data: { result },
		} = await updateUserProfile({ userId: user.id, firstName: user.firstName, lastName: user.lastName, email: user.email, profileImageObj: user.profileImageObj, ...data });

		dispatch({ type: 'SET_USER', payload: result.user });
		handleCloseModal();
	}

	const onSubmit = (e) => {
		handleSubmit(asyncCaller)(e).catch(errorHandler);
	};

	useImperativeHandle(
		ref,
		() => ({
			open: handleOpenModal,
			close: handleCloseModal,
		}),
		[handleOpenModal, handleCloseModal]
	);

	return (
		<ReactModal className="account-card-modal" ref={ref} closeTimeoutMS={200} isOpen={isOpen} onRequestClose={handleCloseModal}>
			<S.Form onSubmit={onSubmit}>
				<IconButton className="close-button" type="button" icon={['fal', 'times']} onClick={handleCloseModal} />
				<div className="modal-content">
					<header className="modal-content__header">
						<Typography tag="h2" weight="extrablack" center>
							Edit Email
						</Typography>
						<Typography tag="p" center>
							Please update your email here.
						</Typography>
					</header>

					<section className="modal-content__section">
						<TextInput
							label="Email"
							placeholder="Email"
							error={errors.email}
							{...register('email', {
								required: required('Email'),
								pattern: pattern('Email', EMAIL),
							})}
						/>
					</section>

					<div className="modal-footer">
						<Button>
							<Typography variation="button-medium" weight="bold">
								{isSubmitting ? 'Loading' : 'Save'}
							</Typography>
						</Button>
					</div>
				</div>
			</S.Form>
		</ReactModal>
	);
});

EditEmailModal.displayName = 'EditEmailModal';

const AccountCard = ({ className }) => {
	const {
		state: { user },
	} = useContext(AdminAdminDetailContext);

	const modalRef = useRef(null);

	const handleEditEmailClick = () => {
		modalRef.current.open();
	};

	const handleResetPasswordClick = async () => {
		try {
			await beginPasswordReset({ email: user.email });
			toast.success("We've sent a secure email to the user's inbox with instructions on how to reset their password.");
		} catch (error) {
			errorHandler(error);
		}
	};

	return (
		<Card className={className} title="Account" transparentHeaderBorder={true}>
			<S.Wrapper>
				<div className="row-line">
					<div className="element-info">
						<Typography className="element-info__title" tag="h6" weight="semibold">
							Email
						</Typography>
						<Typography className="element-info__text" tag="p">
							{user?.email}
						</Typography>
					</div>
					{user.canEditUser && (
						<Button variant="text" type="button" onClick={handleEditEmailClick}>
							<Typography variation="button-medium" weight="bold">
								Update Email
							</Typography>
						</Button>
					)}
				</div>
				<div className="row-line">
					<div className="element-info">
						<Typography className="element-info__title" tag="h6" weight="semibold">
							Password
						</Typography>
						<Typography className="element-info__text" tag="p">
							**********
						</Typography>
					</div>
					{user.canEditUser && (
						<Button variant="text" type="button" onClick={handleResetPasswordClick}>
							<Typography variation="button-medium" weight="bold">
								Reset Password
							</Typography>
						</Button>
					)}
				</div>
			</S.Wrapper>
			<EditEmailModal ref={modalRef} />
		</Card>
	);
};

AccountCard.propTypes = {
	className: PropTypes.string,
};

export default AccountCard;
