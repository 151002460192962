import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import socketIOClient from 'socket.io-client';
import { SESSION_TOKEN_COOKIE } from '../../constants/general.constants';

import { getCookie } from '../utils/cookie-methods';

const NEW_MESSAGE_EVENT = 'message';

const useChat = (channelId) => {
	// Get state
	const user = useSelector((state) => state.user.value);
	const [messages, setMessages] = useState([]);
	const socketRef = useRef();

	useEffect(async () => {
		if (socketRef.current) socketRef.current.disconnect();

		// Creates a WebSocket connection
		socketRef.current = socketIOClient(process.env.REACT_APP_SERVER_URL, {
			transports: ['websocket'],
			query: {
				channelId,
				sessionToken: getCookie(SESSION_TOKEN_COOKIE),
			},
		});

		// Listen for incoming messages
		socketRef.current.on(NEW_MESSAGE_EVENT, (message) => {
			const senderType = message.payload.sender.senderType === 'user' ? 'user' : 'business';
			const idToCompare = senderType === 'user' ? user.id : user.business.id;
			const incomingMessage = {
				...message.payload,
				type: message.payload.sender[senderType].id === idToCompare ? 'sent' : 'received',
			};
			setMessages((messages) => [...messages, incomingMessage]);
		});

		// Destroys the socket reference when the connection is closed
		return () => {
			socketRef.current.disconnect();
		};
	}, [channelId]);

	return { messages, setMessages };
};

export { useChat };
