import React, { useState, useEffect, Fragment } from 'react';
import { useMediaQuery } from 'beautiful-react-hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AGE_GROUPS, META_TITLE } from '../../../constants/general.constants';
import { AdminNavigation, Button, HugeDropdown, MessageDialog, PageHeader, Typography } from '../../components';
import * as S from './AdminCreateTemplate.styles';
import { FormProvider, useForm } from 'react-hook-form';
import { errorHandler } from '../../services/authService';
import { uploadResources } from '../../services/media';
import { useHistory } from 'react-router-dom';
import { StepTemplateType } from './StepTemplateType';
import { StepResourceType } from './StepResourceType';
import { StepResourceCategory } from './StepResourceCategory';
import { StepTemplateOverview } from './StepTemplateOverview';
import { StepReview } from './StepReview';
import { createUpdateTemplate, updatePublishStatusTemplate } from '../../services/templates';

const DEFAULT_STEPS = [
	{ id: 1, name: 'Template Type', completed: false },
	{ id: 2, name: 'Resource Type', completed: false },
	{ id: 3, name: 'Resource Category', completed: false },
	{ id: 4, name: 'Template Overview', completed: false },
	{ id: 5, name: 'Review', completed: false },
];

const AdminCreateTemplate = () => {
	const history = useHistory();
	const isMobile = useMediaQuery('(max-width: 992px)');
	const [steps, setSteps] = useState(DEFAULT_STEPS);
	const [selectedStep, setSelectedStep] = useState(DEFAULT_STEPS[0]);

	const methods = useForm();

	const [confirmationIsOpen, setConfirmationIsOpen] = useState(false);
	const [confirmationDraftIsOpen, setConfirmationDraftIsOpen] = useState(false);

	function handleOpenConfirmationModal() {
		setConfirmationIsOpen(true);
	}

	function handleCloseConfirmationModal() {
		setConfirmationIsOpen(false);
		history.push('/admin/templates');
	}

	function handleOpenConfirmationDraftModal() {
		setConfirmationDraftIsOpen(true);
	}

	function handleCloseConfirmationDraftModal() {
		setConfirmationDraftIsOpen(false);
		history.push('/admin/templates');
	}

	const handleBackButton = () => {
		if (selectedStep.id > 1) {
			uncompleteStep(selectedStep.id - 2);
			setSelectedStep(steps[selectedStep.id - 2]);
		} else {
			history.goBack();
		}
	};

	const handleStepClick = (step) => {
		if (step?.completed || steps[step.id - 2]?.completed) {
			setSelectedStep(step);
		}
	};

	const completeStep = (stepIndex) => {
		setSteps((prev) => {
			const newSteps = [...prev];
			newSteps[stepIndex].completed = true;
			return newSteps;
		});
	};

	const uncompleteStep = (stepIndex) => {
		setSteps((prev) => {
			const newSteps = [...prev];
			newSteps[stepIndex].completed = false;
			return newSteps;
		});
	};

	async function uploadCoverImage(file) {
		const formData = new FormData();
		formData.append('file', file);

		const { data } = await uploadResources(formData);

		return data;
	}

	async function asyncCaller(data) {
		const { type } = data;
		let payload = {};
		if (type === 'file') {
			const { uploadFile, ...rest } = data;
			const uploadFileData = await uploadCoverImage(uploadFile);
			rest.fileUrl = uploadFileData.document;
			rest.fileTitle = uploadFile.title;
			rest.fileDescription = uploadFile.description;
			payload = {
				...rest,
			};
		} else {
			const { coverImage, ...rest } = data;
			if (coverImage instanceof File) {
				const coverImageData = await uploadCoverImage(coverImage);
				rest.coverImageObj = coverImageData;
			} else {
				rest.coverImageObj = coverImage;
			}
			payload = {
				...rest,
				ageGroups: [...new Set(rest.gradeLevels.map((level) => AGE_GROUPS.find((group) => group.values.includes(level)).value))],
			};
		}
		const {
			data: {
				result: { template },
			},
		} = await createUpdateTemplate(payload);
		await updatePublishStatusTemplate({ templateId: template.id });
		handleOpenConfirmationModal();
	}

	async function handleDraftClick() {
		const { type } = methods.getValues();
		let payload = {};
		if (type === 'file') {
			const { uploadFile, ...rest } = methods.getValues();
			const uploadFileData = await uploadCoverImage(uploadFile);
			rest.fileUrl = uploadFileData.document;
			rest.fileTitle = uploadFile.title;
			rest.fileDescription = uploadFile.description;
			payload = {
				...rest,
			};
		} else {
			const { coverImage, ...rest } = methods.getValues();
			if (coverImage instanceof File) {
				const coverImageData = await uploadCoverImage(coverImage);
				rest.coverImageObj = coverImageData;
			} else {
				rest.coverImageObj = coverImage;
			}
			payload = {
				...rest,
				ageGroups: [...new Set(rest.gradeLevels.map((level) => AGE_GROUPS.find((group) => group.values.includes(level)).value))],
			};
		}
		await createUpdateTemplate(payload);
		handleOpenConfirmationDraftModal();
	}

	const onSubmit = (e) => {
		switch (selectedStep.id) {
			case 1:
				const currentType = methods.getValues('type');
				methods.reset();
				methods.setValue('type', currentType);
				methods.handleSubmit(() => {
					completeStep(0);
					setSelectedStep(steps[selectedStep.id]);
				})(e);
				break;
			case 2:
				methods.handleSubmit(() => {
					completeStep(1);
					setSelectedStep(steps[selectedStep.id]);
				})(e);
				break;
			case 3:
				methods.handleSubmit(() => {
					completeStep(2);
					setSelectedStep(steps[selectedStep.id]);
				})(e);
				break;
			case 4:
				methods
					.handleSubmit(() => {
						completeStep(3);
						setSelectedStep(steps[selectedStep.id]);
					})(e)
					.catch(errorHandler);
				break;
			case 5:
				methods.handleSubmit(asyncCaller)(e).catch(errorHandler);
				break;
		}
	};

	const renderContent = () => {
		return (
			<>
				{!isMobile && <PageHeader title="Create Template" />}
				{isMobile && <HugeDropdown value={selectedStep} onChange={handleStepClick} steps={steps} />}
				<S.MainWrapper>
					{!isMobile && (
						<S.SidebarWrapper>
							{steps.map((step, index) => (
								<Fragment key={step.id}>
									<button onClick={() => handleStepClick(step)} className={`sidebar-option${step === selectedStep ? ' active' : ''}${step.completed ? ' completed' : ''}`}>
										<div className="sidebar-option__circle">
											<FontAwesomeIcon icon={['fal', 'check']} />
											<span>{index + 1}</span>
										</div>
										<span className="sidebar-option__name">{step.name}</span>
									</button>
								</Fragment>
							))}
						</S.SidebarWrapper>
					)}
					<FormProvider {...methods}>
						<S.FormWrapper onSubmit={onSubmit}>
							<S.FormContent>
								{selectedStep.id === 1 && <StepTemplateType />}
								{selectedStep.id === 2 && <StepResourceType />}
								{selectedStep.id === 3 && <StepResourceCategory />}
								{selectedStep.id === 4 && <StepTemplateOverview />}
								{selectedStep.id === 5 && <StepReview />}
							</S.FormContent>
							<S.FormFooter>
								<Button className="back-button" variant="outline" variation="secondary" type="button" onClick={handleBackButton}>
									<Typography variation="button-medium" weight="bold">
										Back
									</Typography>
								</Button>
								<div className="spacer" />
								<div className="submit-container">
									{selectedStep.id === 5 && (
										<Button variant="outline" type="button" onClick={handleDraftClick}>
											<Typography variation="button-medium" weight="bold">
												Save as Draft
											</Typography>
										</Button>
									)}
									<Button>
										<Typography variation="button-medium" weight="bold">
											{selectedStep.id === 4 ? 'Review' : selectedStep.id === 5 ? (methods.formState.isSubmitting ? 'Loading' : 'Publish Now') : 'Next'}
										</Typography>
									</Button>
								</div>
							</S.FormFooter>
						</S.FormWrapper>
					</FormProvider>
					<MessageDialog isOpen={confirmationDraftIsOpen} onRequestClose={handleCloseConfirmationDraftModal} title="Template Saved" subtitle="Your template has been saved as a draft. Come back to publish it at any time." icon={['fal', 'check']} />
					<MessageDialog isOpen={confirmationIsOpen} onRequestClose={handleCloseConfirmationModal} title="Template Published" subtitle="Your template has been published. Users can now use them when creating resource and requests." icon={['fal', 'check']} />
				</S.MainWrapper>
			</>
		);
	};

	useEffect(() => {
		// Set document title
		document.title = `Create Template | ${META_TITLE}`;
	}, []);

	return <AdminNavigation>{renderContent()}</AdminNavigation>;
};

export default AdminCreateTemplate;
