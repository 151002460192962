import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';

import { BOOKING_DECLINE_REASONS } from '../../../constants/general.constants';
import { errorHandler } from '../../services/authService';
import { required } from '../../utils/form-default-errors';
import { Button, IconButton, Select, TextArea, Typography } from '..';
import * as S from './DeclineBookingModal.styles';
import { declineBooking } from '../../services/bookings';

export const DeclineBookingModal = forwardRef(({ dispatch }, ref) => {
	const [isOpen, setIsOpen] = useState(false);

	const {
		handleSubmit,
		control,
		register,
		setValue,
		reset,
		formState: { errors, isSubmitting },
	} = useForm();

	const handleOpen = (bookingId) => {
		setValue('bookingId', bookingId);
		setIsOpen(true);
	};

	const handleClose = () => {
		setIsOpen(false);
		setTimeout(() => {
			reset();
		}, 200);
	};

	async function asyncCaller(data) {
		const {
			data: { result },
		} = await declineBooking(data);
		dispatch({ type: 'setBooking', payload: result.booking });
	}

	const onSubmit = (e) => {
		handleSubmit(asyncCaller)(e).catch(errorHandler);
	};

	useImperativeHandle(
		ref,
		() => ({
			open: handleOpen,
			close: handleClose,
		}),
		[handleOpen, handleClose]
	);

	return (
		<ReactModal className="smaller-modal" ref={ref} closeTimeoutMS={200} isOpen={isOpen} onRequestClose={handleClose}>
			<S.Form onSubmit={onSubmit}>
				<IconButton className="close-button" type="button" icon={['fal', 'times']} onClick={handleClose} />
				<S.InfoWrapper>
					<Typography tag="h2" weight="extrablack" center>
						Decline Booking
					</Typography>
				</S.InfoWrapper>
				<S.FieldsWrapper>
					<Controller
						name="declineReason"
						control={control}
						rules={{ required: required('Reason') }}
						render={({ field: { onChange, value } }) => (
							<Select label="Reason" placeholder="Type to search" id="cancel-reason" error={errors?.declineReason} options={BOOKING_DECLINE_REASONS} value={BOOKING_DECLINE_REASONS.find((c) => c.value === value) || ''} onChange={(val) => onChange(val.value)} />
						)}
					/>
					<TextArea label="Notes" placeholder="Add notes here" id="cancel-notes" error={errors?.declineNotes} {...register('declineNotes')} />
				</S.FieldsWrapper>
				<S.ButtonsWrapper>
					<Button type="button" variant="outline" onClick={handleClose}>
						<Typography weight="bold" variation="button-medium">
							Back
						</Typography>
					</Button>
					<Button type="submit" variation="warning">
						<Typography weight="bold" variation="button-medium">
							{isSubmitting ? 'Loading' : 'Decline'}
						</Typography>
					</Button>
				</S.ButtonsWrapper>
			</S.Form>
		</ReactModal>
	);
});

DeclineBookingModal.propTypes = {
	dispatch: PropTypes.func,
};

DeclineBookingModal.displayName = 'DeclineBookingModal';
