import styled, { css } from 'styled-components';

import colors from '../../styles/colors';

export const MapWrapper = styled.div`
	position: absolute;
	inset: 0;
	height: 100%;
	width: 100%;
`;

export const ZoomControlWrapper = styled.div`
	display: inline-block;
	background-color: white;
	border-radius: 0.375rem;
	border: none;
	padding: 0.375rem;
	box-shadow: ${colors.normalShadow};

	button {
		padding: 0.25rem;
		color: ${colors.neutralButton};
	}

	.divider {
		margin: 0.25rem 0;
		height: 1px;
		background-color: ${colors.neutralForm};
	}
`;

export const SelfDot = styled.div`
	width: 1.5rem;
	height: 1.5rem;
	transform: translate(-50%, -50%);
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 9999px;
	background-color: white;
	color: ${colors.brandPrimary};
	box-shadow: ${colors.normalShadow};
	z-index: 1;

	svg {
		width: 1rem !important;
		height: 1rem !important;
	}
`;

export const MapDot = styled.div`
	position: relative;
	width: 1rem;
	height: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	transform: translate(-50%, -50%);
	border-radius: 9999px;
	background-color: ${colors.brandPrimary};
	border: 0.125rem solid white;
	z-index: 1;
	transition: all 0.15s ease-in-out;
	cursor: pointer;

	${({ withMarkerIndex }) =>
		withMarkerIndex &&
		css`
			width: 1.375rem;
			height: 1.375rem;
		`}

	.marker-index {
		color: white;
	}

	.marker-card {
		visibility: hidden;
		opacity: 0;
		position: absolute;
		width: 12.75rem;
		border-radius: 0.375rem;
		overflow: hidden;
		top: calc(100% + 1rem);
		left: calc(50% - 6.25rem);
		background-color: white;
		border: 1px solid ${colors.neutralForm};
		box-shadow: ${colors.normalShadow};
		transition: all 0.15s ease-in-out;

		&__info-wrapper {
			display: flex;
			flex-direction: column;
			padding: 0.5rem 0.75rem 1rem;
		}

		&__type {
			color: ${colors.neutralText};
			margin-bottom: 0.25rem;
		}

		&__title {
			margin-bottom: 0.0625rem;
		}
	}

	&:hover {
		z-index: 2;
		background-color: white;
		border-color: ${colors.brandPrimary};

		.marker-index {
			color: ${colors.brandPrimary};
		}

		.marker-card {
			visibility: visible;
			opacity: 1;
	}
`;
