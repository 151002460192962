import React, { createContext, forwardRef, useEffect, useReducer, useRef, useState, useImperativeHandle, useContext } from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';

import { AdminNavigation, Button, DetailPageHeader, ErrorComponent, IconButton, MessageDialog, Select, Spinner, Tab, Typography } from '../../components';
import * as S from './AdminMembershipDetail.styles';
import { OverviewTab } from './OverviewTab';
import { CompaniesTab } from './CompaniesTab';
import { META_TITLE } from '../../../constants/general.constants';
import { useQuery } from '../../hooks/useQuery';
import { addCompaniesToMembership, archiveUnarchiveMembership, fetchMembership } from '../../services/memberships';
import { useHistory, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Controller, useForm } from 'react-hook-form';
import { fetchBusinesses } from '../../services/business';
import { errorHandler } from '../../services/authService';
import { required } from '../../utils/form-default-errors';

export const AdminMembershipDetailContext = createContext();

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_MEMBERSHIP':
			return {
				...state,
				membership: action.payload,
			};
		case 'REMOVE_COMPANY_FROM_MEMBERSHIP':
			return {
				...state,
				membership: {
					...state.membership,
					businesses: state.membership.businesses.filter((business) => {
						return business.id !== action.payload;
					}),
				},
			};
		default:
			return state;
	}
};

const AddCompanyToMembershipModal = forwardRef(({ onSuccessCallback }, ref) => {
	const { dispatch } = useContext(AdminMembershipDetailContext);
	const [loadingStatus, setLoadingStatus] = useState('idle');
	const [isOpen, setIsOpen] = useState(false);
	const [companies, setCompanies] = useState([]);

	const {
		handleSubmit,
		reset,
		setValue,
		control,
		formState: { errors, isSubmitting },
	} = useForm();

	async function initializeData() {
		setLoadingStatus('loading');
		try {
			const {
				data: {
					result: { businesses },
				},
			} = await fetchBusinesses({ page: 0, isSlim: true });
			setCompanies(businesses);
			setLoadingStatus('success');
		} catch (error) {
			setLoadingStatus('error');
			errorHandler(error);
		}
	}

	const handleOpenModal = (membershipId) => {
		setValue('membershipId', membershipId);
		setIsOpen(true);
	};

	const handleCloseModal = () => {
		setIsOpen(false);
		setTimeout(() => {
			reset();
		}, 200);
	};

	async function asyncCaller(data) {
		const {
			data: { result },
		} = await addCompaniesToMembership(data);
		dispatch({ type: 'SET_MEMBERSHIP', payload: result.membership });
		onSuccessCallback();
		handleCloseModal();
	}

	const onSubmit = (e) => {
		handleSubmit(asyncCaller)(e).catch(errorHandler);
	};

	const renderContent = () => {
		switch (loadingStatus) {
			case 'loading':
				return <Spinner />;
			case 'error':
				return <ErrorComponent />;
			case 'success':
				return (
					<S.Form onSubmit={onSubmit}>
						<IconButton className="close-button" type="button" icon={['fal', 'times']} onClick={handleCloseModal} />
						<div className="modal-content">
							<header className="modal-content__header">
								<Typography tag="h2" weight="extrablack" center>
									Add Company
								</Typography>
							</header>

							<section className="modal-content__section">
								<Controller
									name="businessIds"
									control={control}
									rules={{ required: required('Company') }}
									render={({ field: { onChange, value } }) => (
										<Select
											isMulti
											getOptionValue={(option) => option.id}
											getOptionLabel={(option) => option.name}
											label="Company"
											id="businessIds"
											error={errors?.businessIds}
											placeholder="Company"
											options={companies}
											promise={{
												function: fetchBusinesses,
												params: { isSlim: true },
												key: 'businesses',
												setOptions: setCompanies,
											}}
											value={value?.map((val) => companies.find((c) => c.id === val)) || ''}
											onChange={(val) => onChange(val?.map((val) => val.id))}
										/>
									)}
								/>
							</section>

							<div className="modal-footer">
								<Button variant="outline" variation="secondary" type="button" onClick={handleCloseModal}>
									<Typography variation="button-medium" weight="bold">
										Cancel
									</Typography>
								</Button>
								<div className="flex-1" />
								<Button>
									<Typography variation="button-medium" weight="bold">
										{isSubmitting ? 'Loading' : 'Add'}
									</Typography>
								</Button>
							</div>
						</div>
					</S.Form>
				);
			default:
				return null;
		}
	};

	useEffect(() => {
		if (loadingStatus === 'idle') {
			initializeData();
		}
	}, []);

	useImperativeHandle(
		ref,
		() => ({
			open: handleOpenModal,
			close: handleCloseModal,
		}),
		[handleOpenModal, handleCloseModal]
	);

	return (
		<ReactModal ref={ref} className="add-user-modal" closeTimeoutMS={200} isOpen={isOpen} onRequestClose={handleCloseModal}>
			{renderContent()}
		</ReactModal>
	);
});

AddCompanyToMembershipModal.displayName = 'AddCompanyToMembershipModal';

AddCompanyToMembershipModal.propTypes = {
	onSuccessCallback: PropTypes.func,
};

const AdminMembershipDetail = () => {
	const [pageStatus, setPageStatus] = useState('idle');
	const [archiveIsOpen, setArchiveIsOpen] = useState(false);
	const addCompanyModalRef = useRef();
	const companiesTabRef = useRef();
	const history = useHistory();
	const query = useQuery();
	const [state, dispatch] = useReducer(reducer, {});
	const { id: membershipId } = useParams();

	const [tabs, setTabs] = useState([
		{
			id: 1,
			label: 'Overview',
			component: <OverviewTab />,
			active: query.get('tabId') === '1',
		},
		{
			id: 2,
			label: 'Companies',
			component: <CompaniesTab ref={companiesTabRef} />,
			active: query.get('tabId') === '2',
		},
	]);

	async function initializeData() {
		try {
			setPageStatus('loading');
			const {
				data: { result },
			} = await fetchMembership({ membershipId });
			dispatch({ type: 'SET_MEMBERSHIP', payload: result.membership });
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}

	function handleOpenArchiveModal() {
		setArchiveIsOpen(true);
	}

	function handleCloseArchiveModal() {
		setArchiveIsOpen(false);
	}

	const handleArchiveUnarchive = async () => {
		setArchiveIsOpen(false);
		try {
			const {
				data: { result },
			} = await archiveUnarchiveMembership({ membershipId: state.membership.id });
			dispatch({ type: 'SET_MEMBERSHIP', payload: result.membership });
		} catch (error) {}
	};

	const handleOpenAddCompanyModal = () => {
		addCompanyModalRef.current?.open(membershipId);
	};

	useEffect(() => {
		// Set document title
		document.title = `Membership Details | ${META_TITLE}`;
	}, []);

	useEffect(() => {
		if (pageStatus === 'idle') {
			initializeData();
		}
	}, [pageStatus]);

	function renderContent() {
		switch (pageStatus) {
			case 'loading':
				return <Spinner />;
			case 'success':
				return (
					<AdminMembershipDetailContext.Provider value={{ state, dispatch }}>
						<DetailPageHeader title={state.membership.name} backButton={{ label: 'Back to Memberships', onClick: () => history.push('/admin/memberships') }}>
							<>
								{state.membership.isActive === true ? (
									<S.Button variant="outline" onClick={handleOpenArchiveModal}>
										<Typography tag="span" variation="2" weight="extrablack">
											Archive
										</Typography>
									</S.Button>
								) : (
									<S.Button variant="outline" onClick={handleOpenArchiveModal}>
										<Typography tag="span" variation="2" weight="extrablack">
											Unarchive
										</Typography>
									</S.Button>
								)}
								<S.Button variant="solid" onClick={handleOpenAddCompanyModal}>
									<Typography tag="span" variation="2" weight="extrablack">
										<FontAwesomeIcon icon={['fal', 'plus']} />
										Add Company
									</Typography>
								</S.Button>
								<AddCompanyToMembershipModal ref={addCompanyModalRef} onSuccessCallback={() => companiesTabRef.current?.onChangeCallback()} />
							</>
						</DetailPageHeader>
						<S.Wrapper>
							<Tab tabs={tabs} setTabs={setTabs} />
						</S.Wrapper>
						<MessageDialog
							isOpen={archiveIsOpen}
							onRequestClose={handleCloseArchiveModal}
							title={state.membership.isActive === true ? 'Archive Membership' : 'Unarchive Membership'}
							subtitle={state.membership.isActive === true ? 'Are you sure you want to archive this membership? You will be able to unarchive it later.' : 'Are you sure you want to unarchive this membership?'}
							icon={['fal', 'id-card-alt']}
							actions={[
								{ id: 'back', label: 'Cancel', variant: 'outline', variation: 'default' },
								{ id: 'archiveUnarchive', label: state.membership.isActive === true ? 'Archive Membership' : 'Unarchive Membership', variant: 'solid', variation: 'default', onClick: handleArchiveUnarchive },
							]}
						/>
					</AdminMembershipDetailContext.Provider>
				);
			case 'error':
				return <ErrorComponent />;
			default:
				return null;
		}
	}

	return <AdminNavigation>{renderContent()}</AdminNavigation>;
};

export default AdminMembershipDetail;
