import React, { createContext, useContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useList } from 'react-use';
import { Badge, Picture, Typography, EmptyComponent } from '../../../components';
import { formatToRelativeTime } from '../../../utils/time-formatter';

import { AppointmentDetailsContext } from '../RequestsAppointmentDetails';
import { FilterSection } from './FilterSection';
import { RequestChat } from './RequestChat';
import * as S from './ResponsesTab.styles';

export const TABS_OPTIONS = [
	{ value: 'all', label: 'All' },
	{ value: 'pending', label: 'Pending' },
	{ value: 'accepted', label: 'Accepted' },
	{ value: 'declined', label: 'Declined' },
];

export const BADGE_STATUS = {
	pending: {
		label: 'Pending',
		color: 'primary',
	},
	archived: {
		label: 'Archived',
		color: 'neutral',
	},
	accepted: {
		label: 'Accepted',
		color: 'success',
	},
	declined: {
		label: 'Declined',
		color: 'danger',
	},
};

export const RequestsContext = createContext();

export const initialState = {
	selectedTab: TABS_OPTIONS[0],
	searchInputValue: '',
	selectedConversation: null,
};
export function reducer(state, action) {
	switch (action.type) {
		case 'SET_SELECTED_TAB':
			return {
				...state,
				selectedTab: action.payload,
			};
		case 'SET_SEARCH_INPUT_VALUE':
			return {
				...state,
				searchInputValue: action.payload,
			};
		case 'SET_SELECTED_CONVERSATION':
			return {
				...state,
				selectedConversation: action.payload,
			};
		case 'CLEAR_SELECTED_CONVERSATION':
			return {
				...state,
				selectedConversation: null,
			};
		default:
			return state;
	}
}

const IndividualItem = ({ id, business, request, lastMessage, status }) => {
	const {
		state: { selectedConversation },
		dispatch,
	} = useContext(RequestsContext);

	return (
		<S.ListItem key={id} onClick={() => dispatch({ type: 'SET_SELECTED_CONVERSATION', payload: { id, business, request, status } })} className={selectedConversation != null && selectedConversation.id === id ? 'is-selected' : ''}>
			<div className="individual-card__avatar-wrapper">
				<Picture className="individual-card__avatar" src={business.logoObj[200]} aspectRatio="1/1" />
			</div>
			<div className="individual-card__info-wrapper">
				<Typography className="individual-card__name" tag="h5" weight="bold">
					{business.name}
				</Typography>
				<Typography className="individual-card__category" tag="p" variation="3">
					{lastMessage ? `${formatToRelativeTime(new Date(lastMessage.date.iso))} • ${request.title}` : request.title}
				</Typography>
				{lastMessage && (
					<Typography className="individual-card__message" tag="p" variation="2">
						{lastMessage.message}
					</Typography>
				)}
			</div>
			<div className="individual-card__badge-wrapper">
				<Badge small="small" type={BADGE_STATUS[status].color} variant="outlined">
					{BADGE_STATUS[status].label}
				</Badge>
			</div>
		</S.ListItem>
	);
};
IndividualItem.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	business: PropTypes.object.isRequired,
	request: PropTypes.object,
	lastMessage: PropTypes.object,
	status: PropTypes.string.isRequired,
};

export const ResponsesTab = ({ role }) => {
	const {
		state: {
			request: { responses, title },
		},
	} = useContext(AppointmentDetailsContext);

	const [state, dispatch] = useReducer(reducer, initialState);
	const { selectedTab, searchInputValue, selectedConversation } = state;
	const [individualList, { filter: filterInd, reset: resetInd }] = useList(responses);
	const handleListFilter = (filterMethod, resetMethod) => {
		resetMethod();
		if (searchInputValue && selectedTab.value === 'all') {
			filterMethod((item) => item.searchTag.toLowerCase().includes(searchInputValue.toLowerCase()));
		} else if (searchInputValue && selectedTab.value !== 'all') {
			filterMethod((item) => item.searchTag.toLowerCase().includes(searchInputValue.toLowerCase()) && item.status === selectedTab.value);
		} else if (searchInputValue === '' && selectedTab.value !== 'all') {
			filterMethod((item) => item.status === selectedTab.value);
		}
	};

	const filterData = () => {
		// Update filter state
		handleListFilter(filterInd, resetInd);
	};

	useEffect(async () => {
		// Update filter state
		filterData();
	}, [selectedTab, searchInputValue]);

	return (
		<RequestsContext.Provider value={{ state, dispatch }}>
			<S.Wrapper someChatIsSelected={selectedConversation}>
				<S.ContentWrapper>
					<FilterSection role={role} />
					<S.RelativeWrapper>
						<S.ListWrapper>
							{individualList.length > 0 ? (
								individualList.map(({ id, business, request, lastMessage, status }) => <IndividualItem key={id} id={id} business={business} request={{ ...request, title }} lastMessage={lastMessage} status={status} />)
							) : (
								<EmptyComponent title="No available responses" message="Check back later to view available responses." icon={['fal', 'comment']} />
							)}
						</S.ListWrapper>
					</S.RelativeWrapper>
				</S.ContentWrapper>
				{selectedConversation && (
					<S.ChatWrapper>
						<div className="relative-wrapper">
							<RequestChat role={role} conversation={selectedConversation} />
						</div>
					</S.ChatWrapper>
				)}
			</S.Wrapper>
		</RequestsContext.Provider>
	);
};

ResponsesTab.propTypes = {
	role: PropTypes.string,
};
