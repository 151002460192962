import styled, { css } from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;

	& > label {
		cursor: pointer;
		color: ${({ error }) => (error ? colors.stateDanger : colors.neutralText)};
	}
`;

export const TextArea = styled.textarea`
	appearance: none;
	padding: 0.625rem 0.75rem;
	min-height: 5.625rem;
	border-radius: 0.375rem;
	border: 1px solid ${colors.neutralForm};
	color: black;
	font-size: 1rem;
	line-height: 'normal';
	width: 100%;
	outline: none;
	resize: vertical;

	&:focus {
		border-color: ${colors.neutralButton};
	}

	${({ error }) =>
		error &&
		css`
			color: ${colors.stateDanger};
			border-color: ${colors.stateDanger};
			:focus {
				border-color: ${colors.stateDanger};
			}
		`}
`;

export const FloatingWrapper = styled.div`
	position: absolute;
	bottom: -1rem;
	color: ${colors.stateDanger};
	max-width: 100%;

	p {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
`;
