import React, { useRef, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { BusinessCompanyProfileTabContext } from '../ProfileTab';
import { Card, EditBusinessLocationsModal, Typography } from '../../../../components';
import * as S from './LocationsCard.styles';

export const LocationsCard = () => {
	const {
		state: { businessProfile },
		dispatch,
	} = useContext(BusinessCompanyProfileTabContext);
	const editModalRef = useRef(null);

	const handleOpenModal = () => editModalRef?.current?.open();

	return (
		<Card title="Locations" transparentHeaderBorder actions={[{ id: 1, label: 'Edit', size: 'small', variant: 'outline', icon: { source: ['fal', 'edit'] }, onClick: handleOpenModal }]}>
			<S.Wrapper>
				{businessProfile?.locations?.map((location) => (
					<S.LocationListItem key={location?.id}>
						<div className="icon-wrapper">
							<FontAwesomeIcon icon={['fal', 'building']} />
						</div>
						<div className="info-wrapper">
							<Typography tag="h5" weight="bold">
								{location?.name}
							</Typography>
							<Typography tag="p" variation="2">
								{`${location?.address?.addressLine1}, ${location?.address?.city}, ${location?.address?.state} ${location?.address?.postalCode}`}
							</Typography>
						</div>
					</S.LocationListItem>
				))}
			</S.Wrapper>
			<EditBusinessLocationsModal ref={editModalRef} company={businessProfile} dispatch={dispatch} />
		</Card>
	);
};
