import styled from 'styled-components';
import colors from '../../../styles/colors';

export const ModalFormWrapper = styled.form`
	display: flex;
	flex-direction: column;
	position: relative;
	height: 100%;

	.close-button {
		position: absolute;
		top: 1rem;
		right: 1rem;
		color: ${colors.neutralButton};
	}

	.modal-content {
		display: block;
		height: 100%;
		overflow: auto;
		padding: 3rem 1rem 1rem;

		.step {
			&__wrapper {
				max-width: 41.875rem;
				margin: 0 auto;
			}

			&__header {
				display: flex;
				flex-direction: column;
				text-align: center;

				& > * + * {
					margin-top: 0.25rem;
				}
			}

			&__content {
				display: grid;
				grid-template-columns: 1fr;
				gap: 1.5rem;
				margin-top: 2.5rem;
			}

			&__calendar-wrapper {
				display: flex;
				flex-direction: column;
				position: relative;
			}

			&__calendar-title {
				margin-bottom: 0.5rem;
			}

			&__calendar-content {
				border: 1px solid ${colors.neutralForm};
				border-radius: 0.375rem;
			}

			&__field-label {
				margin-bottom: 0.375em;
				color: ${colors.neutralText};
			}

			&__field-value {
				&--empty {
					color: ${colors.neutralText};
				}
			}

			&__location-wrapper {
				position: relative;
				display: grid;
				grid-template-columns: 1fr;
				gap: 1rem;
				margin-top: -0.75rem;

				.error-wrapper {
					bottom: -1.5rem;
				}
			}

			&__selected-locations {
				display: grid;
				grid-template-columns: 1fr;
				gap: 2rem;
				margin-bottom: 1.5rem;
			}

			&__empty-value-message {
				color: ${colors.neutralText};
			}

			&__location {
				display: grid;
				grid-template-columns: 2rem 1fr;
			}

			&__location-icon-wrapper {
			}

			&__location-fields-wrapper {
				display: grid;
				grid-template-columns: 1fr;
				gap: 0.75rem;
			}

			&__information-card {
				display: flex;
				padding: 0.5rem 0.75rem;
				background-color: ${colors.neutralBackground};
				border: 1px solid ${colors.neutralForm};
				border-radius: 0.375rem;

				.icon-wrapper {
					display: flex;
					justify-content: center;
					width: 1.25rem;
					color: ${colors.brandPrimary};
				}

				.info-wrapper {
					margin-left: 0.75rem;
					color: #000000;

					.info-line {
						display: flex;
						margin-top: 0.5rem;
						color: ${colors.neutralButton};

						svg {
							width: 1rem;
							height: 1rem;
						}

						p {
							margin-left: 0.5rem;
						}
					}
				}
			}

			&__huge-textarea {
				min-height: 21rem;
			}
		}

		.with-error {
			border-color: ${colors.stateDanger};
		}

		.error-wrapper {
			position: absolute;
			bottom: -1rem;
			color: ${colors.stateDanger};
		}
	}

	.modal-footer {
		display: flex;
		justify-content: flex-end;
		padding: 1.5rem;
		border-top: 1px solid ${colors.neutralForm};
	}
`;
