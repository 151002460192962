import React, { useState, useEffect } from 'react';

import { TeacherNavigation, PageHeader, Tab } from '../../components';

import { RequestsTab } from './RequestsTab';
import { BookingsTab } from './BookingsTab';
import { ResourcesTab } from './ResourcesTab';
import { META_TITLE } from '../../../constants/general.constants';
import * as S from './TeacherMessages.styles';

const TeacherMessages = () => {
	useEffect(() => {
		// Set document title
		document.title = `Messages | Dashboard | ${META_TITLE}`;
	}, []);

	const [tabs, setTabs] = useState([
		{
			id: 1,
			label: 'Bookings',
			component: <BookingsTab />,
		},
		{
			id: 2,
			label: 'Requests',
			component: <RequestsTab />,
		},
		{
			id: 3,
			label: 'Resources',
			component: <ResourcesTab />,
		},
	]);

	return (
		<TeacherNavigation>
			<PageHeader title="Messages" />
			<S.Wrapper>
				<Tab tabs={tabs} setTabs={setTabs} />
			</S.Wrapper>
		</TeacherNavigation>
	);
};

export default TeacherMessages;
