import React, { useRef, useContext, forwardRef, useState, useImperativeHandle, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Controller, useForm } from 'react-hook-form';

import { required } from '../../../../utils/form-default-errors';
import { errorHandler } from '../../../../services/authService';
import { AdminUserDetailContext } from '../../AdminUserDetail';
import { Card, Typography, IconButton, Checkbox, Button, Select } from '../../../../components';
import * as S from './CompanyCard.styles';
import { createUpdateEmployee, fetchBusinesses } from '../../../../services/business';

const EditLocationsModal = forwardRef((_props, ref) => {
	const {
		state: { user },
		dispatch,
	} = useContext(AdminUserDetailContext);

	const [isOpen, setIsOpen] = useState(false);
	const [locations, setLocations] = useState(user?.business?.locations || []);
	const [companies, setCompanies] = useState([]);

	const {
		register,
		setValue,
		getValues,
		formState: { errors, isSubmitting },
		reset,
		handleSubmit,
		control,
	} = useForm();

	const handleOpenModal = () => {
		setValue(
			'locationIds',
			user.businessLocations.map(({ id }) => id)
		);
		setValue('businessId', user.business.id);
		setIsOpen(true);
	};

	const handleCloseModal = () => {
		setIsOpen(false);
		setTimeout(() => {
			reset();
		}, 200);
	};

	async function asyncCaller(data) {
		const {
			data: { result },
		} = await createUpdateEmployee({
			employeeId: user.id,
			businessId: user.business.id,
			firstName: user.firstName,
			lastName: user.lastName,
			permissions: user.businessPermissions,
			email: user.email,
			profileImageObj: user.profileImageObj,
			...data,
			locationIds: typeof data.locationIds === 'string' ? [data.locationIds] : data.locationIds,
		});

		dispatch({ type: 'SET_USER', payload: result.employee });
		handleCloseModal();
	}

	function onSubmit(e) {
		handleSubmit(asyncCaller)(e).catch(errorHandler);
	}

	useImperativeHandle(
		ref,
		() => ({
			open: handleOpenModal,
			close: handleCloseModal,
		}),
		[handleOpenModal, handleCloseModal]
	);

	const loadCompanies = async () => {
		try {
			const {
				data: { result },
			} = await fetchBusinesses({ page: 0, isSlim: true, filterOptions: { approved: true } });
			setCompanies(result.businesses);
		} catch (e) {
			setCompanies([]);
		}
	};

	useEffect(() => {
		loadCompanies();
	}, []);

	return (
		<ReactModal ref={ref} className="employee-locations-card-modal" closeTimeoutMS={200} isOpen={isOpen} onRequestClose={handleCloseModal}>
			<S.Form onSubmit={onSubmit}>
				<IconButton className="close-button" type="button" icon={['fal', 'times']} onClick={handleCloseModal} />
				<div className="modal-content">
					<header className="modal-content__header">
						<Typography tag="h2" weight="extrablack" center>
							Edit Company
						</Typography>
						<Typography tag="p" center>
							Edit the company and locations associated with this member.
						</Typography>
					</header>
					<section className="modal-content__section">
						<div className="checkbox-wrapper">
							<Controller
								name="businessId"
								control={control}
								render={({ field: { onChange, value } }) => (
									<Select
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
										label="Company"
										id="company-id"
										options={companies}
										promise={{
											function: fetchBusinesses,
											params: { isSlim: true, filterOptions: { approved: true } },
											key: 'businesses',
											setOptions: setCompanies,
										}}
										value={companies?.find((c) => c.id === value) || ''}
										onChange={(val) => {
											setValue('locationIds', []);
											setLocations(val.locations);
											onChange(val.id);
										}}
									/>
								)}
							/>
						</div>
					</section>
					<section className="modal-content__section">
						<div className="checkbox-wrapper">
							<Typography className={`field-label${!!errors.locationIds ? ' with-error' : ''}`} tag="p" variation="2" weight="semibold">
								Locations
							</Typography>

							{locations.map(({ id, name }) => (
								<div key={id} className="checkbox-field">
									<Controller
										name="locationIds"
										control={control}
										render={({ field: { value } }) => (
											<Checkbox
												id={id}
												value={id}
												checked={value.indexOf(id) > -1}
												onChange={() => {
													let values = getValues('locationIds');
													if (values.indexOf(id) > -1) {
														values = values.filter((v) => v !== id);
													} else {
														values.push(id);
														values = [...new Set(values)];
													}
													setValue('locationIds', values);
												}}
											/>
										)}
									/>
									<label htmlFor={id}>{name}</label>
								</div>
							))}

							{!!errors.locationIds && (
								<div className="error-wrapper">
									<Typography tag="p" variation="2">
										{errors.locationIds.message}
									</Typography>
								</div>
							)}
						</div>
					</section>
				</div>
				<div className="modal-footer">
					<Button>
						<Typography variation="button-medium" weight="bold">
							{isSubmitting ? 'Loading' : 'Save'}
						</Typography>
					</Button>
				</div>
			</S.Form>
		</ReactModal>
	);
});

EditLocationsModal.displayName = 'EditLocationsModal';

export const CompanyCard = ({ className }) => {
	const {
		state: { user },
	} = useContext(AdminUserDetailContext);

	const editModalRef = useRef(null);

	const handleOpenModal = () => editModalRef?.current?.open();

	return (
		<Card title="Company" className={className} transparentHeaderBorder actions={[{ id: 1, label: 'Edit', size: 'small', variant: 'outline', icon: { source: ['fal', 'edit'] }, onClick: handleOpenModal }]}>
			<S.Wrapper>
				<S.Business key={user?.business.id}>
					<img className="business__avatar" src={user?.business.logoObj['200']} alt="" />
					<div className="business__info">
						<Typography tag="h5" weight="bold">
							{user?.business.name}
						</Typography>
						<div className="business__details">
							<div className="business__element">
								<FontAwesomeIcon icon={['fal', 'globe']} />
								<Typography tag="p" variation="2">
									{user?.business.website}
								</Typography>
							</div>
						</div>
					</div>
				</S.Business>

				<Typography tag="h4" weight="bold" className="location-title">
					{`Assigned Locations (${user?.businessLocations?.length || 0})`}
				</Typography>
				{user?.businessLocations?.map((location) => (
					<S.LocationListItem key={location?.id}>
						<div className="icon-wrapper">
							<FontAwesomeIcon icon={['fal', 'building']} />
						</div>
						<div className="info-wrapper">
							<Typography tag="h5" weight="bold">
								{location?.name}
							</Typography>
							<Typography tag="p" variation="2">
								{`${location?.address?.addressLine1}, ${location?.address?.city}, ${location?.address?.state} ${location?.address?.postalCode}`}
							</Typography>
						</div>
					</S.LocationListItem>
				))}
			</S.Wrapper>
			<EditLocationsModal ref={editModalRef} />
		</Card>
	);
};

CompanyCard.propTypes = {
	className: PropTypes.string,
};
