import styled from 'styled-components';

export const Wrapper = styled.div`
	display: grid;
	gap: 1.25rem;
	padding: 1.25rem 1rem 2.5rem;

	.a1 {
		grid-area: a1;
	}
	.a2 {
		grid-area: a2;
	}
	.a3 {
		grid-area: a3;
	}

	grid-template-areas:
		'a1'
		'a2'
		'a3';

	@media screen and (min-width: 992px) {
		grid-template-areas:
			'a1 a1 a1 a1 a1'
			'a2 a2 a2 a3 a3';
	}
`;
