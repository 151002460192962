import styled, { css } from 'styled-components';

import colors from '../../../styles/colors';

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	position: relative;
	height: 100%;

	.close-button {
		position: absolute;
		top: 1rem;
		right: 1rem;
		color: ${colors.neutralButton};
	}

	.modal-content-wrapper {
		flex: 1;
		overflow: visible;
	}

	.modal-content {
		display: block;
		flex: 1;
		max-width: 41.75rem;
		margin: 0 auto;
		width: 100%;
		padding: 6rem 1rem;

		.bigger-title {
			overflow: hidden;
			margin: 0 -1rem;
		}

		.description {
			margin-top: 0.5rem;
			margin-bottom: 3rem;
		}

		.with-error {
			border-color: ${colors.stateDanger};
		}

		.field-wrapper.mt-1 {
			margin-top: 0.5rem;
		}

		.field-label {
			color: ${colors.neutralText};

			&.custom-margin {
				margin-top: 1.5rem;
			}

			&--empty {
				color: ${colors.neutralText};
			}

			&.with-error {
				color: ${colors.stateDanger};
			}
		}

		.fields-wrapper {
			display: grid;
			grid-template-columns: 1fr;
			gap: 1.25rem;
		}

		.checkbox-wrapper {
			display: grid;
			grid-template-columns: 1fr;
			gap: 1rem;
			position: relative;

			.field-label {
				color: ${colors.neutralText};

				&.with-error {
					color: ${colors.stateDanger};
				}
			}

			.checkbox-field {
				display: flex;
				align-items: center;

				& > * + * {
					padding-left: 0.75rem;
				}

				label {
					font-size: 1rem;
					line-height: 1.375rem;
					font-weight: 400;
					color: black;
					cursor: pointer;
				}
			}

			@media screen and (min-width: 768px) {
				grid-template-columns: 1fr 1fr;

				.field-label {
					grid-column: 1 / 3;
				}
			}
		}

		.custom-select {
			margin-top: 1rem;
		}

		.error-wrapper {
			position: absolute;
			bottom: -1rem;
			color: ${colors.stateDanger};

			&.mt1 {
				bottom: -1.5rem;
			}
		}
	}

	.modal-footer {
		display: flex;
		justify-content: flex-end;
		padding: 1.5rem;
		border-top: 1px solid ${colors.neutralForm};
	}
`;

export const ButtonsWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin: 0;
	padding: 1rem;
	border-top: 1px solid ${colors.neutralForm};

	& > * + * {
		margin-left: 1rem;
	}
`;
