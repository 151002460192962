import React from 'react';
import PropTypes from 'prop-types';

import { Card, RatingStars, Typography } from '../../../../components';
import * as S from './ReviewCard.styles';

export const ReviewCard = ({ booking }) => {
	return (
		<Card title="Review" transparentHeaderBorder={true}>
			<S.Wrapper>
				<div className="review__step-half">
					<div className="review__step-item">
						<Typography tag="h4" weight="bold">
							Attendance
						</Typography>

						<S.BigFieldset>
							<Typography tag="h5" weight="bold">
								Actual Attendance
							</Typography>
							<S.BigBox>
								<S.BigBoxHeader>
									<Typography tag="h6" weight="semibold">
										Group
									</Typography>
									<Typography tag="h6" weight="semibold">
										# of Students
									</Typography>
								</S.BigBoxHeader>
								<S.BigBoxLine>
									<Typography tag="p">{booking?.group?.name}</Typography>
									<p className="box-field">{booking?.attendanceGroupCount}</p>
								</S.BigBoxLine>
								<S.BigBoxLine>
									<Typography tag="p">Additional Students</Typography>
									<p className="box-field">{booking?.attendanceAdditionalCount}</p>
								</S.BigBoxLine>
								<S.BigBoxFooter>
									<Typography tag="p" weight="semibold">
										Total
									</Typography>
									<Typography tag="p" weight="semibold" className={booking?.group?.totalStudents != booking?.attendanceGroupCount + booking?.attendanceAdditionalCount && 'warning-text'}>
										{booking?.attendanceGroupCount + booking?.attendanceAdditionalCount}
									</Typography>
								</S.BigBoxFooter>
							</S.BigBox>
						</S.BigFieldset>
					</div>
					<div className="review__step-item">
						<div className="review__info-item">
							<Typography tag="h6" weight="semibold">
								How many additional groups attended?
							</Typography>
							<Typography tag="p">{booking?.attendanceAdditionalGroups}</Typography>
						</div>

						<div className="review__info-item">
							<Typography tag="h6" weight="semibold">
								Additional Teacher&apos;s Name
							</Typography>
							<Typography tag="p">{[...booking?.attendanceAdditionalTeachers, ...booking?.attendanceAdditionalTeachersContacts].map(({ firstName, lastName }) => `${firstName} ${lastName}`).join(', ')}</Typography>
						</div>
					</div>
				</div>

				<div className="review__step-half">
					<div className="review__step-item">
						<Typography tag="h5" weight="bold">
							Demographics
						</Typography>

						<div className="review__info-item">
							<Typography tag="h6" weight="semibold">
								Age Range
							</Typography>
							<Typography tag="p">{`${booking?.attendanceMinAge} - ${booking?.attendanceMaxAge}`}</Typography>
						</div>

						<S.BigFieldset>
							<Typography tag="h6" weight="bold">
								Gender
							</Typography>
							<S.BigBox>
								<S.BigBoxHeader>
									<Typography tag="h6" weight="semibold">
										Gender
									</Typography>
									<Typography tag="h6" weight="semibold">
										# of Students
									</Typography>
								</S.BigBoxHeader>
								<S.BigBoxLine>
									<Typography tag="p">Male</Typography>
									<p className="box-field">{booking?.attendanceGenderMale}</p>
								</S.BigBoxLine>
								<S.BigBoxLine>
									<Typography tag="p">Female</Typography>
									<p className="box-field">{booking?.attendanceGenderFemale}</p>
								</S.BigBoxLine>
								<S.BigBoxLine>
									<Typography tag="p">Other</Typography>
									<p className="box-field">{booking?.attendanceGenderOther}</p>
								</S.BigBoxLine>
								<S.BigBoxFooter>
									<Typography tag="p" weight="semibold">
										Total
									</Typography>
									<Typography tag="p" weight="semibold" className={booking?.group?.totalStudents != booking?.attendanceGroupCount + booking?.attendanceAdditionalCount && 'warning-text'}>
										{booking?.attendanceGenderMale + booking?.attendanceGenderFemale + booking?.attendanceGenderOther}
									</Typography>
								</S.BigBoxFooter>
							</S.BigBox>
						</S.BigFieldset>
					</div>
				</div>
				{booking?.review && (
					<div className="review__step-item review__businesses">
						<Typography tag="h4" weight="bold">
							Business
						</Typography>
						<div className="business-review-item smaller" key={booking?.review?.id}>
							<div className="business-review-item__image-wrapper">
								<img src={booking?.review?.business?.logoObj?.[200]} alt={booking?.review?.business?.name} />
							</div>
							<div className="business-review-item__fields-wrapper">
								<Typography tag="h4" weight="bold">
									{booking?.review?.business?.name}
								</Typography>
								<RatingStars label="Rating" value={booking?.review?.rating} />
								<div className="review__info-item">
									<Typography tag="h6" weight="semibold">
										Message
									</Typography>
									<Typography tag="p">{booking?.review?.comments || 'No comments included'} </Typography>
								</div>
							</div>
						</div>
					</div>
				)}
				<div className="review__step-item review__learning">
					<Typography tag="h4" weight="bold">
						Learning Connections
					</Typography>
					<div className="review__step-item">
						<div className="review__step-half">
							<div className="review__info-item review__info-item--with-badge">
								<Typography tag="h6" weight="semibold">
									Employability Skills
								</Typography>
								<div className="badge-wrapper">
									{booking?.employabilitySkills?.map((value) => (
										<div className="small-badge" key={value}>
											{value}
										</div>
									))}
								</div>
							</div>

							<div className="review__info-item review__info-item--with-badge">
								<Typography tag="h6" weight="semibold">
									Curriculum Standards
								</Typography>
								<div className="badge-wrapper">
									{booking?.curriculumStandards?.map((value) => (
										<div className="small-badge" key={value}>
											{value}
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</S.Wrapper>
		</Card>
	);
};

ReviewCard.propTypes = {
	booking: PropTypes.shape(),
	role: PropTypes.string,
};
