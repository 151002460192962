import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import * as S from './Button.styles';

export const Button = forwardRef(({ children, size = 'medium', variant = 'solid', variation = 'default', ...rest }, ref) => {
	return (
		<S.Button ref={ref} size={size} variant={variant} variation={variation} {...rest}>
			{children}
		</S.Button>
	);
});

Button.displayName = 'Button';
Button.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	size: PropTypes.oneOf(['medium', 'small']),
	variant: PropTypes.oneOf(['solid', 'outline', 'text']),
	variation: PropTypes.oneOf(['default', 'secondary', 'warning']),
};
