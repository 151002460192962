import toast from 'react-hot-toast';

import api from './api';

export const errorHandler = (error) => {
	const errorMessage = error?.response?.data?.error || error?.response?.data?.message || error?.message || 'Something went wrong, try again later!';
	toast.error(errorMessage);
};

export const getCurrentAuthenticatedUser = () => api.post('/core/functions/currentUser');

export const beginPasswordReset = (data) => api.post('/core/functions/beginPasswordReset', data);

export const validateResetAttempt = (data) => api.post('/core/functions/validateResetAttempt', data);

export const validateInviteAttempt = (data) => api.post('/core/functions/validateInviteAttempt', data);

export const resetPassword = (data) => api.post('/core/functions/resetPassword', data);

export const loginUser = (data) => api.post('/users/loginUser', data, { withCredentials: true });

export const acceptInvite = (data) => api.post('/core/functions/acceptInvite', data);

export const logoutUser = () => api.post('/users/logoutUser', {}, { withCredentials: true });
