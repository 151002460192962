import React, { forwardRef, useState, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Controller, useForm } from 'react-hook-form';

import { required } from '../../utils/form-default-errors';
import { updateUser } from '../../app/slices/user/userSlice';
import { updateUserProfile } from '../../services/users';
import { uploadProfilePicture } from '../../services/media';
import { errorHandler } from '../../services/authService';
import { TEACHER_ROLES_OPTIONS } from '../../../constants/general.constants';
import { Avatar, Button, IconButton, Select, TextInput, Typography } from '../';
import * as S from './EditTeacherProfileModal.styles';
import { fetchSchools } from '../../services/school';

export const EditTeacherProfileModal = forwardRef(({ user, dispatch, currentUser = false, allowedEditUID = false }, ref) => {
	const [isOpen, setIsOpen] = useState(false);
	const [avatar, setAvatar] = useState(null);
	const [avatarFile, setAvatarFile] = useState(null);
	const [schools, setSchools] = useState([]);

	const {
		handleSubmit,
		control,
		setValue,
		register,
		watch,
		formState: { errors, isSubmitting },
	} = useForm();

	const selectedSchool = schools?.find((school) => school.id === watch('primarySchoolId'));

	const loadSchools = async () => {
		try {
			const {
				data: { result },
			} = await fetchSchools({ page: 0, isSlim: true });
			setSchools([user?.primarySchool, ...result.schools]);
		} catch (error) {
			setSchools([user?.primarySchool]);
		}
	};

	const handleOpenModal = () => {
		setValue('roleName', user?.teacherRole);
		setValue('primarySchoolId', user?.primarySchool?.id);
		setValue('uid', user?.uid);
		setSchools([user?.primarySchool, ...(user?.schools || [])]);
		setAvatar(user?.profileImageObj?.[200]);
		if (!user?.schools) loadSchools();
		setAvatarFile(null);
		setIsOpen(true);
	};

	const handleCloseModal = () => {
		setIsOpen(false);
	};

	async function asyncCaller(data) {
		let profileImageObj;

		if (avatarFile) {
			const formData = new FormData();
			formData.append('file', avatarFile);

			const { data: uploadResult } = await uploadProfilePicture(formData);

			profileImageObj = uploadResult;
		} else {
			profileImageObj = user?.profileImageObj;
		}

		const {
			data: { result },
		} = await updateUserProfile({ ...data, userId: user.id, profileImageObj });

		if (currentUser) {
			dispatch(updateUser(result?.user));
		} else {
			dispatch({ type: 'SET_USER', payload: result?.user });
		}
		handleCloseModal();
	}

	const onSubmit = (e) => {
		handleSubmit(asyncCaller)(e).catch(errorHandler);
	};

	useImperativeHandle(
		ref,
		() => ({
			open: handleOpenModal,
			close: handleCloseModal,
		}),
		[handleOpenModal, handleCloseModal]
	);

	return (
		<ReactModal className="profile-card-modal" ref={ref} closeTimeoutMS={200} isOpen={isOpen} onRequestClose={handleCloseModal}>
			<S.Form onSubmit={onSubmit}>
				<IconButton className="close-button" type="button" icon={['fal', 'times']} onClick={handleCloseModal} />
				<div className="modal-content">
					<header className="modal-content__header">
						<Typography tag="h2" weight="extrablack" center>
							Edit Profile
						</Typography>
						<Typography tag="p" center>
							Please update your profile information here.
						</Typography>
					</header>

					<section className="modal-content__section">
						<Avatar className="avatar-element" avatar={avatar} setAvatar={setAvatar} file={avatarFile} setFile={setAvatarFile} />

						<TextInput
							label="Teacher UID"
							placeholder="Teacher UID"
							error={errors.uid}
							{...register('uid', {
								required: required('Teacher UID'),
							})}
							name="uid"
							readOnly={!allowedEditUID}
						/>

						<Controller name="roleName" control={control} render={({ field: { onChange, value } }) => <Select label="Role" id="role-name" options={TEACHER_ROLES_OPTIONS} value={TEACHER_ROLES_OPTIONS.find((c) => c.value === value) || ''} onChange={(val) => onChange(val.value)} />} />

						<div>
							<Controller
								name="primarySchoolId"
								control={control}
								render={({ field: { onChange, value } }) => (
									<Select
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
										label="School"
										id="primary-school-id"
										options={schools}
										promise={{
											function: fetchSchools,
											params: { isSlim: true },
											key: 'schools',
											setOptions: setSchools,
										}}
										value={schools?.find((c) => c.id === value) || ''}
										onChange={(val) => onChange(val.id)}
									/>
								)}
							/>

							<div className="information-card">
								<div className="icon-wrapper">
									<FontAwesomeIcon icon="school" />
								</div>
								<div className="info-wrapper">
									<Typography tag="h5" weight="bold">
										{selectedSchool?.name}
									</Typography>
									<div className="info-line">
										<FontAwesomeIcon icon={['fal', 'map-marker']} />
										<Typography tag="p" variation="2">
											{`${selectedSchool?.address?.addressLine1}, ${selectedSchool?.address?.state} ${selectedSchool?.address?.postalCode}`}
										</Typography>
									</div>
									<div className="info-line">
										<FontAwesomeIcon icon={['fal', 'landmark']} />
										<Typography tag="p" variation="2">
											{selectedSchool?.address?.city}
										</Typography>
									</div>
									<div className="info-line">
										<FontAwesomeIcon icon={['fal', 'school']} />
										<Typography tag="p" variation="2">
											{selectedSchool?.schoolCode}
										</Typography>
									</div>
								</div>
							</div>
						</div>
					</section>

					<div className="modal-footer">
						<Button>
							<Typography variation="button-medium" weight="bold">
								{isSubmitting ? 'Loading' : 'Save'}
							</Typography>
						</Button>
					</div>
				</div>
			</S.Form>
		</ReactModal>
	);
});

EditTeacherProfileModal.displayName = 'EditTeacherProfileModal';

EditTeacherProfileModal.propTypes = {
	className: PropTypes.string,
	user: PropTypes.shape(),
	dispatch: PropTypes.func,
	currentUser: PropTypes.bool,
	allowedEditUID: PropTypes.bool,
};
