import styled from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	position: relative;

	.c-listbox {
		&__button {
			width: 100%;
			appearance: none;
			border: none;
			border-bottom: 1px solid ${colors.neutralForm};
			position: relative;
			background-color: white;
			padding: 0.75rem 1rem;
			cursor: pointer;
			color: ${colors.brandPrimary};
			font-size: 1rem;
			line-height: 1.375rem;
			display: flex;
			align-items: center;

			& > * + * {
				margin-left: 0.5rem;
			}

			&__circle {
				width: 1.875rem;
				height: 1.875rem;
				min-width: 1.875rem;
				min-height: 1.875rem;
				border-radius: 9999px;
				border: 2px solid ${colors.brandPrimary};
				display: inline-flex;
				justify-content: center;
				font-weight: 600;
				align-items: center;

				& > svg {
					display: none;
				}
			}

			&__name {
				flex: 1;
				text-align: left;
				font-weight: 700;
			}

			&.completed {
				.c-listbox__button__circle {
					border-color: ${colors.stateSuccess};
					background-color: ${colors.stateSuccess};
					color: white;

					& > span {
						display: none;
					}
					& > svg {
						display: block;
					}
				}
				.c-listbox__button__name {
					color: ${colors.stateSuccess};
				}
			}
		}
		&__options {
			position: absolute;
			left: 0;
			right: 0;
			padding: 0.75rem 1rem;
			list-style: none;
			background-color: white;
			border-radius: 0.25rem;
			box-shadow: ${colors.normalShadow};
			display: flex;
			flex-direction: column;
			z-index: 1;
		}

		&__option {
			font-size: 1rem;
			line-height: 1.375rem;
			font-weight: 700;
			display: flex;
			align-items: center;
			cursor: pointer;
			padding: 0.5rem 0;

			& > * + * {
				margin-left: 0.5rem;
			}

			&__circle {
				width: 1.25rem;
				height: 1.25rem;
				min-width: 1.25rem;
				min-height: 1.25rem;
				border-radius: 9999px;
				border: 2px solid ${colors.neutralText};
				color: ${colors.neutralText};
				display: inline-flex;
				justify-content: center;
				font-size: 0.75rem;
				font-weight: 600;
				align-items: center;

				& > svg {
					display: none;
				}
			}

			&__name {
				font-weight: 400;
				color: ${colors.neutralText};
			}
		}
	}

	.option-selected,
	.option-active {
		.c-listbox__option__circle {
			color: ${colors.brandPrimary};
			border-color: ${colors.brandPrimary};
		}
		.c-listbox__option__name {
			color: ${colors.brandPrimary};
		}
	}

	.completed {
		.c-listbox__option__circle {
			color: white;
			border-color: ${colors.stateSuccess};
			background-color: ${colors.stateSuccess};

			& > span {
				display: none;
			}
			& > svg {
				display: block;
			}
		}
		.c-listbox__option__name {
			color: ${colors.stateSuccess};
		}
	}
`;
