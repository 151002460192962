import React, { useState, useEffect } from 'react';

import { PageHeader, Tab, BusinessNavigation } from '../../components';

import * as S from './BusinessMessages.styles';
import { META_TITLE } from '../../../constants/general.constants';
import { ReceivedBookingsTab } from './ReceivedBookingsTab';
import { RespondedRequestsTab } from './RespondedRequestsTab';
import { PostedRequestsTab } from './PostedRequestsTab';
import { SentBookingsTab } from './SentBookingsTab';
import { ResourcesTab } from './RespondedResourcesTab';
import { PostedResourcesTab } from './PostedResourcesTab';

const BusinessMessages = () => {
	useEffect(() => {
		// Set document title
		document.title = `Messages | Dashboard | ${META_TITLE}`;
	}, []);

	const [tabs, setTabs] = useState([
		{
			id: 1,
			label: 'Received Bookings',
			component: <ReceivedBookingsTab />,
		},
		{
			id: 2,
			label: 'Sent Bookings',
			component: <SentBookingsTab />,
		},
		{
			id: 3,
			label: 'Responded Requests',
			component: <RespondedRequestsTab />,
		},
		{
			id: 4,
			label: 'Posted Requests',
			component: <PostedRequestsTab />,
		},
		{
			id: 5,
			label: 'Responded Resources',
			component: <ResourcesTab />,
		},
		{
			id: 6,
			label: 'Posted Resources',
			component: <PostedResourcesTab />,
		},
	]);

	return (
		<BusinessNavigation>
			<PageHeader title="Messages" />
			<S.Wrapper>
				<Tab tabs={tabs} setTabs={setTabs} />
			</S.Wrapper>
		</BusinessNavigation>
	);
};

export default BusinessMessages;
