import React, { useState, useEffect, createContext, useReducer } from 'react';
import { useSelector } from 'react-redux';

import { fetchBusinessProfile } from '../../../services/directory';
import { Spinner, ErrorComponent } from '../../../components';
import * as S from './ProfileTab.styles';
import { OverviewCard } from './OverviewCard';
import { LocationsCard } from './LocationsCard';

export const BusinessCompanyProfileTabContext = createContext();
export const initialState = {
	businessProfile: null,
};
function reducer(state, action) {
	switch (action.type) {
		case 'SET_BUSINESS_PROFILE':
			return {
				...state,
				businessProfile: action.payload,
			};
		default:
			return state;
	}
}

export const ProfileTab = () => {
	const { business } = useSelector((state) => state.user.value);
	const [pageStatus, setPageStatus] = useState('idle');
	const [state, dispatch] = useReducer(reducer, initialState);

	async function initializeData() {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchBusinessProfile({ businessId: business.id });
			dispatch({ type: 'SET_BUSINESS_PROFILE', payload: result.business });
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}

	useEffect(() => {
		if (pageStatus === 'idle') {
			initializeData();
		}
	}, []);

	const renderContent = () => {
		switch (pageStatus) {
			case 'loading':
				return <Spinner />;
			case 'error':
				return <ErrorComponent />;
			case 'success':
				return (
					<S.Wrapper>
						<OverviewCard />
						<LocationsCard />
					</S.Wrapper>
				);
			default:
				return null;
		}
	};

	return (
		<BusinessCompanyProfileTabContext.Provider value={{ state, dispatch }}>
			<S.Wrapper>{renderContent()}</S.Wrapper>
		</BusinessCompanyProfileTabContext.Provider>
	);
};

ProfileTab.propTypes = {};
