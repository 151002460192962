import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { BusinessNavigation, PageHeader, Tab } from '../../components';
import { ResourcesTab } from './ResourcesTab';
import { RequestsTab } from './RequestsTab';
import * as S from './BusinessSaved.styles';
import { META_TITLE } from '../../../constants/general.constants';

const BusinessSaved = () => {
	const history = useHistory();
	const [tabs, setTabs] = useState([
		{
			id: 1,
			label: 'Resources',
			component: <ResourcesTab />,
		},
		{
			id: 2,
			label: 'Requests',
			component: <RequestsTab />,
		},
	]);

	useEffect(() => {
		// Set document title
		document.title = `Saved Resources | ${META_TITLE}`;
	}, []);

	return (
		<BusinessNavigation>
			<PageHeader
				title="Saved"
				action={[
					{ id: 1, onClick: () => history.push('/business/create-request'), type: 'outline', label: 'Create Request', icon: ['fal', 'plus'] },
					{ id: 2, onClick: () => history.push('/business/create-resource'), label: 'Create Resource', icon: ['fal', 'plus'] },
				]}
			/>
			<S.Wrapper>
				<Tab tabs={tabs} setTabs={setTabs} />
			</S.Wrapper>
		</BusinessNavigation>
	);
};

export default BusinessSaved;
