import styled, { css } from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	color: ${({ error }) => (error ? colors.statusBad : colors.neutralButton)};

	& > label {
		cursor: pointer;
		color: ${({ error }) => (error ? colors.stateDanger : colors.neutralText)};
	}
`;

export const InputWrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;

	& > svg {
		position: absolute;
		left: 0.5em;
		width: 1.25em !important;
		height: 1.25em !important;
		pointer-events: none;
	}
`;

export const Input = styled.input`
	flex: 1;
	appearance: none;
	padding: 0 0.75rem;
	padding-left: 2.15rem;
	border-radius: 0.375rem;
	border: 1px solid ${colors.neutralForm};
	color: black;
	font-size: 1rem;
	min-height: 2.8125rem;
	outline: none;

	&:focus {
		border-color: ${colors.neutralButton};
	}

	&.first-input {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
	&.last-input {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	${({ withoutIcon }) =>
		withoutIcon &&
		css`
			padding-left: 0.75rem;
		`}

	${({ error }) =>
		error &&
		css`
			color: ${colors.statusBad};
			border-color: ${colors.statusBad};
			:focus {
				border-color: ${colors.statusBad};
			}
		`}

	${({ size }) =>
		size === 'small' &&
		css`
			font-size: 0.75rem;
			min-height: 2.25rem;
		`}
`;

export const FloatingWrapper = styled.div`
	position: absolute;
	bottom: -1rem;
	color: ${colors.stateDanger};
	max-width: 100%;

	p {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
`;

export const DateWrapper = styled.div`
	display: flex;
`;

export const RangeWrapper = styled.div`
	display: flex;
	flex: 1;

	${Input} {
		flex: 1;
	}
`;
