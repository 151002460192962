import styled, { css } from 'styled-components';

import colors from '../../styles/colors';

const SIZES = {
	small: css`
		padding: 0.3125rem 0.625rem;
	`,
	medium: css`
		padding: 0.5625rem 1.5625rem;
	`,
};

const VARIATIONS = {
	default: {
		solid: css`
			background-color: ${colors.brandPrimary};
			border-color: ${colors.brandPrimary};
			color: white;
		`,
		outline: css`
			background-color: transparent;
			border-color: ${colors.brandPrimary};
			color: ${colors.brandPrimary};
		`,
		text: css`
			background-color: transparent;
			border-color: transparent;
			color: ${colors.brandPrimary};
		`,
	},
	secondary: {
		solid: css`
			background-color: ${colors.neutralButton};
			border-color: ${colors.neutralButton};
			color: white;
		`,
		outline: css`
			background-color: transparent;
			border-color: ${colors.neutralButton};
			color: ${colors.neutralButton};
		`,
		text: css`
			background-color: transparent;
			border-color: transparent;
			color: ${colors.neutralButton};
		`,
	},
	warning: {
		solid: css`
			background-color: ${colors.stateDanger};
			border-color: ${colors.stateDanger};
			color: white;
		`,
		outline: css`
			background-color: transparent;
			border-color: ${colors.stateDanger};
			color: ${colors.stateDanger};
		`,
		text: css`
			background-color: transparent;
			border-color: transparent;
			color: ${colors.stateDanger};
		`,
	},
};

export const Button = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	appearance: none;
	border-radius: 0.375rem;
	letter-spacing: 1.5px;
	cursor: pointer;
	border-width: 1px;
	border-style: solid;

	& > * + * {
		margin-left: 0.625rem;
	}

	${({ size, variant, variation }) => css`
		${SIZES[size]}
		${VARIATIONS[variation][variant]}
	`}

	&:hover {
		opacity: 0.8;
	}
`;
