import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

import { ROLES } from '../../../../constants/general.constants';
import * as S from '../CreateRequest.styles';
import { OverviewStep } from './OverviewStep';
import { FilesStep } from './FilesStep';
import { LocationStep } from './LocationStep';
import { AvailabilityStep } from './AvailabilityStep';
import { GroupStep } from './GroupStep';
import { LinksStep } from './LinksStep';

export const StepDetails = ({ selectedDetailsStepId, role }) => {
	const { getValues } = useFormContext();

	return (
		<S.FormPanel>
			{getValues('type') === 'appointment' && (
				<>
					{selectedDetailsStepId === 1 && <OverviewStep role={role} />}
					{selectedDetailsStepId === 2 && <FilesStep role={role} />}
					{selectedDetailsStepId === 3 && <LocationStep role={role} />}
					{selectedDetailsStepId === 4 && <AvailabilityStep role={role} />}
					{role === ROLES.TEACHER && selectedDetailsStepId === 5 && <GroupStep role={role} />}
				</>
			)}
			{getValues('type') === 'information' && (
				<>
					{selectedDetailsStepId === 1 && <OverviewStep role={role} />}
					{selectedDetailsStepId === 2 && <FilesStep role={role} />}
					{selectedDetailsStepId === 3 && <LinksStep role={role} />}
					{role === ROLES.TEACHER && selectedDetailsStepId === 4 && <GroupStep role={role} />}
				</>
			)}
		</S.FormPanel>
	);
};

StepDetails.propTypes = {
	selectedDetailsStepId: PropTypes.number,
	role: PropTypes.string,
};
