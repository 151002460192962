import styled, { css } from 'styled-components';

export const WEIGHTS = {
	regular: 400,
	semibold: 600,
	bold: 700,
	extrablack: 800,
};

export const SIZES = {
	h1: '2.5rem', // 40px
	h2: '2rem', // 32px
	h3: '1.5rem', // 24px
	h4: '1.25rem', // 20px
	h5: '1rem', // 16px
	h6: '0.75rem', // 12px
	p1: '1rem', // 16px
	p2: '0.75rem', // 12px
	p3: '0.625rem', // 10px
	span1: '1rem', // 16px
	span2: '0.75rem', // 12px
	span3: '0.625rem', // 10px
	'spanbutton-medium': '0.875rem', // 14px
	'spanbutton-small': '0.75rem', // 12px
	label: '0.75rem', // 12px
};

export const LINE_HEIGHTS = {
	h1: 'normal',
	h2: 'normal',
	h3: 'normal',
	h4: 'normal',
	h5: 'normal',
	h6: 'normal',
	p1: 'normal',
	p2: 'normal',
	p3: 'normal',
	span1: 'normal',
	span2: 'normal',
	span3: 'normal',
	'spanbutton-medium': 'normal',
	'spanbutton-small': 'normal',
	label: 'normal',
};

export const Typography = styled.span`
	font-weight: ${({ weight }) => WEIGHTS[weight]};
	font-size: ${({ as, variation }) => (as === 'span' || as === 'p' ? SIZES[as + variation] : SIZES[as])};
	line-height: ${({ as, variation }) => (as === 'span' || as === 'p' ? LINE_HEIGHTS[as + variation] : LINE_HEIGHTS[as])};
	margin-bottom: ${({ as }) => (as === 'label' ? '0.375em' : '0')};
	text-align: ${({ center }) => (center ? 'center' : 'inherit')};

	${({ truncate }) =>
		truncate &&
		css`
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		`}
`;
