import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { DirectoryCard, Map, Typography, EmptyComponent } from '../../../../components';
import * as S from './DirectoryCardsGrid.styles';
import { formatNumberWithCommas } from '../../../../utils/content-formatter';

const VIEW_OPTIONS = {
	grid: 'grid',
	list: 'list',
};

const DirectoryCardsGrid = ({ data, role, totalItems }) => {
	const [showMap, setShowMap] = useState(false);
	const [selectedViewOption, setSelectedViewOption] = useState(VIEW_OPTIONS.grid);

	return (
		<S.DirectoryCardsGrid showMap={showMap}>
			<div>
				<S.ResultsAndActionsWrapper>
					<Typography tag="p" variation="2">
						{totalItems === 1 ? '1 Results' : `${formatNumberWithCommas(totalItems)} Results`}
					</Typography>
					<div className="actions-wrapper">
						<S.ToggleButton className="grid-button first" active={selectedViewOption === 'grid'} onClick={() => setSelectedViewOption('grid')}>
							<FontAwesomeIcon icon={['fal', 'th-large']} />
						</S.ToggleButton>
						<S.ToggleButton className="grid-button last" active={selectedViewOption === 'list'} onClick={() => setSelectedViewOption('list')}>
							<FontAwesomeIcon icon={['fal', 'list']} />
						</S.ToggleButton>
						<S.ToggleButton active={showMap} onClick={() => setShowMap((prev) => !prev)}>
							<FontAwesomeIcon icon={['fal', 'map']} />
							<Typography tag="p" variation="2">
								Show Map
							</Typography>
						</S.ToggleButton>
					</div>
				</S.ResultsAndActionsWrapper>
				{data.length > 0 ? (
					<S.CardsWrapper viewOption={showMap && selectedViewOption === 'grid' ? 'list' : selectedViewOption}>
						{data.map((request) => (
							<DirectoryCard
								key={request.id}
								to={`/requests/${request.id}`}
								back={'/requests'}
								status={request.status}
								variation={showMap && selectedViewOption === 'grid' ? 'list-alt' : selectedViewOption}
								thumbnail={request.coverImageObj['800']}
								role={role}
								type={request.category}
								title={request.title}
								location={request?.creator?.business?.name || request?.business?.name || `${request?.creator?.firstName} ${request?.creator?.lastName}`}
								isArchived={request.isArchived}
							/>
						))}
					</S.CardsWrapper>
				) : (
					<EmptyComponent title="No available requests" message="Check back later to view available requests from teachers and businesses." icon={['fal', 'file-plus']} />
				)}
			</div>
			{showMap && (
				<S.MapWrapper>
					<Map className="map-component" role={role} enableGeolocationFallback={!role} entityType={'request'} markers={data.filter((datum) => datum.type !== 'information')} />
				</S.MapWrapper>
			)}
		</S.DirectoryCardsGrid>
	);
};

DirectoryCardsGrid.propTypes = {
	data: PropTypes.array,
	role: PropTypes.string,
	totalItems: PropTypes.number,
};

export { DirectoryCardsGrid };
