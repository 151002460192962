import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DetailedCheckbox, TextArea, Typography, RangeInput, Select } from '../../../../components';
import { required } from '../../../../utils/form-default-errors';
import { useSelector } from 'react-redux';

const LOCATION_TYPES = [
	{ value: 'virtual', label: 'Virtual', description: 'Hosted online via video chat, phone call, or conference call.', icon: ['fal', 'camera'] },
	{ value: 'worksite', label: 'Worksite Location', description: 'Hosted at a company headquarters or other business site.', icon: ['fal', 'building'] },
	{ value: 'student', label: 'Student Location', description: 'Hosted at a school or other student location.', icon: ['fal', 'users'] },
];

export const StepTwo = ({ worksiteLocations = [], resourceLocationTypes = [] }) => {
	const {
		register,
		watch,
		control,
		formState: { errors },
	} = useFormContext();

	const user = useSelector((state) => state.user.value);

	const schoolOptions = [...user.schools];
	const selectedSchool = user.schools.find((school) => school.id === watch('schoolLocationId'));
	const businessOptions = worksiteLocations.map((business) => business.location);
	const selectedBusiness = businessOptions.find((business) => business.id === watch('businessLocationId'));

	const locationTypes = watch('locationTypes');

	const availableLocationTypes = LOCATION_TYPES.filter((type) => resourceLocationTypes.includes(type.value));

	return (
		<div className="step__wrapper">
			<header className="step__header">
				<Typography tag="h2" weight="extrablack" className="step__title">
					Select a Location
				</Typography>
				<Typography tag="p" className="step__subtitle">
					Select a location you would like for the resource to occur.
				</Typography>
			</header>
			<section className="step__content">
				<div className="step__content-item">
					<Typography tag="h3" weight="bold">
						Location Type
					</Typography>
					<Typography tag="p" variation="2">
						Select all locations you want to have this resource.
					</Typography>
				</div>

				<div className="step__location-wrapper">
					{availableLocationTypes.map(({ value, label, description, icon }) => (
						<DetailedCheckbox key={value} id={value} value={value} title={label} description={description} icon={icon} {...register('locationTypes', { required: required('Location Type') })} />
					))}
					{!!errors.locationTypes && (
						<div className="error-wrapper">
							<Typography tag="p" variation="2">
								{errors.locationTypes.message}
							</Typography>
						</div>
					)}
				</div>

				<div className="step__content-item">
					<Typography tag="h3" weight="bold">
						Location Details
					</Typography>
					<Typography tag="p" variation="2">
						Enter in more information about your locations.
					</Typography>
				</div>

				<div className="step__selected-locations">
					{!locationTypes || locationTypes.length === 0 ? (
						<Typography tag="p" className="step__empty-value-message">
							No location types selected
						</Typography>
					) : (
						<>
							{locationTypes?.includes('virtual') && (
								<div className="step__location">
									<div className="step__location-icon-wrapper">
										<FontAwesomeIcon icon={['fal', 'camera']} size="lg" />
									</div>
									<div className="step__location-fields-wrapper">
										<Typography tag="h4" weight="bold">
											Virtual
										</Typography>
										<TextArea id="virtualLocationNotes" label="Location Notes" placeholder="Write a message..." error={errors?.virtualLocationNotes} {...register('virtualLocationNotes')} />
									</div>
								</div>
							)}
							{locationTypes?.includes('worksite') && (
								<div className="step__location">
									<div className="step__location-icon-wrapper">
										<FontAwesomeIcon icon={['fal', 'building']} size="lg" />
									</div>
									<div className="step__location-fields-wrapper">
										<Typography tag="h4" weight="bold">
											Worksite Location
										</Typography>
										<Controller
											name="businessLocationId"
											control={control}
											rules={{ required: required('Business Location') }}
											render={({ field: { onChange, value } }) => (
												<Select
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
													label="Business Location"
													placeholder="Select a business location..."
													id="worksite-location-business"
													error={errors?.businessLocationId}
													options={businessOptions}
													value={businessOptions.find((c) => c.id === value) || ''}
													onChange={(val) => onChange(val.id)}
												/>
											)}
										/>
										{selectedBusiness && (
											<div className="step__information-card">
												<div className="icon-wrapper">
													<FontAwesomeIcon icon="building" />
												</div>
												<div className="info-wrapper">
													<Typography tag="h5" weight="bold">
														{selectedBusiness?.name}
													</Typography>
													<div className="info-line">
														<FontAwesomeIcon icon={['fal', 'map-marker']} />
														<Typography tag="p" variation="2">
															{`${selectedBusiness?.address.addressLine1}, ${selectedBusiness?.address.state} ${selectedBusiness?.address.postalCode}`}
														</Typography>
													</div>
												</div>
											</div>
										)}
									</div>
								</div>
							)}
							{locationTypes?.includes('student') && (
								<div className="step__location">
									<div className="step__location-icon-wrapper">
										<FontAwesomeIcon icon={['fal', 'users']} size="lg" />
									</div>
									<div className="step__location-fields-wrapper">
										<Typography tag="h4" weight="bold">
											Student Location
										</Typography>
										<Controller
											name="schoolLocationId"
											control={control}
											rules={{ required: required('Student Location') }}
											render={({ field: { onChange, value } }) => (
												<Select
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
													label="Student Location"
													placeholder="Select a school..."
													id="student-location-school"
													error={errors?.schoolLocationId}
													options={schoolOptions}
													value={schoolOptions.find((c) => c.id === value) || ''}
													onChange={(val) => onChange(val.id)}
												/>
											)}
										/>
										{selectedSchool && (
											<div className="step__information-card">
												<div className="icon-wrapper">
													<FontAwesomeIcon icon="school" />
												</div>
												<div className="info-wrapper">
													<Typography tag="h5" weight="bold">
														{selectedSchool?.name}
													</Typography>
													<div className="info-line">
														<FontAwesomeIcon icon={['fal', 'map-marker']} />
														<Typography tag="p" variation="2">
															{`${selectedSchool?.address.addressLine1}, ${selectedSchool?.address.state} ${selectedSchool?.address.postalCode}`}
														</Typography>
													</div>
													<div className="info-line">
														<FontAwesomeIcon icon={['fal', 'landmark']} />
														<Typography tag="p" variation="2">
															{selectedSchool?.address.city}
														</Typography>
													</div>
													<div className="info-line">
														<FontAwesomeIcon icon={['fal', 'school']} />
														<Typography tag="p" variation="2">
															{selectedSchool?.schoolCode}
														</Typography>
													</div>
												</div>
											</div>
										)}

										<TextArea id="studentLocationNotes" label="Location Notes" placeholder="Write a message..." error={errors?.studentLocationNotes} {...register('studentLocationNotes')} />
									</div>
								</div>
							)}
						</>
					)}
				</div>
			</section>
		</div>
	);
};

StepTwo.propTypes = {
	worksiteLocations: PropTypes.array,
	resourceLocationTypes: PropTypes.array,
};
