import React, { forwardRef, useImperativeHandle, useState, useContext, useCallback } from 'react';
import ReactModal from 'react-modal';
import { useForm } from 'react-hook-form';
import { Button, Checkbox, IconButton, TextArea, TextInput, Typography } from '../../../components';

import { RESOURCE_CATEGORIES, GRADE_LEVELS, CAREER_CLUSTERS, AGE_GROUPS } from '../../../../constants/general.constants';
import { AdminTemplateContext } from '../AdminTemplate';
import { errorHandler } from '../../../services/authService';
import * as S from './RequestCardEditModal.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { required } from '../../../utils/form-default-errors';
import { useDropzone } from 'react-dropzone';
import { uploadResources } from '../../../services/media';
import { createUpdateTemplate } from '../../../services/templates';

const RESOURCE_CATEGORY_OPTIONS = {
	appointment: RESOURCE_CATEGORIES.APPOINTMENT,
	information: RESOURCE_CATEGORIES.INFORMATION,
};

export const RequestCardEditModal = forwardRef((_, ref) => {
	const {
		state: { template },
		dispatch,
	} = useContext(AdminTemplateContext);
	const [isOpen, setIsOpen] = useState(false);
	const [step, setStep] = useState(0);

	const {
		handleSubmit,
		control,
		setValue,
		register,
		getValues,
		watch,
		reset,
		resetField,
		formState: { errors, isSubmitting },
	} = useForm();

	const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
		onDrop: useCallback((acceptedFiles) => acceptedFiles.length > 0 && setValue('coverImage', acceptedFiles[0], { shouldValidate: true }), []),
		multiple: false,
		accept: 'image/png, image/jpg, image/jpeg',
	});

	const renderCoverImage = (image) => {
		if (image) {
			if (image instanceof File) {
				return <img src={URL.createObjectURL(image)} alt="Cover" />;
			} else {
				return <img src={image[1500]} alt="Cover" />;
			}
		}
		return null;
	};

	const coverImage = watch('coverImage');

	const handleOpenModal = () => {
		setValue('templateId', template.id);
		setValue('type', template.type);
		setValue('resourceType', template.resourceType);
		setValue('category', template.categories?.[0]);
		setValue('coverImage', template.coverImageObj);
		setValue('title', template.title);
		setValue('description', template.description);
		setValue('gradeLevels', template.gradeLevels);
		setValue('careerClusters', template.careerClusters);
		setIsOpen(true);
	};

	const handleCloseModal = () => {
		setIsOpen(false);
		setTimeout(() => {
			setStep(0);
			reset();
		}, 200);
	};

	const handleNextStep = () => setStep(step + 1);

	async function uploadCoverImage(file) {
		const formData = new FormData();
		formData.append('file', file);

		const { data } = await uploadResources(formData);

		return data;
	}

	async function asyncCaller(data) {
		const { coverImage, ...rest } = data;
		if (coverImage instanceof File) {
			const coverImageData = await uploadCoverImage(coverImage);
			rest.coverImageObj = coverImageData;
		} else {
			rest.coverImageObj = coverImage;
		}
		rest.ageGroups = [...new Set(rest.gradeLevels.map((level) => AGE_GROUPS.find((group) => group.values.includes(level)).value))];
		const {
			data: {
				result: { template },
			},
		} = await createUpdateTemplate(rest);
		dispatch({ type: 'SET_TEMPLATE', payload: template });
		handleCloseModal();
	}

	const onSubmit = (e) => {
		switch (step) {
			case 0:
			case 1:
				handleSubmit(handleNextStep)(e);
				break;
			default:
				handleSubmit(asyncCaller)(e).catch(errorHandler);
				break;
		}
	};

	useImperativeHandle(
		ref,
		() => ({
			open: handleOpenModal,
			close: handleCloseModal,
		}),
		[handleOpenModal, handleCloseModal]
	);

	return (
		<ReactModal className="template-card-edit-modal" ref={ref} closeTimeoutMS={200} isOpen={isOpen} onRequestClose={handleCloseModal}>
			<S.Form onSubmit={onSubmit}>
				<IconButton className="close-button" type="button" icon={['fal', 'times']} onClick={handleCloseModal} />
				<div className="modal-content">
					<header className="modal-content__header">
						<Typography tag="h2" weight="extrablack" center>
							Edit Template
						</Typography>
						<Typography tag="p" center>
							Please update your template here.
						</Typography>
					</header>

					<section className="modal-content__section">
						{step === 0 && (
							<>
								<Typography tag="h3" weight="extrablack" center>
									Resource Type
								</Typography>
								<div className="radio-wrapper">
									<div className={`radio-field${errors.resourceType ? ' with-error' : ''}`}>
										<input
											className="radio-input"
											type="radio"
											id="appointment"
											value="appointment"
											{...register('resourceType', {
												required: required('Resource Type'),
											})}
										/>
										<FontAwesomeIcon icon={['fal', 'calendar']} size="4x" />
										<Typography tag="h5" weight="bold" center>
											Appointment
										</Typography>
										<Typography tag="p" variation="2" center>
											Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.
										</Typography>
									</div>
									<div className={`radio-field${errors.resourceType ? ' with-error' : ''}`}>
										<input
											className="radio-input"
											type="radio"
											id="information"
											value="information"
											{...register('resourceType', {
												required: required('Template Type'),
											})}
										/>
										<FontAwesomeIcon icon={['fal', 'info-circle']} size="4x" />
										<Typography tag="h5" weight="bold" center>
											Information
										</Typography>
										<Typography tag="p" variation="2" center>
											Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.
										</Typography>
									</div>

									{!!errors.resourceType && (
										<div className="error-wrapper">
											<Typography tag="p" variation="2">
												{errors.resourceType.message}
											</Typography>
										</div>
									)}
								</div>
							</>
						)}
						{step === 1 && (
							<>
								<Typography tag="h3" weight="extrablack" center>
									Category
								</Typography>
								<div className="radio-wrapper">
									{RESOURCE_CATEGORY_OPTIONS[getValues('resourceType')]?.map(({ value, label, icon, description }) => (
										<div key={value} className={`radio-field${errors.category ? ' with-error' : ''}`}>
											<input
												className="radio-input"
												type="radio"
												value={value}
												{...register('category', {
													required: required('Category'),
												})}
											/>
											<FontAwesomeIcon icon={['fal', icon]} size="4x" />
											<Typography tag="h5" weight="bold" center>
												{label}
											</Typography>
											<Typography tag="p" variation="2" center>
												{description}
											</Typography>
										</div>
									))}

									{!!errors.category && (
										<div className="error-wrapper">
											<Typography tag="p" variation="2">
												{errors.category.message}
											</Typography>
										</div>
									)}
								</div>
							</>
						)}
						{step === 2 && (
							<>
								<Typography tag="h3" weight="extrablack" center>
									Template
								</Typography>

								<div className="fields-wrapper">
									<S.HugeImageUploader withError={errors.coverImage}>
										<Typography tag="label" htmlFor="cover-image" weight="semibold">
											Cover Image
										</Typography>
										<S.FileDropZone {...getRootProps()} isDragActive={isDragActive} isDragAccept={isDragAccept} isDragReject={isDragReject}>
											<input id="cover-image" {...register('coverImage', { required: required('Cover Image') })} {...getInputProps({ onChange: (e) => setValue('coverImage', e.target.files[0], { shouldValidate: true }) })} />
											{renderCoverImage(coverImage)}
										</S.FileDropZone>
										{coverImage && (
											<button className="reset-btn" type="button" onClick={() => resetField('coverImage')}>
												<FontAwesomeIcon icon={['fal', 'trash']} />
											</button>
										)}
										{!!errors.coverImage && (
											<div className="error-wrapper">
												<Typography tag="p" variation="2">
													{errors.coverImage.message}
												</Typography>
											</div>
										)}
									</S.HugeImageUploader>

									<TextInput
										label="Title"
										id="title"
										error={errors.title}
										{...register('title', {
											required: required('Title'),
										})}
									/>

									<TextArea
										label="Description"
										id="description"
										error={errors.description}
										{...register('description', {
											required: required('Description'),
										})}
									/>

									<S.CheckboxWrapper>
										<Typography className={`field-label${!!errors.gradeLevels ? ' with-error' : ''}`} tag="p" variation="2" weight="semibold">
											Grade Level(s)
										</Typography>

										{GRADE_LEVELS.map(({ value, label }) => (
											<div key={value} className="checkbox-field">
												<Checkbox id={value} value={value} {...register('gradeLevels', { required: required('Grade Level(s)') })} />
												<label htmlFor={value}>{label}</label>
											</div>
										))}

										{!!errors.gradeLevels && (
											<div className="error-wrapper">
												<Typography tag="p" variation="2">
													{errors.gradeLevels.message}
												</Typography>
											</div>
										)}
									</S.CheckboxWrapper>

									<S.CheckboxWrapper>
										<Typography className={`field-label${!!errors.careerClusters ? ' with-error' : ''}`} tag="p" variation="2" weight="semibold">
											Career Cluster
										</Typography>

										{CAREER_CLUSTERS.map(({ value, label }) => (
											<div key={value} className="checkbox-field">
												<Checkbox id={value} value={value} {...register('careerClusters', { required: required('Career Cluster') })} />
												<label htmlFor={value}>{label}</label>
											</div>
										))}

										{!!errors.careerClusters && (
											<div className="error-wrapper">
												<Typography tag="p" variation="2">
													{errors.careerClusters.message}
												</Typography>
											</div>
										)}
									</S.CheckboxWrapper>
								</div>
							</>
						)}
					</section>

					<div className="modal-footer">
						{step !== 0 && (
							<Button variant="outline" variation="secondary" type="button" onClick={() => setStep((prev) => prev - 1)}>
								<Typography variation="button-medium" weight="bold">
									Back
								</Typography>
							</Button>
						)}
						<div className="flex-1" />
						<Button type="submit">
							<Typography weight="bold" variation="button-medium">
								{isSubmitting ? 'Loading' : step === 2 ? 'Save' : 'Next'}
							</Typography>
						</Button>
					</div>
				</div>
			</S.Form>
		</ReactModal>
	);
});

RequestCardEditModal.displayName = 'RequestCardEditModal';
