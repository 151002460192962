import React, { createContext, useEffect, useReducer, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Button, DetailPageHeader, ErrorComponent, Spinner, Tab, Typography, MessageDialog, AdminNavigation } from '../../components';
import { META_TITLE, ROLES } from '../../../constants/general.constants';
import { OverviewTab } from './OverviewTab';
import { InformationTab } from './InformationTab';
import { GroupsTab } from './GroupsTab';
import { RequestsTab } from './RequestsTab';
import { BookingsTab } from './BookingsTab';
import * as S from './AdminUserDetail.styles';
import { errorHandler } from '../../services/authService';
import { useQuery } from '../../hooks/useQuery';
import { fetchUser, removeUser } from '../../services/users';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const AdminUserDetailContext = createContext();

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_USER':
			return {
				...state,
				user: action.payload,
			};
		default:
			return state;
	}
};

const AdminUserDetail = () => {
	const [pageStatus, setPageStatus] = useState('idle');
	const [state, dispatch] = useReducer(reducer, {});
	const [removeIsOpen, setRemoveIsOpen] = useState(false);
	const { id: userId } = useParams();
	const query = useQuery();

	const history = useHistory();
	const tabsConfig = (user) =>
		[
			{
				id: 1,
				label: 'Overview',
				component: <OverviewTab />,
				active: query.get('tabId') === '1',
			},
			{
				id: 2,
				label: 'Requests',
				component: <RequestsTab />,
				active: query.get('tabId') === '2',
			},
			{
				id: 3,
				label: 'Bookings',
				component: <BookingsTab />,
				active: query.get('tabId') === '3',
			},
			{
				id: 4,
				label: 'Information',
				component: <InformationTab />,
				active: query.get('tabId') === '4',
			},
			user.role === ROLES.TEACHER && {
				id: 5,
				label: 'Groups',
				component: <GroupsTab />,
				active: query.get('tabId') === '5',
			},
		].filter(Boolean);
	const [tabs, setTabs] = useState(tabsConfig({}));

	function handleOpenRemoveModal() {
		setRemoveIsOpen(true);
	}

	function handleCloseRemoveModal() {
		setRemoveIsOpen(false);
	}

	const handleRemoveUser = async () => {
		try {
			await removeUser({ userId: state.user.id });
			history.push('/admin/users');
		} catch (error) {
			errorHandler(error);
		}
	};

	const handleCreateRequest = () => {
		history.push('/admin/create-request', { userId });
	};

	async function initializeData() {
		try {
			setPageStatus('loading');
			const {
				data: { result },
			} = await fetchUser({ userId });
			dispatch({ type: 'SET_USER', payload: result.user });
			setTabs(tabsConfig(result.user));
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}

	function renderContent() {
		switch (pageStatus) {
			case 'loading':
				return <Spinner />;
			case 'success':
				return (
					<AdminUserDetailContext.Provider value={{ state, dispatch }}>
						<DetailPageHeader title={`${state.user.firstName} ${state.user.lastName}`} backButton={{ label: 'Back to Users', onClick: () => history.push('/admin/users') }}>
							<>
								<Button variant="outline" variation="warning" onClick={handleOpenRemoveModal}>
									<Typography tag="span" variation="2" weight="extrablack">
										Remove User
									</Typography>
								</Button>
								<MessageDialog
									isOpen={removeIsOpen}
									onRequestClose={handleCloseRemoveModal}
									title="Remove User?"
									subtitle="Are you sure you want to remove this user?"
									icon={['fal', 'user']}
									actions={[
										{ id: 'back', label: 'Cancel', variant: 'outline', variation: 'default' },
										{ id: 'cancel', label: 'Remove User', variant: 'solid', variation: 'default', onClick: handleRemoveUser },
									]}
								/>
								<S.Button variant="solid" onClick={handleCreateRequest}>
									<Typography tag="span" variation="2" weight="extrablack">
										<FontAwesomeIcon icon={['fal', 'plus']} />
										Create Request
									</Typography>
								</S.Button>
							</>
						</DetailPageHeader>
						<S.Wrapper>
							<Tab tabs={tabs} setTabs={setTabs} />
						</S.Wrapper>
					</AdminUserDetailContext.Provider>
				);
			case 'error':
				return <ErrorComponent />;
			default:
				return null;
		}
	}

	useEffect(() => {
		document.title = `User Details | ${META_TITLE}`;
	}, []);

	useEffect(() => {
		if (pageStatus === 'idle') {
			initializeData();
		}
	}, [pageStatus]);

	return <AdminNavigation>{renderContent()}</AdminNavigation>;
};

export default AdminUserDetail;
