import React from 'react';
import { Menu } from '@headlessui/react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import { Typography } from '../../Typography';
import { IconButton } from '../../IconButton';
import { Notifications } from './Notifications';
import { openMenu, toggleCollapsedMenu } from '../../../app/slices/menu/menuSlice';
import logo from '../../../assets/ncbce-logo-alt.png';
import * as S from './Topbar.styles';

export const Topbar = () => {
	const user = useSelector((state) => state.user.value);
	const dispatch = useDispatch();

	// Create profile link
	let profileLink = '/teacher/profile';
	if (user?.role === 'business') profileLink = '/business/profile';
	else if (user?.role === 'admin' || user?.role === 'super') profileLink = '/admin/profile';

	return (
		<S.Topbar>
			<S.TopbarWrapper>
				<IconButton className="menu-button mobile-only" icon={['fal', 'bars']} onClick={() => dispatch(openMenu())} />
				<IconButton className="menu-button desktop-only" icon={['fal', 'bars']} onClick={() => dispatch(toggleCollapsedMenu())} />
			</S.TopbarWrapper>
			<S.TopbarWrapper fullWidth>
				<S.Picture>
					<img src={logo} alt="logo" />
				</S.Picture>
			</S.TopbarWrapper>
			<S.TopbarWrapper>
				<Notifications />
				<Menu as={S.MenuWrapper}>
					<Menu.Button as={S.AvatarDropdown}>
						<S.Avatar>
							<img src={user?.profileImageObj?.[200]} alt={`${user?.firstName} ${user?.lastName}`} />
						</S.Avatar>
						<Typography tag="p" variation="2" className="avatar-name">
							{`${user?.firstName} ${user?.lastName}`}
						</Typography>
						<FontAwesomeIcon className="angle-down" icon={['fal', 'angle-down']} />
					</Menu.Button>
					<Menu.Items as={S.MenuItems}>
						<Menu.Item>
							{() => (
								<Link className="menu-item" to={profileLink}>
									My Profile
								</Link>
							)}
						</Menu.Item>
						<Menu.Item>
							{() => (
								<Link className="menu-item" to="/logout">
									Logout
								</Link>
							)}
						</Menu.Item>
					</Menu.Items>
				</Menu>
			</S.TopbarWrapper>
		</S.Topbar>
	);
};
