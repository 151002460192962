import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormContext } from 'react-hook-form';

import { GRADE_LEVELS, ROLES } from '../../../../constants/general.constants';
import { fetchGroups } from '../../../services/groups';
import { formatToDayMonth2DigitYear } from '../../../utils/time-formatter';
import { bytesToSize } from '../../../utils/filesize-formatter';
import { Picture, Spinner, Typography } from '../../../components';
import * as S from '../CreateRequest.styles';
import { fetchSchool } from '../../../services/school';

export const StepReview = ({ role }) => {
	const user = useSelector((state) => state.user.value);

	const { getValues } = useFormContext();

	const [pageStatus, setPageStatus] = useState('idle');
	const [selectedGroup, setSelectedGroup] = useState(null);
	const [selectedSchool, setSelectedSchool] = useState(user.schools.find(({ id }) => id === getValues('schoolLocationId')));

	const shouldShowContentWrapper = (section) => {
		switch (section) {
			case 'overview':
				return true;
			case 'files':
				return getValues('publicFiles')?.length > 0 || getValues('privateFiles')?.length > 0;
			case 'locations':
				return getValues('type') === 'appointment' && getValues('locationTypes')?.length > 0;
			case 'links':
				return getValues('type') === 'information' && getValues('links')?.length > 0;
			case 'group':
				return getValues('groupId') != null && getValues('groupId') !== '';
			case 'availability':
				return getValues('type') === 'appointment';
			default:
				return;
		}
	};

	const renderGroupTemplate = () => {
		switch (pageStatus) {
			case 'loading':
				return <Spinner />;
			case 'success':
				return (
					<div className="review__step-item review__group">
						<Typography tag="h3" weight="bold">
							Group
						</Typography>

						<div className="review__info-item">
							<S.InformationCard>
								<div className="icon-wrapper">
									<FontAwesomeIcon icon="users" />
								</div>
								<div className="info-wrapper">
									<Typography tag="h5" weight="bold">
										{selectedGroup?.name}
									</Typography>
									<div className="info-line">
										<FontAwesomeIcon icon={['fal', 'users']} />
										<Typography tag="p" variation="2">
											{selectedGroup?.totalStudents} Students
										</Typography>
									</div>
									<div className="info-line">
										<FontAwesomeIcon icon={['fal', 'users-class']} />
										<Typography tag="p" variation="2">
											{GRADE_LEVELS.find((grade) => grade.value === selectedGroup?.gradeLevels[0])?.label}
										</Typography>
									</div>
									<div className="info-line">
										<FontAwesomeIcon icon={['fal', 'chalkboard']} />
										<Typography tag="p" variation="2">
											{selectedGroup?.subject}
										</Typography>
									</div>
									<div className="info-line">
										<FontAwesomeIcon icon={['fal', 'book']} />
										<Typography tag="p" variation="2">
											{selectedGroup?.classCode}
										</Typography>
									</div>
								</div>
							</S.InformationCard>
						</div>
					</div>
				);
			default:
				return null;
		}
	};

	async function initializeData() {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchGroups();
			setSelectedGroup(result.groups.find(({ groupId }) => groupId === getValues('groupId')));
			if (user.role === ROLES.ADMIN || user.role === ROLES.SUPER) {
				const schoolId = getValues('schoolLocationId');
				const {
					data: { result },
				} = await fetchSchool({ schoolId });
				setSelectedSchool(result.school);
			}
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}

	const renderCoverImage = (image) => {
		if (image) {
			if (image instanceof File) {
				return <Picture className="review__cover-image" src={URL.createObjectURL(image)} aspectRatio="3/2" />;
			} else {
				return <Picture className="review__cover-image" src={image[1500]} aspectRatio="3/2" />;
			}
		}
		return null;
	};

	useEffect(() => {
		if (pageStatus === 'idle') {
			initializeData();
		}
	}, [pageStatus]);

	return (
		<S.FormPanel>
			<Typography tag="h2" weight="extrablack" center>
				Review
			</Typography>
			<Typography tag="p" center>
				Please look over the request you are about to create.
			</Typography>

			<div className="fields-wrapper">
				{shouldShowContentWrapper('overview') && (
					<div className="review__step-item review__overview">
						<Typography tag="h3" weight="bold">
							Overview
						</Typography>

						{renderCoverImage(getValues('coverImage'))}

						<div className="review__info-item">
							<Typography tag="h6" weight="semibold">
								Resource Title
							</Typography>
							<Typography tag="p">{getValues('title')}</Typography>
						</div>

						<div className="review__info-item">
							<Typography tag="h6" weight="semibold">
								Description
							</Typography>
							<Typography tag="p">{getValues('description')}</Typography>
						</div>

						<div className="review__info-item review__info-item--with-badge">
							<Typography tag="h6" weight="semibold">
								Career Cluster
							</Typography>
							<div className="badge-wrapper">
								{getValues('careerClusters').map((value) => (
									<div className="small-badge" key={value}>
										{value}
									</div>
								))}
							</div>
						</div>
					</div>
				)}

				{shouldShowContentWrapper('files') && (
					<div className="review__step-item review__files">
						<Typography tag="h3" weight="bold">
							Files
						</Typography>

						{getValues('publicFiles')?.length > 0 && (
							<>
								<Typography tag="h4" weight="bold">
									Public Files
								</Typography>

								{getValues('publicFiles')?.map((file, index) => (
									<div className="review__file-item" key={file.name}>
										<Typography tag="h5" weight="bold">
											File {index + 1}
										</Typography>

										<div className="review__info-item">
											<Typography tag="h6" weight="semibold">
												File
											</Typography>
											<div className="review__file-detail">
												<FontAwesomeIcon icon={['fal', 'file']} size="lg" />
												<div>
													<Typography tag="p">{file.name}</Typography>
													<Typography tag="p" variation="2">
														{bytesToSize(file.size)}
													</Typography>
												</div>
											</div>
										</div>

										<div className="review__info-item">
											<Typography tag="h6" weight="semibold">
												File Name
											</Typography>
											<Typography tag="p">{file.title}</Typography>
										</div>

										<div className="review__info-item">
											<Typography tag="h6" weight="semibold">
												File Description
											</Typography>
											<Typography tag="p">{file.description}</Typography>
										</div>
									</div>
								))}
							</>
						)}

						{getValues('privateFiles')?.length > 0 && (
							<>
								<Typography tag="h4" weight="bold">
									Private Files
								</Typography>

								{getValues('privateFiles')?.map((file, index) => (
									<div className="review__file-item" key={file.name}>
										<Typography tag="h5" weight="bold">
											File {index + 1}
										</Typography>

										<div className="review__info-item">
											<Typography tag="h6" weight="semibold">
												File
											</Typography>
											<div className="review__file-detail">
												<FontAwesomeIcon icon={['fal', 'file']} size="lg" />
												<div>
													<Typography tag="p">{file.name}</Typography>
													<Typography tag="p" variation="2">
														{bytesToSize(file.size)}
													</Typography>
												</div>
											</div>
										</div>

										<div className="review__info-item">
											<Typography tag="h6" weight="semibold">
												File Name
											</Typography>
											<Typography tag="p">{file.title}</Typography>
										</div>

										<div className="review__info-item">
											<Typography tag="h6" weight="semibold">
												File Description
											</Typography>
											<Typography tag="p">{file.description}</Typography>
										</div>
									</div>
								))}
							</>
						)}
					</div>
				)}

				{shouldShowContentWrapper('locations') && (
					<div className="review__step-item review__locations">
						<Typography tag="h3" weight="bold">
							Location
						</Typography>

						{getValues('locationTypes')?.includes('virtual') && (
							<div className="review__location-item">
								<div className="location-icon-wrapper">
									<FontAwesomeIcon icon={['fal', 'camera']} size="lg" />
								</div>
								<div className="location-info-wrapper">
									<Typography tag="h4" weight="bold">
										Virtual
									</Typography>
									{getValues('virtualLocationNotes') && (
										<div className="review__info-item">
											<Typography tag="h6" weight="semibold">
												Notes
											</Typography>
											<Typography tag="p">{getValues('virtualLocationNotes')}</Typography>
										</div>
									)}
								</div>
							</div>
						)}

						{getValues('locationTypes')?.includes('worksite') && (
							<div className="review__location-item">
								<div className="location-icon-wrapper">
									<FontAwesomeIcon icon={['fal', 'building']} size="lg" />
								</div>
								<div className="location-info-wrapper">
									<Typography tag="h4" weight="bold">
										Worksite Location
									</Typography>

									<div className="review__info-item">
										<Typography tag="h6" weight="semibold">
											Maximum Travel Distance
										</Typography>
										<Typography tag="p">{getValues('worksiteMaxTravelDistance')} mi</Typography>
									</div>
								</div>
							</div>
						)}

						{getValues('locationTypes')?.includes('student') && (
							<div className="review__location-item">
								<div className="location-icon-wrapper">
									<FontAwesomeIcon icon={['fal', 'building']} size="lg" />
								</div>
								<div className="location-info-wrapper">
									<Typography tag="h4" weight="bold">
										Student Location
									</Typography>

									<div className="review__info-item custom-margin">
										<S.InformationCard>
											<div className="icon-wrapper">
												<FontAwesomeIcon icon="school" />
											</div>
											<div className="info-wrapper">
												<Typography tag="h5" weight="bold">
													{selectedSchool?.name}
												</Typography>
												<div className="info-line">
													<FontAwesomeIcon icon={['fal', 'map-marker']} />
													<Typography tag="p" variation="2">
														{`${selectedSchool?.address.addressLine1}, ${selectedSchool?.address.state} ${selectedSchool?.address.postalCode}`}
													</Typography>
												</div>
												<div className="info-line">
													<FontAwesomeIcon icon={['fal', 'landmark']} />
													<Typography tag="p" variation="2">
														{selectedSchool?.address.city}
													</Typography>
												</div>
												<div className="info-line">
													<FontAwesomeIcon icon={['fal', 'school']} />
													<Typography tag="p" variation="2">
														{selectedSchool?.schoolCode}
													</Typography>
												</div>
											</div>
										</S.InformationCard>
									</div>

									{getValues('studentLocationNotes') && (
										<div className="review__info-item">
											<Typography tag="h6" weight="semibold">
												Notes
											</Typography>
											<Typography tag="p">{getValues('studentLocationNotes')}</Typography>
										</div>
									)}
								</div>
							</div>
						)}
					</div>
				)}

				{shouldShowContentWrapper('availability') && (
					<div className="review__step-item review__availability">
						<Typography tag="h3" weight="bold">
							Availability
						</Typography>

						<div className="review__info-item">
							<Typography tag="h6" weight="semibold">
								Start Date
							</Typography>
							<Typography tag="p">{formatToDayMonth2DigitYear(getValues('startDate'))}</Typography>
						</div>

						<div className="review__info-item">
							<Typography tag="h6" weight="semibold">
								Is this ongoing?
							</Typography>
							<Typography className="capitalize" tag="p">
								{getValues('isOngoing')}
							</Typography>
						</div>

						{getValues('isOngoing') === 'no' && (
							<div className="review__info-item">
								<Typography tag="h6" weight="semibold">
									End Date
								</Typography>
								<Typography tag="p">{formatToDayMonth2DigitYear(getValues('endDate'))}</Typography>
							</div>
						)}

						<div className="review__info-item">
							<Typography tag="h6" weight="semibold">
								Days
							</Typography>
							<Typography className="capitalize" tag="p">
								{getValues('days')?.join(', ')}
							</Typography>
						</div>
					</div>
				)}

				{shouldShowContentWrapper('links') && (
					<div className="review__step-item review__links">
						<Typography tag="h3" weight="bold">
							Links
						</Typography>

						{getValues('links')?.map((link) => (
							<div className="review__file-item" key={link.url}>
								<Typography tag="h5" weight="bold">
									{link.title}
								</Typography>

								<div className="review__info-item">
									<Typography tag="h6" weight="semibold">
										Link
									</Typography>
									<Typography tag="p">{link.url}</Typography>
								</div>

								<div className="review__info-item">
									<Typography tag="h6" weight="semibold">
										Description
									</Typography>
									<Typography tag="p">{link.description}</Typography>
								</div>
							</div>
						))}
					</div>
				)}

				{shouldShowContentWrapper('group') && renderGroupTemplate()}
			</div>
		</S.FormPanel>
	);
};

StepReview.propTypes = {
	role: PropTypes.string,
};
