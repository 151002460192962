import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { fetchMessages } from '../../../../services/messages';
import { cancelRequestResponse } from '../../../../services/requests';
import { useChat } from '../../../../hooks';
import { Button, IconButton, Picture, Typography, Badge, Chat, MessageDialog } from '../../../../components';
import { RequestsContext, BADGE_STATUS } from '../RespondedRequestsTab';
import * as S from './RequestChat.styles';
import { errorHandler } from '../../../../services/authService';

export const RequestChat = () => {
	const { state, dispatch } = useContext(RequestsContext);
	const { selectedConversation } = state;
	const { messages, setMessages } = useChat(selectedConversation.id);
	const [pageStatus, setPageStatus] = useState('loading');
	const [responseCancelIsOpen, setResponseCancelIsOpen] = useState(false);
	const history = useHistory();

	function handleOpenResponseCancelModal() {
		setResponseCancelIsOpen(true);
	}

	function handleCloseResponseCancelModal() {
		setResponseCancelIsOpen(false);
	}

	const handleCancelResponse = async () => {
		setResponseCancelIsOpen(false);
		try {
			const {
				data: { result },
			} = await cancelRequestResponse({ responseId: selectedConversation.id });
			dispatch({
				type: 'SET_SELECTED_CONVERSATION',
				payload: {
					id: result.response.id,
					status: result.response.status,
					request: result.request,
					business: result.request.creator.business,
					creator: result.request.creator,
				},
			});
		} catch (error) {
			errorHandler(error);
		}
	};

	async function initializeData() {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchMessages({ page: 0, displayLimit: 100000, requestId: selectedConversation.request.id, responseId: selectedConversation.id, filterOptions: { received: true, sent: true } });
			setMessages(result.messages);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}

	useEffect(() => {
		// Load initial data
		initializeData();
	}, [selectedConversation]);

	return (
		<S.RequestChatWrapper>
			<S.ChatHeader>
				<S.BackButtonWrapper>
					<Button variant="text" size="small" onClick={() => dispatch({ type: 'CLEAR_SELECTED_CONVERSATION' })}>
						<FontAwesomeIcon icon={['fal', 'arrow-left']} />
						<Typography className="button-label" variation="button-small" weight="bold">
							Back to Messages
						</Typography>
					</Button>
				</S.BackButtonWrapper>
				<S.InfoWrapper>
					<Picture className="avatar-image" src={selectedConversation.business ? selectedConversation.business.logoObj['200'] : selectedConversation.creator.profileImageObj['200']} aspectRatio="1/1" alt="profile" />
					<div className="info-block">
						<Typography tag="h3" weight="bold">
							{selectedConversation.business ? selectedConversation.business.name : `${selectedConversation.creator.firstName} ${selectedConversation.creator.lastName}`}
						</Typography>
						<Typography tag="p" variation="2">
							{selectedConversation.request.title}
						</Typography>
						{selectedConversation.status !== 'pending' && (
							<Badge small="small" type={BADGE_STATUS[selectedConversation.status].color} variant="outlined">
								{BADGE_STATUS[selectedConversation.status].label}
							</Badge>
						)}
					</div>
					{selectedConversation.business && (
						<IconButton
							className="info-button"
							icon={['fal', 'info-circle']}
							onClick={() => {
								history.push({ pathname: `/teacher/directory/business/${selectedConversation.business.id}`, state: { backUrl: window.location.pathname, backTitle: 'Back to Messages' } });
							}}
						/>
					)}
				</S.InfoWrapper>
				{selectedConversation.status === 'pending' && (
					<>
						<S.ButtonWrapper>
							<Button
								variant="outline"
								size="small"
								onClick={() => {
									handleOpenResponseCancelModal();
								}}
							>
								<Typography className="button-label" variation="button-small" weight="bold">
									Cancel
								</Typography>
							</Button>
						</S.ButtonWrapper>
						<MessageDialog
							isOpen={responseCancelIsOpen}
							onRequestClose={handleCloseResponseCancelModal}
							title="Cancel Request Response?"
							subtitle="Are you sure you want to cancel this request response? The requester will be notified."
							icon={['fal', 'file-plus']}
							actions={[
								{ id: 'back', label: 'Nevermind', variant: 'outline', variation: 'default' },
								{ id: 'duplicate', label: 'Cancel Response', variant: 'solid', variation: 'default', onClick: handleCancelResponse },
							]}
						/>
					</>
				)}
			</S.ChatHeader>
			<Chat
				loading={pageStatus === 'loading'}
				messages={messages}
				setMessages={setMessages}
				sendProps={
					selectedConversation.business
						? { requestId: selectedConversation.request.id, responseId: selectedConversation.id, businessId: selectedConversation.business.id }
						: { requestId: selectedConversation.request.id, responseId: selectedConversation.id, recipientId: selectedConversation.creator.id }
				}
			/>
		</S.RequestChatWrapper>
	);
};
