export const buildAddressString = (address) => {
	// Get parameters
	const { name, addressLine1, addressLine2, city, state, postalCode } = address;

	// Build address from data
	let addressString = '';
	if (name && name !== '' && name !== '-') {
		addressString += `${name}, `;
	}
	if (addressLine1 && addressLine1 !== '' && addressLine1 !== '-') {
		addressString += `${addressLine1}, `;
	}
	if (addressLine2 && addressLine2 !== '' && addressLine2 !== '-') {
		addressString += `${addressLine2}, `;
	}
	if (city && city !== '') {
		addressString += `${city}, `;
	}
	if (state && state !== '') {
		addressString += `${state}, `;
	}
	if (postalCode && postalCode !== '') {
		addressString += `${postalCode}, `;
	}

	// Clean address string
	return addressString.trim().replace(/(^,)|(,$)/g, '');
};
