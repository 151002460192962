import styled from 'styled-components';
import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1rem 1rem 2rem;
`;

export const Subheader = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid ${colors.neutralForm};
	padding-bottom: 0.75rem;
	margin-bottom: 0.75rem;

	color: ${colors.neutralText};

	.p-container {
		display: flex;
		align-items: center;
		gap: 0.5rem;
	}

	strong {
		margin-right: 0.25rem;
	}

	button {
		margin: 0 0 0 1.5rem;
	}
`;

export const ToggleLine = styled.div`
	display: inline-flex;
	gap: 0.5rem;
	align-items: center;

	& > span {
		color: black;
	}

	& > button {
		margin: 0;
		color: ${colors.brandPrimary};
	}
`;

export const LoadMoreWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 1.75rem 0 0;
	border: none;
`;
