import styled from 'styled-components';

export const Wrapper = styled.div`
	display: grid;
	gap: 1.25rem;
	grid-template-columns: 1fr;
	padding: 1.25rem 1rem 2.5rem;

	.row-wrapper {
		display: flex;
		flex-direction: column;

		& > * + * {
			margin-top: 1.25rem;
		}
	}

	@media screen and (min-width: 992px) {
		grid-template-columns: repeat(2, 1fr);
	}
`;
