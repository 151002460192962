import styled from 'styled-components';
import { Link } from 'react-router-dom';

import colors from '../../../styles/colors';

export const List = styled.div``;

export const ListItem = styled(Link)`
	text-decoration: none;
	color: black;
	display: flex;
	margin-top: 0.625rem;
	padding-bottom: 0.625rem;
	border-bottom: 1px solid ${colors.neutralForm};

	.list-item {
		&__thumbnail {
			min-width: 2.625rem;
			width: 2.625rem;
			height: 2.625rem;
			border-radius: 9999px;
			margin-right: 0.625rem;
		}
		&__info-wrapper {
			margin-top: 0.125rem;

			&__small-text {
				color: ${colors.neutralText};
				margin-bottom: 0.375rem;
			}
		}
	}
`;
