import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Card, Typography } from '../../../../components';
import * as S from './BookingCard.styles';

import { BOOKING_STATUSES, GRADE_LEVELS } from '../../../../../constants/general.constants';

export const BookingCard = ({ role, booking }) => {
	return (
		<Card title={booking.status === BOOKING_STATUSES.PENDING ? 'Booking' : 'Scheduled'} transparentHeaderBorder={true}>
			<S.Wrapper>
				<Typography tag="h4" weight="bold">
					Date
				</Typography>
				<div className="grid-line">
					<div className="grid-line__info-item">
						<Typography tag="h6" weight="semibold">
							Date Range
						</Typography>
						<Typography tag="p">{booking.status === BOOKING_STATUSES.PENDING ? booking?.availabilityDateRange : booking?.scheduleDateRange}</Typography>
					</div>
					<div className="grid-line__info-item">
						<Typography tag="h6" weight="semibold">
							Availability Notes
						</Typography>
						<Typography tag="p">{booking.status === BOOKING_STATUSES.PENDING ? booking?.availabilityNotesOutbound : booking?.notesInbound}</Typography>
					</div>
				</div>
				{Object.keys(booking?.schoolLocation)?.length > 0 && (
					<>
						<Typography tag="h4" weight="bold">
							Location
						</Typography>
						<div className="location-title">
							<FontAwesomeIcon icon={['fal', 'users']} />
							<Typography tag="p" weight="semibold">
								Student Location
							</Typography>
						</div>
						<div className="grid-line">
							<div className="grid-line__info-item">
								<Typography tag="h6" weight="semibold">
									Location Name
								</Typography>
								<Typography tag="p">{booking?.schoolLocation?.name}</Typography>
							</div>
							<div className="grid-line__info-item">
								<Typography tag="h6" weight="semibold">
									Address
								</Typography>
								<Typography tag="p">{`${booking?.schoolLocation?.address?.addressLine1}, ${booking?.schoolLocation?.address?.state} ${booking?.schoolLocation?.address?.postalCode}`}</Typography>
							</div>
						</div>
					</>
				)}
				<Typography tag="h4" weight="bold">
					{booking?.creator?.role === 'teacher' ? 'Booking Teacher' : 'Booking User'}
				</Typography>
				<div className="location-title">
					<Typography tag="h6" weight="semibold">
						{booking?.creator?.firstName} {booking?.creator?.lastName}
					</Typography>
				</div>
				<div className="grid-line">
					<div className="grid-line__info-item">
						<Typography tag="h6" weight="semibold">
							Email
						</Typography>
						<Typography tag="p">{booking?.creator?.email}</Typography>
					</div>
					<div className="grid-line__info-item">
						<Typography tag="h6" weight="semibold">
							{booking?.creator?.role === 'teacher' ? 'School' : 'Business'}
						</Typography>
						<Typography tag="p">{booking?.creator?.role === 'teacher' ? booking?.creator?.schoolName : booking?.creator?.businessName}</Typography>
					</div>
				</div>
				<Typography tag="h4" weight="bold">
					Group
				</Typography>
				<div className="location-title">
					<Typography tag="h6" weight="semibold">
						{booking?.group?.name}
					</Typography>
				</div>
				<div className="grid-line">
					<div className="grid-line__info-item">
						<Typography tag="h6" weight="semibold">
							Grade Level
						</Typography>
						<Typography tag="p">
							{GRADE_LEVELS.filter((gradeLevel) => booking?.group?.gradeLevels?.includes(gradeLevel.value))
								.map((gradeLevel) => gradeLevel.label)
								.join(', ')}
						</Typography>
					</div>
					<div className="grid-line__info-item">
						<Typography tag="h6" weight="semibold">
							# of Students
						</Typography>
						<Typography tag="p">{booking?.group?.totalStudents}</Typography>
					</div>
					<div className="grid-line__info-item">
						<Typography tag="h6" weight="semibold">
							Subject
						</Typography>
						<Typography tag="p">{booking?.group?.subject}</Typography>
					</div>
					<div className="grid-line__info-item">
						<Typography tag="h6" weight="semibold">
							Course Code
						</Typography>
						<Typography tag="p">{booking?.group?.classCode}</Typography>
					</div>
					<div className="grid-line__info-item">
						<Typography tag="h6" weight="semibold">
							Age Range
						</Typography>
						<Typography tag="p">{booking?.group?.ageRange}</Typography>
					</div>
				</div>
				<Typography tag="h4" weight="bold">
					Notes
				</Typography>
				<div className="grid-line">
					<div className="grid-line__info-item">
						<Typography tag="h6" weight="semibold">
							Additional Notes
						</Typography>
						<Typography tag="p">{booking?.notesOutbound}</Typography>
					</div>
					{booking.status === BOOKING_STATUSES.COMPLETED && (
						<div className="grid-line__info-item">
							<Typography tag="h6" weight="semibold">
								Notes Inbound
							</Typography>
							<Typography tag="p">{booking?.notesInbound}</Typography>
						</div>
					)}
				</div>
			</S.Wrapper>
		</Card>
	);
};

BookingCard.propTypes = {
	booking: PropTypes.shape(),
	role: PropTypes.string,
};
