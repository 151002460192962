import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import colors from '../../../styles/colors';

export const Topbar = styled.header`
	display: flex;
	background-color: white;
	border-bottom: 1px solid ${colors.neutralForm};
	padding: 0.7rem 0.5rem;
`;

export const MobileNavigation = styled.div`
	display: flex;

	@media screen and (min-width: 910px) {
		display: none;
	}
`;

export const DesktopNavigation = styled.div`
	display: none;

	@media screen and (min-width: 910px) {
		display: flex;
	}
`;

export const NavigationLink = styled(Link)`
	text-decoration: none;

	button {
		margin-left: 1rem;
	}
	.text-link {
		padding: 0.5625rem 0.6625rem;
		color: ${colors.neutralText};
	}
	.text-link:hover {
		color: ${colors.brandPrimary};
		opacity: 1;
	}
`;

export const TopbarWrapper = styled.div`
	display: flex;
	align-items: center;
	padding: 0 0.5rem;

	@media screen and (max-width: 767px) {
		flex: 0.5;
	}

	.mobile-only {
		display: block;
	}
	.desktop-only {
		display: none;
		margin-left: 0;
	}

	@media screen and (min-width: 768px) {
		.mobile-only {
			display: none;
		}
		.desktop-only {
			display: block;
		}
	}

	${({ fullWidth }) =>
		fullWidth &&
		css`
			justify-content: center;
			flex: 1;
		`}

	& > * + * {
		margin-left: 1rem;
	}

	&:last-child {
		justify-content: flex-end;
	}

	.menu-button {
		color: ${colors.brandPrimary};
	}
`;

export const Picture = styled.picture`
	display: flex;
	max-width: 8rem;

	img {
		width: 100%;
		object-fit: contain;
	}
`;
