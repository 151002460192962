import styled from 'styled-components';

import colors from '../../../styles/colors';

export const Wrapper = styled.div`
	display: grid;
	gap: 1rem;
`;

export const GridWrapper = styled.div`
	display: grid;
	gap: 1rem;
	grid-template-columns: 1fr;

	@media screen and (min-width: 992px) {
		grid-template-columns: repeat(12, 1fr);

		.col4 {
			grid-column: span 4;
		}
		.col6 {
			grid-column: span 6;
		}
		.col12 {
			grid-column: span 12;
		}
	}
`;

export const CardWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	gap: 1rem;

	@media screen and (min-width: 600px) {
		flex-direction: row;

		.metric-card {
			flex: 1;
		}
	}
`;
