import React from 'react';
import { useFormContext } from 'react-hook-form';

import * as S from '../../CreateResource.styles';
import { TextArea, TextInput, Typography } from '../../../../components';
import { required, urlOnly } from '../../../../utils/form-default-errors';

export const EventLinkStep = () => {
	const {
		register,
		control,
		formState: { errors },
	} = useFormContext();

	return (
		<>
			<Typography tag="h2" weight="extrablack" center>
				Event Link
			</Typography>
			<Typography tag="p" center>
				Add the link where people can access and sign up for the event.
			</Typography>

			<div className="fields-wrapper">
				<TextInput
					label="Link"
					id="link-url"
					error={errors?.eventLink}
					{...register('eventLink', {
						required: required('Link'),
						pattern: urlOnly('Link'),
					})}
				/>
				<TextArea
					label="Link Notes"
					id={'link-notes'}
					error={errors?.eventLinkNotes}
					{...register('eventLinkNotes', {
						required: required('Link Notes'),
					})}
				/>
			</div>
		</>
	);
};
