import styled from 'styled-components';

import colors from '../../../../styles/colors';

export const Wrapper = styled.div`
	background-color: white;
	border-top: 1px solid ${colors.neutralForm};
	margin: -1rem -1rem 0;
	padding: 1rem;

	.filter-wrapper {
		display: grid;
		grid-template-columns: 1fr;
		gap: 0.75rem;
		margin-bottom: -1rem;
		margin-top: -1rem;
		padding-bottom: 0.75rem;
		border-bottom: 1px solid ${colors.neutralForm};

		&__grouped-header-wrapper {
			&__back-button {
				margin: 0 -0.5rem 0.25rem;
			}

			&__inner-wrapper {
				display: flex;
				justify-content: space-between;
			}

			&__flex-column {
				display: flex;
				flex-direction: column;
			}

			&__count-wrapper {
				display: flex;
				align-items: center;
				margin-top: 0.25rem;
				color: ${colors.neutralButton};
			}

			&__count-text {
				margin-left: 0.25rem;
			}

			&__badge-wrapper {
			}
		}

		&__view-messages {
			display: flex;
			align-items: center;
			flex: 1;
			max-width: 25.5rem;

			.field-label {
				white-space: nowrap;
				color: ${colors.neutralText};
				margin-right: 0.5rem;
			}

			.select-field {
				flex: 1;
			}
		}

		&__badge-wrapper {
			display: flex;
			flex-wrap: wrap;
			background-color: white;
			padding: 1rem 0 0.75rem;
			border-bottom: 1px solid ${colors.neutralForm};
			border-left: none;
			border-right: none;

			.badge-button {
				appearance: none;
				background-color: transparent;
				border: none;
				padding: 0.125rem 0.9375rem;
				color: ${colors.neutralText};
				border-radius: 6.25rem;
				cursor: pointer;

				&.selected {
					font-weight: 600;
					color: white;
					background-color: ${colors.brandPrimary};
				}
			}
		}

		&__search-input-wrapper {
			max-width: 25.5rem;
		}
	}
`;
