import React, { useState } from 'react';

import * as S from './PendingTab.styles';
import { Tab } from '../../../components';
import { RespondedTab } from './RespondedTab';
import { PostedTab } from './PostedTab';

export const PendingTab = () => {
	const [tabs, setTabs] = useState([
		{
			id: 1,
			label: 'Responded',
			component: <RespondedTab />,
		},
		{
			id: 2,
			label: 'Posted',
			component: <PostedTab />,
		},
	]);

	return (
		<S.Wrapper>
			<Tab tabs={tabs} setTabs={setTabs} transparent variation="pill" />
		</S.Wrapper>
	);
};

PendingTab.propTypes = {};
