import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IconButton } from '../IconButton';
import * as S from './TableComponents.styles';

const CLASS_STATUS_LABELS = {
	true: { label: 'Class', icon: ['fal', 'check'], color: 'success' },
	false: { label: 'Not a Class', icon: ['fal', 'times'], color: 'danger' },
};

const ClassStatusCell = ({ value }) => (
	<S.ClassStatusCell className={CLASS_STATUS_LABELS[value.toString()].color}>
		<FontAwesomeIcon className="icon" icon={CLASS_STATUS_LABELS[value.toString()].icon} />
		{CLASS_STATUS_LABELS[value.toString()].label}
	</S.ClassStatusCell>
);
ClassStatusCell.propTypes = {
	value: PropTypes.oneOf([true, false]),
};

const RemoveButtonCell = ({ onClick, ...rest }) => {
	const handleOnClick = (e) => {
		e.stopPropagation();
		onClick();
	};
	return <S.RemoveButtonCell icon={['fal', 'minus-circle']} size={1} onClick={handleOnClick} />;
};
RemoveButtonCell.propTypes = {
	onClick: PropTypes.func,
};

export const TableWrapper = S.TableWrapper;
export const ClickableRow = S.ClickableRow;
export const Cells = {
	ClassStatusCell,
	RemoveButtonCell,
};
