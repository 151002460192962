import styled from 'styled-components';

export const Wrapper = styled.div`
	display: grid;
	gap: 1rem;
`;

export const LoadMoreWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 1.75rem 0 0;
	border: none;
`;
