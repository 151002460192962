import styled from 'styled-components';

import colors from '../../../../styles/colors';

export const RequestChatWrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hidden;
	background-color: white;
	border: 1px solid ${colors.neutralForm};
	border-bottom: none;
	border-right: none;
`;

export const ChatHeader = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	background-color: white;
	margin: 0;
	padding: 1rem;
	border-bottom: 1px solid ${colors.neutralForm};
`;

export const BackButtonWrapper = styled.div`
	margin: 0 -0.5rem 0.5rem;

	@media (min-width: 992px) {
		display: none;
	}
`;

export const InfoWrapper = styled.div`
	display: flex;
	align-items: center;

	.avatar-image {
		width: 3.375rem;
		height: 3.375rem;
		border-radius: 9999px;
		margin-right: 0.5rem;
	}

	.info-block {
		flex: 1;
	}

	.info-button {
		color: ${colors.brandPrimary};
	}

	p {
		color: ${colors.neutralText};
		margin-bottom: 5px;
	}
`;

export const ButtonWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 1rem;
	margin-top: 1rem;
`;

export const ChatBody = styled.div`
	flex: 1;
	position: relative;
	background-color: ${colors.neutralBackground};

	.chat {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		inset: 0;
		padding: 1.25rem;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		overflow-x: hidden;
		overflow-y: auto;

		&__last-child {
			visibility: hidden;
			margin: 0 !important;
		}

		&__bubble {
			display: flex;
			width: 100%;
			max-width: 75%;

			.bubble-info-wrapper {
				display: flex;
				flex-direction: column;
				margin-right: 0.5rem;

				&__avatar {
					width: 2.5rem;
					height: 2.5rem;
					border-radius: 9999px;
				}
			}

			.bubble-message-wrapper {
				display: flex;
				flex-direction: column;

				&__name {
					margin-bottom: 0.25rem;
					color: ${colors.neutralText};
				}

				&__bubble {
					padding: 0.75rem 0.625rem;
					border-radius: 0.375rem;
					border: 1px solid transparent;
				}
			}

			.bubble-file-wrapper {
				display: flex;
				flex-direction: column;
				width: 100%;

				&__image {
					border: 1px solid ${colors.neutralForm};
					border-radius: 0.375rem;
				}
			}

			&--self {
				align-self: flex-end;
				justify-content: flex-end;

				.bubble-message-wrapper {
					align-self: flex-end;

					&__bubble {
						background-color: ${colors.brandPrimary};
						border-color: ${colors.brandPrimary};
						color: white;
						border-top-right-radius: 0;
					}
				}
			}

			&--other {
				align-self: flex-start;
				justify-content: flex-start;

				.bubble-message-wrapper {
					align-self: flex-start;

					&__bubble {
						background-color: white;
						border-color: ${colors.neutralForm};
						color: black;
						border-top-left-radius: 0;
					}
				}
			}
		}

		& > * + * {
			margin-top: 1rem;
		}
	}
`;

export const ChatFooter = styled.div`
	display: flex;
	align-items: center;
	padding: 1.25rem;
	border: 1px solid ${colors.neutralForm};
	border-left: none;
	background-color: white;

	.chat__footer {
		&__file-button {
			color: ${colors.brandPrimary};
		}

		&__input {
			flex: 1;
			margin: 0 1rem;
			appearance: none;
			border: 1px solid ${colors.neutralForm};
			border-radius: 0.375rem;
			font-size: 1rem;
			padding: 0.5rem 0.75rem;
			background-color: ${colors.neutralBackground};
		}

		&__send-button {
			height: 2.375rem;
		}
	}
`;
