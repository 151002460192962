import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { OverviewCard } from './OverviewCard';
import { LocationCard } from './LocationCard';
import { AvailabilityCard } from './AvailabilityCard';
import { FilesCard } from './FilesCard';
import { LinksCard } from './LinksCard';
import { GroupCard } from './GroupCard';
import { TeacherCard } from './TeacherCard';
import { AppointmentDetailsContext } from '../RequestsAppointmentDetails';
import * as S from './OverviewTab.styles';

export const formatPayloadBeforeUpdate = (request, payload) => ({
	...request,
	requestId: request?.id,
	startDate: request?.availabilityStartDate,
	endDate: request?.availabilityEndDate,
	days: request?.availabilityDays,
	isOngoing: request?.availabilityIsOngoing,
	groupId: request?.group?.groupId,
	schoolLocationId: request?.schoolLocation?.id,
	...payload,
});

export const OverviewTab = ({ role }) => {
	const {
		state: { request },
	} = useContext(AppointmentDetailsContext);

	const user = useSelector((state) => state.user.value);

	function isAppointment() {
		return request.type === 'appointment';
	}
	function isInformation() {
		return request.type === 'information';
	}
	function havePublicFiles() {
		return request?.publicFiles?.length > 0;
	}
	function haveLinks() {
		return request?.links?.length > 0;
	}
	function userSignedIn() {
		return !!role && request.creator != null && request.creator.id === user.id;
	}

	const showOverview = isAppointment() || isInformation();
	const showLocation = isAppointment();
	const showAvailability = isAppointment();
	const showFiles = (isAppointment() || isInformation()) && (userSignedIn() || havePublicFiles());
	const showLinks = (isInformation() && userSignedIn()) || (isInformation() && haveLinks());
	const showGroup = isAppointment() || isInformation();
	const showTeacher = isAppointment() || isInformation();

	return (
		<S.Wrapper>
			{showOverview && <OverviewCard className="a1" role={role} />}
			{showLocation && <LocationCard className="a2" role={role} />}
			{showAvailability && <AvailabilityCard className="a3" role={role} />}
			{showFiles && <FilesCard className="a4" role={role} />}
			{showLinks && <LinksCard className="a3" role={role} />}
			{showGroup && request.group.name && <GroupCard className="a5" role={role} />}
			{showTeacher && request.creator != null && Object.keys(request.creator).length > 0 && <TeacherCard className="a6" request={request} role={role} />}
		</S.Wrapper>
	);
};

OverviewTab.propTypes = {
	role: PropTypes.string,
};
