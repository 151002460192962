import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'react-datepicker/dist/react-datepicker.css';

import { Typography } from '../Typography';
import * as S from './DatePicker.styles';

const CustomInput = forwardRef(({ id, label, error, withoutIcon, size, ...rest }, ref) => (
	<S.Wrapper error={error}>
		{label && (
			<Typography tag="label" weight="semibold" htmlFor={id ? `${id}` : null} id={id ? `${id}-label` : null}>
				{label}
			</Typography>
		)}
		<S.InputWrapper>
			{!withoutIcon && <FontAwesomeIcon icon={['fal', 'calendar']} />}
			<S.Input ref={ref} id={id} size={size} error={error} withoutIcon={withoutIcon} {...rest} />
		</S.InputWrapper>
		{error && error.message && (
			<S.FloatingWrapper title={error.message}>
				<Typography tag="p" variation="2">
					{error.message}
				</Typography>
			</S.FloatingWrapper>
		)}
	</S.Wrapper>
));

CustomInput.displayName = 'CustomInput';
CustomInput.propTypes = {
	id: PropTypes.string,
	label: PropTypes.string,
	withoutIcon: PropTypes.bool,
	error: PropTypes.object,
	size: PropTypes.oneOf(['small', 'medium']),
};

export const DatePicker = forwardRef(({ id, label, error, withoutIcon, selected, size = 'medium', selectsRange, ...rest }, ref) => {
	return (
		<S.DateWrapper style={{ display: 'flex' }}>
			{selectsRange ? (
				<S.RangeWrapper>
					<ReactDatePicker
						selectsStart
						onChange={rest.onChange[0]}
						startDate={rest.startDate}
						endDate={rest.endDate}
						selected={rest.startDate}
						style={{ display: 'block' }}
						customInput={<CustomInput className="first-input" size={size} withoutIcon={withoutIcon} id={id} label={label} error={error} />}
					/>
					<ReactDatePicker
						selectsEnd
						onChange={rest.onChange[1]}
						minDate={rest.startDate}
						startDate={rest.startDate}
						endDate={rest.endDate}
						selected={rest.endDate}
						style={{ display: 'block' }}
						customInput={<CustomInput className="last-input" size={size} withoutIcon={withoutIcon} id={id} label={label} error={error} />}
					/>
				</S.RangeWrapper>
			) : (
				<ReactDatePicker ref={ref} selected={selected && selected instanceof Date && !isNaN(selected.valueOf()) ? selected : null} {...rest} style={{ display: 'block' }} customInput={<CustomInput size={size} withoutIcon={withoutIcon} id={id} label={label} error={error} />} />
			)}
		</S.DateWrapper>
	);
});

DatePicker.displayName = 'DatePicker';
DatePicker.propTypes = {
	id: PropTypes.string,
	label: PropTypes.string,
	withoutIcon: PropTypes.bool,
	error: PropTypes.object,
	selected: PropTypes.instanceOf(Date),
	selectsRange: PropTypes.bool,
	size: PropTypes.oneOf(['small', 'medium']),
};
