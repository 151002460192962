import React from 'react';
import PropTypes from 'prop-types';
import { DateAndTimeStep, DetailsStep, EventLinkStep } from '../Steps';

export const EventFlow = ({ currentStep }) => {
	return (
		<>
			{currentStep === 1 && <DetailsStep />}
			{currentStep === 2 && <EventLinkStep />}
			{currentStep === 3 && <DateAndTimeStep />}
		</>
	);
};

EventFlow.propTypes = {
	currentStep: PropTypes.number.isRequired,
};
