import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { PageHeader, Tab, BusinessNavigation } from '../../components';

import { CurrentTab } from './CurrentTab';
import { ArchivedTab } from './ArchivedTab';
import { DraftsTab } from './DraftsTab';
import * as S from './BusinessResources.styles';
import { META_TITLE } from '../../../constants/general.constants';

const BusinessResources = () => {
	const history = useHistory();
	useEffect(() => {
		// Set document title
		document.title = `Company Resources | Dashboard | ${META_TITLE}`;
	}, []);

	const [tabs, setTabs] = useState([
		{
			id: 1,
			label: 'Current',
			component: <CurrentTab />,
		},
		{
			id: 2,
			label: 'Drafts',
			component: <DraftsTab />,
		},
		{
			id: 3,
			label: 'Archived',
			component: <ArchivedTab />,
		},
	]);

	return (
		<BusinessNavigation>
			<PageHeader title="Company Resources" action={[{ id: 1, onClick: () => history.push('/business/create-resource'), label: 'Create Resource', icon: ['fal', 'plus'] }]} />
			<S.Wrapper>
				<Tab tabs={tabs} setTabs={setTabs} />
			</S.Wrapper>
		</BusinessNavigation>
	);
};

export default BusinessResources;
