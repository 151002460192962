import React from 'react';
import { v4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormContext, useFieldArray } from 'react-hook-form';

import * as S from '../../CreateResource.styles';
import { Button, TextArea, TextInput, Typography } from '../../../../components';
import { required, urlOnly } from '../../../../utils/form-default-errors';

export const LinksStep = () => {
	const {
		register,
		control,
		formState: { errors },
	} = useFormContext();
	const { fields, append } = useFieldArray({ control, name: 'links' });

	function handleAddLink() {
		append({ id: v4() });
	}

	return (
		<>
			<Typography tag="h2" weight="extrablack" center>
				Links
			</Typography>
			<Typography tag="p" center>
				Add in links to attach with your request.
			</Typography>

			<div className="fields-wrapper">
				<S.LinksWrapper>
					{fields.map(({ id }, index) => (
						<div key={id} className="link-item">
							<Typography tag="h3" weight="bold">
								Link {index + 1}
							</Typography>
							<TextInput
								label="Link"
								id={`${index}-url`}
								error={errors?.links?.[index]?.url}
								{...register(`links.${index}.url`, {
									required: required('Link'),
									pattern: urlOnly('Link'),
								})}
							/>
							<TextInput
								label="Title"
								id={`${index}-title`}
								error={errors?.links?.[index]?.title}
								{...register(`links.${index}.title`, {
									required: required('Title'),
								})}
							/>
							<TextArea
								label="Description"
								id={`${index}-description`}
								error={errors?.links?.[index]?.description}
								{...register(`links.${index}.description`, {
									required: required('Description'),
								})}
							/>
						</div>
					))}

					<div className="add-link-wrapper">
						<Button variant="text" type="button" onClick={handleAddLink}>
							<FontAwesomeIcon icon={['fal', 'plus']} />
							<Typography variation="button-medium" weight="bold">
								Add Link
							</Typography>
						</Button>
					</div>
				</S.LinksWrapper>
			</div>
		</>
	);
};
