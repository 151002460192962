import styled from 'styled-components';
import colors from '../../../styles/colors';

export const ButtonWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 1rem;
`;

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	height: 100%;
`;

export const ModalTitleWrapper = styled.div`
	width: 100%;
	margin: 1rem 0 0;
	position: relative;
	padding: 1rem;
	border-bottom: 1px solid ${colors.neutralForm};

	.close-button {
		position: absolute;
		right: 1rem;
		top: 0;
	}
`;

export const TableWrapper = styled.div`
	overflow: auto;
	padding: 1rem;
`;
