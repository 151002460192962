import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { errorHandler, resetPassword, validateResetAttempt } from '../../services/authService';
import { pattern, required } from '../../utils/form-default-errors';
import logo from '../../assets/ncbce-logo-alt.png';
import { Typography, Button, TextInput } from '../../components';
import * as S from './ResetPasswordForm.styles';
import { META_TITLE, PASSWORD_REGEX } from '../../../constants/general.constants';

const ResetPasswordForm = () => {
	const { aToken, bToken } = useParams();
	const {
		register,
		handleSubmit,
		getValues,
		formState: { errors, isSubmitting, isSubmitSuccessful },
	} = useForm();
	const history = useHistory();

	const [isValidated, setIsValidated] = useState(false);
	const [validationError, setValidationError] = useState(null);

	useEffect(() => {
		// Set document title
		document.title = `Reset Password | ${META_TITLE}`;

		let mounted = true;
		validateResetAttempt({ aToken, bToken }).then((response) => {
			const { success, error } = response.data.result;
			if (mounted) {
				setIsValidated(true);
				setValidationError(success !== true ? error : null);
			}
		});
		return () => (mounted = false);
	}, []);

	async function asyncCaller(data) {
		await resetPassword({ aToken, bToken, pass: data.newPassword });
	}

	function onSubmit(e) {
		handleSubmit(asyncCaller)(e).catch(errorHandler);
	}

	return (
		<S.Wrapper>
			<S.Header>
				<div className="flex-1" />
				<div className="centered">
					<S.Picture>
						<img src={logo} alt="NCBCE logo" />
					</S.Picture>
				</div>
				<div className="flex-1" />
			</S.Header>
			{(() => {
				if (isValidated !== true) {
					return <div />;
				} else if (isSubmitSuccessful === true) {
					return (
						<S.Container>
							<FontAwesomeIcon className="envelope-icon" icon={['fal', 'check-circle']} size="3x" />
							<Typography tag="h1" weight="extrablack" center>
								Password Reset
							</Typography>
							<Typography tag="p" className="description" center>
								Your password has been successfully reset.
							</Typography>

							<Button type="submit" className="submit-button" onClick={() => history.push('/login')}>
								<Typography variation="button-medium" weight="bold">
									Continue to Log In
								</Typography>
							</Button>
						</S.Container>
					);
				} else if (validationError != null) {
					return (
						<S.Container>
							<FontAwesomeIcon className="envelope-icon" icon={['fal', 'question-circle']} size="3x" />
							{(() => {
								if (validationError.code === 102) {
									return (
										<>
											<Typography tag="h1" weight="extrablack" center>
												Your password reset link has expired.
											</Typography>
											<Typography tag="p" className="description" center>
												To reset your password, follow the link below.
											</Typography>
										</>
									);
								} else {
									return (
										<>
											<Typography tag="h1" weight="extrablack" center>
												Your reset link has already been used or is no longer valid.
											</Typography>
											<Typography tag="p" className="description" center>
												To reset your password, follow the link below.
											</Typography>
										</>
									);
								}
							})()}
							<Button type="submit" className="submit-button" onClick={() => history.push('/forgot-password')}>
								<Typography variation="button-medium" weight="bold">
									Continue to Forgot Password
								</Typography>
							</Button>
						</S.Container>
					);
				} else {
					return (
						<S.Container>
							<FontAwesomeIcon className="question-icon" icon={['fal', 'lock']} size="3x" />
							<Typography tag="h1" weight="extrablack" center>
								Reset Password
							</Typography>
							<Typography tag="p" className="description" center>
								Enter a new password below
							</Typography>

							<S.Form onSubmit={onSubmit}>
								<TextInput
									label="New Password"
									type="password"
									id="new-password"
									error={errors.newPassword}
									autoComplete="password"
									{...register('newPassword', {
										required: required('New Password'),
										pattern: pattern('New Password', PASSWORD_REGEX, 'Password must contain at least one number, one uppercase letter, one lowercase letter, and be at least 8 characters long'),
									})}
								/>
								<TextInput
									label="Confirm New Password"
									type="password"
									id="confirm-new-password"
									error={errors.confirmNewPassword}
									autoComplete="password"
									{...register('confirmNewPassword', {
										required: required('Confirm New Password'),
										pattern: pattern('Confirm New Password', PASSWORD_REGEX, 'Password must contain at least one number, one uppercase letter, one lowercase letter, and be at least 8 characters long'),
										validate: {
											equalToPassword: (v) => (getValues('newPassword') === v ? true : "Confirm Password doesn't match with Password"),
										},
									})}
								/>

								<Button type="submit" className="submit-button">
									<Typography variation="button-medium" weight="bold">
										{isSubmitting ? 'Loading...' : 'Reset Password'}
									</Typography>
								</Button>
							</S.Form>
						</S.Container>
					);
				}
			})()}
		</S.Wrapper>
	);
};

export default ResetPasswordForm;
