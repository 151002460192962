import styled, { css } from 'styled-components';

const COMMON_STYLES = css`
	position: relative;
	height: 0;

	img {
		position: absolute;
		inset: 0;
		height: 100%;
		object-fit: contain;
		object-position: center;
	}
`;

const ASPECT_RATIO = {
	natural: css``,
	'1/1': css`
		${COMMON_STYLES}
		padding-top: 100%;
	`,
	'4/3': css`
		${COMMON_STYLES}
		padding-top: 75%;
	`,
	'3/4': css`
		${COMMON_STYLES}
		padding-top: 133.33%;
	`,
	'16/9': css`
		${COMMON_STYLES}
		padding-top: 56.25%;
	`,
	'9/16': css`
		${COMMON_STYLES}
		padding-top: 177.77%;
	`,
	'21/9': css`
		${COMMON_STYLES}
		padding-top: 42.85%;
	`,
	'9/21': css`
		${COMMON_STYLES}
		padding-top: 233.33%;
	`,
	'2/1': css`
		${COMMON_STYLES}
		padding-top: 50%;
	`,
	'1/2': css`
		${COMMON_STYLES}
		padding-top: 200%;
	`,
	'3/2': css`
		${COMMON_STYLES}
		padding-top: 66.66%;
	`,
	'2/3': css`
		${COMMON_STYLES}
		padding-top: 150%;
	`,
	'4/1': css`
		${COMMON_STYLES}
		padding-top: 25%;
	`,
	'1/4': css`
		${COMMON_STYLES}
		padding-top: 400%;
	`,
};

export const Container = styled.div`
	display: inline-block;
	overflow: hidden;
	width: 100%;
`;

export const Picture = styled.picture`
	display: block;
	width: 100%;
	overflow: hidden;

	img {
		display: block;
		width: 100%;
		height: auto;
	}

	${({ aspectRatio }) => ASPECT_RATIO[aspectRatio]}
`;
