import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { AdminSchoolDetailContext } from '../../AdminSchoolDetail';
import { Card, Typography } from '../../../../components';
import * as S from './InformationCard.styles';

const InformationCard = ({ className }) => {
	const {
		state: { school },
	} = useContext(AdminSchoolDetailContext);

	return (
		<Card className={className} title="School Information" transparentHeaderBorder={true}>
			<S.Wrapper>
				<div className="info-wrapper">
					<div className="element-info">
						<Typography className="element-info__title" tag="h6" weight="semibold">
							School Name
						</Typography>
						<Typography className="element-info__text" tag="p">
							{school?.name}
						</Typography>
					</div>
					<div className="element-info">
						<Typography className="element-info__title" tag="h6" weight="semibold">
							ID Number
						</Typography>
						<Typography className="element-info__text" tag="p">
							{school?.schoolCode}
						</Typography>
					</div>
					<div className="element-info">
						<Typography className="element-info__title" tag="h6" weight="semibold">
							County
						</Typography>
						<Typography className="element-info__text capitalized" tag="p">
							{school?.addressCountyDisplay}
						</Typography>
					</div>
					<div className="element-info">
						<Typography className="element-info__title" tag="h6" weight="semibold">
							Address
						</Typography>
						<Typography className="element-info__text capitalized" tag="p">
							{`${school?.address?.addressLine1}, ${school?.address?.city}, ${school?.address?.state} ${school?.address?.postalCode}`}
						</Typography>
					</div>
				</div>
			</S.Wrapper>
		</Card>
	);
};

InformationCard.propTypes = {
	className: PropTypes.string,
};

export default InformationCard;
