import styled, { css } from 'styled-components';

import colors from '../../styles/colors';

export const MainWrapper = styled.div`
	flex: 1;
	overflow: auto;
	display: flex;
`;

export const SidebarWrapper = styled.aside`
	overflow: auto;
	min-width: 19.375rem;
	width: 100%;
	max-width: 19.375rem;
	border: none;
	border-right: 1px solid ${colors.neutralForm};
	background-color: white;
	padding: 1rem 1.75rem;

	.sidebar-option {
		appearance: none;
		border: none;
		width: 100%;
		background-color: transparent;
		font-size: 1rem;
		line-height: 1.375rem;
		font-weight: 700;
		display: flex;
		align-items: center;
		cursor: pointer;
		padding: 0.5rem 0;

		& > * + * {
			margin-left: 0.5rem;
		}

		&__circle {
			width: 1.875rem;
			height: 1.875rem;
			min-width: 1.875rem;
			min-height: 1.875rem;
			border-radius: 9999px;
			color: ${colors.neutralText};
			border: 2px solid ${colors.neutralText};
			display: inline-flex;
			justify-content: center;
			font-weight: 600;
			align-items: center;

			& > svg {
				display: none;
			}
		}

		&__name {
			font-weight: 400;
			color: ${colors.neutralText};
		}

		&.active {
			.sidebar-option__name,
			.sidebar-option__circle {
				color: ${colors.brandPrimary};
			}

			.sidebar-option__name {
				font-weight: 700;
			}

			.sidebar-option__circle {
				border-color: ${colors.brandPrimary};
			}
		}

		&.completed {
			.sidebar-option__name {
				color: ${colors.stateSuccess};
			}

			.sidebar-option__circle {
				color: white;
				border-color: ${colors.stateSuccess};
				background-color: ${colors.stateSuccess};

				& > span {
					display: none;
				}
				& > svg {
					display: block;
				}
			}
		}

		&__details-options-wrapper {
			display: grid;
			grid-template-columns: 1fr;
			gap: 0.5rem;
			justify-items: flex-start;
			margin-left: 2.375rem;
		}

		&__details-option {
			appearance: none;
			border: none;
			background-color: transparent;
			font-size: 0.625rem;
			line-height: 0.75rem;
			font-weight: 400;
			color: ${colors.brandPrimary};
			cursor: pointer;

			&.selected {
				font-weight: 700;
			}
		}
	}
`;

export const FormWrapper = styled.form`
	flex: 1;
	display: flex;
	flex-direction: column;
	overflow: hidden;

	.error-wrapper {
		color: ${colors.stateDanger};
	}
`;

export const FormContent = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	overflow: auto;
	overflow-x: hidden;
`;

export const FormPanel = styled.div`
	background-color: white;
	border: 1px solid ${colors.neutralForm};
	border-radius: 0.375rem;
	margin: 1.5rem 1rem;
	padding: 2.5rem 1rem;
	display: flex;
	flex-direction: column;

	& > * + * {
		margin-top: 0.75rem;
	}

	.fields-wrapper {
		display: grid;
		grid-template-columns: 1fr;
		gap: 1.5rem;
		width: 100%;
		max-width: 41rem;
		margin: 1.875rem auto 0;

		h6.gray-text {
			margin-top: 0.25rem;
		}
		.gray-text {
			color: ${colors.neutralText};
		}

		.two-col {
			display: grid;
			gap: 1.25rem;
			grid-template-columns: 1fr;

			@media (min-width: 992px) {
				grid-template-columns: 1fr 1fr;
			}
		}

		.review {
			&__step-item {
				display: grid;
				grid-template-columns: 1fr;
				gap: 1.5rem;
				margin-top: 1rem;

				&:first-child {
					margin-top: 0;
				}
			}

			&__info-item {
				& > h6 {
					color: ${colors.neutralText};
					margin-bottom: 0.375em;
				}

				&--with-badge {
					& > h6 {
						margin-bottom: calc(0.375em + 0.25rem);
					}
				}

				.badge-wrapper {
					display: flex;
					flex-wrap: wrap;
					margin: -0.25rem;

					.small-badge {
						padding: 0.25rem 0.5rem;
						border-radius: 6.25rem;
						border: 1px solid ${colors.brandPrimary};
						font-size: 0.75rem;
						color: ${colors.brandPrimary};
						font-weight: 700;
						margin: 0.25rem;
					}
				}
			}

			&__overview {
				& > h3 {
					margin-bottom: -1rem;
				}
			}

			&__cover-image {
				border: 1px solid ${colors.neutralForm};
				border-radius: 0.375rem;
			}

			&__file-item {
				display: grid;
				grid-template-columns: 1fr;
				gap: 1rem;
			}

			&__file-detail {
				display: flex;
				align-items: center;

				& > svg {
					color: ${colors.neutralText};
				}

				& > div {
					margin-left: 0.5rem;

					& > p:last-child {
						color: ${colors.neutralText};
					}
				}
			}

			&__location-item {
				display: flex;

				.location-icon-wrapper {
					display: flex;
					justify-content: center;
					margin-right: 0.5rem;
					width: 1.5rem;
					color: black;
				}

				.location-info-wrapper {
					flex: 1;
					display: grid;
					grid-template-columns: 1fr;
					gap: 1rem;

					.custom-margin {
						margin-top: 1rem;
					}
				}
			}
		}
	}

	.radio-wrapper {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		width: 100%;
		margin: 1rem auto 0;
		position: relative;

		.radio-field {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			position: relative;
			width: 100%;
			max-width: 16rem;
			height: 16rem;
			padding: 1rem;
			margin: 0.75rem;
			color: ${colors.neutralText};
			z-index: 0;

			&.template-field {
				min-height: 16rem;
				height: auto;
				max-height: none;
			}

			h5 {
				color: black;
			}

			h5,
			p {
				pointer-events: none;
				z-index: 1;
			}

			& > * + * {
				margin-top: 0.5rem;
			}

			svg {
				pointer-events: none;
				margin-bottom: 0.5rem;
				z-index: 1;
			}

			.radio-input {
				position: absolute;
				border: 1px solid ${colors.neutralForm};
				border-radius: 0.375rem;
				appearance: none;
				width: 100%;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				inset: 0;
				cursor: pointer;

				&:checked {
					background-color: rgba(234, 236, 244, 0.4);
					border-width: 2px;
					border-color: ${colors.brandPrimary};
				}
			}

			&.template {
				justify-content: flex-start;
				align-items: flex-start;
				padding: 0.75rem;

				.thumbnail {
					pointer-events: none;
					border-radius: 0.375rem;
					margin-top: 0;
				}

				p {
					margin-top: 0.125rem;
				}
			}

			&.with-error {
				color: ${colors.stateDanger};

				h5 {
					color: ${colors.stateDanger};
				}

				.radio-input {
					border-color: ${colors.stateDanger};
				}
			}

			.radio-input:checked ~ svg,
			.radio-input:checked ~ h5 {
				color: ${colors.brandPrimary};
			}
			.radio-input:checked ~ p {
				color: black;
			}
		}

		.error-wrapper {
			position: absolute;
			bottom: -0.75rem;
			color: ${colors.stateDanger};
			left: 0.75rem;
		}
	}
`;

export const FormFooter = styled.footer`
	background-color: white;
	border-top: 1px solid ${colors.neutralForm};
	padding: 0 2rem 1.25rem;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;

	.back-button {
		margin-top: 1.25rem;
	}

	.spacer {
		flex: 1;
		min-width: 1rem;
		height: 1rem;
	}

	.submit-container {
		display: flex;
		align-items: center;
		margin-top: 1.25rem;

		& > * + * {
			margin-left: 1rem;
		}
	}

	@media screen and (min-width: 768px) {
		justify-content: space-between;
	}
`;

export const FileDropZone = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	border: 1px dashed ${colors.neutralText};
	height: 25rem;
	border-radius: 0.375rem;
	cursor: pointer;

	overflow: hidden;

	img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

	${({ isDragActive }) =>
		isDragActive &&
		css`
			opacity: 0.8;
		`}

	${({ isDragAccept }) =>
		isDragAccept &&
		css`
			border-color: ${colors.stateSuccess};
		`}

	${({ isDragReject }) =>
		isDragReject &&
		css`
			border-color: ${colors.stateDanger};
		`}
`;

export const HugeImageUploader = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;

	label {
		color: ${colors.neutralText};
		cursor: pointer;
	}

	.reset-btn {
		appearance: none;
		border: none;
		border-radius: 0.375rem;
		cursor: pointer;
		background-color: ${colors.brandPrimary};
		color: white;
		display: grid;
		place-content: center;
		width: 2.25rem;
		height: 2.25rem;
		position: absolute;
		top: 2.25rem;
		right: 1rem;

		svg {
			width: 1.125rem;
			height: 1.125rem;
		}

		&:hover {
			opacity: 0.8;
		}
	}

	${({ withError }) =>
		withError &&
		css`
			label {
				color: ${colors.stateDanger};
			}

			${FileDropZone} {
				border-color: ${colors.stateDanger};
			}
		`}

	.error-wrapper {
		position: absolute;
		bottom: -1rem;
		color: ${colors.stateDanger};
	}
`;

export const GradeLevelWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 1rem;
	position: relative;

	.field-label {
		color: ${colors.neutralText};

		&.with-error {
			color: ${colors.stateDanger};
		}
	}

	.checkbox-field {
		display: flex;
		align-items: center;

		& > * + * {
			padding-left: 0.75rem;
		}

		label {
			font-size: 1rem;
			line-height: 1.375rem;
			font-weight: 400;
			color: black;
			cursor: pointer;
		}
	}

	.error-wrapper {
		color: ${colors.stateDanger};
	}

	@media screen and (min-width: 768px) {
		grid-template-columns: 1fr 1fr;

		.field-label {
			grid-column: 1 / 3;
		}
	}
`;

export const CareerClusterWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 1rem;
	position: relative;

	.field-label {
		color: ${colors.neutralText};

		&.with-error {
			color: ${colors.stateDanger};
		}
	}

	.checkbox-field {
		display: flex;
		align-items: center;

		& > * + * {
			padding-left: 0.75rem;
		}

		label {
			font-size: 1rem;
			line-height: 1.375rem;
			font-weight: 400;
			color: black;
			cursor: pointer;
		}
	}

	.error-wrapper {
		color: ${colors.stateDanger};
	}

	@media screen and (min-width: 768px) {
		grid-template-columns: 1fr 1fr;

		.field-label {
			grid-column: 1 / 3;
		}
	}
`;

export const InformationCard = styled.div`
	display: flex;
	padding: 0.5rem 0.75rem;
	background-color: ${colors.neutralBackground};
	border: 1px solid ${colors.neutralForm};
	border-radius: 0.375rem;
	margin-top: -1rem;

	.icon-wrapper {
		display: flex;
		justify-content: center;
		width: 1.25rem;
		color: ${colors.brandPrimary};
	}

	.info-wrapper {
		display: grid;
		width: 100%;
		grid-template-columns: 1fr;
		gap: 0.5rem;
		margin-left: 0.75rem;
		color: black;

		@media screen and (min-width: 768px) {
			grid-template-columns: 1fr 1fr;

			h5 {
				grid-column: span 2;
			}
		}

		.info-line {
			display: flex;
			color: ${colors.neutralButton};

			svg {
				width: 1rem;
				height: 1rem;
			}

			p {
				margin-left: 0.5rem;
			}
		}
	}
`;

export const LocationStep = styled.section`
	display: grid;
	grid-template-columns: 1fr;
	gap: 1.5rem;
	margin-top: 2.5rem;
`;

export const LocationStepWrapper = styled.div`
	position: relative;
	display: grid;
	grid-template-columns: 1fr;
	gap: 1rem;
	margin-top: -0.75rem;

	.error-wrapper {
		bottom: -1.5rem;
	}
`;

export const SelectedLocations = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	gap: 2rem;
	margin-bottom: 1.5rem;

	.gray-color {
		color: ${colors.neutralText};
	}

	.step {
		&__location {
			display: grid;
			grid-template-columns: 2rem 1fr;
		}

		&__location-field-row {
			display: grid;
			gap: 0.75rem;

			@media (min-width: 992px) {
				grid-template-columns: 2fr 1fr 1fr;
			}
		}

		&__location-fields-wrapper {
			display: grid;
			grid-template-columns: 1fr;
			gap: 0.75rem;
		}

		&__information-card {
			display: flex;
			padding: 0.5rem 0.75rem;
			background-color: ${colors.neutralBackground};
			border: 1px solid ${colors.neutralForm};
			border-radius: 0.375rem;

			.icon-wrapper {
				display: flex;
				justify-content: center;
				width: 1.25rem;
				color: ${colors.brandPrimary};
			}

			.info-wrapper {
				margin-left: 0.75rem;
				color: #000000;

				.info-line {
					display: flex;
					margin-top: 0.5rem;
					color: ${colors.neutralButton};

					svg {
						width: 1rem;
						height: 1rem;
					}

					p {
						margin-left: 0.5rem;
					}
				}
			}
		}
	}
`;

export const RadioOptionsWrapper = styled.div`
	display: grid;
	gap: 1rem;
	position: relative;

	.field-label {
		color: ${colors.neutralText};

		&.with-error {
			color: ${colors.stateDanger};
		}
	}

	.radio-field {
		display: flex;
		align-items: center;

		& > * + * {
			margin-left: 0.75rem;
		}
	}
`;

export const DaysSelector = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	position: relative;
	gap: 0.5rem;

	.error-wrapper {
		bottom: -1rem;
		position: absolute;
	}

	.days-wrapper {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -0.375rem;
	}

	.day-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		min-width: 3.75rem;
		width: 100%;
		max-width: 3.75rem;
		height: 3.75rem;
		margin: 0.375rem;

		label {
			font-weight: 400;
			text-transform: capitalize;
		}

		input[type='checkbox'] {
			border-radius: 0.25rem;
			border: 1px solid ${colors.neutralForm};
			appearance: none;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			inset: 0;
			cursor: pointer;

			&:checked {
				border-width: 0.125rem;
				border-color: ${colors.brandPrimary};

				& + label {
					color: ${colors.brandPrimary};
					font-weight: 600;
				}
			}
		}
	}
`;

export const LinksWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	gap: 1.5rem;

	.link-item {
		display: grid;
		grid-template-columns: 1fr;
		gap: 1.125rem;

		& > h3 {
			margin-bottom: -1rem;
		}
	}

	.add-link-wrapper {
		margin: 0 -0.75rem;

		button {
			padding-left: 0.75rem;
			padding-right: 0.75rem;
		}
	}
`;

export const FilesListWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	gap: 1rem;

	.files-wrapper {
		display: grid;
		grid-template-columns: 1fr;
		gap: 1rem;

		header {
			display: flex;
			justify-content: space-between;
			align-items: center;

			button {
				color: ${colors.neutralText};
			}
		}

		&__item {
			display: grid;
			grid-template-columns: 1fr;
			gap: 1rem;
			padding-bottom: 1.5rem;
			border-bottom: 1px solid ${colors.neutralForm};

			&__info {
				h6 {
					color: ${colors.neutralText};
					margin-bottom: 0.375em;
				}
			}

			&__details {
				display: flex;
				align-items: center;

				& > div {
					margin-left: 0.5rem;

					& > p:last-child {
						color: ${colors.neutralText};
					}
				}
			}

			&:last-child {
				padding-bottom: 0;
				border-bottom: none;
			}
		}
	}
`;

export const FileListDropzone = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	border: 1px dashed ${colors.neutralText};
	height: 12.5rem;
	border-radius: 0.375rem;
	cursor: pointer;

	overflow: hidden;

	img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

	.upload-placeholder {
		display: grid;
		grid-template-columns: 1fr;
		gap: 0.5rem;

		&__picture {
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 0.375rem;
			padding: 0.5rem;
			width: 100%;
			max-width: 8.25rem;
			margin: 1rem auto 0;
			background-color: ${colors.neutralMessage};
			color: ${colors.brandPrimary};
		}

		&__paragraph {
			color: ${colors.neutralText};

			strong {
				color: ${colors.brandPrimary};
			}
		}
	}

	${({ isDragActive }) =>
		isDragActive &&
		css`
			opacity: 0.8;
		`}

	${({ isDragAccept }) =>
		isDragAccept &&
		css`
			border-color: ${colors.stateSuccess};
		`}

	${({ isDragReject }) =>
		isDragReject &&
		css`
			border-color: ${colors.stateDanger};
		`}
`;

export const FileTemplatesWrapper = styled.div`
	display: flex;
	flex-direction: column;

	.file-templates-container {
		margin-top: 1rem;
		margin-bottom: 0.5rem;
		display: grid;
		grid-template-columns: 1fr;
		gap: 1rem;

		& > a {
			display: flex;
			align-items: center;
			text-decoration: none;
			color: black;
			padding: 1rem;
			border-radius: 0.375rem;
			border: 1px solid ${colors.neutralForm};

			svg {
				color: ${colors.neutralText};
			}

			& > div {
				flex: 1;
				margin: 0 1rem;
			}

			&:hover {
				border-color: ${colors.brandPrimary};
			}
		}

		@media (min-width: 1024px) {
			grid-template-columns: 1fr 1fr;
		}
	}
`;

export const LocationItem = styled.div`
	margin: 0 auto 1rem;
	width: 100%;
	position: relative;

	.location-remove {
		position: absolute;
		right: 0;
		top: 0;
		padding: 0;
		color: ${colors.stateDanger};
	}
`;

export const AddWrapper = styled.div`
	display: flex;
	align-items: center;
	margin: 1rem auto 0;
	width: 100%;

	button {
		margin-left: -1.5625rem;
	}
`;

export const FieldsWrapper = styled.div`
	display: grid;
	gap: 1rem;
	margin-top: 0.5rem;

	.location-grid-row {
		display: grid;
		gap: 1rem;

		@media screen and (min-width: 410px) {
			grid-template-columns: 2fr 1fr 1fr;
		}
	}
`;
