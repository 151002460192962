import styled from 'styled-components';

import colors from '../../styles/colors';

const SIZES = {
	small: {
		control: {
			minHeight: 36,
			fontSize: '0.75rem',
		},
		option: {
			fontSize: '0.75rem',
		},
	},
	medium: {
		control: {
			minHeight: 45,
			fontSize: '1rem',
		},
		option: {
			fontSize: '1rem',
		},
	},
};

export const colourStyles = ({ size, icon, error }) => ({
	clearIndicator: (styles) => ({ ...styles }),
	container: (styles) => ({ ...styles }),
	control: (styles, { isFocused }) => ({
		...styles,
		borderRadius: '0.375rem',
		minHeight: SIZES[size].control.minHeight,
		fontSize: SIZES[size].control.fontSize,
		borderColor: error ? colors.stateDanger : isFocused ? colors.neutralButton : colors.neutralForm,
		boxShadow: 'none',
		'&:hover': { borderColor: error ? colors.stateDanger : isFocused ? colors.neutralButton : colors.neutralForm },
		cursor: 'pointer',
	}),
	dropdownIndicator: (styles, { isFocused }) => ({ ...styles, padding: 0, color: error ? colors.stateDanger : isFocused ? colors.neutralButton : colors.neutralForm }),
	group: (styles) => ({ ...styles }),
	groupHeading: (styles) => ({ ...styles }),
	indicatorsContainer: (styles) => ({ ...styles, padding: '0 0.75rem' }),
	indicatorSeparator: (styles) => ({ ...styles, width: 0 }),
	input: (styles) => ({ ...styles, padding: 0, margin: 0 }),
	loadingIndicator: (styles) => ({ ...styles }),
	loadingMessage: (styles) => ({ ...styles }),
	menu: (styles) => ({ ...styles, zIndex: 2 }),
	menuList: (styles) => ({ ...styles }),
	menuPortal: (styles) => ({ ...styles }),
	multiValue: (styles) => ({ ...styles, backgroundColor: 'transparent', border: `1px solid ${colors.brandPrimary}`, padding: '0.1875rem 0.4375rem', borderRadius: 9999 }),
	multiValueLabel: (styles) => ({ ...styles, color: colors.brandPrimary, padding: 0, fontSize: 12, fontWeight: 700, lineHeight: '20px' }),
	multiValueRemove: (styles) => ({ ...styles, color: colors.brandPrimary, '&:hover': { backgroundColor: 'transparent' } }),
	noOptionsMessage: (styles) => ({ ...styles }),
	option: (styles) => ({ ...styles, cursor: 'pointer', fontSize: SIZES[size].option.fontSize }),
	placeholder: (styles) => ({ ...styles, padding: 0, margin: 0, whiteSpace: 'nowrap' }),
	singleValue: (styles) => ({ ...styles, padding: 0, margin: 0, color: 'black' }),
	valueContainer: (styles) => ({ ...styles, padding: icon ? '0 2rem 0 0.75rem' : '0 0.75rem', margin: 0 }),
});

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;

	& > label {
		cursor: pointer;
		color: ${({ error }) => (error ? colors.stateDanger : colors.neutralText)};
	}
`;

export const IconWrapper = styled.div`
	position: absolute;
	right: 2rem;
	display: flex;
	height: 100%;
	justify-content: center;
	align-items: center;
	padding: 0 0.75rem;

	svg {
		color: ${colors.brandPrimary};

		&:hover {
			opacity: 0.8;
		}
	}
`;

export const FloatingWrapper = styled.div`
	position: absolute;
	bottom: -1rem;
	color: ${colors.stateDanger};
	max-width: 100%;

	p {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
`;
