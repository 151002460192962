import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useTable, useFlexLayout } from 'react-table';
import { useDebouncedFn } from 'beautiful-react-hooks';
import toast from 'react-hot-toast';

import { TEACHER_ROLES_OPTIONS, USER_SORT_OPTIONS } from '../../../../constants/general.constants';
import { Typography, Card, TableComponents, Spinner, ErrorComponent, EmptyComponent, FilterSection, Button, IconButton, TextInput, Select } from '../../../components';
import * as S from './TeachersTab.styles';
import { useHistory, useParams } from 'react-router-dom';
import { fetchStaff } from '../../../services/school';
import ReactModal from 'react-modal';
import { Controller, useForm } from 'react-hook-form';
import { pattern, required } from '../../../utils/form-default-errors';
import { errorHandler } from '../../../services/authService';
import { EMAIL } from '../../../utils/common-regex';
import { inviteTeacher } from '../../../services/invites';

const AddTeacherModal = forwardRef(({ onSuccessCallback }, ref) => {
	const [isOpen, setIsOpen] = useState(false);

	const {
		handleSubmit,
		register,
		reset,
		setValue,
		control,
		formState: { errors, isSubmitting },
	} = useForm();

	const handleOpenModal = (schoolId) => {
		setValue('primarySchoolId', schoolId);
		setIsOpen(true);
	};

	const handleCloseModal = () => {
		setIsOpen(false);
		setTimeout(() => {
			reset();
		}, 200);
	};

	async function asyncCaller(data) {
		await inviteTeacher(data);
		onSuccessCallback();
		handleCloseModal();
	}

	const onSubmit = (e) => {
		handleSubmit(asyncCaller)(e).catch(errorHandler);
	};

	useImperativeHandle(
		ref,
		() => ({
			open: handleOpenModal,
			close: handleCloseModal,
		}),
		[handleOpenModal, handleCloseModal]
	);

	return (
		<ReactModal ref={ref} className="add-user-modal" closeTimeoutMS={200} isOpen={isOpen} onRequestClose={handleCloseModal}>
			<S.Form onSubmit={onSubmit}>
				<IconButton className="close-button" type="button" icon={['fal', 'times']} onClick={handleCloseModal} />
				<div className="modal-content">
					<header className="modal-content__header">
						<Typography tag="h2" weight="extrablack" center>
							Add Teacher
						</Typography>
					</header>

					<section className="modal-content__section">
						<TextInput
							label="First Name"
							placeholder="First Name"
							error={errors.firstName}
							{...register('firstName', {
								required: required('First Name'),
							})}
						/>

						<TextInput
							label="Last Name"
							placeholder="Last Name"
							error={errors.lastName}
							{...register('lastName', {
								required: required('Last Name'),
							})}
						/>

						<TextInput
							label="Email"
							placeholder="Email"
							error={errors.email}
							{...register('email', {
								required: required('Email'),
								pattern: pattern('Email', EMAIL),
							})}
						/>

						<TextInput
							label="Teacher UID"
							placeholder="Teacher UID"
							error={errors.uid}
							{...register('uid', {
								required: required('Teacher UID'),
							})}
						/>

						<Controller
							name="roleName"
							control={control}
							rules={{ required: required('Role') }}
							render={({ field: { onChange, value } }) => <Select label="Role" id="role" error={errors?.roleName} placeholder="Role" options={TEACHER_ROLES_OPTIONS} value={TEACHER_ROLES_OPTIONS.find((c) => c.value === value) || ''} onChange={(val) => onChange(val.value)} />}
						/>
					</section>

					<div className="modal-footer">
						<Button variant="outline" variation="secondary" type="button" onClick={handleCloseModal}>
							<Typography variation="button-medium" weight="bold">
								Cancel
							</Typography>
						</Button>
						<div className="flex-1" />
						<Button>
							<Typography variation="button-medium" weight="bold">
								{isSubmitting ? 'Loading' : 'Invite'}
							</Typography>
						</Button>
					</div>
				</div>
			</S.Form>
		</ReactModal>
	);
});

AddTeacherModal.displayName = 'AddTeacherModal';

AddTeacherModal.propTypes = {
	onSuccessCallback: PropTypes.func.isRequired,
};

export const TeachersTab = ({}) => {
	const { id: schoolId } = useParams();
	const history = useHistory();
	const [pageStatus, setPageStatus] = useState('idle');
	const [users, setUsers] = useState([]);
	const addTeacherModalRef = useRef();

	const initialPageNumber = 0;
	const [pageNumber, setPageNumber] = useState(initialPageNumber);
	const [totalPages, setTotalPages] = useState(undefined);
	const [loadingMore, setLoadingMore] = useState(false);

	const filterRef = useRef();

	const onChangeCallback = useDebouncedFn(async () => {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchStaff({ schoolId, page: initialPageNumber, ...filterRef.current.value });
			setUsers(result.staff);
			setPageNumber(initialPageNumber);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}, 300);

	async function loadMore() {
		setLoadingMore(true);
		try {
			if (totalPages > pageNumber + 1) {
				const {
					data: { result },
				} = await fetchStaff({ schoolId, page: pageNumber + 1, ...filterRef.current.value });
				setUsers((prev) => [...prev, ...result.staff]);
				setTotalPages(result.totalPages);
				setPageNumber((prev) => prev + 1);
			} else {
				toast.error('No more resources to load');
			}
			setLoadingMore(false);
		} catch (error) {
			setLoadingMore(false);
		}
	}

	async function initializeData() {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchStaff({ schoolId, page: initialPageNumber });
			setUsers(result.staff);
			setTotalPages(result.totalPages);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}

	useEffect(() => {
		// Load initial data
		if (pageStatus === 'idle') {
			initializeData();
		}
	}, [pageStatus]);

	const columns = React.useMemo(
		() => [
			{
				Header: 'First Name',
				accessor: 'firstName',
			},
			{
				Header: 'Last Name',
				accessor: 'lastName',
			},
			{
				Header: '# of Bookings',
				accessor: 'metricBookingsCreated',
			},
			{
				Header: '# of Requests',
				accessor: 'metricRequestsCreated',
			},
			{
				Header: '# of Information',
				accessor: 'metricInformationCreated',
			},
			{
				Header: '# of Groups',
				accessor: 'metricGroupsCreated',
			},
		],
		[]
	);
	const data = React.useMemo(() => users, [users]);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
		{
			columns,
			data,
		},
		useFlexLayout
	);

	const renderContent = () => {
		switch (pageStatus) {
			case 'loading':
				return <Spinner />;
			case 'error':
				return <ErrorComponent />;
			case 'success':
				return rows.length > 0 ? (
					<table {...getTableProps()}>
						<thead>
							{headerGroups.map(({ key, ...headerGroup }) => (
								<tr key={key} {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map(({ key, ...column }) => (
										<th key={key} {...column.getHeaderProps()}>
											<Typography tag="h6" weight="semibold">
												{column.render('Header')}
											</Typography>
										</th>
									))}
								</tr>
							))}
						</thead>
						<tbody {...getTableBodyProps()}>
							{rows.map(({ key, ...row }) => {
								prepareRow(row);
								return (
									<TableComponents.ClickableRow
										key={key}
										{...row.getRowProps()}
										onClick={() => {
											history.push(`/admin/users/${row.original.id}`);
										}}
									>
										{row.cells.map(({ key, ...cell }) => {
											return (
												<td key={key} {...cell.getCellProps()}>
													<Typography tag="p" variation="2">
														{cell.render('Cell')}
													</Typography>
												</td>
											);
										})}
									</TableComponents.ClickableRow>
								);
							})}
						</tbody>
					</table>
				) : (
					<EmptyComponent title="No current teachers" message="Check back later to view all teachers for this school." icon={['fal', 'school']} />
				);
			default:
				return null;
		}
	};

	return (
		<S.Wrapper>
			<FilterSection
				ref={filterRef}
				onChangeCallback={onChangeCallback}
				initialState={{
					searchText: '',
					sortOption: USER_SORT_OPTIONS[0],
					showAdvancedFilters: false,
					activeFilters: [],
					distance: 0,
					counties: [],
					locationTypes: [],
					categories: [],
					careerClusters: [],
					ageGroups: [],
					employabilitySkills: [],
					curriculumStandards: [],
				}}
				sortOptions={USER_SORT_OPTIONS}
				filterOptions={[]}
				action={{ label: 'Add Teacher', icon: { source: ['fal', 'plus'] }, onClick: () => addTeacherModalRef.current.open(schoolId) }}
				showFilters={false}
			/>

			<Card>
				<TableComponents.TableWrapper>{renderContent()}</TableComponents.TableWrapper>
				<AddTeacherModal ref={addTeacherModalRef} onSuccessCallback={onChangeCallback} />
			</Card>
			{users?.length > 0 && totalPages > pageNumber + 1 && (
				<S.LoadMoreWrapper>
					<Button variant="outline" variation="secondary" type="button" onClick={loadMore}>
						<Typography variation="button-medium" weight="bold">
							{loadingMore ? 'Loading...' : 'Load More'}
						</Typography>
					</Button>
				</S.LoadMoreWrapper>
			)}
		</S.Wrapper>
	);
};
