import React, { createContext, useEffect, useReducer, useState } from 'react';

import { AdminNavigation, DetailPageHeader, ErrorComponent, PageHeader, Spinner, Tab } from '../../components';
import * as S from './AdminSchoolDetail.styles';
import { BookingsTab } from './BookingsTab';
import { TeachersTab } from './TeachersTab';
import { OverviewTab } from './OverviewTab';
import { RequestsTab } from './RequestsTab';
import { InformationTab } from './InformationTab';
import { GroupsTab } from './GroupsTab';
import { META_TITLE } from '../../../constants/general.constants';
import { useQuery } from '../../hooks/useQuery';
import { useHistory, useParams } from 'react-router-dom';
import { fetchSchool } from '../../services/school';

export const AdminSchoolDetailContext = createContext();

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_SCHOOL':
			return {
				...state,
				school: action.payload,
			};
		default:
			return state;
	}
};

const AdminSchoolDetail = () => {
	const [pageStatus, setPageStatus] = useState('idle');
	const query = useQuery();
	const history = useHistory();
	const [state, dispatch] = useReducer(reducer, {});
	const { id: schoolId } = useParams();

	const [tabs, setTabs] = useState([
		{
			id: 1,
			label: 'Overview',
			component: <OverviewTab />,
			active: query.get('tabId') === '1',
		},
		{
			id: 2,
			label: 'Requests',
			component: <RequestsTab />,
			active: query.get('tabId') === '2',
		},
		{
			id: 3,
			label: 'Bookings',
			component: <BookingsTab />,
			active: query.get('tabId') === '3',
		},
		{
			id: 4,
			label: 'Information',
			component: <InformationTab />,
			active: query.get('tabId') === '4',
		},
		{
			id: 5,
			label: 'Teachers',
			component: <TeachersTab />,
			active: query.get('tabId') === '5',
		},
		{
			id: 6,
			label: 'Groups',
			component: <GroupsTab />,
			active: query.get('tabId') === '6',
		},
	]);

	async function initializeData() {
		try {
			setPageStatus('loading');
			const {
				data: { result },
			} = await fetchSchool({ schoolId });
			dispatch({ type: 'SET_SCHOOL', payload: result.school });
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}

	useEffect(() => {
		if (pageStatus === 'idle') {
			initializeData();
		}
	}, [pageStatus]);

	useEffect(() => {
		// Set document title
		document.title = `School Details | ${META_TITLE}`;
	}, []);

	function renderContent() {
		switch (pageStatus) {
			case 'loading':
				return <Spinner />;
			case 'success':
				return (
					<AdminSchoolDetailContext.Provider value={{ state, dispatch }}>
						<DetailPageHeader title={state.school.name} backButton={{ label: 'Back to Schools', onClick: () => history.push('/admin/schools') }} />
						<S.Wrapper>
							<Tab tabs={tabs} setTabs={setTabs} />
						</S.Wrapper>
					</AdminSchoolDetailContext.Provider>
				);
			case 'error':
				return <ErrorComponent />;
			default:
				return null;
		}
	}

	return <AdminNavigation>{renderContent()}</AdminNavigation>;
};

export default AdminSchoolDetail;
