import React, { useState, useImperativeHandle, forwardRef, useContext } from 'react';
import ReactModal from 'react-modal';
import { FormProvider, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { IconButton, Stepper, Typography, Button, MessageDialog } from '..';
import { completeBooking } from '../../services/bookings';
import { errorHandler } from '../../services/authService';
import { StepOne } from './StepOne';
import { StepTwo } from './StepTwo';
import { StepThree } from './StepThree';
import { StepFour } from './StepFour';
import * as S from './CompleteBookingModal.styles';

export const CompleteBookingModal = forwardRef(({ role, context }, ref) => {
	const {
		state: { booking },
		dispatch,
	} = useContext(context);
	const [isOpen, setIsOpen] = useState(false);
	const [bookingResult, setBookingResult] = useState(null);

	const [confirmationOpen, setConfirmationOpen] = useState(false);

	const stepOptions = [
		{ id: 1, label: 'Attendance' },
		{ id: 2, label: 'Businesses' },
		{ id: 3, label: 'Learning' },
		{ id: 4, label: 'Review' },
	];

	const [currentStep, setCurrentStep] = useState(0);

	const methods = useForm({
		mode: 'onChange',
	});

	const handleOpen = (bookingId) => {
		methods.setValue('bookingId', bookingId);
		methods.setValue('attendance', undefined);
		methods.setValue('attendanceAdditionalGroups', 0);
		methods.setValue('attendanceMaxAge', null);
		methods.setValue('attendanceMinAge', null);
		methods.setValue('genderAttendance', undefined);
		methods.setValue('selectedTeachers', null);
		methods.setValue('review', null);
		setIsOpen(true);
	};

	const handleClose = () => {
		setIsOpen(false);
		setTimeout(() => {
			methods.reset();
			setCurrentStep(0);
		}, 200);
	};

	const handleCloseConfirmation = () => {
		setConfirmationOpen(false);
		handleClose();
		dispatch({ type: 'setBooking', payload: bookingResult });
	};

	async function asyncCaller(data) {
		const { attendance, genderAttendance, selectedTeachers, ...rest } = data;

		rest.attendanceAdditionalGroups = Number(rest.attendanceAdditionalGroups);
		rest.attendanceMaxAge = Number(rest.attendanceMaxAge);
		rest.attendanceMinAge = Number(rest.attendanceMinAge);
		rest.attendanceGroupCount = Number(attendance.groupCount);
		rest.attendanceAdditionalCount = Number(attendance.additionalCount);
		rest.attendanceGenderMale = Number(genderAttendance.male);
		rest.attendanceGenderFemale = Number(genderAttendance.female);
		rest.attendanceGenderOther = Number(genderAttendance.other);
		rest.attendanceAdditionalTeachers = selectedTeachers.filter((teacher) => teacher.id !== 'not-in-the-system').map(({ id }) => id);
		rest.attendanceAdditionalTeachersContacts = selectedTeachers.filter((teacher) => teacher.id === 'not-in-the-system').map(({ firstName, lastName, email }) => ({ firstName, lastName, email }));

		const {
			data: { result },
		} = await completeBooking(rest);
		setBookingResult(result.booking);

		setConfirmationOpen(true);
	}

	const handleBackButton = () => {
		setCurrentStep((prev) => {
			const newValue = prev - 1;
			return newValue === 1 && Object.keys(booking?.review)?.length === 0 ? newValue - 1 : newValue;
		});
	};

	const onSubmit = (e) => {
		if (stepOptions.length === currentStep + 1) {
			methods.handleSubmit(asyncCaller)(e).catch(errorHandler);
		} else {
			methods
				.handleSubmit(() => {
					if (currentStep === 0) {
						const selectedTeachers = methods.getValues('selectedTeachers') || [];
						methods.setValue('selectedTeachers', selectedTeachers.splice(0, methods.getValues('attendanceAdditionalGroups')));
					}
					setCurrentStep((prev) => {
						const newValue = prev + 1;
						return newValue === 1 && Object.keys(booking?.review)?.length === 0 ? newValue + 1 : newValue;
					});
				})(e)
				.catch(errorHandler);
		}
	};

	useImperativeHandle(ref, () => ({
		open: handleOpen,
		close: handleClose,
	}));

	return (
		<ReactModal className="complete-booking-modal" ref={ref} closeTimeoutMS={200} isOpen={isOpen} onRequestClose={handleClose}>
			<FormProvider {...methods}>
				<S.Form onSubmit={onSubmit}>
					<IconButton className="close-button" type="button" icon={['fal', 'times']} onClick={handleClose} />
					<Stepper activeStep={currentStep} steps={stepOptions} maxWidth={25} />
					<div className="modal-content-wrapper">
						{currentStep === 0 && <StepOne role={role} context={context} />}
						{currentStep === 1 && <StepTwo role={role} context={context} />}
						{currentStep === 2 && <StepThree role={role} context={context} />}
						{currentStep === 3 && <StepFour role={role} context={context} />}
					</div>

					<S.ButtonsWrapper>
						{currentStep > 0 && (
							<>
								<Button variant="outline" variation="secondary" type="button" onClick={handleBackButton}>
									<Typography variation="button-medium" weight="bold">
										Back
									</Typography>
								</Button>
								<div className="flex-1" />
							</>
						)}
						<Button type="submit">
							<Typography weight="bold" variation="button-medium">
								{methods?.formState?.isSubmitting ? 'Loading' : stepOptions.length === currentStep + 1 ? 'Complete' : 'Next'}
							</Typography>
						</Button>
					</S.ButtonsWrapper>
				</S.Form>
			</FormProvider>
			<MessageDialog
				isOpen={confirmationOpen}
				onRequestClose={handleCloseConfirmation}
				title="Booking Completed"
				subtitle="Your booking has been completed!"
				icon={['fal', 'check']}
				actions={[{ id: 'close-confirmation', label: 'Close', variant: 'solid', variation: 'default', onClick: handleCloseConfirmation }]}
			/>
		</ReactModal>
	);
});

CompleteBookingModal.propTypes = {
	role: PropTypes.string,
	context: PropTypes.shape(),
};

CompleteBookingModal.displayName = 'CompleteBookingModal';
