import styled from 'styled-components';

import colors from '../../../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	width: 100%;

	.availability-info {
		width: 100%;
		flex: 0 0 100%;
		display: grid;
		grid-template-columns: 1fr;
		gap: 0.75rem;
		grid-template-areas:
			'a1'
			'a2';
		padding-bottom: 0.8rem;

		&__title {
			color: ${colors.neutralText};
			margin-bottom: 0.25rem;
		}
	}

	@media screen and (min-width: 992px) {
		flex-direction: row;

		.availability-picture {
			min-width: 16.875rem;
			max-width: 16.875rem;
		}

		.availability-info {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-template-areas: 'a1 a2';
		}
	}
`;

export const Form = styled.form`
	position: relative;
	padding: 3rem 1.25rem 0;

	.close-button {
		position: absolute;
		top: 1rem;
		right: 1rem;
		color: ${colors.neutralButton};
	}

	.error-wrapper {
		position: absolute;
		bottom: -1rem;
		color: ${colors.stateDanger};
	}
`;

export const InfoWrapper = styled.div`
	margin: 0 auto 0.75rem;
	width: 100%;
	max-width: 34.5rem;

	& > * + * {
		margin-top: 0.75rem;
	}
`;

export const FieldsWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	gap: 1.5rem;
	width: 100%;
	max-width: 29rem;
	margin: 2rem auto 0;

	.two-col {
		display: grid;
		gap: 1.25rem;
		grid-template-columns: 1fr;

		@media (min-width: 992px) {
			grid-template-columns: 1fr 1fr;
		}
	}

	.location-grid-row {
		display: grid;
		gap: 1rem;

		@media screen and (min-width: 410px) {
			grid-template-columns: 2fr 1fr 1fr;
		}
	}

	.error-wrapper {
		color: ${colors.stateDanger};
	}
`;

export const ButtonsWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin: 1.75rem -1.25rem 0;
	padding: 1rem;
	border-top: 1px solid ${colors.neutralForm};

	& > * + * {
		margin-left: 1rem;
	}
`;

export const RadioOptionsWrapper = styled.div`
	display: grid;
	gap: 1rem;
	position: relative;

	.field-label {
		color: ${colors.neutralText};

		&.with-error {
			color: ${colors.stateDanger};
		}
	}

	.radio-field {
		display: flex;
		align-items: center;

		& > * + * {
			margin-left: 0.75rem;
		}
	}
`;

export const DaysSelector = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	position: relative;
	gap: 0.5rem;

	.error-wrapper {
		bottom: -1rem;
		position: absolute;
	}

	.days-wrapper {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -0.375rem;
	}

	.day-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		min-width: 3.75rem;
		width: 100%;
		max-width: 3.75rem;
		height: 3.75rem;
		margin: 0.375rem;

		label {
			font-weight: 400;
			text-transform: capitalize;
		}

		input[type='checkbox'] {
			border-radius: 0.25rem;
			border: 1px solid ${colors.neutralForm};
			appearance: none;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			inset: 0;
			cursor: pointer;

			&:checked {
				border-width: 0.125rem;
				border-color: ${colors.brandPrimary};

				& + label {
					color: ${colors.brandPrimary};
					font-weight: 600;
				}
			}
		}
	}
`;

export const AddWrapper = styled.div`
	display: flex;
	align-items: center;
	margin: 1rem auto 0;
	width: 100%;

	button {
		margin-left: -1.5625rem;
	}
`;

export const LocationItem = styled.div`
	margin: 0 auto 1rem;
	width: 100%;
	position: relative;

	.location-remove {
		position: absolute;
		right: 0;
		top: 0;
		padding: 0;
		color: ${colors.stateDanger};
	}
`;
