import styled from 'styled-components';

import colors from '../../../styles/colors';

export const Wrapper = styled.div`
	display: grid;
	gap: 1rem;
	padding-bottom: 0.5rem;
`;

export const ToggleLine = styled.div`
	display: flex;
	justify-content: space-between;

	.line-info {
		display: flex;
		flex-direction: column;
	}

	.toggle-wrapper {
		margin-top: 0.25rem;
		margin-left: 0.5rem;
	}
`;
