import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { fetchBusinessProfile } from '../../services/directory';
import { recordView } from '../../services/metrics';
import { Spinner, TeacherNavigation, Tab, ErrorComponent, BusinessNavigation, AdminNavigation } from '../../components';
import { Header } from './Header';
import { OverviewTab } from './OverviewTab';
import { ResourcesTab } from './ResourcesTab';
import * as S from './DirectoryBusinessProfile.styles';
import { META_TITLE, ROLES, SOURCES } from '../../../constants/general.constants';

const DirectoryBusinessProfile = ({ role }) => {
	const { id } = useParams();
	const location = useLocation();
	const [pageStatus, setPageStatus] = useState('idle');
	const [businessProfile, setBusinessProfile] = useState(null);
	const tabsConfig = (profile) => [
		{
			id: 1,
			label: 'Overview',
			component: <OverviewTab business={profile} />,
		},
		{
			id: 2,
			label: 'Resources',
			component: <ResourcesTab business={profile} role={role} />,
		},
	];
	const [tabs, setTabs] = useState(tabsConfig({}));

	const backUrl = location.state?.backUrl || `/${role || 'teacher'}/directory/resources`;
	const backTitle = location.state?.backTitle || 'Back to Directory';

	async function initializeData() {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchBusinessProfile({ businessId: id });
			setBusinessProfile(result.business);
			setTabs(tabsConfig(result.business));
			setPageStatus('success');
			await recordView({ sourceType: SOURCES.BUSINESS, sourceId: id });
		} catch (error) {
			setPageStatus('error');
		}
	}

	useEffect(() => {
		// Set document title
		document.title = `Business Profile | Dashboard | ${META_TITLE}`;

		// Load initial data
		if (pageStatus === 'idle') {
			initializeData();
		}
	}, [pageStatus]);

	const renderContent = () => {
		switch (pageStatus) {
			case 'loading':
				return <Spinner />;
			case 'error':
				return <ErrorComponent />;
			case 'success':
				return (
					<S.Wrapper>
						<Header title={businessProfile.name} avatar={businessProfile.logoObj['800']} backUrl={backUrl} backTitle={backTitle} />
						<Tab tabs={tabs} setTabs={setTabs} />
					</S.Wrapper>
				);
			default:
				return null;
		}
	};

	if (role === ROLES.TEACHER) {
		return <TeacherNavigation>{renderContent()}</TeacherNavigation>;
	} else if (role === ROLES.BUSINESS) {
		return <BusinessNavigation>{renderContent()}</BusinessNavigation>;
	} else {
		return <AdminNavigation>{renderContent()}</AdminNavigation>;
	}
};

DirectoryBusinessProfile.propTypes = {
	role: PropTypes.string,
};

export default DirectoryBusinessProfile;
