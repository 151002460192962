import React, { forwardRef, useContext, useRef, useState, useImperativeHandle, useCallback } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';

import { CAREER_CLUSTERS, RESOURCE_CATEGORIES, ROLES } from '../../../../../constants/general.constants';
import { Button, Card, Checkbox, IconButton, Picture, TextArea, TextInput, Typography } from '../../../../components';
import { AppointmentDetailsContext } from '../../RequestsAppointmentDetails';
import { errorHandler } from '../../../../services/authService';
import { createRequest } from '../../../../services/requests/createRequestService';
import { uploadResources } from '../../../../services/media';
import * as S from './OverviewCard.styles';
import { required } from '../../../../utils/form-default-errors';
import { formatPayloadBeforeUpdate } from '../OverviewTab';
import { useSelector } from 'react-redux';

const RESOURCE_CATEGORY_OPTIONS = {
	appointment: RESOURCE_CATEGORIES.APPOINTMENT,
	information: RESOURCE_CATEGORIES.INFORMATION,
};

const OverviewEditModal = forwardRef((_, ref) => {
	const {
		state: { request },
		dispatch,
	} = useContext(AppointmentDetailsContext);
	const [isOpen, setIsOpen] = useState(false);
	const [step, setStep] = useState(0);

	const {
		handleSubmit,
		register,
		setValue,
		resetField,
		reset,
		watch,
		formState: { errors, isSubmitting },
	} = useForm();

	const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
		onDrop: useCallback((acceptedFiles) => acceptedFiles.length > 0 && setValue('coverImage', acceptedFiles[0], { shouldValidate: true }), []),
		multiple: false,
		accept: 'image/png, image/jpg, image/jpeg',
	});

	const renderCoverImage = (image) => {
		if (image) {
			if (image instanceof File) {
				return <img src={URL.createObjectURL(image)} alt="Cover" />;
			} else {
				return <img src={image[1500]} alt="Cover" />;
			}
		}
		return null;
	};

	const coverImage = watch('coverImage');

	const handleOpen = () => {
		setValue('category', request?.category);
		setValue('coverImage', request?.coverImageObj);
		setValue('title', request?.title);
		setValue('description', request?.description);
		setValue('careerClusters', request?.careerClusters);
		setIsOpen(true);
	};

	const handleClose = () => {
		setIsOpen(false);
		setTimeout(() => {
			setStep(0);
			reset();
		}, 200);
	};

	async function uploadCoverImage(file) {
		const formData = new FormData();
		formData.append('file', file);

		const { data } = await uploadResources(formData);

		return data;
	}

	async function asyncCaller(data) {
		const { coverImage, ...rest } = data;

		if (coverImage instanceof File) {
			const coverImageData = await uploadCoverImage(coverImage);
			rest.coverImageObj = coverImageData;
		} else {
			rest.coverImageObj = coverImage;
		}

		const newRequest = formatPayloadBeforeUpdate(request, { ...rest });

		const {
			data: { result },
		} = await createRequest(newRequest);
		dispatch({ type: 'setRequest', payload: result.request });
		handleClose();
	}

	const handleNextStep = () => setStep(step + 1);

	const onSubmit = (e) => {
		if (step === 0) {
			handleSubmit(handleNextStep)(e);
		} else {
			handleSubmit(asyncCaller)(e).catch(errorHandler);
		}
	};

	useImperativeHandle(ref, () => ({
		open: handleOpen,
		close: handleClose,
	}));

	return (
		<ReactModal className="overview-card-modal" ref={ref} closeTimeoutMS={200} isOpen={isOpen} onRequestClose={handleClose}>
			<S.Form onSubmit={onSubmit}>
				<IconButton className="close-button" type="button" icon={['fal', 'times']} onClick={handleClose} />
				<S.InfoWrapper>
					<Typography tag="h2" weight="extrablack" center>
						Overview Information
					</Typography>
					<Typography tag="p" center>
						Edit the information below to update the overview information for this request.
					</Typography>
				</S.InfoWrapper>
				{step === 0 && (
					<S.CategoryWrapper>
						<div className="radio-wrapper">
							{RESOURCE_CATEGORY_OPTIONS[request?.type].map(({ value, label, icon, description }) => (
								<div key={value} className={`radio-field${errors?.category ? ' with-error' : ''}`}>
									<input
										className="radio-input"
										type="radio"
										value={value}
										{...register('category', {
											required: required('Category'),
										})}
									/>
									<FontAwesomeIcon icon={['fal', icon]} size="4x" />
									<Typography tag="h5" weight="bold" center>
										{label}
									</Typography>
									<Typography tag="p" variation="2" center>
										{description}
									</Typography>
								</div>
							))}

							{!!errors?.category && (
								<div className="error-wrapper">
									<Typography tag="p" variation="2">
										{errors?.category?.message}
									</Typography>
								</div>
							)}
						</div>
					</S.CategoryWrapper>
				)}
				{step === 1 && (
					<S.FieldsWrapper>
						<S.HugeImageUploader withError={errors?.coverImage}>
							<Typography tag="label" htmlFor="cover-image" weight="semibold">
								Cover Image
							</Typography>
							<S.FileDropZone {...getRootProps()} isDragActive={isDragActive} isDragAccept={isDragAccept} isDragReject={isDragReject}>
								<input id="cover-image" {...register('coverImage', { required: required('Cover Image') })} {...getInputProps({ onChange: (e) => setValue('coverImage', e.target.files[0], { shouldValidate: true }) })} />
								{renderCoverImage(coverImage)}
							</S.FileDropZone>
							{coverImage && (
								<button className="reset-btn" type="button" onClick={() => resetField('coverImage')}>
									<FontAwesomeIcon icon={['fal', 'trash']} />
								</button>
							)}
							{!!errors?.coverImage && (
								<div className="error-wrapper">
									<Typography tag="p" variation="2">
										{errors?.coverImage?.message}
									</Typography>
								</div>
							)}
						</S.HugeImageUploader>
						<TextInput
							label="Title"
							id="title"
							error={errors?.title}
							{...register('title', {
								required: required('Title'),
							})}
						/>

						<TextArea
							label="Description"
							id="description"
							error={errors?.description}
							{...register('description', {
								required: required('Description'),
							})}
						/>

						<S.CareerClusterWrapper>
							<Typography className={`field-label${!!errors?.careerClusters ? ' with-error' : ''}`} tag="p" variation="2" weight="semibold">
								Career Cluster
							</Typography>

							{CAREER_CLUSTERS.map(({ value, label }) => (
								<div key={value} className="checkbox-field">
									<Checkbox id={value} value={value} {...register('careerClusters', { required: required('Career Cluster') })} />
									<label htmlFor={value}>{label}</label>
								</div>
							))}

							{!!errors?.careerClusters && (
								<div className="error-wrapper">
									<Typography tag="p" variation="2">
										{errors?.careerClusters?.message}
									</Typography>
								</div>
							)}
						</S.CareerClusterWrapper>
					</S.FieldsWrapper>
				)}
				<S.ButtonsWrapper>
					{step === 1 && (
						<Button variant="outline" variation="secondary" type="button" onClick={() => setStep(0)}>
							<Typography variation="button-medium" weight="bold">
								Back
							</Typography>
						</Button>
					)}
					<div className="flex-1" />
					<Button type="submit">
						<Typography weight="bold" variation="button-medium">
							{isSubmitting ? 'Loading' : step === 0 ? 'Next' : 'Save'}
						</Typography>
					</Button>
				</S.ButtonsWrapper>
			</S.Form>
		</ReactModal>
	);
});
OverviewEditModal.displayName = 'OverviewEditModal';

export const OverviewCard = ({ className, role }) => {
	const {
		state: { request },
	} = useContext(AppointmentDetailsContext);

	const user = useSelector((state) => state.user.value);

	const modalRef = useRef(null);

	const handleEditClick = () => {
		modalRef.current.open();
	};

	return (
		<Card
			title="Overview"
			className={className}
			transparentHeaderBorder
			actions={(role != null && request.creator != null && request.creator.id === user?.id) || role === ROLES.ADMIN || role === ROLES.SUPER ? [{ id: 1, label: 'Edit', size: 'small', variant: 'outline', onClick: handleEditClick, icon: { source: ['fal', 'edit'] } }] : []}
		>
			<S.Wrapper>
				<Picture className="overview-picture" aspectRatio="3/2" src={request?.coverImageObj?.['800']} />
				<div className="overview-info">
					<div className="overview-info__element">
						<Typography className="overview-info__title" tag="h6" weight="semibold">
							Description
						</Typography>
						<Typography className="overview-info__text" tag="p">
							{request?.description}
						</Typography>
					</div>
					<div className="overview-info__element">
						<Typography className="overview-info__title" tag="h6" weight="semibold">
							Resource Category
						</Typography>
						<Typography className="overview-info__text" tag="p">
							{request?.category}
						</Typography>
					</div>
					<div className="overview-info__element">
						{request?.type === 'information' ? (
							<>
								<Typography className="overview-info__title" tag="h6" weight="semibold">
									Resource Type
								</Typography>
								<Typography className="overview-info__text" tag="p">
									Information
								</Typography>
							</>
						) : (
							<>
								<Typography className="overview-info__title" tag="h6" weight="semibold">
									Date Requested
								</Typography>
								<Typography className="overview-info__text" tag="p">
									{request?.availabilityDateRange}
								</Typography>
							</>
						)}
					</div>
					<div className="overview-info__element">
						<Typography className="overview-info__title" tag="h6" weight="semibold">
							Career Cluster
						</Typography>
						<div className="overview-info__badges-wrapper">
							{request?.careerClusters?.map((careerCluster, index) => (
								<div className="overview-info__badge" key={index}>
									{careerCluster}
								</div>
							))}
						</div>
					</div>
				</div>
			</S.Wrapper>
			<OverviewEditModal ref={modalRef} />
		</Card>
	);
};

OverviewCard.propTypes = {
	className: PropTypes.string,
	role: PropTypes.string,
};
