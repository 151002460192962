import styled from 'styled-components';
import { Link } from 'react-router-dom';
import colors from '../../../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Business = styled(Link)`
	text-decoration: none;
	color: black;
	display: flex;
	align-items: center;
	border-bottom: 1px solid ${colors.neutralForm};
	margin-top: 0.75rem;
	padding-bottom: 0.75rem;

	&:first-child {
		margin-top: 0;
	}
	&:last-child {
		margin-bottom: 0.75rem;
	}

	.business {
		&__avatar {
			min-width: 3.75rem;
			width: 3.75rem;
			height: 3.75rem;
			border: 1px solid ${colors.neutralForm};
			border-radius: 9999px;
			margin-right: 0.75rem;
		}
		&__info {
			flex: 1;
			display: flex;
			flex-direction: column;

			& > * + * {
				margin-top: 0.25rem;
			}
		}
		&__details {
			display: flex;
			flex-direction: column;

			@media (min-width: 768px) {
				flex-direction: row;
			}
		}
		&__element {
			flex: 1;
			display: flex;
			align-items: center;
			color: ${colors.neutralText};

			& > * + * {
				margin-left: 0.5rem;
			}
		}
	}
`;
