import styled from 'styled-components';

import colors from '../../../styles/colors';

export const Wrapper = styled.div`
	display: grid;
	gap: 1rem;
	grid-template-columns: 1fr;
	padding-bottom: 0.5rem;

	.element-info {
		&__title {
			color: ${colors.neutralText};
			margin-bottom: 0.25rem;
		}
	}

	@media screen and (min-width: 992px) {
		grid-template-columns: repeat(2, 1fr);
	}
`;
