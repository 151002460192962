import styled from 'styled-components';

export const Wrapper = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	padding: 0 1rem 2rem;
`;

export const SingleWrapper = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	padding: 1rem 1rem 2rem;
`;
