import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip } from 'recharts';

import colors from '../../../styles/colors';
import { Card } from '../../Card';

export const BarChartGraph = ({ className, data = {}, title }) => {
	const [chartData, setChartData] = useState([]);

	useEffect(() => {
		setChartData(Object.keys(data).map((key) => ({ name: key, value: data[key] })));
	}, [data]);

	if (chartData.length === 0 || Object.keys(data).length === 0) {
		return null;
	}

	const formattedChartData = chartData.sort((a, b) => b.value - a.value);
	if (formattedChartData.length > 12) formattedChartData.length = 12;

	return (
		<Card className={className} title={title} transparentHeaderBorder>
			<ResponsiveContainer width="100%" height={400}>
				<BarChart layout="vertical" data={formattedChartData} barCategoryGap={3}>
					<XAxis type="number" fontSize={14} />
					<YAxis
						type="category"
						var
						dataKey="name"
						width={160}
						fontSize={14}
						tickFormatter={(value, index) => {
							const limit = 16; // put your maximum character
							if (value.length < limit) return value;
							return `${value.replace(/(\r\n|\n|\r)/gm, '').substring(0, limit)}...`;
						}}
					/>
					<Tooltip />
					<Bar dataKey="value" fill={colors.brandPrimary} />
				</BarChart>
			</ResponsiveContainer>
		</Card>
	);
};
BarChartGraph.propTypes = {
	className: PropTypes.string,
	data: PropTypes.object,
	title: PropTypes.string,
};
