import React, { useContext } from 'react';

import { TextInput, Typography } from '../../../../components';
import { BookingsContext, TABS_OPTIONS } from '../SentBookingsTab';
import * as S from './FilterSection.styles';

export const FilterSection = () => {
	const { state, dispatch } = useContext(BookingsContext);
	const { selectedTab, searchInputValue } = state;

	return (
		<S.Wrapper>
			<div className="filter-wrapper">
				<div className="filter-wrapper__badge-wrapper">
					{TABS_OPTIONS.map((option) => (
						<button key={option.value} className={`badge-button${selectedTab.value === option.value ? ' selected' : ''}`} onClick={() => dispatch({ type: 'SET_SELECTED_TAB', payload: option })}>
							<Typography tag="h6">{option.label}</Typography>
						</button>
					))}
				</div>
				<div className="filter-wrapper__search-input-wrapper">
					<TextInput value={searchInputValue} size="small" placeholder="Search" icon={['fal', 'search']} onChange={(e) => dispatch({ type: 'SET_SEARCH_INPUT_VALUE', payload: e.target.value })} />
				</div>
			</div>
		</S.Wrapper>
	);
};
