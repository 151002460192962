import React from 'react';
import PropTypes from 'prop-types';

import { formatGradeLevel } from '../../../../utils/content-formatter';
import { Card, Picture, Typography } from '../../../../components';
import * as S from './OverviewCard.styles';

export const OverviewCard = ({ role, className, booking }) => {
	return (
		<Card title="Overview" className={className} transparentHeaderBorder>
			<S.Wrapper>
				<Picture className="overview-picture" aspectRatio="3/2" src={booking.resource.coverImageObj['800']} />
				<div className="overview-info">
					<div className="overview-info__element">
						<Typography className="overview-info__title" tag="h6" weight="semibold">
							Description
						</Typography>
						<Typography className="overview-info__text" tag="p">
							{booking.resource.description}
						</Typography>
					</div>
					<div className="overview-info__element">
						<Typography className="overview-info__title" tag="h6" weight="semibold">
							Resource Category
						</Typography>
						<Typography className="overview-info__text" tag="p">
							{booking.resource.category}
						</Typography>
					</div>
					<div className="overview-info__element">
						<Typography className="overview-info__title" tag="h6" weight="semibold">
							Grades
						</Typography>
						<Typography className="overview-info__text" tag="p">
							{formatGradeLevel(booking.resource.gradeLevels, true)}
						</Typography>
					</div>
					<div className="overview-info__element">
						<Typography className="overview-info__title" tag="h6" weight="semibold">
							Career Cluster
						</Typography>
						<div className="overview-info__badges-wrapper">
							{booking.resource.careerClusters.map((careerCluster, index) => (
								<div className="overview-info__badge" key={index}>
									{careerCluster}
								</div>
							))}
						</div>
					</div>
				</div>
			</S.Wrapper>
		</Card>
	);
};

OverviewCard.propTypes = {
	className: PropTypes.string,
	booking: PropTypes.object,
	role: PropTypes.string,
};
