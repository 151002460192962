import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { fetchStaffMember } from '../../services/school';
import { recordView } from '../../services/metrics';
import { Spinner, TeacherNavigation, Tab, ErrorComponent } from '../../components';
import { Header } from './Header';
import { GroupsTab } from './GroupsTab';
import { BookingsTab } from './BookingsTab';
import { RequestsTab } from './RequestsTab';
import * as S from './TeacherSchoolStaffDetail.styles';
import { META_TITLE, SOURCES } from '../../../constants/general.constants';

const TeacherSchoolStaffDetail = () => {
	const { id } = useParams();
	const [pageStatus, setPageStatus] = useState('idle');
	const [staffProfile, setStaffProfile] = useState(null);
	const tabsConfig = (staff) => [
		{
			id: 1,
			label: 'Groups',
			component: <GroupsTab member={staff} />,
		},
		{
			id: 2,
			label: 'Bookings',
			component: <BookingsTab member={staff} />,
		},
		{
			id: 3,
			label: 'Requests',
			component: <RequestsTab member={staff} />,
		},
	];
	const [tabs, setTabs] = useState(tabsConfig({}));

	async function initializeData() {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchStaffMember({ userId: id });
			setStaffProfile(result.member);
			setTabs(tabsConfig(result.member));
			setPageStatus('success');
			await recordView({ sourceType: SOURCES.TEACHER, sourceId: id });
		} catch (error) {
			setPageStatus('error');
		}
	}

	useEffect(() => {
		// Set document title
		document.title = `Staff Profile | Dashboard | ${META_TITLE}`;

		// Load initial data
		if (pageStatus === 'idle') {
			initializeData();
		}
	}, [pageStatus]);

	const renderContent = () => {
		switch (pageStatus) {
			case 'loading':
				return <Spinner />;
			case 'error':
				return <ErrorComponent />;
			case 'success':
				return (
					<S.Wrapper>
						<Header title={`${staffProfile.firstName} ${staffProfile.lastName}`} email={staffProfile.email} />
						<Tab tabs={tabs} setTabs={setTabs} />
					</S.Wrapper>
				);
			default:
				return null;
		}
	};

	return <TeacherNavigation>{renderContent()}</TeacherNavigation>;
};

export default TeacherSchoolStaffDetail;
