import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDebouncedFn } from 'beautiful-react-hooks';
import toast from 'react-hot-toast';

import { RESOURCE_SORT_OPTIONS, ROLES, META_TITLE } from '../../../constants/general.constants';
import { fetchResources, saveUnsaveResource } from '../../services/directory';
import { TeacherNavigation, PublicNavigation, PageHeader, Spinner, ErrorComponent, FilterSection, SavedSearchSection, Button, Typography, BusinessNavigation, AdminNavigation } from '../../components';
import { DirectoryCardsGrid } from './DirectoryCardsGrid';
import * as S from './Directory.styles';

let selectedSearchId = null;
let manualUpdate = false;

const Directory = ({ role }) => {
	const history = useHistory();
	const [pageStatus, setPageStatus] = useState('idle');
	const [resources, setResources] = useState([]);
	const [currentSelectedFilters, setCurrentSelectedFilters] = useState({});

	const initialPageNumber = 0;
	const [pageNumber, setPageNumber] = useState(initialPageNumber);
	const [totalPages, setTotalPages] = useState(undefined);
	const [totalItems, setTotalItems] = useState(0);
	const [loadingMore, setLoadingMore] = useState(false);

	const filterRef = useRef();

	const handleSaveUnsave = async (id) => {
		try {
			const {
				data: { result },
			} = await saveUnsaveResource({ resourceId: id });
			const newResources = [...resources];
			newResources[newResources.findIndex((el) => el.id === result.resource.id)] = result.resource;
			setResources(newResources);
		} catch (error) {}
	};

	const onChangeCallback = useDebouncedFn(async () => {
		setPageStatus('loading');
		try {
			setCurrentSelectedFilters(filterRef?.current?.value);
			const {
				data: { result },
			} = await fetchResources({ page: initialPageNumber, isPublic: role == null, ...filterRef.current.value, searchId: selectedSearchId });
			setResources(result.resources);
			setPageNumber(initialPageNumber);
			setTotalPages(result.totalPages);
			setTotalItems(result.totalItems);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
		if (manualUpdate) {
			manualUpdate = false;
			selectedSearchId = null;
		}
	}, 300);

	const onSelectCallback = (selectedSearch) => {
		filterRef.current.resetFilterCount();
		filterRef.current.setDistance(selectedSearch.distance);
		filterRef.current.setCounties(selectedSearch.counties);
		filterRef.current.setLocationTypes(selectedSearch.locationTypes);
		filterRef.current.setCategories(selectedSearch.categories);
		filterRef.current.setCareerClusters(selectedSearch.careerClusters);
		filterRef.current.setAgeGroups(selectedSearch.ageGroups);
		filterRef.current.setEmployabilitySkills(selectedSearch.employabilitySkills);
		filterRef.current.setCurriculumStandards(selectedSearch.curriculumStandards);
		selectedSearchId = selectedSearch.id;
		manualUpdate = true;
	};

	async function initializeData() {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchResources({ page: initialPageNumber, isPublic: role == null });
			setResources(result.resources);
			setTotalPages(result.totalPages);
			setTotalItems(result.totalItems);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}

	async function loadMore() {
		setLoadingMore(true);
		try {
			if (totalPages > pageNumber + 1) {
				const {
					data: { result },
				} = await fetchResources({ page: pageNumber + 1, isPublic: role == null, ...filterRef.current.value, searchId: selectedSearchId });
				setResources((prev) => [...prev, ...result.resources]);
				setTotalPages(result.totalPages);
				setTotalItems(result.totalItems);
				setPageNumber((prev) => prev + 1);
			} else {
				toast.error('No more resources to load');
			}
			setLoadingMore(false);
		} catch (error) {
			setLoadingMore(false);
		}
	}

	useEffect(() => {
		// Set document title
		document.title = role != null ? `Resource Directory | Dashboard | ${META_TITLE}` : `Resource Directory | ${META_TITLE}`;

		// Load initial data
		if (pageStatus === 'idle') {
			initializeData();
		}
	}, []);

	const renderContent = () => {
		switch (pageStatus) {
			case 'loading':
				return <Spinner />;
			case 'error':
				return <ErrorComponent />;
			case 'success':
				return (
					<>
						<DirectoryCardsGrid data={resources} role={role} handleSaveUnsave={handleSaveUnsave} totalItems={totalItems} />
						{resources?.length > 0 && totalPages > pageNumber + 1 && (
							<S.LoadMoreWrapper>
								<Button variant="outline" variation="secondary" type="button" onClick={loadMore}>
									<Typography variation="button-medium" weight="bold">
										{loadingMore ? 'Loading...' : 'Load More'}
									</Typography>
								</Button>
							</S.LoadMoreWrapper>
						)}
					</>
				);
			default:
				return null;
		}
	};

	switch (role) {
		case ROLES.ADMIN:
		case ROLES.SUPER:
			return (
				<AdminNavigation>
					<PageHeader
						title="Directory"
						action={[
							{ id: 1, onClick: () => history.push('/admin/create-request'), label: 'Create Request', icon: ['fal', 'plus'], type: 'outline' },
							{ id: 2, onClick: () => history.push('/admin/create-resource'), label: 'Create Resource', icon: ['fal', 'plus'] },
						]}
					/>
					<S.Wrapper>
						<SavedSearchSection currentSelectedFilters={currentSelectedFilters} onSelectCallback={onSelectCallback} />
						<FilterSection
							ref={filterRef}
							onChangeCallback={onChangeCallback}
							initialState={{
								searchText: '',
								sortOption: RESOURCE_SORT_OPTIONS[2],
								showAdvancedFilters: false,
								activeFilters: [],
								distance: 0,
								counties: [],
								locationTypes: [],
								categories: [],
								careerClusters: [],
								ageGroups: [],
								employabilitySkills: [],
								curriculumStandards: [],
							}}
							sortOptions={RESOURCE_SORT_OPTIONS}
						/>
						{renderContent()}
					</S.Wrapper>
				</AdminNavigation>
			);
		case ROLES.TEACHER:
			return (
				<TeacherNavigation>
					<PageHeader title="Directory" action={[{ id: 1, onClick: () => history.push('/teacher/create-request'), label: 'Create Request', icon: ['fal', 'plus'] }]} />
					<S.Wrapper>
						<SavedSearchSection currentSelectedFilters={currentSelectedFilters} onSelectCallback={onSelectCallback} />
						<FilterSection
							ref={filterRef}
							onChangeCallback={onChangeCallback}
							initialState={{
								searchText: '',
								sortOption: RESOURCE_SORT_OPTIONS[2],
								showAdvancedFilters: false,
								activeFilters: [],
								distance: 0,
								counties: [],
								locationTypes: [],
								categories: [],
								careerClusters: [],
								ageGroups: [],
								employabilitySkills: [],
								curriculumStandards: [],
							}}
							sortOptions={RESOURCE_SORT_OPTIONS}
						/>
						{renderContent()}
					</S.Wrapper>
				</TeacherNavigation>
			);
		case ROLES.BUSINESS:
			return (
				<BusinessNavigation>
					<PageHeader
						title="Directory"
						action={[
							{ id: 1, onClick: () => history.push('/business/create-request'), label: 'Create Request', icon: ['fal', 'plus'], type: 'outline' },
							{ id: 2, onClick: () => history.push('/business/create-resource'), label: 'Create Resource', icon: ['fal', 'plus'] },
						]}
					/>
					<S.Wrapper>
						<SavedSearchSection currentSelectedFilters={currentSelectedFilters} onSelectCallback={onSelectCallback} />
						<FilterSection
							ref={filterRef}
							onChangeCallback={onChangeCallback}
							initialState={{
								searchText: '',
								sortOption: RESOURCE_SORT_OPTIONS[2],
								showAdvancedFilters: false,
								activeFilters: [],
								distance: 0,
								counties: [],
								locationTypes: [],
								categories: [],
								careerClusters: [],
								ageGroups: [],
								employabilitySkills: [],
								curriculumStandards: [],
							}}
							sortOptions={RESOURCE_SORT_OPTIONS}
						/>
						{renderContent()}
					</S.Wrapper>
				</BusinessNavigation>
			);
		default:
			return (
				<PublicNavigation>
					<PageHeader title="Directory" />
					<S.Wrapper>
						<FilterSection
							ref={filterRef}
							onChangeCallback={onChangeCallback}
							initialState={{
								searchText: '',
								sortOption: RESOURCE_SORT_OPTIONS[0],
								showAdvancedFilters: false,
								activeFilters: [],
								distance: 0,
								counties: [],
								locationTypes: [],
								categories: [],
								careerClusters: [],
								ageGroups: [],
								employabilitySkills: [],
								curriculumStandards: [],
							}}
							sortOptions={RESOURCE_SORT_OPTIONS}
							filterOptions={['counties', 'locationTypes', 'categories', 'careerClusters', 'ageGroups', 'employabilitySkills', 'curriculumStandards']}
						/>
						{renderContent()}
					</S.Wrapper>
				</PublicNavigation>
			);
	}
};

Directory.propTypes = {
	role: PropTypes.string,
};

export default Directory;
