import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDebouncedFn } from 'beautiful-react-hooks';

import { REQUEST_SORT_OPTIONS } from '../../../../constants/general.constants';
import { fetchRequests } from '../../../services/requests';
import { Spinner, ErrorComponent, FilterSection, Button, Typography } from '../../../components';
import { DirectoryCardsGrid } from './DirectoryCardsGrid';
import * as S from './PublicRequests.styles';

const PublicRequests = ({ role }) => {
	const [pageStatus, setPageStatus] = useState('idle');
	const [requests, setRequests] = useState([]);

	const initialPageNumber = 0;
	const [pageNumber, setPageNumber] = useState(initialPageNumber);
	const [totalPages, setTotalPages] = useState(undefined);
	const [totalItems, setTotalItems] = useState(0);
	const [loadingMore, setLoadingMore] = useState(false);

	const filterRef = useRef();

	const onChangeCallback = useDebouncedFn(async () => {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchRequests({ page: initialPageNumber, filterOptions: { status: 'pending', general: true }, isPublic: role == null, ...filterRef.current.value });
			setRequests(result.requests);
			setPageNumber(initialPageNumber);
			setTotalPages(result.totalPages);
			setTotalItems(result.totalItems);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}, 300);

	async function loadMore() {
		setLoadingMore(true);
		try {
			if (totalPages > pageNumber + 1) {
				const {
					data: { result },
				} = await fetchRequests({ page: pageNumber + 1, filterOptions: { status: 'pending', general: true }, isPublic: role == null, ...filterRef.current.value });
				setRequests((prev) => [...prev, ...result.requests]);
				setTotalPages(result.totalPages);
				setTotalItems(result.totalItems);
				setPageNumber((prev) => prev + 1);
			} else {
				toast.error('No more requests to load');
			}
			setLoadingMore(false);
		} catch (error) {
			setLoadingMore(false);
		}
	}

	async function initializeData() {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchRequests({ page: initialPageNumber, filterOptions: { status: 'pending', general: true }, isPublic: role == null });
			setRequests(result.requests);
			setTotalPages(result.totalPages);
			setTotalItems(result.totalItems);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}

	useEffect(() => {
		// Load initial data
		if (pageStatus === 'idle') {
			initializeData();
		}
	}, []);

	const renderContent = () => {
		switch (pageStatus) {
			case 'loading':
				return <Spinner />;
			case 'error':
				return <ErrorComponent />;
			case 'success':
				return (
					<>
						<DirectoryCardsGrid data={requests} role={role} totalItems={totalItems} />
						{requests?.length > 0 && totalPages > pageNumber + 1 && (
							<S.LoadMoreWrapper>
								<Button variant="outline" variation="secondary" type="button" onClick={loadMore}>
									<Typography variation="button-medium" weight="bold">
										{loadingMore ? 'Loading...' : 'Load More'}
									</Typography>
								</Button>
							</S.LoadMoreWrapper>
						)}
					</>
				);
			default:
				return null;
		}
	};

	return (
		<S.Wrapper>
			<FilterSection
				ref={filterRef}
				onChangeCallback={onChangeCallback}
				initialState={{
					searchText: '',
					sortOption: REQUEST_SORT_OPTIONS[0],
					showAdvancedFilters: false,
					activeFilters: [],
					distance: 0,
					counties: [],
					locationTypes: [],
					categories: [],
					careerClusters: [],
					ageGroups: [],
					employabilitySkills: [],
					curriculumStandards: [],
				}}
				sortOptions={REQUEST_SORT_OPTIONS}
				filterOptions={['counties', 'locationTypes', 'categories', 'careerClusters', 'ageGroups']}
			/>
			{renderContent()}
		</S.Wrapper>
	);
};

PublicRequests.propTypes = {
	role: PropTypes.string,
};

export default PublicRequests;
