import React, { useContext, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';

import { RatingStars, TextArea, Typography } from '../..';
import { required } from '../../../utils/form-default-errors';

export const StepTwo = ({ role, context }) => {
	const {
		state: {
			booking: { review },
		},
	} = useContext(context);

	const {
		register,
		control,
		setValue,
		getValues,
		formState: { errors },
	} = useFormContext();

	useEffect(() => {
		const mappedReview = { businessId: review.business.id };
		if (getValues('review.rating')) {
			mappedReview.rating = getValues('review.rating');
		}
		if (getValues('review.comments')) {
			mappedReview.comments = getValues('review.comments');
		}
		setValue('review', mappedReview);
	}, [review]);

	return (
		<div className="modal-content">
			<Typography className="bigger-title" tag="h1" weight="extrablack" center>
				Businesses
			</Typography>
			<Typography tag="p" className="description" center>
				Please review the businesses who were at the appointment. This is a private review and will not be seen by the business.
			</Typography>

			<div className="fields-wrapper">
				<div className="business-review-item">
					<div className="business-review-item__image-wrapper">
						<img src={review?.business?.logoObj?.[200]} alt={review?.business?.name} />
					</div>
					<div className="business-review-item__fields-wrapper">
						<Typography tag="h3" weight="bold">
							{review?.business?.name}
						</Typography>
						<Controller name="review.rating" control={control} rules={{ required: required('Rating') }} render={({ field: { onChange, value } }) => <RatingStars label="Rating" value={value} onChange={onChange} error={errors?.review?.rating} />} />
						<TextArea
							label="Comments"
							id="comments"
							error={errors?.review?.comments}
							{...register('review.comments', {
								required: required('Comments'),
							})}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

StepTwo.propTypes = {
	role: PropTypes.string,
	context: PropTypes.shape(),
};
