import styled from 'styled-components';

import colors from '../../../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;

	& > * + * {
		margin-top: 1rem;
	}

	.overview-picture {
		padding-bottom: 0.5rem;

		picture {
			border-radius: 0.375rem;
		}
	}

	.overview-info {
		display: grid;
		grid-template-columns: 1fr;
		gap: 0.75rem;
		padding-bottom: 0.5rem;

		&__title {
			color: ${colors.neutralText};
			margin-bottom: 0.25rem;
		}

		&__badges-wrapper {
			display: flex;
			flex-wrap: wrap;
			margin: -0.25rem;
		}

		&__badge {
			padding: 0.25rem 0.5rem;
			border-radius: 6.25rem;
			border: 1px solid ${colors.brandPrimary};
			font-size: 0.75rem;
			color: ${colors.brandPrimary};
			font-weight: 700;
			margin: 0.25rem;
		}
	}

	@media screen and (min-width: 992px) {
		flex-direction: row;

		& > * + * {
			margin-top: 0;
			margin-left: 1rem;
		}

		.overview-picture {
			min-width: 16.875rem;
			max-width: 16.875rem;
		}

		.overview-info {
			display: grid;
			grid-template-columns: repeat(2, 1fr);

			&__element {
				&:first-child,
				&:last-child {
					grid-column: 1 / 3;
				}
			}
		}
	}
`;
