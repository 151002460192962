import React, { forwardRef, useImperativeHandle, useState, useContext, useCallback } from 'react';
import ReactModal from 'react-modal';
import { useForm } from 'react-hook-form';
import { Button, IconButton, TextArea, TextInput, Typography } from '../../../components';

import { ACCEPTABLE_FILE_TYPES, RESOURCE_CATEGORIES } from '../../../../constants/general.constants';
import { AdminTemplateContext } from '../AdminTemplate';
import { errorHandler } from '../../../services/authService';
import * as S from './FileCardEditModal.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { required } from '../../../utils/form-default-errors';
import { bytesToSize } from '../../../utils/filesize-formatter';
import { useDropzone } from 'react-dropzone';
import { uploadResources } from '../../../services/media';
import { createUpdateTemplate } from '../../../services/templates';

const RESOURCE_CATEGORY_OPTIONS = {
	appointment: RESOURCE_CATEGORIES.APPOINTMENT,
	information: RESOURCE_CATEGORIES.INFORMATION,
};

export const FileCardEditModal = forwardRef((_, ref) => {
	const {
		state: { template },
		dispatch,
	} = useContext(AdminTemplateContext);
	const [isOpen, setIsOpen] = useState(false);
	const [step, setStep] = useState(0);

	const {
		handleSubmit,
		control,
		setValue,
		register,
		getValues,
		watch,
		reset,
		resetField,
		formState: { errors, isSubmitting },
	} = useForm();

	const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
		onDrop: useCallback((acceptedFiles) => acceptedFiles.length > 0 && setValue('uploadFile', acceptedFiles[0]), []),
		multiple: false,
		accept: ACCEPTABLE_FILE_TYPES.RESOURCE_MEDIA.MIME_TYPES,
	});

	const uploadFile = watch('uploadFile');

	const handleOpenModal = () => {
		setValue('templateId', template.id);
		setValue('type', template.type);
		setValue('resourceType', template.resourceType);
		setValue('category', template.categories?.[0]);
		setValue('uploadFile', template.fileUrl);
		setValue('uploadFile.title', template.fileTitle);
		setValue('uploadFile.description', template.fileDescription);
		setIsOpen(true);
	};

	const handleCloseModal = () => {
		setIsOpen(false);
		setTimeout(() => {
			setStep(0);
			reset();
		}, 200);
	};

	const handleNextStep = () => setStep(step + 1);

	async function uploadCoverImage(file) {
		const formData = new FormData();
		formData.append('file', file);

		const { data } = await uploadResources(formData);

		return data;
	}

	async function asyncCaller(data) {
		const { uploadFile, ...rest } = data;
		if (uploadFile instanceof File) {
			const uploadFileData = await uploadCoverImage(uploadFile);
			rest.fileUrl = uploadFileData.document;
		} else {
			rest.fileUrl = template.fileUrl;
		}
		rest.fileTitle = uploadFile.title;
		rest.fileDescription = uploadFile.description;
		const {
			data: { result },
		} = await createUpdateTemplate(rest);
		dispatch({ type: 'SET_TEMPLATE', payload: result.template });
		handleCloseModal();
	}

	const onSubmit = (e) => {
		switch (step) {
			case 0:
			case 1:
				handleSubmit(handleNextStep)(e);
				break;
			default:
				handleSubmit(asyncCaller)(e).catch(errorHandler);
				break;
		}
	};

	useImperativeHandle(
		ref,
		() => ({
			open: handleOpenModal,
			close: handleCloseModal,
		}),
		[handleOpenModal, handleCloseModal]
	);

	return (
		<ReactModal className="template-card-edit-modal" ref={ref} closeTimeoutMS={200} isOpen={isOpen} onRequestClose={handleCloseModal}>
			<S.Form onSubmit={onSubmit}>
				<IconButton className="close-button" type="button" icon={['fal', 'times']} onClick={handleCloseModal} />
				<div className="modal-content">
					<header className="modal-content__header">
						<Typography tag="h2" weight="extrablack" center>
							Edit Template
						</Typography>
						<Typography tag="p" center>
							Please update your template here.
						</Typography>
					</header>

					<section className="modal-content__section">
						{step === 0 && (
							<>
								<Typography tag="h3" weight="extrablack" center>
									Resource Type
								</Typography>
								<div className="radio-wrapper">
									<div className={`radio-field${errors.resourceType ? ' with-error' : ''}`}>
										<input
											className="radio-input"
											type="radio"
											id="appointment"
											value="appointment"
											{...register('resourceType', {
												required: required('Resource Type'),
											})}
										/>
										<FontAwesomeIcon icon={['fal', 'calendar']} size="4x" />
										<Typography tag="h5" weight="bold" center>
											Appointment
										</Typography>
										<Typography tag="p" variation="2" center>
											Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.
										</Typography>
									</div>
									<div className={`radio-field${errors.resourceType ? ' with-error' : ''}`}>
										<input
											className="radio-input"
											type="radio"
											id="information"
											value="information"
											{...register('resourceType', {
												required: required('Template Type'),
											})}
										/>
										<FontAwesomeIcon icon={['fal', 'info-circle']} size="4x" />
										<Typography tag="h5" weight="bold" center>
											Information
										</Typography>
										<Typography tag="p" variation="2" center>
											Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.
										</Typography>
									</div>

									{!!errors.resourceType && (
										<div className="error-wrapper">
											<Typography tag="p" variation="2">
												{errors.resourceType.message}
											</Typography>
										</div>
									)}
								</div>
							</>
						)}
						{step === 1 && (
							<>
								<Typography tag="h3" weight="extrablack" center>
									Category
								</Typography>
								<div className="radio-wrapper">
									{RESOURCE_CATEGORY_OPTIONS[getValues('resourceType')]?.map(({ value, label, icon, description }) => (
										<div key={value} className={`radio-field${errors.category ? ' with-error' : ''}`}>
											<input
												className="radio-input"
												type="radio"
												value={value}
												{...register('category', {
													required: required('Category'),
												})}
											/>
											<FontAwesomeIcon icon={['fal', icon]} size="4x" />
											<Typography tag="h5" weight="bold" center>
												{label}
											</Typography>
											<Typography tag="p" variation="2" center>
												{description}
											</Typography>
										</div>
									))}

									{!!errors.category && (
										<div className="error-wrapper">
											<Typography tag="p" variation="2">
												{errors.category.message}
											</Typography>
										</div>
									)}
								</div>
							</>
						)}
						{step === 2 && (
							<>
								<Typography tag="h3" weight="extrablack" center>
									File Template
								</Typography>
								<div className="fields-wrapper">
									<S.HugeImageUploader withError={errors.uploadFile}>
										<Typography tag="label" htmlFor="cover-image" weight="semibold">
											File Upload
										</Typography>
										<S.FileDropZone alternative {...getRootProps()} isDragActive={isDragActive} isDragAccept={isDragAccept} isDragReject={isDragReject}>
											<input id="cover-image" {...register('uploadFile', { required: required('File Upload') })} {...getInputProps({ onChange: (e) => setValue('uploadFile', e.target.files[0]) })} />

											<div className="upload-placeholder">
												<div className="upload-placeholder__picture">
													<FontAwesomeIcon icon={['fal', 'image']} size="2x" />
												</div>
												<Typography tag="p" variation="2" center className="upload-placeholder__paragraph">
													Drag and drop files here, or <strong>browse</strong>
													<br />
													Supports PDF, Doc, PPT, JPG, PNG
												</Typography>
											</div>
										</S.FileDropZone>
										{uploadFile && (
											<button className="reset-btn" type="button" onClick={() => resetField('uploadFile')}>
												<FontAwesomeIcon icon={['fal', 'trash']} />
											</button>
										)}
										{!!errors.uploadFile && (
											<div className="error-wrapper">
												<Typography tag="p" variation="2">
													{errors.uploadFile.message}
												</Typography>
											</div>
										)}
									</S.HugeImageUploader>

									{uploadFile && (
										<div className="files-wrapper">
											<div className="files-wrapper__item">
												<header>
													<Typography tag="h4" weight="bold">
														File
													</Typography>
													<IconButton type="button" icon={['fal', 'trash']} size={1} onClick={() => resetField('uploadFile')} />
												</header>

												<div className="files-wrapper__item__info">
													<Typography tag="h6" weight="semibold">
														File
													</Typography>
													<div className="files-wrapper__item__details">
														<FontAwesomeIcon icon={['fal', 'file']} size="lg" />
														<div>
															<Typography tag="p">{uploadFile.name || template.fileName}</Typography>
															{uploadFile.size && (
																<Typography tag="p" variation="2">
																	{bytesToSize(uploadFile.size)}
																</Typography>
															)}
														</div>
													</div>
												</div>

												<TextInput label="Title" error={errors.uploadFile?.title} {...register('uploadFile.title', { required: required('Title') })} />

												<TextArea label="Description" error={errors.uploadFile?.description} {...register('uploadFile.description', { required: required('Description') })} />
											</div>
										</div>
									)}
								</div>
							</>
						)}
					</section>

					<div className="modal-footer">
						{step !== 0 && (
							<Button variant="outline" variation="secondary" type="button" onClick={() => setStep((prev) => prev - 1)}>
								<Typography variation="button-medium" weight="bold">
									Back
								</Typography>
							</Button>
						)}
						<div className="flex-1" />
						<Button type="submit">
							<Typography weight="bold" variation="button-medium">
								{isSubmitting ? 'Loading' : step === 2 ? 'Save' : 'Next'}
							</Typography>
						</Button>
					</div>
				</div>
			</S.Form>
		</ReactModal>
	);
});

FileCardEditModal.displayName = 'FileCardEditModal';
