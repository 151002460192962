import React, { useState, useEffect, useRef, createContext, useReducer } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { fetchInformationResource, archiveUnarchiveInformationResource, reviewInformationResource } from '../../services/information';
import { recordView } from '../../services/metrics';
import { saveUnsaveResource } from '../../services/directory';
import { TeacherNavigation, Spinner, DetailPageHeader, IconButton, Button, Typography, ErrorComponent, PublicNavigation, MessageDialog, BusinessNavigation, AdminNavigation } from '../../components';
import * as S from './InformationDetails.styles';
import { OverviewCard } from './OverviewCard';
import { LinksCard } from './LinksCard';
import { FilesCard } from './FilesCard';
import { PrivateFilesCard } from './PrivateFilesCard';
import { BusinessCard } from './BusinessCard';
import { LearningConnectionsCard } from './LearningConnectionsCard';
import { StartReviewModal } from './StartReviewModal';
import { AGE_GROUPS, META_TITLE, RESOURCE_STATUSES, ROLES, SOURCES } from '../../../constants/general.constants';
import { useSelector } from 'react-redux';
import { duplicateResource, updatePublishStatusResource } from '../../services/resources';
import { errorHandler } from '../../services/authService';

export const InformationDetailsContext = createContext();

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_RESOURCE':
			return {
				...state,
				resource: action.payload,
			};
		default:
			return state;
	}
};

export const formatPayloadBeforeUpdate = (resource, payload) => {
	return {
		...resource,
		resourceId: resource?.id,
		startDate: resource?.availabilityStartDate,
		endDate: resource?.availabilityEndDate,
		days: resource?.availabilityDays,
		isOngoing: resource?.availabilityIsOngoing,
		ageGroups: [...new Set((resource?.gradeLevels || []).map((level) => AGE_GROUPS.find((group) => group.values.includes(level)).value))],
		...payload,
	};
};

const InformationDetails = ({ role }) => {
	const { id } = useParams();
	const history = useHistory();
	const location = useLocation();
	const [pageStatus, setPageStatus] = useState('idle');
	const [archiveIsOpen, setArchiveIsOpen] = useState(false);
	const [duplicateIsOpen, setDuplicateIsOpen] = useState(false);
	const [publishIsOpen, setPublishIsOpen] = useState(false);
	const [skipReviewIsOpen, setSkipReviewIsOpen] = useState(false);
	const [state, dispatch] = useReducer(reducer, {});
	const user = useSelector((state) => state.user.value);

	const backUrl = location.state?.backUrl || `/${role || 'teacher'}/information`;
	const backTitle = location.state?.backTitle || 'Back to Information';

	const startReviewModalRef = useRef(null);

	function setInformationDetails(resource) {
		dispatch({ type: 'SET_RESOURCE', payload: resource });
	}

	function handleOpenStartReviewModal() {
		startReviewModalRef.current.open(id);
	}

	function handleOpenArchiveModal() {
		setArchiveIsOpen(true);
	}

	function handleCloseArchiveModal() {
		setArchiveIsOpen(false);
	}

	function handleOpenSkipReviewModal() {
		setSkipReviewIsOpen(true);
	}

	function handleCloseSkipReviewModal() {
		setSkipReviewIsOpen(false);
	}

	function handleOpenDuplicateModal() {
		setDuplicateIsOpen(true);
	}

	function handleCloseDuplicateModal() {
		setDuplicateIsOpen(false);
	}

	function handleOpenPublishModal() {
		setPublishIsOpen(true);
	}

	function handleClosePublishModal() {
		setPublishIsOpen(false);
	}

	const handleDuplicateResource = async () => {
		setDuplicateIsOpen(false);
		try {
			const {
				data: { result },
			} = await duplicateResource({ resourceId: id });
			history.push(`/${role || 'teacher'}/information/${result.resource.id}`);
		} catch (error) {
			errorHandler(error);
		}
	};

	const handlePublishResource = async () => {
		setPublishIsOpen(false);
		try {
			const {
				data: { result },
			} = await updatePublishStatusResource(id);
			dispatch({ type: 'SET_RESOURCE', payload: result.resource });
		} catch (error) {}
	};

	const handleArchiveUnarchive = async () => {
		setArchiveIsOpen(false);
		try {
			const {
				data: { result },
			} = await archiveUnarchiveInformationResource({ resourceId: id });
			dispatch({ type: 'SET_RESOURCE', payload: result.resource });
		} catch (error) {}
	};

	const handleSkipReview = async () => {
		setSkipReviewIsOpen(false);
		try {
			const {
				data: { result },
			} = await reviewInformationResource({ resourceId: id, action: 'skip' });
			dispatch({ type: 'SET_RESOURCE', payload: result.resource });
		} catch (error) {}
	};

	async function initializeData() {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchInformationResource({ resourceId: id });
			dispatch({ type: 'SET_RESOURCE', payload: result.resource });
			setPageStatus('success');
			await recordView({ sourceType: SOURCES.RESOURCE, sourceId: id });
		} catch (error) {
			setPageStatus('error');
		}
	}

	const handleSaveUnsaveResource = async () => {
		try {
			const {
				data: { result },
			} = await saveUnsaveResource({ resourceId: id });
			dispatch({ type: 'SET_RESOURCE', payload: result.resource });
		} catch (error) {}
	};

	useEffect(() => {
		// Set document title
		document.title = role != null ? `Information Details | Dashboard | ${META_TITLE}` : `Information Details | ${META_TITLE}`;

		// Load initial data
		if (pageStatus === 'idle') {
			initializeData();
		}
	}, [pageStatus]);

	const renderContent = () => {
		switch (pageStatus) {
			case 'loading':
				return <Spinner />;
			case 'error':
				return <ErrorComponent />;
			case 'success':
				return (
					<InformationDetailsContext.Provider value={{ state, dispatch }}>
						<DetailPageHeader
							title={state.resource.title}
							subtitle={state.resource.business.name}
							badge={(role === ROLES.ADMIN || role === ROLES.SUPER) && state.resource != null && state.resource.status != null ? state.resource.status[0].toUpperCase() + state.resource.status.slice(1) : undefined}
							backButton={{ label: backTitle, onClick: () => history.push(backUrl) }}
						>
							{(role === ROLES.TEACHER || role === ROLES.BUSINESS) && (
								<>
									<IconButton
										className="icon-button"
										icon={['fal', 'share']}
										onClick={() => {
											document.querySelectorAll("[data-network='sharethis']")[0].click();
										}}
									/>
									{state.resource.isSaved === true ? (
										<IconButton
											className="icon-button"
											icon={['fa', 'bookmark']}
											onClick={() => {
												handleSaveUnsaveResource();
											}}
										/>
									) : (
										<IconButton
											className="icon-button"
											icon={['fal', 'bookmark']}
											onClick={() => {
												handleSaveUnsaveResource();
											}}
										/>
									)}
									{state.resource.isCreatorAccess === true && (
										<>
											<Button
												variant="outline"
												onClick={() => {
													handleOpenDuplicateModal();
												}}
											>
												<Typography weight="bold" variation="button-medium">
													Duplicate
												</Typography>
											</Button>
											{state.resource.status === RESOURCE_STATUSES.DRAFT && (
												<Button
													variant="outline"
													onClick={() => {
														handleOpenPublishModal();
													}}
												>
													<Typography weight="bold" variation="button-medium">
														Publish
													</Typography>
												</Button>
											)}
											{state.resource.status === RESOURCE_STATUSES.PUBLISHED && (
												<Button
													variant="outline"
													onClick={() => {
														handleOpenPublishModal();
													}}
												>
													<Typography weight="bold" variation="button-medium">
														Set as Draft
													</Typography>
												</Button>
											)}
										</>
									)}
									{state.resource.requiresInformationReview === true && state.resource.isCreatorAccess !== true ? (
										<>
											<Button
												variant="outline"
												onClick={() => {
													handleOpenSkipReviewModal();
												}}
											>
												<Typography weight="bold" variation="button-medium">
													Skip Review
												</Typography>
											</Button>
											<Button onClick={handleOpenStartReviewModal}>
												<Typography weight="bold" variation="button-medium">
													Start Review
												</Typography>
											</Button>
											<StartReviewModal ref={startReviewModalRef} setInformationDetails={setInformationDetails} role={role} />
										</>
									) : (
										<>
											{state.resource.status === 'archived' ? (
												<Button
													onClick={() => {
														handleOpenArchiveModal();
													}}
												>
													<Typography weight="bold" variation="button-medium">
														Unarchive
													</Typography>
												</Button>
											) : (
												<Button
													onClick={() => {
														handleOpenArchiveModal();
													}}
												>
													<Typography weight="bold" variation="button-medium">
														Archive
													</Typography>
												</Button>
											)}
										</>
									)}
								</>
							)}
							{(role === ROLES.ADMIN || role === ROLES.SUPER) && (
								<>
									<Button
										variant="outline"
										onClick={() => {
											handleOpenDuplicateModal();
										}}
									>
										<Typography weight="bold" variation="button-medium">
											Duplicate
										</Typography>
									</Button>
									{state.resource.isArchived === true ? (
										<Button
											onClick={() => {
												handleOpenArchiveModal();
											}}
										>
											<Typography weight="bold" variation="button-medium">
												Unarchive
											</Typography>
										</Button>
									) : (
										<Button
											onClick={() => {
												handleOpenArchiveModal();
											}}
										>
											<Typography weight="bold" variation="button-medium">
												Archive
											</Typography>
										</Button>
									)}
								</>
							)}
						</DetailPageHeader>
						<S.Wrapper>
							<OverviewCard className="a1" resource={state.resource} role={role} />
							{(state.resource.publicFiles.length > 0 || (role != null && state.resource.business != null && state.resource.business.id === user?.business?.id) || role === ROLES.ADMIN || role === ROLES.SUPER) && <FilesCard className="overflow-hidden" role={role} />}
							{(state.resource.links.length > 0 || (role != null && state.resource.business != null && state.resource.business.id === user?.business?.id) || role === ROLES.ADMIN || role === ROLES.SUPER) && <LinksCard className="overflow-hidden" role={role} />}
							{((role != null && state.resource.business != null && state.resource.business.id === user?.business?.id) || role === ROLES.ADMIN || role === ROLES.SUPER) && <PrivateFilesCard className="overflow-hidden" role={role} />}
							<BusinessCard className="a3" business={state.resource.business} role={role} />
							{(state.resource.employabilitySkills.length > 0 || state.resource.curriculumStandards.length > 0) && <LearningConnectionsCard className="a4" resource={state.resource} role={role} />}
						</S.Wrapper>
						<MessageDialog
							isOpen={archiveIsOpen}
							onRequestClose={handleCloseArchiveModal}
							title={((role === ROLES.ADMIN || role === ROLES.SUPER) && state.resource.isArchived !== true) || state.resource.status !== 'archived' ? 'Archive Resource' : 'Unarchive Resource'}
							subtitle={
								((role === ROLES.ADMIN || role === ROLES.SUPER) && state.resource.isArchived !== true) || state.resource.status !== 'archived'
									? 'Are you sure you want to archive this resource? You will be able to unarchive it later.'
									: 'Are you sure you want to unarchive this resource?'
							}
							icon={['fal', 'info-circle']}
							actions={[
								{ id: 'back', label: 'Cancel', variant: 'outline', variation: 'default' },
								{
									id: 'archiveUnarchive',
									label: ((role === ROLES.ADMIN || role === ROLES.SUPER) && state.resource.isArchived !== true) || state.resource.status !== 'archived' ? 'Archive Resource' : 'Unarchive Resource',
									variant: 'solid',
									variation: 'default',
									onClick: handleArchiveUnarchive,
								},
							]}
						/>
						<MessageDialog
							isOpen={skipReviewIsOpen}
							onRequestClose={handleCloseSkipReviewModal}
							title="Skip Review?"
							subtitle="Are you sure you want to opt out of reviewing this resource? Businesses use review feedback to improve their offerings."
							icon={['fal', 'star']}
							actions={[
								{ id: 'back', label: 'Cancel', variant: 'outline', variation: 'default' },
								{ id: 'skipReview', label: 'Skip Review', variant: 'solid', variation: 'default', onClick: handleSkipReview },
							]}
						/>
						<MessageDialog
							isOpen={duplicateIsOpen}
							onRequestClose={handleCloseDuplicateModal}
							title="Duplicate Resource?"
							subtitle="Are you sure you want to duplicate this resource? All resource data will be copied over to the new resource."
							icon={['fal', 'info-circle']}
							actions={[
								{ id: 'back', label: 'Cancel', variant: 'outline', variation: 'default' },
								{ id: 'duplicate', label: 'Duplicate Resource', variant: 'solid', variation: 'default', onClick: handleDuplicateResource },
							]}
						/>
						<MessageDialog
							isOpen={publishIsOpen}
							onRequestClose={handleClosePublishModal}
							title={state.resource.status === RESOURCE_STATUSES.DRAFT ? 'Publish Resource?' : 'Set Resource as a Draft?'}
							subtitle={state.resource.status === RESOURCE_STATUSES.DRAFT ? 'Are you ready to publish this resource? Teachers will be able to send you requests.' : "Are you sure you want to set this resource as a draft? Teachers won't be able to send you requests."}
							icon={['fal', 'info-circle']}
							actions={[
								{ id: 'back', label: 'Cancel', variant: 'outline', variation: 'default' },
								{ id: 'publish', label: state.resource.status === RESOURCE_STATUSES.DRAFT ? 'Publish Resource' : 'Set as Draft', variant: 'solid', variation: 'default', onClick: handlePublishResource },
							]}
						/>
					</InformationDetailsContext.Provider>
				);
			default:
				return null;
		}
	};

	if (role === ROLES.TEACHER) {
		return <TeacherNavigation>{renderContent()}</TeacherNavigation>;
	} else if (role === ROLES.BUSINESS) {
		return <BusinessNavigation>{renderContent()}</BusinessNavigation>;
	} else if (role === ROLES.ADMIN) {
		return <AdminNavigation>{renderContent()}</AdminNavigation>;
	}
	return <PublicNavigation>{renderContent()}</PublicNavigation>;
};

InformationDetails.propTypes = {
	role: PropTypes.string,
};

export default InformationDetails;
