import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { OverviewCard } from './OverviewCard';
import { DemographicsCard } from './DemographicsCard';
import * as S from './OverviewTab.styles';

const OverviewTab = ({ group }) => {
	const [currentGroup, setCurrentGroup] = useState(group);

	return (
		<S.Wrapper>
			<OverviewCard className="a1" group={currentGroup} setGroup={setCurrentGroup} />
			<DemographicsCard className="a2" group={currentGroup} setGroup={setCurrentGroup} />
		</S.Wrapper>
	);
};

OverviewTab.propTypes = {
	group: PropTypes.object,
};

export default OverviewTab;
