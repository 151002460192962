import React, { forwardRef, useContext, useImperativeHandle, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { Controller, useForm } from 'react-hook-form';
import { format } from 'date-fns';
import moment from 'moment';

import { AppointmentDetailsContext } from '../../RequestsAppointmentDetails';
import { Card, Calendar, IconButton, Typography, DatePicker, Button, Radio, TextArea } from '../../../../components';
import * as S from './AvailabilityCard.styles';
import { errorHandler } from '../../../../services/authService';
import { required } from '../../../../utils/form-default-errors';
import { DAYS_OF_WEEK, ROLES } from '../../../../../constants/general.constants';
import { formatPayloadBeforeUpdate } from '../OverviewTab';
import { createRequest } from '../../../../services/requests/createRequestService';

const AvailabilityEditModal = forwardRef((_, ref) => {
	const {
		state: { request },
		dispatch,
	} = useContext(AppointmentDetailsContext);
	const [isOpen, setIsOpen] = useState(false);

	const {
		handleSubmit,
		register,
		setValue,
		control,
		reset,
		watch,
		formState: { errors, isSubmitting },
	} = useForm();

	const isOngoing = watch('isOngoing');

	const handleOpen = () => {
		setValue('startDate', request?.availabilityStartDate ? moment(request?.availabilityStartDate, 'MM-DD-YYYY', true).toDate() : null);
		setValue('endDate', request?.availabilityEndDate ? moment(request?.availabilityEndDate, 'MM-DD-YYYY', true).toDate() : null);
		setValue('isOngoing', request?.availabilityIsOngoing ? 'yes' : 'no');
		setValue('days', request?.availabilityDays);
		setValue('availabilityNotes', request?.availabilityNotes);
		setIsOpen(true);
	};

	const handleClose = () => {
		setIsOpen(false);
		setTimeout(() => {
			reset();
		}, 200);
	};

	async function asyncCaller(data) {
		const { startDate, endDate, isOngoing, ...rest } = data;
		const newRequest = formatPayloadBeforeUpdate(request, { ...rest, startDate: format(startDate, 'MM-dd-yyyy'), endDate: endDate ? format(endDate, 'MM-dd-yyyy') : null, isOngoing: isOngoing === 'yes' });

		const {
			data: { result },
		} = await createRequest(newRequest);
		dispatch({ type: 'setRequest', payload: result.request });
		handleClose();
	}

	const onSubmit = (e) => handleSubmit(asyncCaller)(e).catch(errorHandler);

	useImperativeHandle(ref, () => ({
		open: handleOpen,
		close: handleClose,
	}));

	return (
		<ReactModal ref={ref} closeTimeoutMS={200} isOpen={isOpen} onRequestClose={handleClose}>
			<S.Form onSubmit={onSubmit}>
				<IconButton className="close-button" type="button" icon={['fal', 'times']} onClick={handleClose} />
				<S.InfoWrapper>
					<Typography tag="h2" weight="extrablack" center>
						Availability
					</Typography>
					<Typography tag="p" center>
						Edit your availability to help students find you.
					</Typography>
				</S.InfoWrapper>
				<S.FieldsWrapper>
					<Controller control={control} name="startDate" rules={{ required: required('Start Date') }} render={({ field: { value, onChange } }) => <DatePicker label="Start Date" selected={value} onChange={onChange} error={errors?.startDate} />} />

					<S.RadioOptionsWrapper>
						<Typography className={`field-label${!!errors?.isOngoing ? ' with-error' : ''}`} tag="p" variation="2" weight="semibold">
							Is this ongoing?
						</Typography>

						<div className="radio-field">
							<Radio
								id="isOngoing1"
								value="yes"
								{...register('isOngoing', {
									required: required('Is this ongoing'),
								})}
							/>
							<label htmlFor="isOngoing1">Yes, it is ongoing</label>
						</div>
						<div className="radio-field">
							<Radio
								id="isOngoing2"
								value="no"
								{...register('isOngoing', {
									required: required('Is this ongoing'),
								})}
							/>
							<label htmlFor="isOngoing2">No, there is an end date</label>
						</div>

						{!!errors.isOngoing && (
							<div className="error-wrapper">
								<Typography tag="p" variation="2">
									{errors.isOngoing.message}
								</Typography>
							</div>
						)}
					</S.RadioOptionsWrapper>

					{isOngoing === 'no' && <Controller control={control} name="endDate" rules={{ required: required('End Date') }} render={({ field: { value, onChange } }) => <DatePicker label="End Date" selected={value} onChange={onChange} error={errors?.endDate} />} />}

					<S.DaysSelector>
						<Typography className={`field-label${!!errors?.days ? ' with-error' : ''}`} tag="p" variation="2" weight="semibold">
							Days
						</Typography>

						<div className="days-wrapper">
							{DAYS_OF_WEEK.map((value) => (
								<div key={value} className="day-wrapper">
									<input type="checkbox" id={`id-${value}`} value={value} {...register('days', { required: required('Days') })} />
									<label className={`field-label${!!errors?.days ? ' with-error' : ''}`} htmlFor={`id-${value}`}>
										{value}
									</label>
								</div>
							))}
						</div>

						{!!errors?.days && (
							<div className="error-wrapper">
								<Typography tag="p" variation="2">
									{errors?.days?.message}
								</Typography>
							</div>
						)}
					</S.DaysSelector>

					<TextArea label="Availability Notes" id="availability-notes" placeholder="Write a message..." {...register('availabilityNotes')} />
				</S.FieldsWrapper>
				<S.ButtonsWrapper>
					<Button type="submit">
						<Typography weight="bold" variation="button-medium">
							{isSubmitting ? 'Loading' : 'Save'}
						</Typography>
					</Button>
				</S.ButtonsWrapper>
			</S.Form>
		</ReactModal>
	);
});
AvailabilityEditModal.displayName = 'AvailabilityEditModal';

export const AvailabilityCard = ({ className, role }) => {
	const {
		state: { request },
	} = useContext(AppointmentDetailsContext);

	const user = useSelector((state) => state.user.value);

	const modalRef = useRef(null);

	const handleEditClick = () => {
		modalRef.current.open();
	};

	return (
		<Card
			title="Availability"
			className={className}
			transparentHeaderBorder
			actions={(role != null && request.creator != null && request.creator.id === user?.id) || role === ROLES.ADMIN || role === ROLES.SUPER ? [{ id: 1, label: 'Edit', size: 'small', variant: 'outline', onClick: handleEditClick, icon: { source: ['fal', 'edit'] } }] : []}
		>
			<S.Wrapper>
				<Calendar
					allDates={request.availablilityDates.map((date) => moment(date, 'MM-DD-YYYY', true).toDate().toDateString())}
					value={request.availablilityDates.length > 0 ? [moment(request.availablilityDates[0], 'MM-DD-YYYY', true).toDate(), moment(request.availablilityDates[request.availablilityDates.length - 1], 'MM-DD-YYYY', true).toDate()] : undefined}
				/>
			</S.Wrapper>
			<AvailabilityEditModal ref={modalRef} />
		</Card>
	);
};

AvailabilityCard.propTypes = {
	className: PropTypes.string,
	role: PropTypes.string,
};
