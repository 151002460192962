import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState, useEffect, useRef, createContext } from 'react';
import { useHistory } from 'react-router-dom';

import { useChat } from '../../../../hooks';
import { BOOKING_STATUSES } from '../../../../../constants/general.constants';
import { fetchMessages } from '../../../../services/messages';
import { cancelBooking } from '../../../../services/bookings';
import { Button, IconButton, Picture, Typography, MessageDialog, Chat, CompleteBookingModal } from '../../../../components';
import { BookingsContext } from '../BookingsTab';
import * as S from './BookingChat.styles';

const role = 'teacher';

export const BookingChat = () => {
	const { state, dispatch } = useContext(BookingsContext);
	const { selectedConversation } = state;
	const { messages, setMessages } = useChat(selectedConversation.id);
	const [cancelIsOpen, setCancelIsOpen] = useState(false);
	const [pageStatus, setPageStatus] = useState('loading');
	const history = useHistory();

	const completeBookingModalRef = useRef(null);

	function handleOpenCancelModal() {
		setCancelIsOpen(true);
	}

	function handleCloseCancelModal() {
		setCancelIsOpen(false);
	}

	const handleCancelBooking = async () => {
		setCancelIsOpen(false);
		try {
			await cancelBooking({ bookingId: selectedConversation.id });
			dispatch({ type: 'SET_SELECTED_CONVERSATION', payload: { ...selectedConversation, status: 'incomplete' } });
		} catch (error) {}
	};

	async function initializeData() {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchMessages({ page: 0, displayLimit: 100000, bookingId: selectedConversation.id, filterOptions: { received: true, sent: true } });
			setMessages(result.messages);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}

	useEffect(() => {
		// Load initial data
		initializeData();
	}, [selectedConversation]);

	const renderButtons = (booking) => {
		if (booking.status === BOOKING_STATUSES.PENDING) {
			return (
				<Button
					variant="outline"
					size="small"
					onClick={() => {
						handleOpenCancelModal();
					}}
				>
					<Typography className="button-label" variation="button-small" weight="bold">
						Cancel
					</Typography>
				</Button>
			);
		} else if (booking.status === BOOKING_STATUSES.SCHEDULED) {
			return (
				<>
					<Button
						variant="outline"
						size="small"
						onClick={() => {
							handleOpenCancelModal();
						}}
					>
						<Typography className="button-label" variation="button-small" weight="bold">
							Cancel
						</Typography>
					</Button>
					<Button
						variant="outline"
						size="small"
						onClick={() => {
							completeBookingModalRef.current.open(selectedConversation.id);
						}}
					>
						<Typography className="button-label" variation="button-small" weight="bold">
							Complete
						</Typography>
					</Button>
					<CompleteBookingModal ref={completeBookingModalRef} role={role} context={BookingsContext} />
				</>
			);
		}
		return <></>;
	};

	return (
		<>
			<S.BookingChatWrapper>
				<S.ChatHeader>
					<S.BackButtonWrapper>
						<Button variant="text" size="small" onClick={() => dispatch({ type: 'CLEAR_SELECTED_CONVERSATION' })}>
							<FontAwesomeIcon icon={['fal', 'arrow-left']} />
							<Typography className="button-label" variation="button-small" weight="bold">
								Back to Messages
							</Typography>
						</Button>
					</S.BackButtonWrapper>
					<S.InfoWrapper>
						<Picture className="avatar-image" src={selectedConversation.business.logoObj['200']} aspectRatio="1/1" alt="profile" />
						<div className="info-block">
							<Typography tag="h3" weight="bold">
								{selectedConversation.business.name}
							</Typography>
							<Typography tag="p" variation="2">
								{selectedConversation.resource.title}
							</Typography>
						</div>
						<IconButton
							className="info-button"
							icon={['fal', 'info-circle']}
							onClick={() => {
								history.push({ pathname: `/teacher/directory/business/${selectedConversation.business.id}`, state: { backUrl: window.location.pathname, backTitle: 'Back to Messages' } });
							}}
						/>
					</S.InfoWrapper>
					<S.ButtonWrapper>{renderButtons(selectedConversation)}</S.ButtonWrapper>
				</S.ChatHeader>
				<Chat loading={pageStatus === 'loading'} messages={messages} setMessages={setMessages} sendProps={{ bookingId: selectedConversation.id, businessId: selectedConversation.business.id }} />
			</S.BookingChatWrapper>
			<MessageDialog
				isOpen={cancelIsOpen}
				onRequestClose={handleCloseCancelModal}
				title="Cancel Booking?"
				subtitle="Are you sure you want to cancel this booking? The business will be notified as well."
				icon={['fal', 'envelope']}
				actions={[
					{ id: 'back', label: 'Nevermind', variant: 'outline', variation: 'default' },
					{ id: 'cancel', label: 'Cancel Booking', variant: 'solid', variation: 'default', onClick: handleCancelBooking },
				]}
			/>
		</>
	);
};
