import React, { useState, useEffect } from 'react';

import { TeacherNavigation, PageHeader, Tab, ModalCreateGroup } from '../../components';

import { CurrentTab } from './CurrentTab';
import { ArchivedTab } from './ArchivedTab';
import * as S from './TeacherGroups.styles';
import { META_TITLE } from '../../../constants/general.constants';

const TeacherGroups = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [groups, setGroups] = useState([]);
	const tabsConfig = (groups) => [
		{
			id: 1,
			label: 'Current',
			component: <CurrentTab newGroups={groups} />,
		},
		{
			id: 2,
			label: 'Archived',
			component: <ArchivedTab />,
		},
	];
	const [tabs, setTabs] = useState(tabsConfig(null));

	function handleOpenModal() {
		setIsOpen(true);
	}

	function handleCloseModal() {
		setIsOpen(false);
	}

	useEffect(() => {
		// Set document title
		document.title = `My Groups | Dashboard | ${META_TITLE}`;
	}, []);

	useEffect(() => {
		setTabs(tabsConfig(groups));
	}, [groups]);

	return (
		<TeacherNavigation>
			<PageHeader
				title="Groups"
				action={[
					{
						id: 1,
						onClick: () => {
							handleOpenModal();
						},
						label: 'Create Group',
						icon: ['fal', 'plus'],
					},
				]}
			/>
			<S.Wrapper>
				<Tab tabs={tabs} setTabs={setTabs} />
			</S.Wrapper>
			<ModalCreateGroup isOpen={isOpen} onRequestClose={handleCloseModal} setGroups={setGroups} />
		</TeacherNavigation>
	);
};

export default TeacherGroups;
