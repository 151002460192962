import api from '../api';
import { fakeAsyncFunction } from '../../utils/fake-async-function';
import { REPORT_TYPES } from '../../../constants/general.constants';
import devResourceData from './resources.json';
import devRequestData from './requests.json';
import devBookingData from './bookings.json';
import devInformationData from './information.json';
import devEngagementData from './engagements.json';

export const generateReport = (data) => {
	if (process.env.REACT_APP_ENV === 'development') {
		switch (data.type) {
			case REPORT_TYPES.RESOURCES:
				return fakeAsyncFunction(devResourceData);
			case REPORT_TYPES.REQUESTS:
				return fakeAsyncFunction(devRequestData);
			case REPORT_TYPES.BOOKINGS:
				return fakeAsyncFunction(devBookingData);
			case REPORT_TYPES.INFORMATION:
				return fakeAsyncFunction(devInformationData);
			case REPORT_TYPES.ENGAGEMENTS:
				return fakeAsyncFunction(devEngagementData);
		}
	} else {
		return api.post('/core/functions/generateReport', data);
	}
};

export const fetchReport = (data) => {
	return api.post('/core/functions/fetchReport', data);
};
