import styled from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	flex-direction: column;
	padding: 0.5rem 1rem;
	background-color: white;
	border-bottom: 1px solid ${colors.neutralForm};

	@media screen and (min-width: 768px) {
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}
`;

export const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 0.5rem;

	& > * + * {
		margin-top: 0.5rem;
	}

	@media screen and (min-width: 768px) {
		flex-direction: row;
		margin-top: 0;

		& > * + * {
			margin-top: 0;
			margin-left: 0.5rem;
		}
	}
`;

export const BackButtonWrapper = styled.div`
	width: 100%;

	button {
		margin-left: -0.625rem;
		-webkit-letter-spacing: 0.75px;
		-moz-letter-spacing: 0.75px;
		-ms-letter-spacing: 0.75px;
		letter-spacing: 0.75px;
		padding: 0.3125rem 0.625rem;
	}
`;
