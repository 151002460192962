import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ROUTER_ROLE = 'business';

export const BusinessRoute = ({ component: Component, path, exact = false, fallback }) => {
	const user = useSelector((state) => state.user.value);
	const status = useSelector((state) => state.user.status);
	const userHasRequiredRole = ROUTER_ROLE === user?.role;
	const location = useLocation();

	const render = (props) => {
		// Create fallback url
		let fallbackUrl = null;
		if (fallback) {
			fallbackUrl = fallback;
			if (props.match.params && props.match.params.id) {
				fallbackUrl = fallbackUrl.replace(':id', props.match.params.id);
			}
		}

		// Return or redirect
		if (user && userHasRequiredRole) {
			if (!user?.accountCompanySetupComplete) {
				if (location.pathname === '/business/create-company') {
					return <Component {...props} role={ROUTER_ROLE} />;
				}
				return <Redirect to="/business/create-company" />;
			}

			if (user?.business?.locations?.length === 0) {
				if (location.pathname === '/business/company-locations') {
					return <Component {...props} role={ROUTER_ROLE} />;
				}
				return <Redirect to="/business/company-locations" />;
			}

			return <Component {...props} role={ROUTER_ROLE} />;
		}
		if (fallbackUrl != null) {
			return (
				<Redirect
					to={{
						pathname: fallbackUrl,
					}}
				/>
			);
		}
		return (
			<Redirect
				to={{
					pathname: '/insufficient-permissions',
				}}
			/>
		);
	};

	if (status === 'idle') {
		return null;
	}

	return <Route exact={exact} path={path} render={render} />;
};

BusinessRoute.propTypes = {
	component: PropTypes.func.isRequired,
	path: PropTypes.string,
	exact: PropTypes.bool,
	fallback: PropTypes.string,
	match: PropTypes.object,
};
