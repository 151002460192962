import styled, { css } from 'styled-components';

import colors from '../../../styles/colors';

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	position: relative;
	height: 100%;

	.close-button {
		position: absolute;
		top: 1rem;
		right: 1rem;
		color: ${colors.neutralButton};
	}

	.modal-content-wrapper {
		flex: 1;
		overflow: auto;
	}

	.modal-content {
		display: block;
		flex: 1;
		max-width: 41.75rem;
		margin: 0 auto;
		width: 100%;
		padding: 3rem 1rem 2rem;

		.bigger-title {
			overflow: hidden;
			margin: 0 -1rem;
		}

		.description {
			margin-top: 0.5rem;
			margin-bottom: 3rem;
		}

		.with-error {
			border-color: ${colors.stateDanger};
		}

		.field-wrapper.mt-1 {
			margin-top: 0.5rem;
		}

		.field-label {
			color: ${colors.neutralText};

			&.custom-margin {
				margin-top: 1.5rem;
			}

			&--empty {
				color: ${colors.neutralText};
			}

			&.with-error {
				color: ${colors.stateDanger};
			}
		}

		.fields-wrapper {
			display: grid;
			grid-template-columns: 1fr;
			gap: 1.25rem;

			.business-review-item {
				display: grid;
				grid-template-columns: 2.625rem 1fr;
				gap: 0.75rem;

				&__image-wrapper {
					img {
						display: block;
						width: 100%;
						border-radius: 9999px;
						border: 1px solid ${colors.neutralForm};
					}
				}

				&__fields-wrapper {
					display: grid;
					gap: 1.25rem;
					margin-top: 0.25rem;

					& > h3 {
						margin-bottom: -0.75rem;
					}
				}

				&.smaller {
					grid-template-columns: 2.125rem 1fr;

					.business-review-item__fields-wrapper {
						gap: 0.5rem;
					}
				}
			}

			.review {
				&__step-item {
					display: grid;
					grid-template-columns: 1fr;
					gap: 1.5rem;
					margin-top: 1rem;

					&:first-child {
						margin-top: 0;
					}
				}

				&__info-item {
					& > h6 {
						color: ${colors.neutralText};
						margin-bottom: 0.375em;
					}

					&--with-badge {
						& > h6 {
							margin-bottom: calc(0.375em + 0.25rem);
						}
					}

					.badge-wrapper {
						display: flex;
						flex-wrap: wrap;
						margin: -0.25rem;

						.small-badge {
							padding: 0.25rem 0.5rem;
							border-radius: 6.25rem;
							border: 1px solid ${colors.brandPrimary};
							font-size: 0.75rem;
							color: ${colors.brandPrimary};
							font-weight: 700;
							margin: 0.25rem;
						}
					}
				}

				&__overview {
					& > h3 {
						margin-bottom: -1rem;
					}
				}

				&__cover-image {
					border: 1px solid ${colors.neutralForm};
					border-radius: 0.375rem;
				}

				&__file-item {
					display: grid;
					grid-template-columns: 1fr;
					gap: 1rem;
				}

				&__file-detail {
					display: flex;
					align-items: center;

					& > svg {
						color: ${colors.neutralText};
					}

					& > div {
						margin-left: 0.5rem;

						& > p:last-child {
							color: ${colors.neutralText};
						}
					}
				}

				&__location-item {
					display: flex;

					.location-icon-wrapper {
						display: flex;
						justify-content: center;
						margin-right: 0.5rem;
						width: 1.5rem;
						color: black;
					}

					.location-info-wrapper {
						flex: 1;
						display: grid;
						grid-template-columns: 1fr;
						gap: 1rem;

						.custom-margin {
							margin-top: 1rem;
						}
					}
				}
			}

			.link-item {
				display: grid;
				grid-template-columns: 1fr;
				gap: 1.125rem;
				position: relative;

				& > h3 {
					margin-bottom: -1rem;
				}

				.link-remove {
					position: absolute;
					right: 0;
					color: ${colors.stateDanger};
				}
			}

			.add-link-wrapper {
				margin-left: 0 -0.75rem;
			}

			.time-wrapper {
				display: grid;
				grid-template-columns: 1fr;
				gap: 1.25rem;

				@media (min-width: 992px) {
					grid-template-columns: 1fr 1fr;
				}
			}

			.huge-textarea {
				min-height: 21rem;
			}
		}

		.review-wrapper {
			display: grid;
			grid-template-columns: 1fr;
			gap: 2rem;
		}

		.section-wrapper {
			display: grid;
			grid-template-columns: 1fr;
			gap: 1.25rem;
		}

		.checkbox-wrapper {
			display: grid;
			grid-template-columns: 1fr;
			gap: 1rem;
			position: relative;

			.field-label {
				color: ${colors.neutralText};

				&.with-error {
					color: ${colors.stateDanger};
				}
			}

			.checkbox-field {
				display: flex;
				align-items: center;

				& > * + * {
					padding-left: 0.75rem;
				}

				label {
					font-size: 1rem;
					line-height: 1.375rem;
					font-weight: 400;
					color: black;
					cursor: pointer;
				}
			}

			@media screen and (min-width: 768px) {
				grid-template-columns: 1fr 1fr;

				.field-label {
					grid-column: 1 / 3;
				}
			}
		}

		.radio-field {
			display: flex;
			align-items: center;

			& > * + * {
				padding-left: 0.75rem;
			}

			label {
				font-size: 1rem;
				line-height: 1.375rem;
				font-weight: 400;
				color: black;
				cursor: pointer;
			}
		}

		.custom-select {
			margin-top: 1rem;
		}

		.error-wrapper {
			position: absolute;
			bottom: -1rem;
			color: ${colors.stateDanger};

			&.mt1 {
				bottom: -1.5rem;
			}
		}
	}

	.modal-footer {
		display: flex;
		justify-content: flex-end;
		padding: 1.5rem;
		border-top: 1px solid ${colors.neutralForm};
	}
`;

export const ButtonsWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin: 0;
	padding: 1rem;
	border-top: 1px solid ${colors.neutralForm};

	& > * + * {
		margin-left: 1rem;
	}
`;

export const FilesListWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	gap: 1rem;

	.files-wrapper {
		display: grid;
		grid-template-columns: 1fr;
		gap: 1rem;

		header {
			display: flex;
			justify-content: space-between;
			align-items: center;

			button {
				color: ${colors.neutralText};
			}
		}

		&__item {
			display: grid;
			grid-template-columns: 1fr;
			gap: 1rem;
			padding-bottom: 1.5rem;
			border-bottom: 1px solid ${colors.neutralForm};

			&__info {
				h6 {
					color: ${colors.neutralText};
					margin-bottom: 0.375em;
				}
			}

			&__details {
				display: flex;
				align-items: center;

				& > div {
					margin-left: 0.5rem;

					& > p:last-child {
						color: ${colors.neutralText};
					}
				}
			}

			&:last-child {
				padding-bottom: 0;
				border-bottom: none;
			}
		}
	}
`;

export const FileListDropzone = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	border: 1px dashed ${colors.neutralText};
	height: 12.5rem;
	border-radius: 0.375rem;
	cursor: pointer;

	overflow: hidden;

	img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

	.upload-placeholder {
		display: grid;
		grid-template-columns: 1fr;
		gap: 0.5rem;

		&__picture {
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 0.375rem;
			padding: 0.5rem;
			width: 100%;
			max-width: 8.25rem;
			margin: 1rem auto 0;
			background-color: ${colors.neutralMessage};
			color: ${colors.brandPrimary};
		}

		&__paragraph {
			color: ${colors.neutralText};

			strong {
				color: ${colors.brandPrimary};
			}
		}
	}

	${({ isDragActive }) =>
		isDragActive &&
		css`
			opacity: 0.8;
		`}

	${({ isDragAccept }) =>
		isDragAccept &&
		css`
			border-color: ${colors.stateSuccess};
		`}

	${({ isDragReject }) =>
		isDragReject &&
		css`
			border-color: ${colors.stateDanger};
		`}
`;
