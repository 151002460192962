import styled from 'styled-components';

export const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-template-areas:
		'a1'
		'a2'
		'a3'
		'a4'
		'a5'
		'a6';

	.a1 {
		grid-area: a1;
		margin: 0 0 1.25rem 0;
	}
	.a2 {
		grid-area: a2;
		margin: 0 0 1.25rem 0;
	}
	.a3 {
		grid-area: a3;
		margin: 0 0 1.25rem 0;
	}
	.a4 {
		grid-area: a4;
		margin: 0 0 1.25rem 0;
	}
	.a5 {
		grid-area: a5;
		margin: 0 0 1.25rem 0;
	}
	.a6 {
		grid-area: a6;
		margin: 0 0 1.25rem 0;
	}

	@media screen and (min-width: 992px) {
		grid-template-columns: repeat(2, 1fr);
		grid-template-areas:
			'a1 a1'
			'a2 a2'
			'a3 a4'
			'a5 a6';

		.a1 {
			grid-area: a1;
			margin: 0 0 1.25rem 0;
		}
		.a2 {
			grid-area: a2;
			margin: 0 0 1.25rem 0;
		}
		.a3 {
			grid-area: a3;
			margin: 0 0.625rem 1.25rem 0;
		}
		.a4 {
			grid-area: a4;
			margin: 0 0 1.25rem 0.625rem;
		}
		.a5 {
			grid-area: a5;
			margin: 0 0.625rem 1.25rem 0;
		}
		.a6 {
			grid-area: a6;
			margin: 0 0 1.25rem 0.625rem;
		}
	}
`;
