import styled, { css } from 'styled-components';

import colors from '../../../styles/colors';

export const Wrapper = styled.section`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 0.75rem;
	border-bottom: 1px solid ${colors.neutralForm};
	padding-bottom: 1rem;

	grid-template-areas:
		'viewfor-select viewfor-select'
		'currentselected-select currentselected-select'
		'daterange-select daterange-select';

	.viewfor-select {
		grid-area: viewfor-select;
	}

	.currentselected-select {
		grid-area: currentselected-select;
	}

	.daterange-select {
		grid-area: daterange-select;
	}

	@media screen and (min-width: 768px) {
		grid-template-areas:
			'viewfor-select currentselected-select'
			'daterange-select daterange-select';
	}

	@media screen and (min-width: 912px) {
		grid-template-columns: repeat(3, 1fr);
		grid-template-areas: 'viewfor-select currentselected-select daterange-select';
	}

	@media screen and (min-width: 1260px) {
		grid-template-columns: repeat(4, 1fr);
	}
`;

export const FieldWrapper = styled.div`
	display: flex;
	align-items: center;

	& > label {
		white-space: nowrap;
		color: ${colors.neutralText};
		margin: 0;
		margin-right: 0.5rem;
	}

	& > div {
		flex: 1;
	}
`;
