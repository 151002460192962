import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormContext } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';

import { required } from '../../../../utils/form-default-errors';
import { bytesToSize } from '../../../../utils/filesize-formatter';
import { ACCEPTABLE_FILE_TYPES } from '../../../../../constants/general.constants';
import { Typography, IconButton, TextInput, TextArea } from '../../../../components';
import * as S from '../StepTemplateOverview.styles';

export const FileTemplate = () => {
	const {
		register,
		watch,
		setValue,
		resetField,
		formState: { errors },
	} = useFormContext();

	const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
		onDrop: useCallback((acceptedFiles) => acceptedFiles.length > 0 && setValue('uploadFile', acceptedFiles[0]), []),
		multiple: false,
		accept: ACCEPTABLE_FILE_TYPES.RESOURCE_MEDIA.MIME_TYPES,
	});

	const uploadFile = watch('uploadFile');

	return (
		<>
			<Typography tag="h2" weight="extrablack" center>
				File Template
			</Typography>
			<Typography tag="p" center>
				Add in overview information for your file template.
			</Typography>

			<div className="fields-wrapper">
				<S.HugeImageUploader withError={errors.uploadFile}>
					<Typography tag="label" htmlFor="cover-image" weight="semibold">
						File Upload
					</Typography>
					<S.FileDropZone alternative {...getRootProps()} isDragActive={isDragActive} isDragAccept={isDragAccept} isDragReject={isDragReject}>
						<input id="cover-image" {...register('uploadFile', { required: required('File Upload') })} {...getInputProps({ onChange: (e) => setValue('uploadFile', e.target.files[0]) })} />

						<div className="upload-placeholder">
							<div className="upload-placeholder__picture">
								<FontAwesomeIcon icon={['fal', 'image']} size="2x" />
							</div>
							<Typography tag="p" variation="2" center className="upload-placeholder__paragraph">
								Drag and drop files here, or <strong>browse</strong>
								<br />
								Supports PDF, Doc, PPT, JPG, PNG
							</Typography>
						</div>
					</S.FileDropZone>
					{uploadFile && (
						<button className="reset-btn" type="button" onClick={() => resetField('uploadFile')}>
							<FontAwesomeIcon icon={['fal', 'trash']} />
						</button>
					)}
					{!!errors.uploadFile && (
						<div className="error-wrapper">
							<Typography tag="p" variation="2">
								{errors.uploadFile.message}
							</Typography>
						</div>
					)}
				</S.HugeImageUploader>

				{uploadFile && (
					<div className="files-wrapper">
						<div className="files-wrapper__item">
							<header>
								<Typography tag="h4" weight="bold">
									File
								</Typography>
								<IconButton type="button" icon={['fal', 'trash']} size={1} onClick={() => resetField('uploadFile')} />
							</header>

							<div className="files-wrapper__item__info">
								<Typography tag="h6" weight="semibold">
									File
								</Typography>
								<div className="files-wrapper__item__details">
									<FontAwesomeIcon icon={['fal', 'file']} size="lg" />
									<div>
										<Typography tag="p">{uploadFile.name}</Typography>
										<Typography tag="p" variation="2">
											{bytesToSize(uploadFile.size)}
										</Typography>
									</div>
								</div>
							</div>

							<TextInput label="Title" error={errors.uploadFile?.title} {...register('uploadFile.title', { required: required('Title') })} />

							<TextArea label="Description" error={errors.uploadFile?.description} {...register('uploadFile.description', { required: required('Description') })} />
						</div>
					</div>
				)}
			</div>
		</>
	);
};
