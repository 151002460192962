import styled, { css } from 'styled-components';

import colors from '../../../styles/colors';

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	position: relative;
	height: 100%;

	.close-button {
		position: absolute;
		top: 1rem;
		right: 1rem;
		color: ${colors.neutralButton};
	}

	.modal-content-wrapper {
		flex: 1;
		overflow: auto;
	}

	.modal-content {
		display: block;
		flex: 1;
		max-width: 41.75rem;
		margin: 0 auto;
		width: 100%;
		padding: 3rem 1rem 2rem;

		.bigger-title {
			overflow: hidden;
			margin: 0 -1rem;
		}

		.description {
			margin-top: 0.5rem;
			margin-bottom: 3rem;
		}

		.custom-select {
			margin-top: 1rem;
		}

		.error-wrapper {
			position: absolute;
			bottom: -1rem;
			color: ${colors.stateDanger};

			&.mt1 {
				bottom: -1.5rem;
			}
		}

		.section-wrapper {
			display: grid;
			grid-template-columns: 1fr;
			gap: 1.25rem;

			& > h3 {
				margin-bottom: -0.75rem;
			}
		}

		.teacher-line {
			display: grid;
			gap: 1rem;
			padding-bottom: 1.5rem;
			border-bottom: 1px solid ${colors.neutralForm};

			&:last-child {
				padding-bottom: 0;
				border: none;
			}
		}

		.grid-line {
			display: grid;
			row-gap: 0.5rem;
			column-gap: 1.25rem;

			.span-2 {
				grid-column: span 2;
			}
			.span-3 {
				grid-column: span 3;
			}

			&.age-range {
				grid-template-columns: 1fr;

				@media screen and (min-width: 768px) {
					grid-template-columns: repeat(3, 1fr);

					.grid-title {
						grid-column: 1 / 4;
					}
				}
			}

			&.teacher-fields {
				margin-top: 0.75rem;
				gap: 1.25rem;
				grid-template-columns: 1fr;

				@media screen and (min-width: 768px) {
					grid-template-columns: repeat(2, 1fr);

					.full-line-field {
						grid-column: 1 / 3;
					}
				}
			}
		}

		.fields-wrapper {
			display: grid;
			grid-template-columns: 1fr;
			gap: 1.25rem;

			&.more-spacing {
				gap: 2rem;
			}

			.business-review-item {
				display: grid;
				grid-template-columns: 2.625rem 1fr;
				gap: 0.75rem;

				&__image-wrapper {
					img {
						display: block;
						width: 100%;
						border-radius: 9999px;
						border: 1px solid ${colors.neutralForm};
					}
				}

				&__fields-wrapper {
					display: grid;
					gap: 1.25rem;
					margin-top: 0.25rem;

					& > h3 {
						margin-bottom: -0.75rem;
					}
				}

				&.smaller {
					grid-template-columns: 2.125rem 1fr;

					.business-review-item__fields-wrapper {
						gap: 0.5rem;
					}
				}
			}

			.field-wrapper {
				& > h6 {
					margin-bottom: 0.375em;
				}
			}

			.field-label {
				color: ${colors.neutralText};

				&.custom-margin {
					margin-top: 1.5rem;
				}

				&--empty {
					color: ${colors.neutralText};
				}

				&.with-error {
					color: ${colors.stateDanger};
				}
			}

			.review {
				&__step-item {
					display: grid;
					grid-template-columns: 1fr;
					gap: 1.5rem;
					margin-top: 1rem;

					&:first-child {
						margin-top: 0;
					}
				}

				&__info-item {
					& > h6 {
						color: ${colors.neutralText};
						margin-bottom: 0.375em;
					}

					&--with-badge {
						& > h6 {
							margin-bottom: calc(0.375em + 0.25rem);
						}
					}

					.badge-wrapper {
						display: flex;
						flex-wrap: wrap;
						margin: -0.25rem;

						.small-badge {
							padding: 0.25rem 0.5rem;
							border-radius: 6.25rem;
							border: 1px solid ${colors.brandPrimary};
							font-size: 0.75rem;
							color: ${colors.brandPrimary};
							font-weight: 700;
							margin: 0.25rem;
						}
					}
				}

				&__overview {
					& > h3 {
						margin-bottom: -1rem;
					}
				}

				&__cover-image {
					border: 1px solid ${colors.neutralForm};
					border-radius: 0.375rem;
				}

				&__file-item {
					display: grid;
					grid-template-columns: 1fr;
					gap: 1rem;
				}

				&__file-detail {
					display: flex;
					align-items: center;

					& > svg {
						color: ${colors.neutralText};
					}

					& > div {
						margin-left: 0.5rem;

						& > p:last-child {
							color: ${colors.neutralText};
						}
					}
				}

				&__location-item {
					display: flex;

					.location-icon-wrapper {
						display: flex;
						justify-content: center;
						margin-right: 0.5rem;
						width: 1.5rem;
						color: black;
					}

					.location-info-wrapper {
						flex: 1;
						display: grid;
						grid-template-columns: 1fr;
						gap: 1rem;

						.custom-margin {
							margin-top: 1rem;
						}
					}
				}
			}

			.link-item {
				display: grid;
				grid-template-columns: 1fr;
				gap: 1.125rem;
				position: relative;

				& > h3 {
					margin-bottom: -1rem;
				}

				.link-remove {
					position: absolute;
					right: 0;
					color: ${colors.stateDanger};
				}
			}

			.add-link-wrapper {
				margin-left: 0 -0.75rem;
			}

			.time-wrapper {
				display: grid;
				grid-template-columns: 1fr;
				gap: 1.25rem;

				@media (min-width: 992px) {
					grid-template-columns: 1fr 1fr;
				}
			}

			.huge-textarea {
				min-height: 21rem;
			}
		}

		.checkbox-wrapper {
			display: grid;
			grid-template-columns: 1fr;
			gap: 1rem;
			position: relative;

			.field-label {
				color: ${colors.neutralText};

				&.with-error {
					color: ${colors.stateDanger};
				}
			}

			.checkbox-field {
				display: flex;
				align-items: center;

				& > * + * {
					padding-left: 0.75rem;
				}

				label {
					font-size: 1rem;
					line-height: 1.375rem;
					font-weight: 400;
					color: black;
					cursor: pointer;
				}
			}

			@media screen and (min-width: 768px) {
				grid-template-columns: 1fr 1fr;

				.field-label {
					grid-column: 1 / 3;
				}
			}
		}
	}
`;

export const ButtonsWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin: 0;
	padding: 1rem;
	border-top: 1px solid ${colors.neutralForm};

	& > * + * {
		margin-left: 1rem;
	}
`;

export const BigFieldset = styled.div`
	display: flex;
	flex-direction: column;

	& > * + * {
		margin-top: 0.5rem;
	}
`;

export const BigBox = styled.div`
	border: 1px solid ${colors.neutralForm};
	border-radius: 0.375rem;
	padding: 0.75rem;

	& > * + * {
		margin-top: 0.5rem;
	}
`;

export const BigBoxHeader = styled.div`
	display: flex;
	background-color: ${colors.neutralBackground};
	color: ${colors.neutralText};
	border-radius: 0.25rem;
	padding: 0.5rem 0.75rem;

	> h6 {
		flex: 1;

		&:last-child {
			width: 100%;
			max-width: 9.375rem;
		}
	}
`;

export const BigBoxLine = styled.div`
	display: flex;
	align-items: center;
	padding: 0.625rem 0.75rem;

	> p {
		flex: 1;
	}

	.box-field {
		width: 100%;
		max-width: 9.375rem;
	}
	p.box-field {
		padding-left: 0.75rem;
	}
`;

export const BigBoxFooter = styled.div`
	display: flex;
	padding: 1rem 0.75rem 0.25rem;
	border-top: 1px solid ${colors.neutralForm};

	> p {
		flex: 1;

		&:last-child {
			width: 100%;
			max-width: 9.375rem;
			padding-left: 0.75rem;
			color: ${colors.stateSuccess};
		}

		&.warning-text {
			color: ${colors.stateWarning};
		}
	}
`;
