import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { DirectoryCard, Map, Typography, EmptyComponent } from '../../../components';
import * as S from './DirectoryCardsGrid.styles';
import { formatNumberWithCommas } from '../../../utils/content-formatter';

const VIEW_OPTIONS = {
	grid: 'grid',
	list: 'list',
};

const DirectoryCardsGrid = ({ data, role, handleSaveUnsave, totalItems }) => {
	const [showMap, setShowMap] = useState(false);
	const [selectedViewOption, setSelectedViewOption] = useState(VIEW_OPTIONS.grid);
	const [backLink] = useState(() => {
		switch (role) {
			case 'teacher':
				return '/teacher/directory/resources';
			case 'business':
				return '/business/directory/resources';
			case 'admin':
			case 'super':
				return '/admin/directory/resources';
			default:
				return '/directory';
		}
	});

	function generateLinkToResource(resource) {
		switch (role) {
			case 'teacher':
				return `/teacher/directory/resources/${resource.id}`;
			case 'business':
				return `/business/directory/resources/${resource.id}`;
			case 'admin':
			case 'super':
				return `/admin/directory/resources/${resource.id}`;
			default:
				return `/directory/${resource.id}`;
		}
	}

	return (
		<S.DirectoryCardsGrid showMap={showMap}>
			<div>
				<S.ResultsAndActionsWrapper>
					<Typography tag="p" variation="2">
						{totalItems === 1 ? '1 Results' : `${formatNumberWithCommas(totalItems)} Results`}
					</Typography>
					<div className="actions-wrapper">
						<S.ToggleButton className="grid-button first" active={selectedViewOption === 'grid'} onClick={() => setSelectedViewOption('grid')}>
							<FontAwesomeIcon icon={['fal', 'th-large']} />
						</S.ToggleButton>
						<S.ToggleButton className="grid-button last" active={selectedViewOption === 'list'} onClick={() => setSelectedViewOption('list')}>
							<FontAwesomeIcon icon={['fal', 'list']} />
						</S.ToggleButton>
						<S.ToggleButton active={showMap} onClick={() => setShowMap((prev) => !prev)}>
							<FontAwesomeIcon icon={['fal', 'map']} />
							<Typography tag="p" variation="2">
								Show Map
							</Typography>
						</S.ToggleButton>
					</div>
				</S.ResultsAndActionsWrapper>
				{data.length > 0 ? (
					<S.CardsWrapper viewOption={showMap && selectedViewOption === 'grid' ? 'list' : selectedViewOption}>
						{data.map((resource) => (
							<DirectoryCard
								key={resource.id}
								to={resource.type !== 'information' ? generateLinkToResource(resource) : `/${role || 'teacher'}/information/${resource.id}`}
								back={backLink}
								status={resource.status}
								variation={showMap && selectedViewOption === 'grid' ? 'list-alt' : selectedViewOption}
								thumbnail={resource.coverImageObj['800']}
								bookmarked={resource.isSaved}
								role={role}
								type={resource.category}
								title={resource.title}
								location={resource?.creator?.business?.name || resource?.business?.name || `${resource?.creator?.firstName} ${resource?.creator?.lastName}`}
								bookmarkAction={() => {
									handleSaveUnsave(resource.id);
								}}
								isArchived={resource.isArchived}
							/>
						))}
					</S.CardsWrapper>
				) : (
					<EmptyComponent title="No available resources" message="Check back later to view available resources in the directory." icon={['fal', 'books']} />
				)}
			</div>
			{showMap && (
				<S.MapWrapper>
					<Map className="map-component" role={role} enableGeolocationFallback={!role} markers={data.filter((datum) => datum.type !== 'information')} />
				</S.MapWrapper>
			)}
		</S.DirectoryCardsGrid>
	);
};

DirectoryCardsGrid.propTypes = {
	data: PropTypes.array,
	role: PropTypes.string,
	handleSaveUnsave: PropTypes.func,
	totalItems: PropTypes.number,
};

export { DirectoryCardsGrid };
