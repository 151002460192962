import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTable, useFlexLayout } from 'react-table';
import { useHistory } from 'react-router-dom';
import { useDebouncedFn } from 'beautiful-react-hooks';
import toast from 'react-hot-toast';

import { fetchStaff } from '../../services/school';
import { recordView } from '../../services/metrics';
import { TeacherNavigation, PageHeader, Typography, TableComponents, Card, Spinner, ErrorComponent, EmptyComponent, FilterSection, Button } from '../../components';
import * as S from './TeacherSchool.styles';
import { META_TITLE, SOURCES, STAFF_SORT_OPTIONS } from '../../../constants/general.constants';
import { buildAddressString } from '../../utils/location-methods';

const TeacherSchool = () => {
	const user = useSelector((state) => state.user.value);
	const history = useHistory();
	const [pageStatus, setPageStatus] = useState('idle');
	const [staffMembers, setStaffMembers] = useState([]);

	const initialPageNumber = 0;
	const [pageNumber, setPageNumber] = useState(initialPageNumber);
	const [totalPages, setTotalPages] = useState(undefined);
	const [loadingMore, setLoadingMore] = useState(false);

	const filterRef = useRef();

	const onChangeCallback = useDebouncedFn(async () => {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchStaff({ page: initialPageNumber, ...filterRef.current.value });
			setStaffMembers(result.staff);
			setPageNumber(initialPageNumber);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}, 300);

	async function loadMore() {
		setLoadingMore(true);
		try {
			if (totalPages > pageNumber + 1) {
				const {
					data: { result },
				} = await fetchStaff({ page: initialPageNumber, ...filterRef.current.value });
				setStaffMembers((prev) => [...prev, ...result.staff]);
				setTotalPages(result.totalPages);
				setPageNumber((prev) => prev + 1);
			} else {
				toast.error('No more resources to load');
			}
			setLoadingMore(false);
		} catch (error) {
			setLoadingMore(false);
		}
	}

	async function initializeData() {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchStaff({ page: initialPageNumber, ...filterRef.current.value });
			setStaffMembers(result.staff);
			setTotalPages(result.totalPages);
			setPageStatus('success');
			await recordView({ sourceType: SOURCES.SCHOOL, sourceId: user.primarySchool.id });
		} catch (error) {
			setPageStatus('error');
		}
	}

	useEffect(() => {
		// Set document title
		document.title = `My School | Dashboard | ${META_TITLE}`;

		// Load initial data
		if (pageStatus === 'idle') {
			initializeData();
		}
	}, [pageStatus]);

	const columns = React.useMemo(
		() => [
			{
				Header: 'First Name',
				accessor: 'firstName',
			},
			{
				Header: 'Last Name',
				accessor: 'lastName',
			},
			{
				Header: '# of Bookings',
				accessor: 'metricBookingsCreated',
			},
			{
				Header: '# of Requests',
				accessor: 'metricRequestsCreated',
			},
			{
				Header: '# of Information',
				accessor: 'metricInformationCreated',
			},
			{
				Header: '# of Groups',
				accessor: 'metricGroupsCreated',
			},
		],
		[]
	);
	const data = React.useMemo(() => staffMembers, [staffMembers]);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
		{
			columns,
			data,
		},
		useFlexLayout
	);

	const renderContent = () => {
		switch (pageStatus) {
			case 'loading':
				return <Spinner />;
			case 'error':
				return <ErrorComponent />;
			case 'success':
				return rows.length > 0 ? (
					<table {...getTableProps()}>
						<thead>
							{headerGroups.map(({ key, ...headerGroup }) => (
								<tr key={key} {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map(({ key, ...column }) => (
										<th key={key} {...column.getHeaderProps()}>
											<Typography tag="h6" weight="semibold">
												{column.render('Header')}
											</Typography>
										</th>
									))}
								</tr>
							))}
						</thead>
						<tbody {...getTableBodyProps()}>
							{rows.map(({ key, ...row }) => {
								prepareRow(row);
								return (
									<TableComponents.ClickableRow
										key={key}
										{...row.getRowProps()}
										onClick={() => {
											history.push(`/teacher/school/staff/${row.original.id}`);
										}}
									>
										{row.cells.map(({ key, ...cell }) => {
											return (
												<td key={key} {...cell.getCellProps()}>
													<Typography tag="p" variation="2">
														{cell.render('Cell')}
													</Typography>
												</td>
											);
										})}
									</TableComponents.ClickableRow>
								);
							})}
						</tbody>
					</table>
				) : (
					<EmptyComponent title="No staff members" message="Check back later to view all teachers at your school." icon={['fal', 'school']} />
				);
			default:
				return null;
		}
	};

	return (
		<TeacherNavigation>
			<PageHeader title={user.primarySchool.name} />
			<S.DetailsWrapper>
				<div className="school__element">
					<Typography tag="h6" weight="semibold">
						School ID
					</Typography>
					<Typography tag="p">{user.primarySchool.schoolCode}</Typography>
				</div>
				<div className="school__element">
					<Typography tag="h6" weight="semibold">
						County
					</Typography>
					<Typography tag="p">{user.primarySchool.addressCountyDisplay}</Typography>
				</div>
				<div className="school__element">
					<Typography tag="h6" weight="semibold">
						Address
					</Typography>
					<Typography tag="p">{buildAddressString(user.primarySchool.address)}</Typography>
				</div>
			</S.DetailsWrapper>
			<S.Wrapper>
				<FilterSection
					ref={filterRef}
					onChangeCallback={onChangeCallback}
					initialState={{
						searchText: '',
						sortOption: STAFF_SORT_OPTIONS[0],
						showAdvancedFilters: false,
						activeFilters: [],
						distance: 0,
						counties: [],
						locationTypes: [],
						categories: [],
						careerClusters: [],
						ageGroups: [],
						employabilitySkills: [],
						curriculumStandards: [],
					}}
					showFilters={false}
					sortOptions={STAFF_SORT_OPTIONS}
					filterOptions={['counties', 'locationTypes', 'categories', 'careerClusters', 'ageGroups', 'employabilitySkills', 'curriculumStandards']}
				/>
				<Card>
					<TableComponents.TableWrapper>{renderContent()}</TableComponents.TableWrapper>
				</Card>
				{staffMembers?.length > 0 && totalPages > pageNumber + 1 && (
					<S.LoadMoreWrapper>
						<Button variant="outline" variation="secondary" type="button" onClick={loadMore}>
							<Typography variation="button-medium" weight="bold">
								{loadingMore ? 'Loading...' : 'Load More'}
							</Typography>
						</Button>
					</S.LoadMoreWrapper>
				)}
			</S.Wrapper>
		</TeacherNavigation>
	);
};

export default TeacherSchool;
