import styled from 'styled-components';

import { Link as ELink } from '../../components';
import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1.5rem 1rem;

	.login-message {
		margin-top: 1.5rem;
	}
`;

export const Header = styled.header`
	width: 100%;
	display: flex;
	align-items: center;

	margin-bottom: 1.5rem;

	.centered {
		display: flex;
		flex: 1;
		justify-content: center;
	}

	.flex-1 {
		flex: 1;

		.button-label {
			display: none;

			@media screen and (min-width: 768px) {
				display: block;
			}
		}
	}
`;

export const Picture = styled.picture`
	display: flex;
	height: 3.125rem;

	img {
		height: 100%;
	}
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 55rem;
	background-color: white;
	border: 1px solid ${colors.neutralForm};
	border-radius: 0.375rem;
	padding: 2rem 1rem 1.5rem;

	.description {
		margin-top: 1rem;
	}

	@media screen and (min-width: 768px) {
		padding: 3.4375rem 1rem;
	}
`;

export const Form = styled.form`
	display: grid;
	grid-template-columns: 1fr;
	gap: 1rem;
	margin: 0 auto;
	width: 100%;
	max-width: 25.625rem;
	margin-top: 2.5rem;

	.submit-button {
		justify-self: center;
		margin-top: 2.5rem;
	}
`;

export const Link = styled(ELink)`
	display: inline-block;
	margin-top: 1rem;
	color: ${colors.brandPrimary};
	font-weight: 400;
	font-size: 0.75rem;
`;
