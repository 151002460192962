import React, { useRef, useContext } from 'react';

import { BusinessCompanyProfileTabContext } from '../ProfileTab';
import { Avatar, Card, EditBusinessModal, Typography } from '../../../../components';
import * as S from './OverviewCard.styles';

export const OverviewCard = () => {
	const {
		state: { businessProfile },
		dispatch,
	} = useContext(BusinessCompanyProfileTabContext);

	const editModalRef = useRef();

	function handleEditClick() {
		editModalRef.current.open();
	}

	return (
		<>
			<Card title="Overview" transparentHeaderBorder actions={[{ id: 1, label: 'Edit', size: 'small', variant: 'outline', icon: { source: ['fal', 'edit'] }, onClick: handleEditClick }]}>
				<S.Wrapper>
					<div className="avatar-wrapper">
						<Avatar avatar={businessProfile?.logoObj?.[200]} />
					</div>
					<div className="info-wrapper">
						<div className="element-info">
							<Typography className="element-info__title" tag="h6" weight="semibold">
								Company
							</Typography>
							<Typography className="element-info__text" tag="p">
								{businessProfile?.name}
							</Typography>
						</div>
						<div className="element-info">
							<Typography className="element-info__title" tag="h6" weight="semibold">
								Bio
							</Typography>
							<Typography className="element-info__text" tag="p">
								{businessProfile?.bio}
							</Typography>
						</div>
						{businessProfile?.careerClusters?.length > 0 && (
							<div className="element-info">
								<Typography className="element-info__title" tag="h6" weight="semibold">
									Career Cluster
								</Typography>
								<div className="element-info__badges-wrapper">
									{businessProfile?.careerClusters.map((careerCluster, index) => (
										<div className="element-info__badge" key={index}>
											{careerCluster}
										</div>
									))}
								</div>
							</div>
						)}
						{businessProfile?.memberships?.length > 0 && (
							<div className="element-info">
								<Typography className="element-info__title" tag="h6" weight="semibold">
									Memberships
								</Typography>
								<div className="element-info__badges-wrapper">
									{businessProfile?.memberships.map((membership, index) => (
										<div className="element-info__badge" key={index}>
											{membership}
										</div>
									))}
								</div>
							</div>
						)}
						<div className="info-wrapper__grid-wrapper">
							<div className="element-info">
								<Typography className="element-info__title" tag="h6" weight="semibold">
									Website
								</Typography>
								<Typography className="element-info__text" tag="p">
									{businessProfile?.website}
								</Typography>
							</div>
							{businessProfile?.linkedin && (
								<div className="element-info">
									<Typography className="element-info__title" tag="h6" weight="semibold">
										LinkedIn
									</Typography>
									<Typography className="element-info__text" tag="p">
										{businessProfile?.linkedin}
									</Typography>
								</div>
							)}
							{businessProfile?.facebook && (
								<div className="element-info">
									<Typography className="element-info__title" tag="h6" weight="semibold">
										Facebook
									</Typography>
									<Typography className="element-info__text" tag="p">
										{businessProfile?.facebook}
									</Typography>
								</div>
							)}
							{businessProfile?.instagram && (
								<div className="element-info">
									<Typography className="element-info__title" tag="h6" weight="semibold">
										Instagram
									</Typography>
									<Typography className="element-info__text" tag="p">
										{businessProfile?.instagram}
									</Typography>
								</div>
							)}
							{businessProfile?.twitter && (
								<div className="element-info">
									<Typography className="element-info__title" tag="h6" weight="semibold">
										Twitter
									</Typography>
									<Typography className="element-info__text" tag="p">
										{businessProfile?.twitter}
									</Typography>
								</div>
							)}
						</div>
					</div>
				</S.Wrapper>
			</Card>
			<EditBusinessModal ref={editModalRef} company={businessProfile} dispatch={dispatch} />
		</>
	);
};
