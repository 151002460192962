import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDebouncedFn } from 'beautiful-react-hooks';
import { useHistory, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';

import { AddEmployeeModal, Typography, Spinner, ErrorComponent, EmptyComponent, FilterSection, Button, TableComponents, Card } from '../../../components';
import * as S from './EmployeesTab.styles';
import { fetchEmployees } from '../../../services/business';
import { useFlexLayout, useTable } from 'react-table';
import { STAFF_SORT_OPTIONS } from '../../../../constants/general.constants';

export const EmployeesTab = ({}) => {
	const { id: businessId } = useParams();
	const history = useHistory();
	const [pageStatus, setPageStatus] = useState('idle');
	const [employees, setEmployees] = useState([]);

	const initialPageNumber = 0;
	const [pageNumber, setPageNumber] = useState(initialPageNumber);
	const [totalPages, setTotalPages] = useState(undefined);
	const [loadingMore, setLoadingMore] = useState(false);

	const filterRef = useRef();

	const addEmployeeModalRef = useRef();

	const handleAddEmployee = () => addEmployeeModalRef.current.open(businessId);

	const onChangeCallback = useDebouncedFn(async () => {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchEmployees({ businessId, page: initialPageNumber, ...filterRef.current.value });
			setEmployees(result.employees);
			setPageNumber(initialPageNumber);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}, 300);

	async function loadMore() {
		setLoadingMore(true);
		try {
			if (totalPages > pageNumber + 1) {
				const {
					data: { result },
				} = await fetchEmployees({ businessId, page: pageNumber + 1, ...filterRef.current.value });
				setEmployees((prev) => [...prev, ...result.employees]);
				setTotalPages(result.totalPages);
				setPageNumber((prev) => prev + 1);
			} else {
				toast.error('No more resources to load');
			}
			setLoadingMore(false);
		} catch (error) {
			setLoadingMore(false);
		}
	}

	async function initializeData() {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchEmployees({ businessId, page: initialPageNumber });
			setEmployees(result.employees);
			setTotalPages(result.totalPages);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}

	useEffect(() => {
		// Load initial data
		if (pageStatus === 'idle') {
			initializeData();
		}
	}, [pageStatus]);

	const columns = React.useMemo(
		() => [
			{
				Header: 'First Name',
				accessor: 'firstName',
			},
			{
				Header: 'Last Name',
				accessor: 'lastName',
			},
			{
				Header: 'Email',
				accessor: 'email',
			},
			{
				Header: 'Resources Created',
				accessor: 'metricResourcesCreated',
			},
			{
				Header: 'Bookings Completed',
				accessor: 'metricBookingsCompleted',
			},
			{
				Header: 'Requests Completed',
				accessor: 'metricRequestsCompleted',
			},
		],
		[]
	);
	const data = React.useMemo(() => employees, [employees]);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
		{
			columns,
			data,
		},
		useFlexLayout
	);

	const renderContent = () => {
		switch (pageStatus) {
			case 'loading':
				return <Spinner />;
			case 'error':
				return <ErrorComponent />;
			case 'success':
				return rows.length > 0 ? (
					<table {...getTableProps()}>
						<thead>
							{headerGroups.map(({ key, ...headerGroup }) => (
								<tr key={key} {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map(({ key, ...column }) => (
										<th key={key} {...column.getHeaderProps()}>
											<Typography tag="h6" weight="semibold">
												{column.render('Header')}
											</Typography>
										</th>
									))}
								</tr>
							))}
						</thead>
						<tbody {...getTableBodyProps()}>
							{rows.map(({ key, ...row }) => {
								prepareRow(row);
								return (
									<TableComponents.ClickableRow key={key} {...row.getRowProps()} onClick={() => history.push(`/admin/users/${row.original.id}`)}>
										{row.cells.map(({ key, ...cell }) => {
											return (
												<td key={key} className="overflow-hidden" {...cell.getCellProps()}>
													<Typography tag="p" variation="2">
														{cell.render('Cell')}
													</Typography>
												</td>
											);
										})}
									</TableComponents.ClickableRow>
								);
							})}
						</tbody>
					</table>
				) : (
					<EmptyComponent title="No current employees" message="Invite a new employee to get started." icon={['fal', 'users']} />
				);
			default:
				return null;
		}
	};

	return (
		<S.Wrapper>
			<div className="topbar-grid">
				<FilterSection
					ref={filterRef}
					onChangeCallback={onChangeCallback}
					initialState={{
						searchText: '',
						sortOption: STAFF_SORT_OPTIONS[0],
						showAdvancedFilters: false,
						activeFilters: [],
						distance: 0,
						counties: [],
						locationTypes: [],
						categories: [],
						careerClusters: [],
						ageGroups: [],
						employabilitySkills: [],
						curriculumStandards: [],
					}}
					sortOptions={STAFF_SORT_OPTIONS}
					filterOptions={['ageGroups']}
				/>
				<Button type="button" onClick={handleAddEmployee}>
					<FontAwesomeIcon icon={['fal', 'plus']} />
					<Typography tag="span" variation="2" weight="extrablack">
						Add Employee
					</Typography>
				</Button>
			</div>
			<Card>
				<TableComponents.TableWrapper>{renderContent()}</TableComponents.TableWrapper>
			</Card>
			{employees?.length > 0 && totalPages > pageNumber + 1 && (
				<S.LoadMoreWrapper>
					<Button variant="outline" variation="secondary" type="button" onClick={loadMore}>
						<Typography variation="button-medium" weight="bold">
							{loadingMore ? 'Loading...' : 'Load More'}
						</Typography>
					</Button>
				</S.LoadMoreWrapper>
			)}
			<AddEmployeeModal ref={addEmployeeModalRef} onSuccessCallback={onChangeCallback} />
		</S.Wrapper>
	);
};
