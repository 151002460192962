import api from '../api';

export const fetchResources = (data) => api.post('/core/functions/fetchResources', data);

export const fetchResource = (data) => api.post('/core/functions/fetchResource', data);

export const fetchSavedResources = (data) => api.post('/core/functions/fetchSavedResources', data);

export const fetchBusinessProfile = (data) => api.post('/core/functions/fetchBusinessProfile', data);

export const saveUnsaveResource = (data) => api.post('/core/functions/saveUnsaveResource', data);

export const archiveUnarchiveResource = (data) => api.post('/core/functions/archiveUnarchiveResource', data);
