import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Avatar, Card, Typography, EditTeacherProfileModal } from '../../../components';
import * as S from './ProfileCard.styles';

const ProfileCard = ({ className }) => {
	const user = useSelector((state) => state.user.value);
	const dispatch = useDispatch();

	const modalRef = useRef(null);

	const handleCardButtonClick = () => {
		modalRef.current.open();
	};

	return (
		<Card className={className} title="Profile" transparentHeaderBorder={true} actions={[{ id: 1, label: 'Edit', size: 'small', variant: 'outline', icon: { source: ['fal', 'edit'] }, onClick: handleCardButtonClick }]}>
			<S.Wrapper>
				<div className="avatar-wrapper">
					<Avatar avatar={user?.profileImageObj?.[200]} />
				</div>
				<div className="info-wrapper">
					<div className="element-info">
						<Typography className="element-info__title" tag="h6" weight="semibold">
							Teacher UID
						</Typography>
						<Typography className="element-info__text" tag="p">
							{user?.uid}
						</Typography>
					</div>
					<div className="element-info">
						<Typography className="element-info__title" tag="h6" weight="semibold">
							Role
						</Typography>
						<Typography className="element-info__text" tag="p">
							{user?.teacherRole}
						</Typography>
					</div>
					<div className="element-info">
						<Typography className="element-info__title" tag="h6" weight="semibold">
							School
						</Typography>
						<Typography className="element-info__text" tag="p">
							{user?.primarySchool?.name}
						</Typography>
					</div>
				</div>
			</S.Wrapper>
			<EditTeacherProfileModal ref={modalRef} user={user} dispatch={dispatch} currentUser={true} allowedEditUID={false} />
		</Card>
	);
};

ProfileCard.propTypes = {
	className: PropTypes.string,
};

export default ProfileCard;
