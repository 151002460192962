import React, { useEffect, useState, useImperativeHandle, forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { Controller, useForm } from 'react-hook-form';

import { AdminNavigation, PageHeader, Tab, IconButton, Typography, TextInput, Button, Select, Spinner, ErrorComponent } from '../../components';
import * as S from './AdminUsers.styles';
import { TeachersTab } from './TeachersTab';
import { CompanyMembersTab } from './CompanyMembersTab';
import { META_TITLE, TEACHER_ROLES_OPTIONS, PERMISSIONS } from '../../../constants/general.constants';
import { exportUsers } from '../../services/users';
import { errorHandler } from '../../services/authService';
import { inviteTeacher } from '../../services/invites';
import { pattern, required } from '../../utils/form-default-errors';
import { EMAIL } from '../../utils/common-regex';
import { fetchSchools } from '../../services/school';
import { createUpdateEmployee, fetchBusinesses } from '../../services/business';
import toast from 'react-hot-toast';

const AddTeacherModal = forwardRef(({ onSuccessCallback }, ref) => {
	const [loadingStatus, setLoadingStatus] = useState('idle');
	const [isOpen, setIsOpen] = useState(false);
	const [schools, setSchools] = useState([]);

	const {
		handleSubmit,
		register,
		reset,
		setValue,
		control,
		formState: { errors, isSubmitting },
	} = useForm();

	async function initializeData() {
		setLoadingStatus('loading');
		try {
			const {
				data: {
					result: { schools },
				},
			} = await fetchSchools({ page: 0, isSlim: true });
			setSchools(schools);
			setLoadingStatus('success');
		} catch (error) {
			setLoadingStatus('error');
			errorHandler(error);
		}
	}

	const handleOpenModal = () => {
		setIsOpen(true);
	};

	const handleCloseModal = () => {
		setIsOpen(false);
		setTimeout(() => {
			reset();
		}, 200);
	};

	async function asyncCaller(data) {
		await inviteTeacher(data);
		onSuccessCallback();
		handleCloseModal();
	}

	const onSubmit = (e) => {
		handleSubmit(asyncCaller)(e).catch(errorHandler);
	};

	const renderContent = () => {
		switch (loadingStatus) {
			case 'loading':
				return <Spinner />;
			case 'error':
				return <ErrorComponent />;
			case 'success':
				return (
					<S.Form onSubmit={onSubmit}>
						<IconButton className="close-button" type="button" icon={['fal', 'times']} onClick={handleCloseModal} />
						<div className="modal-content">
							<header className="modal-content__header">
								<Typography tag="h2" weight="extrablack" center>
									Add Teacher
								</Typography>
							</header>

							<section className="modal-content__section">
								<TextInput
									label="First Name"
									placeholder="First Name"
									error={errors.firstName}
									{...register('firstName', {
										required: required('First Name'),
									})}
								/>

								<TextInput
									label="Last Name"
									placeholder="Last Name"
									error={errors.lastName}
									{...register('lastName', {
										required: required('Last Name'),
									})}
								/>

								<TextInput
									label="Email"
									placeholder="Email"
									error={errors.email}
									{...register('email', {
										required: required('Email'),
										pattern: pattern('Email', EMAIL),
									})}
								/>

								<TextInput
									label="Teacher UID"
									placeholder="Teacher UID"
									error={errors.uid}
									{...register('uid', {
										required: required('Teacher UID'),
									})}
								/>

								<Controller
									name="roleName"
									control={control}
									rules={{ required: required('Role') }}
									render={({ field: { onChange, value } }) => <Select label="Role" id="role" error={errors?.roleName} placeholder="Role" options={TEACHER_ROLES_OPTIONS} value={TEACHER_ROLES_OPTIONS.find((c) => c.value === value) || ''} onChange={(val) => onChange(val.value)} />}
								/>

								<Controller
									name="primarySchoolId"
									control={control}
									rules={{ required: required('Primary School') }}
									render={({ field: { onChange, value } }) => (
										<Select
											getOptionLabel={(option) => option.name}
											getOptionValue={(option) => option.id}
											label="Primary School"
											placeholder="Primary School"
											id="primary-school"
											error={errors?.primarySchoolId}
											options={schools}
											promise={{
												function: fetchSchools,
												params: { isSlim: true },
												key: 'schools',
												setOptions: setSchools,
											}}
											value={schools.find((c) => c.id === value) || ''}
											onChange={(val) => onChange(val.id)}
										/>
									)}
								/>
							</section>

							<div className="modal-footer">
								<Button variant="outline" variation="secondary" type="button" onClick={handleCloseModal}>
									<Typography variation="button-medium" weight="bold">
										Cancel
									</Typography>
								</Button>
								<div className="flex-1" />
								<Button>
									<Typography variation="button-medium" weight="bold">
										{isSubmitting ? 'Loading' : 'Invite'}
									</Typography>
								</Button>
							</div>
						</div>
					</S.Form>
				);
			default:
				return null;
		}
	};

	useEffect(() => {
		if (loadingStatus === 'idle') {
			initializeData();
		}
	}, []);

	useImperativeHandle(
		ref,
		() => ({
			open: handleOpenModal,
			close: handleCloseModal,
		}),
		[handleOpenModal, handleCloseModal]
	);

	return (
		<ReactModal ref={ref} className="add-user-modal" closeTimeoutMS={200} isOpen={isOpen} onRequestClose={handleCloseModal}>
			{renderContent()}
		</ReactModal>
	);
});

AddTeacherModal.displayName = 'AddTeacherModal';

AddTeacherModal.propTypes = {
	onSuccessCallback: PropTypes.func.isRequired,
};

const AddCompanyMemberModal = forwardRef(({ onSuccessCallback }, ref) => {
	const [loadingStatus, setLoadingStatus] = useState('idle');
	const [isOpen, setIsOpen] = useState(false);
	const [businesses, setBusinesses] = useState([]);

	const {
		handleSubmit,
		register,
		reset,
		setValue,
		control,
		formState: { errors, isSubmitting },
	} = useForm();

	async function initializeData() {
		setLoadingStatus('loading');
		try {
			const {
				data: {
					result: { businesses },
				},
			} = await fetchBusinesses({ page: 0, isSlim: true, filterOptions: { approved: true } });
			setBusinesses(businesses);
			setLoadingStatus('success');
		} catch (error) {
			setLoadingStatus('error');
			errorHandler(error);
		}
	}

	const handleOpenModal = () => {
		setIsOpen(true);
	};

	const handleCloseModal = () => {
		setIsOpen(false);
		setTimeout(() => {
			reset();
		}, 200);
	};

	async function asyncCaller(data) {
		await createUpdateEmployee({ ...data, permissions: [data.permissions] });
		onSuccessCallback();
		handleCloseModal();
	}

	const onSubmit = (e) => {
		handleSubmit(asyncCaller)(e).catch(errorHandler);
	};

	const renderContent = () => {
		switch (loadingStatus) {
			case 'loading':
				return <Spinner />;
			case 'error':
				return <ErrorComponent />;
			case 'success':
				return (
					<S.Form onSubmit={onSubmit}>
						<IconButton className="close-button" type="button" icon={['fal', 'times']} onClick={handleCloseModal} />
						<div className="modal-content">
							<header className="modal-content__header">
								<Typography tag="h2" weight="extrablack" center>
									Add Employee
								</Typography>
							</header>

							<section className="modal-content__section">
								<TextInput
									label="First Name"
									placeholder="First Name"
									error={errors.firstName}
									{...register('firstName', {
										required: required('First Name'),
									})}
								/>

								<TextInput
									label="Last Name"
									placeholder="Last Name"
									error={errors.lastName}
									{...register('lastName', {
										required: required('Last Name'),
									})}
								/>

								<TextInput
									label="Email"
									placeholder="Email"
									error={errors.email}
									{...register('email', {
										required: required('Email'),
										pattern: pattern('Email', EMAIL),
									})}
								/>

								<Controller
									name="permissions"
									control={control}
									rules={{ required: required('Permissions') }}
									render={({ field: { onChange, value } }) => (
										<Select label="Permissions" id="permissions" error={errors?.permissions} placeholder="Permissions" options={PERMISSIONS} value={PERMISSIONS.find((c) => c.value === value) || ''} onChange={(val) => onChange(val.value)} />
									)}
								/>

								<Controller
									name="businessId"
									control={control}
									rules={{ required: required('Business') }}
									render={({ field: { onChange, value } }) => (
										<Select
											getOptionLabel={(option) => option.name}
											getOptionValue={(option) => option.id}
											label="Business"
											placeholder="Business"
											id="primary-school"
											error={errors?.businessId}
											options={businesses}
											promise={{
												function: fetchBusinesses,
												params: { page: 0, isSlim: true, filterOptions: { approved: true } },
												key: 'businesses',
												setOptions: setBusinesses,
											}}
											value={businesses.find((c) => c.id === value) || ''}
											onChange={(val) => onChange(val.id)}
										/>
									)}
								/>
							</section>

							<div className="modal-footer">
								<Button variant="outline" variation="secondary" type="button" onClick={handleCloseModal}>
									<Typography variation="button-medium" weight="bold">
										Cancel
									</Typography>
								</Button>
								<div className="flex-1" />
								<Button>
									<Typography variation="button-medium" weight="bold">
										{isSubmitting ? 'Loading' : 'Add'}
									</Typography>
								</Button>
							</div>
						</div>
					</S.Form>
				);
			default:
				return null;
		}
	};

	useEffect(() => {
		if (loadingStatus === 'idle') {
			initializeData();
		}
	}, []);

	useImperativeHandle(
		ref,
		() => ({
			open: handleOpenModal,
			close: handleCloseModal,
		}),
		[handleOpenModal, handleCloseModal]
	);

	return (
		<ReactModal ref={ref} className="add-user-modal" closeTimeoutMS={200} isOpen={isOpen} onRequestClose={handleCloseModal}>
			{renderContent()}
		</ReactModal>
	);
});

AddCompanyMemberModal.displayName = 'AddCompanyMemberModal';

AddCompanyMemberModal.propTypes = {
	onSuccessCallback: PropTypes.func.isRequired,
};

const AdminUsers = () => {
	const tabRef = useRef();
	const teachersTabRef = useRef();
	const companyMembersTabRef = useRef();
	const [tabs, setTabs] = useState([
		{
			id: 1,
			label: 'Teachers',
			component: <TeachersTab ref={teachersTabRef} />,
		},
		{
			id: 2,
			label: 'Company Members',
			component: <CompanyMembersTab ref={companyMembersTabRef} />,
		},
	]);

	const addTeacherModalRef = useRef();
	const addCompanyMemberModalRef = useRef();

	const handleAddUser = () => {
		if (tabRef.current.currentSelectedTabIndex === 0) {
			addTeacherModalRef.current.open();
		} else {
			addCompanyMemberModalRef.current.open();
		}
	};

	const handleExportExcel = async () => {
		// Export users
		exportUsers({}).then(() => {
			toast.success('Your export is in progress. We will send you an email when it is ready!');
		});
	};

	useEffect(() => {
		// Set document title
		document.title = `Users | ${META_TITLE}`;
	}, []);

	return (
		<AdminNavigation>
			<PageHeader
				title="Users"
				action={[
					{
						id: 1,
						onClick: () => {
							handleExportExcel();
						},
						label: 'Export XLSX',
						type: 'outline',
					},
					{ id: 2, onClick: handleAddUser, label: 'New User', icon: ['fal', 'plus'] },
				]}
			/>
			<AddTeacherModal ref={addTeacherModalRef} onSuccessCallback={() => teachersTabRef.current.onChangeCallback()} />
			<AddCompanyMemberModal ref={addCompanyMemberModalRef} onSuccessCallback={() => companyMembersTabRef.current.onChangeCallback()} />
			<S.Wrapper>
				<Tab tabs={tabs} setTabs={setTabs} ref={tabRef} />
			</S.Wrapper>
		</AdminNavigation>
	);
};

export default AdminUsers;
