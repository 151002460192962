import styled, { css } from 'styled-components';

import colors from '../../../styles/colors';

export const List = styled.ul`
	list-style: none;
`;

export const ListItem = styled.li`
	display: flex;
	flex-direction: column;
	padding: 0.625rem 0;
	border-bottom: 1px solid ${colors.neutralForm};

	&:first-child {
		padding-top: 0;
	}

	.info-wrapper {
		display: flex;
		flex-wrap: wrap;
		gap: 0.5rem;
		align-items: center;

		&__avatar {
			width: 2rem;
			height: 2rem;
			display: block;
			border-radius: 9999px;
			overflow: hidden;

			img {
				width: 100%;
				object-fit: cover;
			}
		}

		&__name {
			flex: 1;
		}
	}

	.details-wrapper {
		display: flex;
		margin-top: 0.5rem;
		margin-left: 2.5rem;
		flex-wrap: wrap;
		gap: 0.5rem;
		align-items: center;

		&__item {
			flex: 1;
			display: flex;
			align-items: center;
			gap: 0.5rem;
			color: ${colors.neutralButton};
		}
	}
`;

export const EmptyBusinessCard = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	${({ paddingTop = 2.625, paddingBottom = 3.375 }) => css`
		padding-top: ${paddingTop}rem;
		padding-bottom: ${paddingBottom}rem;
	`}

	.icon-box {
		margin-bottom: 1.25rem;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 10rem;
		height: 10rem;
		border-radius: 9999px;
		background-color: ${colors.neutralBackground};
		border: 1px solid ${colors.neutralForm};
		color: ${colors.neutralButton};
	}

	& > h4 {
		color: ${colors.neutralButton};
		margin-bottom: 0.5rem;
	}
	& > p {
		color: ${colors.neutralButton};
	}
`;
