/* eslint-disable jsx-a11y/aria-role */
import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { formatGradeLevel } from '../../../utils/content-formatter';
import { fetchPendingReviews } from '../../../services/reviews';
import { reviewInformationResource } from '../../../services/information';
import { Card, Picture, Typography, Button, Spinner, ErrorComponent, EmptyComponent, MessageDialog } from '../../../components';
import { StartReviewModal } from '../../InformationDetails/StartReviewModal';
import * as S from './PendingReviews.styles';

export const PendingReviews = ({ className }) => {
	const history = useHistory();
	const [pageStatus, setPageStatus] = useState('idle');
	const [skipReviewIsOpen, setSkipReviewIsOpen] = useState(false);
	const [currentReview, setCurrentReview] = useState(null);
	const [reviews, setReviews] = useState([]);

	const startReviewModalRef = useRef(null);

	function handleOpenSkipReviewModal() {
		setSkipReviewIsOpen(true);
	}

	function handleCloseSkipReviewModal() {
		setSkipReviewIsOpen(false);
	}

	const handleSkipReview = async () => {
		setSkipReviewIsOpen(false);
		try {
			const {
				data: { result },
			} = await reviewInformationResource({ resourceId: currentReview.id, action: 'skip' });
			const newReviews = reviews.filter((review) => review.id !== result.resource.id);
			setReviews(newReviews);
			setCurrentReview(null);
		} catch (error) {}
	};

	async function initializeData() {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchPendingReviews({});
			setReviews(result.resources);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}

	useEffect(() => {
		// Load initial data
		if (pageStatus === 'idle') {
			initializeData();
		}
	}, []);

	const renderContent = () => {
		switch (pageStatus) {
			case 'loading':
				return <Spinner />;
			case 'error':
				return <ErrorComponent />;
			case 'success':
				return reviews.length > 0 ? (
					<>
						{reviews.map((review) => (
							<S.ReviewCard
								key={review.id}
								onClick={() => {
									history.push({ pathname: review.type === 'information' ? `/teacher/information/${review.id}` : `/teacher/bookings/${review.id}`, state: { backUrl: window.location.pathname, backTitle: 'Back to Dashboard' } });
								}}
							>
								<div className="review-card__card-wrapper">
									<Picture className="review-card__thumbnail" src={review.coverImageObj['200']} aspectRatio="3/2" />
									<div className="review-card__info-wrapper">
										<Typography className="review-card__info-wrapper__type" tag="p" variation="3">
											{review.category || 'Event'}
										</Typography>
										<Typography className="review-card__info-wrapper__title" tag="h5" weight="bold">
											{review.title}
										</Typography>
										<div className="review-card__info-wrapper__details-wrapper">
											<div key="business" className="review-card__info-wrapper__details-wrapper__detail-item">
												<FontAwesomeIcon icon={['fal', 'building']} />
												<Typography tag="p" variation="2">
													{review.business.name}
												</Typography>
											</div>
											<div key="gradeLevels" className="review-card__info-wrapper__details-wrapper__detail-item">
												<FontAwesomeIcon icon={['fal', 'users']} />
												<Typography tag="p" variation="2">
													{formatGradeLevel(review.gradeLevels, true)}
												</Typography>
											</div>
											<div key="links" className="review-card__info-wrapper__details-wrapper__detail-item">
												<FontAwesomeIcon icon={['fal', 'external-link']} />
												<Typography tag="p" variation="2">
													{review.links.length !== 1 ? `${review.links.length} Links` : '1 Link'}
												</Typography>
											</div>
											<div key="downloads" className="review-card__info-wrapper__details-wrapper__detail-item">
												<FontAwesomeIcon icon={['fal', 'arrow-to-bottom']} />
												<Typography tag="p" variation="2">
													{review.publicFiles.length !== 1 ? `${review.publicFiles.length} Downloads` : '1 Download'}
												</Typography>
											</div>
										</div>
									</div>
								</div>
								<div className="review-card__actions-wrapper">
									<Button
										size="small"
										variant="outline"
										onClick={(e) => {
											e.stopPropagation();
											e.nativeEvent.stopImmediatePropagation();
											setCurrentReview(review);
											handleOpenSkipReviewModal();
										}}
									>
										<Typography variation="button-small" weight="bold">
											Skip Review
										</Typography>
									</Button>
									<Button
										size="small"
										onClick={(e) => {
											e.stopPropagation();
											e.nativeEvent.stopImmediatePropagation();
											startReviewModalRef.current.open(review.id);
										}}
									>
										<Typography variation="button-small" weight="bold">
											Start Review
										</Typography>
									</Button>
								</div>
							</S.ReviewCard>
						))}
					</>
				) : (
					<EmptyComponent title="No pending reviews" message="Check back later to view your available reviews." icon={['fal', 'star']} />
				);
			default:
				return null;
		}
	};

	return (
		<Card
			className={className}
			title="Pending Reviews"
			actions={[
				{
					id: 1,
					label: 'View All',
					size: 'small',
					variant: 'text',
					onClick: () => {
						history.push('/teacher/information');
					},
					icon: {
						source: ['fal', 'arrow-right'],
						placement: 'right',
					},
				},
			]}
		>
			<S.Wrapper>{renderContent()}</S.Wrapper>
			<MessageDialog
				isOpen={skipReviewIsOpen}
				onRequestClose={handleCloseSkipReviewModal}
				title="Skip Review?"
				subtitle="Are you sure you want to opt out of reviewing this resource? Businesses use review feedback to improve their offerings."
				icon={['fal', 'star']}
				actions={[
					{ id: 'back', label: 'Cancel', variant: 'outline', variation: 'default' },
					{ id: 'skipReview', label: 'Skip Review', variant: 'solid', variation: 'default', onClick: handleSkipReview },
				]}
			/>
			<StartReviewModal ref={startReviewModalRef} role={'teacher'} />
		</Card>
	);
};

PendingReviews.propTypes = {
	className: PropTypes.string,
};
