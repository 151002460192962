import styled from 'styled-components';

import colors from '../../styles/colors';

export const Form = styled.form`
	position: relative;

	.close-button {
		position: absolute;
		top: 1rem;
		right: 1rem;
		color: ${colors.neutralButton};
	}

	.modal-content {
		display: grid;
		grid-template-columns: 1fr;
		gap: 2rem;
		margin: 0 auto 2rem;
		max-width: 41.25rem;
		padding: 3rem 1rem 1rem;

		&__header {
			display: grid;
			grid-template-columns: 1fr;
			gap: 0.5rem;
			margin-bottom: 1rem;
		}

		&__section {
			display: grid;
			grid-template-columns: 1fr;
			gap: 1.5rem;
		}
	}

	.modal-footer {
		display: flex;
		justify-content: flex-end;
		padding: 1.5rem;
		border-top: 1px solid ${colors.neutralForm};
	}
`;

export const FieldsWrapper = styled.div`
	display: grid;
	gap: 1rem;
	margin-top: 0.5rem;

	.location-grid-row {
		display: grid;
		gap: 1rem;

		@media screen and (min-width: 410px) {
			grid-template-columns: 2fr 1fr 1fr;
		}
	}
`;

export const LocationItem = styled.div`
	margin: 2rem auto 1rem;
	width: 100%;
	max-width: 25.625rem;
	position: relative;

	.location-remove {
		position: absolute;
		right: 0;
		top: 0;
		padding: 0;
		color: ${colors.stateDanger};
	}
`;

export const AddWrapper = styled.div`
	display: flex;
	align-items: center;
	margin: 1rem auto 0;
	width: 100%;
	max-width: 25.625rem;

	button {
		margin-left: -1.5625rem;
	}
`;
