import styled from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	min-height: 33.75rem;
	background-color: ${colors.neutralMessage};
`;

export const ChatBody = styled.div`
	flex: 1;
	position: relative;
	background-color: ${colors.neutralBackground};

	.chat {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		inset: 0;
		padding: 1.25rem;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		overflow-x: hidden;
		overflow-y: auto;

		&__last-child {
			visibility: hidden;
			margin: 0 !important;
		}

		&__bubble {
			display: flex;
			width: 100%;
			max-width: 75%;

			.bubble-info-wrapper {
				display: flex;
				flex-direction: column;
				margin-right: 0.5rem;

				&__avatar {
					width: 2.5rem;
					height: 2.5rem;
					border-radius: 9999px;
				}
			}

			.bubble-message-wrapper {
				display: flex;
				flex-direction: column;
				width: 100%;

				&__name {
					margin-bottom: 0.25rem;
					color: ${colors.neutralText};
				}

				&__bubble {
					padding: 0.75rem 0.625rem;
					border-radius: 0.375rem;
					border: 1px solid transparent;
				}
			}

			.bubble-file-wrapper {
				display: flex;
				flex-direction: column;
				width: 100%;
				max-width: 25rem;

				&__link-wrapper {
					display: flex;
					position: relative;
					justify-content: center;
					align-items: center;

					.floating-link-icon {
						visibility: hidden;
						color: ${colors.brandPrimary};
						position: absolute;
					}

					&:hover {
						opacity: 0.8;

						.floating-link-icon {
							visibility: visible;
						}
					}
				}

				&__picture {
					position: relative;
					padding-top: 100%;
					background: white;

					img {
						position: absolute;
						inset: 0;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						height: 100%;
						object-fit: contain;
					}
				}

				&__image {
					border: 1px solid ${colors.neutralForm};
					border-radius: 0.375rem;

					&--with-message {
						border-radius: 0.375rem 0.375rem 0 0;
						border-bottom: none;
					}
				}

				.bubble-message-wrapper__bubble {
					border-top-right-radius: 0;
					border-top-left-radius: 0;
					border-top: none;
				}
			}

			&--self {
				align-self: flex-end;

				.bubble-message-wrapper {
					align-items: flex-end;

					&__bubble {
						background-color: ${colors.brandPrimary};
						border-color: ${colors.brandPrimary};
						color: white;
						border-top-right-radius: 0;
					}
				}

				.bubble-file-wrapper {
					&__image {
						border-color: ${colors.brandPrimary};
					}
				}
			}

			&--other {
				align-self: flex-start;

				.bubble-message-wrapper {
					align-items: flex-start;

					&__bubble {
						background-color: white;
						border-color: ${colors.neutralForm};
						color: black;
						border-top-left-radius: 0;
					}
				}
			}
		}

		& > * + * {
			margin-top: 1rem;
		}
	}
`;

export const ChatFilePreview = styled.div`
	position: absolute;
	bottom: calc(100% + 0.5rem);
	left: 0.5rem;
	display: flex;

	& > * + * {
		margin-left: 0.5rem;
	}

	.chat__footer__preview__item {
		display: flex;
		position: relative;
		justify-content: center;
		align-items: center;

		&__pdf-container {
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: white;
			color: ${colors.brandPrimary};
		}

		&__pdf-container,
		&__image-container {
			width: 3.5rem;
			height: 3.5rem;
			box-shadow: ${colors.normalShadow};
		}

		&__pdf-container,
		&__image-container,
		&__picture {
			border-radius: 0.25rem;
		}

		button[type='button'] {
			position: absolute;
			visibility: hidden;
			height: 100%;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #000;
		}

		&:hover {
			.chat__footer__preview__item__pdf-container,
			.chat__footer__preview__item__image-container {
				box-shadow: none;
				opacity: 0.5;
			}
			button[type='button'] {
				visibility: visible;
			}
		}
	}
`;

export const ChatFooter = styled.form`
	display: flex;
	align-items: center;
	padding: 1.25rem;
	border: 1px solid ${colors.neutralForm};
	border-left: none;
	position: relative;
	background-color: white;

	.chat__footer {
		&__file-button {
			color: ${colors.brandPrimary};
		}

		&__input {
			flex: 1;
			margin: 0 1rem;
			appearance: none;
			border: 1px solid ${colors.neutralForm};
			border-radius: 0.375rem;
			font-size: 1rem;
			padding: 0.5rem 0.75rem;
			background-color: ${colors.neutralBackground};
		}

		&__send-button {
			position: relative;
			height: 2.375rem;

			&__span {
				&--hidden {
					visibility: hidden;
				}
			}

			&__spinner {
				position: absolute;

				display: inline-block;
				width: 1rem;
				height: 1rem;
				margin: 0;
				border: 3px solid rgba(255, 255, 255, 0.3);
				border-radius: 50%;
				border-top-color: #fff;
				animation: spin 1s ease-in-out infinite;
				-webkit-animation: spin 1s ease-in-out infinite;
			}

			@keyframes spin {
				to {
					-webkit-transform: rotate(360deg);
				}
			}
			@-webkit-keyframes spin {
				to {
					-webkit-transform: rotate(360deg);
				}
			}
		}
	}
`;
