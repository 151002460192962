import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as S from './Checkbox.styles';

export const Checkbox = forwardRef(({ containerClassName, ...rest }, ref) => {
	return (
		<S.Wrapper className={containerClassName}>
			<S.CheckboxWrapper>
				<S.Checkbox ref={ref} {...rest} />
				<FontAwesomeIcon className="checkmark" icon={['fal', 'check']} />
			</S.CheckboxWrapper>
		</S.Wrapper>
	);
});

Checkbox.displayName = 'Checkbox';
Checkbox.propTypes = {
	containerClassName: PropTypes.string,
};
