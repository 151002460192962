import React, { useEffect, useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';

import { AppointmentDetailsContext } from '../../RequestsAppointmentDetails';
import { Typography, DetailedCheckbox, TextArea, RangeInput, Select } from '../../../../components';
import { LOCATION_TYPES } from '../../../../../constants/general.constants';
import { required } from '../../../../utils/form-default-errors';
import * as S from '../ScheduleRequestModal.styles';

const LOCATION_TYPES_WITHOUT_IN_PERSON = LOCATION_TYPES.filter(({ id }) => id !== 'inperson');

export const StepTwo = ({ role }) => {
	const {
		state: { request },
	} = useContext(AppointmentDetailsContext);

	const {
		register,
		control,
		watch,
		setValue,
		formState: { errors },
	} = useFormContext();

	const user = useSelector((state) => state.user.value);
	const schoolOptions = [...user.schools];
	const selectedSchool = user.schools.find(({ id }) => id === watch('schoolLocationId'));

	const businessOptions = request?.worksiteLocations || [];
	const selectedBusiness = businessOptions?.find(({ id }) => id === watch('businessLocationId'));

	const locationTypes = watch('locationTypes');

	useEffect(() => {
		if (businessOptions?.length === 0 && Array.isArray(locationTypes) && locationTypes?.includes('worksite')) {
			toast.error("There aren't any available businesses to schedule. Approve a business response to see it here.");
			setValue(
				'locationTypes',
				locationTypes.filter((type) => type !== 'worksite')
			);
		}
	}, [businessOptions, locationTypes]);

	return (
		<div className="modal-content">
			<Typography className="bigger-title" tag="h1" weight="extrablack" center>
				Select a Location
			</Typography>
			<Typography tag="p" className="description" center>
				Select a location you would like for the resource to occur.
			</Typography>

			<S.LocationStep>
				<div>
					<Typography tag="h3" weight="bold">
						Location Type
					</Typography>
					<Typography tag="p" variation="2">
						Select all locations you want to have this resource.
					</Typography>
				</div>
				<S.LocationStepWrapper>
					{LOCATION_TYPES_WITHOUT_IN_PERSON.map(({ id, name, description, icon }) => (
						<DetailedCheckbox key={id} id={id} value={id} title={name} description={description} icon={icon} {...register('locationTypes', { required: required('Location Type') })} />
					))}
					{!!errors?.locationTypes && (
						<div className="error-wrapper">
							<Typography tag="p" variation="2">
								{errors?.locationTypes?.message}
							</Typography>
						</div>
					)}
				</S.LocationStepWrapper>

				<S.SelectedLocations>
					{!locationTypes ||
						(locationTypes.length >= 1 && (
							<>
								<div>
									<Typography tag="h3" weight="bold">
										Location Details
									</Typography>
									<Typography tag="p" variation="2">
										Enter in more information about your locations.
									</Typography>
								</div>
								{locationTypes?.includes('virtual') && (
									<div className="step__location">
										<div className="step__location-icon-wrapper">
											<FontAwesomeIcon icon={['fal', 'camera']} size="lg" />
										</div>
										<div className="step__location-fields-wrapper">
											<Typography tag="h4" weight="bold">
												Virtual
											</Typography>
											<TextArea id="virtualLocationNotes" label="Location Notes" placeholder="Write a message..." error={errors?.virtualLocationNotes} {...register('virtualLocationNotes')} />
										</div>
									</div>
								)}
								{locationTypes?.includes('worksite') && (
									<div className="step__location">
										<div className="step__location-icon-wrapper">
											<FontAwesomeIcon icon={['fal', 'building']} size="lg" />
										</div>
										<div className="step__location-fields-wrapper">
											<Typography tag="h4" weight="bold">
												Worksite Location
											</Typography>
											<Controller
												name="businessLocationId"
												control={control}
												rules={{ required: required('Business Location') }}
												render={({ field: { onChange, value } }) => (
													<Select
														getOptionLabel={(option) => option.name}
														getOptionValue={(option) => option.id}
														label="Business Location"
														placeholder="Select a business..."
														id="business-location"
														error={errors?.businessLocationId}
														options={businessOptions}
														value={businessOptions.find((c) => c.id === value) || ''}
														onChange={(val) => onChange(val.id)}
													/>
												)}
											/>
											{selectedBusiness && (
												<div className="step__information-card">
													<div className="icon-wrapper">
														<FontAwesomeIcon icon="building" />
													</div>
													<div className="info-wrapper">
														<Typography tag="h5" weight="bold">
															{selectedBusiness?.name}
														</Typography>
														<div className="info-line">
															<FontAwesomeIcon icon={['fal', 'map-marker']} />
															<Typography tag="p" variation="2">
																{`${selectedBusiness?.address.addressLine1}, ${selectedBusiness?.address.state} ${selectedBusiness?.address.postalCode}`}
															</Typography>
														</div>
														<div className="info-line">
															<FontAwesomeIcon icon={['fal', 'landmark']} />
															<Typography tag="p" variation="2">
																{selectedBusiness?.address.city}
															</Typography>
														</div>
													</div>
												</div>
											)}
										</div>
									</div>
								)}
								{locationTypes?.includes('student') && (
									<div className="step__location">
										<div className="step__location-icon-wrapper">
											<FontAwesomeIcon icon={['fal', 'users']} size="lg" />
										</div>
										<div className="step__location-fields-wrapper">
											<Typography tag="h4" weight="bold">
												Student Location
											</Typography>
											<Controller
												name="schoolLocationId"
												control={control}
												rules={{ required: required('Student Location') }}
												render={({ field: { onChange, value } }) => (
													<Select
														getOptionLabel={(option) => option.name}
														getOptionValue={(option) => option.id}
														label="Student Location"
														placeholder="Select a school..."
														id="student-location-school"
														error={errors?.schoolLocationId}
														options={schoolOptions}
														value={schoolOptions.find((c) => c.id === value) || ''}
														onChange={(val) => onChange(val.id)}
													/>
												)}
											/>
											{selectedSchool && (
												<div className="step__information-card">
													<div className="icon-wrapper">
														<FontAwesomeIcon icon="school" />
													</div>
													<div className="info-wrapper">
														<Typography tag="h5" weight="bold">
															{selectedSchool?.name}
														</Typography>
														<div className="info-line">
															<FontAwesomeIcon icon={['fal', 'map-marker']} />
															<Typography tag="p" variation="2">
																{`${selectedSchool?.address.addressLine1}, ${selectedSchool?.address.state} ${selectedSchool?.address.postalCode}`}
															</Typography>
														</div>
														<div className="info-line">
															<FontAwesomeIcon icon={['fal', 'landmark']} />
															<Typography tag="p" variation="2">
																{selectedSchool?.address.city}
															</Typography>
														</div>
														<div className="info-line">
															<FontAwesomeIcon icon={['fal', 'school']} />
															<Typography tag="p" variation="2">
																{selectedSchool?.schoolCode}
															</Typography>
														</div>
													</div>
												</div>
											)}

											<TextArea id="studentLocationNotes" label="Location Notes" placeholder="Write a message..." error={errors?.studentLocationNotes} {...register('studentLocationNotes')} />
										</div>
									</div>
								)}
							</>
						))}
				</S.SelectedLocations>
			</S.LocationStep>
		</div>
	);
};

StepTwo.propTypes = {
	role: PropTypes.string,
};
