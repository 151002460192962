import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import toast from 'react-hot-toast';

import logo from '../../assets/ncbce-logo-alt.png';
import { clearUser, updateUser } from '../../app/slices/user/userSlice';
import { addUpdateBusinessLocations } from '../../services/businessLocation';
import { logoutUser } from '../../services/authService';
import { errorHandler } from '../../services/authService';
import { required } from '../../utils/form-default-errors';
import { Button, Typography, TextInput, Stepper, Select } from '../../components';
import { STATE_OPTIONS, META_TITLE } from '../../../constants/general.constants';
import * as S from './SignupBusinessCompanyLocations.styles';
import { clearSavedStates } from '../../app/slices/filterSearch/filterSearchSlice';

const SignupBusinessCompanyLocations = () => {
	const {
		register,
		control,
		getValues,
		formState: { errors, isSubmitting },
		handleSubmit,
	} = useForm();
	const history = useHistory();
	const { fields, append, remove } = useFieldArray({ control, name: 'locations' });
	const user = useSelector((state) => state.user.value);
	const dispatch = useDispatch();

	const handleAddLocation = () => {
		append({});
	};

	const handleRemoveLocation = (index) => {
		remove(index);
	};

	const backToHome = () => {
		logoutUser().then(() => {
			dispatch(clearUser());
			dispatch(clearSavedStates());
			history.push('/business/sign-up');
		});
	};

	useEffect(() => {
		// Set document title
		document.title = `Locations | Business Sign Up | ${META_TITLE}`;
	}, []);

	async function asyncCaller(data) {
		const {
			data: { locations },
		} = await addUpdateBusinessLocations(data);

		dispatch(updateUser({ ...user, business: { ...user.business, locations } }));

		history.push('/business/account-pending');
	}

	function onSubmit(e) {
		if (getValues('locations')?.length > 0) {
			handleSubmit(asyncCaller)(e).catch(errorHandler);
		} else {
			handleSubmit(() => {
				toast.error('Please add at least one location.');
			})(e).catch(errorHandler);
		}
	}

	return (
		<S.Wrapper>
			<S.Header>
				<div className="flex-1">
					<Button variant="text" size="small" onClick={backToHome}>
						<FontAwesomeIcon icon={['fal', 'arrow-left']} />
						<Typography className="button-label" variation="button-small">
							Back to Home
						</Typography>
					</Button>
				</div>
				<div className="centered">
					<S.Picture>
						<img src={logo} alt="NCBCE logo" />
					</S.Picture>
				</div>
				<div className="flex-1" />
			</S.Header>
			<S.Container>
				<S.ContainerHeader>
					<Stepper
						activeStep={1}
						steps={[
							{ id: 1, label: 'Account' },
							{ id: 2, label: 'Locations' },
						]}
					/>
				</S.ContainerHeader>
				<Typography tag="h1" weight="extrablack" center>
					Company Locations
				</Typography>
				<Typography tag="p" className="description" center>
					Add locations associated with your company.
				</Typography>
				<S.Form onSubmit={onSubmit}>
					{fields.map(({ id }, index) => (
						<S.LocationItem key={id}>
							<Typography tag="h5" weight="bold">
								Location {index + 1}
							</Typography>
							<Button className="location-remove" variant="text" type="button" onClick={() => handleRemoveLocation(index)}>
								<Typography variation="button-medium" weight="bold">
									Remove
								</Typography>
							</Button>
							<S.FieldsWrapper>
								<TextInput
									label="Location Name"
									id={`location-name-${index}`}
									error={errors?.locations?.[index]?.locationName}
									{...register(`locations.${index}.locationName`, {
										required: required('Location Name'),
									})}
								/>
								<TextInput
									label="Address"
									id={`address-${index}`}
									error={errors?.locations?.[index]?.addressLine1}
									{...register(`locations.${index}.addressLine1`, {
										required: required('Address'),
									})}
								/>
								<div className="location-grid-row">
									<TextInput
										label="City"
										id={`city-${index}`}
										error={errors?.locations?.[index]?.city}
										{...register(`locations.${index}.city`, {
											required: required('City'),
										})}
									/>
									<Controller
										name={`locations.${index}.state`}
										control={control}
										rules={{ required: required('State') }}
										render={({ field: { onChange, value } }) => (
											<Select
												getOptionValue={(option) => `${option['abbreviation']}`}
												getOptionLabel={(option) => `${option['abbreviation']}`}
												label="State"
												id={`state-${index}`}
												error={errors?.locations?.[index]?.state}
												options={STATE_OPTIONS}
												value={STATE_OPTIONS.find((c) => c.abbreviation === value) || ''}
												onChange={(val) => onChange(val.abbreviation)}
											/>
										)}
									/>
									<TextInput
										label="Zipcode"
										id={`zipcode-${index}`}
										error={errors?.locations?.[index]?.postalCode}
										{...register(`locations.${index}.postalCode`, {
											required: required('Zipcode'),
										})}
									/>
								</div>
							</S.FieldsWrapper>
						</S.LocationItem>
					))}

					<S.AddWrapper>
						<Button variant="text" type="button" onClick={handleAddLocation}>
							<FontAwesomeIcon icon={['fal', 'plus']} />
							<Typography variation="button-medium" weight="bold">
								Add Location
							</Typography>
						</Button>
					</S.AddWrapper>
					<S.ContainerFooter>
						<div className="flex-1" />
						<Button>
							<Typography variation="button-medium" weight="bold">
								{isSubmitting ? 'Loading...' : 'Complete'}
							</Typography>
						</Button>
					</S.ContainerFooter>
				</S.Form>
			</S.Container>
		</S.Wrapper>
	);
};

export default SignupBusinessCompanyLocations;
