import React, { useState, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { Controller, useForm } from 'react-hook-form';

import { pattern, required } from '../../utils/form-default-errors';
import { EMAIL } from '../../utils/common-regex';
import { errorHandler } from '../../services/authService';
import { createUpdateEmployee } from '../../services/business';
import { Typography, Spinner, ErrorComponent, Button, IconButton, TextInput, Select } from '../';
import * as S from './AddEmployeeModal.styles';
import { fetchBusinessProfile } from '../../services/directory';
import { PERMISSIONS } from '../../../constants/general.constants';

export const AddEmployeeModal = forwardRef(({ onSuccessCallback }, ref) => {
	const [loadingState, setLoadingState] = useState('idle');
	const [locations, setLocations] = useState([]);
	const [isOpen, setIsOpen] = useState(false);

	const {
		handleSubmit,
		register,
		reset,
		setValue,
		control,
		formState: { errors, isSubmitting },
	} = useForm();

	const handleOpenModal = async (businessId) => {
		setLoadingState('loading');
		setIsOpen(true);
		setValue('businessId', businessId);
		try {
			const {
				data: { result },
			} = await fetchBusinessProfile({ businessId });
			setLocations(result.business.locations);
			setLoadingState('success');
		} catch (error) {
			setLoadingState('error');
			errorHandler(error);
		}
	};

	const handleCloseModal = () => {
		setIsOpen(false);
		setTimeout(() => {
			reset();
		}, 200);
	};

	async function asyncCaller(data) {
		await createUpdateEmployee({ ...data, permissions: [data.permissions] });
		onSuccessCallback();
		handleCloseModal();
	}

	const onSubmit = (e) => {
		handleSubmit(asyncCaller)(e).catch(errorHandler);
	};

	useImperativeHandle(
		ref,
		() => ({
			open: handleOpenModal,
			close: handleCloseModal,
		}),
		[handleOpenModal, handleCloseModal]
	);

	const renderContent = () => {
		switch (loadingState) {
			case 'loading':
				return <Spinner />;
			case 'success':
				return (
					<S.Form onSubmit={onSubmit}>
						<IconButton className="close-button" type="button" icon={['fal', 'times']} onClick={handleCloseModal} />
						<div className="modal-content">
							<header className="modal-content__header">
								<Typography tag="h2" weight="extrablack" center>
									Add Employee
								</Typography>
							</header>

							<section className="modal-content__section">
								<TextInput
									label="First Name"
									placeholder="First Name"
									error={errors.firstName}
									{...register('firstName', {
										required: required('First Name'),
									})}
								/>

								<TextInput
									label="Last Name"
									placeholder="Last Name"
									error={errors.lastName}
									{...register('lastName', {
										required: required('Last Name'),
									})}
								/>

								<TextInput
									label="Email"
									placeholder="Email"
									error={errors.email}
									{...register('email', {
										required: required('Email'),
										pattern: pattern('Email', EMAIL),
									})}
								/>

								<Controller
									name="locationIds"
									control={control}
									rules={{ required: required('Location(s)') }}
									render={({ field: { onChange, value } }) => (
										<Select
											isMulti
											getOptionValue={(option) => option.id}
											getOptionLabel={(option) => option.name}
											label="Location(s)"
											id="locationIds"
											error={errors?.locationIds}
											placeholder="Location(s)"
											options={locations}
											value={value?.map((val) => locations.find((c) => c.id === val)) || ''}
											onChange={(val) => onChange(val?.map((val) => val.id))}
										/>
									)}
								/>

								<Controller
									name="permissions"
									control={control}
									rules={{ required: required('Permissions') }}
									render={({ field: { onChange, value } }) => (
										<Select label="Permissions" id="permissions" error={errors?.permissions} placeholder="Permissions" options={PERMISSIONS} value={PERMISSIONS.find((c) => c.value === value) || ''} onChange={(val) => onChange(val.value)} />
									)}
								/>
							</section>

							<div className="modal-footer">
								<Button variant="outline" variation="secondary" type="button" onClick={handleCloseModal}>
									<Typography variation="button-medium" weight="bold">
										Cancel
									</Typography>
								</Button>
								<div className="flex-1" />
								<Button>
									<Typography variation="button-medium" weight="bold">
										{isSubmitting ? 'Loading' : 'Add Employee'}
									</Typography>
								</Button>
							</div>
						</div>
					</S.Form>
				);
			case 'error':
				return <ErrorComponent />;
			default:
				return null;
		}
	};

	return (
		<ReactModal ref={ref} className="add-employee-modal" closeTimeoutMS={200} isOpen={isOpen} onRequestClose={handleCloseModal}>
			{renderContent()}
		</ReactModal>
	);
});

AddEmployeeModal.displayName = 'AddEmployeeModal';

AddEmployeeModal.propTypes = {
	onSuccessCallback: PropTypes.func.isRequired,
};
