import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import * as S from './Radio.styles';

export const Radio = forwardRef(({ containerClassName, ...rest }, ref) => {
	return (
		<S.Wrapper className={containerClassName}>
			<S.Radio ref={ref} {...rest} />
		</S.Wrapper>
	);
});

Radio.displayName = 'Radio';
Radio.propTypes = {
	containerClassName: PropTypes.string,
};
