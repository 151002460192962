import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter, Switch, Redirect } from 'react-router-dom';

import { BusinessRoute, PublicRoute, SuperAdminRoute, TeacherRoute, PublicAuthRoute, UserRoute } from './navigation/route-components';
import {
	AdminProfile,
	AdminUsers,
	AdminBookings,
	AdminSchools,
	AdminTemplate,
	AdminTemplates,
	CreateResource,
	BusinessCompany,
	BusinessLoginForm,
	BusinessSignUpForm,
	BusinessProfile,
	BusinessRequests,
	ForgotPasswordForm,
	ResetPasswordForm,
	InviteForm,
	SignupBusinessCreateCompany,
	SignupBusinessCompanyLocations,
	SignupBusinessPending,
	Signin,
	Signup,
	SignupTeacherGroups,
	SignupTeacherOverview,
	TeacherBookings,
	BookingDetail,
	TeacherDashboard,
	Directory,
	TeacherSaved,
	Requests,
	DirectoryResourceDetails,
	DirectoryBusinessProfile,
	RequestsAppointmentDetails,
	TeacherInformation,
	InformationDetails,
	TeacherGroups,
	TeacherGroupDetail,
	TeacherMessages,
	TeacherSchool,
	TeacherSchoolStaffDetail,
	Reports,
	RequestsDirectory,
	CreateRequest,
	TeacherProfile,
	ErrorPage,
	Logout,
	BusinessDashboard,
	BusinessSaved,
	AdminDashboard,
	BusinessBookings,
	BusinessInformation,
	AdminCreateTemplate,
	BusinessMessages,
	BusinessResources,
	BusinessCompanyEmployeeDetail,
	AdminLoginForm,
	AdminMessages,
	AdminCompanies,
	AdminMemberships,
	AdminAdmins,
	AdminUserDetail,
	AdminSchoolDetail,
	AdminCompanyDetail,
	AdminCompanyLocationDetail,
	AdminMembershipDetail,
	AdminAdminDetail,
	About,
	FAQ,
} from './pages';
import { fetchUser } from './app/slices/user/userSlice';
import { Spinner } from './components';
import toast from 'react-hot-toast';

function Router() {
	const status = useSelector((state) => state.user.status);
	const error = useSelector((state) => state.user.error);
	const dispatch = useDispatch();

	useEffect(() => {
		if (status === 'idle') {
			dispatch(fetchUser());
		}
		if (status === 'failed' && error) {
			toast.error(error);
		}
	}, [error, status, dispatch]);

	function renderedContent() {
		if (status === 'loading') {
			return <Spinner />;
		}
		return (
			<>
				<Switch>
					{/* Public Routes */}
					<PublicRoute exact path="/" component={() => <Redirect to="/about" />} />
					<PublicRoute exact path="/directory" component={Directory} />
					<PublicRoute exact path="/directory/:id" component={DirectoryResourceDetails} />
					<PublicRoute exact path="/information/:id" component={InformationDetails} />
					<PublicRoute exact path="/requests" component={Requests} />
					<PublicRoute exact path="/requests/:id" component={RequestsAppointmentDetails} />
					<PublicRoute exact path="/reports" component={Reports} />
					<PublicRoute exact path="/about" component={About} />
					<PublicRoute exact path="/faq" component={FAQ} />

					{/* Auth Routes */}
					<PublicAuthRoute exact path="/login" component={Signin} />
					<PublicAuthRoute exact path="/sign-up" component={Signup} />
					<PublicRoute exact path="/forgot-password" component={ForgotPasswordForm} />
					<PublicRoute exact path="/reset-password/:aToken/:bToken" component={ResetPasswordForm} />
					<PublicRoute exact path="/invite/:aToken/:bToken" component={InviteForm} />
					<UserRoute exact path="/logout" component={Logout} />

					{/* Admin Auth Routes */}
					<PublicAuthRoute exact path="/admin/login" component={AdminLoginForm} />

					{/* Business Auth Routes */}
					<PublicAuthRoute exact path="/business/login" component={BusinessLoginForm} />
					<PublicAuthRoute exact path="/business/sign-up" component={BusinessSignUpForm} />

					{/* Teacher Auth Routes */}
					<TeacherRoute exact path="/sign-up/teacher/overview" component={SignupTeacherOverview} />
					<TeacherRoute exact path="/sign-up/teacher/groups" component={SignupTeacherGroups} />

					{/* Teacher Dashboard Routes */}
					<TeacherRoute exact path="/teacher/dashboard" component={TeacherDashboard} />
					<TeacherRoute exact path="/teacher/directory/resources" component={Directory} fallback="/directory" />
					<TeacherRoute exact path="/teacher/directory/resources/:id" component={DirectoryResourceDetails} fallback="/directory/:id" />
					<TeacherRoute exact path="/teacher/directory/business/:id" component={DirectoryBusinessProfile} />
					<TeacherRoute exact path="/teacher/saved" component={TeacherSaved} />
					<TeacherRoute exact path="/teacher/requests" component={Requests} fallback="/requests" />
					<TeacherRoute exact path="/teacher/requests/:id" component={RequestsAppointmentDetails} fallback="/requests/:id" />
					<TeacherRoute exact path="/teacher/bookings" component={TeacherBookings} />
					<TeacherRoute exact path="/teacher/bookings/:id" component={BookingDetail} />
					<TeacherRoute exact path="/teacher/information" component={TeacherInformation} fallback="/directory" />
					<TeacherRoute exact path="/teacher/information/:id" component={InformationDetails} fallback="/information/:id" />
					<TeacherRoute exact path="/teacher/messages" component={TeacherMessages} />
					<TeacherRoute exact path="/teacher/groups" component={TeacherGroups} />
					<TeacherRoute exact path="/teacher/group/:id" component={TeacherGroupDetail} />
					<TeacherRoute exact path="/teacher/school" component={TeacherSchool} />
					<TeacherRoute exact path="/teacher/school/staff/:id" component={TeacherSchoolStaffDetail} />
					<TeacherRoute exact path="/teacher/reports" component={Reports} fallback="/reports" />
					<TeacherRoute exact path="/teacher/create-request" component={CreateRequest} />
					<TeacherRoute exact path="/teacher/profile" component={TeacherProfile} />

					{/* Business Dashboard Routes */}
					<BusinessRoute exact path="/business/create-company" component={SignupBusinessCreateCompany} />
					<BusinessRoute exact path="/business/company-locations" component={SignupBusinessCompanyLocations} />
					<BusinessRoute exact path="/business/account-pending" component={SignupBusinessPending} />
					<BusinessRoute exact path="/business/dashboard" component={BusinessDashboard} />
					<BusinessRoute exact path="/business/directory/resources" component={Directory} fallback="/directory" />
					<BusinessRoute exact path="/business/directory/resources/:id" component={DirectoryResourceDetails} fallback="/directory/:id" />
					<BusinessRoute exact path="/business/directory/business/:id" component={DirectoryBusinessProfile} />
					<BusinessRoute exact path="/business/profile" component={BusinessProfile} />
					<BusinessRoute exact path="/business/company" component={BusinessCompany} />
					<BusinessRoute exact path="/business/company/employees/:id" component={BusinessCompanyEmployeeDetail} />
					<BusinessRoute exact path="/business/saved" component={BusinessSaved} />
					<BusinessRoute exact path="/business/create-resource" component={CreateResource} />
					<BusinessRoute exact path="/business/bookings" component={BusinessBookings} />
					<BusinessRoute exact path="/business/reports" component={Reports} fallback="/reports" />
					<BusinessRoute exact path="/business/directory/requests" component={RequestsDirectory} fallback="/requests" />
					<BusinessRoute exact path="/business/requests" component={BusinessRequests} fallback="/requests" />
					<BusinessRoute exact path="/business/requests/:id" component={RequestsAppointmentDetails} fallback="/requests/:id" />
					<BusinessRoute exact path="/business/information" component={BusinessInformation} fallback="/directory" />
					<BusinessRoute exact path="/business/information/:id" component={InformationDetails} fallback="/information/:id" />
					<BusinessRoute exact path="/business/bookings/:id" component={BookingDetail} />
					<BusinessRoute exact path="/business/create-request" component={CreateRequest} />
					<BusinessRoute exact path="/business/messages" component={BusinessMessages} />
					<BusinessRoute exact path="/business/resources" component={BusinessResources} />

					{/* Super Admin Dashboard Routes */}
					<SuperAdminRoute exact path="/admin/dashboard" component={AdminDashboard} />
					<SuperAdminRoute exact path="/admin/directory/resources" component={Directory} fallback="/directory" />
					<SuperAdminRoute exact path="/admin/directory/requests" component={RequestsDirectory} fallback="/directory" />
					<SuperAdminRoute exact path="/admin/directory/resources/:id" component={DirectoryResourceDetails} fallback="/directory/:id" />
					<SuperAdminRoute exact path="/admin/directory/business/:id" component={DirectoryBusinessProfile} />
					<SuperAdminRoute exact path="/admin/information" component={() => <Redirect to="/admin/directory/resources" />} />
					<SuperAdminRoute exact path="/admin/information/:id" component={InformationDetails} fallback="/information/:id" />
					<SuperAdminRoute exact path="/admin/profile" component={AdminProfile} />
					<SuperAdminRoute exact path="/admin/users" component={AdminUsers} />
					<SuperAdminRoute exact path="/admin/users/:id" component={AdminUserDetail} />
					<SuperAdminRoute exact path="/admin/bookings" component={AdminBookings} />
					<SuperAdminRoute exact path="/admin/requests" component={Requests} fallback="/requests" />
					<SuperAdminRoute exact path="/admin/schools" component={AdminSchools} />
					<SuperAdminRoute exact path="/admin/schools/:id" component={AdminSchoolDetail} />
					<SuperAdminRoute exact path="/admin/templates" component={AdminTemplates} />
					<SuperAdminRoute exact path="/admin/templates/:id" component={AdminTemplate} />
					<SuperAdminRoute exact path="/admin/create-template" component={AdminCreateTemplate} />
					<SuperAdminRoute exact path="/admin/create-request" component={CreateRequest} />
					<SuperAdminRoute exact path="/admin/create-resource" component={CreateResource} />
					<SuperAdminRoute exact path="/admin/messages" component={AdminMessages} />
					<SuperAdminRoute exact path="/admin/requests/:id" component={RequestsAppointmentDetails} fallback="/requests/:id" />
					<SuperAdminRoute exact path="/admin/bookings/:id" component={BookingDetail} />
					<SuperAdminRoute exact path="/admin/companies" component={AdminCompanies} />
					<SuperAdminRoute exact path="/admin/companies/:id" component={AdminCompanyDetail} />
					<SuperAdminRoute exact path="/admin/companies/:id/locations/:locationId" component={AdminCompanyLocationDetail} />
					<SuperAdminRoute exact path="/admin/memberships" component={AdminMemberships} />
					<SuperAdminRoute exact path="/admin/memberships/:id" component={AdminMembershipDetail} />
					<SuperAdminRoute exact path="/admin/admins" component={AdminAdmins} />
					<SuperAdminRoute exact path="/admin/admins/:id" component={AdminAdminDetail} />
					<SuperAdminRoute exact path="/admin/reports" component={Reports} fallback="/reports" />

					{/* Redirect Routes */}
					<BusinessRoute exact path="/business" component={() => <Redirect to="/business/login" />} />
					<TeacherRoute exact path="/teacher" component={() => <Redirect to="/login" />} />
					<SuperAdminRoute exact path="/super-admin" component={() => <Redirect to="/login" />} />
					<PublicRoute exact path="/insufficient-permissions" component={() => <Redirect to="/logout" />} />

					{/* Error Routes */}
					<PublicRoute component={ErrorPage} />
				</Switch>
			</>
		);
	}

	return <BrowserRouter>{renderedContent()}</BrowserRouter>;
}

export default Router;
