import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import colors from '../../../styles/colors';

export const List = styled.div``;

export const ListItem = styled(Link)`
	text-decoration: none;
	color: black;
	display: flex;
	margin-top: 0.625rem;
	padding-bottom: 0.625rem;
	border-bottom: 1px solid ${colors.neutralForm};

	.list-item {
		&__thumbnail {
			display: flex;
			justify-content: center;
			align-items: center;
			min-width: 2.625rem;
			width: 2.625rem;
			height: 2.625rem;
			border-radius: 9999px;
			border: 1px solid ${colors.neutralForm};
			background-color: ${colors.neutralBackground};
			color: ${colors.brandPrimary};
			margin-right: 0.625rem;
		}
		&__info-wrapper {
			margin-top: 0.125rem;

			&__small-text {
				color: ${colors.neutralText};
				margin-top: 0.375rem;
			}
		}
	}
`;

export const EmptyBusinessCard = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	${({ paddingTop = 2.625, paddingBottom = 3.375 }) => css`
		padding-top: ${paddingTop}rem;
		padding-bottom: ${paddingBottom}rem;
	`}

	.icon-box {
		margin-bottom: 1.25rem;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 10rem;
		height: 10rem;
		border-radius: 9999px;
		background-color: ${colors.neutralBackground};
		border: 1px solid ${colors.neutralForm};
		color: ${colors.neutralButton};
	}

	& > h4 {
		color: ${colors.neutralButton};
		margin-bottom: 0.5rem;
	}
	& > p {
		color: ${colors.neutralButton};
	}
`;
