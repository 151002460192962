import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ROUTER_ROLE = 'teacher';

export const TeacherRoute = ({ component: Component, path, exact = false, fallback }) => {
	const user = useSelector((state) => state.user.value);
	const status = useSelector((state) => state.user.status);
	const userHasRequiredRole = ROUTER_ROLE === user?.role;

	const render = (props) => {
		// Create fallback url
		let fallbackUrl = null;
		if (fallback) {
			fallbackUrl = fallback;
			if (props.match.params && props.match.params.id) {
				fallbackUrl = fallbackUrl.replace(':id', props.match.params.id);
			}
		}

		// Return or redirect
		if (user && userHasRequiredRole) {
			return <Component {...props} role={ROUTER_ROLE} />;
		}
		if (fallbackUrl != null) {
			return (
				<Redirect
					to={{
						pathname: fallbackUrl,
					}}
				/>
			);
		}
		return (
			<Redirect
				to={{
					pathname: '/insufficient-permissions',
				}}
			/>
		);
	};

	if (status === 'idle') {
		return null;
	}

	return <Route exact={exact} path={path} render={render} />;
};

TeacherRoute.propTypes = {
	component: PropTypes.func.isRequired,
	fallback: PropTypes.string,
	path: PropTypes.string,
	exact: PropTypes.bool,
	match: PropTypes.object,
};
