import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { BusinessNavigation, Button, Card, IconButton, PageHeader, Typography } from '../../components';
import * as S from './BusinessDashboard.styles';
import { META_TITLE } from '../../../constants/general.constants';
import { removeActive, removeCompleteProfile, removeEmployees, removePending } from '../../app/slices/businessDashboard/businessDashboardSlice';
import { PendingBookings } from './PendingBookings/PendingBookings';
import { BookedCard } from './BookedCard';
import { RecentActivity } from './RecentActivity';

const BusinessDashboard = () => {
	const history = useHistory();
	const businessDashboard = useSelector((state) => state.businessDashboard);
	const user = useSelector((state) => state.user.value);
	const dispatch = useDispatch();

	useEffect(() => {
		// Set document title
		document.title = `Dashboard | ${META_TITLE}`;
	}, []);

	return (
		<BusinessNavigation>
			{businessDashboard.showActive && (
				<S.ActiveBanner>
					<FontAwesomeIcon icon={['fal', 'check-circle']} size="lg" />
					<div className="info-wrapper">
						<Typography tag="h4" weight="bold">
							Your Account is Now Active
						</Typography>
						<Typography tag="h5">Invite employees to your business account.</Typography>
					</div>
					<IconButton icon={['fal', 'times']} size={1.25} onClick={() => dispatch(removeActive())} />
				</S.ActiveBanner>
			)}
			{businessDashboard.showPending && !user?.business?.isApproved && (
				<S.ActiveBanner>
					<FontAwesomeIcon icon={['fal', 'check-circle']} size="lg" />
					<div className="info-wrapper">
						<Typography tag="h4" weight="bold">
							Your Account is Pending
						</Typography>
						<Typography tag="h5">Once your business account has been approved, you will be able to invite employees.</Typography>
					</div>
					<IconButton icon={['fal', 'times']} size={1.25} onClick={() => dispatch(removePending())} />
				</S.ActiveBanner>
			)}
			<PageHeader title="Dashboard" />
			<S.Wrapper>
				{(businessDashboard.showCompleteProfile || businessDashboard.showEmployees) && (
					<div className="a1 dashboard-banners-wrapper">
						{businessDashboard.showEmployees && (
							<S.DashboardBanner>
								<Typography tag="h3" weight="bold" center>
									Invite Employees
								</Typography>
								<Typography tag="h5" center>
									Invite employees to your business account.
								</Typography>
								<Button onClick={() => history.push('/business/company')}>
									<FontAwesomeIcon icon={['fal', 'envelope']} />
									<Typography weight="bold" variation="button-small">
										Invite Employees
									</Typography>
								</Button>
								<IconButton className="floating-button" icon={['fal', 'times']} size={1.25} onClick={() => dispatch(removeEmployees())} />
							</S.DashboardBanner>
						)}
						{businessDashboard.showCompleteProfile && (
							<S.DashboardBanner>
								<Typography tag="h3" weight="bold" center>
									Complete Your Company Profile
								</Typography>
								<Typography tag="h5" center>
									Add more information to your company profile so teachers are able to know more about you.
								</Typography>
								<Button onClick={() => history.push('/business/company')}>
									<FontAwesomeIcon icon={['fal', 'building']} />
									<Typography weight="bold" variation="button-small">
										Update Company Profile
									</Typography>
								</Button>
								<IconButton className="floating-button" icon={['fal', 'times']} size={1.25} onClick={() => dispatch(removeCompleteProfile())} />
							</S.DashboardBanner>
						)}
					</div>
				)}
				<BookedCard className="a2" />
				<PendingBookings className="a3" />
				<RecentActivity className="a4" />
			</S.Wrapper>
		</BusinessNavigation>
	);
};

export default BusinessDashboard;
