import React, { useRef, useContext } from 'react';
import PropTypes from 'prop-types';

import { BusinessCompanyEmployeeDetailContext } from '../../BusinessCompanyEmployeeDetail';
import { Avatar, Card, Typography, EditBusinessUserProfileModal } from '../../../../components';
import * as S from './ProfileCard.styles';

const ProfileCard = ({ className }) => {
	const {
		state: { employee },
		dispatch,
	} = useContext(BusinessCompanyEmployeeDetailContext);

	const modalRef = useRef(null);

	const handleCardButtonClick = () => {
		modalRef.current.open();
	};

	return (
		<Card className={className} title="Profile" transparentHeaderBorder={true} actions={[{ id: 1, label: 'Edit', size: 'small', variant: 'outline', icon: { source: ['fal', 'edit'] }, onClick: handleCardButtonClick }]}>
			<S.Wrapper>
				<div className="avatar-wrapper">
					<Avatar avatar={employee?.profileImageObj?.[200]} />
				</div>
				<div className="info-wrapper">
					<div className="element-info">
						<Typography className="element-info__title" tag="h6" weight="semibold">
							First Name
						</Typography>
						<Typography className="element-info__text" tag="p">
							{employee?.firstName}
						</Typography>
					</div>
					<div className="element-info">
						<Typography className="element-info__title" tag="h6" weight="semibold">
							Last Name
						</Typography>
						<Typography className="element-info__text" tag="p">
							{employee?.lastName}
						</Typography>
					</div>
					<div className="element-info">
						<Typography className="element-info__title" tag="h6" weight="semibold">
							Role
						</Typography>
						<Typography className="element-info__text capitalized" tag="p">
							{employee?.businessPermissions?.[0] || 'Admin'}
						</Typography>
					</div>
				</div>
			</S.Wrapper>
			<EditBusinessUserProfileModal ref={modalRef} user={employee} dispatch={dispatch} allowUpdatePermissions={false} currentUser={false} />
		</Card>
	);
};

ProfileCard.propTypes = {
	className: PropTypes.string,
};

export default ProfileCard;
