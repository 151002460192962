import React, { useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';

import { AppointmentDetailsContext } from '../../RequestsAppointmentDetails';
import { RatingStars, Typography } from '../../../../components';
import { fetchStaff } from '../../../../services/school';
import * as S from '../CompleteRequestModal.styles';

export const StepFour = ({ role }) => {
	const {
		state: {
			request: { group, reviews },
		},
		dispatch,
	} = useContext(AppointmentDetailsContext);

	const [attendanceAdditionalTeachers, setAttendanceAdditionalTeachers] = useState([]);
	const [attendanceAdditionalTeachersContacts, setAttendanceAdditionalTeachersContacts] = useState([]);

	const { getValues } = useFormContext();

	function returnTotals(fieldObj) {
		return fieldObj ? Object.values(fieldObj).reduce((acc, curr) => acc + (Number.isNaN(Number(curr)) ? 0 : Number(curr)), 0) : 0;
	}

	async function fetchTeacherData() {
		try {
			const teacherIds = getValues('selectedTeachers')
				.filter((teacher) => teacher.id !== 'not-in-the-system')
				.map((teacher) => teacher.id);
			const teachersWithoutId = getValues('selectedTeachers')
				.filter((teacher) => teacher.id === 'not-in-the-system')
				.map(({ firstName, lastName, email }) => ({
					firstName,
					lastName,
					email,
				}));

			if (teacherIds.length > 0) {
				const {
					data: { result },
				} = await fetchStaff();
				setAttendanceAdditionalTeachers(result.staff.filter((teacher) => teacherIds.includes(teacher.id)));
			}

			setAttendanceAdditionalTeachersContacts(teachersWithoutId);
		} catch (error) {
			toast.error('Unable to return the list of teachers');
		}
	}

	useEffect(() => {
		fetchTeacherData();
	}, []);

	return (
		<div className="modal-content">
			<Typography className="bigger-title" tag="h1" weight="extrablack" center>
				Review
			</Typography>
			<Typography tag="p" className="description" center>
				Please look over the review you are about to submit.
			</Typography>

			<div className="fields-wrapper">
				<div className="review__step-item review__attendance">
					<Typography tag="h3" weight="bold">
						Attendance
					</Typography>

					<S.BigFieldset>
						<Typography tag="h4" weight="bold">
							Actual Attendance
						</Typography>
						<S.BigBox>
							<S.BigBoxHeader>
								<Typography tag="h6" weight="semibold">
									Group
								</Typography>
								<Typography tag="h6" weight="semibold">
									# of Students
								</Typography>
							</S.BigBoxHeader>
							<S.BigBoxLine>
								<Typography tag="p">{group?.name}</Typography>
								<p className="box-field">{getValues('attendance.groupCount')}</p>
							</S.BigBoxLine>
							<S.BigBoxLine>
								<Typography tag="p">Additional Students</Typography>
								<p className="box-field">{getValues('attendance.additionalCount')}</p>
							</S.BigBoxLine>
							<S.BigBoxFooter>
								<Typography tag="p" weight="semibold">
									Total
								</Typography>
								<Typography tag="p" weight="semibold" className={group?.totalStudents != returnTotals(getValues('attendance')) && 'warning-text'}>
									{returnTotals(getValues('attendance'))}
								</Typography>
							</S.BigBoxFooter>
						</S.BigBox>
					</S.BigFieldset>

					<div className="review__info-item">
						<Typography tag="h6" weight="semibold">
							How many additional groups attended?
						</Typography>
						<Typography tag="p">{getValues('attendanceAdditionalGroups')}</Typography>
					</div>

					<div className="review__info-item">
						<Typography tag="h6" weight="semibold">
							Additional Teacher&apos;s Name
						</Typography>
						<Typography tag="p">{[...attendanceAdditionalTeachers, ...attendanceAdditionalTeachersContacts].map(({ firstName, lastName }) => `${firstName} ${lastName}`).join(', ')}</Typography>
					</div>
				</div>

				<div className="review__step-item review__demographics">
					<Typography tag="h3" weight="bold">
						Demographics
					</Typography>

					<div className="grid-line age-range">
						<Typography className="grid-title" tag="h5" weight="bold">
							Age Range
						</Typography>

						<div className="review__info-item">
							<Typography tag="h6" weight="semibold">
								Mininum Age
							</Typography>
							<Typography tag="p">{getValues('attendanceMinAge')}</Typography>
						</div>
						<div className="review__info-item">
							<Typography tag="h6" weight="semibold">
								Maximum Age
							</Typography>
							<Typography tag="p">{getValues('attendanceMaxAge')}</Typography>
						</div>
					</div>

					<S.BigFieldset>
						<Typography tag="h4" weight="bold">
							Gender
						</Typography>
						<S.BigBox>
							<S.BigBoxHeader>
								<Typography tag="h6" weight="semibold">
									Gender
								</Typography>
								<Typography tag="h6" weight="semibold">
									# of Students
								</Typography>
							</S.BigBoxHeader>
							<S.BigBoxLine>
								<Typography tag="p">Male</Typography>
								<p className="box-field">{getValues('genderAttendance.male')}</p>
							</S.BigBoxLine>
							<S.BigBoxLine>
								<Typography tag="p">Female</Typography>
								<p className="box-field">{getValues('genderAttendance.female')}</p>
							</S.BigBoxLine>
							<S.BigBoxLine>
								<Typography tag="p">Other</Typography>
								<p className="box-field">{getValues('genderAttendance.other')}</p>
							</S.BigBoxLine>
							<S.BigBoxFooter>
								<Typography tag="p" weight="semibold">
									Total
								</Typography>
								<Typography tag="p" weight="semibold">
									{returnTotals(getValues('genderAttendance'))}
								</Typography>
							</S.BigBoxFooter>
						</S.BigBox>
					</S.BigFieldset>
				</div>

				{reviews.length > 0 && (
					<div className="review__step-item review__businesses">
						<Typography tag="h3" weight="bold">
							Businesses
						</Typography>

						{reviews.map(({ id, business }, index) => (
							<div className="business-review-item smaller" key={id}>
								<div className="business-review-item__image-wrapper">
									<img src={business?.logoObj?.[200]} alt={business?.name} />
								</div>
								<div className="business-review-item__fields-wrapper">
									<Typography tag="h4" weight="bold">
										{business?.name}
									</Typography>
									<RatingStars label="Rating" value={getValues(`reviews.${index}.rating`)} />
									<div className="review__info-item">
										<Typography tag="h6" weight="semibold">
											Comments
										</Typography>
										<Typography tag="p">{getValues(`reviews.${index}.comments`)}</Typography>
									</div>
								</div>
							</div>
						))}
					</div>
				)}

				<div className="review__step-item review__connections">
					<Typography tag="h3" weight="bold">
						Learning Connections
					</Typography>

					<div className="review__info-item review__info-item--with-badge">
						<Typography tag="h6" weight="semibold">
							Employability Skills
						</Typography>
						<div className="badge-wrapper">
							{getValues('employabilitySkills')?.map((value) => (
								<div className="small-badge" key={value}>
									{value}
								</div>
							))}
						</div>
					</div>

					<div className="review__info-item review__info-item--with-badge">
						<Typography tag="h6" weight="semibold">
							Curriculum Standards
						</Typography>
						<div className="badge-wrapper">
							{getValues('curriculumStandards')?.map((value) => (
								<div className="small-badge" key={value}>
									{value}
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

StepFour.propTypes = {
	role: PropTypes.string,
};
