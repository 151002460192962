import { createSlice } from '@reduxjs/toolkit';

export const filterSearchSlice = createSlice({
	name: 'filterSearch',
	initialState: {
		status: 'idle',
		error: null,
		value: {},
	},
	reducers: {
		saveState: (state, action) => {
			state.value[window.location.pathname] = action.payload;
		},
		clearSavedStates: (state) => {
			state.value = {};
		},
	},
});

export const { saveState, clearSavedStates } = filterSearchSlice.actions;

export default filterSearchSlice.reducer;
