import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { errorHandler, beginPasswordReset } from '../../services/authService';
import { pattern, required } from '../../utils/form-default-errors';
import { EMAIL } from '../..//utils/common-regex';
import logo from '../../assets/ncbce-logo-alt.png';
import { Typography, Button, TextInput, Link } from '../../components';
import * as S from './ForgotPasswordForm.styles';
import { META_TITLE } from '../../../constants/general.constants';

const ForgotPasswordForm = () => {
	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitting, isSubmitSuccessful },
	} = useForm();
	const history = useHistory();

	useEffect(() => {
		// Set document title
		document.title = `Forgot Password | ${META_TITLE}`;
	}, []);

	async function asyncCaller(data) {
		await beginPasswordReset({ email: data.email });
	}

	function onSubmit(e) {
		handleSubmit(asyncCaller)(e).catch(errorHandler);
	}

	return (
		<S.Wrapper>
			<S.Header>
				<div className="flex-1" />
				<div className="centered">
					<S.Picture>
						<img src={logo} alt="NCBCE logo" />
					</S.Picture>
				</div>
				<div className="flex-1" />
			</S.Header>
			{isSubmitSuccessful ? (
				<S.Container>
					<FontAwesomeIcon className="envelope-icon" icon={['fal', 'envelope']} size="3x" />
					<Typography tag="h1" weight="extrablack" center>
						Check Your Email
					</Typography>
					<Typography tag="p" className="description" center>
						We sent a link to reset your password to your email address.
					</Typography>

					<Button type="submit" className="submit-button" onClick={() => history.push('/login')}>
						<Typography variation="button-medium" weight="bold">
							Okay
						</Typography>
					</Button>
				</S.Container>
			) : (
				<S.Container>
					<FontAwesomeIcon className="question-icon" icon={['fal', 'question-circle']} size="3x" />
					<Typography tag="h1" weight="extrablack" center>
						Forgot Password
					</Typography>
					<Typography tag="p" className="description" center>
						Enter your email to reset your password.
					</Typography>

					<S.Form onSubmit={onSubmit}>
						<TextInput
							label="Email"
							id="email"
							error={errors.email}
							autoComplete="email"
							{...register('email', {
								required: required('Email'),
								pattern: pattern('Email', EMAIL),
							})}
						/>

						<Button type="submit" className="submit-button">
							<Typography variation="button-medium" weight="bold">
								{isSubmitting ? 'Loading...' : 'Send Reset Link'}
							</Typography>
						</Button>
					</S.Form>
				</S.Container>
			)}
			<Typography tag="h6" className="login-message" center>
				<span>Back to</span>&nbsp;<Link to="/login">Log In</Link>
			</Typography>
		</S.Wrapper>
	);
};

export default ForgotPasswordForm;
