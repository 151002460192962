import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { fetchMessages } from '../../../services/messages';
import { Card, Picture, Typography, Spinner, ErrorComponent } from '../../../components';
import { formatToRelativeTime } from '../../../utils/time-formatter';
import * as S from './RecentResponses.styles';
import { EmptyComponent } from '../../../components/EmptyComponent/EmptyComponent';

export const RecentResponses = ({ className }) => {
	const [pageStatus, setPageStatus] = useState('idle');
	const [responses, setResponses] = useState([]);

	async function initializeData() {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchMessages({ page: 0, displayLimit: 5, filterOptions: { sent: false, received: true } });
			setResponses(result.messages);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}

	useEffect(() => {
		// Load initial data
		if (pageStatus === 'idle') {
			initializeData();
		}
	}, []);

	const renderContent = () => {
		switch (pageStatus) {
			case 'loading':
				return <Spinner />;
			case 'error':
				return <ErrorComponent />;
			case 'success':
				return responses.length > 0 ? (
					<S.List>
						{responses.map((response) => (
							<S.ListItem key={response.id} to={'/teacher/messages'}>
								<Picture className="list-item__thumbnail" src={response.business?.logoObj?.['200']} />
								<div className="list-item__info-wrapper">
									<Typography tag="h5" weight="bold">
										{response.business.name}
									</Typography>
									<Typography tag="p" variation="3" className="list-item__info-wrapper__small-text">
										{formatToRelativeTime(new Date(response.dateSent.iso))} • {response.resource.id != null ? response.resource.title : response.request.title}
									</Typography>
									<Typography tag="p" variation="2">
										{response.message}
									</Typography>
								</div>
							</S.ListItem>
						))}
					</S.List>
				) : (
					<EmptyComponent title="No recent responses" message="Check back later to view your responses." icon={['fal', 'comment']} />
				);
			default:
				return null;
		}
	};

	return (
		<Card className={className} title="Recent Responses">
			{renderContent()}
		</Card>
	);
};

RecentResponses.propTypes = {
	className: PropTypes.string,
};
