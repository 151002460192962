import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';

import { required } from '../../../utils/form-default-errors';
import { Typography } from '../../../components';
import * as S from '../CreateRequest.styles';

export const StepResourceType = ({ role }) => {
	const {
		register,
		formState: { errors },
	} = useFormContext();

	return (
		<S.FormPanel>
			<Typography tag="h2" weight="extrablack" center>
				Select a Type
			</Typography>
			<Typography tag="p" center>
				Select an option for the type of request you would like to make.
			</Typography>
			<div className="radio-wrapper">
				<div className={`radio-field${errors.type ? ' with-error' : ''}`}>
					<input
						className="radio-input"
						type="radio"
						id="appointment"
						value="appointment"
						{...register('type', {
							required: required('Type'),
						})}
					/>
					<FontAwesomeIcon icon={['fal', 'calendar']} size="4x" />
					<Typography tag="h5" weight="bold" center>
						Appointment
					</Typography>
					<Typography tag="p" variation="2" center>
						Select this option to book an individual and/or business for a leadership presentation, attend a career fair, schedule a worksite visit or conduct a mock interview.
					</Typography>
				</div>
				<div className={`radio-field${errors.type ? ' with-error' : ''}`}>
					<input
						className="radio-input"
						type="radio"
						id="information"
						value="information"
						{...register('type', {
							required: required('Request Type'),
						})}
					/>
					<FontAwesomeIcon icon={['fal', 'info-circle']} size="4x" />
					<Typography tag="h5" weight="bold" center>
						Information
					</Typography>
					<Typography tag="p" variation="2" center>
						Select this option to solicit flyers, brochures, manuals, or documents about a specific topic, industry, career path, or field of study from an individual or business.
					</Typography>
				</div>

				{!!errors.type && (
					<div className="error-wrapper">
						<Typography tag="p" variation="2">
							{errors.type.message}
						</Typography>
					</div>
				)}
			</div>
		</S.FormPanel>
	);
};

StepResourceType.propTypes = {
	role: PropTypes.string,
};
