import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

TimeAgo.addDefaultLocale(en);

const timeAgo = new TimeAgo('en-US');

export function formatToRelativeTime(date) {
	return timeAgo.format(date);
}

export function formatToMonthDayAndYear(date) {
	return date.toLocaleDateString('en-US', {
		month: 'long',
		day: 'numeric',
		year: 'numeric',
	});
}

export function formatToDayMonth2DigitYear(date) {
	return date.toLocaleDateString('en-US', { day: 'numeric', month: 'numeric', year: '2-digit' });
}

export function formatToFullDateWithTime(date) {
	return date.toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit' });
}
