import styled from 'styled-components';

import colors from '../../../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding-bottom: 0.25rem;

	& > * + * {
		margin-top: 1rem;
	}

	.location-info {
		flex: 1;

		&__element {
			display: flex;
			border-bottom: 1px solid ${colors.neutralForm};
			margin-bottom: 0.75rem;
			padding-bottom: 0.75rem;

			&:last-child {
				border: none;
				padding-bottom: 0.25rem;
				margin: 0;
			}
		}

		&__detail-wrapper {
			margin-top: 1rem;

			& > * + * {
				margin-top: 0.5rem;
			}

			&__title {
				color: ${colors.neutralText};
			}

			&__count {
				display: inline-block;
				width: 1.25rem;
			}

			&__address {
				margin-left: 1.25rem;
				color: ${colors.neutralText};
			}
		}

		&__icon-wrapper {
			display: flex;
			justify-content: center;
			width: 1.5rem;
			margin-top: 0.125rem;
			margin-right: 0.75rem;
		}

		&__info-wrapper {
		}

		&__heading-wrapper {
			&__title {
				margin-bottom: 0.125rem;
			}

			&__subtitle {
			}
		}
	}

	.location-map {
		flex: 1;

		&__wrapper {
			position: relative;
			width: 100%;
			height: 0;
			border-radius: 0.375rem;
			overflow: hidden;
			padding-top: 66.66%;
		}
	}

	@media screen and (min-width: 992px) {
		flex-direction: row;

		& > * + * {
			margin-top: 0;
			margin-left: 1rem;
		}
	}
`;

export const Form = styled.form`
	position: relative;
	padding: 3rem 1.25rem 0;

	.close-button {
		position: absolute;
		top: 1rem;
		right: 1rem;
		color: ${colors.neutralButton};
	}
`;

export const InfoWrapper = styled.div`
	margin: 0 auto 0.75rem;
	width: 100%;
	max-width: 34.5rem;

	& > * + * {
		margin-top: 0.75rem;
	}
`;

export const FieldsWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	gap: 1.5rem;
	width: 100%;
	max-width: 29rem;
	margin: 2rem auto 0;

	.error-wrapper {
		color: ${colors.stateDanger};
	}
`;

export const ButtonsWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin: 1.75rem -1.25rem 0;
	padding: 1rem;
	border-top: 1px solid ${colors.neutralForm};

	& > * + * {
		margin-left: 1rem;
	}
`;

export const LocationStepWrapper = styled.div`
	position: relative;
	display: grid;
	grid-template-columns: 1fr;
	gap: 1rem;
	margin-top: -0.75rem;

	.error-wrapper {
		bottom: -1.5rem;
	}
`;

export const SelectedLocations = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	gap: 2rem;
	margin-bottom: 1.5rem;

	.step {
		&__location {
			display: grid;
			grid-template-columns: 2rem 1fr;
		}

		&__location-field-row {
			display: grid;
			gap: 0.75rem;

			@media (min-width: 992px) {
				grid-template-columns: 2fr 1fr 1fr;
			}
		}

		&__location-fields-wrapper {
			display: grid;
			grid-template-columns: 1fr;
			gap: 0.75rem;
		}

		&__information-card {
			display: flex;
			padding: 0.5rem 0.75rem;
			background-color: ${colors.neutralBackground};
			border: 1px solid ${colors.neutralForm};
			border-radius: 0.375rem;

			.icon-wrapper {
				display: flex;
				justify-content: center;
				width: 1.25rem;
				color: ${colors.brandPrimary};
			}

			.info-wrapper {
				margin-left: 0.75rem;
				color: #000000;

				.info-line {
					display: flex;
					margin-top: 0.5rem;
					color: ${colors.neutralButton};

					svg {
						width: 1rem;
						height: 1rem;
					}

					p {
						margin-left: 0.5rem;
					}
				}
			}
		}
	}
`;

export const LocationItem = styled.div`
	margin: 0 auto 1rem;
	width: 100%;
	position: relative;

	.location-remove {
		position: absolute;
		right: 0;
		top: 0;
		padding: 0;
		color: ${colors.stateDanger};
	}
`;

export const AddWrapper = styled.div`
	display: flex;
	align-items: center;
	margin: 1rem auto 0;
	width: 100%;

	button {
		margin-left: -1.5625rem;
	}
`;

export const SmallFieldsWrapper = styled.div`
	display: grid;
	gap: 1rem;
	margin-top: 0.5rem;

	.location-grid-row {
		display: grid;
		gap: 1rem;

		@media screen and (min-width: 410px) {
			grid-template-columns: 2fr 1fr 1fr;
		}
	}
`;
