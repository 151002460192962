import styled from 'styled-components';

export const Wrapper = styled.div`
	.nvgtr-map-container .datamaps-legend {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0px;
	}
	.nvgtr-map-container .datamaps-legend dl {
		display: flex;
		justify-content: center;
	}

	.entry-content dl {
		margin: 0 0 20px 0;
	}
	.nvgtr-map-container .datamaps-legend dt,
	.nvgtr-map-container .datamaps-legend dd {
		float: none;
	}

	.datamaps-legend dt,
	.datamaps-legend dd {
		float: left;
		margin: 0 3px 0 0;
	}
	.entry-content dt {
		margin: 5px 0;
		font-weight: bold;
	}
	.nvgtr-map-container .datamaps-legend dt,
	.nvgtr-map-container .datamaps-legend dd {
		float: none;
		display: flex;
		align-items: center;
	}

	.datamaps-legend dd {
		width: 20px;
		height: 20px;
		margin-right: 20px;
		border-radius: 3px;
	}
`;
