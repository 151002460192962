import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useDebouncedFn } from 'beautiful-react-hooks';

import { updateUser } from '../../../app/slices/user/userSlice';
import { updateUserNotifications } from '../../../services/users';
import { errorHandler } from '../../../services/authService';
import { Card, Toggle, Typography } from '../../../components';
import * as S from './NotificationsCard.styles';

const NotificationsCard = ({ className }) => {
	const user = useSelector((state) => state.user.value);
	const dispatch = useDispatch();

	const [notificationValues, setNotificationValues] = useState(user?.preferences?.email);

	const updateNotifications = async (values) => {
		try {
			const {
				data: { result },
			} = await updateUserNotifications({ preferences: { email: values } });
			dispatch(updateUser(result.user));
		} catch (error) {
			errorHandler(error);
		}
	};

	const updateNotificationsDb = useDebouncedFn(updateNotifications, 800, null, []);

	const onChange = (e) => {
		const newValue = { ...notificationValues, [e.target.name]: e.target.checked };
		setNotificationValues(newValue);
		updateNotificationsDb(newValue);
	};

	return (
		<Card className={className} title="Notifications" transparentHeaderBorder={true}>
			<S.Wrapper>
				<Typography tag="h4" weight="bold">
					Email Notifications
				</Typography>
				<S.ToggleLine>
					<div className="line-info">
						<Typography tag="p" weight="semibold">
							All Notifications
						</Typography>
						<Typography tag="p" variation="2">
							Receive email notifications from the Navigator.
						</Typography>
					</div>
					<div className="toggle-wrapper">
						<Toggle name="all" checked={notificationValues?.all} onChange={onChange} />
					</div>
				</S.ToggleLine>
				<S.ToggleLine>
					<div className="line-info">
						<Typography tag="p" weight="semibold">
							New Messages
						</Typography>
						<Typography tag="p" variation="2">
							Receive notifications when you receive a new message.
						</Typography>
					</div>
					<div className="toggle-wrapper">
						<Toggle name="newMessages" checked={notificationValues?.newMessages} onChange={onChange} />
					</div>
				</S.ToggleLine>
				<S.ToggleLine>
					<div className="line-info">
						<Typography tag="p" weight="semibold">
							New Resources
						</Typography>
						<Typography tag="p" variation="2">
							Receive notifications when a new resource is posted that matches a saved search.
						</Typography>
					</div>
					<div className="toggle-wrapper">
						<Toggle name="newResources" checked={notificationValues?.newResources} onChange={onChange} />
					</div>
				</S.ToggleLine>
				<S.ToggleLine>
					<div className="line-info">
						<Typography tag="p" weight="semibold">
							Accepted Bookings
						</Typography>
						<Typography tag="p" variation="2">
							Receive notifications when a booking is accepted.
						</Typography>
					</div>
					<div className="toggle-wrapper">
						<Toggle name="acceptedBookings" checked={notificationValues?.acceptedBookings} onChange={onChange} />
					</div>
				</S.ToggleLine>
			</S.Wrapper>
		</Card>
	);
};

NotificationsCard.propTypes = {
	className: PropTypes.string,
};

export default NotificationsCard;
