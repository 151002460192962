import { Link } from 'react-router-dom';
import styled from 'styled-components';

import colors from '../../../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

export const LocationListItem = styled(Link)`
	text-decoration: none;
	color: black;
	display: flex;
	flex-direction: row;
	border-bottom: 1px solid ${colors.neutralForm};
	padding-bottom: 0.75rem;
	margin-bottom: 0.75rem;

	& > * + * {
		margin-left: 1rem;
	}

	&:last-child {
		border-bottom: none;
		padding-bottom: 0.5rem;
		margin-bottom: 0;
	}

	.info-wrapper p {
		margin-top: 0.125rem;
		color: ${colors.neutralText};
	}
`;
