import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { AppointmentDetailsContext } from '../RequestsAppointmentDetails';
import { ReviewCard } from './ReviewCard';
import { FulfilledCard } from './FulfilledCard';
import { CancellationCard } from './CancellationCard';
import { ScheduleCard } from './ScheduleCard';
import { AcceptedBusinessesCard } from './AcceptedBusinessesCard';

import { REQUEST_STATUSES } from '../../../../constants/general.constants';

import * as S from './AppointmentTab.styles';

export const AppointmentTab = ({ role }) => {
	const {
		state: { request },
	} = useContext(AppointmentDetailsContext);

	return (
		<S.Wrapper>
			{request.type !== 'information' && request.status === REQUEST_STATUSES.COMPLETE && <ReviewCard request={request} role={role} />}
			{request.type === 'information' && request.status === REQUEST_STATUSES.COMPLETE && <FulfilledCard request={request} role={role} />}
			{request.cancelReason != null && request.cancelReason !== '' && <CancellationCard request={request} role={role} />}
			{request.type !== 'information' && request.status === REQUEST_STATUSES.SCHEDULED && <ScheduleCard request={request} role={role} />}
			<AcceptedBusinessesCard request={request} role={role} />
		</S.Wrapper>
	);
};

AppointmentTab.propTypes = {
	role: PropTypes.string,
};
