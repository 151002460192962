import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { AdminCompanyDetailContext } from '../../AdminCompanyLocationDetail';
import { Card, Typography } from '../../../../components';
import * as S from './LocationCard.styles';

const LocationCard = ({ className }) => {
	const {
		state: { companyLocation },
	} = useContext(AdminCompanyDetailContext);

	return (
		<Card className={className} title="Location" transparentHeaderBorder={true}>
			<S.Wrapper>
				<div className="info-wrapper">
					<div className="element-info">
						<Typography className="element-info__title" tag="h6" weight="semibold">
							Location Name
						</Typography>
						<Typography className="element-info__text" tag="p">
							{companyLocation?.name}
						</Typography>
					</div>
					<div className="element-info">
						<Typography className="element-info__title" tag="h6" weight="semibold">
							Address
						</Typography>
						<Typography className="element-info__text capitalized" tag="p">
							{`${companyLocation?.address?.addressLine1}, ${companyLocation?.address?.city}, ${companyLocation?.address?.state} ${companyLocation?.address?.postalCode}`}
						</Typography>
					</div>
				</div>
			</S.Wrapper>
		</Card>
	);
};

LocationCard.propTypes = {
	className: PropTypes.string,
};

export default LocationCard;
