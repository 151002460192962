import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';

import { formatGradeLevel } from '../../../../utils/content-formatter';
import { Card, CompleteResourceEditModal, Picture, Typography } from '../../../../components';
import * as S from './OverviewCard.styles';
import { ROLES } from '../../../../../constants/general.constants';
import { useSelector } from 'react-redux';
import { DirectoryResourceDetailsContext } from '../../DirectoryResourceDetails';
export const OverviewCard = ({ className, role }) => {
	const {
		state: { resource },
	} = useContext(DirectoryResourceDetailsContext);
	const user = useSelector((state) => state.user.value);
	const modalRef = useRef(null);

	const handleEditClick = () => {
		modalRef.current.open();
	};

	return (
		<Card
			title="Overview"
			className={className}
			transparentHeaderBorder
			actions={(role != null && resource.business != null && resource.business.id === user?.business?.id) || role === ROLES.ADMIN || role === ROLES.SUPER ? [{ id: 1, label: 'Edit', size: 'small', variant: 'outline', onClick: handleEditClick, icon: { source: ['fal', 'edit'] } }] : []}
		>
			<S.Wrapper>
				<Picture className="overview-picture" aspectRatio="3/2" src={resource.coverImageObj['800']} />
				<div className="overview-info">
					<div className="overview-info__element a1">
						<Typography className="overview-info__title" tag="h6" weight="semibold">
							Description
						</Typography>
						<Typography className="overview-info__text" tag="p">
							{resource.description}
						</Typography>
					</div>
					<div className="overview-info__element a2">
						<Typography className="overview-info__title" tag="h6" weight="semibold">
							Resource Type
						</Typography>
						<Typography className="overview-info__text" tag="p">
							{resource.category || 'Event'}
						</Typography>
					</div>
					<div className="overview-info__element a3">
						<Typography className="overview-info__title" tag="h6" weight="semibold">
							Grades
						</Typography>
						<Typography className="overview-info__text" tag="p">
							{formatGradeLevel(resource.gradeLevels, true)}
						</Typography>
					</div>
					<div className="overview-info__element a4">
						<Typography className="overview-info__title" tag="h6" weight="semibold">
							Career Clusters
						</Typography>
						<div className="overview-info__badges-wrapper">
							{resource.careerClusters.map((careerCluster, index) => (
								<div className="overview-info__badge" key={index}>
									{careerCluster}
								</div>
							))}
						</div>
					</div>
				</div>
			</S.Wrapper>
			<CompleteResourceEditModal Context={DirectoryResourceDetailsContext} ref={modalRef} />
		</Card>
	);
};

OverviewCard.propTypes = {
	className: PropTypes.string,
	role: PropTypes.string,
};
