import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components';
import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;

	& > label {
		cursor: ${({ hasOnChange }) => (hasOnChange ? 'pointer' : 'default')};
		color: ${({ error }) => (error ? colors.stateDanger : colors.neutralText)};
	}
`;

export const RatingStarIcon = styled(FontAwesomeIcon)`
	width: 1.75rem !important;
	height: 1.75rem !important;
`;

export const StarsWrapper = styled.div`
	display: flex;
	color: ${colors.stateWarning};

	& > * + * {
		margin-left: 0.25rem;
	}
`;

export const FloatingWrapper = styled.div`
	position: absolute;
	bottom: -1.25rem;
	color: ${colors.stateDanger};
	max-width: 100%;

	p {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
`;
