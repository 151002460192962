import React, { useState } from 'react';

import { AllTab } from './AllTab';
import { ArchivedTab } from './ArchivedTab';
import { DraftsTab } from './DraftsTab';
import { PublishedTab } from './PublishedTab';
import { Tab } from '../../../components';
import * as S from './ResourcesTab.styles';

export const ResourcesTab = () => {
	const [tabs, setTabs] = useState([
		{
			id: 1,
			label: 'All',
			component: <AllTab />,
		},
		{
			id: 2,
			label: 'Published',
			component: <PublishedTab />,
		},
		{
			id: 3,
			label: 'Drafts',
			component: <DraftsTab />,
		},
		{
			id: 4,
			label: 'Archived',
			component: <ArchivedTab />,
		},
	]);

	return (
		<S.Wrapper>
			<Tab tabs={tabs} setTabs={setTabs} transparent variation="pill" />
		</S.Wrapper>
	);
};

ResourcesTab.propTypes = {};
