import React from 'react';

import { MetricsCards } from './MetricsCards';
import { LocationCard } from './LocationCard';
import * as S from './OverviewTab.styles';

export const OverviewTab = () => {
	function renderContent() {
		return (
			<S.Wrapper>
				<MetricsCards className="b1" />
				<LocationCard className="b2" />
			</S.Wrapper>
		);
	}
	return renderContent();
};
