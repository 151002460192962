export default {
	brandPrimary: '#394A90',
	brandSecondary: '#68CEFB',
	brandPurple: '#9E8FB2',
	brandOrange: '#F7A278',

	darkOverlay: 'rgba(0, 0, 0, 0.5)',

	normalShadow: '0px 0px 15px 0px rgba(0, 0, 0, 0.1)',
	darkShadow: '0px 0px 15px 0px rgba(0, 0, 0, 0.2)',

	neutralBackground: '#F6F7FC',
	neutralMessage: '#F0F1F6',
	neutralForm: '#E0E2ED',
	neutralText: '#6C7393',
	neutralButton: '#555A77',
	neutralPlaceholder: '#C7C7CD',

	stateSuccess: '#38AB35',
	stateWarning: '#DFCD28',
	stateDanger: '#D33C3C',
};
