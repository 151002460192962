import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { useGeolocationState } from 'beautiful-react-hooks';
import { useSelector } from 'react-redux';

import colors from '../../styles/colors';
import { Picture } from '../Picture';
import { Typography } from '../Typography';
import * as S from './Map.styles';

const DEFAULT_CENTER = { lat: 35.783279, lng: -78.640762 };

export const Map = ({ markers = [], withMarkerIndex = false, defaultCenter = DEFAULT_CENTER, defaultZoom = 15, enableGeolocationFallback = false, entityType = 'resource', role = null }) => {
	const history = useHistory();
	const user = useSelector((state) => state.user.value);
	const [mapLoaded, setMapLoaded] = useState(false);
	const [center, setCenter] = useState(user && user?.coordinates ? { lat: user?.coordinates?.latitude, lng: user?.coordinates?.longitude } : defaultCenter);
	const { isSupported, position } = useGeolocationState({
		enableHighAccuracy: true,
	});

	const mapRef = useRef(null);
	const mapsRef = useRef(null);
	const circleRef = useRef(null);

	const onGoogleApiLoaded = ({ map, maps }) => {
		mapRef.current = map;
		mapsRef.current = maps;

		circleRef.current = new maps.Circle({
			fillColor: colors.brandPrimary,
			fillOpacity: 0.15,
			strokeWeight: 0,
			map,
			center,
			radius: 600,
		});

		setMapLoaded(true);
	};

	const onMarkerClick = (markerId, type) => {
		let url = '/directory';
		if (type === 'resource') {
			switch (role) {
				case 'teacher':
					url = `/teacher/directory/resources/${markerId}`;
					break;
				case 'business':
					url = `/business/directory/resources/${markerId}`;
					break;
				case 'admin':
				case 'super':
					url = `/admin/directory/resources/${markerId}`;
					break;
				default:
					url = `/directory/${markerId}`;
					break;
			}
		} else {
			switch (role) {
				case 'teacher':
					url = `/teacher/requests/${markerId}`;
					break;
				case 'business':
					url = `/business/requests/${markerId}`;
					break;
				case 'admin':
				case 'super':
					url = `/admin/requests/${markerId}`;
					break;
				default:
					url = `/requests/${markerId}`;
					break;
			}
		}

		// Push to history
		history.push(url);
	};

	useEffect(() => {
		if (enableGeolocationFallback && isSupported) {
			if (mapLoaded && position) {
				setCenter(() => {
					const { coords } = position;
					const newValue = { lat: coords.latitude, lng: coords.longitude };
					circleRef.current.setCenter(newValue);
					mapRef.current.setCenter(newValue);
					return newValue;
				});
			} else if (mapLoaded && markers.length > 0) {
				const coords = markers[0].coordinates;
				if (coords) {
					mapRef.current.setCenter({ lat: coords.latitude, lng: coords.longitude });
					setCenter({ lat: coords.latitude, lng: coords.longitude });
				}
			}
		} else if (mapLoaded && markers.length > 0) {
			const coords = markers[0].coordinates;
			if (coords) {
				mapRef.current.setCenter({ lat: coords.latitude, lng: coords.longitude });
				setCenter({ lat: coords.latitude, lng: coords.longitude });
			}
		}
	}, [enableGeolocationFallback, isSupported, position, mapLoaded]);

	return (
		<S.MapWrapper>
			<GoogleMapReact
				bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
				yesIWantToUseGoogleMapApiInternals={true}
				defaultCenter={center}
				defaultZoom={defaultZoom}
				onGoogleApiLoaded={onGoogleApiLoaded}
				options={(maps) => ({
					fullscreenControl: false,
					zoomControlOptions: {
						position: maps.ControlPosition.LEFT_TOP,
					},
				})}
			>
				{mapLoaded &&
					markers.map(
						(marker, index) =>
							marker?.coordinates && (
								<S.MapDot key={marker?.id} lat={marker?.coordinates?.latitude} lng={marker?.coordinates?.longitude} onClick={() => !withMarkerIndex && onMarkerClick(marker?.id, entityType)} withMarkerIndex={withMarkerIndex}>
									{withMarkerIndex ? (
										<Typography className="marker-index" tag="p" variation="2" weight="extrablack">
											{index + 1}
										</Typography>
									) : (
										<div className="marker-card">
											<Picture className="marker-card__picture" aspectRatio="3/2" src={marker?.coverImageObj?.[200]} />
											<div className="marker-card__info-wrapper">
												<Typography className="marker-card__type" tag="p" variation="3">
													{marker?.category || 'Event'}
												</Typography>
												<Typography className="marker-card__title" tag="p" variation="2" weight="bold">
													{marker?.title}
												</Typography>
												<Typography className="marker-card__description" tag="p" variation="3">
													{marker?.description}
												</Typography>
											</div>
										</div>
									)}
								</S.MapDot>
							)
					)}
				{mapLoaded && (
					<S.SelfDot lat={center?.lat} lng={center?.lng}>
						<FontAwesomeIcon icon={['fal', 'users']} />
					</S.SelfDot>
				)}
			</GoogleMapReact>
		</S.MapWrapper>
	);
};

Map.propTypes = {
	role: PropTypes.string,
	defaultCenter: PropTypes.shape({
		lat: PropTypes.number,
		lng: PropTypes.number,
	}),
	withMarkerIndex: PropTypes.bool,
	enableGeolocationFallback: PropTypes.bool,
	markers: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			title: PropTypes.string,
			description: PropTypes.string,
			category: PropTypes.string,
			coverImageObj: PropTypes.shape({
				200: PropTypes.string,
			}),
			coordinates: PropTypes.shape({
				latitude: PropTypes.number,
				longitude: PropTypes.number,
			}),
		})
	),
	defaultZoom: PropTypes.number,
	entityType: PropTypes.string,
};
