import React, { forwardRef, useRef, useState, useImperativeHandle, useContext } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { Controller, useForm } from 'react-hook-form';

import { AdminAdminDetailContext } from '../../AdminAdminDetail';
import { Avatar, Button, Card, IconButton, Select, TextInput, Typography } from '../../../../components';
import { uploadProfilePicture } from '../../../../services/media';
import { updateUserProfile } from '../../../../services/users';
import { errorHandler } from '../../../../services/authService';
import { required } from '../../../../utils/form-default-errors';
import * as S from './ProfileCard.styles';
import { ROLES } from '../../../../../constants/general.constants';

const EditProfileModal = forwardRef((_props, ref) => {
	const {
		state: { user },
		dispatch,
	} = useContext(AdminAdminDetailContext);

	const [isOpen, setIsOpen] = useState(false);
	const [avatar, setAvatar] = useState(null);
	const [avatarFile, setAvatarFile] = useState(null);

	const ADMIN_ROLES = [
		{ label: 'Admin', value: ROLES.ADMIN },
		{ label: 'Super Admin', value: ROLES.SUPER },
	];

	const {
		handleSubmit,
		setValue,
		register,
		reset,
		control,
		formState: { errors, isSubmitting },
	} = useForm();

	const handleOpenModal = () => {
		setValue('firstName', user?.firstName);
		setValue('lastName', user?.lastName);
		setValue('role', user?.role);
		setAvatar(user?.profileImageObj?.[200]);
		setAvatarFile(null);
		setIsOpen(true);
	};

	const handleCloseModal = () => {
		setIsOpen(false);
		setTimeout(() => {
			reset();
		}, 200);
	};

	async function asyncCaller(data) {
		let profileImageObj;

		if (avatarFile) {
			const formData = new FormData();
			formData.append('file', avatarFile);

			const { data: uploadResult } = await uploadProfilePicture(formData);

			profileImageObj = uploadResult;
		} else {
			profileImageObj = user?.profileImageObj;
		}

		const {
			data: { result },
		} = await updateUserProfile({ userId: user.id, firstName: user.firstName, lastName: user.lastName, email: user.email, userRole: user.role, ...data, profileImageObj });

		dispatch({ type: 'SET_USER', payload: result.user });
		handleCloseModal();
	}

	const onSubmit = (e) => {
		handleSubmit(asyncCaller)(e).catch(errorHandler);
	};

	useImperativeHandle(
		ref,
		() => ({
			open: handleOpenModal,
			close: handleCloseModal,
		}),
		[handleOpenModal, handleCloseModal]
	);

	return (
		<ReactModal className="profile-card-modal" ref={ref} closeTimeoutMS={200} isOpen={isOpen} onRequestClose={handleCloseModal}>
			<S.Form onSubmit={onSubmit}>
				<IconButton className="close-button" type="button" icon={['fal', 'times']} onClick={handleCloseModal} />
				<div className="modal-content">
					<header className="modal-content__header">
						<Typography tag="h2" weight="extrablack" center>
							Edit Profile
						</Typography>
						<Typography tag="p" center>
							Please update your profile information here.
						</Typography>
					</header>

					<section className="modal-content__section">
						<Avatar className="avatar-element" avatar={avatar} setAvatar={setAvatar} file={avatarFile} setFile={setAvatarFile} />

						<TextInput
							label="First Name"
							placeholder="First Name"
							error={errors.firstName}
							{...register('firstName', {
								required: required('First Name'),
							})}
						/>
						<TextInput
							label="Last Name"
							placeholder="Last Name"
							error={errors.lastName}
							{...register('lastName', {
								required: required('Last Name'),
							})}
						/>
						{user.role === ROLES.SUPER && user.canRemoveUser && (
							<Controller
								name="role"
								control={control}
								rules={{ required: required('Role') }}
								render={({ field: { onChange, value } }) => <Select label="Role" id="role" error={errors?.role} placeholder="Role" options={ADMIN_ROLES} value={ADMIN_ROLES.find((c) => c.value === value) || ''} onChange={(val) => onChange(val.value)} />}
							/>
						)}
					</section>

					<div className="modal-footer">
						<Button>
							<Typography variation="button-medium" weight="bold">
								{isSubmitting ? 'Loading' : 'Save'}
							</Typography>
						</Button>
					</div>
				</div>
			</S.Form>
		</ReactModal>
	);
});

EditProfileModal.displayName = 'EditProfileModal';

const ProfileCard = ({ className }) => {
	const {
		state: { user },
	} = useContext(AdminAdminDetailContext);

	const modalRef = useRef(null);

	const handleCardButtonClick = () => {
		modalRef.current.open();
	};

	return (
		<Card className={className} title="Profile" transparentHeaderBorder={true} actions={user.canEditUser && [{ id: 1, label: 'Edit', size: 'small', variant: 'outline', icon: { source: ['fal', 'edit'] }, onClick: handleCardButtonClick }]}>
			<S.Wrapper>
				<div className="avatar-wrapper">
					<Avatar avatar={user?.profileImageObj?.[200]} />
				</div>
				<div className="info-wrapper">
					<div className="element-info">
						<Typography className="element-info__title" tag="h6" weight="semibold">
							First Name
						</Typography>
						<Typography className="element-info__text" tag="p">
							{user?.firstName}
						</Typography>
					</div>
					<div className="element-info">
						<Typography className="element-info__title" tag="h6" weight="semibold">
							Last Name
						</Typography>
						<Typography className="element-info__text" tag="p">
							{user?.lastName}
						</Typography>
					</div>
					<div className="element-info">
						<Typography className="element-info__title" tag="h6" weight="semibold">
							Role
						</Typography>
						<Typography className="element-info__text capitalized" tag="p">
							{user?.role === ROLES.SUPER ? 'Super Admin' : 'Admin'}
						</Typography>
					</div>
				</div>
			</S.Wrapper>
			<EditProfileModal ref={modalRef} />
		</Card>
	);
};

ProfileCard.propTypes = {
	className: PropTypes.string,
};

export default ProfileCard;
