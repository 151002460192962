import styled from 'styled-components';

export const Wrapper = styled.div`
	display: grid;
	gap: 1.25rem;
	padding: 1.25rem;

	.overflow-hidden {
		overflow: hidden;
	}

	.a1 {
		grid-area: a1;
	}

	grid-template-columns: 1fr;
	grid-template-areas:
		'a1'
		'a2'
		'a3'
		'a4';

	@media screen and (min-width: 992px) {
		grid-template-columns: repeat(2, 1fr);
		grid-template-areas:
			'a1 a1'
			'a2 a3'
			'. a4';
	}
`;
