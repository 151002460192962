import React, { createContext, useContext, useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useList } from 'react-use';
import { Badge, Picture, Typography, EmptyComponent, Spinner } from '../../../components';
import { formatToRelativeTime } from '../../../utils/time-formatter';

import { fetchRequests } from '../../../services/requests';
import { FilterSection } from './FilterSection';
import { RequestChat } from './RequestChat';
import * as S from './RequestsTab.styles';

export const VIEW_MESSAGES_OPTIONS = [
	{ value: 'grouped-by-request', label: 'Grouped by Request' },
	{ value: 'individual-messages', label: 'Individual Messages' },
];
export const TABS_OPTIONS = [
	{ value: 'all', label: 'All' },
	{ value: 'pending', label: 'Pending' },
	{ value: 'scheduled', label: 'Scheduled' },
	{ value: 'complete', label: 'Completed' },
];

export const BADGE_STATUS = {
	pending: {
		label: 'Pending',
		color: 'primary',
	},
	scheduled: {
		label: 'Scheduled',
		color: 'neutral',
	},
	draft: {
		label: 'Draft',
		color: 'neutral',
	},
	complete: {
		label: 'Completed',
		color: 'success',
	},
	incomplete: {
		label: 'Incomplete',
		color: 'danger',
	},
	archived: {
		label: 'Archived',
		color: 'neutral',
	},
	accepted: {
		label: 'Accepted',
		color: 'success',
	},
	declined: {
		label: 'Declined',
		color: 'danger',
	},
};

export const RequestsContext = createContext();

export const initialState = {
	viewMessages: VIEW_MESSAGES_OPTIONS[0],
	selectedTab: TABS_OPTIONS[0],
	searchInputValue: '',
	groupedHeaderInfo: null,
	selectedConversation: null,
};
export function reducer(state, action) {
	switch (action.type) {
		case 'SET_VIEW_MESSAGES':
			return {
				...state,
				viewMessages: action.payload,
			};
		case 'SET_SELECTED_TAB':
			return {
				...state,
				selectedTab: action.payload,
			};
		case 'SET_SEARCH_INPUT_VALUE':
			return {
				...state,
				searchInputValue: action.payload,
			};
		case 'SET_GROUPED_HEADER_INFO':
			return {
				...state,
				groupedHeaderInfo: action.payload,
				searchInputValue: '',
			};
		case 'CLEAR_GROUPED_HEADER_INFO':
			return {
				...state,
				groupedHeaderInfo: null,
				searchInputValue: '',
			};
		case 'SET_SELECTED_CONVERSATION':
			return {
				...state,
				selectedConversation: action.payload,
			};
		case 'CLEAR_SELECTED_CONVERSATION':
			return {
				...state,
				selectedConversation: null,
			};
		default:
			return state;
	}
}

const IndividualItem = ({ id, business, request, lastMessage, status }) => {
	const {
		state: { selectedConversation },
		dispatch,
	} = useContext(RequestsContext);

	return (
		<S.ListItem key={id} onClick={() => dispatch({ type: 'SET_SELECTED_CONVERSATION', payload: { id, status, business, request } })} className={selectedConversation != null && selectedConversation.id === id ? 'is-selected' : ''}>
			<div className="individual-card__avatar-wrapper">
				<Picture className="individual-card__avatar" src={business.logoObj[200]} aspectRatio="1/1" />
			</div>
			<div className="individual-card__info-wrapper">
				<Typography className="individual-card__name" tag="h5" weight="bold">
					{business.name}
				</Typography>
				<Typography className="individual-card__category" tag="p" variation="3">
					{lastMessage ? `${formatToRelativeTime(new Date(lastMessage.date.iso))} • ${request.title}` : request.title}
				</Typography>
				{lastMessage && (
					<Typography className="individual-card__message" tag="p" variation="2">
						{lastMessage.message}
					</Typography>
				)}
			</div>
			<div className="individual-card__badge-wrapper">
				<Badge small="small" type={BADGE_STATUS[status].color} variant="outlined">
					{BADGE_STATUS[status].label}
				</Badge>
			</div>
		</S.ListItem>
	);
};
IndividualItem.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	business: PropTypes.object.isRequired,
	lastMessage: PropTypes.object,
	status: PropTypes.string.isRequired,
};

const GroupedItem = ({ id, status, coverImageObj, category, title, responses }) => {
	const { dispatch } = useContext(RequestsContext);

	const handleClick = () => {
		dispatch({ type: 'SET_GROUPED_HEADER_INFO', payload: { title, responses, status } });
	};

	return (
		<S.GroupedListItem key={id} onClick={handleClick}>
			<div className="grouped-card__thumbnail-wrapper">
				<Picture pictureClassName="grouped-card__thumbnail" src={coverImageObj[200]} aspectRatio="3/2" />
			</div>
			<div className="grouped-card__info-wrapper">
				<Typography className="grouped-card__category" tag="p" variation="3">
					{category}
				</Typography>
				<Typography className="grouped-card__name" tag="h5" weight="bold">
					{title}
				</Typography>
				<div className="grouped-card__count-wrapper">
					<FontAwesomeIcon icon={['fal', 'comment']} />
					<Typography className="grouped-card__count-text" tag="p" variation="2">
						{`${responses.length} ${responses.length !== 1 ? 'Responses' : 'Response'}`}
					</Typography>
				</div>
			</div>
			<div className="grouped-card__badge-wrapper">
				<Badge small="small" type={BADGE_STATUS[status].color} variant="outlined">
					{BADGE_STATUS[status].label}
				</Badge>
			</div>
		</S.GroupedListItem>
	);
};
GroupedItem.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	status: PropTypes.string.isRequired,
	coverImageObj: PropTypes.shape(),
	category: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	responses: PropTypes.arrayOf(PropTypes.shape({ ...IndividualItem.propTypes })).isRequired,
};

// Initialize default data holder
let requestsArray = [];
let responsesArray = [];

export const RequestsTab = ({}) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const [pageStatus, setPageStatus] = useState('idle');
	const { viewMessages, selectedTab, searchInputValue, selectedConversation, groupedHeaderInfo } = state;
	const [individualList, { set: setIndList, filter: filterInd }] = useList([]);
	const [groupedList, { set: setGrpList, filter: filterGrp }] = useList([]);
	const [currentSelectedGroupedList, { set: setSlctdGrp, filter: filterSelectedGrp }] = useList([]);

	const handleListFilter = (filterMethod, resetMethod) => {
		resetMethod();
		if (searchInputValue && selectedTab.value === 'all') {
			filterMethod((item) => item.searchTag.toLowerCase().includes(searchInputValue.toLowerCase()));
		} else if (searchInputValue && selectedTab.value !== 'all') {
			filterMethod((item) => item.searchTag.toLowerCase().includes(searchInputValue.toLowerCase()) && item.status === selectedTab.value);
		} else if (searchInputValue === '' && selectedTab.value !== 'all') {
			filterMethod((item) => item.status === selectedTab.value);
		}
	};

	const handleFetchData = async () => {
		setPageStatus('loading');

		// Fetch data for value
		if (viewMessages.value === 'grouped-by-request') {
			const {
				data: { result },
			} = await fetchRequests({
				filterOptions: {
					creator: true,
				},
			});
			requestsArray = result.requests;
			setGrpList(result.requests);
		} else {
			const {
				data: { result },
			} = await fetchRequests({
				filterOptions: {
					creator: true,
				},
			});
			const conversations = [].concat(
				...result.requests.map((request) => {
					return request.responses.map((response) => ({
						id: response.id,
						searchTag: response.searchTag,
						request,
						business: response.business,
						status: response.status,
						lastMessage: response.lastMessage,
					}));
				})
			);
			responsesArray = conversations;
			setIndList(conversations);
		}
		setPageStatus('success');
	};

	const filterData = () => {
		// Update filter state
		if (!!groupedHeaderInfo) {
			handleListFilter(filterSelectedGrp, () =>
				setSlctdGrp(
					groupedHeaderInfo.responses.map((response) => {
						return {
							...response,
							request: {
								...response.request,
								title: groupedHeaderInfo.title,
								status: groupedHeaderInfo.status,
							},
						};
					})
				)
			);
		} else {
			if (viewMessages.value === 'grouped-by-request') {
				handleListFilter(filterGrp, () => setGrpList(requestsArray));
			} else {
				handleListFilter(filterInd, () => setIndList(responsesArray));
			}
		}
	};

	useEffect(async () => {
		// Fetch data
		await handleFetchData();

		// Update filter state
		filterData();
	}, [viewMessages]);

	useEffect(async () => {
		// Update filter state
		filterData();
	}, [selectedTab, searchInputValue, groupedHeaderInfo]);

	return (
		<RequestsContext.Provider value={{ state, dispatch }}>
			<S.Wrapper someChatIsSelected={selectedConversation}>
				<S.ContentWrapper>
					<FilterSection />
					<S.RelativeWrapper>
						<S.ListWrapper>
							{!!groupedHeaderInfo &&
								(currentSelectedGroupedList.length > 0 ? (
									currentSelectedGroupedList.map(({ id, business, request, lastMessage, status }) => <IndividualItem key={id} id={id} business={business} request={request} lastMessage={lastMessage} status={status} />)
								) : pageStatus === 'loading' ? (
									<Spinner />
								) : (
									<EmptyComponent title="No available conversations" message="Check back later to view available conversations." icon={['fal', 'comment']} />
								))}
							{!groupedHeaderInfo &&
								viewMessages.value === 'grouped-by-request' &&
								(groupedList.length > 0 ? (
									groupedList.map(({ id, coverImageObj, category, title, responses, status }) => <GroupedItem key={id} id={id} coverImageObj={coverImageObj} category={category} title={title} responses={responses} status={status} />)
								) : pageStatus === 'loading' ? (
									<Spinner />
								) : (
									<EmptyComponent title="No available requests" message="Check back later to view available messages for requests." icon={['fal', 'comment']} />
								))}
							{viewMessages.value === 'individual-messages' &&
								(individualList.length > 0 ? (
									individualList.map(({ id, business, request, lastMessage, status }) => <IndividualItem key={id} id={id} business={business} request={request} lastMessage={lastMessage} status={status} />)
								) : pageStatus === 'loading' ? (
									<Spinner />
								) : (
									<EmptyComponent title="No available conversations" message="Check back later to view available conversations." icon={['fal', 'comment']} />
								))}
						</S.ListWrapper>
					</S.RelativeWrapper>
				</S.ContentWrapper>
				{selectedConversation && (
					<S.ChatWrapper>
						<div className="relative-wrapper">
							<RequestChat conversation={selectedConversation} />
						</div>
					</S.ChatWrapper>
				)}
			</S.Wrapper>
		</RequestsContext.Provider>
	);
};
