import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { PageHeader, Tab, AdminNavigation } from '../../components';

import { PendingTab } from './PendingTab';
import { ScheduledTab } from './ScheduledTab';
import { CompletedTab } from './CompletedTab';
import { IncompleteTab } from './IncompleteTab';
import { ArchivedTab } from './ArchivedTab';
import * as S from './AdminBookings.styles';
import { META_TITLE } from '../../../constants/general.constants';

const AdminBookings = () => {
	const history = useHistory();
	const [tabs, setTabs] = useState([
		{
			id: 1,
			label: 'Pending',
			component: <PendingTab />,
		},
		{
			id: 2,
			label: 'Scheduled',
			component: <ScheduledTab />,
		},
		{
			id: 3,
			label: 'Completed',
			component: 'CompletedTab',
			component: <CompletedTab />,
		},
		{
			id: 4,
			label: 'Incomplete',
			component: 'IncompleteTab',
			component: <IncompleteTab />,
		},
		{
			id: 5,
			label: 'Archived',
			component: <ArchivedTab />,
		},
	]);

	useEffect(() => {
		// Set document title
		document.title = `Bookings | Dashboard | ${META_TITLE}`;
	}, []);

	return (
		<AdminNavigation>
			<PageHeader title="Bookings" />
			<S.Wrapper>
				<Tab tabs={tabs} setTabs={setTabs} />
			</S.Wrapper>
		</AdminNavigation>
	);
};

export default AdminBookings;
