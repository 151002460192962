import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as S from './Toggle.styles';

export const Toggle = forwardRef(({ containerClassName, ...rest }, ref) => {
	return (
		<S.Wrapper className={containerClassName}>
			<S.ToggleWrapper>
				<S.Toggle ref={ref} {...rest} />
				<S.ToggleDot />
			</S.ToggleWrapper>
		</S.Wrapper>
	);
});

Toggle.displayName = 'Toggle';
Toggle.propTypes = {
	containerClassName: PropTypes.string,
};
