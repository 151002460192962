import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { Typography } from '../Typography';
import * as S from './TextArea.styles';

export const TextArea = forwardRef(({ id, label, error, containerClassName, ...rest }, ref) => {
	return (
		<S.Wrapper error={error} className={containerClassName}>
			{label && (
				<Typography tag="label" weight="semibold" htmlFor={id ? `${id}` : null} id={id ? `${id}-label` : null}>
					{label}
				</Typography>
			)}
			<S.TextArea ref={ref} id={id} error={error} {...rest} />
			{error && error.message && (
				<S.FloatingWrapper title={error.message}>
					<Typography tag="p" variation="2">
						{error.message}
					</Typography>
				</S.FloatingWrapper>
			)}
		</S.Wrapper>
	);
});

TextArea.displayName = 'TextArea';
TextArea.propTypes = {
	containerClassName: PropTypes.string,
	id: PropTypes.string,
	placeholder: PropTypes.string,
	label: PropTypes.string,
	error: PropTypes.shape(),
};
