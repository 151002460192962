import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Tab } from '../../../components';
import { CurrentTab } from './CurrentTab';
import { ArchivedTab } from './ArchivedTab';
import * as S from './GroupsTab.styles';

export const GroupsTab = ({ member }) => {
	const [tabs, setTabs] = useState([
		{
			id: 1,
			label: 'Current',
			component: <CurrentTab member={member} />,
		},
		{
			id: 2,
			label: 'Archived',
			component: <ArchivedTab member={member} />,
		},
	]);

	return (
		<S.Wrapper>
			<Tab tabs={tabs} setTabs={setTabs} transparent variation="pill" />
		</S.Wrapper>
	);
};

GroupsTab.propTypes = {
	member: PropTypes.object,
};
