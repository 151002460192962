import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { LOCATION_TYPES } from '../../../../constants/general.constants';
import { fetchBookings } from '../../../services/bookings';
import { Card, Calendar, Picture, Typography, Select, Spinner, ErrorComponent } from '../../../components';
import * as S from './BookedCard.styles';

const FilterComponent = ({ onChangeCallback }) => {
	const [selectedOption, setSelectedOption] = useState({ value: 'all', label: 'All Resources' });

	const handleChange = (selectedOption) => {
		setSelectedOption(selectedOption);
		onChangeCallback(selectedOption.value);
	};

	return (
		<S.FilterComponent>
			<Typography tag="p" variation="2" weight="bold" className="filter-label">
				Show:
			</Typography>
			<Select
				size="small"
				containerClassName="filter-select"
				defaultValue={selectedOption}
				onChange={handleChange}
				options={[
					{ value: 'all', label: 'All Resources' },
					{ value: 'sent', label: 'Sent Bookings' },
					{ value: 'received', label: 'Received Bookings' },
				]}
			/>
		</S.FilterComponent>
	);
};
FilterComponent.propTypes = {
	onChangeCallback: PropTypes.func.isRequired,
};

export const BookedCard = ({ className }) => {
	const [pageStatus, setPageStatus] = useState('idle');
	const [bookings, setBookings] = useState([]);

	let COLOR_INDEX = -1;
	const AVAILABLE_COLORS = ['yellow-tile', 'green-tile', 'blue-tile', 'light-blue-tile', 'purple-tile', 'red-tile', 'orange-tile'];

	const onChangeCallback = async (value) => {
		setPageStatus('loading');
		try {
			const filterOptions = {
				all: { inbound: true, outbound: true },
				sent: { inbound: false, outbound: true },
				received: { inbound: true, outbound: false },
			};

			const {
				data: { result },
			} = await fetchBookings({ page: 0, filterOptions: { status: 'scheduled', ...filterOptions[value] } });
			const bookingsWithColor = result.bookings.map((booking) => {
				if (COLOR_INDEX === AVAILABLE_COLORS.length - 1) COLOR_INDEX = 0;
				else COLOR_INDEX += 1;
				return {
					...booking,
					color: AVAILABLE_COLORS[COLOR_INDEX],
				};
			});
			setBookings(bookingsWithColor);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	};

	async function initializeData() {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchBookings({ page: 0, filterOptions: { status: 'scheduled', inbound: true, outbound: true } });
			const bookingsWithColor = result.bookings.map((booking) => {
				if (COLOR_INDEX === AVAILABLE_COLORS.length - 1) COLOR_INDEX = 0;
				else COLOR_INDEX += 1;
				return {
					...booking,
					color: AVAILABLE_COLORS[COLOR_INDEX],
				};
			});
			setBookings(bookingsWithColor);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}

	useEffect(() => {
		// Load initial data
		if (pageStatus === 'idle') {
			initializeData();
		}
	}, []);

	const renderContent = () => {
		switch (pageStatus) {
			case 'loading':
				return <Spinner />;
			case 'error':
				return <ErrorComponent />;
			case 'success':
				const events = bookings.map((booking) => {
					return {
						...booking,
						allDates: booking.availabilityDates.map((date) => moment(date, 'MM-DD-YYYY', true).toDate().toDateString()),
					};
				});
				return (
					<>
						{bookings.length > 0 ? (
							<>
								<Calendar events={events} />
								<S.BookedCardsList>
									{bookings.map((booking) => (
										<S.BookedCard key={booking.id} to={{ pathname: `/teacher/bookings/${booking.id}`, state: { backUrl: window.location.pathname, backTitle: 'Back to Dashboard' } }}>
											<div className={`booked-card__colored-border ${booking.color}`} />
											<Picture className="booked-card__thumbnail" src={booking.resource.coverImageObj['200']} aspectRatio="3/2" />
											<div className="booked-card__info-wrapper">
												<Typography className="booked-card__info-wrapper__type" tag="p" variation="3">
													{booking.resource.category || 'Event'}
												</Typography>
												<Typography className="booked-card__info-wrapper__title" tag="h5" weight="bold">
													{booking.resource.title}
												</Typography>
												<div className="booked-card__info-wrapper__details-wrapper">
													<div className="booked-card__info-wrapper__details-wrapper__detail-item">
														<FontAwesomeIcon icon={['fal', 'building']} />
														<Typography tag="p" variation="2">
															{booking.business.name}
														</Typography>
													</div>
													<div className="booked-card__info-wrapper__details-wrapper__detail-item">
														<FontAwesomeIcon icon={['fal', 'calendar']} />
														<Typography tag="p" variation="2">
															{booking.scheduleDateRange || booking.availabilityDateRange}
														</Typography>
													</div>
													<div className="booked-card__info-wrapper__details-wrapper__detail-item">
														<FontAwesomeIcon icon={['fal', 'users']} />
														<Typography tag="p" variation="2">
															{booking.group.name || 'No Group'}
														</Typography>
													</div>
													<div className="booked-card__info-wrapper__details-wrapper__detail-item">
														<FontAwesomeIcon icon={['fal', 'map-marker']} />
														<Typography tag="p" variation="2">
															{booking.locationTypes.map((type) => LOCATION_TYPES.find((item) => item.id === type).name).join(', ')}
														</Typography>
													</div>
												</div>
											</div>
										</S.BookedCard>
									))}
								</S.BookedCardsList>
							</>
						) : (
							<S.EmptyBusinessCard paddingTop={4} paddingBottom={6}>
								<div className="icon-box">
									<FontAwesomeIcon icon={['fal', 'calendar']} size="4x" />
								</div>
								<Typography tag="h4" weight="bold" center>
									You Have No Booked Events
								</Typography>
								<Typography tag="p" center>
									Once your account is approved you will be able to see your upcoming bookings here.
								</Typography>
							</S.EmptyBusinessCard>
						)}
					</>
				);
			default:
				return null;
		}
	};

	return (
		<Card className={className} title="Booked" filterComponent={<FilterComponent onChangeCallback={onChangeCallback} />}>
			<S.Wrapper>{renderContent()}</S.Wrapper>
		</Card>
	);
};

BookedCard.propTypes = {
	className: PropTypes.string,
};
