import React, { forwardRef, useState, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';

import { CURRICULUM_STANDARDS, EMPLOYABILITY_SKILLS } from '../../../../constants/general.constants';
import { Button, Typography, IconButton, Checkbox, Select, MessageDialog } from '../../../components';
import { errorHandler } from '../../../services/authService';
import { reviewInformationResource } from '../../../services/resources';
import * as S from './StartReviewModal.styles';
import { required } from '../../../utils/form-default-errors';

export const StartReviewModal = forwardRef(({ setInformationDetails, role }, ref) => {
	const [isOpen, setIsOpen] = useState(false);
	const [resourceResult, setResourceResult] = useState(null);
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const history = useHistory();

	const {
		register,
		control,
		reset,
		setValue,
		handleSubmit,
		formState: { isSubmitting, errors },
	} = useForm();

	const handleOpen = (resourceId) => {
		setValue('resourceId', resourceId);
		setIsOpen(true);
	};

	const handleClose = ({ isComplete }) => {
		setIsOpen(false);
		setTimeout(() => {
			reset();
			if (isComplete) {
				if (setInformationDetails) {
					setInformationDetails(resourceResult);
				} else {
					history.push(`/${role || 'teacher'}/information/${resourceResult.id}`);
				}
			}
		}, 200);
	};

	async function asyncCaller(data) {
		const {
			data: { result },
		} = await reviewInformationResource({ ...data, action: 'complete' });
		setConfirmationOpen(true);
		setResourceResult(result.resource);
	}

	const handleCloseConfirmation = () => {
		setConfirmationOpen(false);
		handleClose({ isComplete: true });
	};

	const onSubmit = (e) => handleSubmit(asyncCaller)(e).catch(errorHandler);

	useImperativeHandle(ref, () => ({
		open: handleOpen,
		close: handleClose,
	}));

	return (
		<ReactModal className="start-review-modal" ref={ref} closeTimeoutMS={200} isOpen={isOpen} onRequestClose={handleClose}>
			<S.Form onSubmit={onSubmit}>
				<IconButton className="close-button" type="button" icon={['fal', 'times']} onClick={handleClose} />
				<div className="modal-content-wrapper">
					<div className="modal-content">
						<Typography className="bigger-title" tag="h1" weight="extrablack" center>
							Review Information
						</Typography>
						<Typography tag="p" className="description" center>
							Please review the information resource with its relevant learning connections.
						</Typography>

						<div className="fields-wrapper">
							<Typography tag="h3" weight="bold">
								Learning Connections
							</Typography>
							<div className="checkbox-wrapper">
								<Typography className={`field-label${!!errors.employabilitySkills ? ' with-error' : ''}`} tag="p" variation="2" weight="semibold">
									Employability Skills
								</Typography>

								{EMPLOYABILITY_SKILLS.map(({ value, label }) => (
									<div key={value} className="checkbox-field">
										<Checkbox id={value} value={value} {...register('employabilitySkills', { required: required('Employability Skills') })} />
										<label htmlFor={value}>{label}</label>
									</div>
								))}
								{!!errors?.employabilitySkills && (
									<div className="error-wrapper mt1">
										<Typography tag="p" variation="2">
											{errors?.employabilitySkills?.message}
										</Typography>
									</div>
								)}
							</div>

							<Controller
								name="curriculumStandards"
								control={control}
								rules={{ required: required('Curriculum Standards') }}
								render={({ field: { onChange, value } }) => (
									<Select
										isMulti
										containerClassName="custom-select"
										label="Curriculum Standards"
										id="curriculumStandards"
										placeholder="Curriculum Standards"
										error={errors?.curriculumStandards}
										options={CURRICULUM_STANDARDS}
										value={value?.map((val) => CURRICULUM_STANDARDS.find((c) => c.value === val)) || ''}
										onChange={(val) => onChange(val?.map((val) => val.value))}
									/>
								)}
							/>
						</div>
					</div>
				</div>
				<S.ButtonsWrapper>
					<Button type="submit">
						<Typography weight="bold" variation="button-medium">
							{isSubmitting ? 'Loading...' : 'Submit Review'}
						</Typography>
					</Button>
				</S.ButtonsWrapper>
			</S.Form>
			<MessageDialog
				isOpen={confirmationOpen}
				onRequestClose={handleCloseConfirmation}
				title="Review Sent"
				subtitle="Your review was sent successfully."
				icon={['fal', 'check']}
				actions={[{ id: 'close-confirmation', label: 'Close', variant: 'solid', variation: 'default', onClick: handleCloseConfirmation }]}
			/>
		</ReactModal>
	);
});

StartReviewModal.displayName = 'StartReviewModal';

StartReviewModal.propTypes = {
	setInformationDetails: PropTypes.func,
	role: PropTypes.string,
};
