import styled from 'styled-components';

import colors from '../../../styles/colors';

export const Wrapper = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	margin: -1rem;
`;

export const ChatHeader = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	background-color: white;
	margin: 0;
	padding: 1rem;
	border-top: 1px solid ${colors.neutralForm};
	border-bottom: 1px solid ${colors.neutralForm};
`;

export const InfoWrapper = styled.div`
	display: flex;
	align-items: center;

	.avatar-image {
		width: 3.375rem;
		height: 3.375rem;
		border-radius: 9999px;
		margin-right: 0.5rem;
	}

	.info-block {
		flex: 1;
	}

	.info-button {
		color: ${colors.brandPrimary};
	}

	p {
		color: ${colors.neutralText};
	}
`;

export const ButtonWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 1rem;
	margin-top: 1rem;
`;
