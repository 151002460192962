import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { AdminNavigation, PageHeader, Tab } from '../../components';
import { RequestsTab } from './RequestsTab';
import { ResourcesTab } from './ResourcesTab';
import { FilesTab } from './FilesTab';
import * as S from './AdminTemplates.styles';
import { META_TITLE } from '../../../constants/general.constants';

const AdminTemplates = () => {
	const history = useHistory();
	const [tabs, setTabs] = useState([
		{
			id: 1,
			label: 'Requests',
			component: <RequestsTab />,
		},
		{
			id: 2,
			label: 'Resources',
			component: <ResourcesTab />,
		},
		{
			id: 3,
			label: 'Files',
			component: <FilesTab />,
		},
	]);

	useEffect(() => {
		// Set document title
		document.title = `Templates | ${META_TITLE}`;
	}, []);

	return (
		<AdminNavigation>
			<PageHeader title="Templates" action={[{ id: 1, onClick: () => history.push('/admin/create-template'), label: 'Create Template', icon: ['fal', 'plus'] }]} />
			<S.Wrapper>
				<Tab tabs={tabs} setTabs={setTabs} />
			</S.Wrapper>
		</AdminNavigation>
	);
};

export default AdminTemplates;
