import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button, Typography } from '../../../components';
import * as S from './Header.styles';

export const Header = ({ title, email }) => {
	const history = useHistory();

	const goBack = () => {
		history.push('/teacher/school');
	};

	return (
		<S.Wrapper>
			<Button className="header__back-button" variant="text" size="small" onClick={goBack}>
				<FontAwesomeIcon icon={['fal', 'arrow-left']} />
				<Typography weight="bold" variation="button-small">
					Back to School
				</Typography>
			</Button>
			<div className="header__title-wrapper">
				<Typography tag="h1" weight="extrablack">
					{title}
				</Typography>
			</div>
			<div className="header__title-wrapper detail-top">
				<Typography tag="h6" weight="semibold">
					Email
				</Typography>
			</div>
			<div className="header__title-wrapper detail-bottom">
				<Typography tag="p" weight="regular">
					{email}
				</Typography>
			</div>
		</S.Wrapper>
	);
};

Header.propTypes = {
	title: PropTypes.string,
	email: PropTypes.string,
};
