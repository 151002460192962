import styled from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1.5rem 1rem;
`;

export const Header = styled.header`
	width: 100%;
	display: flex;
	align-items: center;

	margin-bottom: 1.5rem;

	.centered {
		display: flex;
		flex: 1;
		justify-content: center;
	}

	.flex-1 {
		flex: 1;

		.button-label {
			display: none;

			@media screen and (min-width: 768px) {
				display: block;
			}
		}
	}
`;

export const Picture = styled.picture`
	display: flex;
	height: 3.125rem;

	img {
		height: 100%;
	}
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 55rem;
	background-color: white;
	border: 1px solid ${colors.neutralForm};
	border-radius: 0.375rem;
	padding: 2rem 1rem 1.5rem;

	.description {
		margin-top: 1rem;
	}

	@media screen and (min-width: 768px) {
		padding: 0 1rem 1.5rem;
	}
`;

export const ContainerHeader = styled.div`
	margin: 0 -1rem;
	margin-bottom: 2rem;
`;

export const Form = styled.form``;

export const FieldsWrapper = styled.div`
	display: grid;
	gap: 1rem;
	margin-top: 0.5rem;

	.location-grid-row {
		display: grid;
		gap: 1rem;

		@media screen and (min-width: 410px) {
			grid-template-columns: 2fr 1fr 1fr;
		}
	}
`;

export const ContainerFooter = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 4rem -1rem -1.5rem;
	padding: 1rem;
	border-top: 1px solid ${colors.neutralForm};

	.flex-1 {
		flex: 1;
	}

	@media screen and (min-width: 768px) {
		padding: 1.5rem 2.75rem 1.5rem;
	}
`;

export const LocationItem = styled.div`
	margin: 2rem auto 1rem;
	width: 100%;
	max-width: 25.625rem;
	position: relative;

	.location-remove {
		position: absolute;
		right: 0;
		top: 0;
		padding: 0;
		color: ${colors.stateDanger};
	}
`;

export const AddWrapper = styled.div`
	display: flex;
	align-items: center;
	margin: 1rem auto 0;
	width: 100%;
	max-width: 25.625rem;

	button {
		margin-left: -1.5625rem;
	}
`;
