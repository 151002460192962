import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import logo from '../../assets/ncbce-logo-alt.png';
import { Typography, Button, Link } from '../../components';
import * as S from './ErrorPage.styles';
import { META_TITLE } from '../../../constants/general.constants';

const ErrorPage = () => {
	const history = useHistory();

	useEffect(() => {
		// Set document title
		document.title = `Page Not Found | ${META_TITLE}`;
	}, []);

	return (
		<S.Wrapper>
			<S.Header>
				<div className="flex-1" />
				<div className="centered">
					<Link to="/directory">
						<S.Picture>
							<img src={logo} alt="NCBCE logo" />
						</S.Picture>
					</Link>
				</div>
				<div className="flex-1" />
			</S.Header>
			<S.Container>
				<FontAwesomeIcon className="envelope-icon" icon={['fal', 'question-circle']} size="3x" />
				<Typography tag="h1" weight="extrablack" center>
					Uh oh, we can&apos;t seem to find the page you&apos;re looking for.
				</Typography>
				<Typography tag="p" className="description" center>
					Try going back to the previous page or contact us for more information.
				</Typography>
				<Button type="submit" className="submit-button" onClick={() => history.push('/login')}>
					<Typography variation="button-medium" weight="bold">
						Go to Log In
					</Typography>
				</Button>
			</S.Container>
		</S.Wrapper>
	);
};

export default ErrorPage;
