import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { PageHeader, Tab, BusinessNavigation } from '../../components';

import { CurrentTab } from './CurrentTab';
import { ArchivedTab } from './ArchivedTab';
import * as S from './BusinessInformation.styles';
import { META_TITLE } from '../../../constants/general.constants';

const BusinessInformation = () => {
	const history = useHistory();
	useEffect(() => {
		// Set document title
		document.title = `Information | Dashboard | ${META_TITLE}`;
	}, []);

	const [tabs, setTabs] = useState([
		{
			id: 1,
			label: 'Current',
			component: <CurrentTab />,
		},
		{
			id: 2,
			label: 'Archived',
			component: <ArchivedTab />,
		},
	]);

	return (
		<BusinessNavigation>
			<PageHeader title="Information" action={[{ id: 1, onClick: () => history.push('/business/create-request'), label: 'Create Request', icon: ['fal', 'plus'] }]} />
			<S.Wrapper>
				<Tab tabs={tabs} setTabs={setTabs} />
			</S.Wrapper>
		</BusinessNavigation>
	);
};

export default BusinessInformation;
