import React, { useState, useImperativeHandle, forwardRef, useContext } from 'react';
import ReactModal from 'react-modal';
import { FormProvider, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { IconButton, Stepper, Typography, Button, MessageDialog } from '../../../components';
import { AppointmentDetailsContext } from '../RequestsAppointmentDetails';
import { errorHandler } from '../../../services/authService';
import { uploadResources } from '../../../services/media';
import { fulfillRequest } from '../../../services/requests';
import { StepOne } from './StepOne';
import { StepTwo } from './StepTwo';
import { StepThree } from './StepThree';
import { StepFour } from './StepFour';
import { StepFive } from './StepFive';
import * as S from './FulfillRequestModal.styles';

export const FulfillRequestModal = forwardRef(({ role }, ref) => {
	const {
		state: { request },
		dispatch,
	} = useContext(AppointmentDetailsContext);
	const [isOpen, setIsOpen] = useState(false);
	const [requestResult, setRequestResult] = useState(null);

	const [confirmationOpen, setConfirmationOpen] = useState(false);

	const stepOptions = [
		{ id: 1, label: 'Files' },
		{ id: 2, label: 'Links' },
		{ id: 3, label: 'Businesses' },
		{ id: 4, label: 'Learning' },
		{ id: 5, label: 'Review' },
	];

	const [currentStep, setCurrentStep] = useState(0);

	const methods = useForm();

	const handleOpen = (requestId) => {
		methods.setValue('requestId', requestId);
		methods.setValue('fileObjs', []);
		methods.setValue('linkObjs', []);
		methods.setValue('employabilitySkills', []);
		methods.setValue('curriculumStandards', []);
		methods.setValue('reviews', []);
		setIsOpen(true);
	};

	const handleClose = () => {
		setIsOpen(false);
		setTimeout(() => {
			methods.reset();
			setCurrentStep(0);
		}, 200);
	};

	async function uploadFile(file) {
		if (file?.url) {
			return file;
		}
		const formData = new FormData();
		formData.append('file', file);

		const {
			data: { document },
		} = await uploadResources(formData);

		return { title: file?.title, description: file?.description, url: document };
	}

	async function asyncCaller(data) {
		const { fileObjs, ...rest } = data;

		let newFileObjs = [];

		if (fileObjs?.length > 0) {
			const fileObjsData = await Promise.all(fileObjs.map(uploadFile));
			newFileObjs = fileObjsData;
		}

		const {
			data: { result },
		} = await fulfillRequest({ ...rest, fileObjs: newFileObjs });
		setRequestResult(result.request);

		setConfirmationOpen(true);
	}

	const handleCloseConfirmation = () => {
		setConfirmationOpen(false);
		handleClose();
		dispatch({ type: 'setRequest', payload: requestResult });
	};

	const handleBackButton = () => {
		setCurrentStep((prev) => {
			const newValue = prev - 1;
			return newValue === 2 && request?.reviews?.length === 0 ? newValue - 1 : newValue;
		});
	};

	const onSubmit = (e) => {
		if (stepOptions.length === currentStep + 1) {
			methods.handleSubmit(asyncCaller)(e).catch(errorHandler);
		} else {
			methods
				.handleSubmit(() => {
					setCurrentStep((prev) => {
						const newValue = prev + 1;
						return newValue === 2 && request?.reviews?.length === 0 ? newValue + 1 : newValue;
					});
				})(e)
				.catch(errorHandler);
		}
	};

	useImperativeHandle(ref, () => ({
		open: handleOpen,
		close: handleClose,
	}));

	return (
		<ReactModal className="schedule-request-modal" ref={ref} closeTimeoutMS={200} isOpen={isOpen} onRequestClose={handleClose}>
			<FormProvider {...methods}>
				<S.Form onSubmit={onSubmit}>
					<IconButton className="close-button" type="button" icon={['fal', 'times']} onClick={handleClose} />
					<Stepper activeStep={currentStep} steps={stepOptions} maxWidth={25} />
					<div className="modal-content-wrapper">
						{currentStep === 0 && <StepOne role={role} />}
						{currentStep === 1 && <StepTwo role={role} />}
						{currentStep === 2 && <StepThree role={role} />}
						{currentStep === 3 && <StepFour role={role} />}
						{currentStep === 4 && <StepFive role={role} />}
					</div>

					<S.ButtonsWrapper>
						{currentStep > 0 && (
							<>
								<Button variant="outline" variation="secondary" type="button" onClick={handleBackButton}>
									<Typography variation="button-medium" weight="bold">
										Back
									</Typography>
								</Button>
								<div className="flex-1" />
							</>
						)}
						<Button type="submit">
							<Typography weight="bold" variation="button-medium">
								{methods?.formState?.isSubmitting ? 'Loading' : stepOptions.length === currentStep + 1 ? 'Complete' : 'Next'}
							</Typography>
						</Button>
					</S.ButtonsWrapper>
				</S.Form>
			</FormProvider>
			<MessageDialog
				isOpen={confirmationOpen}
				onRequestClose={handleCloseConfirmation}
				title="Request Fullfilled"
				subtitle="Your Request has been fulfilled!"
				icon={['fal', 'check']}
				actions={[{ id: 'close-confirmation', label: 'Close', variant: 'solid', variation: 'default', onClick: handleCloseConfirmation }]}
			/>
		</ReactModal>
	);
});

FulfillRequestModal.propTypes = {
	role: PropTypes.string,
};

FulfillRequestModal.displayName = 'FulfillRequestModal';
