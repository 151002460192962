import styled from 'styled-components';

import colors from '../../../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding-bottom: 0.25rem;

	& > * + * {
		margin-top: 1rem;
	}

	.location-info {
		flex: 1;

		&__element {
			display: flex;
			border-bottom: 1px solid ${colors.neutralForm};
			margin-bottom: 0.75rem;
			padding-bottom: 0.75rem;

			&:last-child {
				border: none;
				padding-bottom: 0.25rem;
				margin: 0;
			}
		}

		&__detail-wrapper {
			margin-top: 1rem;

			& > * + * {
				margin-top: 0.5rem;
			}

			&__title {
				color: ${colors.neutralText};
			}

			&__address {
				color: ${colors.neutralText};
			}

			&__count {
				display: inline-block;
				width: 1.25rem;
			}

			&__more {
				color: ${colors.neutralText};
			}
		}

		&__icon-wrapper {
			display: flex;
			justify-content: center;
			width: 1.5rem;
			margin-top: 0.125rem;
			margin-right: 0.75rem;
		}

		&__info-wrapper {
		}

		&__heading-wrapper {
			&__title {
				margin-bottom: 0.125rem;
			}

			&__subtitle {
			}
		}
	}

	.location-map {
		flex: 1;

		&__wrapper {
			position: relative;
			width: 100%;
			height: 0;
			border-radius: 0.375rem;
			overflow: hidden;
			padding-top: 66.66%;
		}
	}

	@media screen and (min-width: 992px) {
		flex-direction: row;

		& > * + * {
			margin-top: 0;
			margin-left: 1rem;
		}
	}
`;
