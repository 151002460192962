import styled from 'styled-components';
import { NavLink as ReactNavLink } from 'react-router-dom';

import colors from '../../../../styles/colors';

export const LinkWrapper = styled.li`
	list-style: none;
`;

export const NavLink = styled(ReactNavLink)`
	display: flex;
	align-items: center;
	color: white;
	background-color: ${colors.brandPrimary};
	text-decoration: none;
	padding: 0.5rem 1rem;
	position: relative;

	.link-title {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	& > svg {
		margin-right: 0.75rem;
		width: 1.25rem !important;
		height: 1.25rem !important;
	}

	&:not(.active):hover {
		background-color: rgba(255, 255, 255, 0.2);
	}

	&.active {
		background-color: rgba(255, 255, 255, 0.2);

		&:before {
			content: '';
			position: absolute;
			width: 0.25rem;
			top: 0;
			left: 1px;
			bottom: 0;
			background-color: white;
			border-radius: 0 0.25rem 0.25rem 0;
		}

		.link-title {
			font-weight: 800;
		}
	}
`;
