import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Typography } from '../Typography';
import * as S from './ErrorComponent.styles';

export function ErrorComponent() {
	return (
		<S.Wrapper>
			<FontAwesomeIcon className="envelope-icon" icon={['fal', 'question-circle']} size="3x" />
			<Typography tag="h1" weight="extrablack" center>
				Uh oh, something went wrong.
			</Typography>
			<Typography tag="p" className="description" center>
				Try going back to the previous page or contact us for more information.
			</Typography>
		</S.Wrapper>
	);
}
