import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Card, RatingStars, Typography } from '../../../../components';
import * as S from './FulfilledCard.styles';

export const FulfilledCard = ({ request, role }) => {
	return (
		<Card title="Fullfilled" transparentHeaderBorder={true}>
			<S.Wrapper>
				{request?.fulfilledFiles?.length > 0 && (
					<div className="review__step-item review__files">
						<Typography tag="h3" weight="bold">
							Files
						</Typography>

						{request?.fulfilledFiles?.map((file) => (
							<div className="review__file-item" key={file.id}>
								<div className="review__flex-row">
									<Typography tag="h5" weight="bold">
										{file?.title}
									</Typography>

									<a href={file?.url} download>
										<FontAwesomeIcon icon={['fal', 'arrow-to-bottom']} size="xs" />
										<Typography variation="button-small" weight="bold">
											Download
										</Typography>
									</a>
								</div>

								<div className="review__info-item">
									<Typography tag="h6" weight="semibold">
										File
									</Typography>
									<Typography tag="p">{file?.fileName}</Typography>
								</div>

								<div className="review__info-item">
									<Typography tag="h6" weight="semibold">
										Description
									</Typography>
									<Typography tag="p">{file?.description}</Typography>
								</div>
							</div>
						))}
					</div>
				)}

				{request?.fulfilledLinks?.length > 0 && (
					<div className="review__step-item review__links">
						<Typography tag="h3" weight="bold">
							Links
						</Typography>

						{request?.fulfilledLinks?.map((link) => (
							<div className="review__file-item" key={link.id}>
								<div className="review__flex-row">
									<Typography tag="h5" weight="bold">
										{link?.title}
									</Typography>
									<a href={link?.url} target="_blank" rel="noreferrer">
										<FontAwesomeIcon icon={['fal', 'external-link']} size="xs" />
										<Typography variation="button-small" weight="bold">
											Visit Link
										</Typography>
									</a>
								</div>

								<div className="review__info-item">
									<Typography tag="h6" weight="semibold">
										Link
									</Typography>
									<Typography tag="p">{link?.url}</Typography>
								</div>

								<div className="review__info-item">
									<Typography tag="h6" weight="semibold">
										Description
									</Typography>
									<Typography tag="p">{link?.description}</Typography>
								</div>
							</div>
						))}
					</div>
				)}

				{request?.reviews?.length > 0 && (
					<div className="review__step-item review__businesses">
						<Typography tag="h4" weight="bold">
							Businesses
						</Typography>
						{request?.reviews?.map(({ id, business }, index) => (
							<div className="business-review-item smaller" key={id}>
								<div className="business-review-item__image-wrapper">
									<img src={business?.logoObj?.[200]} alt={business?.name} />
								</div>
								<div className="business-review-item__fields-wrapper">
									<Typography tag="h4" weight="bold">
										{business?.name}
									</Typography>
									<RatingStars label="Rating" value={request?.reviews[index].rating} />
									<div className="review__info-item">
										<Typography tag="h6" weight="semibold">
											Message
										</Typography>
										<Typography tag="p">{request?.reviews[index].comments} </Typography>
									</div>
								</div>
							</div>
						))}
					</div>
				)}

				<div className="review__step-item review__learning">
					<Typography tag="h4" weight="bold">
						Learning Connections
					</Typography>

					<div className="review__step-item">
						<div className="review__step-half">
							<div className="review__info-item review__info-item--with-badge">
								<Typography tag="h6" weight="semibold">
									Employability Skills
								</Typography>
								<div className="badge-wrapper">
									{request?.employabilitySkills?.map((value) => (
										<div className="small-badge" key={value}>
											{value}
										</div>
									))}
								</div>
							</div>

							<div className="review__info-item review__info-item--with-badge">
								<Typography tag="h6" weight="semibold">
									Curriculum Standards
								</Typography>
								<div className="badge-wrapper">
									{request?.curriculumStandards?.map((value) => (
										<div className="small-badge" key={value}>
											{value}
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</S.Wrapper>
		</Card>
	);
};

FulfilledCard.propTypes = {
	request: PropTypes.shape(),
	role: PropTypes.string,
};
