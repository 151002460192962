import React from 'react';
import PropTypes from 'prop-types';

import { Navigation } from '../../components';

const links = [
	{ id: 1, to: '/business/dashboard', label: 'Dashboard', icon: ['fal', 'tachometer'] },
	{ id: 2, label: 'Browse' },
	{ id: 3, to: '/business/directory/resources', label: 'Resource Directory', icon: ['fal', 'books'] },
	{ id: 4, to: '/business/directory/requests', label: 'Request Directory', icon: ['fal', 'file-search'] },
	{ id: 5, to: '/business/saved', label: 'Saved', icon: ['fal', 'bookmark'] },
	{ id: 6, label: 'Activity' },
	{ id: 7, to: '/business/messages', label: 'Messages', icon: ['fal', 'comments'] },
	{ id: 8, to: '/business/bookings', label: 'Bookings', icon: ['fal', 'envelope'] },
	{ id: 9, to: '/business/requests', label: 'Requests', icon: ['fal', 'file-plus'] },
	{ id: 10, to: '/business/information', label: 'Information', icon: ['fal', 'info-circle'] },
	{ id: 11, label: 'Company' },
	{ id: 12, to: '/business/company', label: 'Profile', icon: ['fal', 'building'] },
	{ id: 13, to: '/business/resources', label: 'Resources', icon: ['fal', 'book'] },
	{ id: 14, to: '/business/reports', label: 'Reports', icon: ['fal', 'chart-bar'] },
];

export const BusinessNavigation = ({ children }) => {
	return (
		<Navigation links={links} logoLink="/business/dashboard">
			{children}
		</Navigation>
	);
};
BusinessNavigation.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
