import styled from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: grid;
	gap: 1rem;
	padding: 1rem 1rem 2rem;
`;

export const DetailsWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 1rem;
	background-color: white;
	margin-top: -1px;
	padding: 0 1rem 0.75rem;
	border-bottom: 1px solid ${colors.neutralForm};

	.school {
		&__element {
			display: flex;
			flex-direction: column;

			h6 {
				color: ${colors.neutralText};
			}

			&:last-child {
				grid-column: span 2;
			}

			& > * + * {
				margin-top: 0.25rem;
			}
		}
	}

	@media screen and (min-width: 992px) {
		grid-template-columns: repeat(4, 1fr);
	}
`;

export const LoadMoreWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 1.75rem 0 0;
	border: none;
`;
