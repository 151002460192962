export const required = (label, message) => {
	return {
		value: true,
		message: message || `${label} is required`,
	};
};

export const pattern = (label, value, message) => {
	return {
		value,
		message: message || `${label} is invalid`,
	};
};

export const urlOnly = (label, message) => {
	return {
		value: /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/i,
		message: message || `${label} must be a valid URL`,
	};
};

export const numberOnly = (label, message) => {
	return {
		value: /^\d*$/,
		message: message || `${label} needs to be a number`,
	};
};

export const minValue = (label, value, message) => {
	return {
		value,
		message: message || `${label} needs to be equal or higher than ${value}`,
	};
};
