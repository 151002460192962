import React from 'react';

import { MetricsCards } from './MetricsCards';
import { InformationCard } from './InformationCard';
import * as S from './OverviewTab.styles';

export const OverviewTab = () => {
	function renderContent() {
		return (
			<S.Wrapper>
				<MetricsCards className="b1" />
				<InformationCard className="b2" />
			</S.Wrapper>
		);
	}
	return renderContent();
};
