import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import { GRADE_LEVELS } from '../../../constants/general.constants';
import { errorHandler } from '../../services/authService';
import { addUpdateGroup } from '../../services/groups';
import { Typography, Button, Modal, Stepper, IconButton, TextInput, Checkbox, Select, Radio } from '..';
import { required, numberOnly, minValue } from '../../utils/form-default-errors';
import * as S from './ModalCreateGroup.styles';

export const ModalCreateGroup = ({ isOpen, onRequestClose, setGroups, objectToEdit, editCallback }) => {
	const {
		register,
		reset,
		watch,
		setError,
		formState: { errors, isSubmitting },
		handleSubmit,
	} = useForm();

	useEffect(() => {
		if (objectToEdit) {
			reset({
				groupName: objectToEdit.name,
				gradeLevels: objectToEdit.gradeLevels,
				isThisClass: objectToEdit.isClass ? 'yes' : 'no',
				subject: objectToEdit.subject,
				courseCode: objectToEdit.classCode,
				totalNumberOfStudents: objectToEdit.totalStudents,
				ageRange: {
					minimum: objectToEdit.ageRangeMin,
					maximum: objectToEdit.ageRangeMax,
				},
				genderDemographics: {
					male: objectToEdit.genderMale,
					female: objectToEdit.genderFemale,
					other: objectToEdit.genderOther,
				},
				raceDemographics: {
					white: objectToEdit.raceWhite,
					blackOrAfricanAmerican: objectToEdit.raceBlack,
					americanIndianOrAlaskaNative: objectToEdit.raceNativeAmerican,
					asian: objectToEdit.raceAsian,
					nativeHawaiianAndPacificIslander: objectToEdit.racePacificIslander,
					someOtherRace: objectToEdit.raceOther,
				},
				nativeAmericanDemographics: {
					coharie: objectToEdit.nativeCoharie,
					easternBandOfTheCherokee: objectToEdit.nativeEasternCherokee,
					haliwaSaponi: objectToEdit.nativeHaliwa,
					lumbee: objectToEdit.nativeLumbee,
					occaneechiBandOfTheSaponiNation: objectToEdit.nativeOccaneechi,
					sappony: objectToEdit.nativeSappony,
					waccamawSiouan: objectToEdit.nativeWaccamaw,
					other: objectToEdit.nativeOther,
				},
				ethnicityDemographics: {
					hispanic: objectToEdit.ethnicityHispanic,
					nonHispanic: objectToEdit.ethnicityNonHispanic,
				},
			});
		} else {
			reset({});
		}
	}, [objectToEdit, reset]);

	const totalNumberOfStudents = watch('totalNumberOfStudents');

	const genderDemographics = watch('genderDemographics');
	const raceDemographics = watch('raceDemographics');
	const nativeAmericanDemographics = watch('nativeAmericanDemographics');
	const ethnicityDemographics = watch('ethnicityDemographics');

	const [currentStep, setCurrentStep] = useState(0);

	const isThisClass = watch('isThisClass');

	const handleCloseModal = () => {
		onRequestClose();
		reset();
		setCurrentStep(0);
	};

	const onBack = () => {
		setCurrentStep(0);
	};

	const onNext = () => {
		setTimeout(() => {
			setCurrentStep(currentStep + 1);
		}, 100);
	};

	async function asyncCaller(data) {
		if (Number(data.totalNumberOfStudents) < returnTotals(data.nativeAmericanDemographics)) {
			setError(
				'totalNumberOfStudents',
				{
					type: 'manual',
					message: 'Total number of students must be greater than or equal to the total number of students in the Native American demographics.',
				},
				{
					shouldFocus: true,
				}
			);
		} else if (Number(data.totalNumberOfStudents) !== returnTotals(data.genderDemographics) || Number(data.totalNumberOfStudents) !== returnTotals(data.raceDemographics) || Number(data.totalNumberOfStudents) !== returnTotals(data.ethnicityDemographics)) {
			setError(
				'totalNumberOfStudents',
				{
					type: 'manual',
					message: 'Total number of students must match the total number of students in the demographics',
				},
				{
					shouldFocus: true,
				}
			);
		} else {
			const isClass = isThisClass === 'yes';

			const body = {
				name: data.groupName,
				gradeLevels: data.gradeLevels,
				isClass: isClass,
				subject: isClass ? data.subject : null,
				classCode: isClass ? data.courseCode : null,
				totalStudents: Number(data.totalNumberOfStudents),
				ageRangeMin: Number(data.ageRange.minimum),
				ageRangeMax: Number(data.ageRange.maximum),
				genderMale: Number(data.genderDemographics.male),
				genderFemale: Number(data.genderDemographics.female),
				genderOther: Number(data.genderDemographics.other),
				raceWhite: Number(data.raceDemographics.white),
				raceBlack: Number(data.raceDemographics.blackOrAfricanAmerican),
				raceNativeAmerican: Number(data.raceDemographics.americanIndianOrAlaskaNative),
				raceAsian: Number(data.raceDemographics.asian),
				racePacificIslander: Number(data.raceDemographics.nativeHawaiianAndPacificIslander),
				raceOther: Number(data.raceDemographics.someOtherRace),
				nativeCoharie: Number(data.nativeAmericanDemographics.coharie),
				nativeEasternCherokee: Number(data.nativeAmericanDemographics.easternBandOfTheCherokee),
				nativeHaliwa: Number(data.nativeAmericanDemographics.haliwaSaponi),
				nativeLumbee: Number(data.nativeAmericanDemographics.lumbee),
				nativeOccaneechi: Number(data.nativeAmericanDemographics.occaneechiBandOfTheSaponiNation),
				nativeSappony: Number(data.nativeAmericanDemographics.sappony),
				nativeWaccamaw: Number(data.nativeAmericanDemographics.waccamawSiouan),
				nativeOther: Number(data.nativeAmericanDemographics.other),
				ethnicityHispanic: Number(data.ethnicityDemographics.hispanic),
				ethnicityNonHispanic: Number(data.ethnicityDemographics.nonHispanic),
			};

			if (objectToEdit) {
				body.groupId = objectToEdit.groupId;
			}

			const {
				data: { result },
			} = await addUpdateGroup(body);

			if (objectToEdit) {
				if (editCallback) {
					editCallback(result.group);
				}
				if (setGroups) {
					setGroups((groups) => {
						return groups.map((group) => {
							if (group.groupId === objectToEdit.groupId) {
								return result.group;
							}
							return group;
						});
					});
				}
			} else {
				if (setGroups) {
					setGroups((groups) => [...groups, result.group]);
				}
			}

			handleCloseModal();
		}
	}

	const onSubmit = (e) => {
		if (currentStep === 0) {
			handleSubmit(onNext)(e);
		}
		if (currentStep === 1) {
			handleSubmit(asyncCaller)(e).catch(errorHandler);
		}
	};

	function returnTotals(fieldObj) {
		return fieldObj ? Object.values(fieldObj).reduce((acc, curr) => acc + (Number.isNaN(Number(curr)) ? 0 : Number(curr)), 0) : 0;
	}

	return (
		<Modal isOpen={isOpen} onRequestClose={handleCloseModal} fullHeight>
			<S.ModalFormWrapper onSubmit={onSubmit}>
				<IconButton className="close-button" type="button" icon={['fal', 'times']} onClick={handleCloseModal} />
				<Stepper
					activeStep={currentStep}
					steps={[
						{ id: 1, label: 'Overview' },
						{ id: 2, label: 'Students' },
					]}
				/>
				{currentStep === 0 && (
					<div className="modal-content">
						<Typography className="bigger-title" tag="h1" weight="extrablack" center>
							Group Overview
						</Typography>
						<Typography tag="p" className="description" center>
							Add in details about your group.
						</Typography>

						<S.ModalForm>
							<TextInput
								label="Group Name (i.e class, entire school, grade, etc.)"
								placeholder="Group Name"
								id="groupName"
								error={errors.groupName}
								{...register('groupName', {
									required: required('Group Name'),
								})}
							/>

							<S.GradeLevelWrapper>
								<Typography className={`field-label${!!errors.gradeLevels ? ' with-error' : ''}`} tag="p" variation="2" weight="semibold">
									Grade Level(s)
								</Typography>

								{GRADE_LEVELS.map(({ value, label }) => (
									<div key={value} className="checkbox-field">
										<Checkbox id={value} value={value} {...register('gradeLevels', { required: required('Grade Level(s)') })} />
										<label htmlFor={value}>{label}</label>
									</div>
								))}

								{!!errors.gradeLevels && (
									<div className="error-wrapper">
										<Typography tag="p" variation="2">
											{errors.gradeLevels.message}
										</Typography>
									</div>
								)}
							</S.GradeLevelWrapper>

							<S.ClassWrapper>
								<Typography className={`field-label${!!errors.isThisClass ? ' with-error' : ''}`} tag="p" variation="2" weight="semibold">
									Is this a Class?
								</Typography>

								<div className="radio-wrapper">
									<div className="radio-field">
										<Radio
											id="isThisClass1"
											value="yes"
											{...register('isThisClass', {
												required: required('Is this a Class'),
											})}
										/>
										<label htmlFor="isThisClass1">Yes</label>
									</div>
									<div className="radio-field">
										<Radio
											id="isThisClass2"
											value="no"
											{...register('isThisClass', {
												required: required('Is this a Class'),
											})}
										/>
										<label htmlFor="isThisClass2">No</label>
									</div>
								</div>

								{!!errors.isThisClass && (
									<div className="error-wrapper">
										<Typography tag="p" variation="2">
											{errors.isThisClass.message}
										</Typography>
									</div>
								)}
							</S.ClassWrapper>

							{isThisClass === 'yes' && (
								<S.ClassOptionsWrapper>
									<TextInput
										label="Subject"
										placeholder="i.e. Art, Physics, Algebra"
										id="subject"
										error={errors.subject}
										{...register('subject', {
											required: required('Subject'),
										})}
									/>
									<TextInput
										label="Course Code"
										placeholder="i.e. IL642X0 Commercial Art I"
										id="courseCode"
										error={errors.courseCode}
										{...register('courseCode', {
											required: required('Course Code'),
										})}
									/>
								</S.ClassOptionsWrapper>
							)}
						</S.ModalForm>
					</div>
				)}

				{currentStep === 1 && (
					<div className="modal-content">
						<Typography className="bigger-title" tag="h1" weight="extrablack" center>
							Student Demographics
						</Typography>
						<Typography tag="p" className="description" center>
							Enter a breakdown of the composition your group.
						</Typography>

						<S.StudentsFormWrapper>
							<div className="grid-line student-overview">
								<Typography className="grid-title" tag="h4" weight="bold">
									Student Overview
								</Typography>

								<TextInput
									containerClassName="span-3"
									label="Total Number of Students"
									error={errors.totalNumberOfStudents}
									{...register('totalNumberOfStudents', {
										required: required('Total Number of Students'),
										pattern: numberOnly('Total Number of Students'),
										min: minValue('Total Number of Students', 1),
									})}
								/>
							</div>
							<div className="grid-line age-range">
								<Typography className="grid-title" tag="h6" weight="semibold">
									Age Range
								</Typography>
								<TextInput
									label="Minimum"
									error={errors.ageRange?.minimum}
									{...register('ageRange.minimum', {
										required: required('Minimum'),
										pattern: numberOnly('Minimum'),
									})}
								/>
								<TextInput
									label="Maximum"
									error={errors.ageRange?.maximum}
									{...register('ageRange.maximum', {
										required: required('Maximum'),
										pattern: numberOnly('Maximum'),
									})}
								/>
							</div>
							<S.BigFieldset>
								<Typography tag="h4" weight="bold">
									Gender Demographics
								</Typography>
								<S.BigBox>
									<S.BigBoxHeader>
										<Typography tag="h6" weight="semibold">
											Gender
										</Typography>
										<Typography tag="h6" weight="semibold">
											# of Students
										</Typography>
									</S.BigBoxHeader>
									<S.BigBoxLine>
										<Typography tag="p">Male</Typography>
										<TextInput
											className="box-field"
											defaultValue="0"
											error={errors.genderDemographics?.male}
											{...register('genderDemographics.male', {
												required: required('Male'),
												pattern: numberOnly('Male'),
											})}
										/>
									</S.BigBoxLine>
									<S.BigBoxLine>
										<Typography tag="p">Female</Typography>
										<TextInput
											className="box-field"
											defaultValue="0"
											error={errors.genderDemographics?.female}
											{...register('genderDemographics.female', {
												required: required('Female'),
												pattern: numberOnly('Female'),
											})}
										/>
									</S.BigBoxLine>
									<S.BigBoxLine>
										<Typography tag="p">Other</Typography>
										<TextInput
											className="box-field"
											defaultValue="0"
											error={errors.genderDemographics?.other}
											{...register('genderDemographics.other', {
												required: required('Other'),
												pattern: numberOnly('Other'),
											})}
										/>
									</S.BigBoxLine>
									<S.BigBoxFooter>
										<Typography tag="p" weight="semibold">
											Total
										</Typography>
										<Typography tag="p" weight="semibold" className={totalNumberOfStudents != returnTotals(genderDemographics) && 'warning-text'}>
											{returnTotals(genderDemographics)}
										</Typography>
									</S.BigBoxFooter>
								</S.BigBox>
							</S.BigFieldset>
							<S.BigFieldset>
								<Typography tag="h4" weight="bold">
									Race Demographics
								</Typography>
								<S.BigBox>
									<S.BigBoxHeader>
										<Typography tag="h6" weight="semibold">
											Race
										</Typography>
										<Typography tag="h6" weight="semibold">
											# of Students
										</Typography>
									</S.BigBoxHeader>
									<S.BigBoxLine>
										<Typography tag="p">White</Typography>
										<TextInput
											className="box-field"
											defaultValue="0"
											error={errors.raceDemographics?.white}
											{...register('raceDemographics.white', {
												required: required('White'),
												pattern: numberOnly('White'),
											})}
										/>
									</S.BigBoxLine>
									<S.BigBoxLine>
										<Typography tag="p">Black or African American</Typography>
										<TextInput
											className="box-field"
											defaultValue="0"
											error={errors.raceDemographics?.blackOrAfricanAmerican}
											{...register('raceDemographics.blackOrAfricanAmerican', {
												required: required('Black or African American'),
												pattern: numberOnly('Black or African American'),
											})}
										/>
									</S.BigBoxLine>
									<S.BigBoxLine>
										<Typography tag="p">American Indian or Alaska Native</Typography>
										<TextInput
											className="box-field"
											defaultValue="0"
											error={errors.raceDemographics?.americanIndianOrAlaskaNative}
											{...register('raceDemographics.americanIndianOrAlaskaNative', {
												required: required('American Indian or Alaska Native'),
												pattern: numberOnly('American Indian or Alaska Native'),
											})}
										/>
									</S.BigBoxLine>
									<S.BigBoxLine>
										<Typography tag="p">Asian</Typography>
										<TextInput
											className="box-field"
											defaultValue="0"
											error={errors.raceDemographics?.asian}
											{...register('raceDemographics.asian', {
												required: required('Asian'),
												pattern: numberOnly('Asian'),
											})}
										/>
									</S.BigBoxLine>
									<S.BigBoxLine>
										<Typography tag="p">Native Hawaiian and Pacific Islander</Typography>
										<TextInput
											className="box-field"
											defaultValue="0"
											error={errors.raceDemographics?.nativeHawaiianAndPacificIslander}
											{...register('raceDemographics.nativeHawaiianAndPacificIslander', {
												required: required('Native Hawaiian and Pacific Islander'),
												pattern: numberOnly('Native Hawaiian and Pacific Islander'),
											})}
										/>
									</S.BigBoxLine>
									<S.BigBoxLine>
										<Typography tag="p">Some Other Race</Typography>
										<TextInput
											className="box-field"
											defaultValue="0"
											error={errors.raceDemographics?.someOtherRace}
											{...register('raceDemographics.someOtherRace', {
												required: required('Some Other Race'),
												pattern: numberOnly('Some Other Race'),
											})}
										/>
									</S.BigBoxLine>
									<S.BigBoxFooter>
										<Typography tag="p" weight="semibold">
											Total
										</Typography>
										<Typography tag="p" weight="semibold" className={totalNumberOfStudents != returnTotals(raceDemographics) && 'warning-text'}>
											{returnTotals(raceDemographics)}
										</Typography>
									</S.BigBoxFooter>
								</S.BigBox>
							</S.BigFieldset>
							<S.BigFieldset>
								<Typography tag="h4" weight="bold">
									Native American Demographics
								</Typography>
								<S.BigBox>
									<S.BigBoxHeader>
										<Typography tag="h6" weight="semibold">
											Tribe
										</Typography>
										<Typography tag="h6" weight="semibold">
											# of Students
										</Typography>
									</S.BigBoxHeader>
									<S.BigBoxLine>
										<Typography tag="p">Coharie</Typography>
										<TextInput
											className="box-field"
											defaultValue="0"
											error={errors.nativeAmericanDemographics?.coharie}
											{...register('nativeAmericanDemographics.coharie', {
												required: required('Coharie'),
												pattern: numberOnly('Coharie'),
											})}
										/>
									</S.BigBoxLine>
									<S.BigBoxLine>
										<Typography tag="p">Eastern Band of the Cherokee</Typography>
										<TextInput
											className="box-field"
											defaultValue="0"
											error={errors.nativeAmericanDemographics?.easternBandOfTheCherokee}
											{...register('nativeAmericanDemographics.easternBandOfTheCherokee', {
												required: required('Eastern Band of the Cherokee'),
												pattern: numberOnly('Eastern Band of the Cherokee'),
											})}
										/>
									</S.BigBoxLine>
									<S.BigBoxLine>
										<Typography tag="p">Haliwa-Saponi</Typography>
										<TextInput
											className="box-field"
											defaultValue="0"
											error={errors.nativeAmericanDemographics?.haliwaSaponi}
											{...register('nativeAmericanDemographics.haliwaSaponi', {
												required: required('Haliwa-Saponi'),
												pattern: numberOnly('Haliwa-Saponi'),
											})}
										/>
									</S.BigBoxLine>
									<S.BigBoxLine>
										<Typography tag="p">Lumbee</Typography>
										<TextInput
											className="box-field"
											defaultValue="0"
											error={errors.nativeAmericanDemographics?.lumbee}
											{...register('nativeAmericanDemographics.lumbee', {
												required: required('Lumbee'),
												pattern: numberOnly('Lumbee'),
											})}
										/>
									</S.BigBoxLine>
									<S.BigBoxLine>
										<Typography tag="p">Occaneechi Band of the Saponi Nation</Typography>
										<TextInput
											className="box-field"
											defaultValue="0"
											error={errors.nativeAmericanDemographics?.occaneechiBandOfTheSaponiNation}
											{...register('nativeAmericanDemographics.occaneechiBandOfTheSaponiNation', {
												required: required('Occaneechi Band of the Saponi Nation'),
												pattern: numberOnly('Occaneechi Band of the Saponi Nation'),
											})}
										/>
									</S.BigBoxLine>
									<S.BigBoxLine>
										<Typography tag="p">Sappony</Typography>
										<TextInput
											className="box-field"
											defaultValue="0"
											error={errors.nativeAmericanDemographics?.sappony}
											{...register('nativeAmericanDemographics.sappony', {
												required: required('Sappony'),
												pattern: numberOnly('Sappony'),
											})}
										/>
									</S.BigBoxLine>
									<S.BigBoxLine>
										<Typography tag="p">Waccamaw-Siouan</Typography>
										<TextInput
											className="box-field"
											defaultValue="0"
											error={errors.nativeAmericanDemographics?.waccamawSiouan}
											{...register('nativeAmericanDemographics.waccamawSiouan', {
												required: required('Waccamaw-Siouan'),
												pattern: numberOnly('Waccamaw-Siouan'),
											})}
										/>
									</S.BigBoxLine>
									<S.BigBoxLine>
										<Typography tag="p">Other</Typography>
										<TextInput
											className="box-field"
											defaultValue="0"
											error={errors.nativeAmericanDemographics?.other}
											{...register('nativeAmericanDemographics.other', {
												required: required('Other'),
												pattern: numberOnly('Other'),
											})}
										/>
									</S.BigBoxLine>
									<S.BigBoxFooter>
										<Typography tag="p" weight="semibold">
											Total
										</Typography>
										<Typography tag="p" weight="semibold" className={totalNumberOfStudents < returnTotals(nativeAmericanDemographics) && 'warning-text'}>
											{returnTotals(nativeAmericanDemographics)}
										</Typography>
									</S.BigBoxFooter>
								</S.BigBox>
							</S.BigFieldset>
							<S.BigFieldset>
								<Typography tag="h4" weight="bold">
									Ethnicity Demographics
								</Typography>
								<S.BigBox>
									<S.BigBoxHeader>
										<Typography tag="h6" weight="semibold">
											Ethnicity
										</Typography>
										<Typography tag="h6" weight="semibold">
											# of Students
										</Typography>
									</S.BigBoxHeader>
									<S.BigBoxLine>
										<Typography tag="p">Hispanic</Typography>
										<TextInput
											className="box-field"
											defaultValue="0"
											error={errors.ethnicityDemographics?.hispanic}
											{...register('ethnicityDemographics.hispanic', {
												required: required('Hispanic'),
												pattern: numberOnly('Hispanic'),
											})}
										/>
									</S.BigBoxLine>
									<S.BigBoxLine>
										<Typography tag="p">Non-Hispanic</Typography>
										<TextInput
											className="box-field"
											defaultValue="0"
											error={errors.ethnicityDemographics?.nonHispanic}
											{...register('ethnicityDemographics.nonHispanic', {
												required: required('Non-Hispanic'),
												pattern: numberOnly('Non-Hispanic'),
											})}
										/>
									</S.BigBoxLine>
									<S.BigBoxFooter>
										<Typography tag="p" weight="semibold">
											Total
										</Typography>
										<Typography tag="p" weight="semibold" className={totalNumberOfStudents != returnTotals(ethnicityDemographics) && 'warning-text'}>
											{returnTotals(ethnicityDemographics)}
										</Typography>
									</S.BigBoxFooter>
								</S.BigBox>
							</S.BigFieldset>
						</S.StudentsFormWrapper>
					</div>
				)}

				<div className="modal-footer">
					{currentStep > 0 && (
						<>
							<Button variant="outline" variation="secondary" type="button" onClick={onBack}>
								<Typography variation="button-medium" weight="bold">
									Back
								</Typography>
							</Button>
							<div className="flex-1" />
						</>
					)}
					<Button>
						<Typography variation="button-medium" weight="bold">
							{currentStep === 1 ? (isSubmitting ? 'Loading...' : objectToEdit ? 'Save' : 'Create') : 'Next'}
						</Typography>
					</Button>
				</div>
			</S.ModalFormWrapper>
		</Modal>
	);
};

ModalCreateGroup.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onRequestClose: PropTypes.func.isRequired,
	setGroups: PropTypes.func,
	objectToEdit: PropTypes.shape({
		groupId: PropTypes.string,
		name: PropTypes.string,
		gradeLevels: PropTypes.arrayOf(PropTypes.string),
		isClass: PropTypes.bool,
		subject: PropTypes.string,
		classCode: PropTypes.string,
		totalStudents: PropTypes.number,
		ageRangeMin: PropTypes.number,
		ageRangeMax: PropTypes.number,
		genderMale: PropTypes.number,
		genderFemale: PropTypes.number,
		genderOther: PropTypes.number,
		raceWhite: PropTypes.number,
		raceBlack: PropTypes.number,
		raceNativeAmerican: PropTypes.number,
		raceAsian: PropTypes.number,
		racePacificIslander: PropTypes.number,
		raceOther: PropTypes.number,
		nativeCoharie: PropTypes.number,
		nativeEasternCherokee: PropTypes.number,
		nativeHaliwa: PropTypes.number,
		nativeLumbee: PropTypes.number,
		nativeOccaneechi: PropTypes.number,
		nativeSappony: PropTypes.number,
		nativeWaccamaw: PropTypes.number,
		nativeOther: PropTypes.number,
		ethnicityHispanic: PropTypes.number,
		ethnicityNonHispanic: PropTypes.number,
	}),
	editCallback: PropTypes.func,
};
