import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import * as S from './AccountCard.styles';
import { Card, Typography } from '../../../components';

const AccountCard = ({ className }) => {
	const user = useSelector((state) => state.user.value);

	return (
		<Card
			className={className}
			title="Account"
			transparentHeaderBorder={true}
			actions={[
				{
					id: 1,
					label: 'Visit NCEdcloud',
					size: 'small',
					variant: 'outline',
					icon: { source: ['fal', 'external-link'] },
					onClick: () => {
						window.open('https://my.ncedcloud.org');
					},
				},
			]}
		>
			<S.Wrapper>
				<div className="element-info">
					<Typography className="element-info__title" tag="h6" weight="semibold">
						Email
					</Typography>
					<Typography className="element-info__text" tag="p">
						{user?.email}
					</Typography>
				</div>
				<div className="element-info">
					<Typography className="element-info__title" tag="h6" weight="semibold">
						Password
					</Typography>
					<Typography className="element-info__text" tag="p">
						**********
					</Typography>
				</div>
				<div className="element-info">
					<Typography className="element-info__title" tag="h6" weight="semibold">
						Name
					</Typography>
					<Typography className="element-info__text" tag="p">
						{`${user?.firstName} ${user?.lastName}`}
					</Typography>
				</div>
			</S.Wrapper>
		</Card>
	);
};

AccountCard.propTypes = {
	className: PropTypes.string,
};

export default AccountCard;
