import React, { useEffect, useState, useRef, useContext, useImperativeHandle, forwardRef } from 'react';
import { useTable, useFlexLayout } from 'react-table';
import { useDebouncedFn } from 'beautiful-react-hooks';
import toast from 'react-hot-toast';

import { AdminMembershipDetailContext } from '../AdminMembershipDetail';
import { BUSINESS_SORT_OPTIONS } from '../../../../constants/general.constants';
import { fetchBusinesses } from '../../../services/business';
import { Typography, Card, TableComponents, Spinner, ErrorComponent, EmptyComponent, FilterSection, Button } from '../../../components';
import * as S from './CompaniesTab.styles';
import { useHistory } from 'react-router-dom';
import { removeCompaniesFromMembership } from '../../../services/memberships';

export const CompaniesTab = forwardRef(({}, ref) => {
	const {
		state: { membership },
		dispatch,
	} = useContext(AdminMembershipDetailContext);

	const history = useHistory();
	const [pageStatus, setPageStatus] = useState('idle');
	const [companies, setCompanies] = useState([]);

	const initialPageNumber = 0;
	const [pageNumber, setPageNumber] = useState(initialPageNumber);
	const [totalPages, setTotalPages] = useState(undefined);
	const [loadingMore, setLoadingMore] = useState(false);

	const filterRef = useRef();

	const onChangeCallback = useDebouncedFn(async () => {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchBusinesses({ membershipId: membership.id, page: initialPageNumber, filterOptions: { approved: true }, ...filterRef.current.value });
			setCompanies(result.businesses);
			setPageNumber(initialPageNumber);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}, 300);

	async function loadMore() {
		setLoadingMore(true);
		try {
			if (totalPages > pageNumber + 1) {
				const {
					data: { result },
				} = await fetchBusinesses({ membershipId: membership.id, page: pageNumber + 1, filterOptions: { approved: true }, ...filterRef.current.value });
				setCompanies((prev) => [...prev, ...result.businesses]);
				setTotalPages(result.totalPages);
				setPageNumber((prev) => prev + 1);
			} else {
				toast.error('No more resources to load');
			}
			setLoadingMore(false);
		} catch (error) {
			setLoadingMore(false);
		}
	}

	async function initializeData() {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchBusinesses({ membershipId: membership.id, page: initialPageNumber, filterOptions: { approved: true } });
			setCompanies(result.businesses);
			setTotalPages(result.totalPages);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}

	const handleRemoveCompanyFromMembership = async (companyId) => {
		await removeCompaniesFromMembership({ membershipId: membership.id, businessIds: [companyId] });
		dispatch({ type: 'REMOVE_COMPANY_FROM_MEMBERSHIP', payload: companyId });
		onChangeCallback();
		toast.success('Company removed from membership');
	};

	useImperativeHandle(ref, () => ({
		onChangeCallback,
	}));

	useEffect(() => {
		// Load initial data
		if (pageStatus === 'idle') {
			initializeData();
		}
	}, [pageStatus]);

	const columns = React.useMemo(
		() => [
			{
				Header: 'Company Name',
				accessor: 'name',
			},
			{
				Header: 'City',
				accessor: 'locationCity',
			},
			{
				Header: '# of Locations',
				accessor: 'locationCount',
			},
			{
				Header: '# of Employees',
				accessor: 'employeeCount',
			},
			{
				Header: '# of Resources',
				accessor: 'resourceCount',
			},
			{
				Header: '',
				accessor: 'id',
				// eslint-disable-next-line react/prop-types
				Cell: ({ value }) => <TableComponents.Cells.RemoveButtonCell onClick={() => handleRemoveCompanyFromMembership(value)} />,
			},
		],
		[]
	);
	const data = React.useMemo(() => companies, [companies]);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
		{
			columns,
			data,
		},
		useFlexLayout
	);

	const renderContent = () => {
		switch (pageStatus) {
			case 'loading':
				return <Spinner />;
			case 'error':
				return <ErrorComponent />;
			case 'success':
				return rows.length > 0 ? (
					<table {...getTableProps()}>
						<thead>
							{headerGroups.map(({ key, ...headerGroup }) => (
								<tr key={key} {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map(({ key, ...column }) => (
										<th key={key} {...column.getHeaderProps()}>
											<Typography tag="h6" weight="semibold">
												{column.render('Header')}
											</Typography>
										</th>
									))}
								</tr>
							))}
						</thead>
						<tbody {...getTableBodyProps()}>
							{rows.map(({ key, ...row }) => {
								prepareRow(row);
								return (
									<TableComponents.ClickableRow
										key={key}
										{...row.getRowProps()}
										onClick={() => {
											history.push(`/admin/companies/${row.original.id}`);
										}}
									>
										{row.cells.map(({ key, ...cell }) => {
											return (
												<td key={key} className="overflow-hidden" {...cell.getCellProps()}>
													<Typography tag="p" variation="2">
														{cell.render('Cell')}
													</Typography>
												</td>
											);
										})}
									</TableComponents.ClickableRow>
								);
							})}
						</tbody>
					</table>
				) : (
					<EmptyComponent title="No current companies" message="Invite a new company to get started." icon={['fal', 'building']} />
				);
			default:
				return null;
		}
	};

	return (
		<S.Wrapper>
			<FilterSection
				ref={filterRef}
				onChangeCallback={onChangeCallback}
				initialState={{
					searchText: '',
					sortOption: BUSINESS_SORT_OPTIONS[0],
					showAdvancedFilters: false,
					activeFilters: [],
					distance: 0,
					counties: [],
					locationTypes: [],
					categories: [],
					careerClusters: [],
					ageGroups: [],
					employabilitySkills: [],
					curriculumStandards: [],
				}}
				sortOptions={BUSINESS_SORT_OPTIONS}
				filterOptions={['counties', 'careerClusters']}
			/>

			<Card>
				<TableComponents.TableWrapper>{renderContent()}</TableComponents.TableWrapper>
			</Card>
			{companies?.length > 0 && totalPages > pageNumber + 1 && (
				<S.LoadMoreWrapper>
					<Button variant="outline" variation="secondary" type="button" onClick={loadMore}>
						<Typography variation="button-medium" weight="bold">
							{loadingMore ? 'Loading...' : 'Load More'}
						</Typography>
					</Button>
				</S.LoadMoreWrapper>
			)}
		</S.Wrapper>
	);
});

CompaniesTab.displayName = 'CompaniesTab';
