import api from '../api';

export const fetchRequests = (data) => api.post('/core/functions/fetchRequests', data);

export const fetchRequest = (data) => api.post('/core/functions/fetchRequest', data);

export const archiveUnarchiveRequest = (data) => api.post('/core/functions/archiveUnarchiveRequest', data);

export const duplicateRequest = (data) => api.post('/core/functions/duplicateRequest', data);

export const scheduleRequest = (data) => api.post('/core/functions/scheduleRequest', data);

export const fulfillRequest = (data) => api.post('/core/functions/fulfillRequest', data);

export const completeRequest = (data) => api.post('/core/functions/completeRequest', data);

export const cancelRequest = (data) => api.post('/core/functions/cancelRequest', data);

export const fetchSavedRequests = (data) => api.post('/core/functions/fetchSavedRequests', data);

export const saveUnsaveRequest = (data) => api.post('/core/functions/saveUnsaveRequest', data);

export const archiveUnarchiveRequestResponse = (data) => api.post('/core/functions/archiveUnarchiveRequestResponse', data);

export const cancelRequestResponse = (data) => api.post('/core/functions/cancelRequestResponse', data);

export const sendRequestResponse = (data) => api.post('/core/functions/sendRequestResponse', data);

export const acceptRequestResponse = (data) => api.post('/core/functions/acceptRequestResponse', data);

export const declineRequestResponse = (data) => api.post('/core/functions/declineRequestResponse', data);
