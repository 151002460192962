import React, { useState, useMemo, useImperativeHandle, forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTable, useFlexLayout } from 'react-table';

import { Card, TableComponents, Typography, IconButton, Button } from '../../../components';
import * as S from './TableChartGraph.styles';

const ChartModal = forwardRef(({ title, data = {}, labels = [], fields = [] }, ref) => {
	const [isOpen, setIsOpen] = useState(false);

	const columns = useMemo(
		() => [
			{
				Header: labels?.[0],
				accessor: 'name',
			},
			{
				Header: labels?.[1],
				accessor: 'value',
			},
		],
		[labels]
	);
	const chartData = useMemo(() => {
		if (isOpen) {
			if (Array.isArray(data)) {
				if (fields?.length > 0) {
					return data.map((item) => ({
						name: item[fields[0]],
						value: item[fields[1]],
					}));
				}
				return data;
			}
			return Object.keys(data).map((key) => ({ name: key.charAt(0).toUpperCase() + key.slice(1), value: data[key] }));
		}
		return [];
	}, [data, fields, isOpen]);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
		{
			columns,
			data: chartData,
		},
		useFlexLayout
	);

	const handleOpen = () => {
		setIsOpen(true);
	};

	const handleClose = () => {
		setIsOpen(false);
	};

	useImperativeHandle(ref, () => ({
		open: handleOpen,
		close: handleClose,
	}));

	return (
		<ReactModal className="table-chart-graph-modal" ref={ref} closeTimeoutMS={200} isOpen={isOpen} onRequestClose={handleClose}>
			<S.Wrapper>
				<S.ModalTitleWrapper>
					<Typography tag="h3" weight="bold" center>
						{title}
					</Typography>
					<IconButton className="close-button" type="button" icon={['fal', 'times']} onClick={handleClose} />
				</S.ModalTitleWrapper>
				<S.TableWrapper>
					<TableComponents.TableWrapper>
						<table {...getTableProps()}>
							<thead>
								{headerGroups.map(({ key, ...headerGroup }) => (
									<tr key={key} {...headerGroup.getHeaderGroupProps()}>
										{headerGroup.headers.map(({ key, ...column }) => (
											<th key={key} {...column.getHeaderProps()}>
												<Typography tag="h6" weight="semibold">
													{column.render('Header')}
												</Typography>
											</th>
										))}
									</tr>
								))}
							</thead>
							<tbody {...getTableBodyProps()}>
								{rows.map(({ key, ...row }) => {
									prepareRow(row);
									return (
										<tr key={key} {...row.getRowProps()}>
											{row.cells.map(({ key, ...cell }) => {
												return (
													<td key={key} {...cell.getCellProps()}>
														<Typography tag="p" variation="2">
															{cell.render('Cell')}
														</Typography>
													</td>
												);
											})}
										</tr>
									);
								})}
							</tbody>
						</table>
					</TableComponents.TableWrapper>
				</S.TableWrapper>
			</S.Wrapper>
		</ReactModal>
	);
});

ChartModal.displayName = 'ChartModal';

ChartModal.propTypes = {
	title: PropTypes.string,
	data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	labels: PropTypes.arrayOf(PropTypes.string),
	fields: PropTypes.arrayOf(PropTypes.string),
};

export const TableChartGraph = ({ className, data = {}, title, labels = [], fields = [], itemsToShow = 5 }) => {
	const modalRef = useRef();
	const columns = useMemo(
		() => [
			{
				Header: labels?.[0],
				accessor: 'name',
			},
			{
				Header: labels?.[1],
				accessor: 'value',
			},
		],
		[labels]
	);
	const chartData = useMemo(() => {
		if (Array.isArray(data)) {
			const firstItems = data.slice(0, itemsToShow);
			if (fields?.length > 0) {
				return firstItems.map((item) => ({
					name: item[fields[0]],
					value: item[fields[1]],
				}));
			}
			return firstItems;
		}
		return Object.keys(data)
			.slice(0, itemsToShow)
			.map((key) => ({ name: key.charAt(0).toUpperCase() + key.slice(1), value: data[key] }));
	}, [data, fields, itemsToShow]);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
		{
			columns,
			data: chartData,
		},
		useFlexLayout
	);

	if (chartData.length === 0 || Object.keys(data).length === 0) {
		return null;
	}

	return (
		<>
			<Card className={className} title={title} transparentHeaderBorder>
				<TableComponents.TableWrapper>
					<table {...getTableProps()}>
						<thead>
							{headerGroups.map(({ key, ...headerGroup }) => (
								<tr key={key} {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map(({ key, ...column }) => (
										<th key={key} {...column.getHeaderProps()}>
											<Typography tag="h6" weight="semibold">
												{column.render('Header')}
											</Typography>
										</th>
									))}
								</tr>
							))}
						</thead>
						<tbody {...getTableBodyProps()}>
							{rows.map(({ key, ...row }) => {
								prepareRow(row);
								return (
									<tr key={key} {...row.getRowProps()}>
										{row.cells.map(({ key, ...cell }) => {
											return (
												<td key={key} {...cell.getCellProps()}>
													<Typography tag="p" variation="2">
														{cell.render('Cell')}
													</Typography>
												</td>
											);
										})}
									</tr>
								);
							})}
						</tbody>
					</table>
				</TableComponents.TableWrapper>
				<S.ButtonWrapper>
					<Button variant="text" type="button" onClick={() => modalRef.current.open()}>
						<Typography variation="button-small" weight="bold">
							View All
						</Typography>
						<FontAwesomeIcon icon={['fal', 'arrow-right']} />
					</Button>
				</S.ButtonWrapper>
			</Card>
			<ChartModal ref={modalRef} title={title} data={data} labels={labels} fields={fields} />
		</>
	);
};
TableChartGraph.propTypes = {
	className: PropTypes.string,
	data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	title: PropTypes.string,
	labels: PropTypes.arrayOf(PropTypes.string),
	fields: PropTypes.arrayOf(PropTypes.string),
	itemsToShow: PropTypes.number,
};
