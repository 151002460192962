import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Typography } from '../Typography';
import { Button } from '../Button';
import * as S from './PageHeader.styles';

export function PageHeader({ title, action = [], back = null }) {
	return (
		<S.Wrapper className="page-header">
			{back && (
				<S.BackButtonWrapper>
					<Button className="header__back-button" variant="text" size="small" onClick={back.onClick}>
						<FontAwesomeIcon icon={['fal', 'arrow-left']} />
						<Typography weight="bold" variation="button-small">
							{back.label}
						</Typography>
					</Button>
				</S.BackButtonWrapper>
			)}
			<Typography tag="h1" weight="extrablack">
				{title}
			</Typography>
			{!!action.length && (
				<S.ButtonWrapper>
					{action.map((item) => (
						<Button key={item.id} onClick={item.onClick} variant={item.type} variation={item.variation} size={item.size}>
							{item.icon && <FontAwesomeIcon icon={item.icon} />}
							<Typography tag="span" variation={item.type === 'text' ? '1' : '2'} weight="extrablack">
								{item.label}
							</Typography>
						</Button>
					))}
				</S.ButtonWrapper>
			)}
		</S.Wrapper>
	);
}

PageHeader.propTypes = {
	title: PropTypes.string,
	back: PropTypes.shape({
		onClick: PropTypes.func,
		label: PropTypes.string,
	}),
	action: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			onClick: PropTypes.func,
			label: PropTypes.string,
			icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
			type: PropTypes.oneOf(['solid', 'outline', 'text']),
			variation: PropTypes.oneOf(['default', 'secondary', 'warning']),
			size: PropTypes.oneOf(['medium', 'small']),
		})
	),
};
