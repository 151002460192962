import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { errorHandler, getCurrentAuthenticatedUser } from '../../services/authService';
import { updateUser } from '../../app/slices/user/userSlice';
import { AdminNavigation, PageHeader, Spinner, ErrorComponent } from '../../components';
import { AccountCard } from './AccountCard';
import { ProfileCard } from './ProfileCard';
import { META_TITLE } from '../../../constants/general.constants';
import * as S from './AdminProfile.styles';

const AdminProfile = () => {
	const [pageStatus, setPageStatus] = useState('idle');
	const dispatch = useDispatch();

	async function initializeData() {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await getCurrentAuthenticatedUser();
			dispatch(updateUser(result.user));
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
			errorHandler(error);
		}
	}

	const renderContent = () => {
		switch (pageStatus) {
			case 'loading':
				return <Spinner />;
			case 'error':
				return <ErrorComponent />;
			case 'success':
				return (
					<S.Wrapper>
						<div className="row-wrapper">
							<ProfileCard />
						</div>
						<div className="row-wrapper">
							<AccountCard />
						</div>
					</S.Wrapper>
				);
			default:
				return null;
		}
	};

	useEffect(() => {
		// Set document title
		document.title = `My Profile | ${META_TITLE}`;

		if (pageStatus === 'idle') {
			initializeData();
		}
	}, []);

	return (
		<AdminNavigation>
			<PageHeader title="Profile" />
			{renderContent()}
		</AdminNavigation>
	);
};

export default AdminProfile;
