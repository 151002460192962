import React, { useState, useEffect, useRef, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDebouncedFn } from 'beautiful-react-hooks';
import toast from 'react-hot-toast';

import { FILE_EXTENSIONS, TEMPLATE_SORT_OPTIONS } from '../../../../../constants/general.constants';
import { fetchTemplates } from '../../../../services/templates';
import { Typography, Spinner, ErrorComponent, EmptyComponent, FilterSection, Button, Badge } from '../../../../components';
import { AdminAdminDetailContext } from '../../AdminAdminDetail';
import * as S from './FilesTab.styles';

export const BADGE_STATUS = {
	published: {
		label: 'Published',
		color: 'success',
	},
	archived: {
		label: 'Archived',
		color: 'primary',
	},
	draft: {
		label: 'Draft',
		color: 'neutral',
	},
};

export const FilesTab = () => {
	const {
		state: { user },
	} = useContext(AdminAdminDetailContext);

	const [pageStatus, setPageStatus] = useState('idle');
	const [templates, setTemplates] = useState([]);

	const initialPageNumber = 0;
	const [pageNumber, setPageNumber] = useState(initialPageNumber);
	const [totalPages, setTotalPages] = useState(undefined);
	const [loadingMore, setLoadingMore] = useState(false);

	const filterRef = useRef();

	const onChangeCallback = useDebouncedFn(async () => {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchTemplates({ userId: user.id, page: initialPageNumber, type: 'file', ...filterRef.current.value });
			setTemplates(result.templates);
			setPageNumber(initialPageNumber);
			setTotalPages(result.totalPages);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}, 300);

	async function loadMore() {
		setLoadingMore(true);
		try {
			if (totalPages > pageNumber + 1) {
				const {
					data: { result },
				} = await fetchTemplates({ userId: user.id, page: pageNumber + 1, type: 'file', ...filterRef.current.value });
				setTemplates((prev) => [...prev, ...result.templates]);
				setTotalPages(result.totalPages);
				setPageNumber((prev) => prev + 1);
			} else {
				toast.error('No more resources to load');
			}
			setLoadingMore(false);
		} catch (error) {
			setLoadingMore(false);
		}
	}

	async function initializeData() {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchTemplates({ userId: user.id, page: initialPageNumber, type: 'file' });
			setTemplates(result.templates);
			setTotalPages(result.totalPages);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}

	useEffect(() => {
		if (pageStatus === 'idle') {
			initializeData();
		}
	}, []);

	const renderContent = () => {
		switch (pageStatus) {
			case 'loading':
				return <Spinner />;
			case 'error':
				return <ErrorComponent />;
			case 'success':
				return templates.length > 0 ? (
					<>
						<S.CardsWrapper>
							{templates.map((template) => (
								<S.CardItem key={template.id} to={{ pathname: `/admin/templates/${template.id}`, state: { backUrl: window.location.pathname, backTitle: 'Back to Templates' } }}>
									<div className="big-icon-wrapper">
										<FontAwesomeIcon icon={FILE_EXTENSIONS.find((ext) => ext.value === template.fileExtension).icon} size="2x" />
									</div>
									<div className="info-wrapper">
										<Typography className="category capitalize" tag="p" variation="3">
											{template.resourceType}
										</Typography>
										<Typography tag="h5" weight="bold">
											{template.fileTitle}
										</Typography>
										<div className="info-wrapper__details-wrapper">
											<div key="type" className="detail-item">
												<div className="icon-wrapper">
													<FontAwesomeIcon icon={FILE_EXTENSIONS.find((ext) => ext.value === template.fileExtension).icon} />
												</div>
												<Typography tag="p" variation="2">
													{FILE_EXTENSIONS.find((ext) => ext.value === template.fileExtension).label}
												</Typography>
											</div>
										</div>
									</div>
									<div className="badges-wrapper">
										<Badge small="small" type={BADGE_STATUS[template.status].color} variant="outlined">
											{BADGE_STATUS[template.status].label}
										</Badge>
									</div>
								</S.CardItem>
							))}
						</S.CardsWrapper>
						{totalPages > pageNumber + 1 && (
							<S.LoadMoreWrapper>
								<Button variant="outline" variation="secondary" type="button" onClick={loadMore}>
									<Typography variation="button-medium" weight="bold">
										{loadingMore ? 'Loading...' : 'Load More'}
									</Typography>
								</Button>
							</S.LoadMoreWrapper>
						)}
					</>
				) : (
					<EmptyComponent title="No available templates" message="Create a new template to get started." icon={['fal', 'file-plus']} />
				);

			default:
				return null;
		}
	};

	return (
		<S.Wrapper>
			<FilterSection
				ref={filterRef}
				onChangeCallback={onChangeCallback}
				filterOptions={['categories', 'careerClusters', 'ageGroups']}
				sortOptions={TEMPLATE_SORT_OPTIONS}
				initialState={{
					searchText: '',
					sortOption: TEMPLATE_SORT_OPTIONS[0],
					showAdvancedFilters: false,
					activeFilters: [],
					categories: [],
					careerClusters: [],
					ageGroups: [],
				}}
			/>
			{renderContent()}
		</S.Wrapper>
	);
};

FilesTab.propTypes = {};
