import styled, { css } from 'styled-components';

import colors from '../../styles/colors';

export const Form = styled.form`
	position: relative;
	padding: 3rem 1.25rem 0;

	.close-button {
		position: absolute;
		top: 1rem;
		right: 1rem;
		color: ${colors.neutralButton};
	}
`;

export const InfoWrapper = styled.div`
	margin: 0 auto 0.75rem;
	width: 100%;
	max-width: 34.5rem;

	& > * + * {
		margin-top: 0.75rem;
	}
`;

export const SingleChoiceWrapper = styled.div`
	.radio-wrapper {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		width: 100%;
		margin: 1rem auto 0;
		position: relative;

		.radio-field {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			position: relative;
			width: 100%;
			max-width: 16rem;
			height: 16rem;
			padding: 1rem;
			margin: 0.75rem;
			color: ${colors.neutralText};
			z-index: 0;

			&.template-field {
				min-height: 16rem;
				height: auto;
				max-height: none;
			}

			h5 {
				color: black;
			}

			h5,
			p {
				pointer-events: none;
				z-index: 1;
			}

			& > * + * {
				margin-top: 0.5rem;
			}

			svg {
				pointer-events: none;
				margin-bottom: 0.5rem;
				z-index: 1;
			}

			.radio-input {
				position: absolute;
				border: 1px solid ${colors.neutralForm};
				border-radius: 0.375rem;
				appearance: none;
				width: 100%;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				inset: 0;
				cursor: pointer;

				&:checked {
					background-color: rgba(234, 236, 244, 0.4);
					border-width: 2px;
					border-color: ${colors.brandPrimary};
				}
			}

			&.template {
				justify-content: flex-start;
				align-items: flex-start;
				padding: 0.75rem;

				.thumbnail {
					pointer-events: none;
					border-radius: 0.375rem;
					margin-top: 0;
				}

				p {
					margin-top: 0.125rem;
				}
			}

			&.with-error {
				color: ${colors.stateDanger};

				h5 {
					color: ${colors.stateDanger};
				}

				.radio-input {
					border-color: ${colors.stateDanger};
				}
			}

			.radio-input:checked ~ svg,
			.radio-input:checked ~ h5 {
				color: ${colors.brandPrimary};
			}
			.radio-input:checked ~ p {
				color: black;
			}
		}

		.error-wrapper {
			position: absolute;
			bottom: -0.75rem;
			color: ${colors.stateDanger};
			left: 0.75rem;
		}
	}
`;

export const FieldsWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	gap: 1.5rem;
	width: 100%;
	max-width: 29rem;
	margin: 2rem auto 0;
`;

export const HugeImageUploader = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;

	label {
		color: ${colors.neutralText};
		cursor: pointer;
	}

	.reset-btn {
		appearance: none;
		border: none;
		border-radius: 0.375rem;
		cursor: pointer;
		background-color: ${colors.brandPrimary};
		color: white;
		display: grid;
		place-content: center;
		width: 2.25rem;
		height: 2.25rem;
		position: absolute;
		top: 2.25rem;
		right: 1rem;

		svg {
			width: 1.125rem;
			height: 1.125rem;
		}

		&:hover {
			opacity: 0.8;
		}
	}

	${({ withError }) =>
		withError &&
		css`
			label {
				color: ${colors.stateDanger};
			}

			${FileDropZone} {
				border-color: ${colors.stateDanger};
			}
		`}

	.error-wrapper {
		position: absolute;
		bottom: -1rem;
		color: ${colors.stateDanger};
	}
`;

export const FileDropZone = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	border: 1px dashed ${colors.neutralText};
	height: 25rem;
	border-radius: 0.375rem;
	cursor: pointer;

	overflow: hidden;

	img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

	${({ isDragActive }) =>
		isDragActive &&
		css`
			opacity: 0.8;
		`}

	${({ isDragAccept }) =>
		isDragAccept &&
		css`
			border-color: ${colors.stateSuccess};
		`}

	${({ isDragReject }) =>
		isDragReject &&
		css`
			border-color: ${colors.stateDanger};
		`}
`;

export const CheckboxListWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 1rem;
	position: relative;

	.field-label {
		color: ${colors.neutralText};

		&.with-error {
			color: ${colors.stateDanger};
		}
	}

	.checkbox-field {
		display: flex;
		align-items: center;

		& > * + * {
			padding-left: 0.75rem;
		}

		label {
			font-size: 1rem;
			line-height: 1.375rem;
			font-weight: 400;
			color: black;
			cursor: pointer;
		}
	}

	.error-wrapper {
		color: ${colors.stateDanger};
	}

	@media screen and (min-width: 768px) {
		grid-template-columns: 1fr 1fr;

		.field-label {
			grid-column: 1 / 3;
		}
	}
`;

export const RadioOptionsWrapper = styled.div`
	display: grid;
	gap: 1rem;
	position: relative;

	.field-label {
		color: ${colors.neutralText};

		&.with-error {
			color: ${colors.stateDanger};
		}
	}

	.radio-field {
		display: flex;
		align-items: center;

		& > * + * {
			margin-left: 0.75rem;
		}
	}
`;

export const FormPanel = styled.div`
	display: flex;
	flex-direction: column;

	& > * + * {
		margin-top: 0.75rem;
	}

	.field-label {
		color: ${colors.neutralText};

		&.with-error {
			color: ${colors.stateDanger};
		}
	}

	.error-wrapper {
		position: absolute;
		bottom: -1.25rem;
		color: ${colors.stateDanger};
	}

	.fields-wrapper {
		display: grid;
		grid-template-columns: 1fr;
		gap: 1.5rem;
		width: 100%;
		max-width: 41rem;
		margin: 1.875rem auto 0;

		.review {
			&__step-item {
				display: grid;
				grid-template-columns: 1fr;
				gap: 1.5rem;
				margin-top: 1rem;

				&:first-child {
					margin-top: 0;
				}
			}

			&__info-item {
				& > h6 {
					color: ${colors.neutralText};
					margin-bottom: 0.375em;
				}

				&--with-badge {
					& > h6 {
						margin-bottom: calc(0.375em + 0.25rem);
					}
				}

				.badge-wrapper {
					display: flex;
					flex-wrap: wrap;
					margin: -0.25rem;

					.small-badge {
						padding: 0.25rem 0.5rem;
						border-radius: 6.25rem;
						border: 1px solid ${colors.brandPrimary};
						font-size: 0.75rem;
						color: ${colors.brandPrimary};
						font-weight: 700;
						margin: 0.25rem;
					}
				}
			}

			&__overview {
				& > h3 {
					margin-bottom: -1rem;
				}
			}

			&__cover-image {
				border: 1px solid ${colors.neutralForm};
				border-radius: 0.375rem;
			}

			&__file-item {
				display: grid;
				grid-template-columns: 1fr;
				gap: 1rem;
			}

			&__file-detail {
				display: flex;
				align-items: center;

				& > svg {
					color: ${colors.neutralText};
				}

				& > div {
					margin-left: 0.5rem;

					& > p:last-child {
						color: ${colors.neutralText};
					}
				}
			}

			&__location-item {
				display: flex;

				.location-icon-wrapper {
					display: flex;
					justify-content: center;
					margin-right: 0.5rem;
					width: 1.5rem;
					color: black;
				}

				.location-info-wrapper {
					flex: 1;
					display: grid;
					grid-template-columns: 1fr;
					gap: 1rem;

					.custom-margin {
						margin-top: 1rem;
					}
				}
			}
		}
	}

	.radio-wrapper {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		width: 100%;
		margin: 1rem auto 0;
		position: relative;

		.radio-field {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			position: relative;
			width: 100%;
			max-width: 16rem;
			height: 16rem;
			padding: 1rem;
			margin: 0.75rem;
			color: ${colors.neutralText};
			z-index: 0;

			&.template-field {
				min-height: 16rem;
				height: auto;
				max-height: none;
			}

			h5 {
				color: black;
			}

			h5,
			p {
				pointer-events: none;
				z-index: 1;
			}

			& > * + * {
				margin-top: 0.5rem;
			}

			svg {
				pointer-events: none;
				margin-bottom: 0.5rem;
				z-index: 1;
			}

			.radio-input {
				position: absolute;
				border: 1px solid ${colors.neutralForm};
				border-radius: 0.375rem;
				appearance: none;
				width: 100%;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				inset: 0;
				cursor: pointer;

				&:checked {
					background-color: rgba(234, 236, 244, 0.4);
					border-width: 2px;
					border-color: ${colors.brandPrimary};
				}
			}

			&.template {
				justify-content: flex-start;
				align-items: flex-start;
				padding: 0.75rem;

				.thumbnail {
					pointer-events: none;
					border-radius: 0.375rem;
					margin-top: 0;
				}

				p {
					margin-top: 0.125rem;
				}
			}

			&.with-error {
				color: ${colors.stateDanger};

				h5 {
					color: ${colors.stateDanger};
				}

				.radio-input {
					border-color: ${colors.stateDanger};
				}
			}

			.radio-input:checked ~ svg,
			.radio-input:checked ~ h5 {
				color: ${colors.brandPrimary};
			}
			.radio-input:checked ~ p {
				color: black;
			}
		}

		.error-wrapper {
			position: absolute;
			bottom: -0.75rem;
			color: ${colors.stateDanger};
			left: 0.75rem;
		}
	}
`;

export const DaysSelector = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	position: relative;
	gap: 0.5rem;

	.error-wrapper {
		bottom: -1rem;
		position: absolute;
	}

	.days-wrapper {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -0.375rem;
	}

	.day-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		min-width: 3.75rem;
		width: 100%;
		max-width: 3.75rem;
		height: 3.75rem;
		margin: 0.375rem;

		label {
			font-weight: 400;
			text-transform: capitalize;
		}

		input[type='checkbox'] {
			border-radius: 0.25rem;
			border: 1px solid ${colors.neutralForm};
			appearance: none;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			inset: 0;
			cursor: pointer;

			&:checked {
				border-width: 0.125rem;
				border-color: ${colors.brandPrimary};

				& + label {
					color: ${colors.brandPrimary};
					font-weight: 600;
				}
			}
		}
	}
`;

export const ButtonsWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin: 1.75rem -1.25rem 0;
	padding: 1rem;
	border-top: 1px solid ${colors.neutralForm};

	& > * + * {
		margin-left: 1rem;
	}
`;

export const LocationItem = styled.div`
	margin: 0 auto 1rem;
	width: 100%;
	position: relative;

	.location-remove {
		position: absolute;
		right: 0;
		top: 0;
		padding: 0;
		color: ${colors.stateDanger};
	}
`;

export const AddWrapper = styled.div`
	display: flex;
	align-items: center;
	margin: 1rem auto 0;
	width: 100%;

	button {
		margin-left: -1.5625rem;
	}
`;
