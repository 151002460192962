import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { fetchActivity } from '../../../services/activity';
import { Card, Typography, Spinner, ErrorComponent } from '../../../components';
import { formatToRelativeTime } from '../../../utils/time-formatter';
import { formatTemplatedMessage } from '../../../utils/content-formatter';
import * as S from './RecentActivity.styles';

export const RecentActivity = ({ className }) => {
	const [pageStatus, setPageStatus] = useState('idle');
	const [activities, setActivities] = useState([]);

	function createActivityLink(activity) {
		switch (activity.type) {
			case 'businessLocation':
				return '/admin/company';
			default:
				return '/admin/dashboard';
		}
	}

	async function initializeData() {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchActivity({ limit: 5 });
			setActivities(result.activity);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}

	useEffect(() => {
		if (pageStatus === 'idle') {
			initializeData();
		}
	}, []);

	const renderContent = () => {
		switch (pageStatus) {
			case 'loading':
				return <Spinner />;
			case 'error':
				return <ErrorComponent />;
			case 'success':
				return activities.length > 0 ? (
					<S.List>
						{activities.map((activity) => (
							<S.ListItem key={activity.id} to={createActivityLink(activity)}>
								<div className="list-item__thumbnail">
									<FontAwesomeIcon icon={activity.icon} size="lg" />
								</div>
								<div className="list-item__info-wrapper">
									<Typography tag="p" variation="2">
										{formatTemplatedMessage(activity.message, activity.subject, activity.variables)}
									</Typography>
									<Typography tag="p" variation="3" className="list-item__info-wrapper__small-text">
										{formatToRelativeTime(new Date(activity.date.iso))}
									</Typography>
								</div>
							</S.ListItem>
						))}
					</S.List>
				) : (
					<S.EmptyBusinessCard paddingTop={4} paddingBottom={6}>
						<div className="icon-box">
							<FontAwesomeIcon icon={['fal', 'history']} size="4x" />
						</div>
						<Typography tag="h4" weight="bold" center>
							You Have No Recent Activity
						</Typography>
						<Typography tag="p" center>
							Once your account is approved you will be able to view your recent activity here.
						</Typography>
					</S.EmptyBusinessCard>
				);
			default:
				return null;
		}
	};

	return (
		<Card className={className} title="Recent Activity">
			{renderContent()}
		</Card>
	);
};

RecentActivity.propTypes = {
	className: PropTypes.string,
};
