import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { AppointmentDetailsContext } from '../../RequestsAppointmentDetails';
import { RatingStars, Typography } from '../../../../components';
import { bytesToSize } from '../../../../utils/filesize-formatter';

export const StepFive = ({ role }) => {
	const {
		state: {
			request: { reviews },
		},
	} = useContext(AppointmentDetailsContext);

	const { getValues } = useFormContext();

	return (
		<div className="modal-content">
			<Typography className="bigger-title" tag="h1" weight="extrablack" center>
				Review Your Fullfilled Request
			</Typography>
			<Typography tag="p" className="description" center>
				Please look over the review you are about to submit.
			</Typography>

			<div className="fields-wrapper">
				{getValues('fileObjs')?.length > 0 && (
					<div className="review__step-item review__files">
						<Typography tag="h3" weight="bold">
							Files
						</Typography>

						{getValues('fileObjs')?.map((file, index) => (
							<div className="review__file-item" key={file.name}>
								<Typography tag="h5" weight="bold">
									File {index + 1}
								</Typography>

								<div className="review__info-item">
									<Typography tag="h6" weight="semibold">
										File
									</Typography>
									<div className="review__file-detail">
										<FontAwesomeIcon icon={['fal', 'file']} size="lg" />
										<div>
											<Typography tag="p">{file.name}</Typography>
											<Typography tag="p" variation="2">
												{bytesToSize(file.size)}
											</Typography>
										</div>
									</div>
								</div>

								<div className="review__info-item">
									<Typography tag="h6" weight="semibold">
										File Name
									</Typography>
									<Typography tag="p">{file.title}</Typography>
								</div>

								<div className="review__info-item">
									<Typography tag="h6" weight="semibold">
										File Description
									</Typography>
									<Typography tag="p">{file.description}</Typography>
								</div>
							</div>
						))}
					</div>
				)}

				{getValues('linkObjs')?.length > 0 && (
					<div className="review__step-item review__links">
						<Typography tag="h3" weight="bold">
							Links
						</Typography>

						{getValues('linkObjs')?.map((link) => (
							<div className="review__file-item" key={link.url}>
								<Typography tag="h5" weight="bold">
									{link.title}
								</Typography>

								<div className="review__info-item">
									<Typography tag="h6" weight="semibold">
										Link
									</Typography>
									<Typography tag="p">{link.url}</Typography>
								</div>

								<div className="review__info-item">
									<Typography tag="h6" weight="semibold">
										Description
									</Typography>
									<Typography tag="p">{link.description}</Typography>
								</div>
							</div>
						))}
					</div>
				)}

				{reviews.length > 0 && (
					<div className="review__step-item review__businesses">
						<Typography tag="h3" weight="bold">
							Businesses
						</Typography>

						{reviews.map(({ id, business }, index) => (
							<div className="business-review-item smaller" key={id}>
								<div className="business-review-item__image-wrapper">
									<img src={business?.logoObj?.[200]} alt={business?.name} />
								</div>
								<div className="business-review-item__fields-wrapper">
									<Typography tag="h4" weight="bold">
										{business?.name}
									</Typography>
									<RatingStars label="Rating" value={getValues(`reviews.${index}.rating`)} />
									<div className="review__info-item">
										<Typography tag="h6" weight="semibold">
											Comments
										</Typography>
										<Typography tag="p">{getValues(`reviews.${index}.comments`)} </Typography>
									</div>
								</div>
							</div>
						))}
					</div>
				)}

				<div className="review__step-item review__connections">
					<Typography tag="h3" weight="bold">
						Learning Connections
					</Typography>

					<div className="review__info-item review__info-item--with-badge">
						<Typography tag="h6" weight="semibold">
							Employability Skills
						</Typography>
						<div className="badge-wrapper">
							{getValues('employabilitySkills').map((value) => (
								<div className="small-badge" key={value}>
									{value}
								</div>
							))}
						</div>
					</div>

					<div className="review__info-item review__info-item--with-badge">
						<Typography tag="h6" weight="semibold">
							Curriculum Standards
						</Typography>
						<div className="badge-wrapper">
							{getValues('curriculumStandards').map((value) => (
								<div className="small-badge" key={value}>
									{value}
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

StepFive.propTypes = {
	role: PropTypes.string,
};
