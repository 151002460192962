import styled from 'styled-components';

export const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	gap: 1.25rem;
	grid-template-areas:
		'b1'
		'b2'
		'b3'
		'b4';

	.b1 {
		grid-area: b1;
	}
	.b2 {
		grid-area: b2;
	}
	.b3 {
		grid-area: b3;
	}
	.b4 {
		grid-area: b4;
	}

	@media screen and (min-width: 992px) {
		grid-template-columns: repeat(2, 1fr);
		grid-template-areas:
			'b1 b1'
			'b2 b3'
			'b4 b4';
	}
`;
