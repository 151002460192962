import React from 'react';
import { useFormContext } from 'react-hook-form';

import * as S from '../CompleteResourceEditModal.styles';
import { required, urlOnly } from '../../../utils/form-default-errors';
import { TextInput, TextArea } from '../../../components';

export const EventLinkStep = () => {
	const {
		register,
		formState: { errors },
	} = useFormContext();

	return (
		<S.FormPanel>
			<div className="fields-wrapper">
				<TextInput
					label="Link"
					id="link-url"
					error={errors?.eventLink}
					{...register('eventLink', {
						required: required('Link'),
						pattern: urlOnly('Link'),
					})}
				/>
				<TextArea
					label="Link Notes"
					id={'link-notes'}
					error={errors?.eventLinkNotes}
					{...register('eventLinkNotes', {
						required: required('Link Notes'),
					})}
				/>
			</div>
		</S.FormPanel>
	);
};
