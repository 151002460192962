import React from 'react';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';

import { TextArea, Typography } from '../../../../components';
import { required } from '../../../../utils/form-default-errors';

export const StepThree = ({ role }) => {
	const {
		register,
		formState: { errors },
	} = useFormContext();

	return (
		<div className="modal-content">
			<Typography className="bigger-title" tag="h1" weight="extrablack" center>
				Add a Note
			</Typography>
			<Typography tag="p" className="description" center>
				Add any additional notes for this request.
			</Typography>

			<div className="fields-wrapper">
				<TextArea className="huge-textarea" id="notes" label="Notes" placeholder="Add scheduling notes here..." error={errors?.notes} {...register('notes')} />
			</div>
		</div>
	);
};

StepThree.propTypes = {
	role: PropTypes.string,
};
