import React, { forwardRef, useState, useImperativeHandle } from 'react';
import ReactModal from 'react-modal';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { AGE_GROUPS, CAREER_CLUSTERS, COUNTY_OPTIONS, LOCATION_TYPES, RESOURCE_CATEGORIES } from '../../../../constants/general.constants';
import { IconButton, Typography, Button, RangeInput, Select, Checkbox, Toggle } from '../../../components';
import { updateUser } from '../../../app/slices/user/userSlice';
import { updateRequestPreferences } from '../../../services/users';
import { errorHandler } from '../../../services/authService';
import { required } from '../../../utils/form-default-errors';
import * as S from './RequestPreferencesModal.styles';

export const RequestPreferencesModal = forwardRef(({}, ref) => {
	const [isOpen, setIsOpen] = useState(false);
	const dispatch = useDispatch();

	const {
		handleSubmit,
		register,
		reset,
		setValue,
		control,
		formState: { errors, isSubmitting },
	} = useForm();

	const handleOpenModal = () => {
		setIsOpen(true);
	};

	const handleOpenEditModal = (requestPreferences) => {
		setValue('distance', requestPreferences?.distance);
		setValue('counties', requestPreferences?.counties);
		setValue('locationTypes', requestPreferences?.locationTypes);
		setValue('categories', requestPreferences?.resourceCategories);
		setValue('careerClusters', requestPreferences?.careerClusters);
		setValue('ageGroups', requestPreferences?.ageGroups);
		setValue('emailNotifications', requestPreferences?.settings?.emailNotifications);
		setIsOpen(true);
	};

	const handleCloseModal = () => {
		setIsOpen(false);
		setTimeout(() => {
			reset();
		}, 200);
	};

	async function asyncCaller(data) {
		const {
			data: { result },
		} = await updateRequestPreferences(data);
		dispatch(updateUser(result.user));
		handleCloseModal();
	}

	const onSubmit = (e) => {
		handleSubmit(asyncCaller)(e).catch(errorHandler);
	};

	useImperativeHandle(
		ref,
		() => ({
			open: handleOpenModal,
			edit: handleOpenEditModal,
			close: handleCloseModal,
		}),
		[handleOpenModal, handleCloseModal]
	);
	return (
		<ReactModal ref={ref} closeTimeoutMS={200} isOpen={isOpen} onRequestClose={handleCloseModal}>
			<S.Form onSubmit={onSubmit}>
				<IconButton className="close-button" type="button" icon={['fal', 'times']} onClick={handleCloseModal} />
				<div className="modal-content">
					<header className="modal-content__header">
						<Typography tag="h2" weight="extrablack" center>
							Request Preferences
						</Typography>
						<Typography tag="p" center>
							Set your notification preferences for new requests.
						</Typography>
					</header>
					<section className="modal-content__section">
						<Typography tag="h3" weight="bold">
							Location
						</Typography>

						<Controller name="distance" control={control} rules={{ required: required('Distance') }} render={({ field: { onChange, value } }) => <RangeInput error={errors?.distance} label="Distance" id="distance" unit="mi" value={value} onChange={onChange} />} />
						<Controller
							name="counties"
							control={control}
							rules={{ required: required('County') }}
							render={({ field: { onChange, value } }) => (
								<Select
									isMulti
									getOptionValue={(option) => option.code}
									getOptionLabel={(option) => option.name}
									label="County"
									id="counties"
									error={errors?.counties}
									placeholder="Counties"
									options={COUNTY_OPTIONS}
									value={value?.map((val) => COUNTY_OPTIONS.find((c) => c.code === val)) || ''}
									onChange={(val) => onChange(val?.map((val) => val.code))}
								/>
							)}
						/>
						<div className="checkbox-wrapper">
							<Typography className={`field-label${!!errors.locationTypes ? ' with-error' : ''}`} tag="p" variation="2" weight="semibold">
								Location Type
							</Typography>

							{LOCATION_TYPES.filter((type) => type.id !== 'inperson').map(({ id, name }) => (
								<div key={id} className="checkbox-field">
									<Checkbox id={id} value={id} {...register('locationTypes', { required: required('Location Type') })} />
									<label htmlFor={id}>{name}</label>
								</div>
							))}

							{!!errors.locationTypes && (
								<div className="error-wrapper">
									<Typography tag="p" variation="2">
										{errors.locationTypes.message}
									</Typography>
								</div>
							)}
						</div>
					</section>
					<section className="modal-content__section">
						<Typography tag="h3" weight="bold">
							Resource
						</Typography>

						<Controller
							name="categories"
							control={control}
							rules={{ required: required('Resource Category') }}
							render={({ field: { onChange, value } }) => (
								<Select
									isMulti
									label="Resource Category"
									id="categories"
									error={errors?.categories}
									placeholder="Categories"
									options={RESOURCE_CATEGORIES.APPOINTMENT}
									value={value?.map((val) => RESOURCE_CATEGORIES.APPOINTMENT.find((c) => c.value === val)) || ''}
									onChange={(val) => onChange(val?.map((val) => val.value))}
								/>
							)}
						/>

						<div className="checkbox-wrapper">
							<Typography className={`field-label${!!errors.careerClusters ? ' with-error' : ''}`} tag="p" variation="2" weight="semibold">
								Career Cluster
							</Typography>

							{CAREER_CLUSTERS.map(({ value, label }) => (
								<div key={value} className="checkbox-field">
									<Checkbox id={value} value={value} {...register('careerClusters', { required: required('Career Cluster') })} />
									<label htmlFor={value}>{label}</label>
								</div>
							))}

							{!!errors.careerClusters && (
								<div className="error-wrapper">
									<Typography tag="p" variation="2">
										{errors.careerClusters.message}
									</Typography>
								</div>
							)}
						</div>

						<div className="checkbox-wrapper">
							<Typography className={`field-label${!!errors.ageGroups ? ' with-error' : ''}`} tag="p" variation="2" weight="semibold">
								Age Group
							</Typography>

							{AGE_GROUPS.map(({ value, label }) => (
								<div key={value} className="checkbox-field">
									<Checkbox id={value} value={value} {...register('ageGroups', { required: required('Age Group') })} />
									<label htmlFor={value}>{label}</label>
								</div>
							))}

							{!!errors.ageGroups && (
								<div className="error-wrapper">
									<Typography tag="p" variation="2">
										{errors.ageGroups.message}
									</Typography>
								</div>
							)}
						</div>

						<Typography tag="h5" weight="bold">
							Settings
						</Typography>
						<S.ToggleLine>
							<div className="line-info">
								<Typography tag="h5">Receive Email Notifications</Typography>
							</div>
							<div className="toggle-wrapper">
								<Toggle {...register('emailNotifications')} />
							</div>
						</S.ToggleLine>
					</section>
				</div>
				<div className="modal-footer">
					<Button>
						<Typography variation="button-medium" weight="bold">
							{isSubmitting ? 'Loading' : 'Save'}
						</Typography>
					</Button>
				</div>
			</S.Form>
		</ReactModal>
	);
});

RequestPreferencesModal.displayName = 'RequestPreferencesModal';
