import React, { forwardRef, useRef } from 'react';
import ReactSelect from 'react-select';
import AsyncReactSelect from 'react-select/async';
import PropTypes from 'prop-types';

import { Typography } from '../Typography';
import { IconButton } from '../IconButton';
import * as S from './Select.styles';

export const Select = forwardRef(({ containerClassName, placeholder = '', options = [], label, error, size = 'medium', icon, iconBtnOnClick = () => {}, promise = {}, ...rest }, ref) => {
	const selectRef = useRef(null);

	const loadOptions = (inputValue) =>
		new Promise(async (resolve) => {
			try {
				const {
					data: { result },
				} = await promise.function({ ...promise.params, searchText: inputValue });
				resolve(result[promise.key]);
				if (promise.setOptions) promise.setOptions([...new Set([...options, ...result[promise.key]])]);
			} catch (error) {
				errorHandler(error);
				resolve(options);
			}
		});

	return (
		<S.Wrapper error={error} className={containerClassName}>
			{label && (
				<Typography tag="label" weight="semibold" onClick={() => (ref ? ref.current.focus() : selectRef.current.focus())}>
					{label}
				</Typography>
			)}
			{Object.keys(promise).length > 0 ? (
				<AsyncReactSelect ref={ref || selectRef} placeholder={placeholder} cacheOptions loadOptions={loadOptions} defaultOptions={options} {...rest} styles={S.colourStyles({ size, icon, error })} />
			) : (
				<ReactSelect ref={ref || selectRef} placeholder={placeholder} options={options} {...rest} styles={S.colourStyles({ size, icon, error })} />
			)}

			{icon && (
				<S.IconWrapper>
					<IconButton icon={icon} onClick={iconBtnOnClick} size={1} />
				</S.IconWrapper>
			)}
			{error && error.message && (
				<S.FloatingWrapper title={error.message}>
					<Typography tag="p" variation="2">
						{error.message}
					</Typography>
				</S.FloatingWrapper>
			)}
		</S.Wrapper>
	);
});

Select.displayName = 'Select';
Select.propTypes = {
	containerClassName: PropTypes.string,
	options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })),
	placeholder: PropTypes.string,
	label: PropTypes.string,
	error: PropTypes.shape(),
	size: PropTypes.oneOf(['small', 'medium']),
	icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
	iconBtnOnClick: PropTypes.func,
	promise: PropTypes.shape({
		function: PropTypes.func,
		params: PropTypes.object,
		key: PropTypes.string,
		setOptions: PropTypes.func,
	}),
};
