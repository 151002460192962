import React from 'react';

import { MembershipCard } from './MembershipCard';
import * as S from './OverviewTab.styles';

export const OverviewTab = () => {
	function renderContent() {
		return (
			<S.Wrapper>
				<MembershipCard className="b1" />
			</S.Wrapper>
		);
	}

	return renderContent();
};
