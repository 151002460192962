import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { fetchMetrics } from '../../../../services/metrics';
import { Card, Typography, Spinner, ErrorComponent } from '../../../../components';
import * as S from './MetricsCards.styles';
import { SOURCES } from '../../../../../constants/general.constants';
import { useParams } from 'react-router-dom';

export const MetricsCards = ({ className }) => {
	const { id: schoolId } = useParams();
	const [loadingStatus, setLoadingStatus] = useState('idle');
	const [metrics, setMetrics] = useState([]);

	async function initializeData() {
		setLoadingStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchMetrics({ source: SOURCES.SCHOOL, sourceId: schoolId });
			setMetrics(result.metrics);
			setLoadingStatus('success');
		} catch (error) {
			setLoadingStatus('error');
		}
	}

	useEffect(() => {
		if (loadingStatus === 'idle') {
			initializeData();
		}
	}, []);

	const renderContent = () => {
		switch (loadingStatus) {
			case 'loading':
				return <Spinner />;
			case 'error':
				return <ErrorComponent />;
			case 'success':
				return (
					<>
						<Card className="metric-card">
							<Typography tag="h1" weight="extrablack">
								{metrics?.teacherCount}
							</Typography>
							<Typography tag="h6" weight="semibold">
								Teachers
							</Typography>
						</Card>
						<Card className="metric-card">
							<Typography tag="h1" weight="extrablack">
								{metrics?.bookingCount}
							</Typography>
							<Typography tag="h6" weight="semibold">
								Bookings
							</Typography>
						</Card>
						<Card className="metric-card">
							<Typography tag="h1" weight="extrablack">
								{metrics?.requestCount}
							</Typography>
							<Typography tag="h6" weight="semibold">
								Requests
							</Typography>
						</Card>
						<Card className="metric-card">
							<Typography tag="h1" weight="extrablack">
								{metrics?.groupCount}
							</Typography>
							<Typography tag="h6" weight="semibold">
								Groups
							</Typography>
						</Card>
					</>
				);
			default:
				return null;
		}
	};

	return <S.CardWrapper className={className}>{renderContent()}</S.CardWrapper>;
};

MetricsCards.propTypes = {
	className: PropTypes.string,
};
