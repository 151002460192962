import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import { TeacherNavigation, PageHeader, Tab, PublicNavigation, AdminNavigation, BusinessNavigation } from '../../components';

import { ROLES } from '../../../constants/general.constants';
import { ResourcesTab } from './ResourcesTab';
import { BookingsTab } from './BookingsTab';
import { InformationTab } from './InformationTab';
import { RequestsTab } from './RequestsTab';
import { EngagementsTab } from './EngagementsTab';
import { META_TITLE } from '../../../constants/general.constants';
import * as S from './Reports.styles';

const Reports = ({ role }) => {
	useEffect(() => {
		// Set document title
		document.title = role != null ? `Reports | Dashboard | ${META_TITLE}` : `Reports | ${META_TITLE}`;
	}, []);

	const [tabs, setTabs] = useState([
		{
			id: 1,
			label: 'Resources',
			component: <ResourcesTab role={role} />,
		},
		{
			id: 2,
			label: 'Requests',
			component: <RequestsTab role={role} />,
		},
		{
			id: 3,
			label: 'Bookings',
			component: <BookingsTab role={role} />,
		},
		{
			id: 4,
			label: 'Information',
			component: <InformationTab role={role} />,
		},
		{
			id: 5,
			label: 'Engagements',
			component: <EngagementsTab role={role} />,
		},
	]);

	const exportReportPDF = () => {
		const input = document.getElementById('printArea');
		html2canvas(input, { scrollX: 0, scrollY: 0 }).then((canvas) => {
			const imgData = canvas.toDataURL('image/png');
			var pdf = new jsPDF('p', 'pt', [canvas.width, canvas.height]);
			var pdfWidth = pdf.internal.pageSize.getWidth();
			var pdfHeight = pdf.internal.pageSize.getHeight();
			pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
			const filename = `${tabs.find((tab) => tab.active === true).label.toLowerCase()}-report-${new Date().toJSON().slice(0, 10)}.pdf`;
			pdf.setProperties({
				title: filename,
			});
			window.open(pdf.output('bloburl', { filename }));
		});
	};

	switch (role) {
		case ROLES.TEACHER:
			return (
				<TeacherNavigation>
					<PageHeader
						title="Reports"
						action={[
							{
								id: 1,
								onClick: () => {
									exportReportPDF();
								},
								label: 'Export Current Report',
								icon: ['fal', 'download'],
							},
						]}
					/>
					<div id="printArea">
						<S.Wrapper>
							<Tab tabs={tabs} setTabs={setTabs} />
						</S.Wrapper>
					</div>
				</TeacherNavigation>
			);
		case ROLES.BUSINESS:
			return (
				<BusinessNavigation>
					<PageHeader
						title="Reports"
						action={[
							{
								id: 1,
								onClick: () => {
									exportReportPDF();
								},
								label: 'Export Current Report',
								icon: ['fal', 'download'],
							},
						]}
					/>
					<div id="printArea">
						<S.Wrapper>
							<Tab tabs={tabs} setTabs={setTabs} />
						</S.Wrapper>
					</div>
				</BusinessNavigation>
			);
		case ROLES.ADMIN:
		case ROLES.SUPER:
			return (
				<AdminNavigation>
					<PageHeader
						title="Reports"
						action={[
							{
								id: 1,
								onClick: () => {
									exportReportPDF();
								},
								label: 'Export Current Report',
								icon: ['fal', 'download'],
							},
						]}
					/>
					<div id="printArea">
						<S.Wrapper>
							<Tab tabs={tabs} setTabs={setTabs} />
						</S.Wrapper>
					</div>
				</AdminNavigation>
			);
		default:
			return (
				<PublicNavigation>
					<PageHeader
						title="Reports"
						action={[
							{
								id: 1,
								onClick: () => {
									exportReportPDF();
								},
								label: 'Export Current Report',
								icon: ['fal', 'download'],
							},
						]}
					/>
					<div id="printArea">
						<S.Wrapper>
							<Tab tabs={tabs} setTabs={setTabs} />
						</S.Wrapper>
					</div>
				</PublicNavigation>
			);
	}
};

Reports.propTypes = {
	role: PropTypes.string,
};

export default Reports;
