import styled from 'styled-components';

import colors from '../../styles/colors';

export const ModalFormWrapper = styled.form`
	display: flex;
	flex-direction: column;
	position: relative;
	height: 100%;

	.close-button {
		position: absolute;
		top: 1rem;
		right: 1rem;
		color: ${colors.neutralButton};
	}

	.modal-content {
		display: block;
		height: 100%;
		overflow: auto;
		padding: 3rem 1rem 1rem;

		.bigger-title {
			overflow: hidden;
		}

		.description {
			margin-top: 0.5rem;
		}

		.field-label {
			color: ${colors.neutralText};

			&.with-error {
				color: ${colors.stateDanger};
			}
		}

		.checkbox-field,
		.radio-field {
			display: flex;
			align-items: center;

			& > * + * {
				padding-left: 0.75rem;
			}

			label {
				font-size: 1rem;
				line-height: 1.375rem;
				font-weight: 400;
				color: black;
				cursor: pointer;
			}
		}

		.error-wrapper {
			position: absolute;
			bottom: -1rem;
			color: ${colors.stateDanger};
		}
	}

	.modal-footer {
		display: flex;
		justify-content: flex-end;
		padding: 1.5rem;
		border-top: 1px solid ${colors.neutralForm};
	}
`;

export const GradeLevelWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 1rem;
	position: relative;

	@media screen and (min-width: 768px) {
		grid-template-columns: 1fr 1fr;

		.field-label {
			grid-column: 1 / 3;
		}
	}
`;

export const ClassWrapper = styled.div`
	display: grid;
	gap: 1rem;
	position: relative;

	.radio-wrapper {
		display: flex;
		flex-direction: column;

		& > * + * {
			margin-top: 0.75rem;
		}
	}
`;

export const ClassOptionsWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	gap: 1.25rem;

	@media screen and (min-width: 768px) {
		grid-template-columns: 1fr 1fr;
	}
`;

export const ModalForm = styled.div`
	display: grid;
	gap: 2rem;
	margin: 2rem auto 1rem;
	width: 100%;
	max-width: 41.875rem;
`;

export const StudentsFormWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	gap: 1.5rem;
	margin: 2rem auto 1rem;
	width: 100%;
	max-width: 41.875rem;

	.grid-line {
		display: grid;
		row-gap: 0.5rem;
		column-gap: 1.25rem;

		.span-3 {
			grid-column: span 3;
		}

		&.student-overview,
		&.age-range {
			grid-template-columns: 1fr;

			@media screen and (min-width: 768px) {
				grid-template-columns: repeat(3, 1fr);

				.grid-title {
					grid-column: 1 / 4;
				}
			}
		}
	}
`;

export const BigFieldset = styled.div`
	display: flex;
	flex-direction: column;

	& > * + * {
		margin-top: 0.5rem;
	}
`;

export const BigBox = styled.div`
	border: 1px solid ${colors.neutralForm};
	border-radius: 0.375rem;
	padding: 0.75rem;

	& > * + * {
		margin-top: 0.5rem;
	}
`;

export const BigBoxHeader = styled.div`
	display: flex;
	background-color: ${colors.neutralBackground};
	color: ${colors.neutralText};
	border-radius: 0.25rem;
	padding: 0.5rem 0.75rem;

	> h6 {
		flex: 1;

		&:last-child {
			width: 100%;
			max-width: 9.375rem;
		}
	}
`;

export const BigBoxLine = styled.div`
	display: flex;
	align-items: center;
	padding: 0.625rem 0.75rem;

	> p {
		flex: 1;
	}

	.box-field {
		width: 100%;
		max-width: 9.375rem;
	}
`;

export const BigBoxFooter = styled.div`
	display: flex;
	padding: 1rem 0.75rem 0.25rem;
	border-top: 1px solid ${colors.neutralForm};

	> p {
		flex: 1;

		&:last-child {
			width: 100%;
			max-width: 9.375rem;
			padding-left: 0.75rem;
			color: ${colors.stateSuccess};
		}

		&.warning-text {
			color: ${colors.stateWarning};
		}
	}
`;
