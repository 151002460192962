import React, { useContext } from 'react';

import { TextInput } from '../../../../components';
import { ResourcesContext } from '../ResourcesTab';
import * as S from './FilterSection.styles';

export const FilterSection = () => {
	const { state, dispatch } = useContext(ResourcesContext);
	const { searchInputValue } = state;

	return (
		<S.Wrapper>
			<div className="filter-wrapper">
				<div className="filter-wrapper__search-input-wrapper">
					<TextInput value={searchInputValue} size="small" placeholder="Search" icon={['fal', 'search']} onChange={(e) => dispatch({ type: 'SET_SEARCH_INPUT_VALUE', payload: e.target.value })} />
				</div>
			</div>
		</S.Wrapper>
	);
};
