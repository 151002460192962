import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Picture, Typography } from '../../../components';
import { bytesToSize } from '../../../utils/filesize-formatter';
import { GRADE_LEVELS } from '../../../../constants/general.constants';
import * as S from '../AdminCreateTemplate.styles';

export const StepReview = () => {
	const { getValues } = useFormContext();

	const renderCoverImage = (image) => {
		if (image) {
			if (image instanceof File) {
				return <Picture className="review__cover-image" src={URL.createObjectURL(image)} aspectRatio="3/2" />;
			} else {
				return <Picture className="review__cover-image" src={image[1500]} aspectRatio="3/2" />;
			}
		}
		return null;
	};

	return (
		<S.FormPanel>
			<Typography tag="h2" weight="extrablack" center>
				Review
			</Typography>
			<Typography tag="p" center>
				Please look over the request you are about to create.
			</Typography>

			<div className="fields-wrapper">
				{getValues('type') === 'file' ? (
					<div className="review__step-item review__overview">
						<Typography tag="h3" weight="bold">
							File Overview
						</Typography>

						<div className="review__file-item">
							<div className="review__info-item">
								<Typography tag="h6" weight="semibold">
									File
								</Typography>
								<div className="review__file-detail">
									<FontAwesomeIcon icon={['fal', 'file']} size="lg" />
									<div>
										<Typography tag="p">{getValues('uploadFile')?.name}</Typography>
										<Typography tag="p" variation="2">
											{bytesToSize(getValues('uploadFile').size)}
										</Typography>
									</div>
								</div>
							</div>

							<div className="review__info-item">
								<Typography tag="h6" weight="semibold">
									File Name
								</Typography>
								<Typography tag="p">{getValues('uploadFile').title}</Typography>
							</div>

							<div className="review__info-item">
								<Typography tag="h6" weight="semibold">
									File Description
								</Typography>
								<Typography tag="p">{getValues('uploadFile').description}</Typography>
							</div>
						</div>
					</div>
				) : (
					<div className="review__step-item review__overview">
						<Typography tag="h3" weight="bold">
							Overview
						</Typography>

						{renderCoverImage(getValues('coverImage'))}

						<div className="review__info-item">
							<Typography tag="h6" weight="semibold">
								Resource Type
							</Typography>
							<Typography tag="p" className="capitalize">
								{getValues('resourceType')}
							</Typography>
						</div>

						<div className="review__info-item">
							<Typography tag="h6" weight="semibold">
								Resource Category
							</Typography>
							<Typography tag="p">{getValues('category')}</Typography>
						</div>

						<div className="review__info-item">
							<Typography tag="h6" weight="semibold">
								Resource Title
							</Typography>
							<Typography tag="p">{getValues('title')}</Typography>
						</div>

						<div className="review__info-item">
							<Typography tag="h6" weight="semibold">
								Description
							</Typography>
							<Typography tag="p">{getValues('description')}</Typography>
						</div>

						<div className="review__info-item">
							<Typography tag="h6" weight="semibold">
								Grade Level
							</Typography>
							<Typography tag="p">
								{GRADE_LEVELS.filter((gradeLevel) => getValues('gradeLevels').includes(gradeLevel.value))
									.map((gradeLevel) => gradeLevel.label)
									.join(', ')}
							</Typography>
						</div>

						<div className="review__info-item review__info-item--with-badge">
							<Typography tag="h6" weight="semibold">
								Career Cluster
							</Typography>
							<div className="badge-wrapper">
								{getValues('careerClusters').map((value) => (
									<div className="small-badge" key={value}>
										{value}
									</div>
								))}
							</div>
						</div>
					</div>
				)}
			</div>
		</S.FormPanel>
	);
};
