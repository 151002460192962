import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { updateUser } from '../../app/slices/user/userSlice';
import { errorHandler, loginUser } from '../../services/authService';
import { pattern, required } from '../../utils/form-default-errors';
import { EMAIL } from '../../utils/common-regex';
import logo from '../../assets/ncbce-logo-alt.png';
import { Typography, Button, TextInput, Link } from '../../components';
import * as S from './BusinessLoginForm.styles';
import { META_TITLE, ROLES } from '../../../constants/general.constants';

const BusinessLoginForm = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitting },
	} = useForm();

	useEffect(() => {
		// Set document title
		document.title = `Log In | ${META_TITLE}`;
	}, []);

	async function asyncCaller(data) {
		const {
			data: { user },
		} = await loginUser({ username: data.email, password: data.password });

		dispatch(updateUser(user));

		if (user.role === ROLES.TEACHER) {
			history.push('/teacher/dashboard');
		} else if (user.role === ROLES.BUSINESS) {
			history.push('/business/dashboard');
		} else if (user.role === ROLES.ADMIN || user.role === ROLES.SUPER) {
			history.push('/admin/dashboard');
		}
	}

	function onSubmit(e) {
		handleSubmit(asyncCaller)(e).catch(errorHandler);
	}

	return (
		<S.Wrapper>
			<S.Header>
				<div className="flex-1" />
				<div className="centered">
					<S.Picture>
						<img src={logo} alt="NCBCE logo" />
					</S.Picture>
				</div>
				<div className="flex-1" />
			</S.Header>
			<S.Container>
				<Typography tag="h1" weight="extrablack" center>
					Log In as a Business
				</Typography>
				<Typography tag="p" className="description" center>
					Enter your credentials below.
				</Typography>

				<S.Form onSubmit={onSubmit}>
					<TextInput
						label="Email"
						id="email"
						error={errors.email}
						autoComplete="email"
						{...register('email', {
							required: required('Email'),
							pattern: pattern('Email', EMAIL),
						})}
					/>
					<div>
						<TextInput
							label="Password"
							id="password"
							type="password"
							error={errors.password}
							autoComplete="current-password"
							{...register('password', {
								required: required('Password'),
							})}
						/>

						<S.Link to="/forgot-password">Forgot Password?</S.Link>
					</div>

					<Button type="submit" className="submit-button">
						<Typography variation="button-medium" weight="bold">
							{isSubmitting ? 'Loading...' : 'Login'}
						</Typography>
					</Button>
				</S.Form>
			</S.Container>

			<Typography tag="h6" className="login-message" center>
				<span>Don&apos;t have an account?</span>&nbsp;<Link to="/sign-up">Sign Up</Link>
			</Typography>
		</S.Wrapper>
	);
};

export default BusinessLoginForm;
