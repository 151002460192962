import React, { useRef, useContext } from 'react';

import { AdminCompanyDetailContext } from '../../AdminCompanyDetail';
import { Avatar, EditBusinessModal, Card, Typography } from '../../../../components';
import * as S from './OverviewCard.styles';

export const OverviewCard = () => {
	const {
		state: { company },
		dispatch,
	} = useContext(AdminCompanyDetailContext);

	const editModalRef = useRef();

	function handleEditClick() {
		editModalRef.current.open();
	}

	return (
		<>
			<Card title="Overview" transparentHeaderBorder actions={[{ id: 1, label: 'Edit', size: 'small', variant: 'outline', icon: { source: ['fal', 'edit'] }, onClick: handleEditClick }]}>
				<S.Wrapper>
					<div className="avatar-wrapper">
						<Avatar avatar={company?.logoObj?.[200]} />
					</div>
					<div className="info-wrapper">
						<div className="element-info">
							<Typography className="element-info__title" tag="h6" weight="semibold">
								Company
							</Typography>
							<Typography className="element-info__text" tag="p">
								{company?.name}
							</Typography>
						</div>
						<div className="element-info">
							<Typography className="element-info__title" tag="h6" weight="semibold">
								Bio
							</Typography>
							<Typography className="element-info__text" tag="p">
								{company?.bio}
							</Typography>
						</div>
						{company?.careerClusters?.length > 0 && (
							<div className="element-info">
								<Typography className="element-info__title" tag="h6" weight="semibold">
									Career Cluster
								</Typography>
								<div className="element-info__badges-wrapper">
									{company?.careerClusters.map((careerCluster, index) => (
										<div className="element-info__badge" key={index}>
											{careerCluster}
										</div>
									))}
								</div>
							</div>
						)}
						{company?.memberships?.length > 0 && (
							<div className="element-info">
								<Typography className="element-info__title" tag="h6" weight="semibold">
									Memberships
								</Typography>
								<div className="element-info__badges-wrapper">
									{company?.memberships.map((membership, index) => (
										<div className="element-info__badge" key={index}>
											{membership}
										</div>
									))}
								</div>
							</div>
						)}
						<div className="info-wrapper__grid-wrapper">
							<div className="element-info">
								<Typography className="element-info__title" tag="h6" weight="semibold">
									Website
								</Typography>
								<Typography className="element-info__text" tag="p">
									{company?.website}
								</Typography>
							</div>
							{company?.linkedin && (
								<div className="element-info">
									<Typography className="element-info__title" tag="h6" weight="semibold">
										LinkedIn
									</Typography>
									<Typography className="element-info__text" tag="p">
										{company?.linkedin}
									</Typography>
								</div>
							)}
							{company?.facebook && (
								<div className="element-info">
									<Typography className="element-info__title" tag="h6" weight="semibold">
										Facebook
									</Typography>
									<Typography className="element-info__text" tag="p">
										{company?.facebook}
									</Typography>
								</div>
							)}
							{company?.instagram && (
								<div className="element-info">
									<Typography className="element-info__title" tag="h6" weight="semibold">
										Instagram
									</Typography>
									<Typography className="element-info__text" tag="p">
										{company?.instagram}
									</Typography>
								</div>
							)}
							{company?.twitter && (
								<div className="element-info">
									<Typography className="element-info__title" tag="h6" weight="semibold">
										Twitter
									</Typography>
									<Typography className="element-info__text" tag="p">
										{company?.twitter}
									</Typography>
								</div>
							)}
						</div>
					</div>
				</S.Wrapper>
			</Card>
			<EditBusinessModal ref={editModalRef} company={company} dispatch={dispatch} />
		</>
	);
};
