import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Typography } from '../Typography';
import * as S from './Badge.styles';

const FONT_SIZES_VARIATIONS = {
	small: '3',
	medium: '2',
};

export const Badge = ({ children, size = 'medium', variant = 'solid', variation = 'pill', type = 'primary', icon }) => {
	return (
		<S.Badge size={size} variant={variant} variation={variation} type={type} iconPlacement={icon?.placement || 'left'}>
			{icon && <FontAwesomeIcon className="badge-icon" icon={icon.source} />}
			<Typography tag="span" variation={FONT_SIZES_VARIATIONS[size]} weight="bold">
				{children}
			</Typography>
		</S.Badge>
	);
};

Badge.displayName = 'Badge';
Badge.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	size: PropTypes.oneOf(['small', 'medium']),
	type: PropTypes.oneOf(['primary', 'neutral', 'danger', 'warning', 'success']),
	variant: PropTypes.oneOf(['solid', 'outlined']),
	variation: PropTypes.oneOf(['square', 'pill']),
	icon: PropTypes.shape({
		source: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
		placement: PropTypes.oneOf(['left', 'right']),
	}),
};
