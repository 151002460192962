import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { buildAddressString } from '../../../../utils/location-methods';
import { Card, Typography, EmptyComponent } from '../../../../components';
import * as S from './AcceptedBusinessesCard.styles';
import { ROLES } from '../../../../../constants/general.constants';

export const AcceptedBusinessesCard = ({ request: { responses }, role }) => {
	const acceptedResponses = responses.filter((response) => response.status === 'accepted');
	return (
		<Card title="Accepted Businesses">
			<S.Wrapper>
				{acceptedResponses.length > 0 ? (
					acceptedResponses.map(({ business }) => (
						<S.Business key={business.id} to={{ pathname: role === ROLES.ADMIN || role === ROLES.SUPER ? `/admin/companies/${business.id}` : `/${role || 'teacher'}/directory/business/${business.id}`, state: { backUrl: window.location.pathname, backTitle: 'Back to Request' } }}>
							<img className="business__avatar" src={business.logoObj['200']} alt="" />
							<div className="business__info">
								<Typography tag="h5" weight="bold">
									{business.name}
								</Typography>
								<div className="business__details">
									<div className="business__element">
										<FontAwesomeIcon icon={['fal', 'globe']} />
										<Typography tag="p" variation="2">
											{business.website}
										</Typography>
									</div>
									{business.locations.length > 0 && (
										<div className="business__element">
											<FontAwesomeIcon icon={['fal', 'map-marker']} />
											<Typography tag="p" variation="2">
												{buildAddressString(business.locations[0].address)}
											</Typography>
										</div>
									)}
									<div className="business__element">
										<FontAwesomeIcon icon={['fal', 'building']} />
										<Typography tag="p" variation="2">
											{business.locations.length === 1 ? '1 Location' : `${business.locations.length} Locations`}
										</Typography>
									</div>
								</div>
							</div>
						</S.Business>
					))
				) : (
					<EmptyComponent title="No accepted businesses" message="When you accept a business response, it will appear here." icon={['fal', 'building']} />
				)}
			</S.Wrapper>
		</Card>
	);
};

AcceptedBusinessesCard.propTypes = {
	request: PropTypes.shape(),
	role: PropTypes.string,
};
