import React, { forwardRef, useRef, useState, useImperativeHandle, useContext } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { useForm } from 'react-hook-form';

import { AdminMembershipDetailContext } from '../../AdminMembershipDetail';
import { Button, Card, IconButton, TextArea, TextInput, Typography } from '../../../../components';
import { errorHandler } from '../../../../services/authService';
import { required } from '../../../../utils/form-default-errors';
import * as S from './MembershipCard.styles';
import { createUpdateMembership } from '../../../../services/memberships';

const EditMembershipCard = forwardRef((_props, ref) => {
	const {
		state: { membership },
		dispatch,
	} = useContext(AdminMembershipDetailContext);

	const [isOpen, setIsOpen] = useState(false);
	const {
		handleSubmit,
		setValue,
		register,
		reset,
		formState: { errors, isSubmitting },
	} = useForm();

	const handleOpenModal = () => {
		setValue('name', membership?.name);
		setValue('description', membership?.description);
		setIsOpen(true);
	};

	const handleCloseModal = () => {
		setIsOpen(false);
		setTimeout(() => {
			reset();
		}, 200);
	};

	async function asyncCaller(data) {
		const {
			data: { result },
		} = await createUpdateMembership({ membershipId: membership.id, name: membership.name, description: membership.description, ...data });

		dispatch({ type: 'SET_MEMBERSHIP', payload: result.membership });
		handleCloseModal();
	}

	const onSubmit = (e) => {
		handleSubmit(asyncCaller)(e).catch(errorHandler);
	};

	useImperativeHandle(
		ref,
		() => ({
			open: handleOpenModal,
			close: handleCloseModal,
		}),
		[handleOpenModal, handleCloseModal]
	);

	return (
		<ReactModal className="profile-card-modal" ref={ref} closeTimeoutMS={200} isOpen={isOpen} onRequestClose={handleCloseModal}>
			<S.Form onSubmit={onSubmit}>
				<IconButton className="close-button" type="button" icon={['fal', 'times']} onClick={handleCloseModal} />
				<div className="modal-content">
					<header className="modal-content__header">
						<Typography tag="h2" weight="extrablack" center>
							Edit Membership
						</Typography>
						<Typography tag="p" center>
							Please update your membership information here.
						</Typography>
					</header>

					<section className="modal-content__section">
						<TextInput
							label="Name"
							placeholder="Name"
							error={errors.name}
							{...register('name', {
								required: required('Name'),
							})}
						/>
						<TextArea
							label="Description"
							placeholder="Description"
							error={errors.description}
							{...register('description', {
								required: required('Description'),
							})}
						/>
					</section>

					<div className="modal-footer">
						<Button>
							<Typography variation="button-medium" weight="bold">
								{isSubmitting ? 'Loading' : 'Save'}
							</Typography>
						</Button>
					</div>
				</div>
			</S.Form>
		</ReactModal>
	);
});

EditMembershipCard.displayName = 'EditMembershipCard';

const MembershipCard = ({ className }) => {
	const {
		state: { membership },
	} = useContext(AdminMembershipDetailContext);

	const modalRef = useRef(null);

	const handleCardButtonClick = () => {
		modalRef.current.open();
	};

	return (
		<Card className={className} title="Membership" transparentHeaderBorder={true} actions={[{ id: 1, label: 'Edit', size: 'small', variant: 'outline', icon: { source: ['fal', 'edit'] }, onClick: handleCardButtonClick }]}>
			<S.Wrapper>
				<div className="info-wrapper">
					<div className="element-info">
						<Typography className="element-info__title" tag="h6" weight="semibold">
							Membership Name
						</Typography>
						<Typography className="element-info__text" tag="p">
							{membership?.name}
						</Typography>
					</div>
					<div className="element-info">
						<Typography className="element-info__title" tag="h6" weight="semibold">
							Description
						</Typography>
						<Typography className="element-info__text" tag="p">
							{membership?.description}
						</Typography>
					</div>
				</div>
			</S.Wrapper>
			<EditMembershipCard ref={modalRef} />
		</Card>
	);
};

MembershipCard.propTypes = {
	className: PropTypes.string,
};

export default MembershipCard;
