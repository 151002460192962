import React, { useState, useRef, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { ROLES } from '../../../../../constants/general.constants';
import { Card, Typography, ChangeOwnershipModal } from '../../../../components';
import * as S from './BusinessCard.styles';
import { DirectoryResourceDetailsContext } from '../../DirectoryResourceDetails';

export const BusinessCard = ({ className, role }) => {
	const history = useHistory();
	const {
		state: { resource },
		dispatch,
	} = useContext(DirectoryResourceDetailsContext);
	const changeOwnershipModalRef = useRef();

	function onSuccessCallback(resource) {
		dispatch({ type: 'SET_RESOURCE', payload: resource });
	}

	const cardActionsByRole = () => {
		switch (role) {
			case ROLES.TEACHER:
			case ROLES.BUSINESS:
				return [
					{
						id: 1,
						label: 'View Profile',
						size: 'smaller',
						variant: 'text',
						icon: { source: ['fal', 'arrow-right'], placement: 'right' },
						onClick: () => history.push({ pathname: `/${role || 'teacher'}/directory/business/${resource?.business?.id}`, state: { backUrl: window.location.pathname, backTitle: 'Back to Resource' } }),
					},
				];
			case ROLES.ADMIN:
			case ROLES.SUPER:
				return [
					{
						id: 1,
						label: 'Edit',
						size: 'smaller',
						variant: 'outline',
						icon: { source: ['fal', 'edit'] },
						onClick: () => changeOwnershipModalRef.current.open({ resourceId: resource?.id, businessId: resource?.business?.id }),
					},
					{
						id: 2,
						label: 'View Profile',
						size: 'smaller',
						variant: 'text',
						icon: { source: ['fal', 'arrow-right'], placement: 'right' },
						onClick: () => history.push({ pathname: `/admin/companies/${resource?.business?.id}`, state: { backUrl: window.location.pathname, backTitle: 'Back to Resource' } }),
					},
				];
			default:
				return [];
		}
	};

	const [actions, setActions] = useState([]);

	useEffect(() => {
		setActions(cardActionsByRole());
	}, [resource]);

	return (
		<Card title="Business" className={className} transparentHeaderBorder actions={actions}>
			<S.Wrapper>
				<div className="business-info">
					<img className="business-info__avatar" src={resource?.business?.logoObj?.['200']} alt={`${resource?.business?.name} profile`} />
					<div className="business-info__details">
						<Typography className="business-info__title" tag="p" weight="semibold">
							{resource?.business?.name}
						</Typography>
						<Typography className="business-info__subtitle" tag="p" variation="2">
							{resource?.business?.website}
						</Typography>
					</div>
				</div>
				<div className="business-bio">
					<Typography className="business-bio__title" tag="h6" weight="semibold">
						Bio
					</Typography>
					<Typography tag="p">{resource?.business?.bio}</Typography>
				</div>
				<div className="business-career-cluster">
					<Typography className="business-career-cluster__title" tag="h6" weight="semibold">
						Career Clusters
					</Typography>
					<div className="business-career-cluster__badges-wrapper">
						{resource?.business?.careerClusters?.map((careerCluster, index) => (
							<div className="business-career-cluster__badge" key={index}>
								{careerCluster}
							</div>
						))}
					</div>
				</div>
				{(role === ROLES.ADMIN || role === ROLES.SUPER) && <ChangeOwnershipModal ref={changeOwnershipModalRef} onSuccessCallback={onSuccessCallback} />}
			</S.Wrapper>
		</Card>
	);
};

BusinessCard.propTypes = {
	className: PropTypes.string,
	role: PropTypes.string,
};
