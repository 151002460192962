import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDebouncedFn } from 'beautiful-react-hooks';
import toast from 'react-hot-toast';

import { RESOURCE_SORT_OPTIONS, META_TITLE } from '../../../constants/general.constants';
import { fetchSavedResources, saveUnsaveResource } from '../../services/directory';
import { TeacherNavigation, PageHeader, Spinner, ErrorComponent, FilterSection, Button, Typography } from '../../components';
import { SavedCardsGrid } from './SavedCardsGrid';
import * as S from './TeacherSaved.styles';

const TeacherSaved = () => {
	const history = useHistory();
	const [pageStatus, setPageStatus] = useState('idle');
	const [savedResources, setSavedResources] = useState([]);

	const initialPageNumber = 0;
	const [pageNumber, setPageNumber] = useState(initialPageNumber);
	const [totalPages, setTotalPages] = useState(undefined);
	const [totalItems, setTotalItems] = useState(0);
	const [loadingMore, setLoadingMore] = useState(false);

	const filterRef = useRef();

	const handleSaveUnsave = async (id) => {
		try {
			await saveUnsaveResource({ resourceId: id });
			let newResources = [...savedResources];
			newResources = newResources.filter((item) => item.id !== id);
			setSavedResources(newResources);
		} catch (error) {}
	};

	const onChangeCallback = useDebouncedFn(async () => {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchSavedResources({ page: 0, ...filterRef.current.value });
			setSavedResources(result.resources);
			setPageNumber(initialPageNumber);
			setTotalPages(result.totalPages);
			setTotalItems(result.totalItems);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}, 300);

	async function loadMore() {
		setLoadingMore(true);
		try {
			if (totalPages > pageNumber + 1) {
				const {
					data: { result },
				} = await fetchSavedResources({ page: pageNumber + 1, ...filterRef.current.value });
				setSavedResources((prev) => [...prev, ...result.resources]);
				setTotalPages(result.totalPages);
				setTotalItems(result.totalItems);
				setPageNumber((prev) => prev + 1);
			} else {
				toast.error('No more resources to load');
			}
			setLoadingMore(false);
		} catch (error) {
			setLoadingMore(false);
		}
	}

	async function initializeData() {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchSavedResources({ page: 0 });
			setSavedResources(result.resources);
			setTotalPages(result.totalPages);
			setTotalItems(result.totalItems);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}

	useEffect(() => {
		// Set document title
		document.title = `Saved Resources | Dashboard | ${META_TITLE}`;

		// Load initial data
		if (pageStatus === 'idle') {
			initializeData();
		}
	}, []);

	const renderContent = () => {
		switch (pageStatus) {
			case 'loading':
				return <Spinner />;
			case 'error':
				return <ErrorComponent />;
			case 'success':
				return (
					<>
						<SavedCardsGrid data={savedResources} handleSaveUnsave={handleSaveUnsave} totalItems={totalItems} />
						{savedResources?.length > 0 && totalPages > pageNumber + 1 && (
							<S.LoadMoreWrapper>
								<Button variant="outline" variation="secondary" type="button" onClick={loadMore}>
									<Typography variation="button-medium" weight="bold">
										{loadingMore ? 'Loading...' : 'Load More'}
									</Typography>
								</Button>
							</S.LoadMoreWrapper>
						)}
					</>
				);
			default:
				return null;
		}
	};

	return (
		<TeacherNavigation>
			<PageHeader title="Saved" action={[{ id: 1, onClick: () => history.push('/teacher/create-request'), label: 'Create Request', icon: ['fal', 'plus'] }]} />
			<S.Wrapper>
				<FilterSection
					ref={filterRef}
					onChangeCallback={onChangeCallback}
					initialState={{
						searchText: '',
						sortOption: RESOURCE_SORT_OPTIONS[2],
						showAdvancedFilters: false,
						activeFilters: [],
						distance: 0,
						counties: [],
						locationTypes: [],
						categories: [],
						careerClusters: [],
						ageGroups: [],
						employabilitySkills: [],
						curriculumStandards: [],
					}}
					sortOptions={RESOURCE_SORT_OPTIONS}
				/>
				{renderContent()}
			</S.Wrapper>
		</TeacherNavigation>
	);
};

export default TeacherSaved;
