import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';

import * as S from '../../CreateRequest.styles';
import { Typography, Spinner, Select } from '../../../../components';
import { fetchGroups } from '../../../../services/groups';
import { required } from '../../../../utils/form-default-errors';
import { GRADE_LEVELS } from '../../../../../constants/general.constants';

export const GroupStep = ({ role }) => {
	const [pageStatus, setPageStatus] = useState('idle');
	const [groups, setGroups] = useState([]);

	const {
		control,
		watch,
		formState: { errors },
	} = useFormContext();

	const selectedGroup = groups.find(({ groupId }) => groupId === watch('groupId'));

	const renderTemplate = () => {
		switch (pageStatus) {
			case 'loading':
				return <Spinner />;
			case 'success':
				return groups.length > 0 ? (
					<>
						<Controller
							name="groupId"
							control={control}
							rules={{ required: required('Group') }}
							render={({ field: { onChange, value } }) => (
								<Select
									getOptionLabel={(option) => option.name}
									getOptionValue={(option) => option.groupId}
									label="Name of Group (i.e class, entire school, grade, etc.)"
									id="group-id"
									error={errors.groupId}
									options={groups}
									value={groups.find((c) => c.groupId === value) || ''}
									onChange={(val) => onChange(val.groupId)}
								/>
							)}
						/>
						{selectedGroup && (
							<S.InformationCard>
								<div className="icon-wrapper">
									<FontAwesomeIcon icon="users" />
								</div>
								<div className="info-wrapper">
									<Typography tag="h5" weight="bold">
										{selectedGroup?.name}
									</Typography>
									<div className="info-line">
										<FontAwesomeIcon icon={['fal', 'users']} />
										<Typography tag="p" variation="2">
											{selectedGroup?.totalStudents} Students
										</Typography>
									</div>
									<div className="info-line">
										<FontAwesomeIcon icon={['fal', 'users-class']} />
										<Typography tag="p" variation="2">
											{GRADE_LEVELS.find((grade) => grade.value === selectedGroup?.gradeLevels[0])?.label}
										</Typography>
									</div>
									<div className="info-line">
										<FontAwesomeIcon icon={['fal', 'chalkboard']} />
										<Typography tag="p" variation="2">
											{selectedGroup?.subject}
										</Typography>
									</div>
									<div className="info-line">
										<FontAwesomeIcon icon={['fal', 'book']} />
										<Typography tag="p" variation="2">
											{selectedGroup?.classCode}
										</Typography>
									</div>
								</div>
							</S.InformationCard>
						)}
					</>
				) : (
					<p>Create a group before submitting a request</p>
				);
			default:
				return null;
		}
	};

	async function initializeData() {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchGroups();

			setGroups(result.groups);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}

	useEffect(() => {
		if (pageStatus === 'idle') {
			initializeData();
		}
	}, [pageStatus]);

	return (
		<>
			<Typography tag="h2" weight="extrablack" center>
				Group
			</Typography>
			<Typography tag="p" center>
				Select a group for this request.
			</Typography>
			<div className="fields-wrapper">{renderTemplate()}</div>
		</>
	);
};

GroupStep.propTypes = {
	role: PropTypes.string,
};
