import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Typography } from '../Typography';
import * as S from './TextInput.styles';

export const TextInput = forwardRef(({ id, label, error, type = 'text', size = 'medium', containerClassName, icon, ...rest }, ref) => {
	return (
		<S.Wrapper error={error} className={containerClassName}>
			{label && (
				<Typography tag="label" weight="semibold" htmlFor={id ? `${id}` : null} id={id ? `${id}-label` : null}>
					{label}
				</Typography>
			)}
			{icon && (
				<S.IconWrapper>
					<FontAwesomeIcon icon={icon} />
				</S.IconWrapper>
			)}
			<S.TextInput ref={ref} id={id} error={error} {...rest} type={type} size={size} withIcon={!!icon} />
			{error && error.message && (
				<S.FloatingWrapper title={error.message}>
					<Typography tag="p" variation="2">
						{error.message}
					</Typography>
				</S.FloatingWrapper>
			)}
		</S.Wrapper>
	);
});

TextInput.displayName = 'TextInput';
TextInput.propTypes = {
	icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
	containerClassName: PropTypes.string,
	type: PropTypes.oneOf(['text', 'password', 'email', 'search', 'number']),
	size: PropTypes.oneOf(['small', 'medium']),
	id: PropTypes.string,
	placeholder: PropTypes.string,
	label: PropTypes.string,
	error: PropTypes.shape(),
};
