import React, { useContext, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';

import { AppointmentDetailsContext } from '../../RequestsAppointmentDetails';
import { RatingStars, TextArea, Typography } from '../../../../components';
import { required } from '../../../../utils/form-default-errors';

export const StepTwo = ({ role }) => {
	const {
		state: {
			request: { reviews },
		},
	} = useContext(AppointmentDetailsContext);

	const {
		register,
		control,
		setValue,
		getValues,
		formState: { errors },
	} = useFormContext();

	useEffect(() => {
		const mappedReviews = reviews.map(({ business: { id } }) => ({ businessId: id }));
		const reviewFormValues = [...mappedReviews];
		reviewFormValues.forEach(({ businessId }, index) => {
			reviewFormValues[index].businessId = businessId;
			if (getValues(`reviews.${index}.rating`)) {
				reviewFormValues[index].rating = getValues(`reviews.${index}.rating`);
			}
			if (getValues(`reviews.${index}.comments`)) {
				reviewFormValues[index].comments = getValues(`reviews.${index}.comments`);
			}
		});
		setValue('reviews', reviewFormValues);
	}, [reviews]);

	return (
		<div className="modal-content">
			<Typography className="bigger-title" tag="h1" weight="extrablack" center>
				Businesses
			</Typography>
			<Typography tag="p" className="description" center>
				Please review the businesses who were at the appointment. This is a private review and will not be seen by the business.
			</Typography>

			<div className="fields-wrapper">
				{reviews.map(({ id, business }, index) => (
					<div className="business-review-item" key={id}>
						<div className="business-review-item__image-wrapper">
							<img src={business?.logoObj?.[200]} alt={business?.name} />
						</div>
						<div className="business-review-item__fields-wrapper">
							<Typography tag="h3" weight="bold">
								{business?.name}
							</Typography>
							<Controller name={`reviews.${index}.rating`} control={control} rules={{ required: required('Rating') }} render={({ field: { onChange, value } }) => <RatingStars label="Rating" value={value} onChange={onChange} error={errors?.reviews?.[index]?.rating} />} />
							<TextArea
								label="Comments"
								id={`${index}-comments`}
								error={errors?.reviews?.[index]?.comments}
								{...register(`reviews.${index}.comments`, {
									required: required('Comments'),
								})}
							/>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

StepTwo.propTypes = {
	role: PropTypes.string,
};
