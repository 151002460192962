import axios from 'axios';
import { SESSION_TOKEN_COOKIE } from '../../constants/general.constants';

import { getCookie, removeCookie } from '../utils/cookie-methods';
import { logoutUser } from './authService';

const api = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(async (config) => {
	const token = getCookie(SESSION_TOKEN_COOKIE);

	const headers = { ...config.headers };
	headers['x-parse-application-id'] = process.env.REACT_APP_APP_ID;
	if (token) {
		headers['x-parse-session-token'] = token;
	}
	return { ...config, headers };
});

api.interceptors.response.use(
	(response) => response,
	(error) => {
		if (error?.response?.data?.code === 209) {
			logoutUser().then(() => {
				removeCookie(SESSION_TOKEN_COOKIE);
				window.location.reload();
			});
		}
		return Promise.reject(error);
	}
);

export default api;
