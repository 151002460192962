import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDebouncedFn } from 'beautiful-react-hooks';
import toast from 'react-hot-toast';

import { RESOURCE_SORT_OPTIONS } from '../../../../constants/general.constants';
import { fetchInformationResources } from '../../../services/information';
import { formatGradeLevel } from '../../../utils/content-formatter';
import { Picture, Typography, Spinner, ErrorComponent, EmptyComponent, FilterSection, Button } from '../../../components';
import * as S from './ArchivedTab.styles';

export const ArchivedTab = ({}) => {
	const [pageStatus, setPageStatus] = useState('idle');
	const [resources, setResources] = useState([]);

	const initialPageNumber = 0;
	const [pageNumber, setPageNumber] = useState(initialPageNumber);
	const [totalPages, setTotalPages] = useState(undefined);
	const [loadingMore, setLoadingMore] = useState(false);

	const filterRef = useRef();

	const onChangeCallback = useDebouncedFn(async () => {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchInformationResources({ page: initialPageNumber, filterOptions: { archived: true }, ...filterRef.current.value });
			setResources(result.resources);
			setPageNumber(initialPageNumber);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}, 300);

	async function loadMore() {
		setLoadingMore(true);
		try {
			if (totalPages > pageNumber + 1) {
				const {
					data: { result },
				} = await fetchInformationResources({ page: pageNumber + 1, filterOptions: { archived: true }, ...filterRef.current.value });
				setResources((prev) => [...prev, ...result.resources]);
				setTotalPages(result.totalPages);
				setPageNumber((prev) => prev + 1);
			} else {
				toast.error('No more resources to load');
			}
			setLoadingMore(false);
		} catch (error) {
			setLoadingMore(false);
		}
	}

	async function initializeData() {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchInformationResources({ page: initialPageNumber, filterOptions: { archived: true } });
			setResources(result.resources);
			setTotalPages(result.totalPages);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}

	useEffect(() => {
		// Load initial data
		if (pageStatus === 'idle') {
			initializeData();
		}
	}, [pageStatus]);

	const renderContent = () => {
		switch (pageStatus) {
			case 'loading':
				return <Spinner />;
			case 'error':
				return <ErrorComponent />;
			case 'success':
				return resources.length > 0 ? (
					<>
						<S.CardsWrapper>
							{resources.map((resource) => (
								<S.CardItem key={resource.id} to={`/teacher/information/${resource.id}`}>
									<Picture className="thumbnail" src={resource.coverImageObj['200']} aspectRatio="3/2" />
									<div className="info-wrapper">
										<Typography className="category" tag="p" variation="3">
											{resource.category || 'Event'}
										</Typography>
										<Typography tag="h5" weight="bold">
											{resource.title}
										</Typography>
										<div className="info-wrapper__details-wrapper">
											<div key="business" className="detail-item">
												<div className="icon-wrapper">
													<FontAwesomeIcon icon={['fal', 'building']} />
												</div>
												<Typography tag="p" variation="2">
													{resource.business.name}
												</Typography>
											</div>
											<div key="gradeLevels" className="detail-item">
												<div className="icon-wrapper">
													<FontAwesomeIcon icon={['fal', 'users']} />
												</div>
												<Typography tag="p" variation="2">
													{formatGradeLevel(resource.gradeLevels, true)}
												</Typography>
											</div>
											<div key="links" className="detail-item">
												<div className="icon-wrapper">
													<FontAwesomeIcon icon={['fal', 'external-link']} />
												</div>
												<Typography tag="p" variation="2">
													{resource.links.length !== 1 ? `${resource.links.length} Links` : '1 Link'}
												</Typography>
											</div>
											<div key="downloads" className="detail-item">
												<div className="icon-wrapper">
													<FontAwesomeIcon icon={['fal', 'arrow-to-bottom']} />
												</div>
												<Typography tag="p" variation="2">
													{resource.publicFiles.length !== 1 ? `${resource.publicFiles.length} Downloads` : '1 Download'}
												</Typography>
											</div>
										</div>
									</div>
								</S.CardItem>
							))}
						</S.CardsWrapper>
						{totalPages > pageNumber + 1 && (
							<S.LoadMoreWrapper>
								<Button variant="outline" variation="secondary" type="button" onClick={loadMore}>
									<Typography variation="button-medium" weight="bold">
										{loadingMore ? 'Loading...' : 'Load More'}
									</Typography>
								</Button>
							</S.LoadMoreWrapper>
						)}
					</>
				) : (
					<EmptyComponent title="No archived informational resources" message="Check back later to view your archived information." icon={['fal', 'info-circle']} />
				);
			default:
				return null;
		}
	};

	return (
		<S.Wrapper>
			<FilterSection
				ref={filterRef}
				onChangeCallback={onChangeCallback}
				initialState={{
					searchText: '',
					sortOption: RESOURCE_SORT_OPTIONS[2],
					showAdvancedFilters: false,
					activeFilters: [],
					distance: 0,
					counties: [],
					locationTypes: [],
					categories: [],
					careerClusters: [],
					ageGroups: [],
					employabilitySkills: [],
					curriculumStandards: [],
				}}
				sortOptions={RESOURCE_SORT_OPTIONS}
				filterOptions={['categories', 'careerClusters', 'ageGroups', 'employabilitySkills', 'curriculumStandards']}
			/>
			{renderContent()}
		</S.Wrapper>
	);
};
