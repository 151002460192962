import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import { ACCEPTABLE_FILE_TYPES } from '../../../constants/general.constants';
import { Spinner, Picture, Typography, IconButton, Button } from '../';
import { errorHandler } from '../../services/authService';
import * as S from './Chat.styles';
import { sendMessage } from '../../services/messages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Chat = ({ loading, messages = [], sendProps = {} }) => {
	const inputFileRef = useRef(null);
	const messagesEndRef = useRef(null);
	const [sending, setSending] = useState(false);
	const { register, handleSubmit, watch, resetField, reset } = useForm();
	const { ref: fileRef, ...fileRest } = register('fileList');
	const fileList = watch('fileList');

	const scrollToBottom = () => messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });

	async function asyncCaller(data) {
		if ((!data?.message?.trim() && data?.fileList?.length === 0) || sending) return;
		setSending(true);
		const formData = new FormData();
		if (data?.fileList?.length > 0) {
			formData.append('file', data.fileList[0]);
		}
		if (data?.message?.trim()) {
			formData.append('message', data.message);
		}
		Object.entries(sendProps).forEach(([key, value]) => {
			formData.append(key, value);
		});
		await sendMessage(formData);
	}

	const onSubmit = (e) => {
		handleSubmit(asyncCaller)(e)
			.catch(errorHandler)
			.finally(() => {});
	};

	useEffect(scrollToBottom, [messages]);

	useEffect(() => {
		if (!loading) {
			messagesEndRef.current.scrollIntoView({ behavior: 'auto' });
		}
	}, [loading]);

	useEffect(() => {
		if (messages && messages.length > 0) {
			const mostRecent = messages[messages.length - 1];
			if (mostRecent.type === 'sent') {
				setSending(false);
				reset();
			}
		}
	}, [messages]);

	return (
		<S.Wrapper className="chat-main-wrapper">
			<S.ChatBody>
				<div className="chat">
					{loading ? (
						<Spinner />
					) : (
						messages.map((message) => (
							<div key={message.id} className={`chat__bubble chat__bubble--${message.type === 'received' ? 'other' : 'self'}`}>
								{message.type === 'received' && (
									<div className="bubble-info-wrapper">
										{message.sender.senderType === 'business' ? (
											<Picture className="bubble-info-wrapper__avatar" src={message.sender.business?.logoObj['200']} aspectRatio="1/1" />
										) : (
											<Picture className="bubble-info-wrapper__avatar" src={message.sender.user?.profileImageObj['200']} aspectRatio="1/1" />
										)}
									</div>
								)}
								<div className="bubble-message-wrapper">
									{message.type === 'received' && (
										<Typography tag="p" variation="2" className="bubble-message-wrapper__name">
											{message.sender.senderType === 'business' ? message.sender.business.name : `${message.sender.user.firstName} ${message.sender.user.lastName}`}
										</Typography>
									)}
									{message.mediaObj ? (
										<div className="bubble-file-wrapper">
											{message.mediaObj.document ? (
												<a className="bubble-file-wrapper__link-wrapper" href={message.mediaObj.document} target="_blank" rel="noopener noreferrer">
													<Picture pictureClassName="bubble-file-wrapper__picture" className={`bubble-file-wrapper__image${message.message ? ' bubble-file-wrapper__image--with-message' : ''}`} src={message.mediaObj['600']} width={200} />
													<FontAwesomeIcon icon={['fal', 'external-link']} className="floating-link-icon" size="3x" />
												</a>
											) : (
												<Picture pictureClassName="bubble-file-wrapper__picture" className={`bubble-file-wrapper__image${message.message ? ' bubble-file-wrapper__image--with-message' : ''}`} src={message.mediaObj['600']} width={200} />
											)}

											{message.message && <div className="bubble-message-wrapper__bubble">{message.message}</div>}
										</div>
									) : (
										<div className="bubble-message-wrapper__bubble">{message.message}</div>
									)}
								</div>
							</div>
						))
					)}
					<div className="chat__last-child" ref={messagesEndRef} />
				</div>
			</S.ChatBody>
			{Object.keys(sendProps).length > 0 && (
				<S.ChatFooter onSubmit={onSubmit} autoComplete="off">
					{fileList?.length > 0 && (
						<S.ChatFilePreview>
							{[...fileList].map((file) => (
								<div className="chat__footer__preview__item" key={file.name}>
									{file.type.includes('image') ? (
										<Picture className="chat__footer__preview__item__image-container" pictureClassName="chat__footer__preview__item__picture" src={URL.createObjectURL(file)} aspectRatio="1/1" />
									) : (
										<div className="chat__footer__preview__item__pdf-container">
											<FontAwesomeIcon icon={['fal', 'file-pdf']} size="2x" />
										</div>
									)}
									<IconButton type="button" icon={['fal', 'times']} onClick={() => resetField('fileList')} />
								</div>
							))}
						</S.ChatFilePreview>
					)}
					<IconButton type="button" className="chat__footer__file-button" icon="file" onClick={() => inputFileRef.current.click()} />
					<input
						type="file"
						accept={ACCEPTABLE_FILE_TYPES.MESSAGE_MEDIA.MIME_TYPES.join(',')}
						{...fileRest}
						ref={(e) => {
							fileRef(e);
							inputFileRef.current = e;
						}}
						hidden
					/>
					<input className="chat__footer__input" type="text" placeholder="Message" {...register('message')} />
					<Button type="submit" className="chat__footer__send-button">
						<Typography className={`chat__footer__send-button__span${sending ? ' chat__footer__send-button__span--hidden' : ''}`} variation="button-medium" weight="bold">
							Send
						</Typography>
						{sending && <div className="chat__footer__send-button__spinner" />}
					</Button>
				</S.ChatFooter>
			)}
		</S.Wrapper>
	);
};

Chat.propTypes = {
	loading: PropTypes.bool,
	messages: PropTypes.arrayOf(
		PropTypes.shape({
			sender: PropTypes.shape({
				senderType: PropTypes.oneOf(['business', 'user']),
				business: PropTypes.shape({
					name: PropTypes.string,
					logoObj: PropTypes.shape({
						200: PropTypes.string,
					}),
				}),
				user: PropTypes.shape({
					firstName: PropTypes.string,
					lastName: PropTypes.string,
					profileImageObj: PropTypes.shape({
						200: PropTypes.string,
					}),
				}),
			}),
			message: PropTypes.string,
			mediaObj: PropTypes.shape({
				600: PropTypes.string,
			}),
			type: PropTypes.oneOf(['received', 'sent']),
		})
	),
	sendProps: PropTypes.shape({
		businessId: PropTypes.string,
		bookingId: PropTypes.string,
		recipientId: PropTypes.string,
		requestId: PropTypes.string,
		responseId: PropTypes.string,
		resourceId: PropTypes.string,
	}),
};
