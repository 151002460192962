import { AGE_GROUPS } from '../../constants/general.constants';

function generateAgeByGradeLevelsArray(gradeLevels = []) {
	const ages = [];
	AGE_GROUPS.forEach((ageGroup) => {
		if (gradeLevels.find((gradeLevel) => ageGroup.values.includes(gradeLevel))) {
			ages.push(ageGroup.label);
		}
	});
	return ages;
}

export const formatAgeByGradeLevels = (gradeLevels = []) => {
	const ages = generateAgeByGradeLevelsArray(gradeLevels);
	return ages.length ? ages.join(', ') : 'None';
};

export const getAgeGroupByGradeLevels = (gradeLevels) => {
	return generateAgeByGradeLevelsArray(gradeLevels);
};
