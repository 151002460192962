import styled from 'styled-components';

import colors from '../../styles/colors';

export const Wrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	padding: 0 1rem 2rem;
`;

export const Form = styled.form`
	position: relative;

	.close-button {
		position: absolute;
		top: 1rem;
		right: 1rem;
		color: ${colors.neutralButton};
	}

	.modal-content {
		display: grid;
		grid-template-columns: 1fr;
		gap: 2rem;
		padding: 4rem 0 0;

		&__header {
			display: grid;
			grid-template-columns: 1fr;
			gap: 0.5rem;
			margin-bottom: 1rem;
		}

		&__section {
			display: grid;
			grid-template-columns: 1fr;
			gap: 1.5rem;
			margin: 0 auto;
			padding: 0 1rem;
			width: 100%;
			max-width: 26.25rem;
		}
	}

	.modal-footer {
		display: flex;
		justify-content: flex-end;
		padding: 1.5rem;
		border-top: 1px solid ${colors.neutralForm};
	}
`;
