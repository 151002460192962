import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormContext } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';

import * as S from '../CompleteResourceEditModal.styles';
import { required } from '../../../utils/form-default-errors';
import { Typography, TextInput, TextArea, Checkbox } from '../../../components';
import { AGE_GROUPS, CAREER_CLUSTERS } from '../../../../constants/general.constants';

export const DetailsStep = () => {
	const {
		register,
		watch,
		resetField,
		setValue,
		formState: { errors },
	} = useFormContext();

	const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
		onDrop: useCallback((acceptedFiles) => acceptedFiles.length > 0 && setValue('coverImage', acceptedFiles[0], { shouldValidate: true }), []),
		multiple: false,
		accept: 'image/png, image/jpg, image/jpeg',
	});

	const renderCoverImage = (image) => {
		if (image) {
			if (image instanceof File) {
				return <img src={URL.createObjectURL(image)} alt="Cover" />;
			} else {
				return <img src={image[1500]} alt="Cover" />;
			}
		}
		return null;
	};

	const coverImage = watch('coverImage');

	return (
		<S.FieldsWrapper>
			<S.HugeImageUploader withError={errors?.coverImage}>
				<Typography tag="label" htmlFor="cover-image" weight="semibold">
					Cover Image
				</Typography>
				<S.FileDropZone {...getRootProps()} isDragActive={isDragActive} isDragAccept={isDragAccept} isDragReject={isDragReject}>
					<input id="cover-image" {...register('coverImage', { required: required('Cover Image') })} {...getInputProps({ onChange: (e) => setValue('coverImage', e.target.files[0], { shouldValidate: true }) })} />
					{renderCoverImage(coverImage)}
				</S.FileDropZone>
				{coverImage && (
					<button className="reset-btn" type="button" onClick={() => resetField('coverImage')}>
						<FontAwesomeIcon icon={['fal', 'trash']} />
					</button>
				)}
				{!!errors?.coverImage && (
					<div className="error-wrapper">
						<Typography tag="p" variation="2">
							{errors?.coverImage?.message}
						</Typography>
					</div>
				)}
			</S.HugeImageUploader>
			<TextInput
				label="Title"
				id="title"
				error={errors?.title}
				{...register('title', {
					required: required('Title'),
				})}
			/>

			<TextArea
				label="Description"
				id="description"
				error={errors?.description}
				{...register('description', {
					required: required('Description'),
				})}
			/>

			<S.CheckboxListWrapper>
				<Typography className={`field-label${!!errors.ageGroups ? ' with-error' : ''}`} tag="p" variation="2" weight="semibold">
					Grade Level
				</Typography>

				{AGE_GROUPS.map(({ value, label }) => (
					<div key={value} className="checkbox-field">
						<Checkbox id={value} value={value} {...register('ageGroups', { required: required('Grade Level') })} />
						<label htmlFor={value}>{label}</label>
					</div>
				))}

				{!!errors.ageGroups && (
					<div className="error-wrapper">
						<Typography tag="p" variation="2">
							{errors.ageGroups.message}
						</Typography>
					</div>
				)}
			</S.CheckboxListWrapper>

			<S.CheckboxListWrapper>
				<Typography className={`field-label${!!errors?.careerClusters ? ' with-error' : ''}`} tag="p" variation="2" weight="semibold">
					Career Cluster
				</Typography>

				{CAREER_CLUSTERS.map(({ value, label }) => (
					<div key={value} className="checkbox-field">
						<Checkbox id={value} value={value} {...register('careerClusters', { required: required('Career Cluster') })} />
						<label htmlFor={value}>{label}</label>
					</div>
				))}

				{!!errors?.careerClusters && (
					<div className="error-wrapper">
						<Typography tag="p" variation="2">
							{errors?.careerClusters?.message}
						</Typography>
					</div>
				)}
			</S.CheckboxListWrapper>
		</S.FieldsWrapper>
	);
};
