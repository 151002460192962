import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormContext } from 'react-hook-form';

import { required } from '../../../utils/form-default-errors';
import { Typography } from '../../../components';
import * as S from '../AdminCreateTemplate.styles';

export const StepTemplateType = () => {
	const {
		register,
		formState: { errors },
	} = useFormContext();

	return (
		<S.FormPanel>
			<Typography tag="h2" weight="extrablack" center>
				Select a Template Type
			</Typography>
			<Typography tag="p" center>
				Select an option for the type of template you would like to make.
			</Typography>
			<div className="radio-wrapper">
				<div className={`radio-field${errors.type ? ' with-error' : ''}`}>
					<input
						className="radio-input"
						type="radio"
						id="request"
						value="request"
						{...register('type', {
							required: required('Template Type'),
						})}
					/>
					<FontAwesomeIcon icon={['fal', 'file-plus']} size="4x" />
					<Typography tag="h5" weight="bold" center>
						Request
					</Typography>
					<Typography tag="p" variation="2" center>
						Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.
					</Typography>
				</div>
				<div className={`radio-field${errors.type ? ' with-error' : ''}`}>
					<input
						className="radio-input"
						type="radio"
						id="resource"
						value="resource"
						{...register('type', {
							required: required('Template Type'),
						})}
					/>
					<FontAwesomeIcon icon={['fal', 'book']} size="4x" />
					<Typography tag="h5" weight="bold" center>
						Resource
					</Typography>
					<Typography tag="p" variation="2" center>
						Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.
					</Typography>
				</div>
				<div className={`radio-field${errors.type ? ' with-error' : ''}`}>
					<input
						className="radio-input"
						type="radio"
						id="file"
						value="file"
						{...register('type', {
							required: required('Template Type'),
						})}
					/>
					<FontAwesomeIcon icon={['fal', 'file']} size="4x" />
					<Typography tag="h5" weight="bold" center>
						File
					</Typography>
					<Typography tag="p" variation="2" center>
						Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.
					</Typography>
				</div>

				{!!errors.type && (
					<div className="error-wrapper">
						<Typography tag="p" variation="2">
							{errors.type.message}
						</Typography>
					</div>
				)}
			</div>
		</S.FormPanel>
	);
};
