import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const PublicRoute = ({ component: Component, path, exact = false }) => {
	const status = useSelector((state) => state.user.status);

	const render = (props) => <Component {...props} />;

	if (status === 'idle') {
		return null;
	}

	return <Route exact={exact} path={path} render={render} />;
};

PublicRoute.propTypes = {
	component: PropTypes.func.isRequired,
	path: PropTypes.string,
	exact: PropTypes.bool,
};
