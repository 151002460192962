import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Typography } from '../Typography';
import { Button } from '../Button';
import * as S from './MessageDialog.styles';

export const MessageDialog = forwardRef(({ title, subtitle, icon, actions = [{ id: 'close', label: 'Close', variant: 'solid', variation: 'default' }], isOpen = false, onRequestClose, ...rest }, ref) => {
	return (
		<ReactModal ref={ref} closeTimeoutMS={200} isOpen={isOpen} onRequestClose={onRequestClose} className="c-message-dialog" {...rest}>
			<S.Wrapper>
				{icon && (
					<S.IconWrapper>
						<div className="circle-wrapper">
							<FontAwesomeIcon icon={icon} size="4x" />
						</div>
					</S.IconWrapper>
				)}

				<S.InfoWrapper>
					<Typography tag="h2" weight="extrablack">
						{title}
					</Typography>
					<Typography tag="p">{subtitle}</Typography>
				</S.InfoWrapper>
				<S.ButtonWrapper>
					{actions.map((action) => (
						<Button key={action.id} onClick={action.onClick || onRequestClose} variant={action.variant} variation={action.variation}>
							<Typography weight="bold" variation="button-medium">
								{action.label}
							</Typography>
						</Button>
					))}
				</S.ButtonWrapper>
			</S.Wrapper>
		</ReactModal>
	);
});

MessageDialog.displayName = 'MessageDialog';

MessageDialog.propTypes = {
	title: PropTypes.string.isRequired,
	isOpen: PropTypes.bool.isRequired,
	onRequestClose: PropTypes.func.isRequired,
	subtitle: PropTypes.string,
	icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
	actions: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			onClick: PropTypes.func,
			label: PropTypes.string,
			variant: PropTypes.oneOf(['solid', 'outline', 'text']),
			variation: PropTypes.oneOf(['default', 'secondary', 'warning']),
		})
	),
};
