import styled from 'styled-components';

export const Wrapper = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;

	div.padding {
		padding-left: 0;
		padding-right: 0;
	}
`;
