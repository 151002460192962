import styled from 'styled-components';

import colors from '../../../../styles/colors';

export const DetailWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 1rem;
	padding-bottom: 1rem;
	margin-bottom: 1rem;
	border-bottom: 1px solid ${colors.neutralForm};

	.element-info {
		&__title {
			color: ${colors.neutralText};
			margin-bottom: 0.25rem;
		}
		&__text {
			.success {
				color: ${colors.stateSuccess};
			}
			.danger {
				color: ${colors.stateDanger};
			}
			svg {
				margin-right: 0.3125rem;
			}
		}
	}

	@media screen and (min-width: 992px) {
		grid-template-columns: repeat(4, 1fr);
	}
`;

export const DemographicsWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	gap: 1.25rem;
	padding-bottom: 0.5rem;

	@media screen and (min-width: 992px) {
		grid-template-columns: repeat(2, 1fr);
	}
`;

export const TableWrapper = styled.div`
	display: flex;
	flex-direction: column;

	h5 {
		margin-bottom: 0.25rem;
	}

	.table-info {
		padding: 0.75rem 1rem;
		border: 1px solid ${colors.neutralForm};
		border-radius: 0.375rem;

		&__header {
			display: flex;
			justify-content: space-between;
			background-color: ${colors.neutralBackground};
			border-radius: 0.25rem;
			padding: 0.625rem 0.75rem;
		}

		&__header-item {
			color: ${colors.neutralText};

			&:last-child {
				width: 100%;
				max-width: 5.5rem;
			}
		}

		&__body {
			display: flex;
			flex-direction: column;
		}

		&__body-row {
			display: flex;
			justify-content: space-between;
			padding: 0.75rem;
			border-bottom: 1px solid ${colors.neutralForm};

			h6 {
				&:last-child {
					width: 100%;
					max-width: 5.5rem;
				}
			}

			&:last-child {
				border-bottom: none;
				padding-bottom: 0.5rem;
			}
		}
	}
`;

export const OneLineWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	gap: 1.25rem;
`;
