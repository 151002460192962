import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { required } from '../../../utils/form-default-errors';
import { fetchTemplates } from '../../../services/requests/createRequestService';
import { Picture, Spinner, Typography } from '../../../components';
import * as S from '../CreateResource.styles';

export const StepTemplate = () => {
	const {
		register,
		getValues,
		watch,
		resetField,
		unregister,
		clearErrors,
		setValue,
		formState: { errors },
	} = useFormContext();

	const templateId = watch('templateId');

	const [pageStatus, setPageStatus] = useState('idle');
	const [templates, setTemplates] = useState([]);

	const renderTemplates = () => {
		return templates.map((template) => (
			<div key={template.id} className={`radio-field template-field template${errors.templateId ? ' with-error' : ''}`}>
				<input
					className="radio-input"
					type="radio"
					id={template.id}
					value={template.id}
					{...register('templateId', {
						required: required('Template'),
					})}
				/>
				<Picture className="thumbnail" src={template.coverImageObj[200]} aspectRatio="3/2" />
				<Typography tag="h5" weight="bold">
					{template.title}
				</Typography>
				<Typography tag="p" variation="2">
					{template.description}
				</Typography>
			</div>
		));
	};

	const renderContent = () => {
		switch (pageStatus) {
			case 'loading':
				return <Spinner />;
			case 'error':
				return <h1>Error</h1>;
			case 'success':
				return (
					<>
						<div className={`radio-field template-field template${errors.templateId ? ' with-error' : ''}`}>
							<input
								className="radio-input"
								type="radio"
								id="start-from-scratch"
								value="START_FROM_SCRATCH"
								{...register('templateId', {
									required: required('Template'),
								})}
							/>
							<Picture className="thumbnail" src="https://picsum.photos/200" aspectRatio="3/2" />
							<Typography tag="h5" weight="bold">
								Start from scratch
							</Typography>
							<Typography tag="p" variation="2">
								Create a custom resource.
							</Typography>
						</div>
						{renderTemplates()}
					</>
				);
			default:
				return null;
		}
	};

	async function initializeData() {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchTemplates({ type: 'resource', resourceType: getValues('type'), category: getValues('category') });

			setTemplates(result.templates);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}

	useEffect(async () => {
		if (templateId && templates.length > 0) {
			unregister('coverImage');
			if (templateId === 'START_FROM_SCRATCH') {
				resetField('coverImage');
				resetField('title');
				resetField('description');
				resetField('careerClusters');
				resetField('ageGroups');
			} else {
				const template = templates.find((t) => t.id === templateId);
				setValue('coverImage', template.coverImageObj);
				setValue('title', template.title);
				setValue('description', template.description);
				setValue('careerClusters', template.careerClusters);
				setValue('ageGroups', template.ageGroups);
			}
		}
	}, [templates, templateId]);

	useEffect(() => {
		if (pageStatus === 'idle') {
			initializeData();
		}
	}, [pageStatus]);

	return (
		<S.FormPanel>
			<Typography tag="h2" weight="extrablack" center>
				Select a Template
			</Typography>
			<Typography tag="p" center>
				Select a base to create your request.
			</Typography>

			<div className="radio-wrapper">
				{renderContent()}
				{!!errors.templateId && (
					<div className="error-wrapper">
						<Typography tag="p" variation="2">
							{errors.templateId.message}
						</Typography>
					</div>
				)}
			</div>
		</S.FormPanel>
	);
};
