import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { BookingDetailsContext } from '../BookingDetail';
import { ReviewCard } from './ReviewCard';
import { CancellationCard } from './CancellationCard';
import { BookingCard } from './BookingCard';

import { BOOKING_STATUSES } from '../../../../constants/general.constants';

import * as S from './AppointmentTab.styles';

export const AppointmentTab = ({ role }) => {
	const {
		state: { booking },
	} = useContext(BookingDetailsContext);

	return (
		<S.Wrapper>
			{booking.isCanceled === true && <CancellationCard booking={booking} role={role} />}
			{booking.status === BOOKING_STATUSES.COMPLETE && <ReviewCard booking={booking} role={role} />}
			<BookingCard booking={booking} role={role} />
		</S.Wrapper>
	);
};

AppointmentTab.propTypes = {
	role: PropTypes.string,
};
