import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as S from './OverviewCard.styles';
import { GRADE_LEVELS } from '../../../../../constants/general.constants';
import { Card, ModalCreateGroup, Typography } from '../../../../components';

const OverviewCard = ({ className, group, setGroup }) => {
	const [isOpen, setIsOpen] = useState(false);

	function handleOpenModal() {
		setIsOpen(true);
	}

	function handleCloseModal() {
		setIsOpen(false);
	}

	return (
		<Card className={className} title="Overview" transparentHeaderBorder={true} actions={[{ id: 1, label: 'Edit', size: 'small', variant: 'outline', icon: { source: ['fal', 'edit'] }, onClick: handleOpenModal }]}>
			<S.Wrapper>
				<div className="element-info">
					<Typography className="element-info__title" tag="h6" weight="semibold">
						Group Name
					</Typography>
					<Typography className="element-info__text" tag="p">
						{group.name}
					</Typography>
				</div>
				<div className="element-info">
					<Typography className="element-info__title" tag="h6" weight="semibold">
						Grade Level(s)
					</Typography>
					<Typography className="element-info__text" tag="p">
						{GRADE_LEVELS.filter((gradeLevel) => group.gradeLevels?.includes(gradeLevel.value))
							.map((gradeLevel) => gradeLevel.label)
							.join(', ')}
					</Typography>
				</div>
				<div className="element-info">
					<Typography className="element-info__title" tag="h6" weight="semibold">
						Class Status
					</Typography>
					<Typography className="element-info__text" tag="p">
						{group.isClass ? (
							<span className="success">
								<FontAwesomeIcon className="icon" icon={['fal', 'check']} />
								Class
							</span>
						) : (
							<span className="danger">
								<FontAwesomeIcon className="icon" icon={['fal', 'times']} />
								Not a Class
							</span>
						)}
					</Typography>
				</div>
				{group.subject && (
					<div className="element-info">
						<Typography className="element-info__title" tag="h6" weight="semibold">
							Subject
						</Typography>
						<Typography className="element-info__text" tag="p">
							{group.subject}
						</Typography>
					</div>
				)}
				{group.classCode && (
					<div className="element-info">
						<Typography className="element-info__title" tag="h6" weight="semibold">
							Course Code
						</Typography>
						<Typography className="element-info__text" tag="p">
							{group.classCode}
						</Typography>
					</div>
				)}
			</S.Wrapper>
			<ModalCreateGroup isOpen={isOpen} onRequestClose={handleCloseModal} objectToEdit={group} editCallback={setGroup} />
		</Card>
	);
};

OverviewCard.propTypes = {
	className: PropTypes.string,
	group: PropTypes.object.isRequired,
	setGroup: PropTypes.func.isRequired,
};

export default OverviewCard;
