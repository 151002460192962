import React, { createContext, useContext, useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { useList } from 'react-use';
import { Badge, Picture, Typography, EmptyComponent, Spinner } from '../../../components';
import { formatToRelativeTime } from '../../../utils/time-formatter';

import { fetchBookings } from '../../../services/bookings';
import { FilterSection } from './FilterSection';
import { BookingChat } from './BookingChat';
import * as S from './SentBookingsTab.styles';

export const TABS_OPTIONS = [
	{ value: 'all', label: 'All' },
	{ value: 'pending', label: 'Pending' },
	{ value: 'scheduled', label: 'Scheduled' },
	{ value: 'complete', label: 'Completed' },
];

const BADGE_STATUS = {
	pending: {
		label: 'Pending',
		color: 'primary',
	},
	scheduled: {
		label: 'Scheduled',
		color: 'neutral',
	},
	draft: {
		label: 'Draft',
		color: 'neutral',
	},
	complete: {
		label: 'Completed',
		color: 'success',
	},
	incomplete: {
		label: 'Incomplete',
		color: 'danger',
	},
	archived: {
		label: 'Archived',
		color: 'neutral',
	},
};

export const BookingsContext = createContext();

export const initialState = {
	selectedTab: TABS_OPTIONS[0],
	searchInputValue: '',
	selectedConversation: null,
};
export function reducer(state, action) {
	switch (action.type) {
		case 'SET_SELECTED_TAB':
			return {
				...state,
				selectedTab: action.payload,
			};
		case 'SET_SEARCH_INPUT_VALUE':
			return {
				...state,
				searchInputValue: action.payload,
			};
		case 'SET_SELECTED_CONVERSATION':
			return {
				...state,
				selectedConversation: action.payload,
			};
		case 'CLEAR_SELECTED_CONVERSATION':
			return {
				...state,
				selectedConversation: null,
			};
		case 'setBooking':
			return {
				...state,
				selectedConversation: {
					id: action.payload.id,
					business: action.payload.business,
					resource: action.payload.resource,
					status: action.payload.status,
				},
			};
		default:
			return state;
	}
}

const IndividualItem = ({ id, business, resource, lastMessage, status }) => {
	const {
		state: { selectedConversation },
		dispatch,
	} = useContext(BookingsContext);

	return (
		<S.ListItem key={id} onClick={() => dispatch({ type: 'SET_SELECTED_CONVERSATION', payload: { id, business, resource, status } })} className={selectedConversation != null && selectedConversation.id === id ? 'is-selected' : ''}>
			<div className="individual-card__avatar-wrapper">
				<Picture className="individual-card__avatar" src={business.logoObj[200]} aspectRatio="1/1" />
			</div>
			<div className="individual-card__info-wrapper">
				<Typography className="individual-card__name" tag="h5" weight="bold">
					{business.name}
				</Typography>
				<Typography className="individual-card__category" tag="p" variation="3">
					{lastMessage ? `${formatToRelativeTime(new Date(lastMessage.date.iso))} • ${resource.title}` : resource.title}
				</Typography>
				{lastMessage && (
					<Typography className="individual-card__message" tag="p" variation="2">
						{lastMessage.message}
					</Typography>
				)}
			</div>
			<div className="individual-card__badge-wrapper">
				<Badge small="small" type={BADGE_STATUS[status].color} variant="outlined">
					{BADGE_STATUS[status].label}
				</Badge>
			</div>
		</S.ListItem>
	);
};
IndividualItem.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	business: PropTypes.object.isRequired,
	resource: PropTypes.object,
	lastMessage: PropTypes.object,
	status: PropTypes.string.isRequired,
};

// Initialize default data holder
let bookingsArray = [];

export const SentBookingsTab = ({}) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const [pageStatus, setPageStatus] = useState('idle');
	const { selectedTab, searchInputValue, selectedConversation } = state;
	const [individualList, { set: setIndList, filter: filterInd }] = useList([]);

	const handleListFilter = (filterMethod, resetMethod) => {
		resetMethod();
		if (searchInputValue && selectedTab.value === 'all') {
			filterMethod((item) => item.resource.searchTag.toLowerCase().includes(searchInputValue.toLowerCase()));
		} else if (searchInputValue && selectedTab.value !== 'all') {
			filterMethod((item) => item.resource.searchTag.toLowerCase().includes(searchInputValue.toLowerCase()) && item.status === selectedTab.value);
		} else if (searchInputValue === '' && selectedTab.value !== 'all') {
			filterMethod((item) => item.status === selectedTab.value);
		}
	};

	const handleFetchData = async () => {
		setPageStatus('loading');

		// Fetch data for value
		const {
			data: { result },
		} = await fetchBookings({
			filterOptions: { inbound: false, outbound: true },
		});
		bookingsArray = result.bookings;
		setIndList(result.bookings);
		setPageStatus('success');
	};

	const filterData = () => {
		// Update filter state
		handleListFilter(filterInd, () => setIndList(bookingsArray));
	};

	useEffect(async () => {
		// Fetch data
		await handleFetchData();

		// Update filter state
		filterData();
	}, []);

	useEffect(async () => {
		// Update filter state
		filterData();
	}, [selectedTab, searchInputValue]);

	useEffect(async () => {
		if (selectedConversation != null) {
			const editedItemIndex = bookingsArray.findIndex((item) => item.id === selectedConversation.id);
			bookingsArray[editedItemIndex].status = selectedConversation.status;
			setIndList(bookingsArray);
		}
	}, [selectedConversation]);

	return (
		<BookingsContext.Provider value={{ state, dispatch }}>
			<S.Wrapper someChatIsSelected={selectedConversation}>
				<S.ContentWrapper>
					<FilterSection />
					<S.RelativeWrapper>
						<S.ListWrapper>
							{individualList.length > 0 ? (
								individualList.map(({ id, business, resource, lastMessage, status }) => <IndividualItem key={id} id={id} business={business} resource={resource} lastMessage={lastMessage} status={status} />)
							) : pageStatus === 'loading' ? (
								<Spinner />
							) : (
								<EmptyComponent title="No available conversations" message="Check back later to view available conversations." icon={['fal', 'comment']} />
							)}
						</S.ListWrapper>
					</S.RelativeWrapper>
				</S.ContentWrapper>
				{selectedConversation && (
					<S.ChatWrapper>
						<div className="relative-wrapper">
							<BookingChat conversation={selectedConversation} />
						</div>
					</S.ChatWrapper>
				)}
			</S.Wrapper>
		</BookingsContext.Provider>
	);
};
