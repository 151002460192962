import api from '../api';

export const fetchTemplates = (data) => api.post('/core/functions/fetchTemplates', data);

export const fetchTemplate = (data) => api.post('/core/functions/fetchTemplate', data);

export const createUpdateTemplate = (data) => api.post('/core/functions/createUpdateTemplate', data);

export const updatePublishStatusTemplate = (data) => api.post('/core/functions/updatePublishStatusTemplate', data);

export const archiveUnarchiveTemplate = (data) => api.post('/core/functions/archiveUnarchiveTemplate', data);
