import styled from 'styled-components';

import colors from '../../../styles/colors';

export const Wrapper = styled.div``;

export const CardItem = styled.div`
	text-decoration: none;
	color: black;
	display: flex;
	position: relative;
	justify-content: flex-start;
	align-items: center;
	border-top: 1px solid ${colors.neutralForm};
	padding: 1rem 0 1.5rem;

	.thumbnail {
		border: 1px solid ${colors.neutralForm};
		border-radius: 0.375rem;
		margin-right: 0.75rem;
		min-width: 6rem;
		max-width: 6rem;
	}

	.info-wrapper {
		display: flex;
		flex: 1;
		flex-direction: column;

		.category {
			color: ${colors.neutralText};
			margin-bottom: 0.25rem;
		}

		&__details-wrapper {
			display: grid;
			margin-top: 0.5rem;
			gap: 0.5rem;
			grid-template-columns: repeat(1, 1fr);

			.detail-item {
				display: flex;
				color: ${colors.neutralButton};

				.icon-wrapper {
					display: flex;
					justify-content: center;
					width: 1.5rem;
				}

				& > * + * {
					margin-left: 0.5rem;
				}
			}

			@media screen and (min-width: 426px) {
				grid-template-columns: repeat(2, 1fr);
			}

			@media screen and (min-width: 900px) {
				grid-template-columns: repeat(3, 1fr);
			}

			@media screen and (min-width: 992px) {
				grid-template-columns: repeat(4, 1fr);
			}
		}
	}

	&:first-child {
		padding-top: 0;
		border: none;
	}
	&:last-child {
		padding-bottom: 1rem;
	}
`;

export const CardsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	background-color: white;

	.business-review-item {
		display: grid;
		grid-template-columns: 2.625rem 1fr;
		gap: 0.75rem;
		width: 100%;

		&__image-wrapper {
			img {
				display: block;
				width: 100%;
				border-radius: 9999px;
				border: 1px solid ${colors.neutralForm};
			}
		}

		&__fields-wrapper {
			display: grid;
			gap: 1.25rem;
			margin-top: 0.25rem;

			& > h3 {
				margin-bottom: -0.75rem;
			}
		}

		&.smaller {
			grid-template-columns: 2.125rem 1fr;

			.business-review-item__fields-wrapper {
				gap: 0.5rem;
			}
		}
	}

	.review {
		&__step-item {
			display: grid;
			grid-template-columns: 1fr;
			gap: 1.5rem;
			margin-top: 1rem;
			align-content: start;

			&:first-child {
				margin-top: 0;
			}
		}

		&__businesses {
			border-top: 1px solid ${colors.neutralForm};
			padding-top: 1rem;
		}

		&__learning {
			margin-bottom: 0.75rem;
		}

		&__step-half {
			display: grid;
			grid-template-columns: 1fr;
			gap: 1.5rem;

			@media (min-width: 1024px) {
				grid-template-columns: 1fr 1fr;
			}
		}

		&__info-item {
			& > h6 {
				color: ${colors.neutralText};
				margin-bottom: 0.375em;
			}

			&--with-badge {
				& > h6 {
					margin-bottom: calc(0.375em + 0.25rem);
				}
			}

			.badge-wrapper {
				display: flex;
				flex-wrap: wrap;
				margin: -0.25rem;

				.small-badge {
					padding: 0.25rem 0.5rem;
					border-radius: 6.25rem;
					border: 1px solid ${colors.brandPrimary};
					font-size: 0.75rem;
					color: ${colors.brandPrimary};
					font-weight: 700;
					margin: 0.25rem;
				}
			}
		}
	}
`;

export const ReviewWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	gap: 1rem;

	@media screen and (min-width: 768px) {
		grid-template-columns: repeat(2, 1fr);
	}
`;

export const LoadMoreWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 1.75rem 0 0;
	border: none;
`;
