import { library } from '@fortawesome/fontawesome-svg-core';
import {
	faTachometer,
	faExclamationCircle,
	faFilePlus,
	faArchive,
	faUser,
	faChartBar,
	faGraduationCap,
	faUsersClass,
	faBooks,
	faEnvelope,
	faUsers,
	faBook,
	faCamera,
	faEdit,
	faFileSearch,
	faComment,
	faComments,
	faBuilding,
	faIdCardAlt,
	faLandmark,
	faCalendar,
	faBookmark,
	faMinusCircle,
	faInfoCircle,
	faCheckCircle,
	faSchool,
	faFile,
	faChalkboard,
	faMapMarker,
	faSlidersH,
	faList,
	faBell,
	faAngleRight,
	faSearch,
	faMap,
	faTimes,
	faArrowLeft,
	faAngleDown,
	faPlus,
	faShare,
	faArrowRight,
	faThLarge,
	faMinus,
	faAngleLeft,
	faCheck,
	faUserFriends,
	faQuestionCircle,
	faFileUser,
	faAward,
	faBookUser,
	faBriefcase,
	faIdCard,
	faBookReader,
	faChalkboardTeacher,
	faStreetView,
	faCampground,
	faUserHardHat,
	faPencil,
	faMobile,
	faBars,
	faExternalLink,
	faArrowToTop,
	faArrowToBottom,
	faGlobe,
	faDownload,
	faLock,
	faPodium,
	faAddressCard,
	faFileAlt,
	faUpload,
	faTrash,
	faImage,
	faInboxOut,
	faStar,
	faHistory,
	faCog,
	faPresentation,
	faFileWord,
	faFileImage,
	faFilePowerpoint,
	faFilePdf,
	faFolderOpen,
} from '@fortawesome/pro-light-svg-icons';
import { faGraduationCap as faGraduationCapSolid, faBuilding as faBuildingSolid, faSchool as faSchoolSolid, faUser as faUserSolid, faBookmark as faBookmarkSolid, faFile as faFileSolid, faUsers as faUsersSolid, faStar as faStarSolid } from '@fortawesome/pro-solid-svg-icons';

export function loadIcons() {
	library.add(
		faStar,
		faTachometer,
		faFilePlus,
		faUser,
		faChartBar,
		faGraduationCap,
		faUsersClass,
		faBooks,
		faEnvelope,
		faUsers,
		faBook,
		faCamera,
		faEdit,
		faFileSearch,
		faComment,
		faComments,
		faBuilding,
		faIdCardAlt,
		faLandmark,
		faCalendar,
		faBookmark,
		faInfoCircle,
		faCheckCircle,
		faSchool,
		faFile,
		faChalkboard,
		faMapMarker,
		faSlidersH,
		faList,
		faBell,
		faAngleRight,
		faSearch,
		faMap,
		faTimes,
		faArrowLeft,
		faAngleDown,
		faPlus,
		faArchive,
		faShare,
		faArrowRight,
		faThLarge,
		faMinus,
		faAngleLeft,
		faCheck,
		faUserFriends,
		faQuestionCircle,
		faFileUser,
		faAward,
		faBookUser,
		faBriefcase,
		faIdCard,
		faBookReader,
		faFolderOpen,
		faChalkboardTeacher,
		faStreetView,
		faCampground,
		faUserHardHat,
		faPencil,
		faMobile,
		faBars,
		faExternalLink,
		faArrowToTop,
		faArrowToBottom,
		faGlobe,
		faDownload,
		faLock,
		faPodium,
		faAddressCard,
		faFileAlt,
		faUpload,
		faTrash,
		faImage,
		faHistory,
		faCog,
		faPresentation,
		faFileWord,
		faFileImage,
		faMinusCircle,
		faFilePdf,
		faFilePowerpoint,
		faGraduationCapSolid,
		faBuildingSolid,
		faSchoolSolid,
		faUserSolid,
		faBookmarkSolid,
		faFileSolid,
		faUsersSolid,
		faStarSolid,
		faInboxOut,
		faExclamationCircle
	);
}
