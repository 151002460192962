import React, { useRef, useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';

import { Typography } from '../Typography';
import * as S from './Tab.styles';

export const Tab = forwardRef(({ tabs = [], noPadding = false, setTabs = () => null, transparent, variation = 'default' }, ref) => {
	const [currentSelectedTabIndex, setCurrentSelectedTabIndex] = useState(tabs.findIndex((option) => option.active) || 0);
	const headRef = useRef(null);

	const handleSelectedTab = (id) => {
		const selectedTab = tabs.find((option) => option.id === id);
		if (!selectedTab.active) {
			setTabs((prev) => {
				const newArray = [...prev];
				newArray.forEach((item, index) => {
					if (item.active) newArray[index].active = false;
					if (item.id === id) item.active = true;
				});
				return newArray;
			});
		}
	};

	function handleOnWheel({ deltaY }) {
		headRef.current.scrollLeft += deltaY;
	}

	useEffect(() => {
		if (!tabs.find((option) => option.active)) {
			setTabs((prev) => {
				const newArray = [...prev];
				newArray[0].active = true;
				return newArray;
			});
		} else {
			setCurrentSelectedTabIndex(tabs.findIndex((option) => option.active));
		}
	}, [tabs]);

	useImperativeHandle(
		ref,
		() => ({
			currentSelectedTabIndex,
		}),
		[currentSelectedTabIndex]
	);

	return (
		<S.Wrapper variation={variation}>
			<S.Head onWheel={handleOnWheel} ref={headRef} transparent={transparent}>
				{tabs.map((tab) => (
					<S.TabButton key={tab.id} onClick={() => handleSelectedTab(tab.id)} active={tab.active} variation={variation}>
						<Typography tag="h6" weight="bold">
							{tab.label}
							{tab.badge != null && <S.Badge>{tab.badge}</S.Badge>}
							{tab.active && <S.ActiveIndicator variation={variation} />}
						</Typography>
					</S.TabButton>
				))}
			</S.Head>
			<S.Content className={noPadding === true ? '' : 'padding'}>{tabs[currentSelectedTabIndex]?.component}</S.Content>
		</S.Wrapper>
	);
});

Tab.displayName = 'Tab';

Tab.propTypes = {
	tabs: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			label: PropTypes.string,
			badge: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			active: PropTypes.bool,
			component: PropTypes.object,
		})
	),
	setTabs: PropTypes.func,
	transparent: PropTypes.bool,
	noPadding: PropTypes.bool,
	variation: PropTypes.oneOf(['default', 'pill']),
};
