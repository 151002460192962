import React, { useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toast from 'react-hot-toast';

import { STATE_OPTIONS } from '../../../constants/general.constants';
import { addUpdateBusinessLocations, removeBusinessLocation } from '../../services/businessLocation';
import { errorHandler } from '../../services/authService';
import { Button, IconButton, Select, TextInput, Typography } from '../../components';
import { required } from '../../utils/form-default-errors';
import * as S from './EditBusinessLocationsModal.styles';

export const EditBusinessLocationsModal = forwardRef(({ company, dispatch }, ref) => {
	const [isOpen, setIsOpen] = useState(false);

	const {
		register,
		control,
		setValue,
		getValues,
		formState: { errors, isSubmitting },
		reset,
		handleSubmit,
	} = useForm();
	const { fields, append, remove } = useFieldArray({ control, name: 'locations' });

	const handleAddLocation = () => {
		append({});
	};

	const handleRemoveLocation = async (index) => {
		try {
			if (fields[index].locationId) {
				await removeBusinessLocation({ locationId: fields[index].locationId });
			}
			remove(index);
		} catch {
			toast.error('Unable to remove this Location for now, try again later.');
		}
	};

	const handleOpenModal = () => {
		setValue(
			'locations',
			company?.locations?.map((location) => ({
				locationId: location.id,
				locationName: location.name,
				addressLine1: location.address?.addressLine1,
				city: location.address?.city,
				state: location.address?.state,
				postalCode: location.address?.postalCode,
			}))
		);
		setIsOpen(true);
	};

	const handleCloseModal = () => {
		setIsOpen(false);
		setTimeout(() => {
			reset();
		}, 200);
	};

	async function asyncCaller(data) {
		const {
			data: {
				result: { locations },
			},
		} = await addUpdateBusinessLocations({ ...data, businessId: company.id });

		dispatch({ type: 'SET_BUSINESS_PROFILE', payload: { ...company, locations } });
		dispatch({ type: 'SET_COMPANY', payload: { ...company, locations } });
		handleCloseModal();
	}

	function onSubmit(e) {
		if (getValues('locations')?.length > 0) {
			handleSubmit(asyncCaller)(e).catch(errorHandler);
		} else {
			handleSubmit(() => {
				toast.error('Please add at least one location.');
			})(e).catch(errorHandler);
		}
	}

	useImperativeHandle(
		ref,
		() => ({
			open: handleOpenModal,
			close: handleCloseModal,
		}),
		[handleOpenModal, handleCloseModal]
	);

	return (
		<ReactModal ref={ref} closeTimeoutMS={200} isOpen={isOpen} onRequestClose={handleCloseModal}>
			<S.Form onSubmit={onSubmit}>
				<IconButton className="close-button" type="button" icon={['fal', 'times']} onClick={handleCloseModal} />
				<div className="modal-content">
					<header className="modal-content__header">
						<Typography tag="h2" weight="extrablack" center>
							Edit Locations
						</Typography>
						<Typography tag="p" center>
							Edit locations associated with your company.
						</Typography>
					</header>
					<section className="modal-content__section">
						{fields.map(({ id }, index) => (
							<S.LocationItem key={id}>
								<Typography tag="h5" weight="bold">
									Location {index + 1}
								</Typography>
								<Button className="location-remove" variant="text" type="button" onClick={() => handleRemoveLocation(index)}>
									<Typography variation="button-medium" weight="bold">
										Remove
									</Typography>
								</Button>
								<S.FieldsWrapper>
									<TextInput
										label="Location Name"
										id={`location-name-${index}`}
										error={errors?.locations?.[index]?.locationName}
										{...register(`locations.${index}.locationName`, {
											required: required('Location Name'),
										})}
									/>
									<TextInput
										label="Address"
										id={`address-${index}`}
										error={errors?.locations?.[index]?.addressLine1}
										{...register(`locations.${index}.addressLine1`, {
											required: required('Address'),
										})}
									/>
									<div className="location-grid-row">
										<TextInput
											label="City"
											id={`city-${index}`}
											error={errors?.locations?.[index]?.city}
											{...register(`locations.${index}.city`, {
												required: required('City'),
											})}
										/>
										<Controller
											name={`locations.${index}.state`}
											control={control}
											rules={{ required: required('State') }}
											render={({ field: { onChange, value } }) => (
												<Select
													getOptionValue={(option) => `${option['abbreviation']}`}
													getOptionLabel={(option) => `${option['abbreviation']}`}
													label="State"
													id={`state-${index}`}
													error={errors?.locations?.[index]?.state}
													options={STATE_OPTIONS}
													value={STATE_OPTIONS.find((c) => c.abbreviation === value) || ''}
													onChange={(val) => onChange(val.abbreviation)}
												/>
											)}
										/>
										<TextInput
											label="Zipcode"
											id={`zipcode-${index}`}
											error={errors?.locations?.[index]?.postalCode}
											{...register(`locations.${index}.postalCode`, {
												required: required('Zipcode'),
											})}
										/>
									</div>
								</S.FieldsWrapper>
							</S.LocationItem>
						))}

						<S.AddWrapper>
							<Button variant="text" type="button" onClick={handleAddLocation}>
								<FontAwesomeIcon icon={['fal', 'plus']} />
								<Typography variation="button-medium" weight="bold">
									Add Location
								</Typography>
							</Button>
						</S.AddWrapper>
					</section>
				</div>
				<div className="modal-footer">
					<Button>
						<Typography variation="button-medium" weight="bold">
							{isSubmitting ? 'Loading' : 'Done'}
						</Typography>
					</Button>
				</div>
			</S.Form>
		</ReactModal>
	);
});

EditBusinessLocationsModal.displayName = 'EditBusinessLocationsModal';

EditBusinessLocationsModal.propTypes = {
	company: PropTypes.shape(),
	dispatch: PropTypes.func,
};
