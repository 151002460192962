import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useDebouncedFn } from 'beautiful-react-hooks';

import { updateUser } from '../../../app/slices/user/userSlice';
import { updateUserNotifications } from '../../../services/users';
import { errorHandler } from '../../../services/authService';
import { Card, Radio, Toggle, Typography } from '../../../components';
import * as S from './NotificationsCard.styles';

const NotificationsCard = ({ className }) => {
	const user = useSelector((state) => state.user.value);
	const dispatch = useDispatch();

	const [notificationValues, setNotificationValues] = useState(user?.preferences?.email);

	const updateNotifications = async (values) => {
		try {
			const {
				data: { result },
			} = await updateUserNotifications({ preferences: { email: values } });
			dispatch(updateUser(result.user));
		} catch (error) {
			errorHandler(error);
		}
	};

	const updateNotificationsDb = useDebouncedFn(updateNotifications, 800, null, []);

	const onChange = (e) => {
		let newValue = {};
		if (e.target.name === 'locationPreference') {
			newValue = {
				...notificationValues,
				locationPreference: {
					enabled: e.target.checked,
					preference: notificationValues.locationPreference.preference,
				},
			};
		} else if (e.target.name === 'preference') {
			newValue = {
				...notificationValues,
				locationPreference: {
					enabled: notificationValues.locationPreference.enabled,
					preference: e.target.value,
				},
			};
		} else {
			newValue = { ...notificationValues, [e.target.name]: e.target.checked };
		}
		setNotificationValues(newValue);
		updateNotificationsDb(newValue);
	};

	return (
		<Card className={className} title="Notifications" transparentHeaderBorder={true}>
			<S.Wrapper>
				<Typography tag="h4" weight="bold">
					Email Notifications
				</Typography>
				<S.ToggleLine>
					<div className="line-info">
						<Typography tag="p" weight="semibold">
							All Notifications
						</Typography>
						<Typography tag="p" variation="2">
							Receive email notifications from the Navigator.
						</Typography>
					</div>
					<div className="toggle-wrapper">
						<Toggle name="all" checked={notificationValues?.all} onChange={onChange} />
					</div>
				</S.ToggleLine>
				<S.ToggleLine>
					<div className="line-info">
						<Typography tag="p" weight="semibold">
							New Messages
						</Typography>
						<Typography tag="p" variation="2">
							Receive notifications when you receive a new message.
						</Typography>
					</div>
					<div className="toggle-wrapper">
						<Toggle name="newMessages" checked={notificationValues?.newMessages} onChange={onChange} />
					</div>
				</S.ToggleLine>
				<S.ToggleLine>
					<div className="line-info">
						<Typography tag="p" weight="semibold">
							New Requests
						</Typography>
						<Typography tag="p" variation="2">
							Receive notifications when a new request is posted that matches a request preference.
						</Typography>
					</div>
					<div className="toggle-wrapper">
						<Toggle name="newRequests" checked={notificationValues?.newRequests} onChange={onChange} />
					</div>
				</S.ToggleLine>
				<S.ToggleLine>
					<div className="line-info">
						<Typography tag="p" weight="semibold">
							Location Preference
						</Typography>
						<Typography tag="p" variation="2">
							Receive notifications for specific locations.
						</Typography>
					</div>
					<div className="toggle-wrapper">
						<Toggle name="locationPreference" checked={notificationValues?.locationPreference?.enabled} onChange={onChange} />
					</div>
				</S.ToggleLine>
				{notificationValues?.locationPreference?.enabled && (
					<>
						<div className="radio-field">
							<Radio name="preference" id="preference-all" checked={notificationValues?.locationPreference?.preference === 'allLocations'} onChange={onChange} value="allLocations" />
							<label htmlFor="preference-all">All Company Locations</label>
						</div>
						<div className="radio-field">
							<Radio name="preference" id="preference-assigned" checked={notificationValues?.locationPreference?.preference === 'assignedLocations'} onChange={onChange} value="assignedLocations" />
							<label htmlFor="preference-assigned">Locations Assigned to Me</label>
						</div>
					</>
				)}
			</S.Wrapper>
		</Card>
	);
};

NotificationsCard.propTypes = {
	className: PropTypes.string,
};

export default NotificationsCard;
