import React, { useState, useEffect, useRef } from 'react';
import { useDebouncedFn } from 'beautiful-react-hooks';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';

import { REVIEW_SORT_OPTIONS } from '../../../../constants/general.constants';
import { fetchReviews } from '../../../services/reviews';
import { Typography, Spinner, ErrorComponent, EmptyComponent, FilterSection, Button, RatingStars, Card } from '../../../components';
import * as S from './ReviewsTab.styles';

export const ReviewsTab = ({}) => {
	const { id: businessId } = useParams();
	const [pageStatus, setPageStatus] = useState('idle');
	const [reviews, setReviews] = useState([]);

	const initialPageNumber = 0;
	const [pageNumber, setPageNumber] = useState(initialPageNumber);
	const [totalPages, setTotalPages] = useState(undefined);
	const [loadingMore, setLoadingMore] = useState(false);

	const filterRef = useRef();

	const onChangeCallback = useDebouncedFn(async () => {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchReviews({ page: initialPageNumber, businessId, ...filterRef.current.value });
			setReviews(result.reviews);
			setPageNumber(initialPageNumber);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}, 300);

	async function loadMore() {
		setLoadingMore(true);
		try {
			if (totalPages > pageNumber + 1) {
				const {
					data: { result },
				} = await fetchReviews({ page: pageNumber + 1, businessId, ...filterRef.current.value });
				setReviews((prev) => [...prev, ...result.reviews]);
				setTotalPages(result.totalPages);
				setPageNumber((prev) => prev + 1);
			} else {
				toast.error('No more reviews to load');
			}
			setLoadingMore(false);
		} catch (error) {
			setLoadingMore(false);
		}
	}

	async function initializeData() {
		setPageStatus('loading');
		try {
			const {
				data: { result },
			} = await fetchReviews({ page: initialPageNumber, businessId });
			setReviews(result.reviews);
			setTotalPages(result.totalPages);
			setPageStatus('success');
		} catch (error) {
			setPageStatus('error');
		}
	}

	useEffect(() => {
		if (pageStatus === 'idle') {
			initializeData();
		}
	}, [pageStatus]);

	const renderContent = () => {
		switch (pageStatus) {
			case 'loading':
				return <Spinner />;
			case 'error':
				return <ErrorComponent />;
			case 'success':
				return reviews.length > 0 ? (
					<>
						<S.CardsWrapper>
							{reviews.map((review) => (
								<S.CardItem key={review.id}>
									<div className="business-review-item smaller">
										<div className="business-review-item__image-wrapper">
											<img src={review.creator?.profileImageObj?.[200]} alt={`${review.creator?.firstName} ${review.creator?.lastName}`} />
										</div>
										<div className="business-review-item__fields-wrapper">
											<Typography tag="h4" weight="bold">
												{`${review.creator?.firstName} ${review.creator?.lastName}`}
											</Typography>
											<S.ReviewWrapper>
												<RatingStars label="Rating" value={review.rating} />
												<div className="review__info-item">
													<Typography tag="h6" weight="semibold">
														Message
													</Typography>
													<Typography tag="p">{review.comments || 'No comments included'} </Typography>
												</div>
											</S.ReviewWrapper>
										</div>
									</div>
								</S.CardItem>
							))}
						</S.CardsWrapper>
						{totalPages > pageNumber + 1 && (
							<S.LoadMoreWrapper>
								<Button variant="outline" variation="secondary" type="button" onClick={loadMore}>
									<Typography variation="button-medium" weight="bold">
										{loadingMore ? 'Loading...' : 'Load More'}
									</Typography>
								</Button>
							</S.LoadMoreWrapper>
						)}
					</>
				) : (
					<EmptyComponent title="No available reviews" message="Check back later to view completed reviews." icon={['fal', 'star']} />
				);

			default:
				return null;
		}
	};

	return (
		<S.Wrapper>
			<FilterSection
				ref={filterRef}
				onChangeCallback={onChangeCallback}
				initialState={{
					searchText: '',
					sortOption: REVIEW_SORT_OPTIONS[0],
					showAdvancedFilters: false,
					activeFilters: [],
					distance: 0,
					counties: [],
					locationTypes: [],
					categories: [],
					careerClusters: [],
					ageGroups: [],
					employabilitySkills: [],
					curriculumStandards: [],
				}}
				sortOptions={REVIEW_SORT_OPTIONS}
				filterOptions={['employabilitySkills', 'curriculumStandards']}
			/>
			<Card title="Reviews" transparentHeaderBorder={true}>
				{renderContent()}
			</Card>
		</S.Wrapper>
	);
};
