import styled from 'styled-components';

export const Wrapper = styled.div`
	display: grid;
	gap: 1.25rem;
	padding: 0.5rem 1rem 2.5rem;

	.a1 {
		grid-area: a1;
	}
	.a2 {
		grid-area: a2;
	}

	grid-template-areas:
		'a1'
		'a2';
`;
